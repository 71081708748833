import React from 'react'
import UploadIcon from '../../../assets/upload-icon.svg'
//import Button from '../../Buttons'
//import Input from '../../forms/fileUpload'

const UploadFile = () => {
  return (
    <section className="patientImport__upload">
      <img src={UploadIcon} alt="upload file" />
      <h2 className="h2 bold">Upload a patient data</h2>
      <p className="p">
        Import a CSV patient data to create multiple new patients in Clever
        Clinic
      </p>
    </section>
  )
}

export default UploadFile
