import * as systemSupport from '../services/SystemSupport/SystemSupportService'
import types from './types'

export const A_PostQuery = details => dispatch =>
  systemSupport
    .postQuery(details)
    .then(response => {
      dispatch({ type: types.QUERY_POST.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.QUERY_POST.FAIL, error })
      return Promise.reject(error)
    })
