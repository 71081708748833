import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { A_PostQuery } from '../../actions'
import { requiredSpan } from '../OnBoarding/FormErrorValidations'

class SystemSupport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      subject: true,
      query: true,
    }
    this.subject = React.createRef()
    this.message = React.createRef()
  }

  reportIssue = () => {
    const { actions, history } = this.props
    var subject = this.subject.current.value
    var query = this.message.current.value
    if (subject.length > 0) {
      this.setState({ subject: true })
    } else {
      this.setState({ subject: false })
    }
    if (query.length > 0) {
      this.setState({ query: true })
    } else {
      this.setState({ query: false })
    }
    this.timer1 = setTimeout(() => {
      if (this.state.subject && this.state.query) {
        actions.A_PostQuery({ subject, query }).then(() => {
          this.subject.current.value = ''
          this.message.current.value = ''
          this.timer2 = setTimeout(() => {
            history.push('/clinics/calendar/appointments')
          }, 1000)
        })
      }
    }, 200)
  }

  handleSubject = e => {
    this.setState({ subjectName: e.target.value })
  }

  handleMessage = e => {
    this.setState({ messageText: e.target.value })
  }

  componentWillUnmount = () => {
    if (this.timer1) {
      clearTimeout(this.timer1)
      this.timer1 = 0
    }
    if (this.timer2) {
      clearTimeout(this.timer2)
      this.timer2 = 0
    }
  }

  render() {
    const { reportProblem } = this.props
    return (
      <div>
        <main className="main">
          <section className="main__inner">
            <article className="directionHeading__wrapper">
              <h1 className="h2 directionHeading">
                Contact the Clever Clinic support team using the form below. A
                member of the team will respond to your query as soon as
                possible.
              </h1>
              <form className="form fadeInFlyAnimate" noValidate>
                <section className="form__group">
                  <label className="form__label" htmlFor="subject">
                    {this.state.subject ? '' : requiredSpan()}Subject
                  </label>
                  <input
                    className={`form__textField ${
                      this.state.subject ? '' : 'form__select--error'
                    }`}
                    type="text"
                    id="subject"
                    placeholder="Add a title to your query"
                    onChange={this.handleSubject}
                    ref={this.subject}
                    value={this.state.subjectName ? this.state.subjectName : ''}
                  />
                  <label className="form__label" htmlFor="message">
                    {this.state.query ? '' : requiredSpan()}Message
                  </label>
                  <textarea
                    className={`form__textField ${
                      this.state.query ? '' : 'form__select--error'
                    }`}
                    id="message"
                    onChange={this.handleMessage}
                    ref={this.message}
                    placeholder="Enter your query including as much detail as possible"
                    rows="4"
                    value={this.state.messageText ? this.state.messageText : ''}
                  />
                  <span
                    role="button"
                    tabIndex="0"
                    className="buttonSecondary form__submit modal__submit"
                    onClick={this.reportIssue}
                    onKeyDown={this.reportIssue}
                  >
                    Send
                  </span>
                </section>
              </form>
            </article>
          </section>
        </main>
        <section className="overlay" />
        {reportProblem.success ? (
          <article className="feedback" style={{ display: '-webkit-box' }}>
            <section className="feedback__innerWrapper">
              <span className="feedback__icon icon-tick-circle" />
              <span className="feedback__message">
                <span>
                  Message sent, the team will be in touch via email in 1-2
                  working days
                </span>
                <span className="bold" />
              </span>
            </section>
            <span className="feedback__close icon-cross" />
          </article>
        ) : (
          ''
        )}
      </div>
    )
  }
}

SystemSupport.propTypes = {
  actions: PropTypes.isRequired,
  reportProblem: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

//export default ClinicsPage;SystemSupport
const mapStateToProps = state => {
  return { data: state.clinicDetails, reportProblem: state.reportProblem }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_PostQuery,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SystemSupport),
)
