import React /*, { Component } */ from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import MainButton from '../../Buttons'
import { A_UpdatePatientDetail } from '../../../actions'

class PatientCard extends React.Component {
  constructor(props) {
    super(props)
    const { list, patient } = this.props
    this.state = {
      isEdited: false,
      cardList: list,
      patientEditData: patient,
      duplicateError: false,
      responseError: '',
    }
    this.editMedicalRecordToggle = this.editMedicalRecordToggle.bind(this)
    this.displayList = this.displayList.bind(this)
    this.renderIcons = this.renderIcons.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  editMedicalRecordToggle(e) {
    const { list } = this.props
    if (e) {
      this.setState(prevState => ({
        isEdited: !prevState.isEdited,
        cardList: list,
      }))
    }
  }

  displayList() {
    const { placeholder } = this.props
    const list = this.state.cardList

    let point = []
    let counter = 0
    if (list.length !== 0 || list !== null) {
      list.forEach(med => {
        counter++
        point.push(
          <li key={`med_${med.type}${counter}`}>
            <span>
              {med.title}
              {med.details ? ' - ' + med.details : ''}
            </span>
          </li>,
        )
      })
      if (counter === 0) {
        return <p className="p">{placeholder}</p>
      }
      return point
    }
  }

  /*  Send the patch request and handle the response  */
  submitForm() {
    const { actions, patient, error, global } = this.props

    let clinic = global.currentClinicID

    actions
      .A_UpdatePatientDetail(clinic, patient.id, this.getSubmitPatientData())
      .then(response => {
        if (error) {
          if (response.status && response.status === 400) {
            error(true, [...response.data.non_field_errors])
          } else {
            this.setState({
              isEdited: false,
            })
            error(false, '')
          }
        }
      })
  }

  /*  Parse the data to create the PATCH object  */
  getSubmitPatientData() {
    const { label } = this.props
    let patient = this.state.patientEditData
    let medicalInfo = this.state.cardList

    medicalInfo = medicalInfo.split(',')
    let trimmedInfo = []
    medicalInfo.map((data, index) => {
      if (data === '') {
        return false
      } else {
        trimmedInfo[index] = data.trim()
      }
      return trimmedInfo
    })
    medicalInfo = trimmedInfo.join(', ')
    //Data items that are objects should only have the id returned in the patch object
    //E.g. Clinician or clinic changes
    patient[label] = medicalInfo
    this.setState({
      patientEditData: patient,
    })
    return patient
  }

  updateTextArea(event) {
    this.setState({
      cardList: event.target.value,
    })
  }

  renderIcons() {
    const { color, icon } = this.props

    return (
      <div className={`icon__circle icon__circle--${color}`}>
        <span className={icon} />
      </div>
    )
  }

  render() {
    const { title } = this.props
    return (
      <React.Fragment>
        <section className="medicalCard">
          <div className="">
            {this.renderIcons()}
            <h3 className="h3">{title}</h3>
            {/* 21/08/19 ------  Edit removed due to questionnaire added to IOS, if questionnaire comes to web link edit there */}
            {/* {!this.state.isEdited ? (
              <button
                className="buttonCircle buttonCircle--card icon-pencil"
                onClick={e => {
                  this.editMedicalRecordToggle(e)
                }}
              />
            ) : (
              ""
            )} */}
          </div>
          {!this.state.isEdited ? (
            <section>
              <ul>{this.displayList()}</ul>
            </section>
          ) : (
            <React.Fragment>
              <section>
                <textarea
                  className="form__textField form__textArea--fullHeight"
                  rows="6"
                  placeholder="Seperate with commas to create bullet points"
                  id="edit"
                  onChange={e => {
                    this.updateTextArea(e)
                  }}
                  defaultValue={this.state.cardList}
                />
              </section>
              <div>
                <MainButton
                  type="button"
                  label="Cancel"
                  styleType="tertiary"
                  size="small"
                  margin="right"
                  leftAuto={true}
                  clickHandler={e => {
                    this.editMedicalRecordToggle(e)
                  }}
                />
                <MainButton
                  type="button"
                  label="Update"
                  styleType="secondary"
                  size="small"
                  margin="right"
                  clickHandler={() => {
                    this.submitForm()
                  }}
                />
              </div>
            </React.Fragment>
          )}
        </section>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    global: state.global,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_UpdatePatientDetail,
      },
      dispatch,
    ),
  }
}

PatientCard.defaultProps = {
  title: '',
  placeholder: '',
  icon: '',
  color: '',
  list: [],
  error: undefined,
}

PatientCard.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  list: PropTypes.array,
  patient: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  error: PropTypes.func,
  label: PropTypes.string.isRequired,
  global: PropTypes.object.isRequired,
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PatientCard),
)
