import Promise from 'promise'
import * as api from '../../api'
import * as url from '../../../api_urls'

export const getclinicDetails = id => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.clinics + id + '/')
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const patchUpdatedClinicDetails = data => {
  return new Promise((resolve, reject) => {
    return api
      .patch(url.clinics + data.id + '/', data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const postcallbackurlforQB = (clinicID, callbackURL) => {
  return new Promise((resolve, reject) => {
    return api
      .post(url.clinics + clinicID + '/quickbooks/access-url/', callbackURL)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const post_code_companyID_forQB = (clinicID, Data) => {
  return new Promise((resolve, reject) => {
    return api
      .post(url.clinics + clinicID + '/quickbooks/access_token/', Data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const delete_accessToken_forQB = clinicID => {
  return new Promise((resolve, reject) => {
    return api
      .remove(url.clinics + clinicID + '/quickbooks/access_token/')
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getQBdepartment = (clinicID, data) => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.clinics + clinicID + '/quickbooks/departments/', data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getQBtaxcodes = clinicID => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.clinics + clinicID + '/quickbooks/taxcodes/')
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const postDepartmentQB = (clinicID, data) => {
  return new Promise((resolve, reject) => {
    return api
      .patch(url.clinics + clinicID + '/quickbooks/access_token/', data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const postTaxCodesQB = (clinicID, data) => {
  return new Promise((resolve, reject) => {
    return api
      .patch(url.clinics + clinicID + '/quickbooks/taxcode/', data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const postCredentialsHX = (clinicID, data) => {
  return new Promise((resolve, reject) => {
    return api
      .post(url.clinics + clinicID + '/epharm/login/', data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const patchUpdatedClinicInvoiceDetails = (
  clinicID,
  invoice_header_logo,
) => {
  return new Promise((resolve, reject) => {
    return api
      .patch(url.clinics + clinicID + '/', invoice_header_logo)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
