import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as func from '../ModalOverlay'
import AlertModal from '../../components/Modal/Alert'
import CalendarAppointment from '../../components/Appointments/CalendarAppointment'
import {
  A_PassAppointmentData,
  A_PassBookedAppointmentData,
  A_SetClickedCellData,
  A_checkCanDeleteScheduleChange,
  A_DeleteBookedScheduleChange,
  A_DeleteBookedSchedule,
  A_PassAlreadyFetchedAppointmentDetails,
} from '../../actions'
import { setOddEvenClass } from '../../utilities/ReusableFunctions'

class RenderCalendarAppointments extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showAlert: false,
    }
  }

  componentDidMount() {}

  /*
   * Get the pixel height value of one minute
   */
  getAppointmentHeight = () => {
    // If it's the practitioner calendar, 140px represents the default appointment length
    // So the number of pixels per minute depends on the default appointment length
    // If it's the weekly calendar, the slot height is 105px for zoomed in and 52.5px for zoomed out views and represent 15 minutes
    // So the number of pixels per minute is 7 for zoomed in and 3.5 for zoomed out views
    const { calendar, calendarType } = this.props
    let defaultAppLength = this.getDefaultAppLength()
    let appointmentDefaultHeight =
      calendarType === 'practitionerCalendar'
        ? 140
        : calendar.zoomStatus === 'zoomedin'
        ? defaultAppLength * 7
        : defaultAppLength * 3.5
    return appointmentDefaultHeight
  }

  /*
   * Pass data to the create new appointment modal through an action and open the modal
   */
  showAppointmentModal = (selectedTime, ignoreOpeningHours) => {
    const { actions, global, clinician } = this.props
    const clinic = global.currentClinicID
    const id = clinician.id
    const avatar = clinician.signed_avatar
    actions.A_PassAppointmentData({
      id,
      avatar,
      clinicID: clinic,
      selectedTime,
      ignoreOpeningHours,
    })
    setTimeout(() => {
      func.showModal('createAppointmentModal')
    }, 100)
  }

  /*
   * Pass data to a view booked appointment modal through an action and open the modal
   */
  showAppointmentDetailModal = app => {
    const { actions, clinician } = this.props
    const id = clinician.id
    const avatar = clinician.avatar
    const clinic = global.currentClinicID
    const appointmentID = app.id
    actions.A_PassAlreadyFetchedAppointmentDetails(app)
    actions.A_PassBookedAppointmentData({
      id,
      avatar,
      clinic,
      appointmentID,
    })
    setTimeout(() => {
      func.showModal('viewAppointmentModal')
    }, 100)
  }

  /*
   * Get the classes to add to the calendar divisions to handle weekly or practitioner
   */
  getAppointmentStyle = () => {
    const { calendarType } = this.props
    let styleAppointment = 'appointmentDivision '
    if (calendarType === 'practitionerCalendar') {
      styleAppointment = 'appointmentDivision appointmentDivision--practitioner'
    }
    return styleAppointment
  }

  minutesFromTime = time => {
    const split_time = time.split('.')
    return Math.floor(split_time[0] * 60) + Math.floor(split_time[1])
  }

  timeFromMinutes = minutes => {
    const hours = Math.floor(minutes / 60)
    const mins = Math.floor(minutes % 60)
    return (hours < 10 ? '0' : '') + hours + '.' + (mins < 10 ? '0' : '') + mins
  }

  minutesFromPixels = pixels => {
    const { defaultAppointmentLength } = this.props
    const appointmentHeight = this.getAppointmentHeight()
    const ratio = appointmentHeight / defaultAppointmentLength

    return Math.floor(pixels / ratio)
  }

  pixelsFromMinutes = minutes => {
    const { defaultAppointmentLength } = this.props
    const appointmentHeight = this.getAppointmentHeight()
    const ratio = appointmentHeight / defaultAppointmentLength

    return ratio * minutes
  }

  /*
   * Show alert if out of hours area is clicked
   */
  handleOutOfHourAppointment = date => {
    const { actions } = this.props
    this.setState({ showAlert: true })
    actions.A_SetClickedCellData(date)
  }

  /*
   * Close alert and pass appointment data if user proceeds with out of hours appointment
   */
  proceedwithClosedClinic = () => {
    this.setState({ showAlert: false })
    this.dataToCreateAppointmentModal()
  }

  /*
   * Close alert and return to calendar if user doesn't proceed with out of hours appointment
   */
  closeAlert = () => {
    const { actions } = this.props
    this.setState({
      showAlert: false,
      alertTitle: null,
      alertMessage: null,
      leftButtons: [],
    })
    actions.A_SetClickedCellData(null)
  }

  /*
   * Get data for creating appointment in out of hours area and pass to appointment modal
   */
  dataToCreateAppointmentModal = () => {
    const { calendar } = this.props
    this.showAppointmentModal(
      calendar.clickedCellData, // moment object
      true,
    )
  }

  getDefaultAppLength = () => {
    const { defaultAppointmentLength } = this.props
    let defaultAppLength = defaultAppointmentLength
      ? defaultAppointmentLength
      : 15
    return defaultAppLength
  }

  createAppointment = e => {
    const { calendar } = this.props

    const minutes =
      this.minutesFromPixels(e.nativeEvent.layerY) +
      this.minutesFromTime(
        e.target.parentNode.getAttribute('data-appointment-time'),
      )

    const time = this.timeFromMinutes(minutes)
    const split_time = time.split('.')

    const date = calendar.selectedDate.clone().set({
      hour: split_time[0],
      minute: split_time[1],
    })

    if (
      minutes < this.minutesFromTime(calendar.defaultOpeningTime) ||
      minutes > this.minutesFromTime(calendar.defaultClosingTime)
    ) {
      this.handleOutOfHourAppointment(date)
      return
    }

    this.showAppointmentModal(date, false)
  }

  manageScheduleChange = change => {
    if (change.id === 0) {
      this.setState({
        alertTitle: 'Error',
        alertMessage:
          "You can't make changes to this scheduled day as this is their default location",
        leftButtons: [],
      })
      return
    }

    const {
      actions,
      global: { timezone },
    } = this.props
    if (change.start_date.diff(moment.tz(new Date(), timezone), 'day') < 0) {
      this.setState({
        alertTitle: 'Error',
        alertMessage:
          "You can't make changes to this scheduled day as it is in the past",
        leftButtons: [],
      })
    } else {
      actions.A_checkCanDeleteScheduleChange(change).then(response => {
        const format = 'DD.MM.YYYY'

        const selected_date = moment
          .tz(change.start_date, timezone)
          .format(format)

        let start_date = moment.tz(change.schedule.start_date, timezone)

        if (moment.tz(new Date(), timezone).isAfter(start_date)) {
          start_date = moment.tz(new Date(), timezone)
        }

        const end_date = moment
          .tz(change.schedule.end_date, timezone)
          .format(format)

        let leftButtons = []
        let alertMessage = ''

        if (response.all) {
          alertMessage = `Deleting all would delete from ${start_date.format(
            format,
          )} to ${end_date}.`
          leftButtons.push({
            type: 'button',
            style: 'alert',
            label: 'All',
            size: 'small',
            events: {
              onClick: () => {
                this.deleteBookedSchedule(change)
              },
            },
          })
        }

        if (response.single) {
          alertMessage += `Deleting only selected date would delete ${selected_date}`
          leftButtons.push({
            type: 'button',
            style: 'tertiary',
            label: 'Only selected date',
            size: 'small',
            events: {
              onClick: () => {
                this.deleteBookedScheduleChange(change)
              },
            },
          })
        }

        this.setState({
          alertTitle: 'Would you like to delete schedule change(s)?',
          alertMessage: alertMessage,
          leftButtons: leftButtons,
        })
      })
    }
  }

  deleteBookedScheduleChange = change => {
    const { actions, load } = this.props

    this.closeAlert()

    actions.A_DeleteBookedScheduleChange(change).then(() => {
      load()
    })
  }

  deleteBookedSchedule = change => {
    const { actions, load } = this.props

    this.closeAlert()

    actions.A_DeleteBookedSchedule(change.schedule.id).then(() => {
      load()
    })
  }

  render() {
    const {
      bookedAppointments,
      calendarType,
      oddEvenKey,
      global,
      clinician,
      calendar,
    } = this.props
    const timezone = 'UTC'
    const clinicianId = clinician.id

    var elements = []

    /** Apply the odd even class to appointment_column class */
    let oddEvenClass = setOddEvenClass(oddEvenKey)

    let unavailable_times = []

    const date = moment.tz(calendar.selectedDate.format('YYYY-MM-DD'), timezone)

    const schedules = calendar.schedule
      .filter(sch => {
        return (
          parseInt(sch.clinician.id) === parseInt(clinicianId) &&
          sch.changes.length > 0
        )
      })
      .sort(function(a, b) {
        return new Date(a.start_date) - new Date(b.start_date)
      })

    let changes = []

    for (let i = 0; i < schedules.length; i++) {
      let schedule = schedules[i]

      for (let j = 0; j < schedule.changes.length; j++) {
        changes.push({
          ...schedule.changes[j],
          schedule: schedule,
        })
      }
    }

    if (clinician.current_clinic) {
      if (global.currentClinicID !== clinician.primary_clinic.id) {
        const start_of_day = date.clone().startOf('day')
        const end_of_day = date.clone().endOf('day')

        let start = start_of_day
        let end = end_of_day

        let auto_changes = []

        while (end_of_day.diff(start, 'seconds') > 0) {
          for (let i = 0; i < changes.length; i++) {
            let change = changes[i]

            let start_date = moment.tz(change.start_date, timezone)
            let end_date = moment.tz(change.end_date, timezone)

            if (start_date.diff(start, 'seconds') > 0) {
              end = start_date.clone()
              break
            }

            if (start_date.isSame(start)) {
              start = end_date.clone()
            }
          }

          if (start.diff(end, 'seconds') > 0) {
            end = end_of_day.clone()
          }

          if (start.diff(end_of_day, 'seconds') > 0) {
            start = end_of_day.clone()
          }

          if (start.diff(end, 'seconds') < 0) {
            auto_changes.push({
              id: 0,
              date: start.format('YYYY-MM-DD'),
              start_date: start.toDate(),
              end_date: end.toDate(),
              schedule: {
                location: clinician.primary_clinic,
                clinician: clinician,
                start_date: start,
                end_date: end,
                option: 0,
              },
            })
          }

          start = end
        }

        changes.push(...auto_changes)
      }
    }

    // After adding the primary clinic changes, remove ones that are for viewing clinic
    changes = changes
      .filter(change => {
        return (
          change.schedule.location === null ||
          change.schedule.location.id !== global.currentClinicID
        )
      })
      .map(change => {
        let start_date = moment.tz(change.start_date, timezone)
        let end_date = moment.tz(change.end_date, timezone)

        return {
          ...change,
          start_date: start_date,
          end_date: end_date,
          start_time: start_date.format('HH.mm'),
          end_time: end_date.format('HH.mm'),
        }
      })

    unavailable_times.push(...changes)

    changes = changes.map(change => {
      let schedule_class =
        change.schedule.option === 0
          ? 'appointment_Box--location-change'
          : 'appointment_Box--unavailable'

      let start_date = change.start_time
      let end_date = change.end_time

      let offset = this.minutesFromTime(start_date)
      // Get the height value of the appointment
      var offset_height = this.pixelsFromMinutes(offset)

      return (
        <div
          key={change.id + '_' + start_date + '_' + clinicianId}
          role="button"
          tabIndex="-1"
          className={`appointment_Box ${schedule_class}`}
          style={{
            height:
              this.pixelsFromMinutes(
                this.minutesFromTime(end_date) -
                  this.minutesFromTime(start_date),
              ) - 20,
            top: 10 + offset_height,
            position: 'absolute',
          }}
          onClick={() => {
            this.manageScheduleChange(change)
          }}
          onKeyDown={() => {
            this.manageScheduleChange(change)
          }}
        >
          {change.schedule.option === 0 ? (
            <React.Fragment>
              <span className="appointment_Box_topBorder appointment_Box_topBorder--location-change" />
              <h3 className="h4">Clinic change</h3>
              <p>{change.schedule.location.name}</p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <span className="appointment_Box_topBorder" />
              <h3 className="h4">Unavailable</h3>
            </React.Fragment>
          )}
        </div>
      )
    })

    let available_times = []

    if (bookedAppointments && bookedAppointments.length > 0) {
      let appointments = bookedAppointments
        .filter(appointment => {
          return appointment.clinician.id === clinician.id
        })
        .map(appointment => {
          return {
            ...appointment,
            start_time: moment
              .tz(appointment.start_date, timezone)
              .format('HH.mm'),
            end_time: moment.tz(appointment.end_date, timezone).format('HH.mm'),
          }
        })

      unavailable_times.push(...appointments)

      appointments = appointments.map(app => {
        const time = app.start_time
        const end_time = app.end_time

        let appointmentDuration =
          this.minutesFromTime(end_time) - this.minutesFromTime(time)

        const offset = this.minutesFromTime(time)
        // Get the height value of the appointment
        const offset_height = this.pixelsFromMinutes(offset)

        const type =
          app.appointment_type === 1
            ? 'meeting'
            : app.appointment_type === 2
            ? 'unavailable'
            : 'appointment'

        return (
          <CalendarAppointment
            key={app.id}
            height={this.pixelsFromMinutes(appointmentDuration) - 20}
            top={10 + offset_height}
            type={type}
            calendarType={calendarType}
            showAppointmentDetailModal={this.showAppointmentDetailModal}
            app={app}
          />
        )
      })

      elements.push(
        <div
          key={'appointmentDivison_-1_' + clinicianId}
          className="appointmentDivision"
          data-appointment-time="00.00"
          style={{
            height: 10080,
            top: 0,
            position: 'absolute',
            width: calendarType === 'practitionerCalendar' ? `100%` : 280,
          }}
        >
          {appointments}
        </div>,
      )
    }

    unavailable_times = unavailable_times.sort((a, b) => {
      if (a.start_date === b.start_date) {
        return new Date(a.end_date) - new Date(b.end_date)
      }

      return new Date(a.start_date) - new Date(b.start_date)
    })

    for (let i = 0; i < unavailable_times.length; i++) {
      let app = unavailable_times[i]
      let last_end_time = '00.00'
      let last_minutes = 0
      let last_app

      if (i > 0) {
        last_app = unavailable_times[i - 1]
      }

      if (last_app) {
        last_end_time = last_app.end_time
        last_minutes = this.minutesFromTime(last_end_time)
      }

      if (last_minutes < this.minutesFromTime(app.start_time)) {
        available_times.push({
          start_date: last_end_time,
          end_date: app.start_time,
        })
      }

      if (
        i + 1 === unavailable_times.length &&
        this.minutesFromTime(app.end_time) < this.minutesFromTime('24.00')
      ) {
        available_times.push({ start_date: app.end_time, end_date: '24.00' })
      }
    }

    if (available_times.length === 0) {
      available_times.push({ start_date: '00.00', end_date: '24.00' })
    }

    available_times = available_times.map(available_time => {
      let { start_date, end_date } = available_time

      let last_minutes = this.minutesFromTime(start_date)
      let offset = this.minutesFromTime(end_date)

      return (
        <div
          key={start_date + '_' + clinicianId}
          className={this.getAppointmentStyle()}
          data-appointment-time={start_date}
          style={{
            position: 'absolute',
            height: this.pixelsFromMinutes(offset - last_minutes),
            top: this.pixelsFromMinutes(last_minutes),
            width: calendarType === 'practitionerCalendar' ? `100%` : 280,
          }}
        >
          <button
            type="button"
            className="buttonTransparent buttonFill"
            onClick={this.createAppointment}
            tabIndex="-1"
          />
        </div>
      )
    })

    elements.push(...available_times)
    elements.push(
      <div
        key={'appointmentDivision_-2_' + clinicianId}
        className="appointmentDivision"
        data-appointment-time="00.00"
        style={{ height: 10080, top: 0, position: 'absolute', width: 280 }}
      >
        {changes}
      </div>,
    )

    return (
      <div
        key={'appointmentColumn_' + clinicianId}
        className={`appointment_column_container ${
          calendarType === 'practitionerCalendar'
            ? 'appointment_column--expanded'
            : ''
        }`}
      >
        <div
          key={`practitioner_${clinicianId}`}
          className={`appointment_column ${
            calendarType === 'practitionerCalendar'
              ? ''
              : `gridCol gridCol--${oddEvenClass}`
          } `}
          style={{ height: 10080, width: 280 }}
        >
          {elements}
        </div>
        {this.state.showAlert ? (
          <AlertModal
            message={
              'You are trying to book an appointment outside the normal opening hours of this clinic. Do you wish to continue?'
            }
            closeAlert={this.closeAlert}
            title="Out of Hours Appointment"
            rightButtons={[
              {
                type: 'button',
                style: 'tertiary',
                label: 'Cancel',
                size: 'small',
                events: {
                  onClick: this.closeAlert,
                },
              },
            ]}
            leftButtons={[
              {
                type: 'button',
                style: 'tertiary',
                label: 'Proceed',
                size: 'small',
                events: {
                  onClick: this.proceedwithClosedClinic,
                },
              },
            ]}
            modalClassWidth="col__12-5"
          />
        ) : null}
        {this.state.alertMessage ? (
          <AlertModal
            message={this.state.alertMessage}
            closeAlert={this.closeAlert}
            title={this.state.alertTitle}
            rightButtons={[
              {
                type: 'button',
                style: 'tertiary',
                label: 'Cancel',
                size: 'small',
                events: {
                  onClick: this.closeAlert,
                },
              },
            ]}
            leftButtons={this.state.leftButtons}
            modalClassWidth="col__12-5"
          />
        ) : (
          ''
        )}
      </div>
    )
  }
}

RenderCalendarAppointments.defaultProps = {
  bookedAppointments: [],
  defaultAppointmentLength: 20,
  calendarType: 'weeklyCalendar',
  oddEvenKey: 0,
}

RenderCalendarAppointments.propTypes = {
  actions: PropTypes.object.isRequired,
  bookedAppointments: PropTypes.array,
  defaultAppointmentLength: PropTypes.number,
  calendarType: PropTypes.string,
  oddEvenKey: PropTypes.number,
  calendar: PropTypes.object.isRequired,
  global: PropTypes.object.isRequired,
  clinician: PropTypes.object.isRequired,
  load: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  return {
    calendar: state.calendar,
    global: state.global,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_PassAppointmentData,
        A_PassBookedAppointmentData,
        A_SetClickedCellData,
        A_checkCanDeleteScheduleChange,
        A_DeleteBookedScheduleChange,
        A_DeleteBookedSchedule,
        A_PassAlreadyFetchedAppointmentDetails,
      },
      dispatch,
    ),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RenderCalendarAppointments),
)
