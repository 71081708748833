import * as schedule from '../services/ClinicScheduleService'
import { ignoreGlobalError } from '../utilities/ReusableFunctions'
import types from './types'

export const A_CalendarHeaderMonthActions = (payload, currentdate) => ({
  type: types.CALENDAR_HEADER_RENDER_MONTH,
  payload: payload,
  currentdate: currentdate,
})

export const A_FetchCliniciansList = () => dispatch =>
  schedule
    .fetchCliniciansList()
    .then(response => {
      dispatch({ type: types.FETCH_CLINICIANS_LIST.OK, list: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.FETCH_CLINICIANS_LIST.FAIL, error })
      return Promise.reject(error)
    })

export const A_FetchClinicScheduleList = (
  year,
  month,
  day,
  clinicians,
) => dispatch =>
  schedule
    .fetchClinicScheduleList(year, month, day, clinicians)
    .then(response => {
      dispatch({ type: types.FETCH_SCHEDULE_LIST.OK, list: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.FETCH_SCHEDULE_LIST.FAIL, error })
      return Promise.reject(error)
    })

export const A_FetchClinicScheduleConflictList = (
  clinician,
  startdate,
  enddate,
  isRecurring,
  interval,
  every,
  on,
) => dispatch =>
  schedule
    .fetchClinicScheduleConflictList(
      clinician,
      startdate,
      enddate,
      isRecurring,
      interval,
      every,
      on,
    )
    .then(response => {
      dispatch({ type: types.FETCH_SCHEDULE_CONFLICT_LIST.OK, list: response })
      return response
    })
    .catch(error => {
      dispatch({
        type: types.FETCH_SCHEDULE_CONFLICT_LIST.FAIL,
        error,
        ignoreGlobalError: ignoreGlobalError(error),
      })
      return error
    })

export const A_checkCanDeleteScheduleChange = change => dispatch =>
  schedule
    .checkCanDeleteScheduleChange(change)
    .then(response => {
      return response
    })
    .catch(error => {
      dispatch({ type: types.CHECK_DELETE_SCHEDULE_CHANGE.FAIL, error })
      return Promise.reject(error)
    })

export const A_ResetDeletionProp = () => ({
  type: types.RESET_SCHEDULE_DELETION_PROP,
})

export const A_PostClinicScheduleChange = scheduleChange => dispatch =>
  schedule
    .postClinicScheduleChange(scheduleChange)
    .then(response => {
      dispatch({ type: types.POST_SCHEDULE_CHANGE.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.POST_SCHEDULE_CHANGE.FAIL, error })
      return Promise.reject(error)
    })

export const A_DeleteBookedSchedule = id => dispatch =>
  schedule
    .deleteBookedSchedule(id)
    .then(response => {
      dispatch({ type: types.DELETE_BOOKED_SCHEDULE.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.DELETE_BOOKED_SCHEDULE.FAIL, error })
      return Promise.reject(error)
    })

export const A_DeleteBookedScheduleChange = change => dispatch =>
  schedule
    .deleteBookedScheduleChange(change)
    .then(response => {
      dispatch({ type: types.DELETE_BOOKED_SCHEDULE.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.DELETE_BOOKED_SCHEDULE.FAIL, error })
      return Promise.reject(error)
    })

export const A_PassScheduleData = showModal => ({
  type: types.PASS_SCHEDULE_DETAILS,
  showModal,
})

export const A_SetScheduleDaysList = list => ({
  type: types.SET_SCHEDULE_DAYS_LIST,
  list,
})
