import * as inventory from '../services/Inventory/Inventory'
import types from './types'

export const A_GetInventoryList = id => dispatch =>
  inventory
    .getInventoryList(id)
    .then(response => {
      dispatch({ type: types.GET_INVENTORY_LIST.OK, data: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_INVENTORY_LIST.FAIL, error })
      return Promise.reject(error)
    })

export const A_PostNewInventory = (clinicID, productDetails) => dispatch =>
  inventory
    .postInventoryDetails(clinicID, productDetails)
    .then(response => {
      dispatch({ type: types.POST_NEW_INVENTORY.OK, productDetails: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.POST_NEW_INVENTORY.FAIL, error })
      return Promise.reject(error)
    })

export const A_DeleteInventory = (clinicID, productID) => dispatch =>
  inventory
    .deleteInventoryDetails(clinicID, productID)
    .then(response => {
      dispatch({ type: types.DELETE_INVENTORY.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.DELETE_INVENTORY.FAIL, error })
      return Promise.reject(error)
    })

export const A_PatchInventoryDetails = (
  clinicID,
  productID,
  productDetails,
) => dispatch =>
  inventory
    .patchEditInventoryDetails(clinicID, productID, productDetails)
    .then(response => {
      dispatch({ type: types.PATCH_INVENTORY_DETAILS.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.PATCH_INVENTORY_DETAILS.FAIL, error })
      return Promise.reject(error)
    })

// -------------- start of inventory options -----------------

export const A_GetInventoryOptionList = (clinicID, productID) => dispatch =>
  inventory
    .getInventoryOptionList(clinicID, productID)
    .then(response => {
      dispatch({ type: types.GET_INVENTORY_OPTION_LIST.OK, data: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_INVENTORY_OPTION_LIST.FAIL, error })
      return Promise.reject(error)
    })

export const A_PostNewInventoryOption = (
  clinicID,
  productID,
  productDetails,
) => dispatch =>
  inventory
    .postInventoryOptionDetails(clinicID, productID, productDetails)
    .then(response => {
      dispatch({ type: types.POST_NEW_INVENTORY_OPTION.OK, data: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.POST_NEW_INVENTORY_OPTION.FAIL, error })
      return Promise.reject(error)
    })

export const A_DeleteInventoryOption = (
  clinicID,
  productID,
  optionID,
) => dispatch =>
  inventory
    .deleteInventoryOptionDetails(clinicID, productID, optionID)
    .then(response => {
      dispatch({ type: types.DELETE_INVENTORY_OPTION.OK, data: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.DELETE_INVENTORY_OPTION.FAIL, error })
      return Promise.reject(error)
    })

export const A_PatchEditInventoryOptionDetails = (
  clinicID,
  productID,
  optionID,
  optionDetails,
) => dispatch =>
  inventory
    .patchEditInventoryOptionDetails(
      clinicID,
      productID,
      optionID,
      optionDetails,
    )
    .then(response => {
      dispatch({
        type: types.PATCH_INVENTORY_OPTION_DETAILS.OK,
        productDetails: response,
      })
      return response
    })
    .catch(error => {
      dispatch({ type: types.PATCH_INVENTORY_OPTION_DETAILS.FAIL, error })
      return Promise.reject(error)
    })
