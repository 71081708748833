import React from 'react'
import PropTypes from 'prop-types'

const NoteBanner = ({ notes }) => {
  return (
    <React.Fragment>
      {notes ? (
        <section className="invoice__note">
          <h4>Note:</h4>
          {notes.split('\n').map((note, i) => {
            let IndexID = i
            return <p key={IndexID}>{note}</p>
          })}
        </section>
      ) : null}
    </React.Fragment>
  )
}

NoteBanner.defaultProps = {
  notes: null,
}

NoteBanner.propTypes = {
  notes: PropTypes.string,
}

export default NoteBanner
