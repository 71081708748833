import React from 'react'
import PropTypes from 'prop-types'

const GraphHeaders = props => {
  ///CHANGE 12/7/2019 MAKE HEADERS BOLD
  return (
    <React.Fragment>
      {props.headers.header ? (
        <div className="tableRow__plain">
          {Object.keys(props.headers.header).map(function(index) {
            let header = props.headers.header[index]
              .split(/(?=[A-Z])/)
              .join(' ')
              .toUpperCase()
            return (
              <section
                key={index}
                className={`tableCell ${props.headers.classTitle[index]}`}
              >
                <div className="staffTable__email--container">
                  <span className="text-light bold noMargin summaryTable">
                    {header}
                  </span>
                </div>
              </section>
            )
          })}
        </div>
      ) : (
        ''
      )}
    </React.Fragment>
  )
}

GraphHeaders.propTypes = {
  headers: PropTypes.object.isRequired,
}

export default GraphHeaders
