import React from 'react'
import PropTypes from 'prop-types'
import TextInput from '../../forms/Input'
import SelectInput from '../../forms/Select'
import ModalHeader from '../../Modal/Header'
import ModalFooter from '../../Modal/Footer'
import UploadFile from '../../forms/fileUpload'
import MainButton from '../../Buttons'
import ToolTip from './ViewTreatment/ToolTip'
import Loader from '../../PatientData/Loader'
import { getFileSizefromEvent } from '../../../utilities/ReusableFunctions'

class TreatmentModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      treatmentName: this.props.selectedTreatmentName,
      nameValid: true,
      typeValid: true,
      fileValid: true,
      productSheetValid: true,
      consentFormTitle: this.props.consentFormTitle,
      productSheetTitle: this.props.productSheetTitle,
      selectedTreatmentType: this.props.selectedTreatmentType,
    }
  }

  onFileChange = event => {
    let fileSize = getFileSizefromEvent(event)
    this.setState({
      consentFormTitle: event.target.files[0].name,
      consentFormSize: fileSize,
    })
    this.props.onFileChange(event.target.files[0])
  }

  onProductSheetFileChange = event => {
    let fileSize = getFileSizefromEvent(event)
    this.setState({
      productSheetTitle: event.target.files[0].name,
      productSheetSize: fileSize,
    })
    this.props.onProductSheetFileChange(event.target.files[0])
  }

  onTreatmentNameChange = event => {
    const name = event.target.value
    this.setState({ treatmentName: name })
  }

  onTreatmentTypeChange = event => {
    const type = event.target.value
    this.setState({ selectedTreatmentType: type })
  }

  onButtonClick = event => {
    event.preventDefault()
    let validName = true
    let validType = true
    if (this.state.treatmentName.length === 0) {
      validName = false
    }

    if (this.state.selectedTreatmentType === -1) {
      validType = false
    }
    this.setState({ typeValid: validType, nameValid: validName }, () => {
      if (this.state.typeValid && this.state.nameValid) {
        this.props.handleSaveClick(
          this.state.treatmentName,
          this.state.selectedTreatmentType,
        )
      }
    })
  }

  render() {
    const nameError = !this.state.nameValid ? 'Required' : ''
    const typeError = !this.state.typeValid ? 'Required' : ''

    return (
      <React.Fragment>
        <article
          id="consentUploadModal"
          className="modal modal--overlay col__12-6 fadeInFlyAnimate"
          style={{ display: 'block' }}
        >
          <form method="post" noValidate className="modal_flex_content">
            <ModalHeader
              title={this.props.title}
              closeHandler={this.props.handleClose}
            />
            <section className="modal__body modal__body--form">
              <section className="modal__content form__group--topStackable">
                {this.props.isCustom ? (
                  <React.Fragment>
                    <section className="patientImport__column">
                      <TextInput
                        id="treatmentName"
                        name="treatmentName"
                        error={nameError}
                        label="Treatment name"
                        placeholder="E.g. Botox"
                        type="text"
                        handleOnChange={this.onTreatmentNameChange}
                        value={this.state.treatmentName}
                      />
                    </section>
                    <section className="patientImport__column">
                      <SelectInput
                        id="treatmentType"
                        name="treatmentType"
                        error={typeError}
                        options={this.props.options}
                        label="Treatment type"
                        onChange={this.onTreatmentTypeChange}
                        defaultSelected={this.state.selectedTreatmentType}
                      />
                    </section>
                  </React.Fragment>
                ) : (
                  <p className="modal__body__text">
                    <span className="modal__body__text--bold">
                      {this.props.selectedTreatmentName}
                    </span>{' '}
                    is a Clever Clinic treatment that cannot be deleted or
                    modified
                  </p>
                )}
                <section className="form__groupSmallSubmit">
                  {!this.state.fileValid ? (
                    <p className="form__label--error">
                      Consent forms are required for patients so they can be
                      informed about treatments
                    </p>
                  ) : (
                    ''
                  )}
                  <div className="footer__multipleButtonContainer secondaryMarginBottom">
                    <p className="h3">Consent</p>
                    <p className="modal__body__text">
                      Treatment info, impact and safety instructions for
                      gathering informed consent
                    </p>
                    <p
                      className={`modal__body__text ${
                        this.state.consentFormTitle || this.props.isEdit
                          ? ''
                          : 'hidden'
                      }`}
                    >
                      {this.state.consentFormTitle ? (
                        <button
                          type="button"
                          className="treatment__header__button treatmentItem__alert buttonTransparent"
                          onClick={this.props.consentFormClick}
                        >
                          <span className="treatment__info__icon icon-document" />
                          <span className="treatment__info__text">
                            {this.state.consentFormSize
                              ? `${this.state.consentFormTitle} (${this.state.consentFormSize})`
                              : `${this.state.consentFormTitle}`}
                          </span>
                        </button>
                      ) : this.props.isEdit ? (
                        <span className="treatmentItem__alert">
                          Missing Consent Form
                        </span>
                      ) : (
                        ''
                      )}
                    </p>
                    <UploadFile
                      label="Choose File"
                      styleType="primary"
                      size="small"
                      labelID="uploadConsentButton"
                      inputID="uploadConsentForm"
                      accept=".pdf"
                      fileHandler={this.onFileChange}
                      customClasses={
                        this.props.isEdit || this.props.defaultConsent
                          ? ''
                          : 'modal--spacedButton--left'
                      }
                    />
                    {!this.props.isEdit && !this.props.defaultConsent ? (
                      <MainButton
                        type="button"
                        label="Reset to default"
                        styleType="tertiary"
                        size="small"
                        clickHandler={this.props.handleConsentDeleteTreatment}
                      />
                    ) : null}
                  </div>

                  {/** Amends Added consent and Treatment sheets description as per the discussion  */}
                  {!this.state.productSheetValid ? (
                    <p className="form__label--error">
                      Treatment information sheets are required for patients so
                      they can be informed about the treatment provided and the
                      linked products given in prescriptions
                    </p>
                  ) : (
                    ''
                  )}
                  <div className="footer__multipleButtonContainer">
                    <p className="h3">Treatment Sheet</p>
                    <p className="modal__body__text">
                      Treatment summary, drug information and aftercare
                      instructions for the patient
                    </p>

                    <p
                      className={`modal__body__text ${
                        this.state.productSheetTitle || this.props.isEdit
                          ? ''
                          : 'hidden'
                      }`}
                    >
                      {this.state.productSheetTitle ? (
                        <button
                          type="button"
                          className="treatment__header__button treatmentItem__alert buttonTransparent"
                          onClick={this.props.productSheetClick}
                        >
                          <span className="treatment__info__icon icon-document" />
                          <span className="treatment__info__text">
                            {this.state.productSheetSize
                              ? `${this.state.productSheetTitle} (${this.state.productSheetSize})`
                              : `${this.state.productSheetTitle}`}
                          </span>
                        </button>
                      ) : this.props.isEdit ? (
                        <span className="treatmentItem__alert">
                          Missing Treatment Information Sheet
                        </span>
                      ) : (
                        ''
                      )}
                    </p>
                    <UploadFile
                      label="Choose File"
                      styleType="primary"
                      size="small"
                      labelID="uploadProductInfoButton"
                      inputID="uploadProductInfoSheet"
                      accept=".pdf"
                      fileHandler={this.onProductSheetFileChange}
                      customClasses={
                        this.props.isEdit || this.props.defaultProductSheet
                          ? ''
                          : 'modal--spacedButton--left'
                      }
                    />
                    {!this.props.isEdit && !this.props.defaultProductSheet ? (
                      <MainButton
                        type="button"
                        label="Reset to default"
                        styleType="tertiary"
                        size="small"
                        clickHandler={
                          this.props.handleProductSheetDeleteTreatment
                        }
                      />
                    ) : null}
                  </div>
                </section>
              </section>
            </section>
            {this.props.loader ? <Loader text="updating treatments" /> : null}
            <ModalFooter
              leftButtons={this.props.leftButtons}
              rightButtons={this.props.footerButtons}
              closeHandler={this.props.handleClose}
              clickHandler={this.onButtonClick}
            >
              <ToolTip
                handleDelete={this.props.handleDelete}
                handleCancel={this.props.handleCancel}
                titleHeader={this.props.toolTipHeader}
                titleBody={this.props.toolTipBody}
              />
            </ModalFooter>
          </form>
        </article>
        <section
          className="overlay fadeInAnimate"
          style={{ display: 'block' }}
        />
      </React.Fragment>
    )
  }
}

TreatmentModal.defaultProps = {
  title: '',
  handleSaveClick: () => {},
  handleClose: () => {},
  onFileChange: () => {},
  leftButtons: [],
  footerButtons: [],
  options: [],
  consentFormTitle: '',
  consentFormClick: () => {},
  productSheetTitle: '',
  productSheetClick: () => {},
  onProductSheetFileChange: () => {},
  selectedTreatmentType: -1,
  selectedTreatmentName: '',
  isEdit: false,
  isCustom: false,
  handleDelete: () => {},
  handleCancel: () => {},
  toolTipHeader: '',
  toolTipBody: '',
  defaultConsent: true,
  defaultProductSheet: true,
  handleConsentDeleteTreatment: () => {},
  handleProductSheetDeleteTreatment: () => {},
  loader: false,
}

TreatmentModal.propTypes = {
  title: PropTypes.string,
  handleSaveClick: PropTypes.func,
  handleClose: PropTypes.func,
  onFileChange: PropTypes.func,
  leftButtons: PropTypes.array,
  footerButtons: PropTypes.array,
  options: PropTypes.array,
  consentFormTitle: PropTypes.string,
  consentFormClick: PropTypes.func,
  productSheetTitle: PropTypes.string,
  productSheetClick: PropTypes.func,
  onProductSheetFileChange: PropTypes.func,
  isEdit: PropTypes.bool,
  isCustom: PropTypes.bool,
  selectedTreatmentType: PropTypes.number,
  selectedTreatmentName: PropTypes.string,
  handleDelete: PropTypes.func,
  handleCancel: PropTypes.func,
  toolTipHeader: PropTypes.string,
  toolTipBody: PropTypes.string,
  defaultConsent: PropTypes.bool,
  defaultProductSheet: PropTypes.bool,
  handleConsentDeleteTreatment: PropTypes.func,
  handleProductSheetDeleteTreatment: PropTypes.func,
  loader: PropTypes.bool,
}

export default TreatmentModal
