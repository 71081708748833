import React from 'react'
import PropTypes from 'prop-types'

import TextInput from '../../forms/Input'
import SelectInput from '../../forms/Select'
import CheckboxList from '../../forms/CheckboxList'
import DateOfBirthInput from '../../forms/DateOfBirth'
import AddressLookupInput from '../../forms/AddressLookup'
import PhoneNumberInput from '../../forms/PhoneNumber'

import LegacyDataCard from '../../PatientRecord/LegacyDataCard'
import MainButton from '../../Buttons'

import * as func from '../../../utilities/ReusableFunctions'

const GeneralInfoCard = ({
  idRef,
  sectionData,
  patientData,
  clinicData,
  clinicianData,
  isDynamic,
  isEditMode,
  onFormItemChanged,
  onDOBItemChanged,
  onLegacyItemDelete,
  validations,
  ...props //eslint-disable-line
}) => {
  const getStaticFieldValue = data => {
    if (!data.func) {
      if (data.value.indexOf('.') > -1) {
        let properties = data.value.split('.')
        if (isDynamic) {
          let valueProperties = properties.slice(1).join('.')
          return patientData[properties[0]].filter(
            item => item.key === valueProperties,
          )[0].value
        }
        return patientData[properties[0]][properties[1]]
      }
      return patientData[data.value]
    } else if (data.func === 'getCheckboxItems') {
      if (
        (data.value === 'preferred_contact' ||
          data.value === 'preferred_contact_marketing') &&
        (!patientData[data.value] ||
          patientData[data.value].length === 0 ||
          patientData[data.value][0] === 0)
      ) {
        return 'No permission'
      }
      return func.createStringFromValues(
        func
          .getCheckboxTitles(data.value, patientData)
          .map(checkboxItem => checkboxItem.title),
      )
    } else if (Array.isArray(data.value)) {
      let patientValues = data.value
        .map(item => {
          if (item.indexOf('.') > -1) {
            let properties = item.split('.')
            return patientData[properties[0]][properties[1]]
          }
          return patientData[item]
        })
        .filter(patientItem => patientItem && patientItem.length > 0)
      return func[data.func](patientValues) //eslint-disable-line
    } else {
      return func[data.func](patientData, data.value) //eslint-disable-line
    }
  }

  const getFieldValue = (data, ignoreFunc) => {
    if (!data.func || ignoreFunc) {
      if (Array.isArray(data.value)) {
        return getFieldObject(data); //eslint-disable-line
      }
      if (data.value.indexOf('.') > -1) {
        let properties = data.value.split('.')
        if (isDynamic) {
          return patientData[data.value].value
        }
        if (data.value === 'clinic.name') {
          return patientData.clinic.id
        }
        return patientData[properties[0]][properties[1]]
      }
      if (data.value === 'clinician') {
        if (patientData.clinician) {
          return patientData.clinician.id
        } else {
          return -1
        }
      }
      return patientData[data.value]
    }
    if (data.func === 'getCheckboxItems') {
      return func.getCheckboxItems(data.value, patientData)
    } else if (Array.isArray(data.value)) {
      return func[data.func](getFieldObject(data)); //eslint-disable-line
    } else {
      return func[data.func](patientData, data.value) //eslint-disable-line
    }
  }

  const getFieldArray = data => { //eslint-disable-line
    let patientValues = data.value
      .map(item => {
        if (item.indexOf('.') > -1) {
          let properties = item.split('.')
          return patientData[properties[0]][properties[1]]
        }
        return patientData[item]
      })
      .filter(patientItem => patientItem && patientItem.length > 0)
    return patientValues
  }

  const getDateValue = type => {
    if (!patientData.date_of_birth || patientData.date_of_birth.length === 0) {
      return null
    }
    let values = patientData.date_of_birth.split('-')
    switch (type) {
      case 'day':
        return Number(values[2]).toString()
      case 'month':
        return Number(values[1]).toString()
      case 'year':
      default:
        return Number(values[0]).toString()
    }
  }

  const selectItemChanged = event => {
    onFormItemChanged(event.target.id, Number(event.target.value))
  }

  const formItemChanged = event => {
    onFormItemChanged(event.target.id, event.target.value)
  }

  const dobItemChanged = value => {
    onDOBItemChanged('date_of_birth', value)
  }

  const booleanItemChanged = event => {
    onFormItemChanged(
      event.target.id,
      event.target.value === 'Yes' ? true : false,
    )
  }

  const addressItemChanged = (addressId, fieldNames, addressValues) => {
    fieldNames.forEach(fieldName => {
      onFormItemChanged(fieldName, addressValues[fieldName])
    })
  }

  const checkboxItemChanged = (values, id) => {
    const checkedValues = values
      .filter(value => value.checked)
      .map(filteredValue => Number(filteredValue.id))
    onFormItemChanged(id, checkedValues)
  }

  const legacyItemDelete = event => {
    onLegacyItemDelete(false, Number(event.currentTarget.dataset.itemid))
  }

  const onLegacyDeleteAll = event => { //eslint-disable-line
    onLegacyItemDelete(true)
  }

  const getSelectItems = value => {
    if (value === 'clinic.name') {
      return clinicData.map(clinic => {
        let clinicObj = {}
        clinicObj.id = clinic.id
        clinicObj.title = clinic.name
        return clinicObj
      })
    } else if (value === 'clinician') {
      let clinicians = [
        { id: '-1', title: 'Select a clinician', isDisabled: true },
      ]
      clinicianData.forEach(clinician => {
        clinicians.push({
          id: clinician.id,
          title: func.getFullName(clinician),
        })
      })
      return clinicians
    }
    return func.getItems(value)
  }

  const getContactSelectItems = () => {
    let options = [
      {
        id: 0,
        title: 'No permission',
        isDisabled: true,
      },
    ]
    if (
      !patientData.permission_to_contact_given ||
      patientData.permission_to_contact_given.length === 0 ||
      patientData.permission_to_contact_given[0] === 0
    ) {
      return options
    } else {
      const contacts = func.getItems('preferred_contact')
      const contactOptionValues = func.getItems('permission_to_contact_groups')
      let contactValues = []
      patientData.permission_to_contact
        .map(contactItem => {
          return contactOptionValues.filter(option => {
            return option.id === contactItem
          })[0].title
        })
        .forEach(contactOption => {
          for (var i = 0; i < contactOption.length; i++) {
            contactValues.push(contactOption[i])
          }
        })
      return options.concat(
        contacts.filter(contact => contactValues.indexOf(contact.id) > -1),
      )
    }
  }

  const getStaticCardInfo = () => {
    let els = []
    sectionData.fields.forEach(data => {
      els.push(
        <div
          key={data.id}
          className={`generalInfo__card__item ${
            data.width === 3
              ? 'generalInfo__card__item--fullWidth'
              : data.width === 2
              ? 'generalInfo__card__item--2_3'
              : ''
          }`}
        >
          <label className="form__label">
            {data.title}
            {data.required ? <span className="patientImport__red">*</span> : ''}
          </label>
          <p className="disabledFormEntry">{getStaticFieldValue(data)}</p>
        </div>,
      )
    })
    return els
  }

  const getCardInfo = () => {
    let els = []
    sectionData.fields.forEach(data => {
      let formId = data.id.replace(/ /g, '')
      let validationId = formId
      if (validationId.indexOf('address') > -1) {
        validationId = validationId + '_postcode'
      }

      let isValid = true
      let showError = false
      let validation = validations[validationId]

      if (validation && !validation.isValid) {
        isValid = false
      }
      if (validation && validation.showError) {
        showError = true
      }
      let inputField
      if (data.canEdit) {
        switch (data.type) {
          case 'select':
            inputField = (
              <div
                className={`generalInfo__card__item ${
                  data.width === 3 ? 'generalInfo__card__item--fullWidth' : ''
                }`}
              >
                <SelectInput
                  id={formId}
                  options={getSelectItems(data.value)}
                  defaultSelected={
                    getFieldValue(data, true) ||
                    getSelectItems(data.value)[0].id
                  }
                  label={data.title}
                  isRequired={data.required}
                  showAsterisk={true}
                  onChange={selectItemChanged}
                />
              </div>
            )
            break
          case 'boolean':
            inputField = (
              <div
                className={`generalInfo__card__item ${
                  data.width === 3 ? 'generalInfo__card__item--fullWidth' : ''
                }`}
              >
                <SelectInput
                  id={formId}
                  options={getSelectItems(data.value)}
                  defaultSelected={func.convertBoolean(patientData, data.value)}
                  label={data.title}
                  isRequired={data.required}
                  showAsterisk={true}
                  onChange={booleanItemChanged}
                />
              </div>
            )
            break
          case 'contactSelect':
            inputField = (
              <div
                className={`generalInfo__card__item ${
                  data.width === 3 ? 'generalInfo__card__item--fullWidth' : ''
                }`}
              >
                <SelectInput
                  id={formId}
                  options={getContactSelectItems()}
                  defaultSelected={getFieldValue(data, true) || '0'}
                  label={data.title}
                  isRequired={data.required}
                  showAsterisk={true}
                  onChange={selectItemChanged}
                />
              </div>
            )
            break
          case 'phone':
            inputField = (
              <div
                className={`generalInfo__card__item ${
                  data.width === 3 ? 'generalInfo__card__item--fullWidth' : ''
                }`}
              >
                <PhoneNumberInput
                  key={formId}
                  id={formId}
                  label={data.title}
                  value={getFieldValue(data, true)}
                  placeholder={data.placeholder ? data.placeholder : ''}
                  hasOwnWrapper={true}
                  onChange={value => {
                    formItemChanged({ target: { id: formId, value: value } })
                  }}
                  required={data.required}
                  className={`form__textField `}
                  error={
                    isValid
                      ? null
                      : validation.type === 'Required'
                      ? 'Required'
                      : !isValid && showError
                      ? 'card'
                      : 'Error'
                  }
                  errorMessage={
                    isValid ? '' : showError ? validation.message : ''
                  }
                ></PhoneNumberInput>
              </div>
            )
            break
          case 'text':
            inputField = (
              <div
                className={`generalInfo__card__item ${
                  data.width === 3 ? 'generalInfo__card__item--fullWidth' : ''
                }`}
              >
                <TextInput
                  id={formId}
                  type={data.type}
                  defaultValue={
                    data.type === 'number'
                      ? getFieldValue(data, false) || ''
                      : getFieldValue(data, true) || ''
                  }
                  label={data.title}
                  placeholder={data.placeholder ? data.placeholder : ''}
                  isRequired={data.required}
                  showAsterisk={true}
                  onChange={formItemChanged}
                  error={
                    isValid
                      ? null
                      : validation.type === 'Required'
                      ? 'Required'
                      : !isValid && showError
                      ? 'card'
                      : 'Error'
                  }
                  errorMessage={
                    isValid ? '' : showError ? validation.message : ''
                  }
                  tooltipClasses={
                    isValid ? '' : showError ? validation.classes : ''
                  }
                />
              </div>
            )
            break
          case 'dateOfBirth':
            inputField = (
              <div
                className={`generalInfo__card__item ${
                  data.width === 3 ? 'generalInfo__card__item--fullWidth' : ''
                }`}
              >
                <DateOfBirthInput
                  currentDay={getDateValue('day')}
                  currentMonth={getDateValue('month')}
                  currentYear={getDateValue('year')}
                  onChange={dobItemChanged}
                  error={
                    isValid
                      ? null
                      : validation.type === 'Required'
                      ? 'Required'
                      : !isValid && showError
                      ? 'card'
                      : 'Error'
                  }
                  errorMessage={
                    isValid ? '' : showError ? validation.message : ''
                  }
                />
              </div>
            )
            break
          case 'checkboxlist':
            inputField = (
              <div
                className={`generalInfo__card__item ${
                  data.width === 3
                    ? 'generalInfo__card__item--fullWidth'
                    : data.width === 2
                    ? 'generalInfo__card__item--2_3'
                    : ''
                }`}
              >
                <CheckboxList
                  formId={formId}
                  type="checkbox"
                  inline={true}
                  titleLabel={data.title}
                  labelAbove={true}
                  checkboxes={getFieldValue(data, false)}
                  onChange={checkboxItemChanged}
                />
              </div>
            )
            break
          case 'address':
            inputField = (
              <AddressLookupInput
                fieldName={formId}
                address={func.getAddressObject(data, patientData, formId)}
                onChange={addressItemChanged}
                validations={validations}
                layoutRow={2}
                showError={showError}
              />
            )
            break
          default:
            inputField = (
              <div
                className={`generalInfo__card__item ${
                  data.width === 3 ? 'generalInfo__card__item--fullWidth' : ''
                }`}
              >
                <label className="form__label">
                  {data.title}
                  {data.required ? (
                    <span className="patientImport__red">*</span>
                  ) : (
                    ''
                  )}
                </label>
                <p className="disabledFormEntry">
                  {getFieldValue(data, false)}
                </p>
              </div>
            )
            break
        }
      }
      els.push(<React.Fragment key={formId}>{inputField}</React.Fragment>)
    })
    return els
  }

  const getLegacyCardInfo = () => {
    let els = []
    sectionData.fields.forEach(data => {
      let formId = data.id.replace(/ /g, '')
      let field = patientData.legacy_data.filter(legacyItem => {
        return legacyItem.id === Number(data.id)
      })[0]
      if (field) {
        let inputField = (
          <div className="generalInfo__card__item">
            <LegacyDataCard
              id={field.id.toString()}
              title={field.key}
              value={field.value}
              onClick={legacyItemDelete}
            />
          </div>
        )
        els.push(<React.Fragment key={formId}>{inputField}</React.Fragment>)
      }
    })
    return els
  }

  return (
    <React.Fragment>
      <article id={idRef} className="generalInfo__card">
        <div className="generalInfo__card__header generalInfo__card__header--centered generalInfo__card__header--primary secondaryMarginBottom">
          <h3 className="h3 generalInfo__card__title">{sectionData.title}</h3>
          {isEditMode && sectionData.id === 'patient_legacy' ? (
            <MainButton
              type="button"
              label="Remove all legacy data"
              styleType="destructive"
              size="small"
              onClick={onLegacyDeleteAll}
            />
          ) : (
            ''
          )}
        </div>
        <div className="generalInfo__card__content">
          {!isEditMode
            ? getStaticCardInfo()
            : sectionData.id === 'patient_legacy'
            ? getLegacyCardInfo()
            : getCardInfo()}
        </div>
      </article>
    </React.Fragment>
  )
}

GeneralInfoCard.defaultProps = {
  idRef: '',
  sectionData: null,
  patientData: null,
  clinicData: [],
  clinicianData: [],
  isDynamic: false,
  isEditMode: false,
  onFormItemChanged: () => {},
  onDOBItemChanged: () => {},
  onLegacyItemDelete: () => {},
  validations: {},
}

GeneralInfoCard.propTypes = {
  idRef: PropTypes.string,
  sectionData: PropTypes.object,
  patientData: PropTypes.object,
  clinicData: PropTypes.array,
  clinicianData: PropTypes.array,
  isDynamic: PropTypes.bool,
  isEditMode: PropTypes.bool,
  onFormItemChanged: PropTypes.func,
  onDOBItemChanged: PropTypes.func,
  onLegacyItemDelete: PropTypes.func,
  validations: PropTypes.object,
}

export default GeneralInfoCard
