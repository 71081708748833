import React from 'react'
import PropTypes from 'prop-types'

import TextInput from '../../../../forms/Input'
import TooltipCardValidator from '../../../../Tooltip/TooltipCardValidator'
import Loader from '../../../../PatientData/Loader'

import { callerIdValidators } from '../../../../../utilities/ReusableFunctions'

const CallerIdentifier = ({
  updatedCallerId,
  setTooltip,
  setCallerId,
  isTooltipOpen,
  updateCallerId,
  isFetching,
}) => (
  <div className="settings--view settings--view-lower">
    <h2>SMS Caller ID</h2>
    <p className="settings--view-desc">
      This ID is used when an SMS message is sent to the recipient of a Clinic
      Group, not per clinic. You can customise the name below, your users will
      see this when receiving updates via SMS.
    </p>
    <div style={{ width: '65%' }}>
      <TextInput
        type="text"
        value={updatedCallerId}
        onFocus={() => setTooltip(true)}
        onBlur={() => setTooltip(false)}
        onChange={({ target: { value } }) => setCallerId(value)}
        maxLength={11}
      />
      {isTooltipOpen && (
        <TooltipCardValidator
          validators={callerIdValidators(updatedCallerId)}
        />
      )}
    </div>
    <div style={{ display: 'flex' }}>
      <button
        className="buttonSecondary"
        onClick={() => updateCallerId(updatedCallerId)}
      >
        Update Caller ID
      </button>
      {isFetching ? <Loader isSetting /> : null}
    </div>
  </div>
)

CallerIdentifier.defaultProps = {
  updatedCallerId: '',
}

CallerIdentifier.propTypes = {
  updatedCallerId: PropTypes.string,
  setTooltip: PropTypes.func.isRequired,
  setCallerId: PropTypes.func.isRequired,
  isTooltipOpen: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  updateCallerId: PropTypes.func.isRequired,
}

export default CallerIdentifier
