import React from 'react'
import PropTypes from 'prop-types'

const SwitchToggle = ({ toggleState, handleChange, id }) => {
  return (
    <React.Fragment>
      <label className="switch">
        <input
          id={id}
          type="checkbox"
          checked={toggleState}
          onChange={handleChange}
        />
        <span className="slider round" />
      </label>
    </React.Fragment>
  )
}

SwitchToggle.defaultProps = {
  toggleState: false,
  handleChange: () => {},
  id: '',
}

SwitchToggle.propTypes = {
  toggleState: PropTypes.bool,
  handleChange: PropTypes.func,
  id: PropTypes.string,
}

export default SwitchToggle
