import types from '../../actions/types'

var initialState = {
  reportTable: [],
  storedReportData: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_REPORT_TABLE.FAIL:
      return { ...state }
    case types.GET_REPORT_TABLE.OK:
      return {
        ...state,
        reportTable: action.data,
      }
    case types.STORE_REPORT_DATA:
      return {
        ...state,
        storedReportData: action.data,
      }
    default:
      return state
  }
}
