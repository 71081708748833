import React from 'react'
import PropTypes from 'prop-types'
import MainButton from '../../Buttons'
import TextInput from '../../forms/Input'
import ClinicCard from './ClinicCard'
//import * as obj from '../../../utilities/ReusableObjects'
import * as func from '../../../utilities/ReusableFunctions'

const ClinicTable = ({
  clinicDetails, //eslint-disable-line
  toggleClinicModal, //eslint-disable-line
  filterStaff, //eslint-disable-line
  staffFilter, //eslint-disable-line
  clinicHeader, //eslint-disable-line
  filteredClinicians, //eslint-disable-line
  global, //eslint-disable-line
}) => {
  const sortClinicRows = () => {
    let invites = func.sortRows(clinicDetails)
    if (invites.length > 0) {
      return invites.map(clinician => {
        return <ClinicCard key={clinician.id} staffDetails={clinician} />
      })
    } else {
      return <p>No staff in this clinic</p>
    }
  }

  return (
    <React.Fragment>
      <article className="staffForm staffClinicForm">
        <div className="staffHeader">
          <div className="staffHeader__title">
            <h2 className="h1" id="staff">
              {clinicHeader.name}
            </h2>
          </div>
          <div>
            <section className="searchClinic__staffContainer--small">
              <TextInput
                type="text"
                id={clinicHeader.id + '-Search'}
                placeholder="Filter"
                leftIcon="icon-magnifying-glass"
                handleOnChange={e => filterStaff(e, clinicDetails)}
                value={staffFilter}
              />
            </section>
            {global.is_admin ? (
              <MainButton
                type="button"
                label="Manage staff"
                styleType="tertiary"
                size="small"
                leftAuto={true}
                onClick={() => toggleClinicModal(clinicDetails)}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        <hr className="primaryMarginTop" />
        <div className="staffBody staffClinic__body">
          {filteredClinicians.length > 0 && staffFilter.length > 0 ? (
            filteredClinicians.map(staff => {
              return <ClinicCard key={staff.id} staffDetails={staff} />
            })
          ) : filteredClinicians.length === 0 && staffFilter.length > 0 ? (
            <div className="noResults_message--page noResults_message--centered">
              <p>{`No staff matching '${staffFilter}'`}</p>
            </div>
          ) : (
            sortClinicRows()
          )}
        </div>
      </article>
    </React.Fragment>
  )
}

ClinicTable.defaultProps = {
  filteredClinicians: [],
  staffFilter: '',
}

ClinicTable.propTypes = {
  clinicHeader: PropTypes.object.isRequired,
  filteredClinicians: PropTypes.array,
  staffFilter: PropTypes.string,
  global: PropTypes.object.isRequired,
}

export default ClinicTable
