import React from 'react'
import PropTypes from 'prop-types'

//import * as obj from '../../../utilities/ReusableObjects'

const GeneralInfoNavigation = ({ sections, selected, onClick, ...props }) => { //eslint-disable-line
  const getInfoItems = () => {
    let navItems = []
    let navItemCount = sections.length - 1
    sections.forEach((section, index) => {
      navItems.push(
        <li key={section.id} className="generalInfo__navItem">
          <button
            id={section.id}
            className={`generalInfo__navItem__button ${
              selected.length > 0 && selected === section.id ? 'isSelected' : ''
            } ${
              index === 0
                ? 'generalInfo__navItem__button--top'
                : index === navItemCount
                ? 'generalInfo__navItem__button--bottom'
                : ''
            }`}
            onClick={onClick}
          >
            {section.title}
          </button>
        </li>,
      )
    })
    return navItems
  }

  return (
    <React.Fragment>
      <ul className="generalInfo__navList">{getInfoItems()}</ul>
    </React.Fragment>
  )
}

GeneralInfoNavigation.defaultProps = {
  sections: [],
  selected: '',
  onClick: () => {},
}

GeneralInfoNavigation.propTypes = {
  sections: PropTypes.array,
  selected: PropTypes.string,
  onClick: PropTypes.func,
}

export default GeneralInfoNavigation
