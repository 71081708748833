import React from 'react'
import PropTypes from 'prop-types'
import {
  getAppointmentStatus,
  formatDOB,
} from '../../../utilities/ReusableFunctions'
import { ReactComponent as PatientNotes } from '../../../assets/patient_notes.svg'

const CalendarAppointment = ({
  appKey,
  height,
  top,
  type,
  showAppointmentDetailModal,
  calendarType,
  app,
}) => {
  const onAppointmentKeyPress = event => {
    event.preventDefault()
    if (event.key === 'Enter') {
      showAppointmentDetailModal(app)
    }
  }

  return (
    <React.Fragment key={appKey}>
      <div
        role="button"
        tabIndex="0"
        className={`appointment_Box ${
          type === 'meeting'
            ? 'appointment_Box--meeting'
            : type === 'unavailable'
            ? 'appointment_Box--unavailable'
            : ''
        }`}
        style={{ height: height, top: top }}
        onClick={() => {
          showAppointmentDetailModal(app)
        }}
        onKeyPress={event => onAppointmentKeyPress(event)}
      >
        <span
          className={`appointment_Box_topBorder ${
            type === 'meeting' ? 'appointment_Box_topBorder--meeting' : ''
          } `}
        />
        {type === 'unavailable' ? (
          <h3 className="h4">Unavailable</h3>
        ) : type === 'meeting' ? (
          <div>
            <h3 className="h4 appointmentDetail_title">Meeting</h3>
            <p className="noMargin">{app.title}</p>
          </div>
        ) : (
          <div
            className={`appointmentDetails ${
              calendarType === 'practitionerCalendar'
                ? 'appointmentDetails_cell--practitioner'
                : getAppointmentStatus(app.status).type !== 'Pending' &&
                  height < 135
                ? 'appointmentDetails_cell--priority'
                : ''
            }`}
          >
            {calendarType === 'practitionerCalendar' ? (
              <React.Fragment>
                <h3 className="appointmentDetail_cell appointmentDetail_cell--main h4 appointmentDetails_cell_truncate appointmentDetail_cell--name appointmentDetail_cell--practitioner-row1 appointmentDetail_cell--practitioner-first">
                  {app.patient !== null
                    ? app.patient.first_name + ' ' + app.patient.last_name
                    : null}
                </h3>
                <div className="appointmentDetail_cell appointmentDetail_cell--detail appointmentDetail_cell--secondary appointmentDetails_cell_truncate appointmentDetail_cell--appointmentType appointmentDetail_cell--practitioner-row1 appointmentDetail_cell--practitioner-second">
                  {app.treatment_type ? app.treatment_type.title : ''}
                </div>
                <div className="appointmentDetail_cell appointmentDetail_cell--detail appointmentDetail_cell--secondary appointmentDetails_cell_truncate appointmentDetail_cell--treatmentType appointmentDetail_cell--practitioner-row1 appointmentDetail_cell--practitioner-third">
                  {app.treatments && app.treatments.length > 0
                    ? app.treatments
                        .map(treatment => {
                          return treatment.title
                        })
                        .join(', ')
                    : ''}
                </div>
                {getAppointmentStatus(app.status).type === 'Pending' ? (
                  ''
                ) : (
                  <div className="appointmentDetail_cell appointmentDetail_cell--main appointmentDetail_cell--status appointmentDetail_cell--practitioner-row1 appointmentDetail_cell--practitioner-fourth">
                    <div
                      className={`notifyStatus notifyStatus--${
                        getAppointmentStatus(app.status).style
                      }`}
                    >
                      {getAppointmentStatus(app.status).type}
                    </div>
                  </div>
                )}
                <div className="appointmentDetail_cell appointmentDetail_cell--detail appointmentDetail_cell--small appointmentDetails_cell_truncate appointmentDetail_cell--room appointmentDetail_cell--practitioner-row2 appointmentDetail_cell--practitioner-first">
                  {app.room !== null && app.room.name}
                  {app.room !== null && app.is_virtual && ' - '}
                  {app.is_virtual && 'Virtual'}
                </div>
                <div className="appointmentDetail_cell appointmentDetail_cell--detail appointmentDetail_cell--secondary appointmentDetails_cell_truncate appointmentDetail_cell--dob appointmentDetail_cell--practitioner-row2 appointmentDetail_cell--practitioner-second">
                  <span className="bold">DOB:</span>{' '}
                  {app.patient !== null ? formatDOB(app.patient) : null}
                </div>
                <div className="appointmentDetail_cell appointmentDetail_cell--detail appointmentDetail_cell--secondary appointmentDetails_cell_truncate appointmentDetail_cell--tel appointmentDetail_cell--practitioner-row2 appointmentDetail_cell--practitioner-third">
                  {app.patient !== null ? app.patient.mobile_phone : null}
                </div>

                <div className="appointmentDetail_cell appointmentDetail_cell--detail appointmentDetail_cell--notes appointmentDetail_cell--practitioner-row2 appointmentDetail_cell--practitioner-fourth">
                  {app.notes.length > 0 ? (
                    <div className="notifyInfo notesIndicator">
                      <span className="notifyInfo_icon icon-paperclip" />
                      <span className="notifyInfo_text">Notes</span>
                    </div>
                  ) : null}
                  {app.patient.patient_notes &&
                  app.patient.patient_notes.length > 0 ? (
                    <PatientNotes style={{ marginLeft: '8px' }} />
                  ) : null}
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <h3 className="appointmentDetail_cell appointmentDetail_cell--main h4 appointmentDetails_cell_truncate appointmentDetail_cell--name">
                  {app.patient !== null
                    ? app.patient.first_name + ' ' + app.patient.last_name
                    : null}
                </h3>
                <div className="appointmentDetail_cell appointmentDetail_cell--detail appointmentDetail_cell--lowlight appointmentDetail_cell--id">
                  {app.patient ? app.patient.id : null}
                </div>
                <div className="appointmentDetail_cell appointmentDetail_cell--detail appointmentDetail_cell--secondary appointmentDetails_cell_truncate appointmentDetail_cell--appointmentType">
                  {app.treatment_type ? app.treatment_type.title : ''}
                </div>
                <div className="appointmentDetail_cell appointmentDetail_cell--detail appointmentDetail_cell--secondary appointmentDetails_cell_truncate appointmentDetail_cell--treatmentType">
                  {app.treatments && app.treatments.length > 0
                    ? app.treatments
                        .map(treatment => {
                          return treatment.title
                        })
                        .join(', ')
                    : ''}
                </div>
                <div className="appointmentDetail_cell appointmentDetail_cell--detail appointmentDetail_cell--small appointmentDetails_cell_truncate appointmentDetail_cell--room">
                  {app.room !== null && app.room.name}
                  {app.room !== null && app.is_virtual && ' - '}
                  {app.is_virtual && 'Virtual'}
                </div>
                <div className="appointmentDetail_cell appointmentDetail_cell--detail appointmentDetail_cell--secondary appointmentDetails_cell_truncate appointmentDetail_cell--dob">
                  <span className="bold">DOB:</span>{' '}
                  {app.patient !== null ? formatDOB(app.patient) : null}
                </div>
                <div className="appointmentDetail_cell appointmentDetail_cell--detail appointmentDetail_cell--secondary appointmentDetails_cell_truncate appointmentDetail_cell--tel">
                  {app.patient !== null ? app.patient.mobile_phone : null}
                </div>
                <div className="appointmentDetail_cell appointmentDetail_cell--detail appointmentDetail_cell--notesHeader hidden">
                  Notes
                </div>
                <div className="appointmentDetail_cell appointmentDetail_cell--detail appointmentDetail_cell--notes">
                  {app.notes.length > 0 ? (
                    <div className="notifyInfo notesIndicator">
                      <span className="notifyInfo_icon icon-paperclip" />
                      <span className="notifyInfo_text">Notes</span>
                    </div>
                  ) : null}
                  {app.patient.patient_notes &&
                  app.patient.patient_notes.length > 0 ? (
                    <PatientNotes style={{ marginLeft: '8px' }} />
                  ) : null}
                </div>
                {getAppointmentStatus(app.status).type === 'Pending' ? (
                  ''
                ) : (
                  <div className="appointmentDetail_cell appointmentDetail_cell--main appointmentDetail_cell--status">
                    <div
                      className={`notifyStatus notifyStatus--${
                        getAppointmentStatus(app.status).style
                      }`}
                    >
                      {getAppointmentStatus(app.status).type}
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

CalendarAppointment.defaultProps = {
  appKey: 'key',
  height: 0,
  top: 0,
  type: 'appointment',
  calendarType: 'weeklyCalendar',
  showAppointmentDetailModal: () => {},
}

CalendarAppointment.propTypes = {
  appKey: PropTypes.string,
  height: PropTypes.number,
  top: PropTypes.number,
  type: PropTypes.oneOf(['appointment', 'meeting', 'unavailable']),
  calendarType: PropTypes.string,
  showAppointmentDetailModal: PropTypes.func,
  app: PropTypes.object.isRequired,
}

export default CalendarAppointment
