import * as obj from '../../utilities/ReusableObjects'

import types from '../../actions/types'
import {
  validatePassword,
  validatePhoneNumber,
} from '../../containers/_/Validators'
let Show
let type
let initialState = {
  account: {},
  valid: false,
  errorMessage: {
    e_errorMessage: null,
    p_errorMessage: null,
    u_errorMessage: null,
    linkError: null,
  },
  inputError: '',
  validPassword: true,
  Show: false,
  type: 'password',
  digitLength: true,
  accountCreated: false,
  required: {
    firstName_required: true,
    clinicGroup_required: true,
    surName_required: true,
    phone_required: true,
    email_required: true,
    password_required: true,
  },
}
export default (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_PASSWORD_VIEW:
      if (state) {
        Show = !state.Show
        type = state.type === 'password' ? 'input' : 'password'
      }
      return {
        ...state,
        Show: Show,
        type: type,
      }
    case types.ACCOUNT_CREATION.FAIL:
      var e_errorMessage = ''
      var u_errorMessage = ''
      var p_errorMessage = ''
      var linkError
      if (action.error.account && action.error.account.company_website) {
        linkError = action.error.account.company_website[0]
      } else {
        linkError = null
      }
      if (action.error.email) {
        e_errorMessage = action.error.email[0]
      } else {
        e_errorMessage = null
      }
      if (action.error.username) {
        u_errorMessage = action.error.username[0]
      } else {
        u_errorMessage = null
      }
      if (action.error.phone) {
        p_errorMessage = action.error.phone[0]
      } else {
        p_errorMessage = null
      }
      return {
        ...state,
        accountCreated: false,
        errorMessage: {
          linkError: linkError,
          e_errorMessage: e_errorMessage,
          u_errorMessage: u_errorMessage,
          p_errorMessage: p_errorMessage,
        },
        required: {
          firstName_required: true,
          clinicGroup_required: true,
          surName_required: true,
          phone_required: true,
          email_required: true,
          username_required: true,
          password_required: true,
        },
      }
    case types.VALIDATE_USER_DETAILS:
      var firstName_required
      var surName_required
      var phone_required
      var email_required, password_required, username_required
      var valid
      var clinicGroup_required
      var valid_website
      var account
      var validPassword

      if (action.userDetails.clinicGroup === '') {
        clinicGroup_required = false
      } else {
        clinicGroup_required = true
      }
      if (action.userDetails.first_name === '') {
        firstName_required = false
      } else {
        firstName_required = true
      }
      if (action.userDetails.last_name === '') {
        surName_required = false
      } else {
        surName_required = true
      }

      if (action.userDetails.phone !== null) {
        phone_required = validatePhoneNumber(action.userDetails.phone).valid
      } else {
        phone_required = true
      }

      if (action.userDetails.email === '') {
        email_required = false
      } else {
        email_required = true
      }
      if (action.userDetails.username === '') {
        username_required = false
      } else {
        username_required = true
      }
      if (action.userDetails.password === '') {
        password_required = false
      } else {
        password_required = true
        validPassword = validatePassword(action.userDetails.password).valid
        if (
          obj.urlRegex.test(action.userDetails.website) ||
          action.userDetails.website === ''
        ) {
          valid_website = true
        } else {
          valid_website = false
        }
      }

      if (
        firstName_required &&
        surName_required &&
        phone_required &&
        email_required &&
        username_required &&
        password_required &&
        clinicGroup_required &&
        validPassword &&
        valid_website
      ) {
        valid = true
        account = {
          company_name: action.userDetails.clinicGroup,
          company_website: action.userDetails.website,
        }
      }
      return {
        ...state,
        valid: valid,
        valid_website: valid_website,
        required: {
          firstName_required: firstName_required,
          clinicGroup_required: clinicGroup_required,
          surName_required: surName_required,
          phone_required: phone_required,
          email_required: email_required,
          username_required: username_required,
          password_required: password_required,
        },
        account: account,
        validPassword: validPassword,
      }

    default:
      return state
  }
}
