import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { TutorialContext } from '../../App'
import GraphHeaders from './GraphHeaders'
import moment from 'moment'
import { Line } from 'react-chartjs-2'
import * as func from '../../utilities/ReusableFunctions'
import * as obj from '../../utilities/ReusableObjects'
import PropTypes from 'prop-types'
import TutorialBtn from '../Tutorials/TutorialBtn'
import {
  A_GetChartDetails,
  A_ValidateFilterData,
  A_ToggleAnalyticsApplyButton,
} from '../../actions'
import { isTutorialFeatureEnabled } from '../../utilities/featureToggle'

let options
let headers

class AnalyticsChartsPannel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchText: '',
      treatmentList: [],
      graphData: null,
    }
  }

  componentDidMount = () => {
    headers = [
      {
        header: ['', 'appointmentType', 'dailyAverage', 'totalInRangeShown'],
        classTitle: ['colorSpan', 'typeSpan', 'rangeSpan', 'rangeSpan'],
      },
    ][0]
  }

  validateFilterData = () => {
    const {
      startDate,
      endDate,
      selectedClinicID,
      selectedClinicianID,
      sortID,
      actions,
    } = this.props
    let sDate = new Date(startDate)
    let eDate = new Date(endDate)
    if (Date.parse(sDate) <= Date.parse(eDate)) {
      actions.A_ValidateFilterData({
        startDate: startDate,
        endDate: endDate,
        clinicID: selectedClinicID,
        clinicianID: selectedClinicianID,
        sortID: sortID,
      })

      this.timer = setTimeout(() => {
        const { data } = this.props
        if (data.validFilterData) {
          this.applyFilters()
        }
      }, 300)
    } else {
      actions.A_ToggleAnalyticsApplyButton(true)
    }
  }

  applyFilters = () => {
    const {
      startDate,
      endDate,
      sortID,
      clinicianID,
      clinicID,
      chart,
      chartID,
      locale,
      actions,
    } = this.props
    let dataList = []

    actions
      .A_GetChartDetails(chart, {
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        clinic: clinicID,
        clinician: clinicianID,
        sort_by: sortID,
        locale: locale,
      })
      .then(res => {
        let datasets = []
        let graphData = {}
        res.order.forEach((order, o) => {
          Object.keys(res.results).forEach(list => {
            if (list === order) {
              datasets.push({
                label: func.formatLabels(order),
                type: 'line',
                borderWidth: 0,
                data: Object.keys(res.results[list]).map(
                  objKey => res.results[list][objKey],
                ),
                fill: false,
                backgroundColor:
                  chartID === 0 ? obj.fGraphColors[o] : obj.aGraphColors[o],
                borderColor:
                  chartID === 0 ? obj.fGraphColors[o] : obj.aGraphColors[o],
                lineTension: 0.1,
              })
            }
          })
        })
        let results = []
        let resultsList = Object.keys(res.results[Object.keys(res.results)[0]])
        resultsList.forEach(list => {
          if (parseInt(sortID) === 0) {
            results.push(moment(list, 'DD.MM.YYYY').format('DD.MM.YYYY'))
          } else if (parseInt(sortID) === 1) {
            let displayDate = []
            let modifiedDate = list.split('-')
            modifiedDate.forEach(mDate => {
              let data = moment(mDate).format('DD.MM.YYYY')
              displayDate.push(data)
            })
            displayDate = displayDate.join(' - ')
            results.push(displayDate)
          } else {
            results.push(list)
          }
        })
        graphData.labels = results
        graphData.datasets = datasets
        this.setState({ graphData: graphData })
        res.order.forEach(ord => {
          res.results.forEach((result, index) => {
            if (index === ord) {
              let total = 0
              result.forEach(outp => {
                total += outp
              })
              dataList.push({ [ord]: total })
            }
          })
        })

        this.setState({ dataList: dataList })
      })
  }

  UNSAFE_componentWillReceiveProps = props => {
    const { filterCount } = this.props
    if (filterCount !== props.filterCount) {
      this.validateFilterData()
    }
  }

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = 0
    }
  }

  render() {
    const { chartID } = this.props
    options = {
      responsive: true,
      legend: {
        display: true,
        position: 'top',
        labels: {
          boxWidth: 13,
          boxHeight: 1,
          padding: 15,
          borderRadius: '2px',
        },
      },
      scales: {
        xAxes: [
          {
            ticks: { autoSkip: false, maxRotation: 50, minRotation: 50 },
            fontSize: 100,
            display: true,
            gridLines: { display: true },
            //labels: { show: true },
            labels: this.state.graphData ? this.state.graphData.labels : '',
          },
        ],
        yAxes: [
          {
            type: 'linear',
            display: true,
            id: 'y-1',
            gridLines: { display: true },
            position: 'left',
            ticks: {
              beginAtZero: true,
              min: 0,
            },
          },
        ],
      },
    }
    return (
      <React.Fragment>
        {isTutorialFeatureEnabled ? (
          <TutorialContext.Consumer>
            {({
              toggleTutorial,
              setTutorial,
              tutorialList,
              history,
              activeTutorial,
            }) => (
              <TutorialBtn
                toggleTutorial={toggleTutorial}
                tutorialList={tutorialList}
                setTutorial={setTutorial}
                activeTutorial={activeTutorial}
                history={history}
              />
            )}
          </TutorialContext.Consumer>
        ) : null}
        {this.state.graphData ? (
          <article className="Appoint_Analytics">
            <h1 className="sub__heading">
              {chartID === 0 ? 'Finance' : 'Appointments'}
            </h1>
            <Line data={this.state.graphData} options={options} />
          </article>
        ) : (
          ''
        )}
        <article className="Appoint_Analytics_table">
          <div className="Appoint_Analytics_tableBody">
            {this.state.dataList ? (
              <React.Fragment>
                <GraphHeaders headers={headers} />
                <hr />
                {Object.keys(this.state.dataList).map(
                  function(index) {
                    let title = func.formatLabels(
                      Object.keys(this.state.dataList[index])[0],
                    )
                    let count = Object.keys(this.state.dataList[index]).map(
                      objKey => this.state.dataList[index][objKey],
                    )[0]
                    let averageCount = (
                      parseFloat(count) / this.state.graphData.labels.length
                    ).toFixed(3)

                    /// 12/7/2019 CHANGE TO DISPLAY TABLE CELLS UNDER HEADERS AND ADD TABLE TOTAL CLASS
                    let counter = 0
                    return (
                      <React.Fragment key={index}>
                        {parseInt(index) === this.state.dataList.length - 1 ? (
                          <hr />
                        ) : (
                          ''
                        )}
                        <div
                          className={`${
                            parseInt(index) === this.state.dataList.length - 1
                              ? 'tableRow__total'
                              : 'tableRow__plain'
                          }`}
                        >
                          <section className="tableCell colorSpan">
                            <div className="staffTable__email--container">
                              <div
                                className="boxed table_radius"
                                style={{
                                  border: `7px solid ${
                                    chartID === 0
                                      ? obj.fGraphColors[index]
                                      : obj.aGraphColors[index]
                                  }`,
                                }}
                              />
                            </div>
                          </section>
                          {headers.header
                            ? headers.header.map(function() {
                                counter++
                                let data =
                                  counter === 1
                                    ? title
                                    : counter === 2
                                    ? averageCount
                                    : counter === 3
                                    ? count
                                    : ''
                                let spanClass =
                                  counter === 1 || counter === 3 ? 'bold' : ''
                                return (
                                  <section
                                    key={counter}
                                    className={`tableCell ${headers.classTitle[counter]}`}
                                  >
                                    <div className="staffTable__email--container">
                                      <span
                                        className={`noMargin summaryTable ${spanClass}`}
                                      >
                                        {data}
                                      </span>
                                    </div>
                                  </section>
                                )
                              })
                            : ''}
                        </div>
                      </React.Fragment>
                    )
                  }.bind(this),
                )}
              </React.Fragment>
            ) : (
              ''
            )}
          </div>
        </article>
      </React.Fragment>
    )
  }
}

AnalyticsChartsPannel.defaultProps = {
  selectedClinicID: '',
  selectedClinicianID: '',
}

AnalyticsChartsPannel.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  sortID: PropTypes.number.isRequired,
  selectedClinicID: PropTypes.string,
  selectedClinicianID: PropTypes.string,
  clinicianID: PropTypes.string.isRequired,
  clinicID: PropTypes.number.isRequired,
  chart: PropTypes.string.isRequired,
  chartID: PropTypes.number.isRequired,
  filterCount: PropTypes.number.isRequired,
  locale: PropTypes.string.isRequired,
}

//export default ClinicsPage;
const mapStateToProps = state => {
  return { data: state.charts }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetChartDetails,
        A_ValidateFilterData,
        A_ToggleAnalyticsApplyButton,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AnalyticsChartsPannel),
)
