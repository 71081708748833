import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Checkbox from '../../../forms/Checkbox'
import DiscountModal from '../../DiscountModal'
import { formatCurrency } from '../../../../utilities/ReusableFunctions'

const TreatmentInvoiceRow = ({
  treatment,
  applyDiscount,
  removeDiscount,
  discount,
  toggleVat,
  toggleVatModal,
  isCosmeticConsultation,
  setVatModalId,
  locale,
}) => {
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false)

  const openModal = () => {
    setIsDiscountModalOpen(true)
  }

  const closeModal = () => {
    setIsDiscountModalOpen(false)
  }

  const subTotalValue = formatCurrency(treatment.subtotal, locale)
  const discountValue = formatCurrency(treatment.discount, locale)
  const vatValue = formatCurrency(treatment.vatAmount, locale)
  const totalValue = formatCurrency(treatment.total, locale)

  const isChecked = treatment.item.vat

  const handleCheck = () => {
    if (isCosmeticConsultation && isChecked) {
      setVatModalId(treatment.consultation_level_id)
      toggleVatModal()
    } else {
      toggleVat(treatment.consultation_level_id)
    }
  }

  let icon = 'add'

  if (discount) {
    icon = 'minus'
  }

  let buttonClass = 'icon-' + icon + ' buttonCircle'

  const handleDiscount = () =>
    isDiscountModalOpen
      ? closeModal()
      : discount
      ? removeDiscount(treatment.consultation_level_id)
      : openModal()

  return (
    <>
      <tr className="invoice__firstRow">
        <td className="bold">{treatment.name}</td>
        <td>{subTotalValue}</td>
        <td>
          <div className="invoiceCard__inline">
            {
              <>
                <span
                  role="presentation"
                  className={buttonClass}
                  onClick={handleDiscount}
                  onKeyDown={handleDiscount}
                />
                <DiscountModal
                  isDiscountModalOpen={isDiscountModalOpen}
                  closeModal={closeModal}
                  locale={locale}
                  discount={discount}
                  applyDiscount={d =>
                    applyDiscount(treatment.consultation_level_id, d)
                  }
                />
              </>
            }
            ({discountValue})
          </div>
        </td>
        <td>
          <div className="invoiceCard__inline">
            <Checkbox
              classContainer="invoiceCard__inline__vat"
              onChange={handleCheck}
              isListItem={true}
              checked={isChecked}
            />
          </div>
          {vatValue}
        </td>
        <td colSpan="2" className="bold">
          {totalValue}
        </td>
      </tr>
      {treatment.concernedAreas.map(area => (
        <tr className="invoice__secondRow" key={`${treatment.key}-${area}`}>
          <td>{area}</td>
        </tr>
      ))}
    </>
  )
}

TreatmentInvoiceRow.defaultProps = {
  discount: null,
}

TreatmentInvoiceRow.propTypes = {
  treatment: PropTypes.object.isRequired,
  discount: PropTypes.object,
  removeDiscount: PropTypes.func.isRequired,
  applyDiscount: PropTypes.func.isRequired,
  toggleVatModal: PropTypes.func.isRequired,
  setVatModalId: PropTypes.func.isRequired,
  toggleVat: PropTypes.func.isRequired,
  isCosmeticConsultation: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
}

export default TreatmentInvoiceRow
