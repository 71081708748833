import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  getInitials,
  getFullName,
  displayUserName,
} from '../../utilities/ReusableFunctions'
import { A_GetClinicianDetails } from '../../actions'
import { jobTypeNames } from '../../utilities/ReusableObjects'
import Avatar from '../../components/Avatar/'

class ProfileCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      practitioner: null,
    }

    this.gotoEditProfile = this.gotoEditProfile.bind(this)
  }

  componentDidMount() {
    const { actions } = this.props
    const { clinicianId } = this.props.match.params

    actions.A_GetClinicianDetails(clinicianId).then(response => {
      this.setState({ practitioner: response })
    })
  }

  gotoEditProfile() {
    const { history } = this.props
    const { clinicianId } = this.props.match.params
    history.push(`/clinics/profile/${clinicianId}/edit`)
  }

  render() {
    const { global } = this.props
    const { clinicianId } = this.props.match.params

    const isMe = global.currentUserID === Number(clinicianId)

    const clinician = this.state.practitioner

    return (
      <section className="userProfile userProfile--calendar">
        <article className="userProfile--calendar_form form fadeInFlyAnimate">
          <section className="form__group--stackable">
            <div className="userProfile_avatar_wrapper col_centerVertical">
              <Avatar
                src={clinician ? clinician.signed_avatar : ''}
                type="formImage"
                initials={getInitials(clinician)}
              />
              <h2
                className={`h4 multiline_wordbreak userProfile_avatar_title ${
                  clinician ? '' : 'skeletonLoad skeletonLoad--text'
                }`}
              >
                {getFullName(clinician)}
              </h2>
            </div>
          </section>
          <section className="form__group--stackable">
            <label className="form__label" htmlFor="role">
              Job role
            </label>
            <p
              className={`disabledFormEntry ${
                clinician ? '' : 'skeletonLoad skeletonLoad--text'
              }`}
            >
              {clinician ? jobTypeNames[clinician.job_type] : ''}
            </p>
            {clinician && clinician.primary_clinic ? (
              <React.Fragment>
                <label className="form__label" htmlFor="location">
                  Primary Location
                </label>
                <p
                  className={`disabledFormEntry ${
                    clinician ? '' : 'skeletonLoad skeletonLoad--text'
                  }`}
                >
                  {clinician.primary_clinic.name}
                </p>
              </React.Fragment>
            ) : (
              ''
            )}
          </section>
          <section className="form__group--stackable">
            <label className="form__label" htmlFor="role">
              Email
            </label>
            {clinician ? (
              <p className="disabledFormEntry">
                <a
                  href={`mailto:${
                    clinician && clinician.email ? clinician.email : ''
                  }`}
                >
                  <span className="textLink">
                    <span className="icon-envolope" />
                    Email{' '}
                    {clinician ? displayUserName(clinician.first_name) : ''}
                  </span>
                </a>
              </p>
            ) : (
              <p className="disabledFormEntry skeletonLoad skeletonLoad--text" />
            )}
          </section>
          <section className="form__group--stackable">
            <label className="form__label" htmlFor="phones">
              Phone(s)
            </label>
            <p
              className={`disabledFormEntry ${
                clinician ? '' : 'skeletonLoad skeletonLoad--text'
              }`}
            >
              <a
                className="textLink"
                href={`tel:${
                  clinician && clinician.main_phone ? clinician.main_phone : ''
                }`}
              >
                {clinician && clinician.main_phone ? clinician.main_phone : ''}
              </a>
            </p>
            {clinician && clinician.alternative_phone ? (
              <p
                className={`disabledFormEntry ${
                  clinician ? '' : 'skeletonLoad skeletonLoad--text'
                }`}
              >
                <a
                  className="textLink"
                  href={`tel:${clinician.alternative_phone}`}
                >
                  {clinician.alternative_phone}
                </a>
              </p>
            ) : (
              ''
            )}
          </section>
          {isMe ? (
            <section className="form__group--stackable">
              <button
                className="buttonTertiary button--small"
                onClick={this.gotoEditProfile}
              >
                Edit profile
              </button>
            </section>
          ) : (
            ''
          )}
        </article>
      </section>
    )
  }
}

ProfileCard.propTypes = {
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  global: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
  return {
    global: state.global,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetClinicianDetails,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileCard),
)
