import React from 'react'
import PropTypes from 'prop-types'
import TextInput from '../../../forms/Input'

const TreatmentTableRow = ({
  id,
  price,
  savePricingOption,
  deletePricing,
  currencyIcon,
}) => {
  return (
    <React.Fragment>
      <div className="tableRow">
        <section className="tableColumn-1Icon-1 tableCell">
          <TextInput
            type="number"
            id={id.toString()}
            noMargin={true}
            value={price}
            onChange={e => savePricingOption(e, id)}
            currencyIcon={currencyIcon}
          />
        </section>
        <section className="iconTableColumn tableCell">
          <button
            className="icon-cross buttonCircle buttonCircle--card modal__closeButton modal__closeAction"
            onClick={() => {
              deletePricing(id)
            }}
          />
        </section>
      </div>
    </React.Fragment>
  )
}

TreatmentTableRow.defaultProps = {
  id: null,
  price: 0.0,
  savePricingOption: () => {},
  deletePricing: () => {},
}

TreatmentTableRow.propTypes = {
  id: PropTypes.number,
  price: PropTypes.number,
  savePricingOption: PropTypes.func,
  deletePricing: PropTypes.func,
  currencyIcon: PropTypes.string.isRequired,
}

export default TreatmentTableRow
