import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { default as PatientTelephone } from '../Telephone'

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hideNav: true,
      animateDown: false,
      animateUp: false,
    }

    this.showNavigation = this.showNavigation.bind(this)
    this.hideNavigation = this.hideNavigation.bind(this)
    this.onAnimationComplete = this.onAnimationComplete.bind(this)
  }

  showNavigation(event) { //eslint-disable-line
    this.setState({
      animateDown: true,
      animateUp: false,
      hideNav: false,
    })
  }

  hideNavigation(event) { //eslint-disable-line
    this.setState({
      animateDown: false,
      animateUp: true,
    })
  }

  onAnimationComplete(event) { //eslint-disable-line
    if (this.state.animateUp) {
      this.setState({
        animateUp: false,
        hideNav: true,
      })
    }
  }

  render() {
    const { patient, selected } = this.props

    const sections = [
      {
        title: 'Timeline',
        types: ['treatments'],
      },
      {
        title: 'General Info',
        types: ['general-info'],
      },
      {
        title: 'Finances',
        types: ['finances', 'invoice'],
      },
      {
        title: 'Medical History',
        types: ['medical-history'],
      },
      {
        title: 'Documents',
        types: ['documents'],
      },
      {
        title: 'Notes',
        types: ['notes'],
      },
    ]

    return (
      <React.Fragment>
        <div className="nav--tool__patientNav">
          <PatientTelephone patient={patient} />
          <button
            className={`buttonCircle icon-more patientHeader__button patientHeader__button--more ${
              this.state.hideNav ? '' : 'hidden'
            }`}
            onClick={this.showNavigation}
          />
          <button
            className={`buttonCircle icon-cross patientHeader__button patientHeader__button--less ${
              this.state.hideNav ? 'hidden' : ''
            }`}
            onClick={this.hideNavigation}
          />
        </div>
        <nav
          className={`nav__patient ${
            this.state.animateUp
              ? 'slideUpAnimate--nav'
              : this.state.animateDown
              ? 'slideDownAnimate--nav'
              : this.state.hideNav
              ? 'nav__patient--hide'
              : ''
          }`}
          onAnimationEnd={this.onAnimationComplete}
        >
          <ul className="nav__patient__list">
            {sections.map(({ title, types }) => {
              return (
                <li className="nav__patient__item" key={title}>
                  <Link
                    to={`/clinics/patients/${patient.id}/${types[0]}`}
                    className={`buttonTransparent type_header_button type_header_button_nav ${
                      types.includes(selected)
                        ? 'type_header_button--selected type_header_button_nav--selected'
                        : ''
                    }`}
                  >
                    {title}
                  </Link>
                </li>
              )
            })}
          </ul>
        </nav>
      </React.Fragment>
    )
  }
}

Header.defaultProps = {
  patient: {},
  selected: 'treatments',
}

Header.propTypes = {
  patient: PropTypes.object,
  selected: PropTypes.oneOf([
    'treatments',
    'general-info',
    'finances',
    'invoice',
    'medical-history',
    'documents',
    'notes',
  ]),
}

export default withRouter(connect()(Header))
