import React from 'react'
import PropTypes from 'prop-types'

const FormLabel = ({
  noMargin,
  isGreen,
  isRed,
  isGray,
  isStackable,
  isSmall,
  text, //eslint-disable-line
  isRequired,
  isMandatory,
  id,
}) => {
  return (
    <React.Fragment>
      <label
        id={id}
        htmlFor={id}
        className={`form__textField ${noMargin ? 'noMargin' : ''} ${
          isGreen ? ' form__textField--green' : ''
        }
                ${isGray ? ' form__textField--gray' : ''} ${
          isRed ? ' form__textField--red' : ''
        } ${isStackable ? 'form__textField--stackable' : ''}
                ${isSmall ? 'form__textField--inline-small' : ''}`}
      >
        {text}
        {isMandatory ? <span className="patientImport__red">*</span> : null}
        {!isRequired ? (
          <span className="form__label--optional"> - optional</span>
        ) : (
          ''
        )}
      </label>
    </React.Fragment>
  )
}

FormLabel.defaultProps = {
  noMargin: false,
  isStackable: false,
  isSmall: false,
  isGreen: false,
  isGray: false,
  isRed: false,
  isRequired: true,
  isMandatory: false,
  id: '',
}

FormLabel.propTypes = {
  noMargin: PropTypes.bool,
  isStackable: PropTypes.bool,
  isSmall: PropTypes.bool,
  isGreen: PropTypes.bool,
  isGray: PropTypes.bool,
  isRed: PropTypes.bool,
  isRequired: PropTypes.bool,
  isMandatory: PropTypes.bool,
  id: PropTypes.string,
}

export default FormLabel
