import React from 'react'
import PropTypes from 'prop-types'
//import * as func from '../../../utilities/ReusableFunctions'
import MainButton from '../../Buttons'

const SearchResultMessage = ({ message, searchValue, button, ...props }) => { //eslint-disable-line
  return (
    <React.Fragment>
      <article className="noResults_message--page noResults_message--centered">
        <p className="p noResults_message--page secondaryMarginBottom">
          {message} {searchValue}
        </p>
        {button ? (
          <MainButton
            type="button"
            styleType={button.style}
            label={button.label}
            size="small"
            onClick={button.clickHandler}
            {...button.events}
          />
        ) : (
          ''
        )}
      </article>
    </React.Fragment>
  )
}

SearchResultMessage.defaultProps = {
  message: 'No results match your search criteria',
  searchValue: '',
  button: null,
}

SearchResultMessage.propTypes = {
  message: PropTypes.string,
  searchValue: PropTypes.string,
  button: PropTypes.object,
}

export default SearchResultMessage
