import * as treatments from '../services/TreatmentsLibrary/TreatmentLibraryService'
import types from './types'

export const A_SetTreatmentList = payload => ({
  type: types.SET_TREATMENT_LIST,
  payload,
})

export const A_SetTreatTypes = treatTypes => ({
  type: types.SET_TREAT_TYPES,
  treatTypes,
})

export const A_SetFilteredTreatmentList = payload => ({
  type: types.SET_FILTERED_LIST,
  payload,
})

export const A_SetProductSheet = value => ({
  type: types.SET_PRODUCT_SHEET,
  value,
})

export const A_SetViewingTreatment = treatment => ({
  type: types.SET_VIEWING_TREATMENT,
  treatment,
})

export const A_SetViewingTreatOption = treatoption => ({
  type: types.SET_VIEWING_TREAT_OPTION,
  treatoption,
})

export const A_UploadConsentForm = file => ({
  type: types.SET_CONSENT_FORM,
  file,
})

export const A_TreatmentUpdated = value => ({
  type: types.TREATMENT_UPDATE,
  value,
})

export const A_SetEditTreatment = value => ({
  type: types.EDIT_TREATMENT,
  value,
})

export const A_PostNewTreatment = (
  TreatmentDetails,
  primaryClinic,
) => dispatch =>
  treatments
    .postTreatmentDetails(TreatmentDetails, primaryClinic)
    .then(response => {
      dispatch({ type: types.POST_TREATMENT_DETAILS.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.POST_TREATMENT_DETAILS.FAIL, error })
      return Promise.reject(error)
    })

export const A_DeleteTreatment = (primaryClinic, TreatmentID) => dispatch =>
  treatments
    .deleteTreamentDetails(primaryClinic, TreatmentID)
    .then(response => {
      dispatch({ type: types.DELETE_TREATMENT.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.DELETE_TREATMENT.FAIL, error })
      return Promise.reject(error)
    })

export const A_DeleteConsentTreatment = (
  primaryClinic,
  TreatmentID,
) => dispatch =>
  treatments
    .deleteConsentTreamentDetails(primaryClinic, TreatmentID)
    .then(response => {
      dispatch({ type: types.DELETE_CONSENT_TREATMENT.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.DELETE_CONSENT_TREATMENT.FAIL, error })
      return Promise.reject(error)
    })

export const A_DeleteProductSheetTreatment = (
  primaryClinic,
  TreatmentID,
) => dispatch =>
  treatments
    .deleteProductSheetTreamentDetails(primaryClinic, TreatmentID)
    .then(response => {
      dispatch({ type: types.DELETE_PRODUCT_SHEET_TREATMENT.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.DELETE_PRODUCT_SHEET_TREATMENT.FAIL, error })
      return Promise.reject(error)
    })

export const A_PostEditTreatment = (
  primaryClinic,
  treatmentID,
  treatmentDetails,
) => dispatch =>
  treatments
    .patchEditTreamentDetails(primaryClinic, treatmentID, treatmentDetails)
    .then(response => {
      dispatch({ type: types.POST_TREATMENT_DETAILS.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.POST_TREATMENT_DETAILS.FAIL, error })
      return Promise.reject(error)
    })

export const A_PostNewTreatmentConsent = (
  TreatmentID,
  primaryClinic,
  consentData,
) => dispatch =>
  treatments
    .PostTreatmentConsent(TreatmentID, primaryClinic, consentData)
    .then(response => {
      dispatch({ type: types.POST_TREATMENT_CONSENT.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.POST_TREATMENT_CONSENT.FAIL, error })
      return Promise.reject(error)
    })

export const A_PostNewTreatmentProductSheet = (
  TreatmentID,
  primaryClinic,
  productsheetData,
) => dispatch =>
  treatments
    .PostTreatmentProductSheet(TreatmentID, primaryClinic, productsheetData)
    .then(response => {
      dispatch({ type: types.POST_TREATMENT_PRODUCT.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.POST_TREATMENT_PRODUCT.FAIL, error })
      return Promise.reject(error)
    })

export const A_PostNewTreatmentOption = (
  primaryClinic,
  treatmentID,
  treatmentOptionDetails,
) => dispatch =>
  treatments
    .postTreamentOptionDetails(
      primaryClinic,
      treatmentID,
      treatmentOptionDetails,
    )
    .then(response => {
      dispatch({ type: types.POST_NEW_TREATMENT_OPTION_DETAILS.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.POST_NEW_TREATMENT_OPTION_DETAILS.FAIL, error })
      return Promise.reject(error)
    })

export const A_PostEditTreatmentOption = (
  primaryClinic,
  treatmentID,
  treatOptionID,
  treatmentOptionDetails,
) => dispatch =>
  treatments
    .patchEditTreamentOptionDetails(
      primaryClinic,
      treatmentID,
      treatOptionID,
      treatmentOptionDetails,
    )
    .then(response => {
      dispatch({ type: types.PATCH_EDIT_TREATMENT_OPTION_DETAILS.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.PATCH_EDIT_TREATMENT_OPTION_DETAILS.FAIL, error })
      return Promise.reject(error)
    })

export const A_DeleteTreatmentOption = (
  primaryClinic,
  treatmentID,
  treatOptionID,
) => {
  return dispatch => {
    return treatments
      .DeleteTreamentOptionDetails(primaryClinic, treatmentID, treatOptionID)
      .then(response => {
        dispatch({
          type: types.PATCH_EDIT_TREATMENT_OPTION_DETAILS.OK,
          response,
        })
        return response
      })
      .catch(error => {
        dispatch({
          type: types.PATCH_EDIT_TREATMENT_OPTION_DETAILS.FAIL,
          error,
        })
        return Promise.reject(error)
      })
  }
}

export const A_GetEpharmProdList = (clinicID, searchKey) => {
  return dispatch => {
    return treatments
      .getEpharmProdList(clinicID, searchKey)
      .then(response => {
        dispatch({ type: types.GET_PRODUCT_LIST_EPHARM.OK, response })
        return response
      })
      .catch(error => {
        dispatch({ type: types.GET_PRODUCT_LIST_EPHARM.FAIL, error })
        return Promise.reject(error)
      })
  }
}
