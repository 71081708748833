import React from 'react'
import PropTypes from 'prop-types'

const ToolTip = ({ titleHeader, titleBody, handleCancel, handleDelete }) => (
  <div style={{ position: 'relative' }}>
    <div id="confirmCancelAlert" className="confirmTooltip hidden">
      <article className="confirmTooltip__header">
        <span>{titleHeader}</span>
      </article>
      <article className="confirmTooltip__body">{titleBody}</article>
      <article className="confirmTooltip__footer">
        <button
          type="button"
          className="buttonAlert button--small modal_footer_button confirm__remove"
          onClick={handleDelete}
        >
          Delete
        </button>
        <button
          type="button"
          className="buttonTertiary button--small modal_footer_button confirm__keep"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </article>
    </div>
  </div>
)

ToolTip.defaultProps = {
  titleHeader: '',
  titleBody: '',
  handleCancel: () => {},
  handleDelete: () => {},
}

ToolTip.propTypes = {
  titleHeader: PropTypes.string,
  titleBody: PropTypes.string,
  handleCancel: PropTypes.func,
  handleDelete: PropTypes.func,
}

export default ToolTip
