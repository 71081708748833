import * as func from '../utilities/ReusableFunctions'
import types from '../actions/types'
var current_month = func.getCurrentMonthandYear()

var initialState = {
  scheduleCalendarDateHeader: current_month,
  schedules: [],
  conflicts: [],
  showModal: false,
  deleteSuccess: false,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case types.CALENDAR_HEADER_RENDER_MONTH:
      return {
        ...state,
        scheduleCalendarDateHeader: action.payload,
        calendarDate: action.currentdate,
      }
    case types.FETCH_SCHEDULE_LIST.OK:
      return { ...state, schedules: action.list }
    case types.FETCH_SCHEDULE_LIST.FAIL:
      return { ...state }
    case types.FETCH_SCHEDULE_CONFLICT_LIST.OK:
      return { ...state, conflicts: action.list }
    case types.RESET_SCHEDULE_DELETION_PROP:
      return { ...state, deleteSuccess: false }
    case types.FETCH_SCHEDULE_CONFLICT_LIST.FAIL:
      return { ...state, conflicts: action.list }
    case types.POST_SCHEDULE_CHANGE.OK:
      return { ...state }
    case types.POST_SCHEDULE_CHANGE.FAIL:
      return { ...state }
    case types.PASS_SCHEDULE_DETAILS:
      return { ...state, showModal: action.showModal }
    case types.DELETE_BOOKED_SCHEDULE.OK:
      return { ...state, deleteSuccess: true }
    case types.CLOSE_SCHEDULE_MODAL:
      return {
        ...state,
        changeDefault: 0,
        selectedClinician: -1,
        reasons: [],
        clinics: [],
      }
    default:
      return state
  }
}
