import * as setup from '../services/AccountSetup/AccountSetupService'
import types from './types'
import { getToken } from '../services/OnBoarding/auth'

export const A_ValidateTooltipCard = input => ({
  type: types.VALIDATE_TOOLTIPCARD,
  input,
})

export const A_TogglePasswordView = () => ({ type: types.TOGGLE_PASSWORD_VIEW })

export const A_PhoneNumberValidation = phone => ({
  type: types.VALIDATE_PHONE_NUMBER,
  phone,
})

export const A_FinishAccountSetup = accountDetails => dispatch =>
  setup
    .updateAccountDetails(accountDetails)
    .then(response => {
      dispatch({ type: types.ACCOUNT_SETUP.OK, accountDetails: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.ACCOUNT_SETUP.FAIL, error })
      return Promise.reject(error)
    })

export const A_UpdatedAvatar = avatar => dispatch =>
  setup
    .updateAccountDetails(avatar)
    .then(response => {
      dispatch({ type: types.ACCOUNT_SETUP.OK, accountDetails: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.ACCOUNT_SETUP.FAIL, error })
      return Promise.reject(error)
    })

export const A_GetUpdateAvatar = () => dispatch =>
  setup
    .getUpdateAccountDetails()
    .then(response => {
      dispatch({ type: types.GET_UPDATED_DETAILS.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.ACCOUNT_SETUP.FAIL, error })
      return Promise.reject(error)
    })

export const A_GetUserDetails = () => dispatch =>
  setup
    .getUpdateAccountDetails()
    .then(response => {
      dispatch({
        type: types.LOGIN.OK,
        token: getToken(),
        user: response,
      })
      dispatch({ type: types.GET_USER_DETAILS.OK, response })
      dispatch({ type: types.ACCOUNT_SETUP.OK, accountDetails: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_USER_DETAILS.FAIL, error })
      return Promise.reject(error)
    })
