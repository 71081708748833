import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import CurrencyIcon from '../../Globalisation/CurrencyIcon'
import ProgressBar from '../../Clinic/ProgressBar'

const OpenClinicCard = ({ clinic, getclinicID }) => {
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      getclinicID(clinic.id)
    }
  }

  const address = clinic.address_town
    ? clinic.address_town + ', ' + clinic.address_postcode
    : ''

  const isSetupComplete = clinic.percentage_complete === 100

  return (
    <article
      role="presentation"
      className="card clinic clinic--existing"
      onClick={() => {
        getclinicID(clinic.id)
      }}
      onKeyPress={handleKeyPress}
    >
      <div className="clinic__header">
        <h2 className="clinic__name">{clinic.name}</h2>
        <CurrencyIcon currency={clinic.currencySymbol} />
      </div>

      {clinic && !clinic.epharm_token ? (
        <div className="clinicStrap__healthxchange">
          <p className="clinicStrap__message">Healthxchange not connected</p>
        </div>
      ) : clinic.has_quickbooks &&
        moment().tz(clinic.timezone) >
          moment(clinic.quickbooks_token.expires).tz(clinic.timezone) ? (
        <div className="clinicStrap__healthxchange">
          <p className="clinicStrap__message">Quickbooks session has expired</p>
        </div>
      ) : (
        <React.Fragment>
          <p className="clinic__address">
            <span className="clinic__addressIcon icon-map-marker-filled"></span>
            <span className="clinic__addressText">{address}</span>
          </p>
          <p className="clinic__rooms">
            <span className="bold">Rooms:</span> {clinic.rooms.length}
          </p>
        </React.Fragment>
      )}

      <img
        className="clinic__illustration"
        src="../../libs/assets/clinic-illustration.svg"
        alt="Clinic"
      />
      {!isSetupComplete && (
        <ProgressBar percentageComplete={clinic.percentage_complete} />
      )}
    </article>
  )
}

OpenClinicCard.propTypes = {
  clinic: PropTypes.object.isRequired,
  getclinicID: PropTypes.func.isRequired,
}

export default OpenClinicCard
