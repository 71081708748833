import React from 'react'
import PropTypes from 'prop-types'
import TextInput from '../../../forms/Input'
import SelectInput from '../../../forms/Select'
import SwitchToggle from '../../../forms/SwitchToggle'
import * as func from '../../../../utilities/ReusableFunctions'
import * as obj from '../../../../utilities/ReusableObjects'
import Avatar from '../../../Avatar'
import { validateEmailAddress } from '../../../../containers/_/Validators'

const StaffRow = ({
  staffDetails, //eslint-disable-line
  isEdit, //eslint-disable-line
  nodeStructures, //eslint-disable-line
  isClinicView, //eslint-disable-line
  removeStaffRow, //eslint-disable-line
  onChangeStaffRows, //eslint-disable-line
  emailError, //eslint-disable-line
  ...props
}) => {
  const jobRoleSelectOptions = userRole => {
    let options = []
    let jobTypes = func.getItems('job_type')
    let tempOptions = []
    jobTypes.map((type, index) => { //eslint-disable-line
      tempOptions.push({
        id: type.id,
        title: type.title,
        isDisabled: false,
        value: type.id,
      })
    })
    if (userRole === 1) {
      options = tempOptions.slice(0, 7)
      return options
    } else {
      options = tempOptions.slice(-4)
      return options
    }
  }

  const getDataRows = () => {
    const validator = validateEmailAddress(staffDetails.clinician.email)

    let nodes = []
    let jobOptions = jobRoleSelectOptions(staffDetails.clinician.user_type)
    nodeStructures.forEach(function(nodeStructure, index) {
      switch (nodeStructure.title) {
        case 'STAFF MEMBER':
          nodes.push(
            <section
              key={index} //eslint-disable-line
              style={{ width: nodeStructure.width + '%' }}
              className="tableCell"
            >
              {staffDetails.clinician &&
              staffDetails.clinician.email &&
              staffDetails.id ? (
                <div className="staffTable__email--container">
                  <span className="tableCell__avatar">
                    <Avatar
                      src={staffDetails.clinician.signed_avatar}
                      type="formImage"
                      initials={func.getInitials(staffDetails.clinician)}
                    />
                  </span>
                  <span className="tableCell__avatarText tableCell__avatarText--plainText">
                    <span className="staffTable__name">
                      {staffDetails.status === 1 ||
                      (staffDetails.clinician &&
                        staffDetails.clinician.invite &&
                        staffDetails.clinician.invite.status === 1) ? (
                        func.getFullName(staffDetails.clinician)
                      ) : (
                        <span className="staffTable__email">PENDING</span>
                      )}
                    </span>
                    <span className="staffTable__email">
                      {staffDetails.clinician.email}
                    </span>
                  </span>
                </div>
              ) : !isClinicView && isEdit ? (
                <TextInput
                  type="text"
                  id={'staffEmail_' + staffDetails.tempID}
                  noMargin={true}
                  placeholder="Email"
                  maxLength="50"
                  value={staffDetails.clinician.email}
                  onChange={e => onChangeStaffRows(e, staffDetails.tempID)}
                  error={
                    emailError && staffDetails.clinician.email.length === 0
                      ? 'Required'
                      : emailError && !validator.valid
                      ? validator.error
                      : ''
                  }
                />
              ) : (
                <div className="staffTable__email--container">
                  <span className="tableCell__avatar">
                    <Avatar
                      src={staffDetails.clinician.signed_avatar}
                      type="formImage"
                      initials={func.getInitials(staffDetails.clinician)}
                    />
                  </span>
                  <span className="tableCell__avatarText tableCell__avatarText--plainText">
                    <span className="staffTable__name">
                      {staffDetails.status === 1 ||
                      (staffDetails.clinician &&
                        staffDetails.clinician.invite &&
                        staffDetails.clinician.invite.status === 1) ? (
                        func.getFullName(staffDetails.clinician)
                      ) : (
                        <span className="staffTable__email">PENDING</span>
                      )}
                    </span>
                    <span className="staffTable__email">
                      {staffDetails.clinician.email}
                    </span>
                  </span>
                </div>
              )}
            </section>,
          )
          break
        case 'USER ROLE':
          nodes.push(
            <section
              key={index} //eslint-disable-line
              style={{ width: nodeStructure.width + '%' }}
              className="tableCell"
            >
              {!isClinicView &&
              isEdit &&
              staffDetails.clinician.user_type === 0 ? (
                <div className="staff__selectContainer">
                  <SelectInput
                    id={'staffRole_' + staffDetails.tempID}
                    options={func.getItems('user_type')}
                    defaultSelected={2}
                    hasOwnWrapper={true}
                    onChange={e => onChangeStaffRows(e, staffDetails.tempID)}
                  />
                </div>
              ) : !isClinicView && isEdit ? (
                <div className="staff__selectContainer">
                  <SelectInput
                    id={'staffRole_' + staffDetails.tempID}
                    options={func.getItems('user_type')}
                    defaultSelected={staffDetails.clinician.user_type}
                    hasOwnWrapper={true}
                    onChange={e => onChangeStaffRows(e, staffDetails.tempID)}
                  />
                </div>
              ) : staffDetails.clinician.user_type === 0 ? (
                obj.userTypeNames[2]
              ) : (
                obj.userTypeNames[staffDetails.clinician.user_type]
              )}
            </section>,
          )
          break
        case 'ADMIN':
          nodes.push(
            <section
              key={index} //eslint-disable-line
              style={{ width: nodeStructure.width + '%' }}
              className="tableCell"
            >
              {staffDetails.account_owner ? (
                <span className="smallLabel__card">ACCOUNT ADMIN</span>
              ) : (props.isModalRow || !isEdit) && staffDetails.is_admin ? (
                <span className="smallLabel__card">ADMIN</span>
              ) : !isClinicView && isEdit ? (
                <SwitchToggle
                  id={'adminRole_' + staffDetails.tempID}
                  toggleState={staffDetails.is_admin}
                  handleChange={e => onChangeStaffRows(e, staffDetails.tempID)}
                />
              ) : (
                ''
              )}
              {isEdit ? (
                <>
                  {props.isModalRow ? (
                    <span
                      tabIndex={0}
                      role="button"
                      className="buttonCircle icon-cross"
                      style={{ marginLeft: 'auto' }}
                      onKeyDown={e => removeStaffRow(e, staffDetails)}
                      onClick={e => removeStaffRow(e, staffDetails)}
                    />
                  ) : !staffDetails.account_owner ? (
                    <>
                      {staffDetails.id ? (
                      <span /* eslint-disable-line */
                          className="buttonCircle icon-cross button--autoLeft"
                          data-staff={staffDetails}
                          onClick={e => removeStaffRow(e, staffDetails)}
                        />
                      ) : (
                      <span /* eslint-disable-line */
                          className="buttonCircle icon-minus button--autoLeft"
                          onClick={e => removeStaffRow(e, staffDetails)}
                        />
                      )}
                    </>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                ''
              )}
            </section>,
          )
          break
        case 'JOB TITLE':
          nodes.push(
            <section
              key={index} //eslint-disable-line
              style={{ width: nodeStructure.width + '%' }}
              className="tableCell"
            >
              {!isClinicView && isEdit ? (
                <div className="staff__selectContainer">
                  <SelectInput
                    id={'jobRole_' + staffDetails.tempID}
                    options={jobOptions}
                    defaultSelected={staffDetails.clinician.job_type}
                    hasOwnWrapper={true}
                    onChange={e => onChangeStaffRows(e, staffDetails.tempID)}
                  />
                </div>
              ) : staffDetails.clinician.user_type === 0 ? (
                obj.jobTypeNames[7]
              ) : (
                obj.jobTypeNames[staffDetails.clinician.job_type]
              )}
            </section>,
          )
          break
        default:
          break
      }
    })
    return nodes
  }

  return <div className="tableRow">{getDataRows()}</div>
}

StaffRow.defaultProps = {
  isModalRow: false,
}

StaffRow.propTypes = {
  staffDetails: PropTypes.object.isRequired,
  nodeStructures: PropTypes.array.isRequired,
  isModalRow: PropTypes.bool,
}

export default StaffRow
