import React from 'react'
import PropTypes from 'prop-types'
import TextInput from '../../../forms/Input'
import TreatmentTableRow from '../../Treatment/ViewTreatment/treatmentTableRow'

let currentPricingIDval = 0
class VDefaultOpt extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      pricingArray: props.pricingArray,
      matricsArray: props.matricsArray,
      productsArray: props.productsArray,
    }
    this.handleAddPricing = this.handleAddPricing.bind(this)
    this.deletePricing = this.deletePricing.bind(this)
    this.savePricingOption = this.savePricingOption.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pricingArray && nextProps.pricingArray.length > 0) {
      this.setState({ pricingArray: nextProps.pricingArray })
    }
  }

  handleAddPricing() {
    if (this.state.pricingArray.length > 0) {
      let length = this.state.pricingArray.length - 1
      currentPricingIDval = this.state.pricingArray[length].id + 1
      this.setState({
        pricingArray: [
          ...this.state.pricingArray,
          { id: currentPricingIDval, price: '' },
        ],
      })
    } else {
      currentPricingIDval = currentPricingIDval + 1
      this.setState({
        pricingArray: [
          ...this.state.pricingArray,
          { id: currentPricingIDval, price: '' },
        ],
      })
    }
  }

  deletePricing(id) {
    let pricingArray = this.state.pricingArray
    let filteredArray = pricingArray.filter(ele => {
      return ele.id !== Number(id)
    })
    currentPricingIDval = currentPricingIDval - 1
    this.setState({ pricingArray: filteredArray })

    this.props.saveDefaultPricing(filteredArray)
  }

  savePricingOption(e, index) {
    let result = [...this.state.pricingArray]
    let length = this.state.pricingArray.length

    for (var j = 0; j < length; j++) {
      if (result[j].id === index) {
        result[j].price = e.target.value
      }
    }
    this.setState({ pricingArray: result })

    this.props.saveDefaultPricing(result)
  }

  render() {
    return (
      <React.Fragment>
        <section className="modal__body modal__body--form">
          <section className="form__group--topStackable form__groupSmallSubmit">
            <p className="p">
              <span className="h5 bold clinic__name">{this.props.title}</span>{' '}
              is a Clever Clinic treatment option. Treatment option name,
              treatment information, treatment metrics and linked products
              cannot be modified
            </p>
            <h4 className="h3">Pricing</h4>
            <p className="p">
              The different amounts a patient could be charged for this
              treatment option in your clinic
            </p>
            <div className="form__group--halfWidth__panel">
              <div className="form__group--halfWidth">
                <article className="table--withMargin">
                  <div className="tableRow tableHeader">
                    <section className="flex--noshrink tableCell tableHeadCell">
                      Price
                    </section>
                  </div>
                  {this.state.pricingArray.map(price => {
                    return (
                      <TreatmentTableRow
                        key={price.id}
                        id={price.id}
                        price={price.price}
                        savePricingOption={this.savePricingOption}
                        deletePricing={this.deletePricing}
                        currencyIcon={this.props.currencyIcon}
                      />
                    )
                  })}
                  <div className="tableRow tableFooter">
                    <button
                      className="textLink textLink--button"
                      type="button"
                      onClick={this.handleAddPricing}
                    >
                      <span className="icon-add" />
                      New pricing option
                    </button>
                  </div>
                </article>
              </div>
            </div>
          </section>

          <section className="form__group--stackable">
            <h4 className="h3">Treatment metrics</h4>
            <p className="p">
              A scale that shows how much treatment was given. E.g. Botox would
              be measured in units
            </p>
            <article className="table--withMargin">
              <div className="tableRow tableHeader">
                <section className="tableColumn-2Icon-1 tableCell tableHeadCell">
                  Option name
                </section>
                <section className="tableColumn-2Icon-1 tableCell tableHeadCell">
                  Unit price
                </section>
              </div>
              {this.state.matricsArray.map((mat, index) => {
                return (
                  <div className="tableRow" key={mat.id}>
                    <section className="tableColumn-2Icon-1 tableCell">
                      <TextInput
                        type="text"
                        id={'metricName' + index}
                        name={'metricName' + index}
                        noMargin={true}
                        value={mat.title}
                        placeholder="Units"
                        disabled="disabled"
                      />
                    </section>
                    <section className="tableColumn-2Icon-1 tableCell">
                      <TextInput
                        type="text"
                        id={'metricUnit' + index}
                        name={'metricUnit' + index}
                        value={mat.placeholder}
                        noMargin={true}
                        placeholder="1"
                        disabled="disabled"
                      />
                    </section>
                    <section className="tableColumn-6Icon-1 tableCell">
                      <span className="icon-cross buttonCircle modal__closeButton modal__closeAction" />
                    </section>
                  </div>
                )
              })}
            </article>
          </section>
          <section className="form__group--stackable">
            <span className="treatmentItem__section treatmentItem__header">
              <h4 className="h3">Linked products</h4>
              <span className="treatmentItem__header__editStatus">
                &nbsp; - Optional
              </span>
            </span>
            <p className="p">
              Healthxchange products typically used for this treatment. These
              populate the ‘Prescription’ stage of patient consultation
            </p>
            <article className="table--withMargin">
              <div className="tableRow tableHeader">
                <section className="tableColumn-2Icon-1 tableCell tableHeadCell">
                  Product Description
                </section>
                <section className="tableColumn-2Icon-1 tableCell tableHeadCell">
                  Sage stock code
                </section>
              </div>
              {this.state.productsArray.map(prod => {
                return (
                  <div key={prod.product_id} className="tableRow">
                    <section className="col__12-6">
                      <span>
                        {prod.product_description
                          ? prod.product_description
                          : prod.description
                          ? prod.description
                          : null}
                      </span>
                    </section>
                    <section className="col__12-6">
                      <span>
                        {prod.product_sageStock_Code
                          ? prod.product_sageStock_Code
                          : prod.sageStock_Code
                          ? prod.sageStock_Code
                          : null}
                      </span>
                    </section>
                  </div>
                )
              })}
            </article>
          </section>
        </section>
      </React.Fragment>
    )
  }
}

VDefaultOpt.defaultProps = {
  title: '',
  pricingArray: [],
  matricsArray: [],
  productsArray: [],
  saveDefaultPricing: () => {},
}

VDefaultOpt.propTypes = {
  title: PropTypes.string,
  pricingArray: PropTypes.array,
  matricsArray: PropTypes.array,
  productsArray: PropTypes.array,
  saveDefaultPricing: PropTypes.func,
  currencyIcon: PropTypes.string.isRequired,
}

export default VDefaultOpt
