import React from 'react'
import dateFns from 'date-fns'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'

const dateFormat = 'MMM'
const yearFormat = 'YYYY'
// const Format = 'MM'
class DatePickerRangeHHL extends React.Component {
  constructor(props) {
    super(props)
    const { selectedDate } = this.props
    this.state = {
      currentMonth: selectedDate,
      selectedDate: selectedDate,
      position: { top: '0px', left: '0px' },
    }

    this.onFocusOut = this.onFocusOut.bind(this)
    this.onDateClick = this.onDateClick.bind(this)
    this.dp = React.createRef()
  }

  getDPStyle() {
    const { position, dpInput } = this.props
    // const containerRect = container.getBoundingClientRect()
    const rect = dpInput.getBoundingClientRect()
    const boundRight = rect.right - rect.left
    // const boundCentre = (rect.bottom - rect.top) / 2
    let dpStyles = {
      top: rect.top - 12 + 'px',
      left: rect.left + boundRight + 20 + 'px',
    }
    if (window.innerWidth < 600) {
      dpStyles = {
        top: rect.top + 55 + 'px',
        left: rect.left - 20 + 'px',
      }
      if (position === 'leftBottom') {
        dpStyles = {
          bottom: rect.bottom + 55 + 'px',
          left: rect.left - 20 + 'px',
        }
      }
    }
    if (position === 'leftBottom') {
      dpStyles = {
        top: rect.top - this.dp.current.clientHeight + 55,
        left: rect.left + boundRight + 20 + 'px',
      }
    }
    this.setState({
      position: dpStyles,
    })
  }

  onFocusOut(event) {
    let target = event.relatedTarget
    if (target === null) {
      target = document.activeElement
    }
    if (this.dp.current.contains(target)) {
      return
    }
    this.hideDatePicker()
  }

  componentDidMount() {
    this.getDPStyle()
    this.dp.current.focus()
    window.addEventListener('resize', this.getDPStyle.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getDPStyle.bind(this))
  }

  handleClickOutside = () => this.props.onHide()

  renderHeader() {
    return (
      <div className="datePicker_header">
        <button
          className="buttonCircle--35 icon-chevron-left"
          onClick={e => {
            this.prevMonth(e)
          }}
        />
        <div className="datePicker_header_text">
          <span className="datePicker_header--primaryText">
            {dateFns.format(this.state.currentMonth, dateFormat)}{' '}
          </span>
          <span className="datePicker_header--secondaryText">
            {dateFns.format(this.state.currentMonth, yearFormat)}
          </span>
        </div>
        <button
          //ref="forwardMonth"
          className="buttonCircle--35 icon-chevron-right"
          onClick={e => {
            this.nextMonth(e)
          }}
        />
      </div>
    )
  }

  renderDays() {
    const dateFormat = 'dd'
    const days = []

    let startDate = dateFns.startOfWeek(this.state.currentMonth, {
      weekStartsOn: 1,
    })

    for (let i = 0; i < 7; i++) {
      days.push(
        <th key={i}>
          <span className="datePicker_day">
            {' '}
            {dateFns.format(dateFns.addDays(startDate, i), dateFormat)}{' '}
          </span>
        </th>,
      )
    }

    return (
      <thead className="datePicker_body">
        <tr className="datePicker_week_wrapper">{days}</tr>
      </thead>
    )
  }

  renderCells() {
    const { startDate, endDate } = this.props
    const { currentMonth, selectedDate } = this.state
    const monthStart = dateFns.startOfMonth(currentMonth)
    const monthEnd = dateFns.endOfMonth(monthStart)
    const startDateVal = dateFns.startOfWeek(monthStart, { weekStartsOn: 1 })
    const endDateVal = dateFns.endOfWeek(monthEnd)

    const dateFormat = 'D'
    const rows = []

    let days = []
    let day = startDateVal
    let formattedDate = ''
    const noRange = dateFns.isSameDay(startDate, endDate)

    while (day <= endDateVal) {
      for (let i = 0; i < 7; i++) {
        formattedDate = dateFns.format(day, dateFormat)
        const cloneDay = day
        const inRange =
          (startDate === selectedDate && day > selectedDate && day < endDate) ||
          (endDate === selectedDate && day > startDate && day < selectedDate)

        let buttonClassList = 'datePicker_number'
        let containerClassList = 'datePicker_number_container'
        if (
          dateFns.isSameDay(day, startDate) ||
          dateFns.isSameDay(day, endDate)
        ) {
          buttonClassList += ' datePicker_number--selected'
        }
        if (!noRange && dateFns.isSameDay(day, startDate)) {
          buttonClassList += ' datePicker_number--selected-start'
          containerClassList += ' datePicker_number_container--start'
        }
        if (!noRange && dateFns.isSameDay(day, endDate)) {
          buttonClassList += ' datePicker_number--selected-end'
          containerClassList += ' datePicker_number_container--end'
        }
        if (
          inRange &&
          !dateFns.isSameDay(day, startDate) &&
          !dateFns.isSameDay(day, endDate)
        ) {
          buttonClassList += ' datePicker_number--range'
          containerClassList += ' datePicker_number_container--range'
        }
        if (
          !dateFns.isSameMonth(day, monthStart) &&
          !dateFns.isSameDay(day, startDate) &&
          !dateFns.isSameDay(day, endDate)
        ) {
          buttonClassList += ' datePicker_number--fade'
        }
        if (dateFns.isToday(day)) {
          buttonClassList += ' datePicker_number--today'
        }
        days.push(
          <td
            role="presentation"
            key={day}
            onClick={e => {
              this.onDateClick(dateFns.parse(cloneDay), e)
            }}
            className={containerClassList}
          >
            <button className={buttonClassList}>{formattedDate}</button>
          </td>,
        )
        day = dateFns.addDays(day, 1)
      }
      rows.push(
        <tr className="datePicker_week_wrapper" key={day}>
          {days}
        </tr>,
      )
      days = []
    }
    return <tbody>{rows}</tbody>
  }

  onDateClick = day => {
    this.hideDatePicker(day)
  }

  nextMonth = e => {
    e.preventDefault()
    this.setState({
      currentMonth: dateFns.addMonths(this.state.currentMonth, 1),
    })
  }

  prevMonth = e => {
    e.preventDefault()
    this.setState({
      currentMonth: dateFns.subMonths(this.state.currentMonth, 1),
    })
  }

  hideDatePicker(day) {
    const { onHide } = this.props
    onHide(day)
  }

  render() {
    const { position, calendarType } = this.props
    let scheduleClasses =
      'fadeInAnimateFull datePicker--appointment datePicker--fixed'
    switch (position) {
      case 'leftTop':
        scheduleClasses += ' datePicker--leftTop'
        break
      case 'leftBottom':
        scheduleClasses += ' datePicker--leftBottom'
        break
      default:
        break
    }
    return (
      <div
        ref={this.dp}
        onBlur={e => this.onFocusOut(e)}
        style={this.state.position}
        className={`datePicker datePicker--open datePicker--popup ${
          calendarType === 'manage_schedule_range' ? scheduleClasses : ''
        }`}
      >
        {this.renderHeader()}
        <table className="datePicker_body">
          {this.renderDays()}
          {this.renderCells()}
        </table>
      </div>
    )
  }
}

DatePickerRangeHHL.propTypes = {
  selectedDate: PropTypes.isRequired,
  position: PropTypes.isRequired,
  dpInput: PropTypes.isRequired,
  startDate: PropTypes.isRequired,
  endDate: PropTypes.isRequired,
  onHide: PropTypes.isRequired,
  calendarType: PropTypes.isRequired,
}

export default withRouter(onClickOutside(DatePickerRangeHHL))
