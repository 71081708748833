import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as PlayButton } from '../../../assets/play-button.svg'
import { hasNumber } from '../../../utilities/ReusableFunctions'

const position = (right, bottom) => ({
  right: `${right}px`,
  bottom: `${bottom}px`,
})

const TutorialBtn = ({
  right,
  bottom,
  toggleTutorial,
  setTutorial,
  tutorialList,
  history,
  activeTutorial,
}) => {
  useEffect(() => {
    const {
      location: { pathname },
    } = history

    const clinics = '/clinics'
    const clinicSummary = hasNumber.test(`${clinics}/${pathname.split('/')[2]}`)
    const clinicEdit = hasNumber.test(`${clinics}/${clinicSummary}/edit`)

    const pathChecker =
      pathname === clinics || clinicSummary || clinicEdit ? 1 : 2

    const pathId = pathname.split('/')[pathChecker]

    setTutorial(tutorialList.find(tutorial => tutorial.key === pathId))
  })

  return activeTutorial !== undefined ? (
    <button
      className="tutorialBtn"
      style={position(right, bottom)}
      onClick={() => toggleTutorial(true)}
    >
      <PlayButton />
    </button>
  ) : (
    ''
  )
}

TutorialBtn.propTypes = {
  history: PropTypes.object,
  right: PropTypes.number,
  bottom: PropTypes.number,
  tutorialList: PropTypes.array.isRequired,
  setTutorial: PropTypes.func.isRequired,
  toggleTutorial: PropTypes.func.isRequired,
  activeTutorial: PropTypes.object,
}

TutorialBtn.defaultProps = {
  history: null,
  right: 25,
  bottom: 25,
  activeTutorial: undefined,
}

export default TutorialBtn
