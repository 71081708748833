import types from '../../actions/types'

var initialState = {
  data: {},
  reqEndDate: true,
  reqClinic: true,
  reqClinician: true,
  reqSortID: true,
  validFilterData: false,
  inValidData: false,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_GRAPH_DETAILS.OK:
      return {
        ...state,
      }
    case types.GET_GRAPH_DETAILS.FAIL:
      return {
        ...state,
      }
    case types.VALIDATE_FILTER_DATA: {
      let validFilterData
      let clinic = action.data.clinicID !== -1 ? true : false
      let clinician = action.data.clinicianID !== -1 ? true : false
      let sortID = action.data.sortID !== -1 ? true : false
      let endDate = action.data.endDate ? true : false
      if (clinic && clinician && sortID && endDate) {
        validFilterData = true
      } else {
        validFilterData = false
      }
      return {
        ...state,
        reqEndDate: endDate,
        reqClinic: clinic,
        reqClinician: clinician,
        reqSortID: sortID,
        validFilterData: validFilterData,
        inValidData: false,
      }
    }

    case types.DISABLE_ANALYTICS_APPLY_BUTTON:
      return {
        inValidData: action.bool,
      }
    default:
      return state
  }
}
