import React from 'react'
import errorIcon from '../../../assets/error.svg'
import addIcon from '../../../assets/add.svg'
import Button from '../../Buttons'

// CHANGE 9/7/2019 leftAuto REMOVED from <a> TAG
const ImportReview = props => {
  return (
    <form className="form patientImport__form">
      <section className="patientImport__review">
        <h2 className="h2">Patient import complete</h2>
        <p className="p">
          Download the CSV produced to manage errors and choose ‘New import’ to
          try again
        </p>
        <section className="patientImport__reviewContainer">
          <div className="patientImport__reviewRow">
            <img src={addIcon} alt="" className="patientImport__reviewIcon" />
            <h3 className="bold">
              {props.successCount /* eslint-disable-line */} Rows successfully imported
            </h3>
            {/* <Button styleType="tertiary" size="small" leftAuto={true} label="Download CSV" /> */}
          </div>
        </section>
        <section className="patientImport__reviewContainer">
          <div className="patientImport__reviewRow">
            <img src={errorIcon} alt="" className="patientImport__reviewIcon" />
            <h3 className="bold">
              {props.errorCount /* eslint-disable-line */} Errors{' '}
              <span className="p">(not imported)</span>
            </h3>
            {props.errorCount > 0 ? ( //eslint-disable-line
              <a
                className=" button--autoLeft"
                href={props.downloadCsv /* eslint-disable-line */}
                download="errors_file" /*leftAuto={true}*/
              >
                <Button
                  styleType="tertiary"
                  size="small"
                  leftAuto={true}
                  label="Download CSV"
                  onClick={props.handleFileDownload /* eslint-disable-line */}
                />
                {/* buttonTertiary button--small button--autoLeft */}
              </a>
            ) : null}
          </div>
        </section>
        <br />
        <Button
          styleType="secondary"
          size="small"
          label="New Import"
          onClick={props.newImport /* eslint-disable-line */}
        />
      </section>
    </form>
  )
}

export default ImportReview
