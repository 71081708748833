import types from '../../actions/types'
import moment from 'moment'

const slotsMapping = slots => {
  return slots.map(slot => {
    const startDate = moment.tz(slot.start_date, 'UTC')
    const endDate = moment.tz(slot.end_date, 'UTC')

    return {
      ...slot,
      slot: `${startDate.format('HH:mm')} - ${endDate.format('HH:mm')}`,
      startDate: startDate,
      endDate: endDate,
      rooms: slot.rooms.map(({ id, name }) => ({
        id,
        title: name,
      })),
      duration: moment.duration(endDate.diff(startDate)).asMinutes(),
    }
  })
}

var initialState = {
  slot_length: 0,
  clinicPatientList: [],
  showPatientList: false,
  patientSelected: false,
  patient: true,
  validPatient: true,
  treatments: [],
  additional_equipment: [],
  selectedDate: '',
  appointment_type: true,
  treatment: true,
  equipment: true,
  treatment_type: true,
  notes: true,
  valid: true,
  room: true,
  clinicID: '',
  treatmentTypeList: [],
  basicAppointmentData: [],
  filteredTreatments: [],
  timezone: '',
  patientDetails: null,
  slots: [],
  gaps: [],
  isFetchingPatient: false,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_BASIC_DETAILS.OK:
      var equipmentList = []

      action.data.rooms.forEach(function(room) {
        equipmentList = [
          ...equipmentList,
          room.equipment.filter(function(equipment) {
            return equipment.is_permanent === false
          }),
        ]
      })

      return {
        ...state,
        additional_equipment: equipmentList,
        basicAppointmentData: action.data,
        timezone: action.data.timezone,
      }
    case types.GET_BASIC_DETAILS.FAIL:
      return {
        ...state,
        initialState: action.state,
      }
    case types.GET_FILTERED_PATIENTS_LIST.REQ:
      return {
        ...state,
        isFetchingPatient: true,
      }
    case types.GET_FILTERED_PATIENTS_LIST.OK:
      return {
        ...state,
        isFetchingPatient: false,
        clinicPatientList: action.data.results,
        showPatientList: true,
      }
    case types.GET_FILTERED_PATIENTS_LIST.FAIL:
      return {
        ...state,
        showPatientList: false,
      }
    case types.GET_FILTERED_PATIENTS_LIST.CLEAR:
      return {
        ...state,
        clinicPatientList: [],
      }
    case types.SELECT_PATIENT:
      return {
        ...state,
        showPatientList: false,
        patientSelected: true,
        patient: true,
        validPatient: true,
      }

    case types.VALIDATE_SELECTED_PATIENT:
      var patient
      var validPatient
      var patientSelected
      if (action.patient.length > 0) {
        patient = true
        if (patientSelected) {
          validPatient = true
        } else {
          validPatient = false
        }
      } else {
        patient = false
        patientSelected = false
      }
      return {
        ...state,
        patient: patient,
        validPatient: validPatient,
        patientSelected: patientSelected,
        showPatientList: false,
      }

    case types.FETCH_TREATMENT_LIST.OK:
      return {
        ...state,
        treatments: action.list,
      }
    case types.FETCH_TREATMENT_LIST.FAIL:
      return {
        ...state,
      }
    case types.FETCH_CONSULTATION_TREATMENT_LIST.OK:
      return {
        ...state,
        filteredTreatments: action.list,
      }
    case types.FETCH_CONSULTATION_TREATMENT_LIST.CLEAR:
      return {
        ...state,
        filteredTreatments: [],
      }
    case types.FETCH_CONSULTATION_TREATMENT_LIST.FAIL:
      return {
        ...state,
      }
    case types.FETCH_TREATMENT_TYPE_LIST.OK:
      return {
        ...state,
        treatmentTypeList: action.list,
      }
    case types.FETCH_TREATMENT_TYPE_LIST.FAIL:
      return {
        ...state,
      }
    case types.STORE_SLOT_LENGTH:
      return {
        ...state,
        slot_length: action.minutes,
      }

    case types.GET_AVALIABLE_SLOTS_LIST.OK:
      if (action.minutes !== state.slot_length) {
        return state
      }

      return {
        ...state,
        slots: slotsMapping(action.list.slots),
        gaps: action.list.gaps.map(gap => {
          return {
            ...gap,
            slots: slotsMapping(gap.slots),
          }
        }),
      }
    case types.GET_AVALIABLE_SLOTS_LIST.CLEAR:
      return {
        ...state,
        slots: [],
        gaps: [],
      }
    case types.GET_AVALIABLE_SLOTS_LIST.FAIL:
      return {
        ...state,
      }
    case types.PASS_STANDALONE_APPOINTMENT_DETAILS:
      return {
        ...state,
        ...action.appointmentData.appointmentDetails,
        showAppointmentModal: true,
        clinicID:
          action.appointmentData.appointmentDetails &&
          action.appointmentData.appointmentDetails.clinic
            ? action.appointmentData.appointmentDetails.clinic.id
            : '',
        patientDetails: action.appointmentData.patientDetails,
      }

    default:
      return state
  }
}
