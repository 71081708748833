import React from 'react'

export default function NoSlots() {
  return (
    <article className="noResults_message noResults_message--allow-scroll">
      <span className="form__label--error">
        No appointments available, please change the date or appointment
        duration to find available appointment slots.
      </span>
    </article>
  )
}
