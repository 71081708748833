import Promise from 'promise'
import * as api from '../api'
import * as url from '../../api_urls'

export const getCliniciansList = () => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.clinicians)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const patchClinicInvite = (id, invites) => {
  return new Promise((resolve, reject) => {
    return api
      .patch(url.clinics + id + '/', invites[0])
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const patchClinicianInvite = clinicData => {
  return new Promise((resolve, reject) => {
    return api
      .patch(url.clinicAccount, clinicData)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
