import React from 'react'
import PropTypes from 'prop-types'
import TutorialItem from '../TutorialItem'

const TutorialList = ({ videos, setVideo, setVideosStatus }) => {
  const toggleVideoStatus = title => {
    const newVids = [...videos]
    let activeVideo = videos.find(video => video.title === title)
    activeVideo.isPlaying = !activeVideo.isPlaying
    Object.assign(
      newVids[newVids.findIndex(el => el.title === activeVideo.title)],
      activeVideo,
    )
    setVideosStatus(newVids)
  }

  return (
    <div style={{ height: '300px', width: '100%' }}>
      {videos.map(video => (
        <TutorialItem
          key={video.title}
          video={video}
          setVideo={setVideo}
          toggleVideoStatus={toggleVideoStatus}
        />
      ))}
    </div>
  )
}

TutorialList.propTypes = {
  videos: PropTypes.array.isRequired,
  setVideo: PropTypes.func.isRequired,
  setVideosStatus: PropTypes.func.isRequired,
}

export default TutorialList
