import React from 'react'
import PropTypes from 'prop-types'
import Form from '../../_/Form'
import Steps from '../../_/Steps'
import Address from '../../_/Address'
import OpeningHours from '../../_/OpeningHours'
import Rooms from '../../_/Rooms'
import Staff from '../../_/Staff'
import MainButton from '../../../components/Buttons'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  validateNotEmpty,
  validateEmailAddress,
  validatePhoneNumber,
} from '../../_/Validators'
import {
  A_GetAccessURL_QB,
  A_Delete_QB,
  A_GetTaxCodes_Vat_QB,
  A_GetDepartment_QB,
  A_FetchClinicDetails,
  A_PatchUpdatedClinicDetails,
  A_UpdatedInvoiceLogo,
  A_PostClinicDetails,
} from '../../../actions'
import * as func from '../../ModalOverlay'
import Loader from '../../../components/PatientData/Loader'
import * as allFunc from '../../../utilities/ReusableFunctions'
import ClinicSetupFinanceCard from '../../../components/Clinic/Finance/index'
import Modal from '../../../components/Modal'
import ModalInfo from '../../../components/Modal/Info'
import moment from 'moment'
import { TutorialContext } from '../../../App'
import TutorialBtn from '../../../containers/Tutorials/TutorialBtn'
import { isTutorialFeatureEnabled } from '../../../utilities/featureToggle'
import { ClinicLocaleTypes } from '../../../utilities/ReusableObjects'
import UploadFile from '../../../components/forms/fileUpload'

class EditSummaryPage extends Form {
  constructor(props) {
    super(props)

    props.clinicSummary.clinicInfo = {
      id: 0,
      name: '',
      email: '',
      main_phone: '',
      alternative_phone: '',
      address_line_one: '',
      address_line_two: '',
      address_town: '',
      address_county: '',
      address_postcode: '',
      address_country: 'United Kingdom',
      opening_hours: allFunc.mapOpeningTimesDays(),
      rooms: [],
      invites: [],
      timezone: moment.tz.guess(),
      locale: ClinicLocaleTypes.en_GB,
      appointment_document: null,
    }

    this.state = {
      ...this.state,
      required:
        'Please enter a valid opening and closing time in the format HH:MM',
      error: 'Closing time should be later than opening time',
      navUrl: '',
      changeSpan: false,
      dataLoaded: false,
      VAT: 0,
      showInfoModal: false,
      VatList: [],
      DeptList: [],
      clinicLogo: '',
      clinicLogoFD: null,
      appointmentDocumentFD: null,
      appointmentDocumentName: '',
      step: 1,
    }
  }

  componentDidMount = () => {
    const { actions, clinicSummary } = this.props
    const { clinicId } = this.props.match.params

    if (parseInt(clinicId)) {
      actions.A_FetchClinicDetails(clinicId).then(response => {
        const clinicInfo = response

        this.setState(
          {
            data: {
              ...clinicInfo,
              invites: allFunc.sortRows(clinicInfo.invites),
            },
            clinicLogo: clinicInfo.signed_invoice_header_logo,
            dataLoaded: true,
          },
          () => {
            if (
              clinicInfo.has_quickbooks &&
              moment().tz(clinicInfo.timezone) <
                moment(clinicInfo.quickbooks_token.expires).tz(
                  clinicInfo.timezone,
                )
            ) {
              this.loadTaxCodes(clinicId)
              this.loadDepartments(clinicId)
            }
          },
        )
      })
    } else {
      this.setState({
        data: clinicSummary.clinicInfo,
        dataLoaded: true,
      })
    }

    const sidebarLinks = document.getElementsByClassName('sidebar')[0].children
    const navbarLinks = document.getElementsByClassName(
      'nav__profileOptions',
    )[0].children

    Object.values(sidebarLinks).forEach(link => {
      link.addEventListener(
        'click',
        function(event) {
          this.setState({ navUrl: link.getAttribute('href') })
          if (this.state.updated) {
            event.preventDefault()
            func.showModal('saveChangesModal')
          }
        }.bind(this),
      )
    })

    Object.values(navbarLinks).forEach(navLink => {
      navLink.addEventListener(
        'click',
        function(event) {
          this.setState({ navUrl: navLink.getAttribute('href') })
          if (this.state.updated) {
            event.preventDefault()
            func.showModal('saveChangesModal')
          }
        }.bind(this),
      )
    })
  }

  loadTaxCodes = (clinicId, attempt = 1) => {
    const { actions } = this.props
    actions.A_GetTaxCodes_Vat_QB(clinicId).then(response => {
      let VatValues = this.getVatValues(response)

      if (VatValues.length === 1 && attempt < 3) {
        setTimeout(() => {
          this.loadTaxCodes(clinicId, attempt + 1)
        }, 300)
        return
      } else {
        this.setState({
          VatList: VatValues,
        })
      }
    })
  }

  loadDepartments = (clinicId, attempt = 1) => {
    const { actions } = this.props

    actions.A_GetDepartment_QB(clinicId).then(response => {
      let Deptvalues = this.getDepartment(response)

      if (Deptvalues.length === 1 && attempt < 3) {
        setTimeout(() => {
          this.loadDepartments(clinicId, attempt + 1)
        }, 300)
        return
      } else {
        this.setState({
          DeptList: Deptvalues,
        })
      }
    })
  }

  getVatValues = VatVals => {
    var actualVatArray = []
    actualVatArray.push({
      id: '-1',
      title: 'Please select...',
      isDisabled: false,
    })

    VatVals.forEach(vatVal => {
      if (vatVal.rates && vatVal.rates.length > 0) {
        vatVal.rates.forEach(rate => {
          rate.id = rate.rate_id
          rate.title = `${rate.rate_name} - ${rate.vat}%`
          rate.isDisabled = false

          actualVatArray.push(rate)
        })
      }
    })

    return actualVatArray
  }

  displayEquipModal = () => {
    this.setState({ displayEquipModal: true })
  }

  closeEquipModal = () => {
    func.closeOverlayModal()
    this.timer = setTimeout(() => {
      this.setState({ displayEquipModal: false })
    }, 600)
  }

  getDepartment = Departments => {
    var modifiedArray = []
    modifiedArray.push({
      id: '-1',
      title: 'Please select...',
      isDisabled: false,
    })
    Departments.forEach(element => {
      element.title = element.name
      element.isDisabled = false
      modifiedArray.push(element)
    })

    return modifiedArray
  }

  handlepostcallbackURl = e => {
    const { actions, callback_url } = this.props
    const { clinicId } = this.props.match.params
    e.preventDefault()

    let currentUrl = {
      callback_url: callback_url,
    }

    actions.A_GetAccessURL_QB(clinicId, currentUrl).then(response => {
      window.location.href = response.url
    })
  }

  handleDeleteQB = () => {
    const { actions } = this.props
    const { clinicId } = this.props.match.params
    actions.A_Delete_QB(clinicId).then(() => {
      this.setState({
        updated: true,
        data: {
          ...this.state.data,
          quickbooks_token: null,
          has_quickbooks: false,
        },
      })
    })
  }

  cancelModifications = () => {
    const { history } = this.props
    const { clinicId } = this.props.match.params
    this.setState({ updated: false }, () => {
      history.push(`/clinics/${clinicId}`)
    })
  }
  discardModifications = () => {
    const { history } = this.props
    func.closeOverlayModal()
    this.setState({ updated: false }, () => {
      var url = this.state.navUrl
      this.timer1 = setTimeout(() => {
        history.push(url) //eslint-disable-line
      }, 650)
    })
  }
  saveModifications = () => {
    func.closeOverlayModal()
  }
  SetupdatedKey = () => {
    this.setState({ updated: true })
  }

  handleChangeVAT = event => {
    let vatAmount
    // for default selected
    let selectedVat
    // for server call
    let selectedVatItem
    if (Number.isNaN(Number(event.target.value)) || event.target.value === '') {
      vatAmount = 0
    } else {
      vatAmount = parseFloat(event.target.value)

      selectedVat = event.target.value
      selectedVatItem = this.state.VatList.find(function(vat) {
        return vat.id === selectedVat
      })
    }

    if (selectedVatItem) {
      this.setState({
        data: {
          ...this.state.data,
          tax_code_id: selectedVat,
          vat: selectedVatItem.vat,
          tax_code_name: selectedVatItem.rate_name,
        },
      })
    } else {
      this.setState({
        data: {
          ...this.state.data,
          tax_code_id: null,
          vat: vatAmount,
          tax_code_name: null,
        },
      })
    }
  }

  handleChangeDepartment = event => {
    let selectedDeptItem = this.state.DeptList.find(function(item) {
      return item.id === event.target.value
    })

    if (selectedDeptItem) {
      this.setState({
        data: {
          ...this.state.data,
          quickbooks_token: {
            ...this.state.data.quickbooks_token,
            department_id: selectedDeptItem.id,
            department_name: selectedDeptItem.name,
          },
        },
      })
    } else {
      this.setState({
        data: {
          ...this.state.data,
          quickbooks_token: {
            ...this.state.data.quickbooks_token,
            department_id: null,
            department_name: null,
          },
        },
      })
    }
  }

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = 0
    }
    if (this.timer1) {
      clearTimeout(this.timer1)
      this.timer1 = 0
    }
  }

  selectAppointmentDocument = async imageSource => {
    let file = imageSource.files[0]
    if (file.type === 'application/pdf') {
      var appointmentDocumentFD = new FormData()
      appointmentDocumentFD.append('appointment_document', file)
      this.setState({
        appointmentDocumentFD: appointmentDocumentFD,
        appointmentDocumentName: file.name,
      })
    }
  }

  clearAppointmentDocument = () => {
    this.setState({
      appointmentDocumentFD: null,
      appointmentDocumentName: '',
      data: {
        ...this.state.data,
        appointment_document: null,
        signed_appointment_document: null,
      },
    })
  }

  selectLogoPreview = async imageSource => {
    let file = imageSource.files[0]
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      var clinicLogoFD = new FormData()
      clinicLogoFD.append('invoice_header_logo', file)
      this.setState({ clinicLogoFD: clinicLogoFD }, () => {
        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = e => {
          this.setState({ clinicLogo: e.target.result })
        }
      })
    }
  }

  clearLogoPreview = () => {
    this.setState({
      clinicLogo: null,
      clinicLogoFD: null,
      data: {
        ...this.state.data,
        invoice_header_logo: null,
        signed_invoice_header_logo: null,
      },
    })
  }

  previousStep = () => {
    this.setState({ step: this.state.step - 1 })
  }

  nextStep = () => {
    if (this.handleValidation()) {
      this.setState({ step: this.state.step + 1 })
    } else {
      window.scroll(0, 0)
    }
  }

  handleResponse = response => {
    const { history } = this.props

    if (response.id) {
      history.push(`/clinics/${response.id}`)
      return
    }
    if (response.data) {
      this.setState({ dataLoaded: true })
      alert(response.data.non_field_errors[0])
    }
  }

  saveClinic = () => {
    const { actions } = this.props
    let { data } = this.state

    if (this.handleValidation()) {
      this.setState({ dataLoaded: false }, () => {
        if (data.id !== 0) {
          const { clinicLogoFD, appointmentDocumentFD } = this.state

          delete data.invoice_header_logo
          delete data.signed_invoice_header_logo

          if (clinicLogoFD !== null) {
            actions.A_UpdatedInvoiceLogo(data.id, clinicLogoFD)
          }

          delete data.appointment_document
          delete data.signed_appointment_document

          if (appointmentDocumentFD !== null) {
            actions.A_UpdatedInvoiceLogo(data.id, appointmentDocumentFD)
          }

          actions.A_PatchUpdatedClinicDetails(data).then(response => {
            this.handleResponse(response)
          })
        } else {
          actions.A_PostClinicDetails(data).then(response => {
            this.handleResponse(response)
          })
        }
      })
    }
  }

  deleteClinicRoom = () => {
    const { clinicSummary } = this.props
    let { data } = this.state

    func.closeOverlayModal()

    this.setState(
      {
        data: {
          ...data,
          rooms: data.rooms.filter(function(r, id) {
            return id !== clinicSummary.selectedIndex
          }),
        },
      },
      () => {
        clinicSummary.selectedIndex = -1
      },
    )
  }

  toggleInfoModal() {
    this.setState({
      showInfoModal: !this.state.showInfoModal,
    })
  }

  render() {
    const {
      data,
      step,
      dataLoaded,
      appointmentDocumentFD,
      appointmentDocumentName,
    } = this.state
    const { clinicId } = this.props.match.params

    const isCreating = clinicId === undefined

    return (
      <React.Fragment>
        {dataLoaded ? (
          <div>
            <main className="main">
              {isCreating ? (
                <Steps
                  steps={['Clinic Info', 'Add Rooms', 'Add Staff']}
                  step={step}
                />
              ) : (
                ''
              )}

              <section
                className="main__inner"
                id="information"
                ref="information" //eslint-disable-line
              >
                {step === 1 || !isCreating ? (
                  <article className="col__12-7 fadeInFlyAnimate clinicForm clinicForm--feedbackStrap clinicForm--wide">
                    {!isCreating ? (
                      <h2 className="h1 gutterMarginBottom">Clinic Details</h2>
                    ) : (
                      ''
                    )}
                    <form onChange={this.SetupdatedKey}>
                      <article
                        className={`form ${isCreating ? 'clinicForm' : ''}`}
                      >
                        <article className="form__group">
                          <section className="form__group--halfWidth">
                            {this.newField(
                              'name',
                              'Clinic Name',
                              'Add Clinic Name',
                              [validateNotEmpty],
                            )}
                            {this.newField('email', 'Email', 'Clinic Email', [
                              validateNotEmpty,
                              validateEmailAddress,
                            ])}
                          </section>
                          <section className="form__group--halfWidth">
                            {this.newField(
                              'main_phone',
                              'Main Phone',
                              'Main number',
                              [validateNotEmpty, validatePhoneNumber],
                              true,
                              Form.InputType.PHONE,
                            )}
                            {this.newField(
                              'alternative_phone',
                              'Alternative Phone',
                              'Alternative number',
                              [validateNotEmpty, validatePhoneNumber],
                              false,
                              Form.InputType.PHONE,
                            )}
                          </section>
                        </article>
                        <hr />

                        <Address data={data} parent={this} />
                        <hr />
                        <article className="form__group">
                          <section className="_form__group--halfWidth">
                            {this.newField(
                              'timezone',
                              'Clinic Timezone',
                              allFunc.timezoneChoices(),
                              [validateNotEmpty],
                              true,
                              Form.InputType.SELECT,
                              '',
                              this.state.data.id !== 0,
                            )}
                          </section>
                        </article>
                        <hr />
                        <OpeningHours data={data} />
                        <MainButton
                          styleType="primary"
                          label="Add Rooms"
                          id="buttonPrimary"
                          customClasses={`form__submit ${
                            step === 1 && isCreating ? '' : 'hidden'
                          }`}
                          onClick={this.nextStep}
                        />
                      </article>
                    </form>
                  </article>
                ) : (
                  ''
                )}
                {step > 1 && isCreating ? (
                  <article className="col__12-7 clinicForm">
                    <MainButton
                      styleType="tertiary"
                      label="Back"
                      id="buttonTertiary"
                      customClasses={`form__submit gutterMarginTop ${
                        step > 1 && isCreating ? '' : 'hidden'
                      }`}
                      onClick={this.previousStep}
                    />
                  </article>
                ) : (
                  ''
                )}

                {step === 2 || !isCreating ? (
                  <article className="col__12-7 fadeInFlyAnimate clinicForm">
                    <Rooms
                      data={data}
                      displayEquipModal={this.displayEquipModal}
                      deleteClinicRoom={this.deleteClinicRoom}
                    />

                    <MainButton
                      styleType="primary"
                      label="Add Staff"
                      id="buttonPrimary"
                      customClasses={`form__submit gutterMarginTop ${
                        step === 2 && isCreating ? '' : 'hidden'
                      }`}
                      onClick={this.nextStep}
                    />
                  </article>
                ) : (
                  ''
                )}
                <article className="col__12-7 fadeInFlyAnimate clinicForm">
                  {step === 3 || !isCreating ? (
                    <React.Fragment>
                      <Staff
                        data={data}
                        toggleInfoModal={() => this.toggleInfoModal()}
                      />
                      <MainButton
                        styleType="secondary"
                        label="Finish and Review"
                        id="buttonSecondary"
                        customClasses={`form__submit gutterMarginTop ${
                          isCreating ? '' : 'hidden'
                        }`}
                        onClick={this.saveClinic}
                      />
                    </React.Fragment>
                  ) : (
                    ''
                  )}

                  {isCreating ? (
                    ''
                  ) : (
                    <>
                      <article className="fadeInFlyAnimate gutterMarginBottom">
                        <h2 className="h1 gutterMarginBottom">
                          Appointment Settings
                        </h2>
                        <div className="form">
                          <article className="form__group">
                            <div className="form__group--halfWidth  secondaryMarginBottom">
                              <p className="text-light">
                                Upload a document that is sent with appointment
                                confirmations to give the patient any further
                                information they may need.
                                <br />
                                (Must be a PDF)
                              </p>
                              <>
                                <div className="form__group--twothird__panel clinicLogo__container">
                                  {appointmentDocumentFD !== null ? (
                                    <div className="button--marginRight">
                                      <button
                                        type="button"
                                        className="treatment__header__button treatmentItem__alert buttonTransparent"
                                      >
                                        <span className="treatment__info__icon icon-document" />
                                        <span className="treatment__info__text">
                                          {allFunc.getFileName(
                                            appointmentDocumentName,
                                          )}
                                        </span>
                                      </button>
                                    </div>
                                  ) : data.appointment_document !== null &&
                                    data.signed_appointment_document !==
                                      null ? (
                                    <div className="button--marginRight">
                                      <button
                                        type="button"
                                        className="treatment__header__button treatmentItem__alert buttonTransparent"
                                        onClick={() => {
                                          window.open(
                                            data.signed_appointment_document,
                                            '_blank',
                                          )
                                        }}
                                      >
                                        <span className="treatment__info__icon icon-document" />
                                        <span className="treatment__info__text">
                                          {allFunc.getFileName(
                                            data.appointment_document,
                                          )}
                                        </span>
                                      </button>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  <div className="button--marginRight">
                                    <UploadFile
                                      label={
                                        data.appointment_document !== null
                                          ? 'Replace'
                                          : 'Upload'
                                      }
                                      styleType="tertiary"
                                      size="small"
                                      labelID="uploadAppointmentDocument"
                                      inputID="inputAppointmentDocument"
                                      accept="application/pdf"
                                      fileHandler={e => {
                                        this.selectAppointmentDocument(e.target)
                                      }}
                                    />
                                  </div>
                                  {data.appointment_document !== null ||
                                  this.state.appointmentDocumentFD !== null ? (
                                    <MainButton
                                      type="button"
                                      label="Remove"
                                      styleType="destructive"
                                      size="small"
                                      onClick={e => {
                                        this.clearAppointmentDocument(e)
                                      }}
                                    />
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </>
                            </div>
                          </article>
                        </div>
                      </article>

                      <article className="fadeInFlyAnimate gutterMarginBottom">
                        <ClinicSetupFinanceCard
                          isEdit={true}
                          vat={data.vat}
                          handleConnectQB={this.handlepostcallbackURl}
                          handleRemoveQB={this.handleDeleteQB}
                          vatList={this.state.VatList}
                          DeptList={this.state.DeptList}
                          handleChangeVat={this.handleChangeVAT}
                          handleChangeDepartment={this.handleChangeDepartment}
                          SelectedVatVal={this.state.selectedVat}
                          selectedDept={this.state.selectedDept}
                          clinicLogo={this.state.clinicLogo}
                          selectLogoPreview={this.selectLogoPreview}
                          clearLogoPreview={this.clearLogoPreview}
                          data={data}
                        />
                      </article>
                    </>
                  )}
                </article>
              </section>
              {!isCreating ? (
                <aside className="clinicControls">
                  <span /* eslint-disable-line */
                    className="clinicControls__button buttonPrimary"
                    onClick={this.saveClinic}
                  >
                    Save Changes
                  </span>
                  <span /* eslint-disable-line */
                    className="clinicControls__button buttonTertiary"
                    onClick={this.cancelModifications}
                  >
                    Cancel
                  </span>
                </aside>
              ) : (
                ''
              )}
              {isTutorialFeatureEnabled ? (
                <TutorialContext.Consumer>
                  {({
                    toggleTutorial,
                    setTutorial,
                    tutorialList,
                    history,
                    activeTutorial,
                  }) => (
                    <TutorialBtn
                      tutorialList={tutorialList}
                      toggleTutorial={toggleTutorial}
                      setTutorial={setTutorial}
                      activeTutorial={activeTutorial}
                      history={history}
                    />
                  )}
                </TutorialContext.Consumer>
              ) : null}
            </main>

            <article
              className="modal modal--overlay col__12-6"
              id="cannotDeleteRoomModal"
            >
              <section className="modal__header">
                <h4 className="h3">Permanently remove room from clinic?</h4>
                <span /* eslint-disable-line */
                  className="icon-cross buttonCircle modal__closeButton"
                  onClick={func.closeOverlayModal}
                />
              </section>
              <section className="modal__body">
                <p className="modal__bodyMessage primaryMarginBottom">
                  {`This will remove the room from the clinic and from any
                  existing appointments, which will default to 'no room
                  selected'.`}
                </p>
              </section>
              <section className="modal__footer">
                <span /* eslint-disable-line */
                  className="buttonSecondary button--small modal__submit"
                  onClick={func.closeOverlayModal}
                >
                  Cancel
                </span>
                <span /* eslint-disable-line */
                  className="buttonAlert button--small modal__submit"
                  onClick={() => {
                    this.deleteClinicRoom()
                  }}
                >
                  Delete
                </span>
              </section>
            </article>

            <article
              className="modal modal--overlay col__12-4"
              id="deleteStaffModal"
            >
              <section className="modal__header">
                <h4 className="h3">Delete staff member?</h4>
                <span /* eslint-disable-line */
                  className="icon-cross buttonCircle modal__closeButton"
                  onClick={func.closeOverlayModal}
                />
              </section>
              <section className="modal__body">
                <p className="modal__bodyMessage">
                  You are about to delete a staff member, they will lose all
                  access to this clinic
                </p>
              </section>
              <section className="modal__footer">
                <span /* eslint-disable-line */
                  className="buttonTertiary button--small modal__submit"
                  onClick={func.closeOverlayModal}
                >
                  Cancel
                </span>
                <span /* eslint-disable-line */
                  className="buttonAlert button--small modal__submit"
                  onClick={() => {
                    this.deleteStaff(this.state.currentStaffID)
                  }}
                >
                  Delete
                </span>
              </section>
            </article>

            <article
              className="modal modal--overlay col__12-4"
              id="saveChangesModal"
            >
              <section className="modal__header">
                <h4 className="h3">Save changes?</h4>
                <span /* eslint-disable-line */
                  className="icon-cross buttonCircle modal__closeButton"
                  onClick={func.closeOverlayModal}
                />
              </section>
              <section className="modal__body">
                <p className="modal__bodyMessage">
                  You are about to leave this page without saving your changes
                  to clinic information
                </p>
              </section>
              <section className="modal__footer">
                <span /* eslint-disable-line */
                  className="buttonAlert button--small modal__submit"
                  onClick={this.discardModifications}
                >
                  Discard
                </span>
                <span /* eslint-disable-line */
                  className="buttonSecondary button--small modal__submit"
                  onClick={this.saveModifications}
                >
                  Save
                </span>
              </section>
            </article>

            <section className="overlay" />
            {this.state.showInfoModal ? (
              <Modal
                id="infoRoleModal"
                title="Clever Clinic user roles"
                closeModal={() => this.toggleInfoModal()}
                rightButtons={[
                  {
                    type: 'button',
                    style: 'tertiary',
                    label: 'Close',
                    size: 'small',
                    events: {
                      onClick: () => this.toggleInfoModal(),
                    },
                  },
                ]}
              >
                <ModalInfo type="Staff" />
              </Modal>
            ) : this.state.displayEquipModal ? (
              <Modal
                id="equipmentModal"
                title="Equipment mobility options"
                closeModal={() => this.closeEquipModal()}
                rightButtons={[
                  {
                    type: 'button',
                    style: 'tertiary',
                    label: 'Close',
                    size: 'small',
                    events: {
                      onClick: () => this.closeEquipModal(),
                    },
                  },
                ]}
              >
                <ModalInfo type="Equipment" />
              </Modal>
            ) : (
              ''
            )}
          </div>
        ) : (
          <Loader />
        )}
      </React.Fragment>
    )
  }
}

EditSummaryPage.propTypes = {
  actions: PropTypes.object.isRequired,
  clinicSummary: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  global: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
  return {
    clinicSummary: state.clinicSummary,
    clinicGroup: state.clinicians,
    global: state.global,
    callback_url: window.location.origin + '/clinics/quickbooks-success',
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_FetchClinicDetails,
        A_PatchUpdatedClinicDetails,
        A_GetAccessURL_QB,
        A_Delete_QB,
        A_GetTaxCodes_Vat_QB,
        A_GetDepartment_QB,
        A_UpdatedInvoiceLogo,
        A_PostClinicDetails,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditSummaryPage),
)
