import React from 'react'
import PropTypes from 'prop-types'
import MainButton from '../../Buttons'

const ModalFooter = ({
  leftButtons,
  rightButtons,
  layout, //eslint-disable-line
  closeHandler, //eslint-disable-line
  allowDelete, //eslint-disable-line
  clickHandler, //eslint-disable-line
  ...props
}) => {
  const getButtons = buttonSet => {
    return buttonSet.map((button, index) => {
      return (
        <MainButton
          key={index} //eslint-disable-line
          closeHandler={closeHandler}
          clickHandler={clickHandler}
          type={button.type}
          styleType={button.style}
          label={button.label}
          size={button.size}
          isDisplayed={button.isDisplayed}
          isDisabled={button.isDisabled}
          loading={{
            isLoading: button.isLoading,
            loadingText: button.loadingText,
          }}
          customClasses={
            index < buttonSet.length - 1
              ? 'modal--spacedButton--left modal_footer_button'
              : ''
          }
          {...button.events}
        />
      )
    })
  }

  return (
    <section className="modal__footer">
      <div className="confirm__container">
        {props.children ? props.children : null /* eslint-disable-line */}
        {leftButtons.length > 0 ? getButtons(leftButtons) : ''}
      </div>

      <div className="footer__spacer" />
      <div
        className={`footer__multipleButtonContainer ${
          props.rightChildren ? 'confirm__container' : '' //eslint-disable-line
        } `}
      >
        {props.rightChildren ? props.rightChildren : null /* eslint-disable-line */}
        {rightButtons.length > 0 ? getButtons(rightButtons) : ''}
      </div>
    </section>
  )
}

ModalFooter.defaultProps = {
  rightButtons: [],
  leftButtons: [],
  layout: 'right-only',
}

ModalFooter.propTypes = {
  rightButtons: PropTypes.array,
  leftButtons: PropTypes.array,
  layout: PropTypes.oneOf(['right-only', 'left-spaced']),
}

export default ModalFooter
