import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import SelectInput from '../../components/forms/Select'
import DatePickerInput from '../../components/forms/datePickerInput'
import DatePickerRangeHHL from '../../components/DatePicker/DatePickerRange'
import MainButton from '../../components/Buttons'
import {
  getPreviousWeekStartEndDays,
  clinicsForLocale,
} from '../../utilities/ReusableFunctions'
import TreatmentSummary from './TreatmentSummary'
import AppointmentTreatmentSummary from './AppointmentTreatmentSummary'
import AnalyticsChartsPannel from './AnalyticsChartsPannel'
import FeedbackNotification from '../../components/Feedback/FeedbackNotification'
import {
  A_GetClinicsList,
  A_FetchCliniciansList,
  A_ToggleAnalyticsApplyButton,
} from '../../actions'
import Placeholder from '../../components/Tutorials/Placeholder'
import { getTasks } from '../../services/OnBoarding/auth'

class AnalyticsFilterPanel extends React.Component {
  constructor(props) {
    super(props)
    this.startDateRef = React.createRef()
    this.endDateRef = React.createRef()
    this.dpContainer = React.createRef()

    const previous = getPreviousWeekStartEndDays()

    this.state = {
      showStartPicker: false,
      showEndPicker: false,
      clinicList: [],
      clinicianList: [],
      startDate: previous.startDate,
      endDate: previous.endDate,
      selectedClinicianID: '',
      selectedClinicID: '',
      sortID: 0,
      filterCount: 0,
      localeList: [],
      locale: props.global.currentClinic.locale,
    }
  }

  componentDidMount = async () => {
    const { actions, formatTasks } = this.props
    const tasks = await getTasks()
    formatTasks(tasks)

    actions.A_GetClinicsList().then(() => {
      const { global } = this.props

      const data = clinicsForLocale(
        global.clinics,
        global.currentClinicID,
        global.currentClinic.locale,
      )

      this.setState(
        {
          clinicList: data.clinics,
          localeList: data.locales,
          locale: data.locale,
        },
        () => {
          this.applyFilters()
        },
      )
    })
    actions.A_FetchCliniciansList().then(() => {
      const {
        global: { allFilterClinicians },
      } = this.props
      this.setState({ clinicianList: allFilterClinicians })
    })
  }

  selectLocale = e => {
    const { global } = this.props
    let selectedValue = e.target.value

    const data = clinicsForLocale(
      global.clinics,
      this.state.selectedClinicID,
      selectedValue,
    )

    this.setState({
      clinicList: data.clinics,
      selectedClinicID: data.clinic,
      locale: data.locale,
    })
  }

  selectClinic = e => {
    let selectedValue = e.target.value
    this.setState({ selectedClinicID: selectedValue })
  }

  selectClinician = e => {
    let selectedValue = e.target.value
    this.setState({ selectedClinicianID: selectedValue })
  }

  showStartDatePicker = () => {
    this.setState(() => ({
      showStartPicker: true,
    }))
  }
  showEndDatePicker = () => {
    this.setState(() => ({
      showEndPicker: true,
    }))
  }

  hideStartDatePicker = day => {
    //day ? (day >= this.state.endDate ? this.resetDateValue(true) : ""):"";
    let date = day && day <= this.state.endDate ? day : this.state.startDate
    //let datePickerState = !day || day <= this.state.endDate ? false : true
    this.setState(() => ({
      showStartPicker: false,
      startDate: date,
    }))
  }
  hideEndDatePicker = day => {
    //day && day <= this.state.startDate ? this.resetDateValue(true) : "";
    let date = day && day >= this.state.startDate ? day : this.state.endDate
    //let datePickerState = !day || day >= this.state.startDate ? false : true
    this.setState(() => ({
      showEndPicker: false,
      endDate: date,
    }))
  }

  applyFilters = () => {
    this.setState({ filterCount: this.state.filterCount + 1 })
  }

  selectSortByType = e => {
    let selectedValue = e.target.value
    this.setState({ sortID: selectedValue })
  }

  resetDateValue = data => {
    const { actions } = this.props
    actions.A_ToggleAnalyticsApplyButton(data)
  }
  render() {
    const { data } = this.props
    return (
      <React.Fragment>
        <main className="main">
          <>
            {!this.props.hasCompletedAllTasks ? (
              <Placeholder activeTasks={this.props.activeTasks} />
            ) : null}
          </>
          <section className="report__sidebar" ref={this.dpContainer}>
            <div className="content">
              <fieldset className="form__group--topStackable">
                <article className="form__textField--has-icon form__textField--dateMargin">
                  <label
                    className="form__label"
                    htmlFor="form__label_startDate"
                  >
                    START DATE
                  </label>
                  <DatePickerInput
                    id="startDate"
                    value={moment(this.state.startDate).format('DD.MM.YYYY')}
                    refVal={this.startDateRef}
                    classList={`form__textField form__textField--right-icon datePicker__input noMargin ${
                      this.state.showStartPicker
                        ? 'form__textField--active'
                        : ''
                    }`}
                    onClick={this.showStartDatePicker}
                  />
                </article>
                <article className="form__textField--has-icon form__textField--dateMargin">
                  <label className="form__label" htmlFor="form__label_endDate">
                    END DATE
                  </label>
                  <DatePickerInput
                    id="endDate"
                    value={
                      this.state.endDate
                        ? moment(this.state.endDate).format('DD.MM.YYYY')
                        : ''
                    }
                    refVal={this.endDateRef}
                    classList={`form__textField form__textField--right-icon datePicker__input noMargin ${
                      this.state.showEndPicker ? 'form__textField--active' : ''
                    }`}
                    onClick={this.showEndDatePicker}
                  />
                </article>

                <SelectInput
                  label="BREAKDOWN RESULTS"
                  options={[
                    { id: '-1', title: 'Please select...', isDisabled: true },
                    { id: '0', title: 'Day', isDisabled: false },
                    { id: '1', title: 'Week', isDisabled: false },
                    { id: '2', title: 'Month', isDisabled: false },
                  ]}
                  defaultSelected={this.state.sortID ? this.state.sortID : ''}
                  onChange={this.selectSortByType}
                />
                {this.state.localeList.length > 1 ? (
                  <SelectInput
                    label="LOCALE"
                    options={this.state.localeList}
                    defaultSelected={this.state.locale}
                    onChange={this.selectLocale}
                  />
                ) : (
                  ''
                )}

                <SelectInput
                  label="CLINIC"
                  options={this.state.clinicList}
                  defaultSelected={
                    this.state.selectedClinicID
                      ? this.state.selectedClinicID
                      : ''
                  }
                  onChange={this.selectClinic}
                />
                <SelectInput
                  label="STAFF MEMBER"
                  options={this.state.clinicianList}
                  defaultSelected={
                    this.state.selectedClinicianID
                      ? this.state.selectedClinicianID
                      : ''
                  }
                  onChange={this.selectClinician}
                />
                <MainButton
                  styleType="secondary"
                  size="small"
                  label="Apply"
                  clickHandler={this.applyFilters}
                />
              </fieldset>
            </div>
          </section>
          <div className="analytics_wrapper">
            <section className="main__inner main__inner--hasLeftPanel">
              {/* Main inner page content */}
              {['finance_graph', 'appointments_graph'].map(
                function(value, id) {
                  return (
                    <AnalyticsChartsPannel
                      key={id}
                      clinicID={this.state.selectedClinicID}
                      clinicianID={this.state.selectedClinicianID}
                      startDate={moment(this.state.startDate).format(
                        'YYYY-MM-DD',
                      )}
                      endDate={moment(this.state.endDate).format('YYYY-MM-DD')}
                      sortID={this.state.sortID}
                      filterCount={this.state.filterCount}
                      chart={value}
                      chartID={id}
                      locale={this.state.locale}
                    />
                  )
                }.bind(this),
              )}
              <AppointmentTreatmentSummary
                clinicID={this.state.selectedClinicID}
                clinicianID={this.state.selectedClinicianID}
                startDate={moment(this.state.startDate).format('YYYY-MM-DD')}
                endDate={moment(this.state.endDate).format('YYYY-MM-DD')}
                filterCount={this.state.filterCount}
                locale={this.state.locale}
              />
              <TreatmentSummary
                clinicID={this.state.selectedClinicID}
                clinicianID={this.state.selectedClinicianID}
                startDate={moment(this.state.startDate).format('YYYY-MM-DD')}
                endDate={moment(this.state.endDate).format('YYYY-MM-DD')}
                filterCount={this.state.filterCount}
                locale={this.state.locale}
              />
            </section>
          </div>
          {data.inValidData ? (
            <FeedbackNotification
              id="feedbackMessage"
              dataError={true}
              message={'End date cannot be before Start date'}
              iserrorAlert={true}
              closeHandler={() => {
                this.resetDateValue(false)
              }}
            />
          ) : (
            ''
          )}
        </main>
        {this.state.showStartPicker ? (
          <DatePickerRangeHHL
            selectedDate={this.state.endDate}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            dpInput={this.startDateRef.current}
            position="leftTop"
            container={this.dpContainer.current}
            calendarType="manage_schedule_range"
            onHide={this.hideStartDatePicker}
          />
        ) : this.state.showEndPicker ? (
          <DatePickerRangeHHL
            selectedDate={this.state.endDate}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            dpInput={this.endDateRef.current}
            position="leftTop"
            container={this.dpContainer.current}
            calendarType="manage_schedule_range"
            onHide={this.hideEndDatePicker}
          />
        ) : (
          ''
        )}
      </React.Fragment>
    )
  }
}

AnalyticsFilterPanel.propTypes = {
  actions: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  global: PropTypes.object.isRequired,
  formatTasks: PropTypes.func.isRequired,
  hasCompletedAllTasks: PropTypes.bool.isRequired,
  activeTasks: PropTypes.array.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetClinicsList,
        A_FetchCliniciansList,
        A_ToggleAnalyticsApplyButton,
      },
      dispatch,
    ),
  }
}

const mapStateToProps = state => {
  return {
    data: state.charts,
    global: state.global,
  }
}

//Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AnalyticsFilterPanel),
)
