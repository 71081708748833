import React from 'react'
import PropTypes from 'prop-types'

import Appointment from './Appointment'
import PersonalMeeting from './PersonalMeeting'

import { appointmentReasonTitles } from '../../../../../utilities/ReusableObjects'

export default function CreateEdit({
  type,
  appointmentPurpose,
  clinicId,
  treatmentTypes,
  treatmentHandler,
  equipmentHandler,
  appointmentDetails,
  patient,
  setPatient,
  rooms,
  errors,
  setError,
  isReBookingAppointment,
  isOutstandingAction,
  title,
  setTitle,
  isVirtual,
}) {
  const { APPOINTMENT } = appointmentReasonTitles
  const validateInput = ({ name, value }) => {
    setError(err => ({
      ...err,
      [`${name}HasError`]: value ? '' : 'Required',
    }))
  }

  return (
    <>
      {appointmentPurpose === APPOINTMENT ? (
        <Appointment
          type={type}
          clinicId={clinicId}
          treatmentTypes={treatmentTypes}
          patient={patient}
          setPatient={setPatient}
          setError={setError}
          appointmentDetails={appointmentDetails}
          errors={errors}
          rooms={rooms}
          treatmentHandler={treatmentHandler}
          equipmentHandler={equipmentHandler}
          isReBookingAppointment={isReBookingAppointment}
          isOutstandingAction={isOutstandingAction}
          isVirtual={isVirtual}
        />
      ) : (
        <PersonalMeeting
          validateInput={validateInput}
          errors={errors}
          appointmentPurpose={appointmentPurpose}
          setTitle={setTitle}
          title={title}
        />
      )}
    </>
  )
}

CreateEdit.defaultProps = {
  appointmentDetails: {},
  patient: {},
  isReBookingAppointment: false,
  isOutstandingAction: false,
  isVirtual: false,
}

CreateEdit.propTypes = {
  type: PropTypes.string.isRequired,
  appointmentPurpose: PropTypes.string.isRequired,
  clinicId: PropTypes.number.isRequired,
  treatmentTypes: PropTypes.array.isRequired,
  treatmentHandler: PropTypes.object.isRequired,
  equipmentHandler: PropTypes.object.isRequired,
  appointmentDetails: PropTypes.object,
  patient: PropTypes.object,
  setPatient: PropTypes.func.isRequired,
  rooms: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
  isReBookingAppointment: PropTypes.bool,
  isOutstandingAction: PropTypes.bool,
  title: PropTypes.string.isRequired,
  setTitle: PropTypes.func.isRequired,
  isVirtual: PropTypes.bool,
}
