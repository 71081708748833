import React from 'react'
import PropTypes from 'prop-types'
import MainButton from '../../Buttons'

const ConfirmTooltip = ({
  id,
  title,
  bodyText,
  removeLabel,
  removeStyle,
  onRemoveClick,
  keepLabel,
  keepStyle,
  onKeepClick,
  position,
  ...props //eslint-disable-line
}) => {
  return (
    <div
      id={id}
      className={`confirmTooltip ${
        position === 'right'
          ? 'confirmTooltip--right'
          : position === 'centre'
          ? 'confirmTooltip--centre'
          : ''
      } fadeInUnravelAnimate--top`}
    >
      <article className="confirmTooltip__header">
        <span>{title}</span>
      </article>
      <article className="confirmTooltip__body">{bodyText}</article>
      <article className="confirmTooltip__footer confirmTooltip__footer--noMargin">
        <MainButton
          type="button"
          label={removeLabel}
          styleType={removeStyle}
          size="small"
          customClasses="modal_footer_button confirm__remove"
          clickHandler={onRemoveClick}
        />
        <MainButton
          type="button"
          label={keepLabel}
          styleType={keepStyle}
          size="small"
          customClasses="modal_footer_button confirm__keep"
          clickHandler={onKeepClick}
        />
      </article>
    </div>
  )
}

ConfirmTooltip.defaultProps = {
  id: '',
  title: '',
  bodyText: '',
  removeLabel: '',
  keepLabel: '',
  removeStyle: 'alert',
  keepStyle: 'tertiary',
  onRemoveClick: () => {},
  onKeepClick: () => {},
  position: 'left',
}

ConfirmTooltip.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  bodyText: PropTypes.string,
  removeLabel: PropTypes.string,
  keepLabel: PropTypes.string,
  removeStyle: PropTypes.string,
  keepStyle: PropTypes.string,
  onRemoveClick: PropTypes.func,
  onKeepClick: PropTypes.func,
  position: PropTypes.string,
}

export default ConfirmTooltip
