import React from 'react'
import PropTypes from 'prop-types'

import Input from '../../../../../forms/Input'

export default function PersonalMeeting({
  validateInput,
  errors,
  appointmentPurpose,
  title,
  setTitle,
}) {
  return (
    <fieldset className="form__group--topStackable form__group--modal-last">
      <Input
        label="Title"
        placeholder="Add title"
        name="title"
        handleOnChange={({ target }) => {
          validateInput(target)
          setTitle(target.value)
        }}
        error={
          appointmentPurpose === 'Meeting'
            ? errors.meetingHasError
            : errors.personalHasError
        }
        value={title}
      />
      <p className="text-light noMargin">
        {appointmentPurpose === 'Meeting'
          ? 'Meetings are marked as meetings on your calendar'
          : "Personal time is marked as 'unavailable' on your calendar"}
      </p>
    </fieldset>
  )
}

PersonalMeeting.defaultProps = {
  title: '',
  setTitle: () => {},
}

PersonalMeeting.propTypes = {
  title: PropTypes.string,
  setTitle: PropTypes.func,
  validateInput: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  appointmentPurpose: PropTypes.string.isRequired,
}
