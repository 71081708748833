import * as staff from '../services/Staff/StaffService'
import { ignoreGlobalError } from '../utilities/ReusableFunctions'
import types from './types'

export const A_GetCliniciansList = () => dispatch =>
  staff
    .getCliniciansList()
    .then(response => {
      dispatch({ type: types.GET_CLINICIANS_LIST.OK, data: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_CLINICIANS_LIST.FAIL, error })
      return Promise.reject(error)
    })

export const A_PatchClinicianInvite = clinicData => dispatch => {
  dispatch({ type: types.CLINICIAN_LIST_PATCH.REQ })
  return staff
    .patchClinicianInvite(clinicData)
    .then(response => {
      dispatch({ type: types.CLINICIAN_LIST_PATCH.OK, data: response })
      return response
    })
    .catch(error => {
      dispatch({
        type: types.CLINICIAN_LIST_PATCH.FAIL,
        error,
        ignoreGlobalError: ignoreGlobalError(error),
      })
      return error
    })
}

export const A_PatchClinicInvite = (id, invites) => dispatch =>
  staff
    .patchClinicInvite(id, invites)
    .then(response => {
      dispatch({ type: types.CLINIC_LIST_PATCH.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.CLINIC_LIST_PATCH.FAIL, error })
      return Promise.reject(error)
    })
