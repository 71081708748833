import React from 'react'
import PropTypes from 'prop-types'

import ReactMarkdown from 'react-markdown'

export default function Section({
  section: { title, content, attachments },
  renderAttachment,
  toggleModal,
  setAttachment,
}) {
  return (
    <div className="settings--view" data-testid="data-testsection">
      <h2>{title}</h2>
      <ReactMarkdown source={content} />
      <div className="support-attachments">
        {attachments.map(attachment =>
          renderAttachment(attachment, toggleModal, setAttachment),
        )}
      </div>
    </div>
  )
}

Section.propTypes = {
  section: PropTypes.object.isRequired,
  renderAttachment: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  setAttachment: PropTypes.func.isRequired,
}
