import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import PatientSearch from '../../../../../forms/InputSearchPatient'
import Select from '../../../../../forms/Select'
import MultiSelect from '../../../../../forms/InputMultiSelect'
import TextArea from '../../../../../forms/Textarea'

import { A_FetchConsultationClinicTreatmentList } from '../../../../../../actions'

import { appointmentModalTypes } from '../../../../../../utilities/ReusableObjects'
import { getItems } from '../../../../../../utilities/ReusableFunctions'

export default function Appointment({
  type,
  clinicId,
  treatmentTypes,
  patient,
  setPatient,
  setError,
  appointmentDetails,
  errors,
  rooms,
  treatmentHandler,
  equipmentHandler,
  isReBookingAppointment,
  isOutstandingAction,
  isVirtual,
}) {
  const { CREATE, EDIT } = appointmentModalTypes
  const dispatch = useDispatch()

  const [initialValues, setValues] = useState({
    appointmentType: appointmentDetails ? appointmentDetails.purpose : '-1',
    treatmentType:
      appointmentDetails && appointmentDetails.treatment_type
        ? appointmentDetails.treatment_type.id
        : '-1',
    room:
      appointmentDetails && appointmentDetails.room
        ? appointmentDetails.room.id
        : '-1',
  })

  const {
    isTreatmentOptionsOpen,
    setTreatmentOptions,
    toggleTreatments,
    treatments,
  } = treatmentHandler

  const {
    isEquipmentOptionsOpen,
    equipment,
    setEquipmentOptions,
    toggleEquipment,
  } = equipmentHandler

  const isTreatmentInList = Boolean(
    treatmentTypes.find(({ id }) => id === initialValues.treatmentType),
  )

  const checkEditedTreatments = editedTreatments => {
    return editedTreatments.forEach(treatment =>
      appointmentDetails.treatments.forEach(
        initialTreatment =>
          treatment.id === initialTreatment.id &&
          (treatment.checked = 'checked'),
      ),
    )
  }

  useEffect(() => {
    const fetchTreatments = async () => {
      const editedTreatments = await dispatch(
        A_FetchConsultationClinicTreatmentList(
          clinicId,
          initialValues.treatmentType,
        ),
      )
      checkEditedTreatments(editedTreatments)
    }

    if (
      (type === EDIT || isReBookingAppointment || isOutstandingAction) &&
      Number(initialValues.treatmentType) !== -1
    )
      fetchTreatments()
  }, [])

  const validateSelect = ({ name, value }) => {
    setError(err => {
      return {
        ...err,
        [`${name}HasError`]: value === '-1' ? 'Required' : '',
      }
    })
  }

  const validateMultiSelect = () => {
    const hasCheckedTreatments = treatments.some(
      ({ checked }) => checked === 'checked',
    )
    setError(err => ({
      ...err,
      treatmentsHasError: !hasCheckedTreatments ? 'Required' : '',
    }))
  }

  const validateInput = e => {
    setError(err => ({
      ...err,
      patientHasError: e ? '' : 'Required',
    }))
  }

  return (
    <>
      <fieldset className="form__group--stackable">
        <section className="form__group--halfWidth createAppointment--right createAppointment--patient">
          <PatientSearch
            clinicId={clinicId}
            onPatientSelected={e => setPatient(e)}
            selectedPatient={
              type === CREATE ? patient : appointmentDetails.patient
            }
            error={errors.patientHasError}
            disabled={type === EDIT || isOutstandingAction ? 'disabled' : ''}
            validatePatient={validateInput}
          />
          <article className="form__group--halfWidth__panel">
            <Select
              id="appointmentType"
              label="appointment type"
              name="appointmentType"
              options={[
                {
                  id: -1,
                  title: 'Please select...',
                  isDisabled: type === EDIT,
                },
                ...getItems('purpose'),
              ]}
              onChange={({ target }) => {
                validateSelect(target)
                setValues(prevState => ({
                  ...prevState,
                  appointmentType: target.value,
                }))
              }}
              disabled={isOutstandingAction}
              defaultSelected={initialValues.appointmentType}
              error={errors.appointmentTypeHasError}
            />
          </article>
        </section>
      </fieldset>
      <fieldset className="form__group--stackable">
        <section className="form__group--halfWidth createAppointment--right">
          <article className="form__group--halfWidth__panel">
            <Select
              id="treatmentType"
              label="treatment type"
              name="treatmentType"
              options={[
                {
                  id: -1,
                  title: 'Please select...',
                  isDisabled: type === EDIT,
                },
                ...treatmentTypes,
              ]}
              onChange={({ target }) => {
                validateSelect(target)
                if (Number(target.value) !== -1) {
                  dispatch(
                    A_FetchConsultationClinicTreatmentList(
                      clinicId,
                      target.value,
                    ),
                  )
                }

                setValues(prevState => ({
                  ...prevState,
                  treatmentType: target.value,
                }))
              }}
              disabled={isOutstandingAction && isTreatmentInList}
              defaultSelected={initialValues.treatmentType}
              error={errors.treatmentTypeHasError}
            />
          </article>
          <MultiSelect
            isDynamic
            id="treatments"
            label="treatment(s)"
            placeholder="Treatment"
            containerClasses="form__group--halfWidth__panel"
            options={treatments}
            showOptions={() => setTreatmentOptions(true)}
            showSelector={isTreatmentOptionsOpen}
            onOptionChange={({ target }) => {
              toggleTreatments(target.value)
              validateMultiSelect(target)
            }}
            closeSelector={() => setTreatmentOptions(false)}
            error={errors.treatmentsHasError}
            disabled={isOutstandingAction && isTreatmentInList}
          />
        </section>
      </fieldset>
      <fieldset className="form__group--stackable">
        <section className="form__group--halfWidth createAppointment--right">
          <article className="form__group--halfWidth__panel">
            <Select
              id="name"
              label="room"
              name="room"
              options={[
                {
                  id: 0,
                  title: isVirtual ? 'Not selected' : 'Please select...',
                  isDisabled: type === EDIT && !isVirtual,
                },
                ...rooms,
              ]}
              disabled={!rooms.length}
              defaultSelected={initialValues.room}
              onChange={({ target }) => {
                validateSelect(target)
                setValues(prevState => ({
                  ...prevState,
                  room: target.value,
                }))
              }}
              error={!isVirtual && errors.roomHasError}
              isRequired={!isVirtual}
            />
          </article>
          <MultiSelect
            id="equipment"
            isDynamic
            label="equipment"
            placeholder="Equipment"
            containerClasses="form__group--halfWidth__panel"
            options={equipment}
            showOptions={() => setEquipmentOptions(true)}
            showSelector={isEquipmentOptionsOpen}
            onOptionChange={({ target: { value } }) => toggleEquipment(value)}
            closeSelector={() => setEquipmentOptions(false)}
            isRequired={false}
          />
        </section>
      </fieldset>
      <>
        {type === CREATE && (
          <fieldset className="form__group form__group--stackable createAppointment--right">
            <TextArea
              id="notes"
              label="Notes"
              name="notes"
              placeholder="Notes"
              isRequired={false}
            />
          </fieldset>
        )}
      </>
    </>
  )
}

Appointment.defaultProps = {
  patient: {},
  isReBookingAppointment: false,
  isOutstandingAction: false,
  isVirtual: false,
}

Appointment.propTypes = {
  type: PropTypes.string.isRequired,
  clinicId: PropTypes.number.isRequired,
  treatmentTypes: PropTypes.array.isRequired,
  treatmentHandler: PropTypes.object.isRequired,
  equipmentHandler: PropTypes.object.isRequired,
  appointmentDetails: PropTypes.object.isRequired,
  patient: PropTypes.object,
  setPatient: PropTypes.func.isRequired,
  rooms: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
  isReBookingAppointment: PropTypes.bool,
  isOutstandingAction: PropTypes.bool,
  isVirtual: PropTypes.bool,
}
