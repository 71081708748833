import React from 'react'
import PropTypes from 'prop-types'

import SettingContent from '../../SettingContent'
import GroupDetails from '../Clinic/GroupDetails'

const ClinicSetting = ({ name, account, updateGroupDetails }) => {
  return (
    <SettingContent
      name={name}
      desc="Here are your clinic group details, use the below to update them. Changes will be reflected across the app."
    >
      <GroupDetails account={account} updateGroupDetails={updateGroupDetails} />
    </SettingContent>
  )
}

ClinicSetting.propTypes = {
  name: PropTypes.string.isRequired,
  account: PropTypes.object.isRequired,
  updateGroupDetails: PropTypes.func.isRequired,
}

export default ClinicSetting
