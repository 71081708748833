import React, { Component } from 'react'
import { withRouter /*, Link*/ } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ProcessStepper from '../../components/ProcessStepper'
import FormLabel from '../../components/forms/label'
import PropTypes from 'prop-types'
import {
  A_GetImportedFileData,
  A_UpdateFieldHeadersList,
  A_ImportData,
} from '../../actions'
import SelectInput from '../../components/forms/Select/index'
//import Footer from '../../components/Bottombar'
import UnMatchedFields from '../../components/PatientData/importComplete'
import AlertModal from '../../components/Modal/Alert'
import Loader from '../../components/PatientData/Loader'
import FileName from '../../components/PatientData/fileName'

export const Demographics = [
  'Title',
  'First name',
  'Last name',
  'Middle names',
  'Gender',
  'Date of birth',
  'Marital status',
  'Maiden name',
]
export const ContactInfo = [
  'Mobile phone',
  'Home phone',
  'Work phone',
  'Email',
  'Address line 1',
  'Address line 2',
  'City/Town',
  'County',
  'Postcode',
  'Country',
]
export const EmergencyContact = [
  Demographics[1],
  Demographics[2],
  ContactInfo[0],
  ContactInfo[1],
  'Relationship to patient',
]
export const GPInfo = [
  'GP practice',
  'GP name',
  'GP phone',
  ContactInfo[4],
  ContactInfo[5],
  ContactInfo[6],
  ContactInfo[7],
  ContactInfo[8],
  ContactInfo[9],
  'Privately insured?',
]
let data = Demographics.concat(ContactInfo, EmergencyContact, GPInfo)

class DataImport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      unSelectedData: [],
      required_1: true,
      required_2: true,
      unMatchedRows: [],
      matchedRows: [],
      imported_file: '',
      unCheckedLegacyData: '',
      importedDataList: [],
      defaultChecked: true,
      clearImport: false,
      testData: [],
      isLoading: true,
      displayLoader: false,
      loaderText: 'Loading Data',
    }
  }

  componentDidMount = () => {
    const { history, actions, global } = this.props

    const clinic = global.currentClinicID

    let unMatchedRows = []
    let importID = localStorage.getItem('importID')
    actions
      .A_GetImportedFileData({ clinicID: clinic, importID: importID })
      .then(res => {
        const { importData } = this.props
        if (res.status !== 0) {
          history.push('/clinics/data-import-error-logs')
          return
        }

        data.forEach((item, d) => {
          this.setState({ ['rowID_' + d]: importData.fileHeaders })
        })
        this.setState({
          unSelectedData: importData.fileHeaders,
          imported_file: res.signed_imported_file,
        })

        importData.fileHeaders.forEach(header => {
          if (header.title !== 'Please select...') {
            unMatchedRows.push(header.title)
          }
        })

        let unCheckedLegacyData = unMatchedRows.join(', ')
        this.setState(
          {
            unMatchedRows: unMatchedRows,
            unCheckedLegacyData: unCheckedLegacyData,
          },
          () => {
            this.state.unMatchedRows.forEach((row, k) => {
              this.setState({ ['checked_' + k]: true })
            })
          },
        )

        this.setState({ isLoading: false })
      })
  }

  selectRelaventOption = (e, rowID) => {
    this.setState({ displayLoader: false })
    let newDatatobeAdded
    let selectedValue
    let dataIndex
    let valueID = parseInt(e.target.value) + 1
    let UnMatchedData = []
    let selectedID
    let importedDataList = this.state.importedDataList

    let key = data[rowID]

    let record_key = key.toLowerCase().replace(/\s+/g, '_')

    /// CHANGE 8/7/2019 HANDLE ADDRESS LINE KEYS - SHOULD BE STRING INSTEAD OF NUMBER ////
    if (record_key.indexOf('address_line') > -1) {
      if (record_key.indexOf('1') > -1) {
        record_key = 'address_line_one'
      }
      if (record_key.indexOf('2') > -1) {
        record_key = 'address_line_two'
      }
    }

    //CHANGE 8/7/2019 HANDLE ADDRESS TOWN, COUNTY, POSTCODE AND COUNTRY
    if (key === 'City/Town') {
      record_key = 'address_town'
    }
    if (key === 'County') {
      record_key = 'address_county'
    }
    if (key === 'Postcode') {
      record_key = 'address_postcode'
    }

    if (key === 'Country') {
      record_key = 'address_country'
    }

    // Contact info one that doesnt conform
    if (key === 'Permission given to contact') {
      record_key = 'permission_to_contact'
    }

    let demo = Demographics.length
    let contact = demo + ContactInfo.length
    let emergency = contact + EmergencyContact.length
    let gp = emergency + GPInfo.length

    //let isDemographic = rowID < demo
    //let isContact = rowID >= demo && rowID < contact
    let isEmergency = rowID >= contact && rowID < emergency
    let isGP = rowID >= emergency && rowID < gp

    if (isEmergency === true) {
      // Finds first_name -> home_phone and puts emergency_ at the start

      //CHANGE 8/7/2019 CHECK EMERGENCY CONTACT ROW TITLES
      EmergencyContact.forEach(contact => {
        let value = contact

        if (value === key) {
          record_key = 'emergency_' + record_key
        }
      })

      // This one doesnt conform to the main structure
      if (key === 'Relationship to patient') {
        record_key = 'emergency_relationship'
      }
    }

    if (isGP === true) {
      // Finds first_name -> home_phone and puts emergency_ at the start
      GPInfo.forEach(info => {
        //CHANGE 8/7/2019 CHECK GP INFO ROW TITLES
        let value = info
        if (value === key) {
          record_key = 'gp_' + record_key
        }
      })

      //CHANGE 8/7/2019 HANDLE GP PHONE AND PRACTICE TITLES
      if (key === 'GP practice') {
        record_key = 'gp_practice'
      }

      if (key === 'GP name') {
        record_key = 'gp_name'
      }

      if (key === 'GP phone') {
        record_key = 'gp_work_phone'
      }

      // This one doesnt conform to the main structure
      if (key === 'Privately insured?') {
        record_key = 'gp_privately_insured'
      }
    }

    // Remove existing match
    importedDataList.forEach((dataList, t) => {
      if (record_key === dataList.record_key) {
        importedDataList.splice(t, 1)
        return
      }
    })

    // Add match
    if (this.state.unSelectedData[valueID].id !== -1) {
      importedDataList.push({
        record_key: record_key,
        imported_key: this.state.unSelectedData[valueID].title,
      })
    }
    this.setState({ importedDataList: importedDataList })

    data.forEach((dadaEle, d) => {
      if (this.state['selectedValue_' + rowID]) {
        if (parseInt(rowID) === -1) {
          newDatatobeAdded = null
        }
        this.state.unSelectedData.forEach((unData, p) => {
          if (unData.title === this.state['selectedValue_' + rowID]) {
            newDatatobeAdded = unData
            dataIndex = p
          }
        })
      }
      //* mapping the selected value to corresponding value in the left //
      if (parseInt(d) === parseInt(rowID)) {
        if (parseInt(d) === 1) {
          this.setState({ required_1: true })
        } else if (parseInt(d) === 2) {
          this.setState({ required_2: true })
        }

        //CHANGE 9/7/2019 REMOVE DOB REQUIRED

        this.state['rowID_' + d].forEach(row => {
          if (parseInt(row.id) === parseInt(e.target.value)) {
            selectedValue = row.title
            selectedID = row.id
            return
          }
        })
        this.state.unMatchedRows.forEach((matchedRows, m) => {
          if (matchedRows === selectedValue) {
            this.state.unMatchedRows.splice(m, 1)
          }
        })
        this.setState({
          ['selectedValue_' + d]: selectedValue,
          ['selectedID_' + d]: selectedID,
        })
      } else {
        let newList = []
        let newState = this.state['rowID_' + d]

        newState.forEach(nState => {
          // if (parseInt(e.target.value) === -1) {
          // } else {
          //   if (parseInt(nState.id) === parseInt(e.target.value)) {

          //   }
          // }
          //* making pre-selected value available for all the dropdowns when selected value is changed in any on the dropdown's //
          UnMatchedData.push(nState.title)
          newList.push({
            id: nState.id,
            title: nState.title,
            isDisabled: nState.isDisabled,
            isSelected: nState.isSelected,
          })
        })
        if (newDatatobeAdded && newDatatobeAdded.id !== -1) {
          if (dataIndex > newList.length - 1) {
            dataIndex = newList.length
          }
          newList.splice(dataIndex, 0, newDatatobeAdded)
        }
        this.setState({
          ['rowID_' + d]: [...new Set(newList)],
          unMatchedRows: this.state.unMatchedRows,
        })
      }
    })

    const arr =
      newDatatobeAdded && newDatatobeAdded === -1
        ? this.state.unMatchedRows.push(newDatatobeAdded.title)
        : this.state.unMatchedRows

    this.setState({
      unMatchedRows: arr,
    })
  }

  importMatchedData = () => {
    const { history, actions, global } = this.props

    const clinic = global.currentClinicID

    let data = []
    let importID = localStorage.getItem('importID')

    //CHANGE 9/7/2019 REMOVE DOB REQUIRED

    let req1 = document.getElementById('rowID_1').value > -1
    let req2 = document.getElementById('rowID_2').value > -1

    this.state.unMatchedRows.forEach((unRow, m) => {
      if (!this.state['checked_' + m]) {
        data.push(unRow)
      }
    })
    data = data.join(', ')

    //CHANGE 9/7/2019 REMOVE DOB REQUIRED & VALIDATE AFTER STATE SET
    this.setState(
      () => ({
        displayLoader: true,
        required_1: req1,
        required_2: req2,
      }),
      () => {
        if (this.state.required_1 && this.state.required_2) {
          actions
            .A_ImportData({
              clinicID: clinic,
              import_keys: this.state.importedDataList,
              importID: importID,
              rows_to_exclude: data,
            })
            .then(() => {
              this.setState({ loaderText: 'Importing Data' }, () => {
                history.push('/clinics/data-import-error-logs')
              })
            })
        } else {
          window.scroll(0, 0)
        }
      },
    )
  }

  toggleSelectAllCheckbox = () => {
    // let currentChecked = this.state.defaultChecked
    // currentChecked = currentChecked ? false : true
    this.setState({ defaultChecked: !this.state.defaultChecked })
    this.state.unMatchedRows.forEach((row, s) => {
      this.setState({ ['checked_' + s]: !this.state.defaultChecked })
    })
  }

  handleIndividualCheckClick = e => {
    let count = 0
    let updatedValue = !this.state['checked_' + e.target.id]
    this.setState({ ['checked_' + e.target.id]: updatedValue })
    if (!updatedValue) {
      this.setState({ defaultChecked: updatedValue })
    } else {
      this.state.unMatchedRows.forEach((row, s) => {
        if (this.state['checked_' + s]) {
          count++
        }
      })
    }
    if (count === this.state.unMatchedRows.length - 1) {
      this.setState({ defaultChecked: true })
    }
  }

  cancelImport = () => {
    this.setState({ clearImport: true })
  }

  closeAlert = () => {
    this.setState({ clearImport: false })
  }
  confirmCancelImport = e => {
    const { history } = this.props
    if (e.target.textContent === 'Yes') {
      history.push('/clinics/upload-file')
    } else {
      this.setState({ clearImport: false })
    }
  }

  render() {
    const { importData } = this.props
    return (
      <React.Fragment>
        {this.state.displayLoader &&
        this.state.required_1 &&
        this.state.required_2 ? (
          <Loader text="UPLOADING DATA" />
        ) : (
          <main className="main">
            <ProcessStepper
              step="2"
              stepText="Map patient rows"
              processText="Progress"
            />
            $
            {this.state.isLoading ? (
              <Loader text={this.state.loaderText} />
            ) : (
              <section className="main__inner">
                <section
                  className="patientImport__wrapper patientImport__wrapper--stageTwo"
                  stage="default"
                >
                  <form className="form patientImport__form">
                    <article className="patientImport__block">
                      <h2 className="h2 bold patientImport__h2">
                        {importData.fileHeaders
                          ? importData.fileHeaders.length
                          : null}{' '}
                        column(s) found
                      </h2>
                      <section className="patientImport__column">
                        <p className="p">
                          <span className="patientImport__red">*</span>Required
                          fields are marked with an asterix
                        </p>
                      </section>
                      <section className="patientImport__column">
                        <div className="patientImport__fileTag--Container">
                          <FileName
                            file={this.state.imported_file}
                            name={importData.importedFileName}
                            size={importData.importedFileSize}
                          />
                        </div>
                      </section>
                    </article>
                    <article className="patientImport__block">
                      <h2 className="h2 bold patientImport__h2">
                        Demographics
                      </h2>
                      <section className="patientImport__column">
                        <p className="form__label">CLEVER CLINIC COLUMN</p>
                        {Object.keys(Demographics).map(
                          function(index) {
                            return (
                              <section
                                key={index}
                                className="patientImport__row"
                              >
                                <FormLabel
                                  id={'formLabel_' + index}
                                  text={Demographics[index]}
                                  isMandatory={
                                    parseInt(index) === 1 ||
                                    parseInt(index) === 2
                                  }
                                  isGray={true}
                                  isGreen={
                                    this.state['selectedValue_' + index] &&
                                    this.state['selectedValue_' + index] !==
                                      'Please select...'
                                  }
                                />
                              </section>
                            )
                          }.bind(this),
                        )}
                      </section>
                      <section className="patientImport__column">
                        <p className="form__label">MATCHING IMPORTED FIELD</p>
                        {Object.keys(Demographics).map(
                          function(index) {
                            return (
                              <React.Fragment key={index}>
                                <SelectInput
                                  key={index}
                                  type="text"
                                  id={'rowID_' + index}
                                  options={this.state['rowID_' + index]}
                                  isColumnCell={true}
                                  isSmallMargin={true}
                                  inputSelect={'rowID_' + index}
                                  onChange={e =>
                                    this.selectRelaventOption(e, index)
                                  }
                                  defaultSelected={
                                    this.state['selectedValue_' + index]
                                      ? this.state['selectedID_' + index]
                                      : -1
                                  }
                                  error={
                                    parseInt(index) === 1
                                      ? this.state.required_1
                                        ? null
                                        : 'card'
                                      : parseInt(index) === 2
                                      ? this.state.required_2
                                        ? null
                                        : 'card'
                                      : null
                                  }
                                />
                              </React.Fragment>
                            )
                          }.bind(this),
                        )}
                      </section>
                    </article>
                    <article className="patientImport__block">
                      <h2 className="h2 bold patientImport__h2">Contact</h2>
                      <section className="patientImport__column">
                        <p className="form__label">CLEVER CLINIC COLUMN</p>
                        {Object.keys(ContactInfo).map(
                          function(index) {
                            let loopIndex =
                              Demographics.length + parseInt(index)
                            return (
                              <section
                                key={index}
                                className="patientImport__row"
                              >
                                <FormLabel
                                  id={'formLabel_' + loopIndex}
                                  text={ContactInfo[index]}
                                  isGray={true}
                                  isGreen={
                                    this.state['selectedValue_' + loopIndex] &&
                                    this.state['selectedValue_' + loopIndex] !==
                                      'Please select...'
                                      ? true
                                      : false
                                  }
                                />
                              </section>
                            )
                          }.bind(this),
                        )}
                      </section>
                      <section className="patientImport__column">
                        <p className="form__label">MATCHING IMPORTED FIELD</p>
                        {Object.keys(ContactInfo).map(
                          function(index) {
                            let loopIndex =
                              Demographics.length + parseInt(index)
                            return (
                              <SelectInput
                                key={index}
                                type="text"
                                id="test1"
                                options={this.state['rowID_' + loopIndex]}
                                defaultSelected={
                                  this.state['selectedValue_' + loopIndex]
                                    ? this.state['selectedID_' + loopIndex]
                                    : -1
                                }
                                isColumnCell={true}
                                isSmallMargin={true}
                                onChange={e => {
                                  this.selectRelaventOption(e, loopIndex)
                                }}
                              />
                            )
                          }.bind(this),
                        )}
                      </section>
                    </article>
                    <article className="patientImport__block">
                      <h2 className="h2 bold patientImport__h2">
                        Emergency contact
                      </h2>
                      <section className="patientImport__column">
                        <p className="form__label">CLEVER CLINIC COLUMN</p>
                        {Object.keys(EmergencyContact).map(
                          function(index) {
                            let loopIndex =
                              Demographics.length +
                              ContactInfo.length +
                              parseInt(index)
                            return (
                              <section
                                key={index}
                                className="patientImport__row"
                              >
                                <FormLabel
                                  id={'formLabel_' + loopIndex}
                                  text={EmergencyContact[index]}
                                  isGray={true}
                                  isGreen={
                                    this.state['selectedValue_' + loopIndex] &&
                                    this.state['selectedValue_' + loopIndex] !==
                                      'Please select...'
                                      ? true
                                      : false
                                  }
                                />
                              </section>
                            )
                          }.bind(this),
                        )}
                      </section>
                      <section className="patientImport__column">
                        <p className="form__label">MATCHING IMPORTED FIELD</p>
                        {Object.keys(EmergencyContact).map(
                          function(index) {
                            let loopIndex =
                              Demographics.length +
                              ContactInfo.length +
                              parseInt(index)
                            return (
                              <SelectInput
                                key={index}
                                type="text"
                                id="test1"
                                options={this.state['rowID_' + loopIndex]}
                                isSmallMargin={true}
                                isColumnCell={true}
                                onChange={e => {
                                  this.selectRelaventOption(e, loopIndex)
                                }}
                                defaultSelected={
                                  this.state['selectedValue_' + loopIndex]
                                    ? this.state['selectedID_' + loopIndex]
                                    : -1
                                }
                              />
                            )
                          }.bind(this),
                        )}
                      </section>
                    </article>
                    <article className="patientImport__block">
                      <h2 className="h2 bold patientImport__h2">
                        GP information
                      </h2>
                      <section className="patientImport__column">
                        <p className="form__label">CLEVER CLINIC COLUMN</p>
                        {Object.keys(GPInfo).map(
                          function(index) {
                            let loopIndex =
                              Demographics.length +
                              ContactInfo.length +
                              EmergencyContact.length +
                              parseInt(index)
                            return (
                              <section
                                key={index}
                                className="patientImport__row"
                              >
                                <FormLabel
                                  id={'formLabel_' + loopIndex}
                                  text={GPInfo[index]}
                                  isGray={true}
                                  isGreen={
                                    this.state['selectedValue_' + loopIndex] &&
                                    this.state['selectedValue_' + loopIndex] !==
                                      'Please select...'
                                      ? true
                                      : false
                                  }
                                />
                              </section>
                            )
                          }.bind(this),
                        )}
                      </section>
                      <section className="patientImport__column">
                        <p className="form__label">MATCHING IMPORTED FIELD</p>
                        {Object.keys(GPInfo).map(
                          function(index) {
                            let loopIndex =
                              Demographics.length +
                              ContactInfo.length +
                              EmergencyContact.length +
                              parseInt(index)
                            return (
                              <SelectInput
                                key={index}
                                type="text"
                                id="test1"
                                options={this.state['rowID_' + loopIndex]}
                                isSmallMargin={true}
                                isColumnCell={true}
                                onChange={e => {
                                  this.selectRelaventOption(e, loopIndex)
                                }}
                                defaultSelected={
                                  this.state['selectedValue_' + loopIndex]
                                    ? this.state['selectedID_' + loopIndex]
                                    : -1
                                }
                              />
                            )
                          }.bind(this),
                        )}
                      </section>
                    </article>
                    <h2 className="h2 bold patientImport__h2">
                      Registered clinic
                    </h2>
                    <p className="p">
                      All patients imported will automatically be aligned to
                      your primary clinic
                    </p>
                  </form>
                </section>
                <UnMatchedFields
                  unMatchedrows={this.state.unMatchedRows}
                  defaultChecked={this.state.defaultChecked}
                  toggleSelectAllChecked={this.toggleSelectAllCheckbox}
                  toggleSingleChecked={this.handleIndividualCheckClick}
                  selectedCheck={this.state}
                  importMatchedData={this.importMatchedData}
                  cancelImport={this.cancelImport}
                />
                {this.state.clearImport ? (
                  <AlertModal
                    message={
                      'No patients will be added to Clever Clinic and all import data will be lost'
                    }
                    title="Cancel patient import?"
                    leftButtons={[
                      {
                        type: 'button',
                        style: 'destructive',
                        label: 'Yes',
                        size: 'small',
                      },
                    ]}
                    rightButtons={[
                      {
                        type: 'button',
                        style: 'tertiary',
                        label: 'Cancel',
                        size: 'small',
                      },
                    ]}
                    closeAlert={this.closeAlert}
                    confirmCancelImport={this.confirmCancelImport}
                  />
                ) : null}
              </section>
            )}
          </main>
        )}
      </React.Fragment>
    )
  }
}
//

DataImport.propTypes = {
  importData: PropTypes.array.isRequired,
  history: PropTypes.array.isRequired,
  actions: PropTypes.isRequired,
  global: PropTypes.object.isRequired,
}

/** Need to change this as per need */
const mapStateToProps = state => {
  return {
    auth: state.auth,
    importData: state.dataImport,
    global: state.global,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetImportedFileData,
        A_UpdateFieldHeadersList,
        A_ImportData,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DataImport),
)
