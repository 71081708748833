import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Checkbox from '../../../forms/Checkbox'
import TextInput from '../../../forms/Input'
import SearchResults from '../../../Search/Results'
import DiscountModal from '../../DiscountModal'
import { productSearchNodes } from '../../../../utilities/ReusableObjects'
import { formatCurrency } from '../../../../utilities/ReusableFunctions'

const ProductInvoiceRow = ({
  product,
  applyDiscount,
  removeDiscount,
  discount,
  index,
  isNew,
  isEdit,
  productSearchValue,
  searchProducts,
  selectProduct,
  productList,
  removeProduct,
  isOdd,
  locale,
  toggleVat,
}) => {
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false)

  const openModal = () => {
    setIsDiscountModalOpen(true)
  }

  const closeModal = () => {
    setIsDiscountModalOpen(false)
  }

  const subTotalValue = formatCurrency(product?.subtotal ?? 0.0, locale)
  const discountValue = formatCurrency(product?.discount ?? 0.0, locale)
  const vatValue = formatCurrency(product?.vatAmount ?? 0.0, locale)
  const totalValue = formatCurrency(product?.total ?? 0.0, locale)

  const isChecked = product?.item.vat ?? false
  const handleCheck = () => {
    toggleVat(index)
  }

  let icon = 'add'

  if (discount) {
    icon = 'minus'
  }

  let buttonClass = 'icon-' + icon + ' buttonCircle buttonCircle--flex'

  return (
    <tr
      className={`${
        isOdd ? 'invoice__firstRow' : 'invoice__secondRow--product'
      }`}
    >
      <td className="bold">
        {isNew || isEdit ? (
          <>
            <div className="search_wrapper">
              <TextInput
                type="text"
                placeholder="Search"
                leftIcon="icon-magnifying-glass"
                noMargin={true}
                value={productSearchValue}
                onChange={e => searchProducts(e)}
              />
            </div>
            {productSearchValue.length > 0 && productList.length > 0 ? (
              <div className="searchResults_wrapper searchResults_wrapper--inRow">
                <SearchResults
                  data={productList}
                  idField="id"
                  nodeStructures={productSearchNodes}
                  searchValue={productSearchValue}
                  onSelect={e => selectProduct(e)}
                />
              </div>
            ) : (
              ''
            )}
          </>
        ) : (
          product.title
        )}
      </td>
      <td>{subTotalValue}</td>
      <td>
        <div className="invoiceCard__inline">
          {
            <>
              <button
                className={buttonClass}
                disabled={!isNew && !isEdit ? '' : 'disabled'}
                onClick={() =>
                  isDiscountModalOpen
                    ? closeModal()
                    : discount
                    ? removeDiscount(index)
                    : openModal()
                }
              />
              <DiscountModal
                isDiscountModalOpen={isDiscountModalOpen}
                closeModal={closeModal}
                locale={locale}
                discount={discount}
                applyDiscount={d => applyDiscount(index, d)}
              />
            </>
          }
          ({discountValue})
        </div>
      </td>
      <td>
        <div className="invoiceCard__inline">
          <Checkbox
            classContainer="invoiceCard__inline__vat"
            onChange={handleCheck}
            checked={isChecked}
            isListItem={true}
            disabled={!isNew && !isEdit ? '' : 'disabled'}
          />
          {vatValue}
        </div>
      </td>
      <td className="bold">{totalValue} </td>
      <td className="bold buttonCircle--containerBG">
        <button
          className="buttonCircle buttonCircle--flex icon-minus button--autoLeft"
          onClick={() => removeProduct(index)}
        />
      </td>
    </tr>
  )
}

ProductInvoiceRow.defaultProps = {
  isNew: false,
  isEdit: false,
  productSearchValue: '',
  product: null,
  productList: [],
  isOdd: true,
  discount: null,
  index: null,
  selectProduct: () => {},
  searchProducts: () => {},
}

ProductInvoiceRow.propTypes = {
  isNew: PropTypes.bool,
  isEdit: PropTypes.bool,
  productSearchValue: PropTypes.string,
  searchProducts: PropTypes.func,
  selectProduct: PropTypes.func,
  productList: PropTypes.array,
  removeProduct: PropTypes.func.isRequired,
  isOdd: PropTypes.bool,
  product: PropTypes.object,
  locale: PropTypes.string.isRequired,
  applyDiscount: PropTypes.func.isRequired,
  removeDiscount: PropTypes.func.isRequired,
  discount: PropTypes.object,
  index: PropTypes.number,
  toggleVat: PropTypes.func.isRequired,
}

export default ProductInvoiceRow
