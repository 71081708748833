import React from 'react'

export default function LoadingSlots() {
  return (
    <ul className="radio__group" role="group">
      {[...Array(7).keys()].map(i => {
        return (
          <li key={i} className="radioButton radioButton--fullWidth">
            <div className="roundedToggleButton roundedToggleButton--stacked skeletonLoad skeletonLoad--toggleButton" />
          </li>
        )
      })}
    </ul>
  )
}
