import types from '../../actions/types'

var initialState = {
  notificationsList: [],
  prevQuery: null,
  nextQuery: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_NOTIFICATION_LIST.FAIL:
      return { ...state }
    case types.GET_NOTIFICATION_LIST.OK: {
      const prevQuery = action.data.previous
        ? action.data.previous.substring(
            action.data.previous.indexOf('?'),
            action.data.previous.length,
          )
        : null
      let nextQuery = action.data.next
        ? action.data.next.substring(
            action.data.next.indexOf('?') + 1,
            action.data.next.length,
          )
        : null
      let notifications = action.data.results
      if (action.checkUnread === 'checkUnread') {
        if (
          action.data.results.length > 0 &&
          state.notificationsList.length > 0 &&
          action.data.results[0].id === state.notificationsList[0].id
        ) {
          notifications = state.notificationsList
        }
        nextQuery = null
      }
      if (action.nextQuery) {
        notifications = [...state.notificationsList, ...action.data.results]
      }
      return {
        ...state,
        prevQuery: prevQuery,
        nextQuery: nextQuery,
        notificationsList: notifications,
      }
    }
    case types.MARK_NOTIFICATION_READ.OK:
      return { ...state }
    case types.MARK_NOTIFICATION_READ.FAIL:
      return { ...state }
    case types.POST_NOTIFICATION.FAIL:
      return { ...state }
    case types.POST_NOTIFICATION.OK:
      return { ...state }
    default:
      return state
  }
}
