import React from 'react'
import StaffBlock from '../../components/Staff/'

class Staff extends React.Component {
  render() {
    return <StaffBlock />
  }
}

export default Staff
