import React, { Component } from 'react'
import { withRouter /*, Link */ } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Input from '../../components/forms/fileUpload'
import FileUploadComponent from '../../components/PatientData/uploadFile'
import LeftTutorial from '../../components/PatientData/tutorial'
import UploadError from '../../components/PatientData/uploadError'
import Button from '../../components/Buttons'
import Loader from '../../components/PatientData/Loader'
import ProcessStepper from '../../components/ProcessStepper'
import { A_ImportCSVFile, A_ImportData } from '../../actions'
import PropTypes from 'prop-types'

class File_Import extends Component {
  constructor(props) {
    super(props)
    this.state = {
      displayLoader: false,
      displayImport: true,
      displayError: false,
    }
  }

  uploadFile = fileInput => {
    const { history, actions, global } = this.props

    const clinic = global.currentClinicID
    if (
      fileInput.files[0].type === 'text/csv' ||
      fileInput.files[0].name.indexOf('.csv') > -1
    ) {
      this.setState({
        displayLoader: true,
        displayImport: false,
        displayError: false,
      })
      const formData = new FormData()
      formData.append('imported_file', fileInput.files[0])
      actions
        .A_ImportCSVFile({ clinicID: clinic, imported_file: formData })
        .then(res => {
          if (res.data) {
            this.setState({
              displayError: true,
              displayLoader: false,
              displayImport: false,
              errorText: res.data.detail,
            })
          } else {
            localStorage.setItem('importID', res.id)
            this.setState({ displayError: false }, () => {
              this.timer = setTimeout(function() {
                history.push('/clinics/upload-data')
              }, 1500)
            })
          }
        })
    } else {
      this.setState({
        displayLoader: true,
        displayImport: false,
        displayError: true,
      })
    }
  }
  resetStateValue = () => {
    this.setState({
      displayImport: true,
      displayError: false,
      displayLoader: false,
    })
  }

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = 0
    }
  }

  render() {
    return (
      <React.Fragment>
        <main className="main">
          <ProcessStepper
            step="1"
            stepText="Upload file"
            processText="Progress"
          />
          <section className="main__inner">
            <section className="patientImport__wrapper" stage="default">
              <LeftTutorial />
              <article className="form patientImport__container--twoThirds">
                {this.state.displayImport ? (
                  <React.Fragment>
                    <FileUploadComponent label="Upload file" />
                    <Input
                      label="File upload"
                      styleType="secondary"
                      size="small"
                      labelID="uploadLabel"
                      inputID="inputUpload"
                      fileHandler={e => {
                        this.uploadFile(e.target)
                      }}
                      accept=".csv, text/csv"
                    />
                  </React.Fragment>
                ) : this.state.displayLoader ? (
                  <Loader text="CHECKING FILE" />
                ) : this.state.displayError ? (
                  <React.Fragment>
                    <UploadError errorText={this.state.errorText} />
                    <Button
                      styleType="secondary"
                      size="small"
                      label="Try again"
                      onClick={this.resetStateValue}
                    />
                  </React.Fragment>
                ) : (
                  ''
                )}
              </article>
            </section>
          </section>
        </main>
      </React.Fragment>
    )
  }
}

File_Import.propTypes = {
  history: PropTypes.object.isRequired,
  actions: PropTypes.isRequired,
  global: PropTypes.object.isRequired,
}

/** Need to change this as per need */
const mapStateToProps = state => {
  return {
    data: state.clinicDetails,
    global: state.global,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_ImportCSVFile,
        A_ImportData,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(File_Import),
)
