import React from 'react'
import PropTypes from 'prop-types'

class FeedbackNotification extends React.Component {
  constructor(props) {
    super(props)
    this.feedback = React.createRef()
  }

  componentDidMount() {
    const { isOnboarding } = this.props
    if (isOnboarding) {
      return
    }
    this.feedbackTimeout = setTimeout(() => {
      this.hideFeedback()
    }, 2500)
  }

  hideFeedback = () => {
    const { closeHandler, isWarning } = this.props
    closeHandler ? closeHandler() : '' //eslint-disable-line
    if (this.feedback.current) {
      this.feedback.current.classList.remove('fadeInFly--feedbackAnimate')
      this.feedback.current.classList.add('fadeOutFly--feedbackAnimate')
      if (!isWarning) {
        this.hideTimeout = setTimeout(() => {
          const { closeHandler, hideFeedback } = this.props
          closeHandler ? closeHandler() : '' //eslint-disable-line
          hideFeedback()
        }, 1600)
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.feedbackTimeout)
    clearTimeout(this.hideTimeout)
  }

  render() {
    const { id, isWarning, iserrorAlert, message } = this.props
    return (
      <React.Fragment>
        <article
          id={id}
          className={`feedback fadeInFly--feedbackAnimate ${
            isWarning ? 'warning_message' : ''
          }`}
          style={{ display: 'flex' }}
          ref={this.feedback}
        >
          <section className="feedback__innerWrapper">
            <span
              className={`feedback__icon ${
                isWarning
                  ? 'icon-warning'
                  : iserrorAlert
                  ? 'icon-alert'
                  : 'icon-tick-circle'
              }`}
            />
            <span className="feedback__message">
              <span className="bold">{message}</span>
            </span>
          </section>
          <button
            className="feedback__close icon-cross"
            onClick={this.hideFeedback}
          />
        </article>
      </React.Fragment>
    )
  }
}

FeedbackNotification.defaultProps = {
  id: '',
  message: '',
  isWarning: false,
  hideFeedback: () => {},
  isOnboarding: false,
  iserrorAlert: false,
  closeHandler: () => {},
}

FeedbackNotification.propTypes = {
  id: PropTypes.string,
  message: PropTypes.string,
  isWarning: PropTypes.bool,
  hideFeedback: PropTypes.func,
  isOnboarding: PropTypes.bool,
  iserrorAlert: PropTypes.bool,
  closeHandler: PropTypes.func,
}

export default FeedbackNotification
