import React from 'react'
import PropTypes from 'prop-types'

import SettingContent from '../../SettingContent'

import Credits from '../SMS/Credits'
import CallerIdentifier from '../SMS/CallerIdentifier'
import Voucher from '../SMS/Voucher'
import Bundles from '../SMS/Bundles'

import { formatCredit } from '../../../../utilities/ReusableFunctions'
import {
  isVoucherBundlesEnabled,
  isBundlesEnabled,
} from '../../../../utilities/featureToggle'

const SMSSetting = ({
  name,
  credits,
  account,
  bundles,
  updatedCallerId,
  setTooltip,
  setCallerId,
  isTooltipOpen,
  isFetching,
  updateCallerId,
  activeBundle,
  setBundle,
  setErrors,
  errors,
  voucherCode,
  setVoucherCode,
  applyVoucherCode,
  isAddingVoucher,
  token,
}) => {
  const hasSMS = Object.entries(account.sms).length

  return (
    <SettingContent
      name={name}
      desc={`You have sent a total of ${account.sms.sent || 0} messages`}
    >
      {hasSMS ? (
        <Credits
          credits={credits}
          formatCredits={formatCredit}
          companyName={account.company_name}
        />
      ) : null}
      {hasSMS ? (
        <CallerIdentifier
          updatedCallerId={updatedCallerId}
          setTooltip={setTooltip}
          setCallerId={setCallerId}
          isTooltipOpen={isTooltipOpen}
          isFetching={isFetching}
          updateCallerId={updateCallerId}
        />
      ) : null}
      {isVoucherBundlesEnabled ? (
        <Voucher
          voucherCode={voucherCode}
          setVoucherCode={setVoucherCode}
          applyVoucherCode={applyVoucherCode}
          isAddingVoucher={isAddingVoucher}
        />
      ) : (
        ''
      )}
      {isBundlesEnabled ? (
        <Bundles
          activeBundle={activeBundle}
          setBundle={setBundle}
          bundles={bundles}
          formatCredit={formatCredit}
          clinicGroupName={account.company_name}
          errors={errors}
          setErrors={setErrors}
          token={token}
        />
      ) : (
        <div className="settings--view settings--view-lower">
          <h2>Bundles coming soon...</h2>
        </div>
      )}
    </SettingContent>
  )
}

SMSSetting.defaultProps = {
  activeBundle: null,
  errors: {},
  token: {},
  updatedCallerId: '',
}

SMSSetting.propTypes = {
  activeBundle: PropTypes.object,
  name: PropTypes.string.isRequired,
  credits: PropTypes.number.isRequired,
  account: PropTypes.object.isRequired,
  bundles: PropTypes.array.isRequired,
  updatedCallerId: PropTypes.string,
  setTooltip: PropTypes.func.isRequired,
  setCallerId: PropTypes.func.isRequired,
  isTooltipOpen: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  updateCallerId: PropTypes.func.isRequired,
  setBundle: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  errors: PropTypes.object,
  voucherCode: PropTypes.string.isRequired,
  setVoucherCode: PropTypes.func.isRequired,
  applyVoucherCode: PropTypes.func.isRequired,
  isAddingVoucher: PropTypes.bool.isRequired,
  token: PropTypes.shape({}),
}

export default SMSSetting
