import React from 'react'
import PropTypes from 'prop-types'
import * as func from '../../../../utilities/ReusableFunctions'
import * as obj from '../../../../utilities/ReusableObjects'
import Avatar from '../../../Avatar'

const ClinicCard = ({ staffDetails }) => { //eslint-disable-line
  const hasAvatar = !staffDetails.clinician.needs_setup
  return (
    <div className="invoiceItem cellBlock__row">
      <div className="staffTable__email--container">
        {hasAvatar ? (
          <span className="tableCell__avatar">
            <Avatar
              src={staffDetails.clinician.signed_avatar}
              type="formImage"
              initials={func.getInitials(staffDetails.clinician)}
            />
          </span>
        ) : (
          ''
        )}

        <span
          className={`${
            hasAvatar ? 'tableCell__avatarText' : ''
          } tableCell__avatarText--plainText`}
        >
          <span className="staffTable__name">
            {func.clinicianName(staffDetails)}
          </span>
          <span className="staffTable__email">
            {obj.jobTypeNames[staffDetails.clinician.job_type]}
          </span>
        </span>
      </div>
      {staffDetails.clinician.invite.account_owner ? (
        <span className="smallLabel__card">ACCOUNT ADMIN</span>
      ) : staffDetails.clinician.invite.is_admin ? (
        <span className="smallLabel__card">ADMIN</span>
      ) : (
        ''
      )}
    </div>
  )
}

ClinicCard.propTypes = {
  staffDetails: PropTypes.object.isRequired,
}

export default ClinicCard
