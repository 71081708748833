import Promise from 'promise'
import * as api from '../api'
import * as url from '../../api_urls'

export const getInventoryList = id => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.clinics + id + url.inventory)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const postInventoryDetails = (clinicID, productDetails) => {
  return new Promise((resolve, reject) => {
    return api
      .post(url.clinics + clinicID + '/inventory/', productDetails)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const patchEditInventoryDetails = (
  clinicID,
  productID,
  productDetails,
) => {
  return new Promise((resolve, reject) => {
    return api
      .patch(
        url.clinics + clinicID + '/inventory/' + productID + '/',
        productDetails,
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const deleteInventoryDetails = (clinicID, productID) => {
  return new Promise((resolve, reject) => {
    return api
      .remove(url.clinics + clinicID + '/inventory/' + productID + '/')
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getInventoryOptionList = (clinicID, productID) => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.clinics + clinicID + '/inventory/' + productID + '/options/')
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const postInventoryOptionDetails = (
  clinicID,
  productID,
  optionDetails,
) => {
  return new Promise((resolve, reject) => {
    return api
      .post(
        url.clinics + clinicID + '/inventory/' + productID + '/options/',
        optionDetails,
      )
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const patchEditInventoryOptionDetails = (
  clinicID,
  productID,
  optionID,
  optionDetails,
) => {
  return new Promise((resolve, reject) => {
    return api
      .patch(
        url.clinics +
          clinicID +
          '/inventory/' +
          productID +
          '/options/' +
          optionID +
          '/',
        optionDetails,
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const deleteInventoryOptionDetails = (clinicID, productID, optionID) => {
  return new Promise((resolve, reject) => {
    return api
      .remove(
        url.clinics +
          clinicID +
          '/inventory/' +
          productID +
          '/options/' +
          optionID +
          '/',
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
