import {
  emailRegex,
  postcodeRegex,
  passwordRegex,
  phoneErrorMessage,
} from '../../../utilities/ReusableObjects'
import { isValidNumber, parseNumber } from 'libphonenumber-js'

export const validateNotEmpty = e => {
  const formatted = e ? e.trim() : ''

  return {
    initial: e,
    formatted: formatted,
    valid: formatted.length > 0,
    error: 'Required',
  }
}

export const validatePhoneNumber = e => {
  const formatted = e.trim()

  const parsed = parseNumber(formatted)

  if (!parsed.country) {
    return {
      intial: e,
      formatted: formatted,
      valid: false,
      error: 'Not a valid phone number',
      message: phoneErrorMessage,
    }
  }
  return {
    intial: e,
    formatted: formatted,
    valid: isValidNumber(formatted, parsed.country),
    error: 'Not a valid phone number',
  }
}

export const validateEmailAddress = e => {
  const formatted = e.trim().toLowerCase()

  return {
    intial: e,
    formatted: formatted,
    valid: emailRegex.test(formatted),
    error: 'Not a valid email address',
  }
}

export const validatePostcode = e => {
  const formatted = e
    .trim()
    .replace(/\s/g, '')
    .toUpperCase()

  return {
    intial: e,
    formatted: formatted,
    valid: postcodeRegex.test(formatted),
    error: 'Not a valid postcode',
  }
}

export const validatePassword = e => {
  return {
    initial: e,
    formatted: e,
    valid: passwordRegex.test(e),
    error: 'Password not strong enough',
  }
}

export const validateNumber = e => {
  const formatted = parseFloat(e)

  return {
    initial: e,
    formatted: formatted,
    valid: isNaN(formatted) === false,
    error: 'Not a valid number',
  }
}
