import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useDispatch } from 'react-redux'

import CreateEdit from './CreateEdit'
import View from './View'
import AppointmentPurpose from './CreateEdit/Appointment/AppointmentPurpose'

import {
  appointmentModalTypes,
  appointmentReasonTitles,
} from '../../../../utilities/ReusableObjects'

import {
  A_FetchTreatmentTypeList,
  A_FetchBookedAppointmentDetails,
  A_ClearBookedAppointmentDetails,
  A_ClearAvailableSlotList,
  A_ClearFilteredPatientList,
} from '../../../../actions'

export default function AppointmentContent({
  type,
  appointmentId,
  clinicId,
  appointmentPurpose,
  setAppointmentPurpose,
  isPurposesDisabled,
  appointmentDetails,
  appointmentAlerts,
  selectedPatient,
  setPatient,
  treatments,
  filteredTreatments,
  treatmentTypes,
  setTreatments,
  equipment,
  setEquipment,
  rooms,
  errors,
  setError,
  timezone,
  isReBookingAppointment,
  isOutstandingAction,
  title,
  setTitle,
  isVirtual,
  setIsVirtual,
}) {
  const { CREATE, EDIT, VIEW } = appointmentModalTypes
  const { APPOINTMENT } = appointmentReasonTitles
  const dispatch = useDispatch()
  const [isTreatmentOptionsOpen, setTreatmentOptions] = useState(false)
  const [isEquipmentOptionsOpen, setEquipmentOptions] = useState(false)

  useEffect(() => {
    const fetchAppointment = () => {
      if (type === VIEW)
        dispatch(A_FetchBookedAppointmentDetails(appointmentId))
    }
    fetchAppointment()
  }, [type])

  useEffect(() => {
    setTreatments(filteredTreatments)
  }, [filteredTreatments])

  useEffect(() => {
    if (appointmentPurpose === APPOINTMENT) {
      const fetchTreatmentTypes = async () => {
        dispatch(A_FetchTreatmentTypeList(clinicId))
      }
      fetchTreatmentTypes()
    }
    return () => {
      dispatch(A_ClearBookedAppointmentDetails())
      dispatch(A_ClearAvailableSlotList())
      dispatch(A_ClearFilteredPatientList())
    }
  }, [])

  const toggleTreatments = treatmentId => {
    let newOptions = [...treatments]
    let selectedOption = newOptions.find(({ id }) => id === Number(treatmentId))
    selectedOption.checked =
      selectedOption.checked === 'checked' ? '' : 'checked'
    setTreatments(newOptions)
  }

  const toggleEquipment = equipmentId => {
    let newOptions = [...equipment]
    let selectedOption = newOptions.find(({ id }) => id === Number(equipmentId))
    selectedOption.checked =
      selectedOption.checked === 'checked' ? '' : 'checked'
    setEquipment(newOptions)
  }

  return (
    <>
      {type === CREATE || type === EDIT ? (
        <>
          <AppointmentPurpose
            type={type}
            appointmentPurpose={appointmentPurpose}
            setAppointmentPurpose={setAppointmentPurpose}
            isPurposesDisabled={isPurposesDisabled}
            isVirtual={isVirtual}
            setIsVirtual={setIsVirtual}
          />
          <CreateEdit
            type={type}
            appointmentDetails={appointmentDetails}
            appointmentPurpose={appointmentPurpose}
            clinicId={clinicId}
            rooms={rooms}
            treatmentTypes={treatmentTypes}
            treatmentHandler={{
              treatments,
              isTreatmentOptionsOpen,
              setTreatmentOptions,
              toggleTreatments,
            }}
            equipmentHandler={{
              isEquipmentOptionsOpen,
              equipment,
              setEquipmentOptions,
              toggleEquipment,
            }}
            setAppointmentPurpose={setAppointmentPurpose}
            patient={selectedPatient}
            setPatient={setPatient}
            errors={errors}
            setError={setError}
            isReBookingAppointment={isReBookingAppointment}
            isOutstandingAction={isOutstandingAction}
            title={title}
            setTitle={setTitle}
            isVirtual={isVirtual}
          />
        </>
      ) : (
        <View
          appointmentDetails={appointmentDetails}
          appointmentAlerts={appointmentAlerts}
          appointmentPurpose={appointmentPurpose}
          timezone={timezone}
        />
      )}
    </>
  )
}

AppointmentContent.defaultProps = {
  appointmentId: null,
  appointmentDetails: {},
  appointmentAlerts: [],
  isPurposesDisabled: false,
  isReBookingAppointment: false,
  isOutstandingAction: false,
  title: '',
  selectedPatient: null,
  isVirtual: false,
}

AppointmentContent.propTypes = {
  type: PropTypes.string.isRequired,
  appointmentId: PropTypes.number,
  clinicId: PropTypes.number.isRequired,
  appointmentPurpose: PropTypes.string.isRequired,
  appointmentDetails: PropTypes.object,
  appointmentAlerts: PropTypes.array,
  setAppointmentPurpose: PropTypes.func.isRequired,
  selectedPatient: PropTypes.object,
  treatments: PropTypes.array.isRequired,
  filteredTreatments: PropTypes.array.isRequired,
  treatmentTypes: PropTypes.array.isRequired,
  setTreatments: PropTypes.func.isRequired,
  equipment: PropTypes.array.isRequired,
  setEquipment: PropTypes.func.isRequired,
  rooms: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  setPatient: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  isPurposesDisabled: PropTypes.bool,
  isReBookingAppointment: PropTypes.bool,
  isOutstandingAction: PropTypes.bool,
  title: PropTypes.string,
  setTitle: PropTypes.func.isRequired,
  isVirtual: PropTypes.bool,
  setIsVirtual: PropTypes.func.isRequired,
}
