import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as func from '../../utilities/ReusableFunctions'
import dateFns from 'date-fns'
import { A_CalendarHeaderMonthActions } from '../../actions'
import SelectInput from '../../components/forms/Select'

class ScheduleHeader extends Component {
  constructor(props) {
    super(props)
    this.previousMonth = this.previousMonth.bind(this)
    this.nextMonth = this.nextMonth.bind(this)
    this.changeClinic = this.changeClinic.bind(this)
    this.getMonthHeader = this.getMonthHeader.bind(this)
  }

  getMonthHeader() {
    const {
      A_CalendarHeaderMonthActions,
      calendarDate,
      scheduleCalendarDateHeader,
    } = this.props
    let months = []
    for (let i = 0; i < 12; i++) {
      months.push(
        <button
          key={func.monthList[i]}
          onClick={() => {
            A_CalendarHeaderMonthActions(
              {
                month: func.monthList[i],
                year: scheduleCalendarDateHeader.year,
              },
              calendarDate,
            )
          }}
          className={`buttonTransparent buttonDate ${
            scheduleCalendarDateHeader.month === func.monthList[i]
              ? 'buttonTransparent--selected'
              : ''
          }`}
        >
          {func.monthList[i]}
          <span
            className={`today_indicator ${
              scheduleCalendarDateHeader.year ===
                func.getCurrentMonthandYear().year &&
              func.getCurrentMonthandYear().month === func.monthList[i]
                ? 'today_indicator--selected'
                : ''
            }`}
          />
        </button>,
      )
    }
    return months
  }

  render() {
    const { showScheduleChangeModal, enableHeaderButton, global } = this.props

    const clinic = global.currentClinicID
    const clinics = global.filterClinics

    return (
      <React.Fragment>
        <section className="calendarActions_wrapper">
          <article className="timePeriod__wrapper timePeriod__wrapper--schedule">
            <button
              className="timePeriod_arrowButton timePeriod_arrowButton--secondary icon-chevron-left"
              onClick={this.previousMonth}
            />
            {this.getMonthHeader()}
            <button
              className="timePeriod_arrowButton timePeriod_arrowButton--secondary icon-chevron-right"
              onClick={this.nextMonth}
            />
            <div className="calendarActions">
              <button
                type="button"
                className="buttonTertiary button--small schedule__manage"
                onClick={showScheduleChangeModal}
                disabled={!enableHeaderButton}
              >
                Manage schedule
              </button>
            </div>
          </article>
          <article className="calendarActions">
            <SelectInput
              id="filterClinicName"
              options={clinics}
              defaultSelected={clinic}
              hasOwnWrapper={true}
              label="Clinic"
              isLabelInline={true}
              hasImage={true}
              isInline={true}
              isCalendarHeader={true}
              onChange={this.changeClinic}
            />
          </article>
        </section>
      </React.Fragment>
    )
  }

  changeClinic(event) {
    const { changeClinic } = this.props
    changeClinic(event.currentTarget.value)
  }

  previousMonth() {
    const {
      scheduleCalendarDateHeader,
      A_CalendarHeaderMonthActions,
      calendarDate,
    } = this.props
    let month = func.getPrevMonth(scheduleCalendarDateHeader.month)
    let year = scheduleCalendarDateHeader.year
    if (month === 'Dec') {
      year = dateFns.format(
        dateFns.subYears(new Date(scheduleCalendarDateHeader.year), 1),
        'YYYY',
      )
    }
    A_CalendarHeaderMonthActions({ month: month, year: year }, calendarDate)
  }

  nextMonth() {
    const {
      scheduleCalendarDateHeader,
      A_CalendarHeaderMonthActions,
      calendarDate,
    } = this.props
    let month = func.getNextMonth(scheduleCalendarDateHeader.month)
    let year = scheduleCalendarDateHeader.year
    if (month === 'Jan') {
      year = dateFns.format(
        dateFns.addYears(new Date(scheduleCalendarDateHeader.year), 1),
        'YYYY',
      )
    }
    A_CalendarHeaderMonthActions({ month: month, year: year }, calendarDate)
  }
}

ScheduleHeader.defaultProps = {
  showScheduleChangeModal: () => {},
  calendarDate: null,
  changeClinic: () => {},
  enableHeaderButton: false,
}

ScheduleHeader.propTypes = {
  showScheduleChangeModal: PropTypes.func,
  calendarDate: PropTypes.instanceOf(Date),
  changeClinic: PropTypes.func,
  enableHeaderButton: PropTypes.bool,
  scheduleCalendarDateHeader: PropTypes.object.isRequired,
  A_CalendarHeaderMonthActions: PropTypes.func.isRequired,
  global: PropTypes.object.isRequired,
}

function mapState(state) {
  return {
    scheduleCalendarDateHeader: state.schedule.scheduleCalendarDateHeader,
    calendarDate: state.calendar.calendarDate,
    global: state.global,
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapState, { A_CalendarHeaderMonthActions })(ScheduleHeader),
)
