import React from 'react'
import PropTypes from 'prop-types'
import { initializeSingleDigit } from '../../utilities/ReusableFunctions'

const TimeSelector = ({
  calendarType,
  isPractitionerProfile,
  timeSlots,
  styleProps,
  selectedDate,
  currentDate,
  zoomStatus,
}) => {
  /*
   * TimeSelector is the left hand time divider axis
   * Build time divisions for time division side panel
   */
  const renderTimeDivision = () => {
    let styleClass = `timeDivision ${
      calendarType === 'practitionerCalendar'
        ? 'timeDivision--practitioner'
        : ''
    }`

    if (!isPractitionerProfile) {
      let temp = 0
      let timeDividerArray = []
      let count = 0
      //Split into 24 hours
      for (var i = 0; i < 24; i++) {
        i = initializeSingleDigit(i)

        // Split into 15 minute divisions
        for (var j = 0; j < 4; j++) {
          // here we loop through the "child" arrays
          count++
          switch (j) {
            case 0:
              temp = '00'
              break
            case 1:
              temp = '15'
              break
            case 2:
              temp = '30'
              break
            case 3:
              temp = '45'
              break
            default:
              break
          }
          let containerClass = styleClass
          let textClass = 'timeDivision_text'

          //If start or end division apply extra classes
          if (i === '00' && temp === '00') {
            textClass += ' timeDivision_text--first'
          } else if (i === '23' && temp === '45') {
            containerClass += ' timeDivider--end'
            textClass += ' timeDivision--end'
          }
          if (zoomStatus === 'zoomedout') {
            containerClass += ' timeDivision--zoom'
          }
          timeDividerArray.push(
            <div key={count} className={containerClass}>
              <span className={textClass}>
                {i}.{temp}
              </span>
              <span className="timeDivision_border" />
            </div>,
          )
        }
      }
      return timeDividerArray
    } else {
      let timeArray = []
      let lengthTimeSlots = timeSlots.length

      for (let i = 0; i < lengthTimeSlots - 1; i++) {
        let time = timeSlots[i]
          .toString()
          .split(' ')[4]
          .substr(0, 5)
          .split(':')
          .join('.')
        let containerClass = styleClass
        let textClass = 'timeDivision_text'
        if (i === 0) {
          textClass += ' timeDivision_text--first'
        } else if (i === lengthTimeSlots - 2) {
          containerClass += ' timeDivider--end'
          textClass += ' timeDivision--end'
        }
        timeArray.push(
          <div key={i} className={containerClass} style={{ height: 140 }}>
            <span className={textClass}>{time}</span>
            <span className="timeDivision_border" />
          </div>,
        )
      }

      return timeArray
    }
  }

  return (
    <article
      className={
        calendarType === 'practitionerCalendar'
          ? 'time_selector time_selector--practitioner'
          : 'time_selector'
      }
    >
      {selectedDate === currentDate ? (
        <React.Fragment>
          <div
            className="currentTimeDivisionIndicator"
            style={{
              top: isPractitionerProfile
                ? styleProps.currentTimePos - 3
                : styleProps.divisionPos,
            }}
          />
          <div
            className="currentTimeIndicator currentTimeIndicator--division"
            style={{ top: styleProps.currentTimePos }}
          />
        </React.Fragment>
      ) : null}
      {renderTimeDivision()}
    </article>
  )
}

TimeSelector.defaultProps = {
  calendarType: 'weeklyCalendar',
  isPractitionerProfile: false,
  timeSlots: [],
  zoomStatus: 'zoomedin',
}

TimeSelector.propTypes = {
  calendarType: PropTypes.string,
  isPractitionerProfile: PropTypes.bool,
  timeSlots: PropTypes.array,
  styleProps: PropTypes.object.isRequired,
  selectedDate: PropTypes.string.isRequired,
  currentDate: PropTypes.string.isRequired,
  zoomStatus: PropTypes.oneOf(['zoomedin', 'zoomedout']),
}

export default TimeSelector
