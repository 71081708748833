import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import DatePickerInput from '../../../forms/datePickerInput'
import DatePickerRangeHHL from '../../../DatePicker/DatePickerRange'
import MultiSelect from '../../../forms/InputMultiSelect'
import Select from '../../../forms/Select'

import {
  PaymentType,
  paymentTypes,
  cardTypes,
} from '../../../../utilities/ReusableObjects'
import { financeFeatureToggle } from '../../../../utilities/featureToggle'
import { clinicsForLocale } from '../../../../utilities/ReusableFunctions'

export default function FinanceFilter({
  activeClinic,
  allClinics,
  clinicians,
  allClinicians,
  filterReport,
  toggleInvoiceStatuses,
  selectedInvoiceStatuses,
  toggleInvoiceTypes,
  selectedInvoiceTypes,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  locale,
  locales,
}) {
  const [clinic, setActiveClinic] = useState('')
  const [activeClinician, setActiveClinician] = useState('')
  const [activeTaken, setActiveTaken] = useState('')
  const [activeAssociated, setActiveAssociated] = useState('')
  const [paymentType, setPaymentType] = useState(paymentTypes[0].id)
  const [cardType, setCardType] = useState(cardTypes[0].id)

  const [isStartOpen, toggleStartPicker] = useState(false)
  const [isEndOpen, toggleEndPicker] = useState(false)
  const [isCardOpen, toggleCardSelector] = useState(false)
  const [isInvoiceOpen, toggleInvoiceSelector] = useState(false)
  const [isInvoiceTypesOpen, toggleInvoiceTypesSelector] = useState(false)
  const [activeLocale, setActiveLocale] = useState('')

  const startRef = useRef(null)
  const endRef = useRef(null)

  const datePickerRef = useRef(null)

  useEffect(() => setActiveClinic(activeClinic), [activeClinic])

  const toggleDatePicker = (e, pickerType) => {
    if (e) {
      if (pickerType === 'start') {
        setStartDate(e)
      } else if (pickerType === 'end') {
        setEndDate(e)
      }
    }
    toggleStartPicker(false)
    toggleEndPicker(false)
  }

  useEffect(() => setActiveLocale(locale), [locale])

  useEffect(() => {
    paymentTypes[0].id = ''
    paymentTypes[0].title = 'Show all'
    cardTypes[0].id = ''
    cardTypes[0].title = 'Show all'
    return () => {
      paymentTypes[0].id = ''
      paymentTypes[0].title = 'Please select...'
      cardTypes[0].id = ''
      cardTypes[0].title = 'Please select...'
    }
  })

  return (
    <form onSubmit={filterReport}>
      <section className="reportFilters__wrapper" ref={datePickerRef}>
        <article className="reportFilters__wrapper--inner">
          <div className="form__filterSection--dateinput">
            <label htmlFor="startDate" className="form__label">
              Start Date
            </label>
            <DatePickerInput
              id="startDate"
              value={moment(startDate).format('DD.MM.YYYY')}
              refVal={startRef}
              onClick={() => toggleStartPicker(!isStartOpen)}
              classList={`form__textField form__textField--right-icon datePicker__input noMargin ${
                isStartOpen ? 'form__textField--active' : ''
              }`}
            />
          </div>
          <div className="form__filterSection--dateinput">
            <label htmlFor="endDate" className="form__label">
              End Date
            </label>
            <DatePickerInput
              id="endDate"
              value={moment(endDate).format('DD.MM.YYYY')}
              refVal={endRef}
              onClick={() => toggleEndPicker(!isEndOpen)}
              classList={`form__textField form__textField--right-icon datePicker__input noMargin ${
                isEndOpen ? 'form__textField--active' : ''
              }`}
            />
          </div>
          {clinicsForLocale(allClinics, activeClinic, locale).locales.length >
          1 ? (
            <div className="form__filterSection--select">
              <Select
                id="locale"
                label="Locale"
                options={locales}
                value={activeLocale}
                isSmallMargin
                onChange={({ target: { value } }) => setActiveLocale(value)}
              />
            </div>
          ) : (
            ''
          )}
          <div className="form__filterSection--select">
            <Select
              id="clinic"
              label="Clinic"
              options={
                clinicsForLocale(allClinics, activeClinic, activeLocale).clinics
              }
              value={clinic}
              onChange={({ target: { value } }) => setActiveClinic(value)}
              isSmallMargin
            />
          </div>
          <div className="form__filterSection--select">
            <Select
              id="clinicianId"
              label="Clinician"
              options={clinicians}
              value={activeClinician}
              isSmallMargin
              onChange={({ target: { value } }) =>
                setActiveClinician(Number(value))
              }
            />
          </div>
          <div className="form__filterSection--select">
            <Select
              id="takenById"
              label="Taken By"
              options={allClinicians}
              value={activeTaken}
              isSmallMargin
              onChange={({ target: { value } }) =>
                setActiveTaken(Number(value))
              }
            />
          </div>
          <div className="form__filterSection--select">
            <Select
              id="associatedToId"
              label="Associated To"
              options={allClinicians}
              value={activeAssociated}
              isSmallMargin
              onChange={({ target: { value } }) =>
                setActiveAssociated(Number(value))
              }
            />
          </div>
          <div className="form__filterSection--select">
            <Select
              id="paymentType"
              label="Payment Type"
              options={paymentTypes}
              value={paymentType}
              isSmallMargin
              hasOwnWrapper={true}
              onChange={({ target: { value } }) => {
                setPaymentType(value)
                toggleCardSelector(parseInt(value) === PaymentType.CARD)
                return paymentType !== PaymentType.CARD ? setCardType('') : ''
              }}
            />
          </div>
          <div className="form__filterSection--select">
            <Select
              id="cardType"
              label="Card Type"
              options={cardTypes}
              value={cardType}
              isSmallMargin
              hasOwnWrapper={true}
              disabled={!isCardOpen}
              onChange={({ target: { value } }) => setCardType(value)}
            />
          </div>
          {financeFeatureToggle.isMultiFiltersEnabled ? (
            <>
              <article
                className="form__group--third__panel"
                style={{
                  width: '13%',
                  marginRight: '1.5rem',
                  marginTop: '15px',
                }}
              >
                <MultiSelect
                  isDynamic
                  id="invoiceTypes"
                  options={selectedInvoiceTypes}
                  label="Invoice type"
                  showSelector={isInvoiceTypesOpen}
                  showOptions={() => toggleInvoiceTypesSelector(true)}
                  closeSelector={() => toggleInvoiceTypesSelector(false)}
                  onOptionChange={({ target: { value } }) =>
                    toggleInvoiceTypes(value)
                  }
                  saveSelectorData={() => toggleInvoiceTypesSelector(false)}
                />
              </article>
              <article
                className="form__group--third__panel"
                style={{ width: '13%', marginTop: '15px' }}
              >
                <MultiSelect
                  isDynamic
                  id="invoiceOptions"
                  options={selectedInvoiceStatuses}
                  label="Invoice status"
                  showSelector={isInvoiceOpen}
                  showOptions={() => toggleInvoiceSelector(true)}
                  closeSelector={() => toggleInvoiceSelector(false)}
                  onOptionChange={({ target: { value } }) =>
                    toggleInvoiceStatuses(value)
                  }
                  saveSelectorData={() => toggleInvoiceSelector(false)}
                />
              </article>
            </>
          ) : null}
        </article>
        <button type="submit" className="buttonSecondary button--small">
          Apply
        </button>
      </section>
      {isStartOpen ? (
        <DatePickerRangeHHL
          selectedDate={startDate}
          startDate={startDate}
          endDate={endDate}
          dpInput={startRef.current}
          position="leftTop"
          container={datePickerRef.current}
          calendarType="manage_schedule_range"
          onHide={e => toggleDatePicker(e, 'start')}
        />
      ) : isEndOpen ? (
        <DatePickerRangeHHL
          selectedDate={endDate}
          startDate={startDate}
          endDate={endDate}
          dpInput={endRef.current}
          position="leftTop"
          container={datePickerRef.current}
          calendarType="manage_schedule_range"
          onHide={e => toggleDatePicker(e, 'end')}
        />
      ) : null}
    </form>
  )
}

FinanceFilter.defaultProps = {
  activeClinic: {},
}

FinanceFilter.propTypes = {
  activeClinic: PropTypes.object,
  allClinics: PropTypes.array.isRequired,
  clinicians: PropTypes.array.isRequired,
  allClinicians: PropTypes.array.isRequired,
  filterReport: PropTypes.func.isRequired,
  selectedInvoiceStatuses: PropTypes.array.isRequired,
  toggleInvoiceStatuses: PropTypes.func.isRequired,
  selectedInvoiceTypes: PropTypes.array.isRequired,
  toggleInvoiceTypes: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
  locales: PropTypes.array.isRequired,
  locale: PropTypes.string.isRequired,
}
