import React, { Component } from 'react'
import { withRouter /*, Link */ } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ProcessStepper from '../../components/ProcessStepper'
import ImportReview from '../../components/PatientData/rowList/index'
import { A_GetImportedFileData } from '../../actions'
import PropTypes from 'prop-types'
import Loader from '../../components/PatientData/Loader'

class DataImportErrorLogs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorCount: 0,
      displayLoader: true,
    }
  }

  checkImport = () => {
    const { actions, global } = this.props

    const clinic = global.currentClinicID
    let importID = localStorage.getItem('importID')
    actions
      .A_GetImportedFileData({ clinicID: clinic, importID: importID })
      .then(res => {
        if (res.status !== 1) {
          this.setState({
            displayLoader: false,
          })
        } else {
          setTimeout(() => {
            this.checkImport()
          }, 30000)
        }
      })
  }

  componentDidMount = () => {
    this.checkImport()
  }

  handlenewImport = () => {
    const { history } = this.props
    history.push('/clinics/upload-file')
  }

  render() {
    const { data } = this.props
    return (
      <React.Fragment>
        {this.state.displayLoader ? (
          <Loader text="Displaying Results" />
        ) : (
          <main className="main">
            <ProcessStepper step="3" stepText="Review" processText="Progress" />
            <section className="main__inner">
              <section className="patientImport__wrapper" stage="default">
                <ImportReview
                  errorCount={data.errountCount}
                  successCount={data.successCount}
                  downloadCsv={data.errorCsv}
                  newImport={this.handlenewImport}
                />
              </section>
            </section>
          </main>
        )}
      </React.Fragment>
    )
  }
}

DataImportErrorLogs.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.isRequired,
  history: PropTypes.object.isRequired,
  global: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
  return {
    data: state.dataImport,
    global: state.global,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetImportedFileData,
      },
      dispatch,
    ),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DataImportErrorLogs),
)
