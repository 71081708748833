import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { A_CalendarHeaderActions, A_SetViewingClinic } from '../../actions'
import SelectInput from '../../components/forms/Select'
import { bindActionCreators } from 'redux'
import moment from 'moment'

class CalendarHeader extends Component {
  changeDay(offset) {
    const { selectedDay, actions } = this.props
    let date = selectedDay.clone()

    if (offset < 0) {
      date.subtract(1, 'day')
    } else {
      date.add(1, 'day')
    }

    actions.A_CalendarHeaderActions(date)
  }

  /*
   *  Set previous week days when previous week selected
   */
  handleLeftButtonClick = () => this.changeDay(-1)

  /*
   *  Set following week days when next week selected
   */
  handleRightButtonClick = () => this.changeDay(1)

  /*
   *  Set clinic from drop down select change
   */
  changeClinic = event => {
    const { actions } = this.props
    actions.A_SetViewingClinic(Number(event.currentTarget.value))
  }

  render() {
    const {
      selectedDay,
      actions,
      weeklyCalendarDateHeader,
      global,
      isSameDay,
      toCurrentTime,
    } = this.props

    const clinic = global.currentClinicID
    const clinics = global.filterClinics

    return (
      <section className="calendarActions_wrapper no-print">
        <article className="timePeriod__wrapper">
          <button
            className="timePeriod_arrowButton icon-chevron-left"
            onClick={this.handleLeftButtonClick}
            disabled={`${clinics.length > 0 ? '' : 'disabled'}`}
          />
          {weeklyCalendarDateHeader
            ? weeklyCalendarDateHeader.map(date => (
                <button
                  disabled={`${clinics.length > 0 ? '' : 'disabled'}`}
                  className={`buttonTransparent buttonDate ${
                    date.isSame(selectedDay, 'day')
                      ? 'buttonTransparent--selected'
                      : ''
                  }`}
                  onClick={() => actions.A_CalendarHeaderActions(date)}
                  key={date}
                >
                  {`${date.format('ddd DD')}`}
                  <span
                    className={`today_indicator ${
                      isSameDay(date) ? 'today_indicator--selected' : ''
                    }`}
                  />
                </button>
              ))
            : null}
          <button
            className="timePeriod_arrowButton icon-chevron-right"
            onClick={this.handleRightButtonClick}
            disabled={`${clinics.length > 0 ? '' : 'disabled'}`}
          />
          <div className="calendarActions">
            <button
              disabled={`${clinics.length > 0 ? '' : 'disabled'}`}
              type="button"
              className="buttonTertiary button--small appointment__today"
              onClick={toCurrentTime}
            >
              Today
            </button>
          </div>
        </article>
        <article className="calendarActions">
          <SelectInput
            id="calendarClinicSelect"
            options={clinics}
            defaultSelected={clinic}
            hasOwnWrapper={true}
            label="Clinic"
            isLabelInline={true}
            hasImage={true}
            isInline={true}
            isCalendarHeader={true}
            onChange={this.changeClinic}
          />
        </article>
      </section>
    )
  }
}

CalendarHeader.propTypes = {
  actions: PropTypes.object.isRequired,
  weeklyCalendarDateHeader: PropTypes.array.isRequired,
  selectedDay: PropTypes.instanceOf(moment).isRequired,
  global: PropTypes.object.isRequired,
  toCurrentTime: PropTypes.func.isRequired,
  isSameDay: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  weeklyCalendarDateHeader: state.calendar.weeklyCalendarDateHeader,
  selectedDay: state.calendar.selectedDate,
  global: state.global,
})

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_CalendarHeaderActions,
        A_SetViewingClinic,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarHeader)
