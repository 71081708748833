import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import AppointmentsFilterHeading from '../../components/Reports/AppointmentsFilterHeading'
import ReportTable from '../../components/Reports/ReportsTable'
import MainButton from '../../components/Buttons'
import ExportCSV from '../../components/forms/ExportCSV'
import * as obj from '../../utilities/ReusableObjects'
import { A_GetClinicsList } from '../../actions'
import ChangeColumnModal from '../../components/Reports/changeColumns'
import Modal from '../../components/Modal'
import moment from 'moment'
import { getItemValue, getItems } from '../../utilities/ReusableFunctions'
import { TutorialContext } from '../../App'
import TutorialBtn from '../Tutorials/TutorialBtn'
import { isTutorialFeatureEnabled } from '../../utilities/featureToggle'
import Placeholder from '../../components/Tutorials/Placeholder'
import { getTasks } from '../../services/OnBoarding/auth'

class ReportAppointments extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formattedTable: [],
      coloumnList: obj.reportAppointmentsListcolumns,
      tableColumns: obj.reportAppointmentsStartHeader,
      displayTable: [],
    }
    this.reportTable = React.createRef()
  }

  componentDidMount = async () => {
    const { actions, history, global, formatTasks } = this.props

    const tasks = await getTasks()
    formatTasks(tasks)

    // ------ user must be admin or account admin
    if (global.is_admin) {
      actions.A_GetClinicsList().then(() => {
        this.formatTableData()
      })
    } else {
      history.push('/clinics/calendar/appointments')
    }
  }

  componentDidUpdate(prevProps) {
    const { reportData } = this.props
    if (reportData.reportTable.results && reportData !== prevProps.reportData) {
      this.formatTableData()
    }
  }

  formatTableData = () => {
    const { reportData } = this.props
    let tableData = reportData.reportTable.results

    let tableColumns = this.state.tableColumns
    let formattedTable = []
    let formattedRow = {}
    let appointmentType = ''
    let appointmentStatus = ''
    let time = ''
    let date = ''
    let gender = ''

    // ------- formatted data so the functions for changing columns in ResusableFunctions are simpler
    formattedTable = tableData.map(appointment => {
      const {
        clinic: { name, locale, timezone },
        patient,
      } = appointment

      appointmentType = getItemValue(appointment, 'purpose')

      const status = getItems('appointmentStatus').find(
        ({ id }) => appointment.status === id,
      )

      if (status) {
        appointmentStatus = status.title
      }

      gender = patient ? patient.gender : ''

      gender =
        gender !== ''
          ? getItems('gender').filter(({ id }) => gender === id)[0].title
          : ''

      if (appointment.start_date) {
        const momentDate = moment(appointment.start_date).tz(timezone)

        date = momentDate.format('DD.MM.YYYY')
        time = momentDate.format('HH:mm')
      }

      formattedRow = {
        id: patient ? patient.id : '',
        patient_name: patient ? patient.full_name : '',
        gender: gender,
        clinician: appointment.clinician ? appointment.clinician.full_name : '',
        clinic: name,
        appointment_type: appointmentType,
        appointment_status: appointmentStatus,
        date: date,
        time: time,
        locale: locale,
      }
      return formattedRow
    })

    const tableWidth = tableColumns.reduce((acc, val) => {
      return acc + Number(val.width)
    }, 0)

    const displayTable = this.displayTable(
      formattedTable,
      tableColumns,
      tableWidth,
    )
    this.setState({
      formattedTable: formattedTable,
      displayTable: displayTable,
      tableColumns: tableColumns,
    })
  }

  displayTable = (formattedTable, tableColumns, tableWidth) => {
    let data = formattedTable
    data.sort((a, b) => {
      if (a.date > b.date) {
        return 1
      } else if (a.date < b.date) {
        return -1
      } else {
        return 0
      }
    })
    let table = (
      <ReportTable
        data={data}
        idField="id"
        nodeStructures={tableColumns}
        tableWidth={tableWidth}
      />
    )
    return table
  }

  showColumnModal = e => {
    if (e) {
      this.setState({
        showColumnModal: !this.state.showColumnModal,
      })
    }
  }

  clickCheckbox = e => {
    let list = this.state.coloumnList
    if (e) {
      list.forEach(item => {
        item.rows.forEach(ele => {
          if (e.target.id === ele.heading) {
            ele.checked = !ele.checked
          }
        })
      })
      this.setState({
        coloumnList: list,
      })
    }
  }

  submitColumnChange = e => {
    let columnList = this.state.coloumnList
    let tableColumns = obj.reportStartHeader
    let newTableColumn = []

    if (e) {
      tableColumns.forEach(ele => {
        return columnList.forEach(item => {
          return item.rows.forEach(row => {
            if (row.checked && row.heading === ele.value) {
              newTableColumn.push(ele)
            }
          })
        })
      })

      const tableWidth = newTableColumn.reduce((acc, val) => {
        return acc + Number(val.width)
      }, 0)

      const displayTable = this.displayTable(
        this.state.formattedTable,
        newTableColumn,
        tableWidth,
      )
      this.setState({
        displayTable: displayTable,
        tableColumns: newTableColumn,
      })

      if (e.target) {
        this.showColumnModal(true)
      }
    }
  }
  getReportTable = () => {
    return this.reportTable.current
  }

  render() {
    const { reportData, hasCompletedAllTasks, activeTasks } = this.props
    return (
      <React.Fragment>
        <main className="main">
          {!hasCompletedAllTasks ? (
            <Placeholder activeTasks={activeTasks} />
          ) : null}
          <AppointmentsFilterHeading />
          <section className="main__inner">
            {reportData.reportTable.results &&
            reportData.reportTable.results.length > 0 ? (
              <div
                className="reportTable__container gutterMarginBottom"
                ref={this.reportTable}
              >
                {this.state.displayTable}
              </div>
            ) : (
              <div className="noResults_message--page noResults_message--centered">
                <p className="p noResults_message--page secondaryMarginBottom">
                  No information available for this filter criteria
                </p>
              </div>
            )}
            <aside className="clinicControls">
              <ExportCSV
                label="Export report"
                styleType="primary"
                size="small"
                table={this.getReportTable}
              />
              <span className="button--marginRight" />
              <MainButton
                styleType="tertiary"
                size="small"
                label="Change columns"
                onClick={e => this.showColumnModal(e)}
              />
            </aside>
          </section>
          {isTutorialFeatureEnabled ? (
            <TutorialContext.Consumer>
              {({
                toggleTutorial,
                setTutorial,
                tutorialList,
                history,
                activeTutorial,
              }) => (
                <TutorialBtn
                  toggleTutorial={toggleTutorial}
                  tutorialList={tutorialList}
                  setTutorial={setTutorial}
                  activeTutorial={activeTutorial}
                  history={history}
                />
              )}
            </TutorialContext.Consumer>
          ) : null}
        </main>
        {this.state.showColumnModal ? (
          <Modal
            id="changeColumnModal"
            title="Change report columns"
            closeModal={e => this.showColumnModal(e)}
            rightButtons={[
              {
                type: 'button',
                style: 'tertiary',
                label: 'Close',
                size: 'small',
                events: { onClick: e => this.showColumnModal(e) },
              },
              {
                type: 'button',
                style: 'secondary',
                label: 'Save',
                size: 'small',
                events: { onClick: e => this.submitColumnChange(e) },
              },
            ]}
          >
            <ChangeColumnModal
              data={this.state.coloumnList}
              checkboxHandler={e => this.clickCheckbox(e)}
            />
          </Modal>
        ) : (
          ''
        )}
      </React.Fragment>
    )
  }
}

ReportAppointments.propTypes = {
  reportData: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  global: PropTypes.object.isRequired,
  hasCompletedAllTasks: PropTypes.bool.isRequired,
  activeTasks: PropTypes.array.isRequired,
  formatTasks: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  return {
    reportData: state.reportAppointmentsTable,
    global: state.global,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetClinicsList,
      },
      dispatch,
    ),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReportAppointments),
)
