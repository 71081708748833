import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as modal from '../ModalOverlay'
import {
  getItems,
  mapOpeningTimesDays,
  getCurrentDateandWeek,
  getInitials,
  getFullName,
} from '../../utilities/ReusableFunctions'
import * as obj from '../../utilities/ReusableObjects'
import moment from 'moment'
import dateFns from 'date-fns'
import DatePickerRangeHHL from '../../components/DatePicker/DatePickerRange'
import RadioGroup from '../../components/forms/RadioGroup'
import {
  A_GetClinicsList,
  A_PostClinicScheduleChange,
  A_FetchClinicScheduleConflictList,
} from '../../actions/index'
import ErrorToolTipCard, {
  errorSpan,
  requiredSpan,
} from '../OnBoarding/FormErrorValidations'
import Avatar from '../../components/Avatar'
import SelectInput from '../../components/forms/Select'
import DatePickerInput from '../../components/forms/datePickerInput'

class ScheduleChange extends Component {
  constructor(props) {
    super(props)
    this.fromDateRef = React.createRef()
    this.toDateRef = React.createRef()
    this.endDateRef = React.createRef()
    this.recurrenceFormRef = null
    this.modalScrollAreaRef = null
    this.state = this.getInitialState()
    this.onScheduleChangeReasonChange = this.onScheduleChangeReasonChange.bind(
      this,
    )
    this.changeClinician = this.changeClinician.bind(this)
    this.changeLocation = this.changeLocation.bind(this)
    this.changeFromDate = this.changeFromDate.bind(this)
    this.showFromDatePicker = this.showFromDatePicker.bind(this)
    this.changeToDate = this.changeToDate.bind(this)
    this.showToDatePicker = this.showToDatePicker.bind(this)
    this.showEndDatePicker = this.showEndDatePicker.bind(this)
    this.changeFromTime = this.changeFromTime.bind(this)
    this.changeToTime = this.changeToTime.bind(this)
    this.changeEndDate = this.changeEndDate.bind(this)
    this.onScheduleChangeRepeatChange = this.onScheduleChangeRepeatChange.bind(
      this,
    )
    this.onScheduleChangeFrequencyChange = this.onScheduleChangeFrequencyChange.bind(
      this,
    )
    this.onScheduleRecurrenceEndChange = this.onScheduleRecurrenceEndChange.bind(
      this,
    )
    this.onScheduleFrequencyAmountChange = this.onScheduleFrequencyAmountChange.bind(
      this,
    )
    this.toggleRepeatOccurences = this.toggleRepeatOccurences.bind(this)
    this.toggleTimes = this.toggleTimes.bind(this)
    this.closeScheduleModal = this.closeScheduleModal.bind(this)
    this.postScheduleChange = this.postScheduleChange.bind(this)
    this.focusDatePicker = this.focusDatePicker.bind(this)
    this.onPickerFadeOutAnimationEnd = this.onPickerFadeOutAnimationEnd.bind(
      this,
    )
    this.getInitialState = this.getInitialState.bind(this)
    this.resetConflicts = this.resetConflicts.bind(this)
    this.getClinicOpeningTimes = this.getClinicOpeningTimes.bind(this)
    this.checkClinicIsOpen = this.checkClinicIsOpen.bind(this)
    this.hideClinicMessage = this.hideClinicMessage.bind(this)
    this.getOpeningTime = this.getOpeningTime.bind(this)
    this.getClosingTime = this.getClosingTime.bind(this)
    this.setRecurrenceFormRef = this.setRecurrenceFormRef.bind(this)
    this.setModalScrollAreaRef = this.setModalScrollAreaRef.bind(this)
  }

  getInitialState() {
    const {
      selectedReason,
      selectedClinician,
      selectedDate,
      global,
    } = this.props
    const openingTimes = mapOpeningTimesDays(global.currentClinic.opening_hours)
    const openingDay = openingTimes.filter(
      time =>
        time.dayName ===
        dateFns.format(getCurrentDateandWeek().currentDate, 'dddd'),
    )
    return {
      reqClinician: true,
      reqClinic: true,
      reqStartTime: true,
      reqEndTime: true,
      validTime: true,
      validDate: true,
      validRecurrenceDate: true,
      allValid: false,
      selectedReason: selectedReason !== -1 ? selectedReason : 0,
      selectedClinician: selectedClinician,
      selectedDays: Object.keys(obj.scheduleDaysShort)
        .map(day => obj.scheduleDaysShort[day])
        .splice(0, 5),
      selectedFrequency: 0,
      selectedFrequencyAmount: 0,
      isRecurring: false,
      reasons: getItems('reasons').filter((item, index) => index > 0),
      showFromPicker: false,
      showToPicker: false,
      showEndPicker: false,
      showTimes: true,
      clinicians: [],
      viewClinics: [],
      conflicts: [],
      noConflicts: false,
      clinicOpen: true,
      selectedFromDate: selectedDate,
      selectedToDate: selectedDate,
      selectedLocation: 0,
      selectedFromTime:
        openingDay.length > 0 ? openingDay[0].opens : '00:00:00',
      selectedToTime: openingDay.length > 0 ? openingDay[0].closes : '00:00:00',
      endRecurrenceDate: dateFns.addDays(
        getCurrentDateandWeek().currentDate,
        7,
      ),
      recurrenceIterations: 1,
      openingTimes: openingTimes,
    }
  }

  onScheduleChangeReasonChange(event) {
    this.setState({
      selectedReason: Number(event.currentTarget.value),
    })
  }

  changeClinician(event) {
    this.getClinicianClinics(Number(event.currentTarget.value))
    this.validateScheduleChange('clinician')
  }

  getClinicianClinics(id) {
    const { clinics } = this.props

    let visibleClinics = clinics.filter(function(clinic) {
      return clinic.invites.find(function(invite) {
        return invite.clinician !== null && invite.clinician.id === id
      })
    })

    let selectedClinic = visibleClinics[0]

    const openingHours = this.getClinicOpeningTimes(selectedClinic.id)
    const openingTime = openingHours.filter(
      time =>
        time.dayName === dateFns.format(this.state.selectedFromDate, 'dddd'),
    )
    const closingTime = openingHours.filter(
      time =>
        time.dayName === dateFns.format(this.state.selectedToDate, 'dddd'),
    )
    this.setState({
      selectedClinician: id,
      selectedLocation: selectedClinic.id,
      viewClinics: visibleClinics,
      selectedFromTime:
        openingTime.length > 0 ? openingTime[0].opens : '00:00:00',
      selectedToTime:
        closingTime.length > 0 ? closingTime[0].closes : '00:00:00',
    })
  }

  getClinicOpeningTimes(clinicId) {
    const { clinics } = this.props
    const clinic = clinics.find(function(item) {
      return item.id === clinicId
    })
    return mapOpeningTimesDays(clinic.opening_hours)
  }

  changeLocation(event) {
    const openingHours = this.getClinicOpeningTimes(
      Number(event.currentTarget.value),
    )
    const openingTime = openingHours.filter(
      time =>
        time.dayName === dateFns.format(this.state.selectedFromDate, 'dddd'),
    )
    const closingTime = openingHours.filter(
      time =>
        time.dayName === dateFns.format(this.state.selectedToDate, 'dddd'),
    )
    this.setState({
      selectedLocation: Number(event.currentTarget.value),
      openingTimes: openingHours,
      selectedFromTime:
        openingTime.length > 0 ? openingTime[0].opens : '00:00:00',
      selectedToTime:
        closingTime.length > 0 ? closingTime[0].closes : '00:00:00',
    })
    this.validateScheduleChange('clinic')
  }

  changeFromTime(event) {
    this.setState({
      selectedFromTime: event.currentTarget.value,
    })
    this.validateScheduleChange('startTime')
  }

  changeFromDate(day) {
    if (day) {
      const openingTime = this.state.openingTimes.filter(
        time => time.dayName === dateFns.format(day, 'dddd'),
      )
      let newState = {
        selectedFromDate: day,
        selectedFromTime:
          openingTime.length > 0 ? openingTime[0].opens : '00:00:00',
      }
      if (this.state.isRecurring) {
        const endRecurrence = this.calculateEndRecurrenceDate('startDate', day)
        newState.endRecurrenceDate = endRecurrence
      } else if (dateFns.isAfter(day, this.state.selectedToDate)) {
        newState.selectedToDate = day
        newState.selectedToTime =
          openingTime.length > 0 ? openingTime[0].closes : '00:00:00'
      }
      this.setState(newState)
    }
    this.showFromDatePicker()
  }

  changeEndDate(day) {
    if (day) {
      this.setState({
        endRecurrenceDate: day,
      })
    }
    this.showEndDatePicker()
  }

  showFromDatePicker() {
    this.setState({
      showFromPicker: !this.state.showFromPicker,
    })
  }

  changeToTime(event) {
    this.setState({
      selectedToTime: event.currentTarget.value,
    })
    this.validateScheduleChange('endTime')
  }

  changeToDate(day) {
    if (day) {
      const closingTime = this.state.openingTimes.filter(
        time => time.dayName === dateFns.format(day, 'dddd'),
      )
      this.setState({
        selectedToDate: day,
        selectedToTime:
          closingTime.length > 0 ? closingTime[0].closes : '00:00:00',
      })
    }
    this.showToDatePicker()
  }

  showToDatePicker() {
    this.setState({
      showToPicker: !this.state.showToPicker,
    })
  }

  showEndDatePicker() {
    this.setState({
      showEndPicker: !this.state.showEndPicker,
    })
  }

  focusDatePicker(event) {
    if (event.currentTarget.contains(event.relatedTarget)) {
      return
    }
    event.currentTarget.classList.remove('fadeInAnimateFull')
    event.currentTarget.classList.add('fadeOutAnimateFull')
    event.currentTarget.addEventListener(
      'animationend',
      this.onPickerFadeOutAnimationEnd,
    )
  }

  onPickerFadeOutAnimationEnd(event) {
    event.currentTarget.removeEventListener(
      'animationend',
      this.onPickerFadeOutAnimationEnd,
    )
    event.currentTarget.classList.add('hidden')
  }

  onScheduleChangeRepeatChange(event) {
    let selectedDays = this.state.selectedDays
    selectedDays.indexOf(event.target.value) < 0
      ? selectedDays.push(event.target.value)
      : selectedDays.splice(selectedDays.indexOf(event.target.value), 1)
    this.setState({
      selectedDays: selectedDays,
    })
    this.checkConflicts(false)
  }

  onScheduleChangeFrequencyChange(event) {
    const endRecurrence = this.calculateEndRecurrenceDate(
      'frequency',
      Number(event.currentTarget.value),
    )
    this.setState({
      selectedFrequency: Number(event.currentTarget.value),
      endRecurrenceDate: endRecurrence,
    })
    this.checkConflicts(false)
  }

  onScheduleRecurrenceEndChange(event) {
    const endRecurrence = this.calculateEndRecurrenceDate(
      'repetition',
      Number(event.currentTarget.value),
    )
    this.setState({
      recurrenceIterations: Number(event.currentTarget.value),
      endRecurrenceDate: endRecurrence,
    })
    this.checkConflicts(false)
  }

  onScheduleFrequencyAmountChange(event) {
    const endRecurrence = this.calculateEndRecurrenceDate(
      'frequencyAmount',
      Number(event.currentTarget.value),
    )
    this.setState({
      selectedFrequencyAmount: Number(event.currentTarget.value),
      endRecurrenceDate: endRecurrence,
    })
    this.checkConflicts(false)
  }

  calculateEndRecurrenceDate(changedKey, changedValue) {
    let recurrenceVars = {
      startDate: this.state.selectedFromDate,
      frequency: this.state.selectedFrequency,
      repetition: this.state.recurrenceIterations,
      frequencyAmount: this.state.selectedFrequencyAmount,
      isRecurring: this.state.isRecurring,
    }
    recurrenceVars[changedKey] = changedValue
    if (changedKey === 'isRecurring') {
      recurrenceVars.frequency = 1
      recurrenceVars.frequencyAmount = 1
    }
    if (changedKey === 'startDate') {
      recurrenceVars.startDate = changedValue
    }
    if (recurrenceVars.frequency === 1) {
      const days =
        recurrenceVars.repetition * 7 * recurrenceVars.frequencyAmount - 1
      return dateFns.addDays(recurrenceVars.startDate, days)
    }
    const months = recurrenceVars.repetition * recurrenceVars.frequencyAmount
    return dateFns.addMonths(recurrenceVars.startDate, months)
  }

  checkConflicts() {
    const { actions } = this.props
    if (this.getValidState()) {
      const fromTime = this.state.selectedFromTime.toString()
      const toTime = this.state.selectedToTime.toString()
      if (this.state.selectedClinician !== -1) {
        const clinician = this.state.selectedClinician
        const startDate =
          moment(this.state.selectedFromDate).format('YYYY-MM-DD') +
          'T' +
          (fromTime.length < 6 ? fromTime + ':00' : fromTime) +
          'Z'
        const endDate =
          moment(
            this.state.isRecurring
              ? this.state.endRecurrenceDate
              : this.state.selectedToDate,
          ).format('YYYY-MM-DD') +
          'T' +
          (toTime.length < 6 ? toTime + ':00' : toTime) +
          'Z'
        const isRecurring = this.state.isRecurring
        const interval = this.state.isRecurring
          ? this.state.selectedFrequency
          : '0'
        const every = this.state.isRecurring
          ? this.state.selectedFrequencyAmount
          : '0'
        const repeatOnQuery = this.state.isRecurring
          ? this.state.selectedDays.join(',')
          : []
        actions
          .A_FetchClinicScheduleConflictList(
            clinician,
            startDate,
            endDate,
            isRecurring,
            interval,
            every,
            repeatOnQuery,
          )
          .then(response => {
            if (response.status && response.status === 400) {
              this.setState({
                noConflicts: false,
                conflicts: [...response.data.errors.non_field_errors],
                allValid: false,
                clinicOpen: true,
              })
            } else {
              this.setState({
                noConflicts: true,
                conflicts: [],
                allValid: true,
                clinicOpen: this.checkClinicIsOpen(),
              })
            }
          })
      }
    }
  }

  resetConflicts() {
    this.setState({
      conflicts: [],
    })
  }

  hideClinicMessage() {
    this.setState({
      clinicOpen: true,
    })
  }

  checkClinicIsOpen() {
    if (this.state.selectedReason !== 0) {
      return true
    }
    const clinicTimes = this.getClinicOpeningTimes(this.state.selectedLocation)
    if (clinicTimes.length === 0) {
      return false
    }
    let clinicDays = dateFns.eachDay(
      new Date(this.state.selectedFromDate),
      new Date(this.state.selectedToDate),
    )
    if (this.state.isRecurring) {
      let scheduleDays = Object.keys(obj.scheduleDaysShort)
        .filter(key => {
          return (
            this.state.selectedDays.indexOf(obj.scheduleDaysShort[key]) > -1
          )
        })
        .map(dayKey => {
          return obj.scheduleDays[dayKey]
        })
      const scheduleDaysLength = scheduleDays.length
      for (let i = 0; i < scheduleDaysLength; i++) {
        if (
          !clinicTimes.filter(time => time.dayName === scheduleDays[i])[0]
            .is_open
        ) {
          return false
        }
      }
    }
    const clinicDaysLength = clinicDays.length
    for (let j = 0; j < clinicDaysLength; j++) {
      if (
        !clinicTimes.filter(
          time => time.dayName === dateFns.format(clinicDays[j], 'dddd'),
        )[0].is_open
      ) {
        return false
      }
    }
    return true
  }

  toggleRepeatOccurences() {
    let recurrenceForm = this.recurrenceFormRef
    if (this.state.isRecurring) {
      recurrenceForm.classList.add('fadeOutAnimateFull')
      recurrenceForm.classList.remove('fadeInAnimateFull')
      this.timer1 = setTimeout(function() {
        recurrenceForm.classList.add('hidden')
      }, 500 /* Length of CSS Animation */)
      this.setState({
        isRecurring: false,
      })
    } else {
      recurrenceForm.classList.remove('hidden')
      recurrenceForm.classList.remove('fadeOutAnimateFull')
      recurrenceForm.classList.add('fadeInAnimateFull')
      const endRecurrence = this.calculateEndRecurrenceDate('isRecurring', true)
      this.setState({
        isRecurring: true,
        selectedFrequency: 1,
        selectedFrequencyAmount: 1,
        endRecurrenceDate: endRecurrence,
      })
    }
  }

  toggleTimes(event) {
    if (event && event.currentTarget.checked) {
      const toDate = dateFns.addDays(this.state.selectedFromDate, 1)
      this.setState({
        selectedToDate: toDate,
        selectedFromTime: '00:00:00',
        selectedToTime: '00:00:00',
        showTimes: false,
      })
    } else {
      const openingTime = this.getOpeningTime(this.state.selectedFromDate)
      const closingTime = this.getClosingTime(this.state.selectedToDate)
      this.setState({
        selectedFromTime: openingTime,
        selectedToTime: closingTime,
        showTimes: true,
      })
    }
  }

  getOpeningTime(date) {
    const openingTime = this.state.openingTimes.filter(
      time => time.dayName === dateFns.format(date, 'dddd'),
    )
    return openingTime.length > 0 ? openingTime[0].opens : '00:00:00'
  }

  getClosingTime(date) {
    const openingTime = this.state.openingTimes.filter(
      time => time.dayName === dateFns.format(date, 'dddd'),
    )
    return openingTime.length > 0 ? openingTime[0].closes : '00:00:00'
  }

  validateScheduleChange(type) {
    let validationPassed = true
    let valClinic = true
    let valClinician = true
    let valTime = true
    let valDate = true
    let valRecurrence = true
    const startDate = new Date(
      moment(this.state.selectedFromDate).format('YYYY-MM-DD') +
        ' ' +
        this.state.selectedFromTime,
    )
    const endDate = new Date(
      moment(this.state.selectedToDate).format('YYYY-MM-DD') +
        ' ' +
        this.state.selectedToTime,
    )
    const currentDate = getCurrentDateandWeek().currentDate
    const startTimeDate = new Date(
      moment(currentDate).format('YYYY-MM-DD') +
        ' ' +
        this.state.selectedFromTime,
    )
    const endTimeDate = new Date(
      moment(currentDate).format('YYYY-MM-DD') +
        ' ' +
        this.state.selectedToTime,
    )
    const startTime = new Date(currentDate).setTime(startTimeDate.getTime())
    const endTime = new Date(currentDate).setTime(endTimeDate.getTime())
    const startRecurrenceDate = new Date(
      moment(this.state.selectedFromDate).format('YYYY-MM-DD') +
        ' ' +
        this.state.selectedFromTime,
    )
    const endRecurrenceDate = new Date(
      moment(this.state.endRecurrenceDate).format('YYYY-MM-DD') +
        ' ' +
        this.state.selectedToTime,
    )
    let reqStart = true
    let reqEnd = true
    switch (type) {
      case 'clinician':
        if (this.state.selectedClinician === -1) {
          valClinician = false
          validationPassed = false
        }
        if (valClinician !== this.state.reqClinician) {
          this.setState({
            reqClinician: valClinician,
            allValid: validationPassed,
          })
        }
        break
      case 'clinic':
        if (
          this.state.selectedReason === 0 &&
          this.state.selectedLocation === 0
        ) {
          valClinic = false
          validationPassed = false
        }
        if (
          this.state.viewClinics.length <= 1 &&
          this.state.selectedReason === 0
        ) {
          valClinic = false
          validationPassed = false
        }
        if (valClinic !== this.state.reqClinic) {
          this.setState({
            reqClinic: valClinic,
            allValid: validationPassed,
          })
        } else {
          this.setState({
            clinicOpen: true,
          })
        }
        break
      case 'date':
        if (startDate > endDate) {
          valDate = false
          validationPassed = false
        }
        if (valDate !== this.state.validDate) {
          this.setState({
            validDate: valDate,
            allValid: validationPassed,
          })
        }
        break
      case 'time':
        if (startTime > endTime) {
          valTime = false
          validationPassed = false
        }
        if (valTime !== this.state.validTime) {
          this.setState({
            validTime: valTime,
            allValid: validationPassed,
          })
        }
        break
      case 'startTime':
        if (this.state.selectedFromTime.length < 1) {
          reqStart = false
          validationPassed = false
        }
        if (reqStart !== this.state.reqStartTime) {
          this.setState({
            reqStartTime: reqStart,
            allValid: validationPassed,
          })
        }
        break
      case 'endTime':
        if (this.state.selectedToTime.length < 1) {
          reqEnd = false
          validationPassed = false
        }
        if (reqEnd !== this.state.reqEndTime) {
          this.setState({
            reqEndTime: reqEnd,
            allValid: validationPassed,
          })
        }
        break
      case 'recurrenceDate':
        if (startRecurrenceDate > endRecurrenceDate) {
          valRecurrence = false
          validationPassed = false
        }
        if (valRecurrence !== this.state.validRecurrenceDate) {
          this.setState({
            validRecurrenceDate: valRecurrence,
            allValid: validationPassed,
          })
        }
        break
      default:
        break
    }
    return validationPassed
  }

  getValidState() {
    if (
      this.validateScheduleChange('clinician') &&
      this.validateScheduleChange('clinic') &&
      this.validateScheduleChange('startTime') &&
      this.validateScheduleChange('endTime') &&
      this.validateScheduleChange('date') &&
      this.validateScheduleChange('time')
    ) {
      if (this.state.isRecurring) {
        if (!this.validateScheduleChange('recurrenceDate')) {
          return false
        }
      }
      return true
    }
    return false
  }

  postScheduleChange(event) {
    event.preventDefault()
    if (this.state.noConflicts && this.state.allValid) {
      this.postScheduleData()
    }
  }

  postScheduleData() {
    const { actions, onSave } = this.props
    let postData = {
      option: this.state.selectedReason,
      repeat_every: this.state.selectedFrequencyAmount,
      repeat_interval: this.state.selectedFrequency,
      repeat_recurrence: this.state.isRecurring,
      start_date:
        moment(this.state.selectedFromDate).format('YYYY-MM-DD') +
        'T' +
        this.state.selectedFromTime +
        'Z',
      end_date:
        moment(
          this.state.isRecurring
            ? this.state.endRecurrenceDate
            : this.state.selectedToDate,
        ).format('YYYY-MM-DD') +
        'T' +
        this.state.selectedToTime +
        'Z',
      repeat_on: this.state.selectedDays,
      location: {
        id:
          this.state.selectedReason === 0 ? this.state.selectedLocation : null,
      },
      clinician: {
        id: this.state.selectedClinician,
      },
    }
    actions.A_PostClinicScheduleChange(postData).then(() => {
      onSave()
    })
  }

  closeScheduleModal() {
    const { onClose } = this.props
    this.setState(this.getInitialState())
    modal.closeOverlayModal()
    this.timer2 = setTimeout(() => {
      onClose()
    }, 500)
  }

  componentDidUpdate(props, prevState) {
    if (prevState.selectedFromDate !== this.state.selectedFromDate) {
      if (this.state.isRecurring) {
        this.validateScheduleChange('recurrenceDate')
      } else {
        this.validateScheduleChange('date')
      }
      this.checkConflicts(false)
    }
    if (prevState.selectedToDate !== this.state.selectedToDate) {
      this.validateScheduleChange('date')
      this.checkConflicts(false)
    }
    if (this.state.selectedFromTime.length === 0) {
      this.validateScheduleChange('startTime')
    } else if (this.state.selectedToTime.length === 0) {
      this.validateScheduleChange('endTime')
    } else if (
      prevState.selectedFromTime !== this.state.selectedFromTime ||
      prevState.selectedToTime !== this.state.selectedToTime
    ) {
      this.validateScheduleChange('time')
      this.checkConflicts(false)
    }
    if (prevState.selectedClinician !== this.state.selectedClinician) {
      this.checkConflicts(false)
    }
    if (prevState.selectedLocation !== this.state.selectedLocation) {
      this.checkConflicts(false)
    }
    if (prevState.selectedReason !== this.state.selectedReason) {
      this.checkConflicts(false)
    }
    if (prevState.endRecurrenceDate !== this.state.endRecurrenceDate) {
      this.validateScheduleChange('recurrenceDate')
      this.checkConflicts(false)
    }
  }

  componentDidMount() {
    const { selectedCell, selectedClinician } = this.props
    if (selectedCell.duration < 2) {
      this.toggleTimes()
    }
    if (selectedClinician !== -1) {
      this.getClinicianClinics(selectedClinician)
    }
    this.modalScrollAreaRef.addEventListener(
      'scroll',
      this.onContainerScroll.bind(this),
    )
  }

  componentWillUnmount() {
    this.modalScrollAreaRef.removeEventListener(
      'scroll',
      this.onContainerScroll.bind(this),
    )
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = 0
    }
    if (this.timer1) {
      clearTimeout(this.timer1)
      this.timer1 = 0
    }
    if (this.timer2) {
      clearTimeout(this.timer2)
      this.timer2 = 0
    }
  }

  onContainerScroll() {
    if (this.state.showFromPicker) {
      this.setState({
        showFromPicker: !this.state.showFromPicker,
      })
    }
    if (this.state.showToPicker) {
      this.setState({
        showToPicker: !this.state.showToPicker,
      })
    }
    if (this.state.showEndPicker) {
      this.setState({
        showEndPicker: !this.state.showEndPicker,
      })
    }
  }

  setRecurrenceFormRef(element) {
    this.recurrenceFormRef = element
  }

  setModalScrollAreaRef(element) {
    this.modalScrollAreaRef = element
  }

  render() {
    const { clinicians, selectedReason } = this.props
    let selectedClinician = clinicians.filter(
      clinician => clinician.id === this.state.selectedClinician,
    )[0]
    let clinicianOptions = [
      { id: '-1', title: 'Please select...', isDisabled: true },
    ]
    const clinicianLength = clinicians.length
    for (let i = 0; i < clinicianLength; i++) {
      const item = clinicians[i]
      clinicianOptions.push({
        id: item.id.toString(),
        title: getFullName(item),
        isDisabled: false,
      })
    }
    const clinicOptions = this.state.viewClinics.map(clinic => {
      return {
        id: clinic.id,
        title: clinic.name,
        isDisabled: false,
      }
    })

    return (
      <React.Fragment>
        <article
          role="dialog"
          id="scheduleCalendarModal"
          className="modal modal--overlay col__12-7"
        >
          <form
            method="post"
            noValidate
            onSubmit={this.postScheduleChange}
            className="modal_flex_content"
          >
            <section className="modal__header">
              <h4 className="h3">Schedule manager</h4>
              <button
                className="icon-cross buttonCircle buttonCircle--card modal__closeButton modal__closeAction"
                onClick={this.closeScheduleModal}
              />
            </section>
            <section
              ref={this.setModalScrollAreaRef}
              className="modal__body modal__body--form"
            >
              <fieldset className="form__group--topStackable">
                <div className="form__label">SCHEDULE CHANGE</div>
                <RadioGroup
                  type="radio"
                  items={this.state.reasons}
                  name="reason"
                  defaultSelected={[this.state.selectedReason]}
                  inline={true}
                  optionid={selectedReason}
                  onChange={this.onScheduleChangeReasonChange}
                />
              </fieldset>
              <fieldset className="form__group--topStackable">
                <section className="form__group--autoWidth__panel createAppointment--right">
                  <article className="form__group--fixed__panel form__group--autoWidth__panel--left">
                    <label className="form__label" htmlFor="practitionerName">
                      {this.state.reqClinician ? '' : requiredSpan()}CLINICIAN
                    </label>
                    <section className="form_hasImage_wrapper">
                      <Avatar
                        src={
                          selectedClinician
                            ? selectedClinician.signed_avatar
                            : null
                        }
                        type="formImage"
                        initials={
                          selectedClinician
                            ? getInitials(selectedClinician)
                            : ''
                        }
                      />
                      <SelectInput
                        id="practitionerName"
                        options={clinicianOptions}
                        defaultSelected={
                          selectedClinician ? selectedClinician.id : '-1'
                        }
                        hasImage={true}
                        hasOwnWrapper={true}
                        onChange={this.changeClinician}
                      />
                    </section>
                  </article>
                  <article
                    className={`form__group--fixed__panel ${
                      this.state.selectedReason > 0 ? 'hidden' : ''
                    }`}
                  >
                    <SelectInput
                      id="clinic"
                      label="Working from"
                      options={clinicOptions}
                      defaultSelected={this.state.selectedLocation}
                      onChange={this.changeLocation}
                      error={this.state.reqClinic ? '' : 'required'}
                      disabled={this.state.viewClinics.length <= 1}
                    />
                  </article>
                </section>
              </fieldset>
              <fieldset className="form__group--stackable">
                <section className="form__group--flex-left form__group--flex-bottom createAppointment--right">
                  <article className="form__group--time__panel">
                    <label className="form__label" htmlFor="scheduleTimeFrom">
                      {this.state.isRecurring || this.state.validDate
                        ? ''
                        : errorSpan()}
                      DATE
                    </label>
                    <div className="form__group--flex-left">
                      <div className="form__group--date__panel primaryMarginBottom">
                        <section className="form__textField--has-icon">
                          <DatePickerInput
                            classList={`form__textField form__textField--right-icon datePicker__input noMargin ${
                              this.state.showFromPicker
                                ? 'form__textField--active'
                                : ''
                            }`}
                            id="scheduleFromDate"
                            noLabel={true}
                            value={dateFns.format(
                              this.state.selectedFromDate,
                              'DD.MM.YYYY',
                            )}
                            refVal={this.fromDateRef}
                            onChange={this.changeFromDate}
                            onClick={this.showFromDatePicker}
                          />
                        </section>
                        {this.state.isRecurring || this.state.validDate ? (
                          ''
                        ) : (
                          <ErrorToolTipCard
                            errorMsg={obj.shedulerTimeSlotError}
                          />
                        )}
                      </div>
                    </div>
                  </article>
                  <article
                    className={`form__group--flex-left form__group--time__panel ${
                      this.state.showTimes ? '' : 'hidden'
                    }`}
                  >
                    <div className="form__label" htmlFor="scheduleTimeFrom">
                      {this.state.validTime
                        ? this.state.reqStartTime && this.state.reqEndTime
                          ? ''
                          : requiredSpan()
                        : errorSpan()}
                    </div>
                    <div className="form__group--time__input primaryMarginBottom">
                      <section className="form__textField--has-icon">
                        <input
                          className="form__textField noMargin"
                          type="time"
                          id="scheduleTimeFrom"
                          value={this.state.selectedFromTime}
                          onChange={this.changeFromTime}
                        />
                      </section>
                    </div>
                    <span className="form__separator_text--time primaryMarginBottom">
                      to
                    </span>
                    <div className="form__group--time__input primaryMarginBottom">
                      <section className="form__textField--has-icon">
                        <input
                          className="form__textField noMargin"
                          type="time"
                          id="scheduleTimeTo"
                          value={this.state.selectedToTime}
                          onChange={this.changeToTime}
                        />
                      </section>
                      {this.state.validTime ? (
                        ''
                      ) : (
                        <ErrorToolTipCard errorMsg="Start time must be before end time" />
                      )}
                    </div>
                  </article>
                  <article
                    className={`form__group--autoWidth__panel--left ${
                      this.state.isRecurring ? 'hidden' : ''
                    }`}
                  >
                    <label className="form__label" htmlFor="scheduleTimeTo">
                      ENDS
                    </label>
                    <div className="form__group--flex-left">
                      <div className="form__group--date__panel primaryMarginBottom">
                        <section className="form__textField--has-icon">
                          <DatePickerInput
                            classList={`form__textField form__textField--right-icon datePicker__input noMargin ${
                              this.state.showToPicker
                                ? 'form__textField--active'
                                : ''
                            }`}
                            id="scheduleToDate"
                            noLabel={true}
                            value={dateFns.format(
                              this.state.selectedToDate,
                              'DD.MM.YYYY',
                            )}
                            refVal={this.toDateRef}
                            onChange={this.changeToDate}
                            onClick={this.showToDatePicker}
                          />
                        </section>
                      </div>
                    </div>
                  </article>
                  {this.state.conflicts.length > 0 ? (
                    <article
                      style={{ display: 'flex' }}
                      className="feedback conflict_message fadeInFly--conflictAnimate"
                    >
                      <section className="feedback__innerWrapper">
                        <span className="feedback__icon form__fieldIcon--invalid icon-alert" />
                        <span className="feedback__message">
                          {this.state.conflicts[0]}
                        </span>
                      </section>
                      <button
                        type="button"
                        className="buttonTransparent conflict_message_close feedback__close icon-cross"
                        onClick={this.resetConflicts}
                      />
                    </article>
                  ) : (
                    ''
                  )}
                  {this.state.conflicts.length === 0 &&
                  !this.state.clinicOpen ? (
                    <article
                      style={{ display: 'flex' }}
                      className="feedback conflict_message warning_message fadeInFly--conflictAnimate"
                    >
                      <section className="feedback__innerWrapper">
                        <span className="feedback__icon icon-warning" />
                        <span className="feedback__message">
                          This clinic is closed during the dates you have
                          selected
                        </span>
                      </section>
                      <button
                        type="button"
                        className="buttonTransparent conflict_message_close feedback__close icon-cross"
                        onClick={this.hideClinicMessage}
                      />
                    </article>
                  ) : (
                    ''
                  )}
                </section>
                <article className="form__group--modal-last">
                  <input
                    id="fullDay"
                    type="checkbox"
                    onChange={this.toggleTimes}
                  />
                  <label
                    className="clinicOpeningHours__openStatus"
                    htmlFor="fullDay"
                  >
                    All day
                  </label>
                </article>
              </fieldset>
              <hr />
              <fieldset className="form__group--topStackable form__group--modal-last">
                <article className="form__group--halfWidth__panel">
                  <input
                    id="repeat"
                    type="checkbox"
                    onChange={this.toggleRepeatOccurences}
                  />
                  <label
                    className="clinicOpeningHours__openStatus"
                    htmlFor="repeat"
                  >
                    Repeat
                  </label>
                </article>
              </fieldset>
              <fieldset
                ref={this.setRecurrenceFormRef}
                className="form__group--modal-last form__group--appointment--recurrence hidden"
              >
                <section className="form__group--stackable">
                  <div className="form__label">REPEAT ON</div>
                  <RadioGroup
                    type="checkbox"
                    items={getItems('days')}
                    name="days"
                    defaultSelected={this.state.selectedDays}
                    inline={true}
                    onChange={this.onScheduleChangeRepeatChange}
                  />
                </section>
                <section className="form__group--topStackable">
                  <label className="form__label" htmlFor="recurrenceAmount">
                    REPEAT EVERY
                  </label>
                  <div className="form__group--flex-left">
                    <div className="form__group--date__panel form__group--small-content">
                      <input
                        className="form__textField form__textField--auto form__textField--inline-small noMargin"
                        type="text"
                        pattern="[0-9]+"
                        id="recurrenceAmount"
                        value={this.state.selectedFrequencyAmount}
                        maxLength="3"
                        onChange={this.onScheduleFrequencyAmountChange}
                      />
                    </div>
                    <div className="form__group--autoWidth">
                      <RadioGroup
                        type="radio"
                        items={getItems('recurrences').splice(
                          1,
                          getItems('recurrences').length,
                        )}
                        name="frequency"
                        defaultSelected={[this.state.selectedFrequency]}
                        inline={true}
                        onChange={this.onScheduleChangeFrequencyChange}
                      />
                    </div>
                  </div>
                </section>
                <section className="form__group--topStackable">
                  <div className="form__label">
                    {this.state.validRecurrenceDate ? '' : errorSpan()}ENDS
                  </div>
                  <article className="form__group--flex-left">
                    <div className="form__group--flex-left">
                      <label
                        className="form__paragraph_text"
                        htmlFor="scheduleRecurrenceEnd"
                      >
                        On the
                      </label>
                      <article className="form__textField--has-icon form__textField--auto form__group--date__panel">
                        <DatePickerInput
                          classList={`form__textField form__textField--right-icon datePicker__input noMargin ${
                            this.state.showEndPicker
                              ? 'form__textField--active'
                              : ''
                          }`}
                          id="scheduleRecurrenceEnd"
                          noLabel={true}
                          value={dateFns.format(
                            this.state.endRecurrenceDate,
                            'DD.MM.YYYY',
                          )}
                          refVal={this.endDateRef}
                          onChange={this.changeEndDate}
                          onClick={this.showEndDatePicker}
                        />
                      </article>
                      {this.state.validRecurrenceDate ? (
                        ''
                      ) : (
                        <ErrorToolTipCard
                          errorMsg={obj.shedulerTimeSlotError}
                        />
                      )}
                    </div>
                    <div className="form__group--flex-left">
                      <span className="form__separator_text">- OR -</span>
                    </div>
                    <div className="form__group--flex-left">
                      <label
                        className="form__paragraph_text"
                        htmlFor="scheduleRecurrenceIteration"
                      >
                        After
                      </label>
                      <section className="form__textField--auto">
                        <input
                          className="form__textField form__textField--auto form__textField--inline-small noMargin"
                          type="text"
                          pattern="[0-9]+"
                          id="scheduleRecurrenceIteration"
                          value={this.state.recurrenceIterations}
                          maxLength="2"
                          onChange={this.onScheduleRecurrenceEndChange}
                        />
                      </section>
                      <span className="form__paragraph_text">Occurrences</span>
                    </div>
                  </article>
                </section>
              </fieldset>
            </section>
            <section className="modal__footer modal__footer--spacedButtons modal__footer--create_schedule">
              <div className="footer__multipleButtonContainer">
                <button
                  type="button"
                  className="buttonTertiary button--small modal--spacedButton--left modal__closeAction modal__cancel"
                  onClick={this.closeScheduleModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="buttonSecondary button--small modal__save"
                  disabled={`${
                    this.state.noConflicts && this.state.allValid
                      ? ''
                      : 'disabled'
                  }`}
                >
                  Save
                </button>
              </div>
            </section>

            <section className="modal__footer modal__footer--spacedButtons modal__footer--edit_schedule hidden">
              <div className="footer__multipleButtonContainer">
                <button
                  type="button"
                  className="buttonAlert button--small modal--spacedButton--left modal__closeAction modal__cancel"
                  onClick={this.closeScheduleModal}
                >
                  Cancel schedule change
                </button>
                <button
                  type="submit"
                  className="buttonSecondary button--small modal__save"
                >
                  Save changes
                </button>
              </div>
            </section>
          </form>
        </article>

        <section className="overlay" />
        {this.state.showFromPicker ? (
          <DatePickerRangeHHL
            selectedDate={this.state.selectedFromDate}
            startDate={this.state.selectedFromDate}
            endDate={
              this.state.isRecurring
                ? this.state.endRecurrenceDate
                : this.state.selectedToDate
            }
            calendarType="manage_schedule_range"
            dpInput={this.fromDateRef.current}
            onHide={this.changeFromDate}
            position="leftTop"
            container={this.modalScrollAreaRef}
          />
        ) : (
          ''
        )}
        {this.state.showToPicker ? (
          <DatePickerRangeHHL
            selectedDate={this.state.selectedToDate}
            startDate={this.state.selectedFromDate}
            endDate={this.state.selectedToDate}
            calendarType="manage_schedule_range"
            dpInput={this.toDateRef.current}
            onHide={this.changeToDate}
            position="leftTop"
            container={this.modalScrollAreaRef}
          />
        ) : (
          ''
        )}
        {this.state.showEndPicker ? (
          <DatePickerRangeHHL
            selectedDate={this.state.endRecurrenceDate}
            startDate={this.state.selectedFromDate}
            endDate={this.state.endRecurrenceDate}
            calendarType="manage_schedule_range"
            dpInput={this.endDateRef.current}
            onHide={this.changeEndDate}
            position="leftBottom"
            container={this.modalScrollAreaRef}
          />
        ) : (
          ''
        )}
      </React.Fragment>
    )
  }
}

ScheduleChange.defaultProps = {
  selectedClinician: null,
  onClose: () => {},
  onSave: () => {},
  selectedCell: null,
  selectedReason: 0,
}

ScheduleChange.propTypes = {
  global: PropTypes.object.isRequired,
  clinics: PropTypes.array.isRequired,
  clinicians: PropTypes.array.isRequired,
  selectedClinician: PropTypes.number,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  selectedCell: PropTypes.object,
  actions: PropTypes.object.isRequired,
  selectedReason: PropTypes.number,
}

const mapStateToProps = state => {
  return {
    global: state.global,
    clinics: state.global.clinics,
    clinicians: state.global.visibleClinicians,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetClinicsList,
        A_PostClinicScheduleChange,
        A_FetchClinicScheduleConflictList,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ScheduleChange),
)
