import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter /*, Link */ } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  A_FetchClinicDetails,
  A_storeUpdatedKey,
  A_Login_HX,
  A_PatchUpdatedClinicDetails,
} from '../../../actions'
import * as allFunc from '../../../utilities/ReusableFunctions'
import Loader from '../../../components/PatientData/Loader'
import ClinicSetupFinanceCard from '../../../components/Clinic/Finance/index'
import Modal from '../../../components/Modal'
import ModalInfo from '../../../components/Modal/Info'
import StaffTable from '../../../components/Staff/StaffTable'
import FeedbackStrap from '../../../components/Feedback/FeedbackStrap/index.js'
import TextInput from '../../../components/forms/Input'
import FeedbackNotification from '../../../components/Feedback/FeedbackNotification'
import { TutorialContext } from '../../../App'
import TutorialBtn from '../../../containers/Tutorials/TutorialBtn'
import { isTutorialFeatureEnabled } from '../../../utilities/featureToggle'
import ProgressBar from '../../../components/Clinic/ProgressBar'
import CurrencyIcon from '../../../components/Globalisation/CurrencyIcon'
import { getCurrency } from 'locale-currency'

class ClinicSummarayPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filterList: [],
      value: '',
      loadData: false,
      showInfoModal: false,
      clinicFilter: '',
      showHXmodal: false,
      notificationMsg: false,
      hasHXCError: false,
      alertMsg: '',
    }
    this.filterStaff = this.filterStaff.bind(this)
  }

  componentDidMount = () => {
    const { actions } = this.props
    const { clinicId } = this.props.match.params

    if (parseInt(clinicId)) {
      actions.A_FetchClinicDetails(clinicId).then(response => {
        let clinicInfo = response

        let notification = ''

        if (clinicInfo.clinic_info_completion < 1.0) {
          notification = 'information'
        } else if (clinicInfo.opening_hours_completion < 1.0) {
          notification = 'opening hours'
        } else if (clinicInfo.rooms_completion < 1.0) {
          notification = 'rooms'
        } else if (clinicInfo.equipment_completion < 1.0) {
          notification = 'equipment'
        } else if (clinicInfo.staff_completion < 1.0) {
          notification = 'staff'
        }

        this.setState({
          filterList: allFunc.sortRows(clinicInfo.invites),
          value: notification,
          loadData: true,
        })
      })
    }
  }

  selectLocation = (e, roomIndex, equipmentIndex) => {
    let { clinicSummary, actions } = this.props
    let data = clinicSummary.clinicInfo
    let rooms = data.rooms

    let oldRoom = rooms[roomIndex]

    let equipment = oldRoom.equipment[equipmentIndex]

    let newRoom = rooms[e.target.value]

    newRoom.equipment = [...newRoom.equipment, equipment]
    oldRoom.equipment = oldRoom.equipment.filter(function(e, index) {
      return index !== equipmentIndex
    })

    actions.A_PatchUpdatedClinicDetails(data).then(() => {
      this.setState({ loadData: true })
    })
  }

  editClinic = () => {
    const { actions, history } = this.props
    const { clinicId } = this.props.match.params
    actions.A_storeUpdatedKey()
    history.push(`/clinics/${clinicId}/edit`)
  }

  toggleInfoModal() {
    this.setState({
      showInfoModal: !this.state.showInfoModal,
    })
  }

  filterStaff(e) {
    const { clinicSummary } = this.props
    const clinicInfo = clinicSummary.clinicInfo

    let staff = clinicInfo.invites
    let searchValue = ''
    if (e) {
      searchValue = e.target.value.toLowerCase()
      staff = staff.filter(staff => {
        return (
          allFunc
            .getFullName(staff.clinician)
            .toLowerCase()
            .indexOf(searchValue) !== -1 ||
          staff.clinician.email.toLowerCase().indexOf(searchValue) !== -1
        )
      })
      this.setState({ filterList: staff, clinicFilter: searchValue })
    }
  }

  componentWillUnmount = () => {}

  handleConnectHX = () => {
    this.toggleHXModal()
  }

  hxLogin = async () => {
    const { actions } = this.props
    const { clinicId } = this.props.match.params
    const {
      clinicSummary: {
        clinicInfo: { system },
      },
    } = this.props

    const username = document.getElementById('emailHealthXchange').value
    const password = system.include_password
      ? document.getElementById('passwordHealthXchange').value
      : username
    const hxcRes = await actions.A_Login_HX(clinicId, {
      username,
      password,
    })
    if (hxcRes.epharm_token !== null) {
      this.setState({
        notificationMsg: `${system.success_title} - ${system.success_message}`,
        hasHXCError: false,
        alertMsg: '',
      })

      if (parseInt(clinicId)) {
        actions.A_FetchClinicDetails(clinicId)
      }

      this.toggleHXModal()
    } else {
      this.setState({ hasHXCError: true })
      const { data } = hxcRes
      if (data !== undefined) {
        const {
          errors: {
            non_field_errors: [error],
          },
        } = data
        this.setState({ alertMsg: error })
      }
    }
  }

  toggleHXModal = () => {
    this.setState({ showHXmodal: !this.state.showHXmodal })
  }

  render() {
    const { clinicSummary, global } = this.props
    const { hasHXCError, alertMsg } = this.state
    const clinicInfo = clinicSummary.clinicInfo
    const system = clinicInfo.system

    const not_complete =
      global.is_admin && clinicInfo.percentage_complete < 100.0

    return (
      <React.Fragment>
        {this.state.loadData ? (
          <div>
            <main className="main">
              {this.state.notificationMsg ? (
                <FeedbackNotification
                  id="feedbackMessage"
                  message={this.state.notificationMsg}
                  isWarning={false}
                  hideFeedback={() => {}}
                />
              ) : null}
              <form>
                {not_complete ? (
                  <section className="progressChecker__wrapper">
                    <article className="progressChecker">
                      <ProgressBar
                        percentageComplete={clinicInfo.percentage_complete}
                      />
                    </article>
                  </section>
                ) : (
                  ''
                )}
                {clinicInfo.epharm_token !== null ? (
                  global.is_admin && this.state.value !== '' ? (
                    <section
                      className={`feedbackStrap ${
                        not_complete ? 'feedbackStrap--offset' : ''
                      }`}
                    >
                      <p className="feedbackStrap__message">
                        {`You've not added any ${this.state.value} to this clinic yet.`}
                        <a
                          href={`/clinics/${this.props.match.params.clinicId}/edit#${this.state.value}`}
                          className="bold"
                        >
                          {' '}
                          Do it now?
                        </a>
                      </p>
                    </section>
                  ) : (
                    ''
                  )
                ) : (
                  <FeedbackStrap
                    isAlert={true}
                    hasMargin={not_complete}
                    hasOffset={not_complete}
                    onClick={this.handleConnectHX}
                    message="You're not connected to your Healthxchange account with this clinic,"
                    buttonText="Do it now?"
                  />
                )}
                <section className="main__inner">
                  <article className="col__12-7 fadeInFlyAnimate clinicForm clinicForm--feedbackStrap clinicForm--wide">
                    <h2 className="h1 gutterMarginBottom">Clinic Details</h2>
                    <article className="form">
                      <article className="form__group">
                        <section className="form__group--halfWidth">
                          <fieldset className="form__group--halfWidth__panel">
                            <label className="form__label" htmlFor="clinicName">
                              Clinic Name
                            </label>
                            <p className="disabledFormEntry" id="clinicName">
                              {clinicInfo.name}
                            </p>
                          </fieldset>
                          <fieldset className="form__group--halfWidth__panel">
                            <label
                              className="form__label"
                              htmlFor="clinicPhone"
                            >
                              Clinic Phone
                            </label>
                            <p className="disabledFormEntry">
                              {clinicInfo.main_phone}
                            </p>
                          </fieldset>
                        </section>
                        <section className="form__group--halfWidth">
                          <fieldset className="form__group--halfWidth__panel">
                            <label
                              className="form__label"
                              htmlFor="clinicEmail"
                            >
                              Clinic Email
                            </label>
                            <p className="disabledFormEntry">
                              {clinicInfo.email}
                            </p>
                          </fieldset>
                          <fieldset className="form__group--halfWidth__panel">
                            <label
                              className="form__label"
                              htmlFor="altClinicPhone"
                            >
                              Alternative Phone
                            </label>
                            <p className="disabledFormEntry">
                              {clinicInfo.alternative_phone}
                            </p>
                          </fieldset>
                        </section>
                      </article>
                      <hr />
                      <article className="form__group">
                        <label className="form__label" htmlFor="form__label">
                          Clinic Address
                        </label>
                        <p>{allFunc.address(clinicInfo)}</p>
                      </article>
                      <hr />
                      <article className="form__group">
                        <section className="form__group--halfWidth">
                          <fieldset className="form__group--halfWidth__panel">
                            <label className="form__label" htmlFor="clinicName">
                              Clinic currency
                            </label>
                            <div className="currency__symbol_container">
                              <CurrencyIcon
                                currency={clinicInfo.currencySymbol}
                              />
                              <span className="currency__symbol_text">
                                {getCurrency(clinicInfo.locale)}
                              </span>
                            </div>
                          </fieldset>
                          <fieldset className="form__group--halfWidth__panel">
                            <label
                              className="form__label"
                              htmlFor="clinicPhone"
                            >
                              Clinic timezone
                            </label>
                            <p className="disabledFormEntry">
                              {clinicInfo.timezone}
                            </p>
                          </fieldset>
                        </section>
                      </article>
                      <hr />
                      <article className="form__group">
                        <section className="clinicOpeningHours--disabled col__12-8">
                          <h2 className="form__label">Clinic Opening Hours</h2>
                          {clinicInfo.opening_hours.map(function(
                            opening_hours,
                          ) {
                            return (
                              <div
                                key={opening_hours.id}
                                className="clinicOpeningHours--disabled__row col__12-8"
                              >
                                <span>{opening_hours.formattedOpens}</span>
                                <span className="bold">
                                  {opening_hours.dayName.substr(0, 3)}
                                </span>
                                <span>{opening_hours.formattedCloses}</span>
                              </div>
                            )
                          })}
                        </section>
                      </article>
                    </article>
                    <h2
                      className="h1 primaryMarginBottom gutterMarginTop"
                      id="clinicRooms"
                    >
                      Rooms &amp; Equipment
                    </h2>
                    <h2 className="h2">Clinic Rooms</h2>
                    <section className="cardWrapper">
                      {clinicInfo.rooms.length === 0 ? (
                        <span className="emptyState">No rooms added yet</span>
                      ) : (
                        clinicInfo.rooms.map(function(room) {
                          return (
                            <article
                              key={room.id}
                              className="col__12-6 card--solidBorder clinicRoom"
                            >
                              <h4 className="h4 clinicRoom__name">
                                {room.name}
                              </h4>
                              <span className="clinicRoom__equipment">{`Equipment(${room.equipment.length})`}</span>
                            </article>
                          )
                        })
                      )}
                    </section>
                    <article>
                      <h2
                        className="h2 primaryMarginTop primaryMarginBottom"
                        id="equipment"
                      >
                        Portable Equipment
                      </h2>
                      <article className="form gutterMarginBottom">
                        <article className="table">
                          <div className="tableRow tableHeader">
                            <section className="tableColumn-2Icon-1 tableCell">
                              Name
                            </section>
                            <section className="tableColumn-2Icon-1 tableCell tableHeadCell">
                              Current room
                            </section>
                          </div>
                          {clinicInfo.rooms.find(function(room) {
                            return room.equipment.find(function(equipment) {
                              return equipment.is_permanent === false
                            })
                          }) === undefined ? (
                            <div className="tableRow">
                              <section className="tableCell">
                                No portable equipment added yet
                              </section>
                            </div>
                          ) : (
                            clinicInfo.rooms.map(
                              function(room, res) {
                                return room.equipment.map(
                                  function(equipment, index) {
                                    if (equipment.is_permanent === false) {
                                      return (
                                        <div
                                          key={equipment.id}
                                          className="tableRow"
                                        >
                                          <section className="tableColumn-2Icon-1 tableCell">
                                            {equipment.name}
                                          </section>
                                          <section className="tableColumn-2Icon-1 tableCell">
                                            <select /* eslint-disable-line */
                                              className="form__textField"
                                              onChange={e => {
                                                this.selectLocation(
                                                  e,
                                                  res,
                                                  index,
                                                )
                                              }}
                                              defaultValue={res}
                                            >
                                              <option disabled>
                                                Room name...
                                              </option>
                                              {clinicInfo.rooms.map(function(
                                                room,
                                                index,
                                              ) {
                                                return (
                                                  <option
                                                    key={room.id}
                                                    value={index}
                                                  >
                                                    {room.name}
                                                  </option>
                                                )
                                              })}
                                            </select>
                                            <span className="icon-chevron-down form__select form__select--labeless " />
                                          </section>
                                        </div>
                                      )
                                    }
                                  }.bind(this),
                                )
                              }.bind(this),
                            )
                          )}
                        </article>
                      </article>
                    </article>
                    <h2 className="h1 gutterMarginBottom" id="staff">
                      Staff
                    </h2>
                    <article className="form gutterMarginBottom">
                      <StaffTable
                        isLoading={false}
                        staffList={this.state.filterList}
                        isEdit={false}
                        toggleInfoModal={() => this.toggleInfoModal()}
                        VirtualClinic={true}
                        staffFilter={this.state.clinicFilter}
                        filterStaff={this.filterStaff}
                        global={global}
                      />
                    </article>
                    <article className="fadeInFlyAnimate gutterMarginBottom">
                      <h2 className="h1 gutterMarginBottom">
                        Appointment Settings
                      </h2>
                      <div className="form">
                        <article className="form__group">
                          <div className="form__group--halfWidth  secondaryMarginBottom">
                            <p className="text-light">
                              Upload a document that is sent with appointment
                              confirmations to give the patient any further
                              information they may need. Edit the clinic to
                              upload/delete the file
                              <br />
                            </p>
                            {clinicInfo.signed_appointment_document !== null ? (
                              <button
                                type="button"
                                className="treatment__header__button treatmentItem__alert buttonTransparent"
                                onClick={() => {
                                  window.open(
                                    clinicInfo.signed_appointment_document,
                                    '_blank',
                                  )
                                }}
                              >
                                <span className="treatment__info__icon icon-document" />
                                <span className="treatment__info__text">
                                  {allFunc.getFileName(
                                    clinicInfo.appointment_document,
                                  )}
                                </span>
                              </button>
                            ) : (
                              ''
                            )}
                          </div>
                        </article>
                      </div>
                    </article>
                    <ClinicSetupFinanceCard isEdit={false} data={clinicInfo} />
                  </article>
                </section>
                {global.is_admin ? (
                  <aside className="clinicControls">
                    <span
                      role="button"
                      tabIndex="0"
                      className="clinicControls__button buttonPrimary"
                      onClick={this.editClinic}
                      onKeyDown={this.editClinic}
                    >
                      Edit Clinic
                    </span>
                  </aside>
                ) : (
                  ''
                )}
              </form>
              {isTutorialFeatureEnabled ? (
                <TutorialContext.Consumer>
                  {({
                    toggleTutorial,
                    setTutorial,
                    tutorialList,
                    history,
                    activeTutorial,
                  }) => (
                    <TutorialBtn
                      tutorialList={tutorialList}
                      toggleTutorial={toggleTutorial}
                      setTutorial={setTutorial}
                      activeTutorial={activeTutorial}
                      history={history}
                    />
                  )}
                </TutorialContext.Consumer>
              ) : null}
            </main>

            <section className="overlay" />
            {this.state.showInfoModal ? (
              <Modal
                id="infoRoleModal"
                title="Clever Clinic user roles"
                closeModal={() => this.toggleInfoModal()}
                rightButtons={[
                  {
                    type: 'button',
                    style: 'tertiary',
                    label: 'Close',
                    size: 'small',
                    events: {
                      onClick: () => this.toggleInfoModal(),
                    },
                  },
                ]}
              >
                <ModalInfo type="Staff" />
              </Modal>
            ) : this.state.showHXmodal && system ? (
              <Modal
                id="healthXchangeModal"
                title={system.login_title.replace('{clinic}', clinicInfo.name)}
                closeModal={this.toggleHXModal}
                rightButtons={[
                  {
                    type: 'button',
                    style: 'tertiary',
                    label: 'Cancel',
                    size: 'small',
                    events: {
                      onClick: this.toggleHXModal,
                    },
                  },
                  {
                    type: 'button',
                    style: 'secondary',
                    label: system.login_button,
                    size: 'small',
                    events: {
                      onClick: this.hxLogin,
                    },
                  },
                ]}
              >
                <div className="modal__body">
                  <p className="p">
                    {system.login_message.replace('{clinic}', clinicInfo.name)}
                  </p>
                  <p
                    className="p form__label--error"
                    style={{ position: 'absolute', top: '9rem' }}
                  >
                    {hasHXCError ? alertMsg : ''}
                  </p>
                  <TextInput
                    type="text"
                    id="emailHealthXchange"
                    noMargin={false}
                    error={hasHXCError ? 'error' : null}
                    placeholder={system.username_field_label}
                    label={system.username_field_label}
                  />
                  {system.include_password ? (
                    <TextInput
                      type="password"
                      id="passwordHealthXchange"
                      noMargin={false}
                      error={hasHXCError ? 'error' : null}
                      placeholder="Password"
                      label="Password"
                    />
                  ) : (
                    ''
                  )}
                </div>
              </Modal>
            ) : (
              ''
            )}
          </div>
        ) : (
          <React.Fragment>
            <Loader />
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

ClinicSummarayPage.propTypes = {
  clinicSummary: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  global: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
  return {
    data: state.clinicDetails,
    clinicSummary: state.clinicSummary,
    global: state.global,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_FetchClinicDetails,
        A_storeUpdatedKey,
        A_Login_HX,
        A_PatchUpdatedClinicDetails,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClinicSummarayPage),
)
