import React from 'react'
import PropTypes from 'prop-types'
//import AlertIcon from "../../../assets/alert.svg";

const UploadError = props => {
  return (
    <React.Fragment>
      <span className="icon-alert form__label--error form__label__image--medium" />
      <h2 className="h2 bold">
        There was a problem
        <br />
        with the file you uploaded
      </h2>
      <p className="p">{props.errorText}</p>
    </React.Fragment>
  )
}

UploadError.propTypes = {
  errorText: PropTypes.isRequired,
}

export default UploadError
