import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import moment from 'moment'

import Slot from './Slot'
import LoadingSlots from './Loading'
import NoSlots from './NoSlots'

export default function Slots({
  activeSlot,
  setActiveSlot,
  availableSlots,
  setAvailableSlots,
  appointmentDate,
  appointmentDuration,
  isLoading,
  errors,
  setError,
}) {
  const clinicAppointment = useSelector(
    ({ clinicAppointment }) => clinicAppointment,
  )

  const hasActiveSlot = Boolean(Object.keys(activeSlot).length)

  const [slotFound, setSlotFound] = useState(false)
  const [finishedAlogirthm, setFinishedAlogirthm] = useState(false)

  useEffect(() => {
    const { slots, gaps } = clinicAppointment

    const found = formatAvailableSlots()
    formatAvailableGaps(found)
    setFinishedAlogirthm(slots.length > 0 || gaps.length > 0)
  }, [clinicAppointment.slots, clinicAppointment.gaps])

  useEffect(() => {
    const { slots } = clinicAppointment

    if (finishedAlogirthm === true && slotFound === false) {
      if (slots.length > 0) {
        setActiveSlot(slots[0])
      }
    }
  }, [finishedAlogirthm])

  useEffect(() => {
    const activeSlotNode = document.getElementById(activeSlot.id)
    const isSlotRendered = activeSlotNode !== null
    if (isSlotRendered)
      activeSlotNode.scrollIntoView({
        behavior: 'smooth',
      })

    if (hasActiveSlot) {
      setError(err => ({ ...err, slotsHasError: '' }))
    } else {
      setError(err => ({ ...err, slotsHasError: 'Required' }))
    }
  }, [activeSlot])

  const chooseSlot = (selectedSlots, slot, startDate, compareDuration) => {
    const { duration } = slot

    const start = slot.startDate.format('YYYY-MM-DDTHH:mm')
    const end = slot.endDate.format('YYYY-MM-DDTHH:mm')

    const isSame =
      slot.startDate.isSame(startDate, 'day') &&
      slot.startDate.format('HH:mm') === startDate.format('HH:mm')

    const isBetween = startDate.isBetween(start, end)

    if (isSame || isBetween) {
      if (compareDuration) {
        if (duration !== appointmentDuration) {
          return null
        }
      }

      setAvailableSlots(selectedSlots)
      setActiveSlot(slot)
      setSlotFound(true)

      return slot
    }

    return null
  }

  const slotForLoop = (found, slots, selectedSlots, startDate, duration) => {
    if (!found) {
      for (var i = 0; i < selectedSlots.length; i++) {
        if (chooseSlot(slots, selectedSlots[i], startDate, duration) !== null) {
          found = true
          break
        }
      }
    }

    return found
  }

  const formatAvailableSlots = () => {
    const { slots } = clinicAppointment

    const startDate = appointmentDate.clone()

    setFinishedAlogirthm(false)
    setSlotFound(false)
    setAvailableSlots(slots)

    const sameStartDate = slots.filter(slot => {
      return (
        slot.startDate.isSame(startDate, 'day') &&
        slot.startDate.format('HH:mm') === startDate.format('HH:mm')
      )
    })

    var found = false

    found = slotForLoop(found, slots, sameStartDate, startDate, true)
    found = slotForLoop(found, slots, slots, startDate, true)

    return found
  }

  const formatAvailableGaps = found => {
    const { slots, gaps } = clinicAppointment

    const startDate = appointmentDate.clone()

    if (slots.length === 0 || (slotFound === false && found === false)) {
      for (var g = 0; g < gaps.length; g++) {
        const gap = gaps[g]
        const sameStartDate = gap.slots.filter(slot => {
          return (
            slot.startDate.isSame(startDate, 'day') &&
            slot.startDate.format('HH:mm') === startDate.format('HH:mm')
          )
        })

        found = slotForLoop(found, gap.slots, sameStartDate, startDate, false)
        found = slotForLoop(found, gap.slots, gap.slots, startDate, false)
      }
    }
  }

  return (
    <fieldset className="form__group--stackable createAppointment--availableSlots">
      <label className="form__label" htmlFor={activeSlot}>
        {errors.slotsHasError && hasActiveSlot && (
          <span className="form__label--error">REQUIRED: </span>
        )}
        Available slots
      </label>
      {isLoading ? (
        <LoadingSlots />
      ) : availableSlots.length ? (
        <ul className="radio__group" role="group">
          {availableSlots.map(slot => (
            <Slot
              key={slot.id}
              slot={slot}
              activeSlot={activeSlot}
              setActiveSlot={setActiveSlot}
            />
          ))}
        </ul>
      ) : (
        <NoSlots />
      )}
    </fieldset>
  )
}

Slots.propTypes = {
  activeSlot: PropTypes.object.isRequired,
  setActiveSlot: PropTypes.func.isRequired,
  availableSlots: PropTypes.array.isRequired,
  setAvailableSlots: PropTypes.func.isRequired,
  appointmentDate: PropTypes.instanceOf(moment).isRequired,
  appointmentDuration: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
}
