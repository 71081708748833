import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { requiredSpan } from '../../OnBoarding/FormErrorValidations'
import * as func from '../../ModalOverlay'

class Rooms extends Component {
  constructor(props) {
    super(props)

    this.state = {
      equipmentList: [],
      editRoom: false,
      equipment: true,
      reqroomName: true,
      current_data: null,
    }

    this.clinicRoom = React.createRef()
  }

  updateClinicRoom = () => {
    const { data } = this.props
    let { equipmentList, current_data } = this.state
    if (this.clinicRoom.current.value.length > 0) { //eslint-disable-line
      this.setState({ reqroomName: true })

      for (let i = 0; i < equipmentList.length; i++) {
        let field = this.refs['equipmentName_' + i] //eslint-disable-line
        let classes = 'form__textField form__textField--stackable'

        if (equipmentList[i].name.length === 0) {
          this.setState({ equipment: false })
          field.className = 'form__textField--error ' + classes
          return
        } else {
          field.className = classes
        }
      }

      if (current_data === null) {
        current_data = data.rooms.length
        data.rooms = [...data.rooms, { id: 0 }]
      }

      let room = data.rooms[current_data]
      room.name = this.clinicRoom.current.value
      room.equipment = equipmentList
    } else {
      this.setState({ reqroomName: false })
      return
    }

    this.clinicRoom.current.value = ''
    this.setState({
      equipmentList: [],
      equipment: true,
      editRoom: false,
      current_data: null,
    })
  }

  addEquipment = () => {
    var data = {
      id: 0,
      name: '',
      is_permanent: true,
    }

    this.setState({ equipmentList: [...this.state.equipmentList, data] })
  }

  storeEquipmentName = e => {
    let equipment = this.state.equipmentList
    equipment[e.target.id].name = e.target.value
    this.setState({ equipmentList: equipment })
  }

  editClinicRoom = index => {
    const { data } = this.props
    const room = data.rooms[index]

    this.clinicRoom.current.value = room.name
    this.setState({
      editRoom: true,
      current_data: index,
      equipmentList: room.equipment,
    })
  }

  deleteEquipment = index => {
    let equipment = this.state.equipmentList.filter(function(e, i) {
      return i !== index
    })

    this.setState({ equipmentList: equipment })
  }

  equipmentMobility = equipment => {
    equipment.is_permanent = !equipment.is_permanent
  }

  render() {
    const {
      data,
      displayEquipModal,
      clinicSummary,
      deleteClinicRoom,
    } = this.props

    const isCreating = data.id === 0

    return (
      <React.Fragment>
        {!isCreating ? (
          <h2
            className="h1 primaryMarginBottom gutterMarginTop"
            id="clinicRooms"
          >
            Rooms &amp; Equipment
          </h2>
        ) : (
          ''
        )}
        <h2 className="h2 primaryMarginBottom">Clinic Rooms</h2>
        <section className="form">
          <article className="form__group form__groupSmallSubmit">
            <section className="">
              <label className="form__label">
                {this.state.reqroomName ? '' : requiredSpan()} Room Name
              </label>
              <input
                className={`form__textField ${
                  this.state.reqroomName === false
                    ? 'form__textField--error'
                    : ''
                }`}
                type="text"
                id="clinicRoom"
                    ref={this.clinicRoom} //eslint-disable-line
                placeholder="Clinic Room"
              />

              {this.state.equipmentList.length > 0 ? (
                <article id="equipmentWrapper">
                  <section className="form__group--halfWidth">
                    <section className="tableColumn-2Icon-1 tableCell">
                      <span className="form__label">
                        {this.state.equipment ? '' : requiredSpan()}
                        Equipment name
                      </span>
                    </section>
                    <section className="tableColumn-2Icon-1 tableCell">
                      <span className="form__label">
                        Mobility
                        <span
                          role="button"
                          tabIndex="0"
                          className="icon-info tableHeadCell__icon"
                          id="mobilityModalButton"
                          onClick={displayEquipModal}
                          onKeyDown={displayEquipModal}
                        ></span>
                      </span>
                    </section>
                    <section className="iconTableColumn tableCell" />
                  </section>

                  <section className="equipmentContainer">
                    {this.state.equipmentList.map(
                      function(equipment, index) {
                        return (
                          <article
                            key={index}
                            className="form__group--halfWidth"
                          >
                            <section className="tableColumn-2Icon-1 tableCell">
                              <label
                                className="form__label hidden"
                                htmlFor="equipmentName"
                              >
                                Equipment name
                              </label>
                              <input
                                id={index}
                                className="form__textField form__textField--stackable"
                                placeholder="Equipment"
                                ref={'equipmentName_' + index}
                                onChange={this.storeEquipmentName}
                                defaultValue={equipment.name}
                              />
                            </section>
                            <section className="tableColumn-2Icon-1 tableCell">
                              <label
                                className="form__label hidden"
                                htmlFor="equipmentMobility"
                              >
                                Mobility
                              </label>
                                  <select /* eslint-disable-line*/
                                className="form__textField form__textField--stackable"
                                id="equipmentMobility"
                                onChange={() => {
                                  this.equipmentMobility(equipment)
                                }}
                                defaultValue={equipment.is_permanent}
                              >
                                <option key={true} value={true}>
                                  Static
                                </option>
                                <option key={false} value={false}>
                                  Portable
                                </option>
                              </select>
                              <span className="icon-chevron-down form__select form__select--labeless form__select--padded" />
                            </section>
                            <section className="iconTableColumn tableCell tableRow__removeButton--boundless">
                                  <span /* eslint-disable-line */
                                className="icon-minus buttonCircle"
                                onClick={() => {
                                  this.deleteEquipment(index)
                                }}
                              />
                            </section>
                          </article>
                        )
                      }.bind(this),
                    )}
                  </section>
                </article>
              ) : (
                ''
              )}
              <span
                role="button"
                tabIndex="-1"
                className="textLink form__textLink--left"
                id="includeEquipmentButton"
                onClick={this.addEquipment}
                onKeyDown={this.addEquipment}
              >
                <span className="icon-add" />
                Include equipment in room
              </span>
              <span
                role="button"
                tabIndex="-2"
                className="buttonSecondary button--small"
                onClick={this.updateClinicRoom}
                onKeyDown={this.updateClinicRoom}
              >
                {this.state.editRoom ? 'Update Room' : 'Add Room'}
              </span>
            </section>
          </article>
        </section>
        <section className="cardWrapper">
          {data.rooms.map(
            function(room, index) {
              return (
                <article
                  key={index}
                  className="col__12-6 card--solidBorder clinicRoom"
                >
                  <h4 className="h4 clinicRoom__name">{room.name}</h4>
                    <span /* eslint-disable-line */
                    className="buttonCircle icon-pencil"
                    onClick={() => {
                      this.editClinicRoom(index)
                    }}
                  />
                  {
                      <span /* eslint-disable-line */
                      className={
                        room.id === 0
                          ? 'buttonCircle icon-minus deleteRoomButton'
                          : 'tableRow__removeButton icon-cross buttonCircle deleteStaffButton'
                      }
                      onClick={() => {
                        clinicSummary.selectedIndex = index

                        if (room.id === 0) {
                          deleteClinicRoom()
                        } else {
                          func.showModal('cannotDeleteRoomModal')
                        }
                      }}
                    />
                  }
                  <span className="clinicRoom__equipment">{`Equipment(${room.equipment.length})`}</span>
                </article>
              )
            }.bind(this),
          )}
        </section>
      </React.Fragment>
    )
  }
}

Rooms.propTypes = {
  data: PropTypes.object.isRequired,
  displayEquipModal: PropTypes.func.isRequired,
  clinicSummary: PropTypes.object.isRequired,
  deleteClinicRoom: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  return {
    clinicSummary: state.clinicSummary,
  }
}

export default connect(mapStateToProps, null)(Rooms)
