import types from '../../actions/types'

export default (state = null, action) => {
  if (
    action.type === types.ACCOUNT_SETUP.FAIL ||
    (action.error && !action.ignoreGlobalError)
  ) {
    return {
      ...state,
      type: action.type,
      error: action.error,
      status: action.error ? action.error.status : null,
    }
  }
  return state
}
