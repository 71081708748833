import React from 'react'
import PropTypes from 'prop-types'

class ModalHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.getSubtitles = this.getSubtitles.bind(this)
  }

  getSubtitles() {
    const { subtitles } = this.props
    const subtitleList = subtitles.map((subtitle, i) => {
      let indexID = i
      return (
        <span
          key={`subtitle_${indexID}`}
          className={`modal__header__subtitle modal__header__multipleTitle--item ${
            i === subtitles.length - 1 ? 'modal__header__subtitle--last' : ''
          }`}
        >
          {subtitle}
        </span>
      )
    })
    return subtitleList
  }

  render() {
    const { subtitles, title, allowEdit, closeHandler } = this.props
    return subtitles.length > 0 ? (
      <section className="modal__header modal__header--hasButton modal__header--wrapTitle modal__item--flex-fixed">
        <div className="modal__header__title modal__header__title--wrap">
          <h4 className="h3 modal__header__multipleTitle--item">{title}</h4>
          {this.getSubtitles()}
        </div>
        {allowEdit ? <span className="icon-lock" /> : ''}
        <span
          role="button"
          tabIndex="0"
          className="icon-cross buttonCircle modal__closeButton modal__closeAction"
          onClick={closeHandler}
          onKeyDown={closeHandler}
        />
      </section>
    ) : (
      <section className="modal__header">
        <h4 className="h3">{title}</h4>
        {allowEdit ? <span className="icon-lock" /> : ''}
        <span
          role="button"
          tabIndex="0"
          className="icon-cross buttonCircle modal__closeButton modal__closeAction"
          onClick={closeHandler}
          onKeyDown={closeHandler}
        />
      </section>
    )
  }
}

ModalHeader.defaultProps = {
  title: '',
  subtitles: [],
  allowEdit: false,
}

ModalHeader.propTypes = {
  title: PropTypes.string,
  subtitles: PropTypes.array,
  allowEdit: PropTypes.bool,
  closeHandler: PropTypes.func.isRequired,
}

export default ModalHeader
