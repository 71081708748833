import React from 'react'
import PropTypes from 'prop-types'

export default function ProgressBar({ percentageComplete }) {
  return (
    <div className="progressChecker__clinicCard">
      <aside className="progressChecker__progress">
        <section className="progressChecker__progressBackground">
          <span
            className="progressChecker__marker"
            style={{
              left: `calc(${percentageComplete / 10}rem - 0.5rem)`,
            }}
          />
          <span
            className="progressChecker__progressBar"
            style={{
              width: `${percentageComplete / 10}rem`,
            }}
          />
        </section>
      </aside>
      <p className="progressChecker__text">
        <strong>{`${percentageComplete}%`} </strong>
        complete
      </p>
    </div>
  )
}

ProgressBar.propTypes = {
  percentageComplete: PropTypes.string.isRequired,
}
