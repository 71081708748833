import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ErrorToolTipCard from '../../OnBoarding/FormErrorValidations'

class OpeningHours extends Component {
  state = {}

  componentDidMount() {
    this.validateClinicHours()
  }

  validateClinicHours = e => { //eslint-disable-line
    const { data } = this.props

    var clinicHoursList = []
    var count_1 = 0
    var count_2 = 0
    var re = /^(([0-1][0-9])|(2[0-3])):[0-5][0-9]$/
    var nonErrorClass = 'form__textField noMargin'
    var errorClass = 'form__textField noMargin form__textField--error'

    const openingHours = data.opening_hours

    openingHours.forEach(
      function(opening_hours) {
        const i = opening_hours.day

      var openEle = this.refs['openTime_' + i] //eslint-disable-line

      if (openEle) { //eslint-disable-line
        var closeEle = this.refs['closeTime_' + i] //eslint-disable-line
        if (this.refs['checked_' + i].checked) { //eslint-disable-line
            count_1++

            if (!openEle.value) {
              openEle.value = '09:00'
            }
            if (!closeEle.value) {
              closeEle.value = '17:00'
            }

          if (openEle.value.length > 0) { //eslint-disable-line
              this.setState({ ['reqOpenTime_' + i]: true })
              openEle.className = nonErrorClass
            if (re.test(openEle.value.slice(0, 5))) { //eslint-disable-line
                this.setState({
                  ['validOpenTime_' + i]: true,
                  ['reqOpenTime_' + i]: true,
                })
                openEle.className = nonErrorClass
              if (closeEle.value.length > 0) { //eslint-disable-line
                  closeEle.className = nonErrorClass
                  this.setState({ ['reqCloseTime_' + i]: true })
                if (re.test(closeEle.value.slice(0, 5))) { //eslint-disable-line
                    this.setState({
                      ['validCloseTime_' + i]: true,
                      ['reqCloseTime_' + i]: true,
                    })
                    closeEle.className = nonErrorClass
                  var open_i = openEle.value.split(':') //eslint-disable-line
                  var close_i = closeEle.value.split(':') //eslint-disable-line
                    if (parseInt(close_i[0]) > parseInt(open_i[0])) {
                      count_2++
                      this.setState({
                        ['time_' + i]: true,
                        ['validOpenTime_' + i]: true,
                        ['reqOpenTime_' + i]: true,
                      })
                      closeEle.className = nonErrorClass
                    } else if (parseInt(close_i[0]) === parseInt(open_i[0])) {
                      if (parseInt(close_i[1]) > parseInt(open_i[1])) {
                        count_2++
                        this.setState({ ['time_' + i]: true })
                        closeEle.className = nonErrorClass
                      } else {
                        this.setState({ ['time_' + i]: false })
                        closeEle.className = errorClass
                      }
                    } else {
                      this.setState({ ['time_' + i]: false })
                      closeEle.className = errorClass
                    }
                  } else {
                    this.setState({ ['validCloseTime_' + i]: false })
                    closeEle.className = errorClass
                  }
                } else {
                  this.setState({ ['reqCloseTime_' + i]: false })
                  closeEle.className = errorClass
                }
              } else {
                this.setState({ ['validOpenTime_' + i]: false })
                openEle.className = errorClass
              }
            } else {
              this.setState({ ['reqOpenTime_' + i]: false })
              openEle.className = errorClass
            }

            opening_hours.is_open = true
            opening_hours.opens = openEle.value
            opening_hours.closes = closeEle.value
          } else {
            closeEle.className = nonErrorClass
            openEle.className = nonErrorClass
          openEle.value = "--:--" //eslint-disable-line
          closeEle.value = "--:--" //eslint-disable-line
            this.setState({
              ['validCloseTime_' + i]: true,
              ['validOpenTime_' + i]: true,
              ['time_' + i]: true,
              ['reqOpenTime_' + i]: true,
              ['reqCloseTime_' + i]: true,
            })

            opening_hours.is_open = false
            opening_hours.opens = '00:00:00'
            opening_hours.closes = '00:00:00'
          }
        }
        clinicHoursList.push(opening_hours)
      }.bind(this),
    )

    if (count_1 === count_2) {
      data.opening_hours = clinicHoursList
      this.setState({ validTimings: true })
    } else {
      this.setState({ validTimings: false })
    }
  }

  render() {
    const { data } = this.props
    return (
      <article className="form__group">
        <h2 className="form__label clinicOpeningHours__heading">
          Clinic Opening Hours
        </h2>
        <section
          className="clinicOpeningHours"
          onChange={this.validateClinicHours}
        >
          {data.opening_hours.map(
            function(opening_hours) {
              const index = opening_hours.day
              return (
                <div key={index}>
                  <article className="clinicOpeningHours__day">
                    <div className="clinicOpeningHours__outer">
                      <label className="clinicOpeningHours__dayText">
                        {opening_hours.dayName.substr(0, 3)}
                      </label>
                    </div>
                    <div className="clinicOpeningHours__time">
                      <input
                        className="form__textField noMargin"
                        type="time"
                        defaultValue={
                          opening_hours.is_open
                            ? opening_hours.opens.substr(0, 5)
                            : '--:--'
                        }
                        ref={'openTime_' + index} //eslint-disable-line
                      />
                    </div>
                    <div className="clinicOpeningHours__divider">-</div>
                    <div className="clinicOpeningHours__time">
                      <input
                        className="form__textField noMargin"
                        type="time"
                        defaultValue={
                          opening_hours.is_open
                            ? opening_hours.closes.substr(0, 5)
                            : '--:--'
                        }
                        ref={'closeTime_' + index} //eslint-disable-line
                      />
                    </div>
                    <div className="clinicOpeningHours__outer">
                      <input
                        id="openingHours"
                        type="checkbox"
                        ref={'checked_' + index} //eslint-disable-line
                        defaultChecked={opening_hours.is_open}
                      />
                      <label
                        htmlFor="openingHours"
                        className="clinicOpeningHours__openStatus"
                      >
                        Open
                      </label>
                    </div>
                  </article>
                  {this.state['reqOpenTime_' + index] === false ||
                  this.state['reqCloseTime_' + index] === false ||
                  this.state['validCloseTime_' + index] === false ||
                  this.state['validOpenTime_' + index] === false ? (
                    <ErrorToolTipCard errorMsg={this.state.required} />
                  ) : this.state['time_' + index] === false ? (
                    <ErrorToolTipCard errorMsg={this.state.error} />
                  ) : (
                    ''
                  )}
                </div>
              )
            }.bind(this),
          )}
        </section>
      </article>
    )
  }
}

OpeningHours.defaultProps = {}

OpeningHours.propTypes = {
  data: PropTypes.object.isRequired,
}

export default OpeningHours
