import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Select from '../../forms/Select'
import PaymentModal from '../../../containers/PatientFinance/PaymentModal'
import FeedbackNotification from '../../../components/Feedback/FeedbackNotification'

import {
  InvoiceStatus,
  InvoiceOption,
  invoiceOptions,
} from '../../../utilities/ReusableObjects'
import { financeFeatureToggle } from '../../../utilities/featureToggle'

import { formatCurrency } from '../../../utilities/ReusableFunctions'

const InvoiceItem = ({
  treatment,
  clinics,
  clinicians,
  patient,
  openQuickbooks,
  displayInvoice,
  deleteInvoice,
}) => {
  const [isLogPaymentSelected, toggleLogPaymentModal] = useState(false)
  const [isViewPaymentSelected, toggleViewPaymentModal] = useState(false)
  const [paymentRes, setPaymentRes] = useState(null)

  const isPaymentSuccessfull = paymentRes ? paymentRes.status === 201 : null
  const isPaymentUnsuccessfull = paymentRes ? !isPaymentSuccessfull : null

  const paidValue = formatCurrency(treatment.paid, treatment.locale)
  const totalValue = formatCurrency(treatment.total, treatment.locale)

  let statusClass = 'balance__Notification balance__Notification--'
  let statusTitle

  switch (treatment.paymentStatus) {
    case InvoiceStatus.FULL_PAID:
      statusTitle = 'Paid'
      break
    case InvoiceStatus.PART_PAID:
      statusTitle = 'Partially paid'
      break
    case InvoiceStatus.OUTSTANDING:
      statusTitle = 'Outstanding'
      break
    case InvoiceStatus.REFUNDED:
      statusTitle = 'Refunded'
      break
    default:
      statusClass = null
  }

  statusClass += statusTitle.replace(' ', '').toLowerCase()

  const modalType = isLogPaymentSelected
    ? 'create'
    : isViewPaymentSelected
    ? 'view'
    : null
  const modalToggleType = isLogPaymentSelected
    ? toggleLogPaymentModal
    : isViewPaymentSelected
    ? toggleViewPaymentModal
    : null

  const invoiceActioner = optionId => {
    switch (parseInt(optionId)) {
      case InvoiceOption.QUICKBOOKS:
        return openQuickbooks(treatment.id)
      case InvoiceOption.VIEW_INVOICE:
        return displayInvoice(treatment.id)
      case InvoiceOption.LOG_PAYMENT:
        toggleLogPaymentModal(true)
        break
      case InvoiceOption.VIEW_PAYMENTS:
        toggleViewPaymentModal(true)
        break
      case InvoiceOption.DELETE_INVOICE:
        return deleteInvoice(treatment.id)
      default:
        break
    }
  }

  const dropdownInvoiceOptions = invoiceOptions.filter(({ id }) => {
    switch (id) {
      case InvoiceOption.QUICKBOOKS:
        return treatment.quickbooksURL !== null
      case InvoiceOption.LOG_PAYMENT:
        return financeFeatureToggle.isLoggingEnabled
      case InvoiceOption.VIEW_PAYMENTS:
        return financeFeatureToggle.isViewingEnabled
      default:
        return true
    }
  })

  const globalError = 'Unable to log payment, please re-open modal and'

  const paymentError = paymentRes ? Object.keys(paymentRes.data)[0] : null

  const errorMsg =
    paymentError === 'card_type'
      ? `${globalError} select a card type`
      : paymentError === 'payment_type'
      ? `${globalError} select a payment type`
      : null

  return (
    <>
      <section className="invoiceItem">
        <div className="invoiceItem__row invoiceItem__row--top">
          <h3 className="h3">{treatment.treatments}</h3>
          <p>{treatment.date}</p>
          <p>{treatment.consultationTime}</p>
          <p>{treatment.doctor}</p>
          <p>{treatment.location}</p>
          <h4 className="h3 treatmentPrice greyedOut">({paidValue})</h4>
          <h4 className="h3">{totalValue}</h4>
        </div>

        <div className="invoiceItem__row">
          <Select
            options={dropdownInvoiceOptions}
            onChange={({ target: { value } }) => invoiceActioner(value)}
            value={InvoiceOption.ACTIONS}
          />

          <span className={statusClass}>{statusTitle}</span>
        </div>
      </section>
      {paymentRes !== null ? (
        <FeedbackNotification
          message={`${
            isPaymentSuccessfull
              ? `Successfully logged payment against invoice #${treatment.id}`
              : isPaymentUnsuccessfull
              ? `${errorMsg}`
              : ''
          }`}
          iserrorAlert={isPaymentUnsuccessfull}
        />
      ) : null}
      {isLogPaymentSelected || isViewPaymentSelected ? (
        <PaymentModal
          type={modalType}
          patient={patient}
          treatment={treatment}
          clinics={clinics}
          clinicians={clinicians}
          paymentError={paymentError}
          setPaymentRes={setPaymentRes}
          togglePaymentModal={modalToggleType}
        />
      ) : null}
    </>
  )
}

InvoiceItem.propTypes = {
  treatment: PropTypes.object.isRequired,
  patient: PropTypes.object.isRequired,
  clinics: PropTypes.array.isRequired,
  clinicians: PropTypes.array.isRequired,
  openQuickbooks: PropTypes.func.isRequired,
  displayInvoice: PropTypes.func.isRequired,
  deleteInvoice: PropTypes.func.isRequired,
}

export default InvoiceItem
