import React from 'react'
import PropTypes from 'prop-types'
//import MainButton from '../../Buttons'

const IconTooltip = ({ title, ...props }) => { //eslint-disable-line
  return (
    <section className="tooltipWrapper">
      <aside className="tooltip tooltip--icon">
        <p className="noMargin">{title}</p>
      </aside>
    </section>
  )
}

IconTooltip.defaultProps = {
  title: '',
}

IconTooltip.propTypes = {
  title: PropTypes.string,
}

export default IconTooltip
