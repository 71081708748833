import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter /*, Link */ } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Builder from '../../components/PatientFinance/builder'
import { formatCurrency } from '../../utilities/ReusableFunctions'

import {
  A_GetPatientConsultationDetail,
  A_GetPatientDetail,
  A_GetClinicsList,
  A_GetInventoryList,
} from '../../actions'
import Loader from '../../components/PatientData/Loader'

class InvoiceBuilder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      isClinicUnavailable: false,
      consultation: null,
      clinicId: null,
      clinicList: [],
      productOnly: false,
      inventoryLoaded: false,
      inventoryItems: [],
      selectedClinicId: null,
    }
  }

  componentDidMount = () => {
    const { actions } = this.props

    actions.A_GetClinicsList().then(() => {
      const { global } = this.props

      const { patientId } = this.props.match.params
      const clinic = global.currentClinicID

      if (clinic) {
        actions.A_GetPatientDetail(clinic, patientId).then(() => {
          this.getInitialPatientData(clinic)
        })
      } else {
        this.setState(() => ({
          isLoading: false,
          isClinicUnavailable: true,
        }))
      }
    })
  }

  getInitialPatientData = clinic => {
    const { patient, actions } = this.props
    let patientID = patient.id
    actions.A_GetClinicsList().then(() => {
      const { global } = this.props
      this.setState({ clinicList: global.filterClinics })

      let { consultationId } = this.props.match.params
      consultationId = Number(consultationId)
      if (consultationId) {
        actions
          .A_GetPatientConsultationDetail({
            clinicID: clinic,
            patientID: patientID,
            consultationID: consultationId,
          })
          .then(() => {
            const {
              data: { consultation },
            } = this.props
            this.setState(
              () => ({
                clinicId: consultation.locationId,
                selectedClinicId: consultation.locationId,
                isLoading: false,
                consultation,
                productOnly: false,
              }),
              () => {
                this.getInventoryList(consultation.locationId)
              },
            )
          })
      } else {
        this.setState(
          {
            clinicId: clinic,
            selectedClinicId: clinic,
            isLoading: false,
            consultation: null,
            productOnly: true,
          },
          () => {
            this.getInventoryList(clinic)
          },
        )
      }
    })
  }

  getInventoryList = clinicID => {
    const { actions } = this.props
    actions.A_GetInventoryList(clinicID).then(() => {
      const { inventoryList, global } = this.props
      const clinic = global.clinics.find(clinic => {
        return clinic.id === Number(clinicID)
      })
      const clinicVat = clinic.vat
      const options = inventoryList.inventoryList

      let inventoryItemArray = []
      options.forEach(optEle => {
        optEle.options.forEach(levelEle => {
          if (levelEle.levels.length > 0) {
            const level = levelEle.levels[0]
            const priceId = level.id
            const price = level.price
            inventoryItemArray.push(
              Object.assign(
                {
                  productId: optEle.id,
                  productTitle: optEle.title,
                  productPriceTitle: formatCurrency(price, clinic.locale),
                  productPrice: price,
                  productPriceId: priceId,
                  vat: clinicVat,
                  treatment_level_id: level.id,
                },
                levelEle,
              ),
            )
          }
        })
      })

      this.setState(() => ({
        inventoryLoaded: true,
        inventoryItems: inventoryItemArray,
        selectedClinicId: clinicID,
      }))
    })
  }

  render() {
    return (
      <>
        {this.state.isClinicUnavailable ? (
          ''
        ) : !this.state.isLoading &&
          this.state.inventoryLoaded &&
          (this.state.consultation || this.state.productOnly) ? (
          <Builder
            consultation={this.state.consultation}
            clinicId={
              this.state.productOnly
                ? this.state.selectedClinicId
                : this.state.clinicId
            }
            clinicsList={this.state.clinicList}
            stage="finances"
            isProductOnly={this.state.productOnly}
            inventoryItemList={this.state.inventoryItems}
            onClinicChange={this.getInventoryList}
          />
        ) : (
          <Loader />
        )}
      </>
    )
  }
}

InvoiceBuilder.defaultProps = {
  patient: {},
}

InvoiceBuilder.propTypes = {
  inventoryList: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  patient: PropTypes.object,
  global: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

/** Need to change this as per need */
const mapStateToProps = state => {
  return {
    patient: state.patients.selectedPatient,
    data: state.finance,
    inventoryList: state.inventoryList,
    inventoryOptionList: state.inventoryOptionList,
    global: state.global,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetPatientDetail,
        A_GetPatientConsultationDetail,
        A_GetClinicsList,
        A_GetInventoryList,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InvoiceBuilder),
)
