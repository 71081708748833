import React from 'react'
import PropTypes from 'prop-types'
import * as func from '../../../utilities/ReusableFunctions'
import { A_ToggleDatePicker } from '../../../actions'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import DatePickerHHL from '../../DatePicker/DatePickerHHL'
import MonthlyDatePickerHHL from '../../DatePicker/MonthlyDatePicker'
import { default as PatientRecordHeader } from '../../PatientRecord/Header'
import {
  financeFeatureToggle,
  isAppointmentOverviewEnabled,
  isAppointmentPreOrderEnabled,
  restrictedToInternal,
  // restrictedToExternal,
} from '../../../utilities/featureToggle'
import moment from 'moment'
import { REACT_APP_SERVERS } from '../../../api_urls'

class Header extends React.Component {
  defaultHeader = title => {
    return (
      <div>
        <article className="nav--tool__toolDetails">
          <div className="nav--tool__logo" />
          <h1 className="nav--tool__heading h3">{title}</h1>
        </article>
      </div>
    )
  }

  renderHeader = () => {
    const {
      location,
      A_ToggleDatePicker,
      datepickerIsVisible,
      clinicList,
      selectedDate,
      scheduleCalendarDateHeader,
      patient,
    } = this.props

    const internalRestrictionAvailable =
      process.env.REACT_APP_SERVER &&
      process.env.REACT_APP_SERVER === REACT_APP_SERVERS.PROD
        ? clinicList.find(
            ({ id }) =>
              restrictedToInternal.find(clinic_id => id === clinic_id) !==
              undefined,
          ) !== undefined
        : true
    //
    // const externalRestrictionAvailable =
    //   process.env.REACT_APP_SERVER &&
    //   process.env.REACT_APP_SERVER === REACT_APP_SERVERS.PROD
    //     ? clinicList.find(
    //         ({ id }) =>
    //           restrictedToExternal.find(clinic_id => id === clinic_id) !==
    //           undefined,
    //       ) !== undefined
    //     : true

    const regex = /\/clinics\/[0-9]/

    if (regex.test(location.pathname) || location.pathname === '/clinics') {
      return this.defaultHeader('Your clinics')
    } else if (
      location.pathname === '/clinics/upload-file' ||
      location.pathname === '/clinics/upload-data' ||
      location.pathname === '/clinics/data-import-error-logs'
    ) {
      return this.defaultHeader('Patient import')
    } else if (location.pathname.startsWith('/clinics/calendar/')) {
      return (
        <article className="nav--tool__toolDetails">
          <div className="nav--tool__logo" />
          {location.pathname.endsWith('appointments') ? (
            <div className="nav--tool__heading nav--tool__datePicker_header">
              <button
                className="datePicker_header_button"
                onClick={A_ToggleDatePicker}
                disabled={`${clinicList.length > 0 ? '' : 'disabled'}`}
              >
                <span className="datePicker_header--primaryText">
                  {selectedDate ? selectedDate.format('DD MMM ') : ''}
                </span>
                <span className="datePicker_header--secondaryText">
                  {selectedDate ? selectedDate.format('YYYY') : ''}
                </span>
              </button>
              {datepickerIsVisible ? (
                <DatePickerHHL calendarType="weekly_calendar" />
              ) : null}
            </div>
          ) : location.pathname.endsWith('planner') ? (
            <div className="nav--tool__heading nav--tool__datePicker_header">
              <button
                className="datePicker_header_button"
                onClick={A_ToggleDatePicker}
              >
                <span className="datePicker_header--primaryText">
                  {scheduleCalendarDateHeader
                    ? scheduleCalendarDateHeader.month
                    : ''}{' '}
                </span>
                <span className="datePicker_header--secondaryText">
                  {scheduleCalendarDateHeader
                    ? scheduleCalendarDateHeader.year
                    : ''}
                </span>
              </button>
              {datepickerIsVisible ? (
                <MonthlyDatePickerHHL calendarType="monthly_scheduler" />
              ) : null}
            </div>
          ) : (
            <div className="nav--tool__heading nav--tool__datePicker_header">
              <div className="datePicker_header_button">
                <span className="datePicker_header--primaryText">Calendar</span>
              </div>
            </div>
          )}
          <Link to="/clinics/calendar/appointments">
            <article
              className={`buttonTransparent type_header_button ${
                location.pathname === '/clinics/calendar/appointments'
                  ? 'buttonTransparent type_header_button  type_header_button--selected'
                  : ' '
              }`}
            >
              Appointments
            </article>
          </Link>
          <Link to="/clinics/calendar/planner">
            <button
              className={`buttonTransparent type_header_button ${
                location.pathname === '/clinics/calendar/planner'
                  ? 'type_header_button--selected'
                  : ''
              }`}
            >
              Planner
            </button>
          </Link>
          {isAppointmentOverviewEnabled ? (
            <Link to="/clinics/calendar/overview">
              <button
                className={`buttonTransparent type_header_button  ${
                  location.pathname === '/clinics/calendar/overview'
                    ? 'type_header_button--selected'
                    : ''
                }`}
              >
                Overview
              </button>
            </Link>
          ) : (
            ''
          )}
          {isAppointmentPreOrderEnabled ? (
            <Link to="/clinics/calendar/pre-ordering">
              <button
                className={`buttonTransparent type_header_button  ${
                  location.pathname === '/clinics/calendar/pre-ordering'
                    ? 'type_header_button--selected'
                    : ''
                }`}
              >
                Pre-ordering
              </button>
            </Link>
          ) : (
            ''
          )}
        </article>
      )
    } else if (location.pathname.indexOf('/clinics/analytics') === 0) {
      return (
        <article className="nav--tool__toolDetails">
          <div className="nav--tool__logo" />
          <h1 className="nav--tool__heading h3">Analytics</h1>
          <Link to="/clinics/analytics/summary">
            <article
              className={`buttonTransparent type_header_button ${
                location.pathname === '/clinics/analytics/summary'
                  ? 'buttonTransparent type_header_button  type_header_button--selected'
                  : ' '
              }`}
            >
              Summary
            </article>
          </Link>
          <Link to="/clinics/analytics/reports">
            <button
              className={`buttonTransparent type_header_button ${
                location.pathname === '/clinics/analytics/reports'
                  ? 'type_header_button--selected'
                  : ''
              }`}
            >
              Report Builder
            </button>
          </Link>
          <Link to="/clinics/analytics/appointments">
            <button
              className={`buttonTransparent type_header_button ${
                location.pathname === '/clinics/analytics/appointments'
                  ? 'type_header_button--selected'
                  : ''
              }`}
            >
              Appointment Report Builder
            </button>
          </Link>
          {financeFeatureToggle.isReportingEnabled && (
            <Link to="/clinics/analytics/finance">
              <article
                className={`buttonTransparent type_header_button ${
                  location.pathname === '/clinics/analytics/finance'
                    ? 'type_header_button--selected'
                    : ''
                }`}
              >
                Daily Transactions
              </article>
            </Link>
          )}
          {internalRestrictionAvailable && (
            <Link to="/clinics/analytics/recall">
              <button
                className={`buttonTransparent type_header_button ${
                  location.pathname === '/clinics/analytics/recall'
                    ? 'type_header_button--selected'
                    : ''
                }`}
              >
                Recall Builder
              </button>
            </Link>
          )}
        </article>
      )
    } else if (/^\/clinics\/profile\/([0-9]*)$/.test(location.pathname)) {
      return this.defaultHeader('Profile')
    } else if (/^\/clinics\/profile\/[0-9]/.test(location.pathname)) {
      return this.defaultHeader('Editing profile')
    } else if (location.pathname === '/clinics/inventory') {
      return this.defaultHeader('Products')
    } else if (location.pathname === '/clinics/patients') {
      return this.defaultHeader('Patient search')
    } else if (location.pathname === '/clinics/treatments') {
      return this.defaultHeader('Treatment library')
    } else if (location.pathname === '/clinics/staff') {
      return this.defaultHeader('Staff')
    } else if (location.pathname.indexOf('/clinics/patients') > -1 && patient) {
      const segments = location.pathname.split('/')

      let selectedPath = segments.pop() || segments.pop()

      if (parseInt(selectedPath)) {
        selectedPath = segments.pop()
      }

      return (
        <React.Fragment>
          <article className="nav--tool__toolDetails flex--nowrap">
            <div className="nav--tool__logo flex--noshrink" />
            <h1 className="nav--tool__heading h3 singleLine_truncatedText">
              {func.getFullName(patient)}
            </h1>
          </article>
          <article className="nav--tool__toolDetails nav--tool__toolDetails--centerLeft">
            <PatientRecordHeader patient={patient} selected={selectedPath} />
          </article>
        </React.Fragment>
      )
    } else if (location.pathname === '/clinics/settings') {
      return this.defaultHeader('Settings')
    } else if (location.pathname === '/clinics/support') {
      return this.defaultHeader('Support')
    } else {
      return <div className="nav--tool__logo" />
    }
  }

  render() {
    return <React.Fragment>{this.renderHeader()}</React.Fragment>
  }
}

Header.propTypes = {
  location: PropTypes.object,
  A_ToggleDatePicker: PropTypes.func.isRequired,
  datepickerIsVisible: PropTypes.bool.isRequired,
  clinicList: PropTypes.array.isRequired,
  selectedDate: PropTypes.instanceOf(moment),
  scheduleCalendarDateHeader: PropTypes.object,
  patient: PropTypes.object,
}

Header.defaultProps = {
  location: {},
  patient: {},
  scheduleCalendarDateHeader: null,
  selectedDate: null,
}

function mapState(state) {
  return {
    datepickerIsVisible: state.calendar.toggleDatePicker,
    clinicList: state.global.clinics,
    scheduleCalendarDateHeader: state.calendar.scheduleCalendarDateHeader,
    selectedDate: state.calendar.selectedDate,
    patient: state.patients.selectedPatient,
  }
}

export default withRouter(connect(mapState, { A_ToggleDatePicker })(Header))
