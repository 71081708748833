import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import Avatar from '../../../../../../Avatar'

import {
  getInitials,
  getFullName,
} from '../../../../../../../utilities/ReusableFunctions'
import { noteTypes } from '../../../../../../../utilities/ReusableObjects'

export default function Note({ type, created_at, clinician, text, timezone }) {
  switch (type) {
    case noteTypes.MANUAL:
      return (
        <article className="note__wrapper">
          <Avatar
            src={clinician ? clinician.signed_avatar : ''}
            type="practitionerProfileHeaderCalendar"
            initials={getInitials(clinician)}
          />
          <div className="note__text__wrapper">
            <span className="note__info note__contributer">
              {getFullName(clinician)}
            </span>
            <span className="note__info note__timeStamp">{`${moment(
              created_at,
            ).format('HH:mm')} - ${moment(created_at).format(
              'DD.MM.YY',
            )}`}</span>
            <p className="note__text">{text}</p>
          </div>
        </article>
      )
    case noteTypes.AUTOMATIC:
      return (
        <article className="note__wrapper note__wrapper--status">
          <div className="note__status">
            <span className="note__status__message">{text}</span>
            <span className="note__status__time">
              {timezone
                ? moment(created_at)
                    .tz(timezone)
                    .format(' HH.mm  -  DD.MM.YY')
                : ''}
            </span>
          </div>
        </article>
      )
    default:
      return ''
  }
}

Note.propTypes = {
  type: PropTypes.number.isRequired,
  created_at: PropTypes.string.isRequired,
  clinician: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
}
