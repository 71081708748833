import React from 'react'
import PropTypes from 'prop-types'

const TooltipCardValidator = ({ validators }) => (
  <section className="tooltipWrapper tooltip--recommended">
    <aside className="tooltip">
      <h6 className="tooltip__heading">Required</h6>
      {validators.map(validator => (
        <section
          key={validator.id}
          className={`tooltip__tip ${
            validator.check ? 'icon-tick-circle' : 'icon-empty'
          }`}
        >
          <span className="tooltip__tipText">{validator.message}</span>
        </section>
      ))}
      <span className="tooltip__pointerStretch tooltip__pointerStretch--large">
        <span className="tooltip__pointer" />
      </span>
    </aside>
  </section>
)

TooltipCardValidator.propTypes = {
  validators: PropTypes.array.isRequired,
}

export default TooltipCardValidator
