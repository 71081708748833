import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Loader from '../../components/PatientData/Loader'
import {
  appointmentPurposeIds,
  appointmentPurposeTitles,
  appointmentReasonIds,
  appointmentReasonTitles,
} from '../../utilities/ReusableObjects'
import ReactToPrint from 'react-to-print'
import OverviewClinician from './OverviewClinician'

class OverviewAppointments extends Component {
  handleClinician = clinician_id => {
    const { visibleClinician, setVisibleClinician } = this.props

    if (visibleClinician === clinician_id) {
      setVisibleClinician(0)
    } else {
      setVisibleClinician(clinician_id)
    }
  }

  render() {
    const {
      global,
      isLoading,
      clinicians,
      appointments,
      visibleClinician,
      startDate,
      endDate,
    } = this.props

    const clinic = global.currentClinic

    const appointmentReasons = Object.keys(appointmentReasonIds).filter(id => {
      return id !== 'APPOINTMENT'
    })
    const appointmentReasonsList = [...appointmentReasons].reverse()

    return (
      <section className="main__inner">
        <section className="patientImport__wrapper">
          <article className="overviewAppointments__oneThird no-print">
            <article className="form">
              <h2 className="h2 bold">{clinic.name}</h2>
              <span className="practitioner_role">
                {startDate.format('DD.MM.YYYY')} to{' '}
                {endDate.format('DD.MM.YYYY')}
              </span>
              <br />
              <br />
              <br />

              <span className="heading">Appointments Due</span>
              <h1 className="h1">{appointments.length}</h1>
              <br />

              <span className="heading">Appointment Types</span>
              {isLoading ? (
                <Loader />
              ) : (
                Object.keys(appointmentPurposeIds).map(key => {
                  const amount = appointments.filter(
                    ({ purpose, appointment_type }) =>
                      purpose === appointmentPurposeIds[key] &&
                      appointment_type === appointmentReasonIds.APPOINTMENT,
                  ).length
                  if (amount > 0) {
                    const percentage = (amount / appointments.length) * 100.0
                    return (
                      <div key={key} className="weeklyOverviewBar--wrapper">
                        <div
                          className={`weeklyOverviewBar weeklyOverviewBar--${key}`}
                          style={{ width: `${percentage}%` }}
                        ></div>
                        <span className={`weeklyOverviewLabel--${key}`}>
                          {amount} {appointmentPurposeTitles[key]}
                        </span>
                      </div>
                    )
                  }
                  return ''
                })
              )}
              {isLoading ? (
                ''
              ) : (
                <>
                  {appointmentReasons.map(key => {
                    const amount = appointments.filter(
                      ({ appointment_type }) =>
                        appointment_type === appointmentReasonIds[key],
                    ).length
                    const percentage = (amount / appointments.length) * 100.0
                    return (
                      <div key={key} className="weeklyOverviewBar--wrapper">
                        <div
                          className={`weeklyOverviewBar weeklyOverviewBar--${key}`}
                          style={{ width: `${percentage}%` }}
                        ></div>
                        <span className={`weeklyOverviewLabel--${key}`}>
                          {amount} {appointmentReasonTitles[key]}
                        </span>
                      </div>
                    )
                  })}
                  <ReactToPrint
                    trigger={() => {
                      // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                      // to the root node of the returned component as it will be overwritten.
                      return (
                        <div style={{ position: 'relative' }}>
                          <button
                            type="button"
                            className="buttonTertiary button--small"
                            style={{ width: '100%' }}
                          >
                            <span className="icon-printer" /> Print all
                          </button>
                        </div>
                      )
                    }}
                    content={() => this.printAllRef}
                  />
                </>
              )}
            </article>
          </article>
          <article
            className="overviewAppointment__twoThirds"
            ref={el => (this.printAllRef = el)}
          >
            {clinicians.map((clinician, i) => {
              return (
                <OverviewClinician
                  key={clinician.id}
                  clinician={clinician}
                  index={i}
                  appointments={appointments}
                  appointmentReasonsList={appointmentReasonsList}
                  visibleClinician={visibleClinician}
                  handleClinician={this.handleClinician}
                />
              )
            })}
          </article>
        </section>
      </section>
    )
  }
}

OverviewAppointments.defaultProps = {}

OverviewAppointments.propTypes = {
  global: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  clinicians: PropTypes.array.isRequired,
  appointments: PropTypes.array.isRequired,
  visibleClinician: PropTypes.number.isRequired,
  setVisibleClinician: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(moment).isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired,
}

export default OverviewAppointments
