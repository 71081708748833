import React from 'react'
import PropTypes from 'prop-types'

import TextInput from '../../../../forms/Input'
import Loader from '../../../../PatientData/Loader'

const Voucher = ({
  voucherCode,
  setVoucherCode,
  applyVoucherCode,
  isAddingVoucher,
}) => (
  <div className="settings--view settings--view-lower">
    <h2>Add credits with voucher</h2>
    <p className="settings--view-desc">
      If you have been given a promotional voucher code, enter it here to add
      the credits to your account.
    </p>
    <div style={{ width: '65%' }}>
      <TextInput
        type="text"
        value={voucherCode || ''}
        onChange={({ target: { value } }) => setVoucherCode(value)}
      />
    </div>
    <div style={{ display: 'flex' }}>
      <button
        className="buttonSecondary"
        onClick={() => applyVoucherCode(voucherCode)}
      >
        Apply Voucher
      </button>
      {isAddingVoucher ? <Loader isSetting /> : null}
    </div>
  </div>
)

Voucher.propTypes = {
  voucherCode: PropTypes.string.isRequired,
  setVoucherCode: PropTypes.func.isRequired,
  applyVoucherCode: PropTypes.func.isRequired,
  isAddingVoucher: PropTypes.bool.isRequired,
}

export default Voucher
