import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import {
  CardType,
  cardTypes,
  paymentTypes,
} from '../../../../utilities/ReusableObjects'
import {
  getFullName,
  formatCurrency,
} from '../../../../utilities/ReusableFunctions'

import Select from '../../../forms/Select'

import Note from './Note'

const PaymentViewer = ({ payments, activePayment, setBillId }) => {
  const cardType = cardTypes.find(({ id }) => id === activePayment.card_type)

  const paymentType = paymentTypes.find(
    ({ id }) => id === activePayment.payment_type,
  )

  const paymentUser = {
    ...activePayment.taken_by,
    note: activePayment.notes,
    date: moment(activePayment.date_of_payment).format('DD/MM/YYYY'),
  }

  const conditionalDataCheck = prop => (!payments.length ? '-' : prop)

  let cardTitle = ''

  switch (activePayment.card_type) {
    case CardType.UNKNOWN:
      cardTitle = 'Not specified'
      break
    default:
      cardTitle = cardType ? cardType.title : ''
  }

  return (
    <>
      <div className="payment-viewer">
        <div className="payment-viewer--transaction">
          <label
            htmlFor="transaction"
            className="form__label"
            style={{ marginTop: '10px' }}
          >
            Select a transaction
          </label>
          <div style={{ width: '30rem' }}>
            <Select
              options={
                payments.length
                  ? payments
                  : [
                      {
                        id: 1,
                        title: 'No transactions made...',
                      },
                    ]
              }
              onChange={({ target: { value } }) => setBillId(Number(value))}
              disabled={!payments.length}
            />
          </div>
        </div>
        <div className="payment-viewer--col payment-viewer--col-left">
          <label htmlFor="amount" className="form__label">
            Payment amount
          </label>
          <p>
            {conditionalDataCheck(
              activePayment.clinic
                ? formatCurrency(
                    activePayment.amount,
                    activePayment.clinic.locale,
                  )
                : '',
            )}
          </p>
          <label htmlFor="cardType" className="form__label">
            Card Type
          </label>
          <p>{conditionalDataCheck(cardTitle)}</p>
          <label htmlFor="taken" className="form__label">
            Payment taken by
          </label>
          <p>{conditionalDataCheck(getFullName(activePayment.taken_by))}</p>
        </div>
        <div className="payment-viewer--col payment--col-right">
          <label htmlFor="paymentType" className="form__label">
            Payment type
          </label>
          <p>{conditionalDataCheck(paymentType ? paymentType.title : '')}</p>
          <label htmlFor="location" className="form__label">
            Location of payment
          </label>
          <p>
            {conditionalDataCheck(
              activePayment.clinic ? activePayment.clinic.name : '',
            )}
          </p>
          <label htmlFor="date" className="form__label">
            Date of payment
          </label>
          <p>
            {conditionalDataCheck(
              moment(activePayment.date_of_payment).format('DD.MM.YYYY'),
            )}
          </p>
        </div>
        <hr />
        <div className="payment-viewer--note-container">
          <div className="payment-viewer--notes">
            <label htmlFor="note" className="form__label">
              Notes
            </label>
            {activePayment.notes ? (
              <Note paymentUser={paymentUser} />
            ) : (
              <div style={{ textAlign: 'center', marginLeft: 0 }}>
                No notes made for this payment
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

PaymentViewer.propTypes = {
  activePayment: PropTypes.object.isRequired,
  payments: PropTypes.array.isRequired,
  setBillId: PropTypes.func.isRequired,
}

export default PaymentViewer
