import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TutorialContext } from '../../App'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  A_GetClinicsList,
  A_GetPractitionerAppointmentDetails,
  A_SetAppointmentOpeningTime,
  A_CalendarHeaderActions,
  A_FetchClinicScheduleList,
  A_ClearAppointmentDetails,
  A_IsAppointmentChange,
  A_GetZoomStatus,
  A_CloseBookedAppointmentModal,
} from '../../actions'
import TimeSelector from './TimeSelector'
import TimeDivider from './TimeDivider'
import {
  calculateOutOfHoursHeight,
  getFullName,
  getInitials,
} from '../../utilities/ReusableFunctions'
import ZoomINOUT from './ZoomINOUT'
import PractitionerHeader from './PractitionerHeader'
import TimeDividerContainer from './TimeDividerContainer'
import dateFns from 'date-fns'
import AppointmentModal from '../../components/Appointments/Appointment'
import TutorialBtn from '../Tutorials/TutorialBtn'
import { isTutorialFeatureEnabled } from '../../utilities/featureToggle'
import moment from 'moment'

var appointmentDefaultLength = 15

class WeeklyCalendar extends Component {
  constructor(props) {
    super(props)
    const { calendar } = this.props
    this.divisionPosIndicator = React.createRef()
    this.timePosIndicator = React.createRef()
    this.appointmentScrollAreaRef = null

    this.state = {
      divisionPos: 0,
      currentTimePos: 0,
      bookedAppointments: [],
      allPractitionerIDs: '',
      zoom: calendar.zoomStatus,
      isAppointmentDataLoaded: false,
      outOfHoursTopHeight: 0,
      outOfHoursBottomHeight: 0,
    }
    this.getPractitionerAppointmentDivisionLength = this.getPractitionerAppointmentDivisionLength.bind(
      this,
    )
    this.setCurrentTime = this.setCurrentTime.bind(this)
    this.getBookedAppointments = this.getBookedAppointments.bind(this)
    this.getNewFormattedDate = this.getNewFormattedDate.bind(this)
    this.getWeekDayNumber = this.getWeekDayNumber.bind(this)
    this.getClinicianSchedules = this.getClinicianSchedules.bind(this)
    this.showHeaderAndAppointments = this.showHeaderAndAppointments.bind(this)
    this.getCurrentScrollPos = this.getCurrentScrollPos.bind(this)
    this.setZoomScrollPos = this.setZoomScrollPos.bind(this)
    this.setOutOfHoursArea = this.setOutOfHoursArea.bind(this)
    this.isCalendarAvailable = this.isCalendarAvailable.bind(this)
    this.onZoom = this.onZoom.bind(this)
    this.setAppointmentScrollAreaRef = this.setAppointmentScrollAreaRef.bind(
      this,
    )

    this.scrollPos = 0
    this.intervalID = 0
  }

  refresh() {
    const { actions } = this.props

    actions.A_GetClinicsList().then(() => {
      this.load(this.props)
    })
  }

  load(props) {
    const {
      actions,
      global: { currentClinic },
      calendar: { selectedDate },
    } = props

    this.setupOpeningHours(currentClinic)

    actions.A_CalendarHeaderActions(selectedDate.tz(currentClinic.timezone))

    this.getBookedAppointments(props)
    this.getClinicianSchedules()
  }

  reload = () => {
    this.getBookedAppointments()
    this.getClinicianSchedules()
  }

  setupOpeningHours(clinic) {
    const { actions } = this.props

    let dayNumber = this.getWeekDayNumber()

    let opening_hours = clinic.opening_hours

    if (opening_hours && opening_hours.length > 0) {
      let current_day = opening_hours.find(function(hours) {
        return hours.day === dayNumber && hours.is_open
      })

      this.setState({ openingHoursArray: clinic.opening_hours }, () => {
        actions.A_SetAppointmentOpeningTime(current_day)
        this.setOutOfHoursArea()
      })
    }
  }

  componentDidMount() {
    this.refresh()

    this.setCurrentTime()

    /** To set the initial scroll position of the clinician as his opening time */
    var currentObj = this
    this.intervalID = setInterval(function() {
      currentObj.setCurrentTime()
    }, 2000)
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { calendar, actions, global } = this.props

    this.setCurrentTime()
    if (props.calendar.zoomStatus !== calendar.zoomStatus) {
      this.setState({ zoom: props.calendar.zoomStatus })
    }

    if (
      this.state.isAppointmentDataLoaded &&
      (props.global.currentClinicID !== global.currentClinicID ||
        props.calendar.isAppointmentChanged)
    ) {
      this.load(props)
      actions.A_IsAppointmentChange(false)
    }
  }

  componentDidUpdate(prevProps) {
    const { calendar } = this.props
    if (prevProps.calendar.zoomStatus !== calendar.zoomStatus) {
      this.setCurrentTime()
      this.setZoomScrollPos(this.scrollPos)
      this.setOutOfHoursArea()
    }
    if (prevProps.calendar.defaultOpeningTime !== calendar.defaultOpeningTime) {
      this.setOutOfHoursArea()
    }
    if (prevProps.calendar.selectedDate !== calendar.selectedDate) {
      this.setState({ isAppointmentDataLoaded: false }, () => {
        this.load(this.props)
      })
    }
  }

  setOutOfHoursArea() {
    const { calendar } = this.props
    const { openingHoursArray } = this.state

    let Opentime = '0.00'
    let Closetime = '0.00'

    if (openingHoursArray && openingHoursArray.length > 0) {
      let opening_hours = openingHoursArray

      let dayNumber = this.getWeekDayNumber()

      let current_day = opening_hours.find(function(hours) {
        return hours.day === dayNumber && hours.is_open
      })

      if (current_day) {
        Opentime = current_day.opens
          .substr(0, 5)
          .split(':')
          .join('.')
        Closetime = current_day.closes
          .substr(0, 5)
          .split(':')
          .join('.')
      }
    }

    let appHeight = calendar.zoomStatus === 'zoomedout' ? 52.5 : 105
    let opening = calculateOutOfHoursHeight('open', Opentime, 15, appHeight)
    let closing = calculateOutOfHoursHeight('close', Closetime, 15, appHeight)

    ///// Calculate out of hours state
    this.setState(() => ({
      outOfHoursTopHeight: opening,
      outOfHoursBottomHeight: closing,
    }))
  }

  showHeaderAndAppointments() {
    const { visibleClinicians } = this.props

    if (visibleClinicians.length > 0) {
      this.setState({ isAppointmentDataLoaded: true })
    }
  }

  getClinicianSchedules() {
    const {
      actions,
      clinicianIDs,
      calendar: { selectedDate },
    } = this.props

    const components = this.getNewFormattedDate(selectedDate).split('-')
    const selectedYear = components[0]
    const selectedMonth = components[1]
    const selectedDay = components[2]

    actions.A_FetchClinicScheduleList(
      selectedYear,
      selectedMonth,
      selectedDay,
      clinicianIDs,
    )
  }

  getWeekDayNumber() {
    const { calendar } = this.props
    let formatdate = this.getNewFormattedDate(calendar.selectedDate)
    let dayNumber = dateFns.getDay(new Date(formatdate)) + 1

    return dayNumber
  }

  componentWillUnmount() {
    const {
      actions,
      global: {
        currentClinic: { timezone },
      },
    } = this.props
    actions.A_CalendarHeaderActions(moment.tz(new Date(), timezone))
    clearInterval(this.intervalID)
  }

  getNewFormattedDate(date) {
    return date.format('YYYY-MM-DD')
  }

  getBookedAppointments(props) {
    const { actions, calendar, clinicianIDs, global } = props
      ? props
      : this.props

    if (global.currentClinicID !== null) {
      actions
        .A_GetPractitionerAppointmentDetails(
          this.getNewFormattedDate(calendar.selectedDate),
          clinicianIDs,
          global.currentClinicID,
        )
        .then(response => {
          this.setState({ bookedAppointments: response }, () => {
            this.showHeaderAndAppointments()
          })
        })
    } else {
      this.setState({ bookedAppointments: [] }, () => {
        this.showHeaderAndAppointments()
      })
    }
  }

  /**
   * Get the appointment Division length for zommedin and zoomed out
   */
  getPractitionerAppointmentDivisionLength(mins) {
    var divisionHeight = this.state.zoom === 'zoomedout' ? 52.5 : 105
    return mins * (divisionHeight / appointmentDefaultLength)
  }

  /**
   * Get the current scroll position to set on zoom
   */
  getCurrentScrollPos() {
    const appointmentHeight = this.getPractitionerAppointmentDivisionLength(
      appointmentDefaultLength,
    )
    this.scrollPos =
      this.appointmentScrollAreaRef.scrollTop /
      (appointmentHeight / appointmentDefaultLength)
  }

  /**
   * Set scroll position to match times shown in previous zoom view
   */
  setZoomScrollPos(previousTimePos) {
    const appointmentHeight = this.getPractitionerAppointmentDivisionLength(
      appointmentDefaultLength,
    )
    this.appointmentScrollAreaRef.scrollTop =
      (appointmentHeight / appointmentDefaultLength) * previousTimePos
  }

  /**
   * Current time indicator
   */
  setCurrentTime() {
    const {
      global: {
        currentClinic: { timezone },
      },
    } = this.props

    const momentDate = moment.tz(new Date(), timezone)
    const time = momentDate.get('minute')
    const hours = momentDate.get('hours')

    var percentTime = time / 60
    var calcTime = hours + percentTime
    var now = calcTime * 60
    var appointmentHeight = this.getPractitionerAppointmentDivisionLength(
      appointmentDefaultLength,
    )
    var currentTimePos = (appointmentHeight / appointmentDefaultLength) * now
    var divisionPos = currentTimePos - 3
    var timeIndicators = document.getElementsByClassName('currentTimeIndicator')
    this.setState({ divisionPos: divisionPos })
    for (var i = 0; i < timeIndicators.length; i++) {
      timeIndicators[i].setAttribute('style', 'top:' + currentTimePos + 'px;')
    }
    if (this.divisionPosIndicator.current) {
      this.divisionPosIndicator.current.setAttribute(
        'style',
        'top:' + divisionPos + 'px;',
      )
      this.timePosIndicator.current.setAttribute(
        'style',
        'top:' + currentTimePos + 'px;',
      )
    }
  }

  isCalendarAvailable() {
    const { global, visibleClinicians } = this.props
    return (
      this.state.isAppointmentDataLoaded &&
      global.currentClinicID &&
      global.currentClinic &&
      visibleClinicians.length > 0
    )
  }

  onZoom(type) {
    const { actions } = this.props
    actions.A_GetZoomStatus(type)
  }

  setAppointmentScrollAreaRef(element) {
    this.appointmentScrollAreaRef = element
  }

  render() {
    let TimeDividerSkeleton = []
    for (var k = 0; k < 96; k++) {
      TimeDividerSkeleton.push(k)
    }
    let headerSkeleton = [1, 2, 3, 4]
    const {
      actions,
      global,
      calendar,
      clinicAppointment,
      bookedAppointment,
      appointmentAlerts,
    } = this.props
    const { visibleClinicians, currentClinic } = global
    const { clinicianID, appointmentID, selectedDate, selectedTime } = calendar
    const clinicians = visibleClinicians.map(clinician => ({
      ...clinician,
      title: getFullName(clinician),
      initials: getInitials(clinician),
    }))

    return (
      <React.Fragment>
        <section className="main__inner--calendar">
          <section className="calendar__wrapper">
            <div className="calendar_header">
              <div className="calendar_initial_cell" />
              <article className="practitioner_header">
                {this.isCalendarAvailable() ? (
                  <>
                    {visibleClinicians.map((clinician, i) => {
                      return (
                        <PractitionerHeader
                          key={clinician.invite.id}
                          oddEvenKey={i + 1}
                          value={clinician}
                        />
                      )
                    })}
                  </>
                ) : (
                  <React.Fragment>
                    <article className="practitioner_header">
                      {headerSkeleton.map(res => {
                        return (
                          <div
                            key={`head_${res}`}
                            className="practitioner gridCol gridCol--odd"
                          >
                            <div className="practitioner_details">
                              <div className="practitioner_detail practitioner_detail_avatar">
                                <div className="practitioner--header__profilePicture skeletonLoad" />
                              </div>
                              <div className="practitioner_detail practitioner_detail_info">
                                <div className="practitioner_name skeletonLoad skeletonLoad--text" />
                                <div className="practitioner_role skeletonLoad skeletonLoad--text" />
                              </div>
                              <div className="practitioner_detail practitioner_detail_add">
                                <button
                                  type="button"
                                  className="buttonCircle--35 appointment__addButton icon-add"
                                />
                              </div>
                            </div>
                          </div>
                        )
                      })}
                      <div className="practitioner-spacer" />
                    </article>
                  </React.Fragment>
                )}
                {/** This renders each practitioner header details */}
                <div className="practitioner-spacer" />
              </article>
            </div>
            {calendar.showModal ? (
              <AppointmentModal
                type="create"
                currentClinic={currentClinic}
                clinicianID={clinicianID}
                clinicians={clinicians}
                appointmentDetails={bookedAppointment}
                treatmentTypes={clinicAppointment.treatmentTypeList}
                filteredTreatments={clinicAppointment.filteredTreatments}
                selectedDate={
                  selectedTime
                    ? selectedTime.clone()
                    : selectedDate
                    ? selectedDate.clone()
                    : moment.tz(new Date(), 'UTC')
                }
                closeModal={() => actions.A_ClearAppointmentDetails()}
                refreshAppointments={() => this.getBookedAppointments()}
                ignoreOpeningHours={calendar.ignoreOpeningHours}
              />
            ) : (
              ''
            )}
            {calendar.showBookedAppointmentModal ? (
              <AppointmentModal
                type="view"
                appointmentId={appointmentID}
                appointmentDetails={bookedAppointment}
                treatmentTypes={clinicAppointment.treatmentTypeList}
                filteredTreatments={clinicAppointment.filteredTreatments}
                appointmentAlerts={appointmentAlerts}
                currentClinic={currentClinic}
                clinicians={clinicians}
                clinicianID={clinicianID}
                closeModal={() => actions.A_CloseBookedAppointmentModal()}
                refreshAppointments={() => this.getBookedAppointments()}
              />
            ) : (
              ''
            )}
            {this.isCalendarAvailable() ? (
              <div className="calendar_body">
                <TimeSelector
                  styleProps={this.state}
                  selectedDate={calendar.appointmentDate}
                  currentDate={calendar.defaultDate}
                  calendarType="weeklyCalendar"
                  zoomStatus={this.state.zoom}
                />
                <article
                  ref={this.setAppointmentScrollAreaRef}
                  className="appointment_selector"
                >
                  <div className="appointment_wrapper">
                    {calendar.defaultDate === calendar.appointmentDate ? (
                      <div
                        className="currentTimeIndicator"
                        id="currentTime"
                        style={{ top: this.state.currentTimePos }}
                      />
                    ) : (
                      ''
                    )}
                    {/*** time dividers array */}
                    <TimeDividerContainer
                      outOfHoursTopHeight={this.state.outOfHoursTopHeight}
                      outOfHoursBottomHeight={this.state.outOfHoursBottomHeight}
                      zoom={calendar.zoomStatus}
                    />
                    <div className="appointment_column_wrapper">
                      {visibleClinicians.map((clinician, i) => {
                        return (
                          <TimeDivider
                            key={clinician.invite.id}
                            oddEvenKey={i + 1}
                            styleProps={this.state.currentTimePos}
                            defaultAppointmentLength={
                              clinician.default_appointment_length
                            }
                            clinician={clinician}
                            bookedAppointments={this.state.bookedAppointments}
                            OpeningTime={calendar.defaultOpeningTime}
                            zoomed={this.state.zoom}
                            load={this.reload}
                          />
                        )
                      })}
                    </div>
                  </div>
                </article>
              </div>
            ) : (
              /** Show Skeleton Loader for before data getting loaded for calendar slots and Timedividers on date and clinic change*/
              <div className="calendar_body">
                <article className="time_selector" />
                <article className="appointment_selector">
                  <div className="appointment_wrapper">
                    <div className="timeDivision_container">
                      {TimeDividerSkeleton.map(res => {
                        return (
                          <div
                            key={`timeDivider_${res}`}
                            className="timeDivider"
                            style={{ height: 105 }}
                          />
                        )
                      })}
                    </div>
                    <div className="appointment_column_wrapper">
                      {headerSkeleton.map(res => {
                        return (
                          <div
                            key={`col_${res}`}
                            className="appointment_column_container"
                          >
                            <div className="appointment_column gridCol gridCol--odd">
                              {TimeDividerSkeleton.map(res => {
                                return (
                                  <div
                                    key={`appointmentDivision_${res}`}
                                    className="appointmentDivision"
                                    style={{
                                      minHeight: '210px',
                                      height: '210px',
                                    }}
                                  >
                                    <div
                                      className="buttonTransparent buttonFill skeletonLoad"
                                      style={{
                                        minHeight: '-webkit-fill-available',
                                      }}
                                    />
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </article>
              </div>
            )}
          </section>
          <ZoomINOUT
            updateCurrentTime={this.setCurrentTime}
            setScrollPos={this.getCurrentScrollPos}
            zoomStatus={this.state.zoom}
            onZoom={this.onZoom}
          />
          {isTutorialFeatureEnabled ? (
            <TutorialContext.Consumer>
              {({
                toggleTutorial,
                setTutorial,
                tutorialList,
                history,
                activeTutorial,
              }) => (
                <TutorialBtn
                  right={25}
                  bottom={160}
                  tutorialList={tutorialList}
                  toggleTutorial={toggleTutorial}
                  setTutorial={setTutorial}
                  activeTutorial={activeTutorial}
                  history={history}
                />
              )}
            </TutorialContext.Consumer>
          ) : null}
        </section>
      </React.Fragment>
    )
  }
}

WeeklyCalendar.defaultProps = {
  appointmentAlerts: [],
}

WeeklyCalendar.propTypes = {
  actions: PropTypes.object.isRequired,
  calendar: PropTypes.object.isRequired,
  global: PropTypes.object.isRequired,
  visibleClinicians: PropTypes.array.isRequired,
  clinicianIDs: PropTypes.string.isRequired,
  clinicAppointment: PropTypes.object.isRequired,
  bookedAppointment: PropTypes.object.isRequired,
  appointmentAlerts: PropTypes.array,
}

const mapStateToProps = state => {
  return {
    global: state.global,
    calendar: state.calendar,
    currentMonthYear: state.calendar.currentMonthYear,
    clinicAppointment: state.clinicAppointment,
    visibleClinicians: state.global.visibleClinicians,
    clinicianIDs: state.global.clinicianIDs,
    bookedAppointment: state.bookedAppointment.data,
    appointmentAlerts: state.bookedAppointment.alerts,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetClinicsList,
        A_CalendarHeaderActions,
        A_FetchClinicScheduleList,
        A_GetPractitionerAppointmentDetails,
        A_SetAppointmentOpeningTime,
        A_IsAppointmentChange,
        A_GetZoomStatus,
        A_ClearAppointmentDetails,
        A_CloseBookedAppointmentModal,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WeeklyCalendar),
)
