import React from 'react'
import PropTypes from 'prop-types'

import Setting from '../../Settings/SettingController/Setting'

export default function SupportController({ activePage, setPage, pages }) {
  return (
    <div className="settings--controller">
      <li className="settings--controller-heading">Support sections</li>
      {pages.map(page => (
        <Setting
          key={page.id}
          setting={page}
          onClick={() => setPage(page)}
          isActive={activePage.id === page.id}
          isDisabled={page.sections.length === 0}
        />
      ))}
    </div>
  )
}

SupportController.propTypes = {
  activePage: PropTypes.object.isRequired,
  pages: PropTypes.array.isRequired,
  setPage: PropTypes.func.isRequired,
}
