import React from 'react'
import PropTypes from 'prop-types'

const ModalInfo = props => {
  return props.type === 'Staff' ? (
    <section className="modal__body">
      <article className="table--withBorder">
        <div className="tableRow--withBorder tableHeader">
          <section className="col__12-6 tableCell">User role</section>
          <section className="col__12-6 tableCell tableHeadCell">
            Capabilities
          </section>
        </div>
        <div className="tableRow--withBorder table__align--baseline">
          <section className="col__12-6">
            <span className="bold">Clinical</span>
          </section>
          <section className="col__12-6 p table__row--noMargin">
            Clinical users can perform patient consultation, manage calendar
            appointments, manage patient information, access consultation
            invoices and send notifications to secretaries
          </section>
        </div>
        <div className="tableRow--withBorder table__align--baseline">
          <section className="col__12-6">
            <span className="bold">Non-clinical</span>
          </section>
          <section className="col__12-6 p table__row--noMargin">
            Non-clinical users can manage calendar appointments, access patient
            medical information and manage patient general information
          </section>
        </div>
      </article>
      <p className="iconTableColumn--label p">
        All users are either clinical or non-clinical. ‘Admin’ access allows
        users to perform managerial functions in addition to their user role.
      </p>
      <p className="p">
        A clinic group has a single Account Admin, which is the person who
        creates the group.
      </p>
      <article className="table--withBorder">
        <div className="tableRow--withBorder tableHeader">
          <section className="col__12-6 tableCell">Admin role</section>
          <section className="col__12-6 tableCell tableHeadCell">
            Capabilities
          </section>
        </div>
        <div className="tableRow--withBorder table__align--baseline">
          <section className="col__12-6">
            <span className="bold">Account admin</span>
          </section>
          <section className="col__12-6 table__row--noMargin p">
            Existing role capabilities with added functionality of
            &apos;Admin&apos; features, creating or deleting clinics and
            treatments
          </section>
        </div>
        <div className="tableRow--withBorder table__align--baseline">
          <section className="col__12-6">
            <span className="bold">Admin</span>
          </section>
          <section className="col__12-6 table__row--noMargin p">
            Existing role capabilities with added functionality of editing
            clinics, managing staff and editing treatments
          </section>
        </div>
      </article>
    </section>
  ) : props.type === 'Equipment' ? (
    <section className="modal__body">
      <article className="table">
        <div className="tableRow tableHeader">
          <section className="col__12-6 tableCell">Mobility Option</section>
          <section className="col__12-6 tableCell tableHeadCell">
            Explanation
          </section>
        </div>
        <div className="tableRow">
          <section className="col__12-6">
            <span className="bold">Portable</span>
          </section>
          <section className="col__12-6">
            Equipment that can be moved between rooms easily (e.g. handheld
            tools)
          </section>
        </div>
        <div className="tableRow">
          <section className="col__12-6">
            <span className="bold">Static</span>
          </section>
          <section className="col__12-6">
            Equipment that cannot be removed from a room (e.g. large machines)
          </section>
        </div>
      </article>
    </section>
  ) : (
    ''
  )
}

ModalInfo.defaultProps = {
  type: '',
}

ModalInfo.propTypes = {
  type: PropTypes.string,
}

export default ModalInfo
