import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Steps extends Component {
  stepClass = s => {
    const { step } = this.props
    const active = 'processStepper__process__iconActive'
    const inactive = 'processStepper__process__iconInactive'
    const done = 'processStepper__process__iconDone icon-tick-circle'

    switch (s) {
      case 1:
        switch (step) {
          case 1:
            return active
          default:
            return done
        }
      case 2:
        switch (step) {
          case 1:
            return inactive
          case 2:
            return active
          default:
            return done
        }
      case 3:
        switch (step) {
          case 3:
            return active
          default:
            return inactive
        }
      default:
        break
    }
  }

  render() {
    const { step, steps } = this.props
    return (
      <section className="processStepper__wrapper">
        <article className="processStepper">
          <p className="processStepper__text">Progress</p>
          <aside
            className={`processStepper__steps processStepper__steps--step${step}`}
          >
            <article className="processStepper__stepper">
              <span className="processStepper__stepper__stepNumber">
                Step 0{step}
              </span>
              <span className="processStepper__stepper__stepName">
                {steps[step - 1]}
              </span>
              <span className="processStepper__stepper__pointer">
                <span className="processStepper__stepper__pointerInner"></span>
              </span>
            </article>

            <section className="processStepper__process">
              <div className="processStepper__process__outerSection">
                <span
                  className={`processStepper__process__icon1 ${this.stepClass(
                    1,
                  )}`}
                ></span>
              </div>
              <span
                className={`processStepper__process__icon2 ${this.stepClass(
                  2,
                )}`}
              ></span>
              <div className="processStepper__process__outerSection">
                <span
                  className={`processStepper__process__icon3 ${this.stepClass(
                    3,
                  )}`}
                ></span>
              </div>
            </section>
          </aside>
          <p className="processStepper__text"></p>
        </article>
      </section>
    )
  }
}

Steps.propTypes = {
  step: PropTypes.number.isRequired,
  steps: PropTypes.array.isRequired,
}

export default Steps
