import types from '../../actions/types'

var initialState = {
  treatmentsList: [],
  backupTreatments: [],
  treatmentTypes: [],
  isTreatmentUpdated: false,
  consentForm: null,
  productSheet: null,
  editTreatment: [],
  viewingTreatment: [],
  viewingTreatOption: [],
}
export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TREATMENT_LIST:
      return {
        ...state,
        treatmentsList: action.payload,
        backupTreatments: action.payload,
      }
    case types.SET_FILTERED_LIST:
      return { ...state, treatmentsList: action.payload }
    case types.SET_TREAT_TYPES:
      return { ...state, treatmentTypes: action.treatTypes }
    case types.SET_CONSENT_FORM:
      return { ...state, consentForm: action.file }

    case types.TREATMENT_UPDATE:
      return { ...state, isTreatmentUpdated: action.value }
    case types.EDIT_TREATMENT:
      return { ...state, editTreatment: action.value }

    case types.SET_PRODUCT_SHEET:
      return { ...state, productSheet: action.value }

    case types.SET_VIEWING_TREATMENT:
      return { ...state, viewingTreatment: action.treatment }

    case types.SET_VIEWING_TREAT_OPTION:
      return { ...state, viewingTreatOption: action.treatoption }

    default:
      return state
  }
}
