import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TutorialContext } from '../../App'
import { Link, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { A_GetAllClinicsList, A_FetchCliniciansList } from '../../actions'
import OpenClinicCard from '../../components/Clinic/OpenClinicCard'
import Loader from '../../components/PatientData/Loader'
import TutorialBtn from '../Tutorials/TutorialBtn'
import {
  isTutorialFeatureEnabled,
  maxClinics,
} from '../../utilities/featureToggle'
import * as auth from '../../services/OnBoarding/auth'

class ClinicSetUp extends Component {
  componentDidMount = () => {
    const { actions } = this.props

    actions.A_GetAllClinicsList()
  }

  getclinicID = id => {
    const { history } = this.props
    history.push(`/clinics/${id}`)
  }

  render() {
    const { global } = this.props
    const { allClinicsList, hasFetchedClinicList } = this.props.data
    return (
      <div>
        <main className="main">
          {isTutorialFeatureEnabled ? (
            <TutorialContext.Consumer>
              {({
                toggleTutorial,
                setTutorial,
                history,
                tutorialList,
                activeTutorial,
              }) => (
                <TutorialBtn
                  tutorialList={tutorialList}
                  toggleTutorial={toggleTutorial}
                  setTutorial={setTutorial}
                  activeTutorial={activeTutorial}
                  history={history}
                />
              )}
            </TutorialContext.Consumer>
          ) : null}
          <section className="main__inner--virtualClinic">
            {hasFetchedClinicList &&
            global.account_owner &&
            allClinicsList.length < maxClinics &&
            auth.getAccountType() !== '1' ? (
              <article
                role="presentation"
                className="clinic clinic--new modal__openButton"
              >
                <Link to="/clinics/create">
                  <section className="innerWrapper">
                    <span className="buttonCircle--x2 icon-add" />

                    <span className="heading">New Clinic</span>
                  </section>
                </Link>
              </article>
            ) : (
              ''
            )}
            {hasFetchedClinicList ? (
              <React.Fragment>
                {allClinicsList.map(clinic => (
                  <OpenClinicCard
                    clinic={clinic}
                    key={'clinic_' + clinic.id}
                    getclinicID={this.getclinicID}
                  />
                ))}
              </React.Fragment>
            ) : (
              <Loader />
            )}
          </section>
        </main>
      </div>
    )
  }
}

ClinicSetUp.propTypes = {
  actions: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  global: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
  return {
    data: state.clinicSetup,
    global: state.global,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetAllClinicsList,
        A_FetchCliniciansList,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClinicSetUp),
)
