import types from '../../actions/types'
import { getCurrencySymbol } from '../../utilities/ReusableFunctions'

var initialState = {
  clinicsList: [],
  hasFetchedClinicList: false,
  allClinicsList: [],
  emailResponseError: '',
  isFetching: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ASSIGN_CLINICADMIN.REQ:
      return {
        ...state,
        isFetching: true,
      }
    case types.ASSIGN_CLINICADMIN.OK:
      return {
        ...state,
        isFetching: false,
        emailResponseError: '',
        allClinicsList: [...state.allClinicsList, action.adminInfo],
      }
    case types.ASSIGN_CLINICADMIN.FAIL:
      var emailResponseError = ''
      if (action.error.data.email) {
        emailResponseError = action.error.data.email[0]
      } else {
        emailResponseError = ''
      }
      return {
        ...state,
        emailResponseError: emailResponseError,
      }
    case types.CLINIC_DATA_POST.OK:
      return {
        ...state,
        allClinicsList: [...state.allClinicsList, action.data],
      }
    case types.CLINIC_DATA_POST.FAIL:
      return {
        ...state,
      }
    case types.FETCH_CLINICLIST.OK:
      return {
        ...state,
        clinicsList: action.data,
      }
    case types.FETCH_CLINICLIST.FAIL:
      return {
        ...state,
      }
    case types.FETCH_ALL_CLINICLIST.OK:
      return {
        ...state,
        hasFetchedClinicList: true,
        allClinicsList: action.data.map(clinic => ({
          ...clinic,
          currencySymbol: getCurrencySymbol(clinic.locale),
        })),
      }
    case types.FETCH_ALL_CLINICLIST.FAIL:
      return {
        ...state,
      }
    default:
      return state
  }
}
