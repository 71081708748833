import types from '../../actions/types'
import moment from 'moment'
import { getFullName } from '../../utilities/ReusableFunctions'

var initialState = {
  consultation: null,
  consultationsList: [],
  invoicesList: [],
  invoicesNextUrl: null,
  createInvoiceSuccess: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PATIENT_CONSULTATION_DETAIL.OK: {
      let consultation = action.data
      let treatments = []
      let treatmentName = []
      let subTotal = 0.0
      let clinician = getFullName(consultation.appointment.clinician)
      let date = moment(consultation.appointment.start_date).format(
        'DD.MM.YYYY',
      )
      let consultationTime = moment(consultation.appointment.start_date).format(
        'HH:mm',
      )
      let vat = consultation.appointment.clinic.vat
      let location = consultation.appointment.clinic.name
      let locationId = consultation.appointment.clinic.id
      let notes = consultation.billing_notes

      // Loop through examination concerns
      consultation.examination_concerns.forEach(examination_concern => {
        let concern = examination_concern.concern.title
        let concernedAreas = []

        // Loop through concerned areas
        examination_concern.concerned_areas.forEach(area => {
          concernedAreas.push(concern + ', ' + area.title)
        })

        // Loop through levels
        examination_concern.levels.forEach(level => {
          let price = 0.0
          let title
          if (level.treatment_level) {
            price = parseFloat(level.treatment_level.price)
          }

          subTotal = parseFloat(subTotal) + price

          if (level.treatment_option) {
            title = level.treatment_option.title
            if (parseInt(treatmentName.indexOf(title)) === -1) {
              treatmentName.push(title)
            }
          }

          if (concernedAreas.length > 0 && level.treatment_option) {
            treatments.push({
              name: title,
              price: price,
              concernedAreas: concernedAreas,
              vat: vat,
              consultation_level_id: level.id,
              treatment_level_id: level.treatment_level
                ? level.treatment_level.id
                : null,
            })
          }
        })
      })

      treatmentName = treatmentName.join(', ')
      let data = {
        id: consultation.id,
        treatments: treatments,
        treatmentName: treatmentName,
        location: location,
        locationId: locationId,
        doctor: clinician,
        date: date,
        consultationTime: consultationTime,
        notes: notes,
        subTotal: subTotal,
        consultationType: consultation.consultation_type,
      }

      return {
        ...state,
        consultation: data,
      }
    }

    case types.GET_PATIENT_CONSULTATION_LIST.OK: {
      let consultationsList = []
      let consultations = action.data
      consultations.forEach(consultationEle => {
        let treatmentName = []
        let consultation = consultationEle
        let createInvoiceAllowed = consultation.can_create_bill
        let id = consultation.id
        let clinician = getFullName(consultation.appointment.clinician)

        const momentDate = moment.tz(consultation.appointment.start_date, 'UTC')

        const date = momentDate.format('DD.MM.YYYY')
        const consultationTime = momentDate.format('HH:mm')
        let location = consultation.appointment.clinic.name
        let locationId = consultation.appointment.clinic.id

        // Loop through examination concerns
        consultation.examination_concerns.forEach(concern => {
          let examination_concern = concern

          // Loop through levels
          examination_concern.treatments.forEach(treatmentEle => {
            let treatment = treatmentEle
            treatment.treatment_options.forEach(optionEle => {
              let option = optionEle
              if (option) {
                let title = option.title
                if (parseInt(treatmentName.indexOf(title)) === -1) {
                  treatmentName.push(title)
                }
              }
            })
          })
        })

        consultationsList.push({
          id: id,
          treatmentName: treatmentName.join(', '),
          location: location,
          locationId: locationId,
          doctor: clinician,
          date: date,
          createInvoiceAllowed: createInvoiceAllowed,
          consultationTime: consultationTime,
        })
      })

      return {
        ...state,
        consultationsList: consultationsList,
      }
    }

    case types.GET_IMPORTED_DATA.FAIL:
      return { ...state }
    case types.GET_PATIENT_BILLING_DATA.OK: {
      let invoicesList = []

      let invoices = action.data.results

      invoices.forEach(invoiceEle => {
        let invoice = invoiceEle
        let place = invoice.clinic.name
        let placeId = invoice.clinic.id
        let treatments = []
        let momentDate = moment(invoice.created_at)

        let billClinician = invoice.document
          ? getFullName(invoice.document.created_by)
          : ''

        let items = invoice.items
        items.forEach(ele => {
          let item = ele
          let title = item.text
          if (treatments.indexOf(title) === -1) {
            treatments.push(title)
          }
        })
        if (invoice.consultation && invoice.consultation.appointment) {
          billClinician = getFullName(
            invoice.consultation.appointment.clinician,
          )
          momentDate = moment.tz(
            invoice.consultation.appointment.start_date,
            'UTC',
          )
        }

        const billConsultationDate = momentDate.format('DD.MM.YYYY')
        const billConsultationTime = momentDate.format('HH:mm')

        invoicesList.push({
          id: invoice.id,
          treatments: treatments.join(', '),
          date: billConsultationDate,
          doctor: billClinician,
          location: place,
          paymentStatus: invoice.payment_status,
          locationId: placeId,
          total: invoice.total,
          paid: invoice.paid,
          consultationTime: billConsultationTime,
          quickbooksURL: invoice.quickbooks_url,
          signed_file: invoice.document ? invoice.document.signed_file : null,
          locale: invoice.clinic.locale,
        })
      })

      if (action.data.previous) {
        invoicesList = state.invoicesList.concat(invoicesList)
      }

      return {
        ...state,
        invoicesList: invoicesList,
        invoicesNextUrl: action.data.next,
      }
    }

    case types.CREATE_NEW_INVOICE_PDF.OK:
      return {
        ...state,
        createInvoiceSuccess: true,
      }
    case types.SET_TO_DEFAULT_VALUES:
      return {
        ...state,
        createInvoiceSuccess: false,
      }
    default:
      return state
  }
}
