import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'

import Avatar from '../../../../Avatar'
import Select from '../../../../forms/Select'
import DatePicker from '../../../../forms/datePickerInput'
import Slots from '../Slots'

import { A_GetAvaliableSlotList } from '../../../../../actions'

import {
  appointmentLengths,
  appointmentHours,
} from '../../../../../utilities/ReusableObjects'

export default function CreateEdit({
  clinician,
  setClinician,
  clinicians,
  clinicId,
  timezone,
  appointmentId,
  appointmentLengthProps,
  isDatePickerOpen,
  datePickerRef,
  toggleDatePicker,
  appointmentDate,
  activeSlot,
  setActiveSlot,
  availableSlots,
  setAvailableSlots,
  errors,
  setError,
  ignoreOpeningHours,
}) {
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const { minutes, setMinutes, hours, setHours } = appointmentLengthProps

  useEffect(() => {
    fetchAvailableSlots(clinician.id, minutes + hours)
  }, [minutes, hours, clinician])

  useEffect(() => {
    fetchAvailableSlots(clinician.id, minutes + hours)
  }, [appointmentDate])

  const onClinicianChange = newClinician => {
    const default_appointment_length = newClinician.default_appointment_length

    setClinician(newClinician)

    const hours = Math.floor(default_appointment_length / 60)
    const mins = Math.floor(default_appointment_length % 60)

    setHours(hours * 60)
    setMinutes(mins)
  }

  const onMinutesChange = minutes => {
    setMinutes(minutes)
  }

  const onHoursChange = hours => {
    setHours(hours)
  }

  const fetchAvailableSlots = async (clinicianId, appointmentLength) => {
    setLoading(true)
    if (appointmentLength > 0) {
      await dispatch(
        A_GetAvaliableSlotList(
          {
            clinic: clinicId,
            appointmentID: appointmentId,
            date: `${appointmentDate.format('YYYY/MM/DD')}/${clinicianId}`,
            appointmentLength,
            timezone,
          },
          ignoreOpeningHours,
        ),
      )
    }
    setLoading(false)
  }

  return (
    <>
      <fieldset className="form__group--topStackable createAppointment--practitioner">
        <label className="form__label" htmlFor="practitionerName">
          Clinician
        </label>
        <section className="form_hasImage_wrapper">
          <Avatar
            src={clinician.signed_avatar}
            type="formImage"
            initials={clinician.initials}
          />
          <Select
            id="clinician"
            options={clinicians}
            defaultSelected={clinician.id}
            onChange={({ target: { value: clinicianId } }) => {
              onClinicianChange(
                clinicians.find(({ id }) => id === Number(clinicianId)),
              )
            }}
            hasOwnWrapper
            hasImage
            hasTopMargin
          />
        </section>
      </fieldset>
      <fieldset className="form__group--stackable createAppointment--date">
        <section className="form__textField--has-icon">
          <label className="form__label" htmlFor="appointmentDate">
            Date
          </label>
          <DatePicker
            id="appointmentDate"
            classList={`form__textField form__textField--right-icon datePicker__input`}
            value={appointmentDate.format('DD/MM/YYYY')}
            onClick={() => toggleDatePicker(!isDatePickerOpen)}
            disabled={false}
            refVal={datePickerRef}
          />
        </section>
      </fieldset>
      <fieldset className="form__group--stackable createAppointment--time">
        <div className="form__label">Appointment length</div>
        <section className="form__group--halfWidth">
          <div className="form__group--halfWidth__panel">
            <Select
              id="hours"
              options={appointmentHours}
              defaultSelected={hours}
              onChange={({ target: { value } }) =>
                onHoursChange(Number(value), clinician)
              }
            />
          </div>
          <div className="form__group--halfWidth__panel">
            <Select
              id="minutes"
              options={appointmentLengths}
              defaultSelected={minutes}
              onChange={({ target: { value } }) =>
                onMinutesChange(Number(value), clinician)
              }
            />
          </div>
        </section>
      </fieldset>
      <Slots
        activeSlot={activeSlot}
        setActiveSlot={setActiveSlot}
        availableSlots={availableSlots}
        setAvailableSlots={setAvailableSlots}
        appointmentDate={appointmentDate}
        appointmentDuration={minutes + hours}
        isLoading={isLoading}
        errors={errors}
        setError={setError}
      />
    </>
  )
}

CreateEdit.defaultProps = {
  appointmentId: '',
}

CreateEdit.propTypes = {
  clinician: PropTypes.object.isRequired,
  setClinician: PropTypes.func.isRequired,
  appointmentId: PropTypes.string,
  appointmentLengthProps: PropTypes.object.isRequired,
  clinicians: PropTypes.array.isRequired,
  clinicId: PropTypes.number.isRequired,
  timezone: PropTypes.string.isRequired,
  datePickerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  toggleDatePicker: PropTypes.func.isRequired,
  isDatePickerOpen: PropTypes.bool.isRequired,
  appointmentDate: PropTypes.instanceOf(moment).isRequired,
  activeSlot: PropTypes.object.isRequired,
  setActiveSlot: PropTypes.func.isRequired,
  availableSlots: PropTypes.array.isRequired,
  setAvailableSlots: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
  ignoreOpeningHours: PropTypes.bool.isRequired,
}
