import React from 'react'
import PropTypes from 'prop-types'
import Setting from './Setting'

const SettingsController = ({ activeSetting, clinicSettings, setSetting }) => {
  const settingOption = (settings, title) => {
    return (
      <>
        <li className="settings--controller-heading">{title}</li>
        {settings.map(setting => (
          <Setting
            key={setting.id}
            setting={setting}
            onClick={() => setSetting(setting)}
            isActive={activeSetting.id === setting.id}
            isDisabled={setting.isDisabled}
          />
        ))}
      </>
    )
  }

  return (
    <div className="settings--controller">
      {settingOption(clinicSettings, 'Clinic group settings')}
    </div>
  )
}

SettingsController.propTypes = {
  activeSetting: PropTypes.object.isRequired,
  clinicSettings: PropTypes.array.isRequired,
  setSetting: PropTypes.func.isRequired,
}

export default SettingsController
