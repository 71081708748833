import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../../Modal'
import TextInput from '../../forms/Input'
//import MainButton from '../../Buttons'

const InventoryModals = ({
  showModel, //eslint-disable-line
  modals, //eslint-disable-line
  toggleModal, //eslint-disable-line
  productNameInput, //eslint-disable-line
  productPriceInput, //eslint-disable-line
  changeOption, //eslint-disable-line
  submitInventory, //eslint-disable-line
  showConfirm, //eslint-disable-line
  errors, //eslint-disable-line
  currencySymbol,
  ...props //eslint-disable-line
}) => {
  const loadButtons = () => {
    let buttons = []
    const label =
      modals.showCreate || modals.showCreateOption ? 'Save' : 'Save changes'
    buttons = [
      {
        type: 'button',
        style: 'tertiary',
        label: 'Close',
        size: 'small',
        events: {
          onClick: e => toggleModal(e),
        },
      },
      {
        type: 'button',
        style: 'secondary',
        label: label,
        size: 'small',
        events: {
          onClick: () => submitInventory(),
        },
      },
    ]
    return buttons
  }

  const leftButtons = () => {
    const label = modals.showEdit ? 'Delete product' : 'Delete option'
    let buttons = [
      {
        type: 'button',
        style: 'alert',
        label: label,
        size: 'small',
        events: {
          onClick: e => {
            showConfirm(e)
          },
        },
      },
    ]
    return buttons
  }
  return (
    <React.Fragment>
      {showModel ? (
        <Modal
          id="inventoryModal"
          title={
            modals.showCreate
              ? 'Create new product'
              : modals.showCreateOption
              ? 'New product option'
              : modals.showEdit
              ? 'Editing product'
              : modals.showEditOption
              ? 'Editing product option'
              : ''
          }
          closeModal={e => toggleModal(e)}
          rightButtons={loadButtons()}
          leftButtons={
            modals.showEdit || modals.showEditOption ? leftButtons() : []
          }
          handleDelete={showConfirm()}
          handleCancel={showConfirm()}
          toolTipHeader="Are you sure you want to delete this product?"
          toolTipBody="this action cannot be undone"
        >
          <div className="modal__body">
            <div className="scheduleItem__half--withPadding">
              <TextInput
                type="text"
                id="productName"
                placeholder={
                  modals.showCreate || modals.showEdit
                    ? 'e.g. Suncream'
                    : `e.g. ${currencySymbol}10 Off Voucher`
                }
                label={
                  modals.showCreate || modals.showEdit
                    ? 'Product name'
                    : 'Option name'
                }
                value={productNameInput}
                error={errors.titleError ? 'Required' : ''}
                onChange={e => changeOption(e)}
              />
            </div>
            {modals.showCreateOption || modals.showEditOption ? (
              <section className="scheduleItem__half--withPadding">
                <TextInput
                  type="number"
                  id="productPrice"
                  noMargin={true}
                  label={`Unit price (${currencySymbol})`}
                  value={productPriceInput}
                  error={errors.priceError ? 'Required' : ''}
                  onChange={e => changeOption(e)}
                />
              </section>
            ) : (
              ''
            )}
          </div>
        </Modal>
      ) : (
        ''
      )}
    </React.Fragment>
  )
}

InventoryModals.defaultProps = {
  errors: {},
}

InventoryModals.propTypes = {
  errors: PropTypes.object,
  modals: PropTypes.object.isRequired,
  currencySymbol: PropTypes.string.isRequired,
}

export default InventoryModals
