import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import PropTypes from 'prop-types'
import PatientSidebar from './PatientSideBar'
import NoteBanner from './Note'
import TreatmentInvoice from './TreatmentInvoice'
import ProductInvoice from './ProductInvoice'
import SubTopBar from './SubTopBar'
import { A_CreateNewInvoicePDF } from '../../actions'
import TextAreaInput from '../forms/Textarea'
import AlertModal from '../Modal/Alert'
import { localeForClinic } from '../../utilities/ReusableFunctions'

const InvoiceBuilder = ({
  consultation,
  isProductOnly,
  filters,
  inventoryItemList,
  onClinicChange,
}) => {
  const { patientId } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const global = useSelector(state => state.global)

  const [createInvoiceEnabled, setCreateInvoiceEnabled] = useState(false)

  const [subTotal, setSubTotal] = useState(0.0)
  const [totalDiscount, setTotalDiscount] = useState(0.0)
  const [totalVat, setTotalVat] = useState(0.0)

  const [clinic, setClinic] = useState(global.currentClinicID)
  const [locale, setLocale] = useState('en_GB')
  const [treatments, setTreatments] = useState([])
  const [products, setProducts] = useState([])

  const [notes, setNotes] = useState('')

  //Search for a product and update the search results
  const [productSearchValue, setProductSearchValue] = useState('')
  const [searchResults, setSearchResults] = useState([])
  useEffect(() => {
    const results = inventoryItemList.filter(
      item =>
        item.productTitle
          .toLowerCase()
          .indexOf(productSearchValue.toLowerCase()) > -1 ||
        item.title.toLowerCase().indexOf(productSearchValue.toLowerCase()) > -1,
    )

    setSearchResults(results)
  }, [inventoryItemList, productSearchValue])

  //Initial launch set everything up with the consultation
  useEffect(() => {
    setSubTotal(0.0)
    setTotalDiscount(0.0)
    setTotalVat(0.0)

    if (consultation) {
      setClinic(consultation.locationId ?? global.currentClinicID)

      setNotes(consultation.notes)

      const treatments = consultation.treatments.map(treatment =>
        treatmentWithValues({
          ...treatment,
          item: {
            text: treatment.name,
            detail: treatment.concernedAreas.join('\n'),
            cost: treatment.price,
            vat: consultation.consultationType === 1,
            consultation_level: { id: treatment.consultation_level_id },
            treatment_level: { id: treatment.treatment_level_id },
            discount: null,
          },
        }),
      )

      setTreatments(treatments)
    }
  }, [consultation])

  //Update information related to a clinic change
  useEffect(() => {
    setLocale(localeForClinic(global.clinics, clinic))
    removeAllProducts()
    onClinicChange(clinic)
  }, [clinic])

  const [showNewProductRow, setShowNewProdctRow] = useState(false)

  useEffect(() => {
    calculateSubtotal()
    calculateVat()
    calculateDiscount()
    setShowNewProdctRow(false)
    setProductSearchValue('')
    setCreateInvoiceEnabled((treatments + products).length > 0)

    if (isVatModalOpen) {
      toggleVatModal()
    }
  }, [treatments, products])

  const updateDiscount = (objectId, property, list, updateFunc, discount) => {
    updateFunc(
      list.map(t => {
        if (t[property] === objectId) {
          t = {
            ...t,
            item: {
              ...t.item,
              discount: discount
                ? {
                    ...discount,
                    amount:
                      discount.type === 0
                        ? discount.value
                        : Math.floor(
                            t.item.cost *
                              (parseFloat(discount.value) / 100.0) *
                              100.0,
                          ) / 100.0,
                  }
                : null,
            },
          }
        }

        return treatmentWithValues(t)
      }),
    )
  }

  const applyDiscount = (consultation_level_id, discount) => {
    updateDiscount(
      consultation_level_id,
      'consultation_level_id',
      treatments,
      setTreatments,
      discount,
    )
  }

  const removeDiscount = consultation_level_id =>
    applyDiscount(consultation_level_id, null)

  const applyProductDiscount = (index, discount) =>
    updateDiscount(index, 'index', products, setProducts, discount)

  const removeProductDiscount = index => applyProductDiscount(index, null)

  const changeClinic = event => {
    const selected = event.currentTarget.value

    setClinic(selected)
  }

  const treatmentWithValues = treatment => {
    let subtotal = treatment.item.cost
    let discount = treatment.item?.discount?.amount ?? 0.0

    const vatAmount = treatment.item.vat
      ? (subtotal - discount) * (parseFloat(treatment.vat) / 100.0)
      : 0.0

    const total = subtotal - discount + vatAmount

    return {
      ...treatment,
      subtotal,
      discount,
      vatAmount,
      total,
    }
  }

  const calculateVat = () =>
    setTotalVat(
      [...treatments, ...products].reduce((v, t) => v + t.vatAmount, 0.0),
    )

  const calculateSubtotal = () =>
    setSubTotal(
      [...treatments, ...products].reduce((s, t) => s + t.subtotal, 0.0),
    )

  const calculateDiscount = () =>
    setTotalDiscount(
      [...treatments, ...products].reduce(
        (d, { item: { discount } }) => d + (discount ? discount.amount : 0.0),
        0.0,
      ),
    )

  const updateVat = (objectId, property, list, updateFunc) =>
    updateFunc(
      list.map(t => {
        if (t[property] === objectId) {
          t.item.vat = !t.item.vat
          return treatmentWithValues(t)
        }

        return t
      }),
    )

  const toggleVat = consultation_level_id =>
    updateVat(
      consultation_level_id,
      'consultation_level_id',
      treatments,
      setTreatments,
    )

  const toggleProductVat = index =>
    updateVat(index, 'index', products, setProducts)

  const createInvoice = () => {
    let data = {
      clinic: { id: clinic },
      patient: { id: patientId },
      items: [...treatments, ...products].map(({ item }) => item),
      notes: invoiceNotes,
      consultation: !isProductOnly ? { id: parseInt(consultation.id) } : null,
    }

    dispatch(
      A_CreateNewInvoicePDF({
        consultation: data,
        clinicID: clinic,
        patientID: patientId,
      }),
    ).then(() => {
      history.push(showInvoiceTimeLine())
    })
  }

  const showInvoiceTimeLine = () => `/clinics/patients/${patientId}/finances`

  const addNewProduct = () => {
    setShowNewProdctRow(true)
  }

  const hideNewProduct = () => {
    setShowNewProdctRow(false)
  }

  const selectProduct = ({
    currentTarget: {
      dataset: { patient },
    },
  }) => {
    const product = searchResults.find(({ id }) => {
      return Number(id) === Number(patient)
    })

    setProducts([
      ...products,
      treatmentWithValues({
        ...product,
        index: products.length,
        item: {
          text: product.productTitle,
          detail: product.title,
          cost: product.productPrice,
          vat: false,
          treatment_level: { id: product.treatment_level_id },
          discount: null,
        },
      }),
    ])
  }

  const removeProduct = index => {
    setProducts(
      products
        .filter((p, i) => index !== i)
        .map((p, i) => ({ ...p, index: i })),
    )
  }

  const removeAllProducts = () => {
    setProducts([])
  }

  const [invoiceNotes, setInvoiceNotes] = useState('')
  const addNote = ({ currentTarget: { value } }) => {
    setInvoiceNotes(value)
  }

  const [vatModalId, setVatModalId] = useState(null)
  const [isVatModalOpen, setIsVatModalOpen] = useState(false)
  const toggleVatModal = () => {
    setIsVatModalOpen(!isVatModalOpen)
  }

  useEffect(() => {
    if (!isVatModalOpen) {
      setVatModalId(null)
    }
  }, [isVatModalOpen])

  const isCosmeticConsultation = consultation
    ? consultation.consultationType === 1
    : 0

  const clinics = global.filterClinics

  return (
    <React.Fragment>
      {isCosmeticConsultation && isVatModalOpen ? (
        <AlertModal
          message="Are you sure you want to remove VAT as this is an invoice for a cosmetic consultation?"
          title="Remove VAT"
          rightButtons={[
            {
              type: 'button',
              style: 'tertiary',
              label: 'Proceed',
              size: 'small',
              events: {
                onClick: () => {
                  toggleVat(vatModalId)
                },
              },
            },
          ]}
          closeAlert={() => toggleVatModal()}
        />
      ) : (
        ''
      )}
      <main className="main main__patient patient__totalRow">
        <SubTopBar
          filterSet={filters}
          stage="invoice"
          header={`${isProductOnly ? 'New invoice' : 'Consultation'}`}
          showInvoiceTimeLine={showInvoiceTimeLine}
          data={consultation}
          locale={locale}
        />
        <section className="main__patient--twoThirds">
          {!isProductOnly ? (
            <article className="invoiceItem__container">
              <h4 className="h3 secondaryMarginBottom">Treatments</h4>
              <NoteBanner notes={notes} />
              <TreatmentInvoice
                treatments={treatments}
                applyDiscount={applyDiscount}
                removeDiscount={removeDiscount}
                toggleVat={toggleVat}
                toggleVatModal={toggleVatModal}
                isCosmeticConsultation={isCosmeticConsultation}
                setVatModalId={setVatModalId}
                locale={locale}
              />
            </article>
          ) : (
            ''
          )}

          <article className="invoiceItem__container">
            <h4 className="h3 secondaryMarginBottom">Products</h4>
            <ProductInvoice
              products={products}
              applyDiscount={applyProductDiscount}
              removeDiscount={removeProductDiscount}
              toggleVat={toggleProductVat}
              addProductRow={addNewProduct}
              hideNewProduct={hideNewProduct}
              onProductSearch={({ target: { value } }) =>
                setProductSearchValue(value)
              }
              productSearchValue={productSearchValue}
              clinicsList={isProductOnly ? clinics : []}
              selectedClinic={clinic}
              onClinicChange={changeClinic}
              searchResults={searchResults}
              removeProduct={removeProduct}
              selectProduct={selectProduct}
              locale={locale}
              showNewProductRow={showNewProductRow}
            />
          </article>
          <article className="invoiceItem__container">
            <h4 className="h3 secondaryMarginBottom">Additional Information</h4>
            <section className="treatment__invoiceCard">
              <TextAreaInput
                id="invoiceNotes"
                noMargin={true}
                label="Invoice Notes"
                isRequired={false}
                onChange={addNote}
                value={invoiceNotes}
              />
            </section>
          </article>
        </section>
        <section className="main__patient--oneThird">
          <PatientSidebar
            creator={true}
            treatments={consultation}
            subTotal={subTotal}
            discount={totalDiscount}
            VAT={totalVat}
            createInvoice={createInvoice}
            createInvoiceEnabled={createInvoiceEnabled}
            locale={locale}
          />
        </section>
      </main>
    </React.Fragment>
  )
}

InvoiceBuilder.defaultProps = {
  filters: [],
  isProductOnly: false,
  inventoryItemList: [],
  onClinicChange: () => {},
  consultation: null,
}

InvoiceBuilder.propTypes = {
  isProductOnly: PropTypes.bool,
  inventoryItemList: PropTypes.array,
  onClinicChange: PropTypes.func,
  filters: PropTypes.array,
  consultation: PropTypes.object,
}

// Wrap the component to inject dispatch and state into it
export default InvoiceBuilder
