import React from 'react'
import PropTypes from 'prop-types'
import { TutorialContext } from '../../App'
import SearchHeader from '../../components/Search/Header'
import FeedbackStrap from '../../components/Feedback/FeedbackStrap'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  A_SetTreatmentList,
  A_SetFilteredTreatmentList,
  A_SetTreatTypes,
  A_PostNewTreatment,
  A_TreatmentUpdated,
  A_PostNewTreatmentConsent,
  A_UploadConsentForm,
  A_PostEditTreatment,
  A_SetEditTreatment,
  A_DeleteTreatment,
  A_DeleteConsentTreatment,
  A_DeleteProductSheetTreatment,
  A_PostNewTreatmentOption,
  A_SetViewingTreatment,
  A_SetViewingTreatOption,
  A_PostEditTreatmentOption,
  A_DeleteTreatmentOption,
  A_SetProductSheet,
  A_PostNewTreatmentProductSheet,
  A_SetViewingClinic,
  A_GetEpharmProdList,
  A_GetClinicsList,
  A_FetchClinicTreatmentList,
} from '../../actions'
import {
  getFileSizefromEvent,
  getFileName,
} from '../../utilities/ReusableFunctions'
import TreatmentModal from '../../components/Library/Treatment/treatment'
import CreateCustomTreatOption from '../../components/Library/Treatment/CreateTreatment/createCustomTreatOpt'
import TreatmentOverviewItem from '../../components/Library/Treatment/TreatmentOverviewItem/index'
import * as func from '../ModalOverlay'
import FeedbackNotification from '../../components/Feedback/FeedbackNotification'
import SearchResultMessage from '../../components/Search/ResultMessage/index'
import MainButton from '../../components/Buttons'
import TutorialBtn from '../Tutorials/TutorialBtn'
import { isTutorialFeatureEnabled } from '../../utilities/featureToggle'
import Placeholder from '../../components/Tutorials/Placeholder'
import { getTasks } from '../../services/OnBoarding/auth'

let noMatchValue = ''
class TreatmentLibrary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDefaultTreatmentModal: false,
      showTreatmentModal: false,
      showTreatmentOptionModal: false,
      treatment: '',
      allowEdit: false,
      consentTitle: '',
      newTreatment: false,
      filters: [],
      editTreatment: [],
      loader: false,
      updatedConsentName: null,
      notificationMsg: null,
      isNotificationvisible: false,
      isBannerVisible: false,
      searchResultEpharm: [],
    }
    this.openDefaultTreatmentModal = this.openDefaultTreatmentModal.bind(this)
    this.openTreatmentOptionModal = this.openTreatmentOptionModal.bind(this)
    this.handleSearchTreatment = this.handleSearchTreatment.bind(this)
    this.handleConsentClick = this.handleConsentClick.bind(this)
    this.handleProductSheetClick = this.handleProductSheetClick.bind(this)
    this.getCheckBoxListFilterByTreatType = this.getCheckBoxListFilterByTreatType.bind(
      this,
    )
    this.closeDefaultTreatmentModal = this.closeDefaultTreatmentModal.bind(this)
    this.applyFilterTreatments = this.applyFilterTreatments.bind(this)
    this.getOptionsForNewTreatment = this.getOptionsForNewTreatment.bind(this)
    this.handleNewTreatmentClick = this.handleNewTreatmentClick.bind(this)
    this.loadTreatments = this.loadTreatments.bind(this)
    this.handleConsentUpload = this.handleConsentUpload.bind(this)
    this.handleProductSheetUpload = this.handleProductSheetUpload.bind(this)
    this.handleEditTreatmentClick = this.handleEditTreatmentClick.bind(this)
    this.handleEditTreatClose = this.handleEditTreatClose.bind(this)
    this.handleEditTreatmentSave = this.handleEditTreatmentSave.bind(this)
    this.handleDeleteTreatment = this.handleDeleteTreatment.bind(this)
    this.handleConsentFormUploadToServer = this.handleConsentFormUploadToServer.bind(
      this,
    )
    this.handleConsentDeleteTreatment = this.handleConsentDeleteTreatment.bind(
      this,
    )
    this.displayCancelAppointmentModal = this.displayCancelAppointmentModal.bind(
      this,
    )
    this.revertCancel = this.revertCancel.bind(this)
    this.closeTreatOptionModal = this.closeTreatOptionModal.bind(this)
    this.handleSaveTreatmentOptions = this.handleSaveTreatmentOptions.bind(this)
    this.openEditTreatmentOptionModal = this.openEditTreatmentOptionModal.bind(
      this,
    )
    this.handleClickTreatOption = this.handleClickTreatOption.bind(this)
    this.handleDeleteTreatOption = this.handleDeleteTreatOption.bind(this)
    this.handleProductSheetDeleteTreatment = this.handleProductSheetDeleteTreatment.bind(
      this,
    )
    this.getFooterButtons = this.getFooterButtons.bind(this)
    this.shouldBannerAvailable = this.shouldBannerAvailable.bind(this)
    this.handleBannerClick = this.handleBannerClick.bind(this)
    this.handleClinicChange = this.handleClinicChange.bind(this)
  }

  async componentDidMount() {
    const { actions, formatTasks } = this.props
    const tasks = await getTasks()
    formatTasks(tasks)

    actions.A_GetClinicsList().then(() => {
      const { global } = this.props

      const clinic = global.currentClinicID

      if (clinic) {
        this.loadTreatments(clinic)
      }
    })
  }

  loadTreatments(clinic) {
    this.props.actions.A_FetchClinicTreatmentList(clinic).then(response => {
      this.props.actions.A_SetTreatmentList(response)
      if (!response) {
        this.setState({ isTreatmentListEmpty: true })
      }
      /**
       * If there is any treatment options pricing is missing set the Banner flag to show
       */
      this.shouldBannerAvailable(response)
      this.setState({ filters: this.getCheckBoxListFilterByTreatType() })
    })
  }

  componentDidUpdate() {
    const { global } = this.props
    let clinic = global.currentClinicID

    if (this.props.treatments.isTreatmentUpdated) {
      this.loadTreatments(clinic)
      this.props.actions.A_TreatmentUpdated(false)
    }
  }

  shouldBannerAvailable(response) {
    let BannerKeyLS = JSON.parse(localStorage.getItem('BannerSeen'))
    if (!BannerKeyLS) {
      for (let i = 0; i < response.length - 1; i++) {
        if (response[i].options && response[i].options.length > 0) {
          for (let j = 0; j < response[i].options.length - 1; j++) {
            if (response[i].options[j].levels.length === 0) {
              if (!this.state.isBannerVisible) {
                this.setState({ isBannerVisible: true })
                break
              }
            }
          }
          if (this.state.isBannerVisible) {
            break
          }
        }
      }
    }
  }

  closeTreatOptionModal() {
    func.closeOverlayModal()
    this.modalTimeout = setTimeout(() => {
      this.setState({
        showTreatmentOptionModal: false,
      })
    }, 500)
  }

  handleSaveTreatmentOptions(
    event,
    name,
    pricing,
    matrics,
    isDefaultTreatOption,
    productsList,
    isNewTreatment,
  ) {
    event.preventDefault()

    const { global, treatments } = this.props
    let clinic = global.currentClinicID
    /**
     *  on save click from new or edited treatment options
     */
    let treatmentID = treatments.viewingTreatment.id
    let optionID = treatments.viewingTreatOption.id
    let epharmProductsList = []

    if (productsList && productsList.length > 0) {
      if (isNewTreatment) {
        epharmProductsList = productsList.map(ele => {
          return { product_id: ele.id }
        })
      } else {
        epharmProductsList = productsList.map(ele => {
          let epharmId = ele.product_id ? ele.product_id : ele.id ? ele.id : ''
          return { product_id: epharmId }
        })
      }
    }
    let data
    if (isDefaultTreatOption) {
      data = {
        levels: pricing,
      }
    } else {
      if (epharmProductsList && epharmProductsList.length > 0) {
        data = {
          title: name,
          levels: pricing,
          questions: matrics,
          products: epharmProductsList,
        }
      } else {
        data = { title: name, levels: pricing, questions: matrics }
      }
    }
    if (this.state.newTreatment) {
      this.props.actions
        .A_PostNewTreatmentOption(clinic, treatmentID, data)
        .then(() => {
          this.props.actions.A_TreatmentUpdated(true)
          this.setState({
            notificationMsg: 'Treatment Option created successfully',
            isNotificationvisible: true,
          })
        })
    } else {
      this.props.actions
        .A_PostEditTreatmentOption(clinic, treatmentID, optionID, data)
        .then(() => {
          this.props.actions.A_TreatmentUpdated(true)
          this.setState({
            notificationMsg: 'Treatment Option edited successfully',
            isNotificationvisible: true,
          })
        })
    }
    this.closeTreatOptionModal()
    this.setState({ isNotificationvisible: false })
  }

  componentWillUnmount() {
    clearTimeout(this.modalTimeout)
  }

  handleConsentFormUploadToServer() {
    const { global, treatments } = this.props
    let clinic = global.currentClinicID
    let treatment = treatments.editTreatment
    let consentData = treatments.consentForm
    let productSheetData = treatments.productSheet
    if (consentData) {
      let consent = new FormData()
      consent.append('consent', consentData)
      this.props.actions
        .A_PostNewTreatmentConsent(clinic, treatment.id, consent)
        .then(() => {
          this.props.actions.A_TreatmentUpdated(true)
          this.handleEditTreatClose()
        })
    }
    if (productSheetData) {
      let productSheet = new FormData()
      productSheet.append('product_sheet', productSheetData)
      this.props.actions
        .A_PostNewTreatmentProductSheet(clinic, treatment.id, productSheet)
        .then(() => {
          this.props.actions.A_TreatmentUpdated(true)
          this.handleEditTreatClose()
        })
    }
  }

  handleConsentUpload(file) {
    if (file.target) {
      this.props.actions.A_UploadConsentForm(file.target.files[0])
      let size = getFileSizefromEvent(file)
      this.setState({
        updatedConsentName: file.target.files[0].name,
        updatedConsentSize: size,
      })
    } else {
      this.props.actions.A_UploadConsentForm(file)
    }
  }

  handleProductSheetUpload(file) {
    if (file.target) {
      this.props.actions.A_SetProductSheet(file.target.files[0])
      let size = getFileSizefromEvent(file)
      this.setState({
        updatedproductSheetName: file.target.files[0].name,
        updatedproductSheetSize: size,
      })
    } else {
      this.props.actions.A_SetProductSheet(file)
    }
  }

  handleDeleteTreatment() {
    const { actions, global } = this.props
    let clinic = global.currentClinicID
    actions
      .A_DeleteTreatment(clinic, this.props.treatments.editTreatment.id)
      .then(() => {
        this.setState({
          notificationMsg: 'Treatment deleted successfully',
          isNotificationvisible: true,
        })

        let editTreatment = this.state.editTreatment
        editTreatment.length = 0
        this.props.actions.A_SetEditTreatment(editTreatment)
        this.props.actions.A_TreatmentUpdated(true)
      })
    this.setState({ isNotificationvisible: false })
  }

  handleConsentDeleteTreatment() {
    const { actions, global, treatments } = this.props
    let clinic = global.currentClinicID
    actions
      .A_DeleteConsentTreatment(clinic, treatments.editTreatment.id)
      .then(() => {
        let editTreatment = this.state.editTreatment
        editTreatment.length = 0
        actions.A_SetEditTreatment(editTreatment)
        actions.A_TreatmentUpdated(true)
      })
  }

  handleProductSheetDeleteTreatment() {
    const { actions, global, treatments } = this.props
    let clinic = global.currentClinicID
    actions
      .A_DeleteProductSheetTreatment(clinic, treatments.editTreatment.id)
      .then(() => {
        let editTreatment = this.state.editTreatment
        editTreatment.length = 0
        actions.A_SetEditTreatment(editTreatment)
        actions.A_TreatmentUpdated(true)
      })
  }

  handleEditTreatClose() {
    const { actions } = this.props

    let editTreatment = this.state.editTreatment
    editTreatment.length = 0
    actions.A_SetEditTreatment(editTreatment)
    actions.A_UploadConsentForm(null)
    this.setState({ editTreatment: editTreatment, updatedConsentName: null })
  }

  handleEditTreatmentSave(name, type) {
    const { actions, treatments, global } = this.props
    let clinic = global.currentClinicID

    this.setState({ loader: true })
    let treatment = treatments.editTreatment
    let treatmentDetails = {
      title: name,
      treatment_type: { id: Number(type) },
    }

    let consentData = treatments.consentForm
    let productSheetData = treatments.productSheet

    actions
      .A_PostEditTreatment(clinic, treatment.id, treatmentDetails)
      .then(() => {
        if (!consentData) {
          this.props.actions.A_TreatmentUpdated(true)
          this.handleEditTreatClose()
          this.setState({ loader: false })
        }

        if (consentData) {
          let consent = new FormData()
          consent.append('consent', consentData)
          this.props.actions
            .A_PostNewTreatmentConsent(clinic, treatment.id, consent)
            .then(() => {
              actions.A_TreatmentUpdated(true)
              this.handleEditTreatClose()
              this.setState({ loader: false })
            })
        }
        if (productSheetData) {
          let productSheet = new FormData()
          productSheet.append('product_sheet', productSheetData)
          actions
            .A_PostNewTreatmentProductSheet(clinic, treatment.id, productSheet)
            .then(() => {
              actions.A_TreatmentUpdated(true)
              this.setState({ loader: false })
            })
        }
        this.setState({
          notificationMsg: 'Treatment edited successfully',
          isNotificationvisible: true,
        })
      })
    this.setState({ isNotificationvisible: false })
  }

  handleNewTreatmentClick(treatmentName, treatmentTypeId) {
    const { actions, treatments, global } = this.props
    let clinic = global.currentClinicID
    let consentData = treatments.consentForm
    let productSheetData = treatments.productSheet
    let treatmentDetails = {
      title: treatmentName,
      treatment_type: { id: Number(treatmentTypeId) },
    }

    actions.A_PostNewTreatment(treatmentDetails, clinic).then(response => {
      this.setState({
        notificationMsg: 'Treatment created successfully',
        isNotificationvisible: true,
      })
      if (consentData) {
        /**
         * if consent is present, upload it by calling another api
         */
        let consent = new FormData()
        consent.append('consent', consentData)
        actions
          .A_PostNewTreatmentConsent(clinic, response.data.id, consent)
          .then(() => {
            actions.A_TreatmentUpdated(true)
          })
      }
      if (productSheetData) {
        /**
         * if product sheet is present, upload it by calling another api
         */
        let productSheet = new FormData()
        productSheet.append('product_sheet', productSheetData)
        actions
          .A_PostNewTreatmentProductSheet(
            clinic,
            response.data.id,
            productSheet,
          )
          .then(() => {
            actions.A_TreatmentUpdated(true)
          })
      } else {
        /**
         * If there's no consent directly update the view
         */
        actions.A_TreatmentUpdated(true)
      }
      this.setState({ showDefaultTreatmentModal: false })
    })
    this.setState({ isNotificationvisible: false })
  }

  handleClickTreatOption(treatOption, currentTreatment) {
    this.props.actions.A_SetViewingTreatment(currentTreatment)
    this.props.actions.A_SetViewingTreatOption(treatOption)
    this.openEditTreatmentOptionModal(treatOption)
  }

  openEditTreatmentOptionModal(treatOption) {
    this.setState({
      isDefaultTreat: treatOption.locked,
      showTreatmentOptionModal: true,
      newTreatment: false,
    })
    func.showModal('treatmentOptionModal')
  }

  handleEditTreatmentClick(treatment) {
    let treatmentArray = [treatment]
    this.props.actions.A_SetEditTreatment(treatment)
    this.setState({ editTreatment: treatmentArray })
  }

  getOptionsForNewTreatment() {
    let options = this.props.treatments.treatmentTypes.map(treat => {
      return {
        id: treat.id,
        title: treat.label,
        isDisabled: false,
        value: Number(treat.id),
      }
    })
    options.unshift({
      title: 'Please select...',
      id: -1,
      isDisabled: true,
      value: -1,
    })
    return options
  }

  applyFilterTreatments(enteredValue) {
    /**
     * Entered value result validation
     */
    if (enteredValue) {
      let results = this.props.treatments.backupTreatments.filter(treat => {
        let treatTitle = treat.title.toLowerCase()
        let type = this.props.treatments.treatmentTypes.filter(treatType => {
          return (
            treat.treatment_type.title === treatType.label && treatType.checked
          )
        })
        return treatTitle.indexOf(enteredValue) > -1 && type.length > 0
      })
      if (results.length === 0) {
        noMatchValue = enteredValue
      }
      return results
    } else {
      /**
       * Check box checked and unchecked validation with search input
       */
      let checkboxResults = this.props.treatments.backupTreatments.filter(
        treat => {
          let type = this.props.treatments.treatmentTypes.filter(treatType => {
            return (
              treat.treatment_type.title === treatType.label &&
              treatType.checked
            )
          })
          return type.length > 0
        },
      )
      return checkboxResults
    }
  }

  handleSearchTreatment(event) {
    let searchResults = []
    if (event.target) {
      let enteredValue = event.target.value
      enteredValue = enteredValue.toLowerCase()
      searchResults = this.applyFilterTreatments(enteredValue)
      this.props.actions.A_SetFilteredTreatmentList(searchResults)
    } else {
      this.props.actions.A_SetTreatTypes(event)
      let searchBoxString = document.getElementById('search1')
      searchResults = this.applyFilterTreatments(searchBoxString.value)
      this.props.actions.A_SetFilteredTreatmentList(searchResults)
    }
  }

  openInNewTab = url => {
    window.open(url, '_blank')
  }

  handleConsentClick(treat) {
    if (treat.signed_consent) {
      this.openInNewTab(treat.signed_consent)
    } else {
      alert('Consent form is null')
    }
  }

  handleProductSheetClick(treat) {
    if (treat.signed_product_sheet) {
      this.openInNewTab(treat.signed_product_sheet)
    } else {
      alert('product sheet is null')
    }
  }

  getUniqueTreatTypes = (arr, comp) => {
    //store the comparison  values in array
    const unique = arr
      .map(e => e[comp])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & return unique objects
      .filter(e => arr[e])
      .map(e => arr[e])

    return unique
  }

  getCheckBoxListFilterByTreatType() {
    let checkboxList = []
    if (
      this.props.treatments.treatmentTypes &&
      this.props.treatments.treatmentTypes.length === 0
    ) {
      let treatments = this.props.treatments.backupTreatments
      let allTreatmentTypes = []

      for (let k = 0; k < treatments.length; k++) {
        allTreatmentTypes.push({
          id: treatments[k].treatment_type.id,
          treatment_type: treatments[k].treatment_type.title,
        })
      }

      let dupesRemoved = this.getUniqueTreatTypes(
        allTreatmentTypes,
        'treatment_type',
      )
      for (let i = 0; i < dupesRemoved.length; i++) {
        checkboxList.push({
          id: dupesRemoved[i].id,
          label: dupesRemoved[i].treatment_type,
          classContainer: 'radioInput',
          name: 'filter1',
          checked: true,
        })
      }
      this.props.actions.A_SetTreatTypes(checkboxList)
    } else {
      checkboxList = this.props.treatments.treatmentTypes
      // --- if checkbox list is edited, then get the filtered items based on the checkboxes checked.
      this.handleSearchTreatment(checkboxList)
    }

    return checkboxList
  }

  openDefaultTreatmentModal() {
    this.setState({ showDefaultTreatmentModal: true })
  }

  closeDefaultTreatmentModal() {
    this.setState({ showDefaultTreatmentModal: false })
  }

  openTreatmentOptionModal(treatment) {
    this.setState({
      showTreatmentOptionModal: true,
      allowEdit: false,
      newTreatment: true,
    })
    this.props.actions.A_SetViewingTreatment(treatment)
    func.showModal('treatmentOptionModal')
  }

  displayCancelAppointmentModal = () => {
    document.getElementById('confirmCancelAlert').classList.remove('hidden')
    document
      .getElementById('confirmCancelAlert')
      .classList.remove('fadeOutUnravelAnimate--top')
    document
      .getElementById('confirmCancelAlert')
      .classList.add('fadeInUnravelAnimate--top')
  }

  revertCancel = () => {
    document
      .getElementById('confirmCancelAlert')
      .classList.remove('fadeInUnravelAnimate--top')
    document
      .getElementById('confirmCancelAlert')
      .classList.add('fadeOutUnravelAnimate--top')
  }

  handleDeleteTreatOption() {
    const { actions, treatments, global } = this.props
    let clinic = global.currentClinicID

    let viewingTreatment = treatments.viewingTreatment.id
    let viewingOptionID = treatments.viewingTreatOption.id
    actions
      .A_DeleteTreatmentOption(clinic, viewingTreatment, viewingOptionID)
      .then(() => {
        actions.A_TreatmentUpdated(true)
        this.closeTreatOptionModal()
        this.setState({
          notificationMsg: 'Treatment Option deleted successfully',
          isNotificationvisible: true,
        })
      })
    this.setState({ isNotificationvisible: false })
  }

  getFooterButtons() {
    const { global } = this.props
    let clinic = global.currentClinicID
    let buttonList = []
    let toBeDisabled = clinic ? false : true
    buttonList.push(
      <MainButton
        key="NewTreatmentButton"
        type="button"
        label="New custom treatment"
        styleType="secondary"
        size="medium"
        customClasses="button--wide"
        isDisabled={toBeDisabled}
        onClick={this.openDefaultTreatmentModal}
      />,
    )
    return buttonList
  }

  /**
   * This method is used to show/hide the banner in treatment library showing missing treatment options
   */
  handleBannerClick() {
    this.setState({ isBannerVisible: false }, () => {
      localStorage.setItem('BannerSeen', true)
    })
  }

  handleClinicChange(e) {
    const { actions } = this.props
    const clinic = Number(e.target.value)
    actions.A_SetViewingClinic(clinic)
    this.loadTreatments(clinic)
  }

  render() {
    const { global, activeTasks, hasCompletedAllTasks } = this.props

    const clinics = global.filterClinics
    const clinic = global.currentClinicID

    let selectOptions = this.getOptionsForNewTreatment()
    let leftButtons = [
      {
        type: 'button',
        style: 'destructive',
        label: 'Delete Treatment',
        size: 'small',
        events: {
          onClick: this.displayCancelAppointmentModal,
        },
      },
    ]
    let rightButtonsView = [
      {
        type: 'button',
        style: 'tertiary',
        label: 'Cancel',
        size: 'small',
        events: {
          onClick: this.handleEditTreatClose,
        },
      },
      {
        type: 'button',
        style: 'secondary',
        label: 'Save changes',
        size: 'small',
      },
    ]
    return (
      <React.Fragment>
        <main className="main">
          <>
            {!hasCompletedAllTasks ? (
              <Placeholder activeTasks={activeTasks} />
            ) : null}
          </>
          {isTutorialFeatureEnabled ? (
            <TutorialContext.Consumer>
              {({
                toggleTutorial,
                setTutorial,
                tutorialList,
                history,
                activeTutorial,
              }) => (
                <TutorialBtn
                  tutorialList={tutorialList}
                  toggleTutorial={toggleTutorial}
                  setTutorial={setTutorial}
                  activeTutorial={activeTutorial}
                  history={history}
                  bottom={100}
                />
              )}
            </TutorialContext.Consumer>
          ) : null}
          {this.state.notificationMsg && this.state.isNotificationvisible ? (
            <FeedbackNotification
              id="feedbackMessage"
              message={this.state.notificationMsg}
              isWarning={false}
            />
          ) : null}
          <SearchHeader
            filterSet={this.state.filters}
            rightFilterId="filterClinicName"
            rightFilterLabel="Clinic"
            rightFilter={clinics}
            rightFilterSelected={clinic}
            rightFilterChange={this.handleClinicChange}
            onChange={this.handleSearchTreatment}
            onFilterChange={this.handleSearchTreatment}
          />
          {this.state.isBannerVisible && (
            <FeedbackStrap
              hasMargin={false}
              message="Treatments without pricing options will not appear in consultations"
              buttonText="Don't show this again"
              onClick={this.handleBannerClick}
            />
          )}
          <section className="main__inner">
            <article className="col__12-7 clinicForm clinicForm--feedbackStrap clinicForm--wide">
              {this.props.treatments.treatmentsList &&
              this.props.treatments.treatmentsList.length > 0 ? (
                this.props.treatments.treatmentsList.map(treatment => {
                  return (
                    <TreatmentOverviewItem
                      key={`treatment_${treatment.id}`}
                      openNewTreat={this.openDefaultTreatmentModal}
                      editTreatmentOptions={this.openEditTreatmentOptionModal}
                      handleClickTreatOption={this.handleClickTreatOption}
                      openNewTreatOption={this.openTreatmentOptionModal}
                      consentFormClick={this.handleConsentClick}
                      productSheetClick={this.handleProductSheetClick}
                      headerText={treatment.title}
                      allowEdit={treatment.locked}
                      consentFormTitle="Consent form"
                      productSheetTitle="Post Treatment Instructions"
                      treatmentOptions={treatment.options}
                      treatment={treatment}
                      handleEditTreatmentClick={this.handleEditTreatmentClick}
                    />
                  )
                })
              ) : this.state.isTreatmentListEmpty ? (
                <SearchResultMessage
                  message="No treatments have been added to this clinic"
                  searchValue={noMatchValue}
                />
              ) : (
                <SearchResultMessage
                  message="No treatments match your search criteria"
                  searchValue={noMatchValue}
                />
              )}
            </article>
            <aside className="clinicControls">{this.getFooterButtons()}</aside>
          </section>

          {this.state.editTreatment.length > 0 ? (
            <TreatmentModal
              title="Edit treatment"
              footerButtons={rightButtonsView}
              leftButtons={
                this.state.editTreatment[0].locked ? [] : leftButtons
              }
              isCustom={!this.state.editTreatment[0].locked}
              isEdit={!this.state.editTreatment[0].locked}
              handleClose={this.handleEditTreatClose}
              options={selectOptions}
              handleSaveClick={
                this.state.editTreatment[0].locked
                  ? this.handleConsentFormUploadToServer
                  : this.handleEditTreatmentSave
              }
              onFileChange={this.handleConsentUpload}
              onProductSheetFileChange={this.handleProductSheetUpload}
              consentFormTitle={
                this.state.updatedConsentName
                  ? this.state.updatedConsentName
                  : this.state.editTreatment[0].consent
                  ? getFileName(this.state.editTreatment[0].consent)
                  : undefined
              }
              consentFormSize={
                this.state.updatedConsentSize
                  ? this.state.updatedConsentSize
                  : this.state.editTreatment[0].consent
                  ? ''
                  : undefined
              }
              productSheetTitle={
                this.state.updatedproductSheetName
                  ? this.state.updatedproductSheetName
                  : this.state.editTreatment[0].product_sheet
                  ? getFileName(this.state.editTreatment[0].product_sheet)
                  : undefined
              }
              productSheetSizeSize={
                this.state.updatedproductSheetSize
                  ? this.state.updatedproductSheetSize
                  : this.state.editTreatment[0].product_sheet
                  ? ''
                  : undefined
              }
              consentFormClick={() => {
                this.handleConsentClick(this.state.editTreatment[0])
              }}
              productSheetClick={() => {
                this.handleProductSheetClick(this.state.editTreatment[0])
              }}
              selectedTreatmentType={
                this.state.editTreatment[0].treatment_type.id || -1
              }
              selectedTreatmentName={this.state.editTreatment[0].title || ''}
              tooltipModal={this.displayCancelAppointmentModal}
              handleDelete={this.handleDeleteTreatment}
              handleCancel={this.revertCancel}
              toolTipHeader="Delete Treatment?"
              toolTipBody="You are about to permanently remove this treatment from clever clinic."
              defaultConsent={this.state.editTreatment[0].default_consent}
              defaultProductSheet={
                this.state.editTreatment[0].default_product_sheet
              }
              handleConsentDeleteTreatment={this.handleConsentDeleteTreatment}
              handleProductSheetDeleteTreatment={
                this.handleProductSheetDeleteTreatment
              }
              loader={this.state.loader}
            />
          ) : null}

          {this.state.showTreatmentOptionModal ? (
            <CreateCustomTreatOption
              handleClose={this.closeTreatOptionModal}
              treatmentTitle={this.state.treatment}
              fileName={this.state.consentTitle}
              newTreat={this.state.newTreatment}
              isDefaultTreatment={
                this.state.newTreatment
                  ? this.state.allowEdit
                  : this.state.isDefaultTreat
              }
              saveTreatmentOptions={this.handleSaveTreatmentOptions}
              pricingArray={
                this.state.newTreatment
                  ? [{ id: 0, price: '' }]
                  : this.props.treatments.viewingTreatOption.levels
              }
              matricsArray={
                this.state.newTreatment
                  ? [{ id: 0, title: '', placeholder: '' }]
                  : this.props.treatments.viewingTreatOption.questions
              }
              productsArray={
                this.state.newTreatment
                  ? []
                  : this.props.treatments.viewingTreatOption.products
              }
              viewingTreatment={
                this.state.newTreatment
                  ? { id: 0 }
                  : this.props.treatments.viewingTreatment
              }
              viewingOption={
                this.state.newTreatment
                  ? {}
                  : this.props.treatments.viewingTreatOption
              }
              tooltipModal={this.displayCancelAppointmentModal}
              handleDelete={this.handleDeleteTreatOption}
              handleCancel={this.revertCancel}
              toolTipHeader="Remove treatment option from consultation for your clinic group?"
              toolTipBody="This action cannot be undone"
              handleEpharmaSearch={this.handleEpharmaSearch}
              handleClickLinkEpharm={this.handleClickLinkEpharm}
              LinkEpharmProducts={
                this.state.newTreatment ? [] : this.state.tobeLinkedProducts
              }
              hasLinkedProducts={
                this.props.treatments.viewingTreatOption.products &&
                this.props.treatments.viewingTreatOption.products.length > 0 &&
                !this.state.newTreatment
              }
              searchProductResults={
                this.state.searchResultEpharm &&
                this.state.searchResultEpharm.length > 0 &&
                !this.state.newTreatment
                  ? this.state.searchResultEpharm
                  : undefined
              }
              footerButtons={[
                {
                  type: 'button',
                  style: 'tertiary',
                  label: 'Cancel',
                  size: 'small',
                },
                {
                  type: 'submit',
                  style: 'secondary',
                  label: 'Save changes',
                  size: 'small',
                },
              ]}
              leftFooterButton={
                this.props.treatments.viewingTreatOption.locked ||
                this.state.newTreatment
                  ? undefined
                  : [
                      {
                        type: 'button',
                        style: 'tertiary',
                        label: 'Delete Treatment Option',
                        size: 'small',
                        events: {
                          onClick: this.displayCancelAppointmentModal,
                        },
                      },
                    ]
              }
            />
          ) : null}

          {this.state.showDefaultTreatmentModal ? (
            <TreatmentModal
              title="New treatment"
              footerButtons={[
                {
                  type: 'button',
                  style: 'tertiary',
                  label: 'Cancel',
                  size: 'small',
                },
                {
                  type: 'button',
                  style: 'secondary',
                  label: 'Create treatment',
                  size: 'small',
                },
              ]}
              isCustom={true}
              isEdit={false}
              handleClose={this.closeDefaultTreatmentModal}
              options={selectOptions}
              handleSaveClick={this.handleNewTreatmentClick}
              onFileChange={this.handleConsentUpload}
              onProductSheetFileChange={this.handleProductSheetUpload}
            />
          ) : null}
        </main>
      </React.Fragment>
    )
  }
}

TreatmentLibrary.defaultProps = {}

TreatmentLibrary.propTypes = {
  actions: PropTypes.object.isRequired,
  treatments: PropTypes.object.isRequired,
  global: PropTypes.object.isRequired,
  activeTasks: PropTypes.array.isRequired,
  formatTasks: PropTypes.func.isRequired,
  hasCompletedAllTasks: PropTypes.bool.isRequired,
}

const mapStateToProps = state => {
  return {
    treatments: state.treatments,
    global: state.global,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_FetchClinicTreatmentList,
        A_SetTreatmentList,
        A_SetFilteredTreatmentList,
        A_SetTreatTypes,
        A_PostNewTreatment,
        A_TreatmentUpdated,
        A_PostNewTreatmentConsent,
        A_UploadConsentForm,
        A_PostEditTreatment,
        A_SetEditTreatment,
        A_DeleteTreatment,
        A_DeleteConsentTreatment,
        A_DeleteProductSheetTreatment,
        A_PostNewTreatmentOption,
        A_SetViewingTreatment,
        A_SetViewingTreatOption,
        A_PostEditTreatmentOption,
        A_DeleteTreatmentOption,
        A_SetProductSheet,
        A_PostNewTreatmentProductSheet,
        A_SetViewingClinic,
        A_GetEpharmProdList,
        A_GetClinicsList,
      },
      dispatch,
    ),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TreatmentLibrary),
)
