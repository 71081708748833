import * as auth from '../../services/OnBoarding/auth'
import types from '../../actions/types'
import { getInitials, getFullName } from '../../utilities/ReusableFunctions'

var initialState = {
  avatar: '',
  userName: '',
  initials: '',
}
export default (state = initialState, action) => {
  switch (action.type) {
    case types.ACCOUNT_SETUP.OK: {
      auth.setUser({ token: auth.getToken(), user: action.accountDetails })
      return {
        ...state,
        avatar: action.accountDetails.signed_avatar,
        initials: getInitials(action.accountDetails),
        userName: getFullName(action.accountDetails),
      }
    }
    case types.ACCOUNT_SETUP.FAIL:
      return {
        ...state,
        noError: false,
      }
    case types.GET_USER_DETAILS.OK:
      auth.setUser({ token: auth.getToken(), user: action.response })
      return {
        ...state,
      }
    default:
      return state
  }
}
