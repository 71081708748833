import React from 'react'
//import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
//import ModalHeader from '../Header'

const ModalRightSide = ({
  children,
  toggleModal,
  messageActive,
  toggleMessage,
  toggleNotification,
}) => {
  return (
    <div>
      <article
        id="notificationPanel"
        className={`modal__rightSide ${
          messageActive ? `modal__rightSide--overflow` : ``
        }`}
      >
        <section className="modal__header">
          <h4
            role="presentation"
            className={`buttonTransparent type_header_button ${
              !messageActive ? 'type_header_button--selected' : ''
            }`}
            onClick={toggleNotification}
            onKeyDown={toggleNotification}
          >
            Notifications
          </h4>
          <h4
            role="presentation"
            className={`buttonTransparent type_header_button ${
              messageActive ? 'type_header_button--selected' : ''
            }`}
            onClick={toggleMessage}
            onKeyDown={toggleMessage}
          >
            Messages
          </h4>
          <span
            role="button"
            tabIndex="0"
            className="icon-cross buttonCircle modal__closeButton modal__closeAction"
            onClick={toggleModal}
            onKeyDown={toggleModal}
          />
        </section>
        {children}
      </article>
      <section
        role="presentation"
        id="overlayNotification"
        className="overlayNotifications"
        onClick={toggleModal}
        onKeyDown={toggleModal}
      />
    </div>
  )
}

ModalRightSide.defaultProps = {}

ModalRightSide.propTypes = {
  children: PropTypes.array.isRequired,
  toggleModal: PropTypes.func.isRequired,
  messageActive: PropTypes.bool.isRequired,
  toggleNotification: PropTypes.func.isRequired,
  toggleMessage: PropTypes.func.isRequired,
}

export default ModalRightSide
