import * as clinicSummary from '../services/Clinic_Setup/ClinicSummaryPages/ClinicSummaryPageService'
import * as clinic from '../services/ClinicAppointments/Clinic_Setup/ClinicDashboard_01Service'
import { ignoreGlobalError } from '../utilities/ReusableFunctions'
import types from './types'

export const A_FetchClinicDetails = id => dispatch =>
  clinicSummary
    .getclinicDetails(id)
    .then(response => {
      dispatch({ type: types.FETCH_CLINIC_DETAILS.OK, data: response })
      dispatch({ type: types.VIEWING_CLINIC, clinic: id })
      return response
    })
    .catch(error => {
      dispatch({ type: types.FETCH_CLINIC_DETAILS.FAIL, error })
      return Promise.reject(error)
    })

export const A_storeUpdatedKey = () => ({ type: types.UPDATE_KEY_VALUE })

export const A_PatchUpdatedClinicDetails = data => dispatch =>
  clinicSummary
    .patchUpdatedClinicDetails(data)
    .then(response => {
      dispatch({ type: types.PATCH_UPDATED_CLINICINFO.OK, data: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.PATCH_UPDATED_CLINICINFO.FAIL, error })
      return Promise.reject(error)
    })

export const A_GetAccessURL_QB = (clinicID, callback) => dispatch =>
  clinicSummary
    .postcallbackurlforQB(clinicID, callback)
    .then(response => {
      dispatch({ type: types.POST_CALLBACK_URL_QB.OK, response })
      dispatch({ type: types.VIEWING_CLINIC, clinic: clinicID })
      return response
    })
    .catch(error => {
      dispatch({ type: types.POST_CALLBACK_URL_QB.FAIL, error })
      return Promise.reject(error)
    })

export const A_GetAccessTOKEN_QB = (clinicID, data) => dispatch =>
  clinicSummary
    .post_code_companyID_forQB(clinicID, data)
    .then(response => {
      dispatch({ type: types.POST_CALLBACK_URL_QB.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.POST_CALLBACK_URL_QB.FAIL, error })
      return Promise.reject(error)
    })

export const A_Delete_QB = clinicID => dispatch =>
  clinicSummary
    .delete_accessToken_forQB(clinicID)
    .then(response => {
      dispatch({ type: types.DELETE_QB.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.DELETE_QB.FAIL, error })
      return Promise.reject(error)
    })

export const A_GetTaxCodes_Vat_QB = clinicID => dispatch =>
  clinicSummary
    .getQBtaxcodes(clinicID)
    .then(response => {
      dispatch({ type: types.GET_TAXCODES_QB.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_TAXCODES_QB.FAIL, error })
      return Promise.reject(error)
    })

export const A_GetDepartment_QB = clinicID => dispatch =>
  clinicSummary
    .getQBdepartment(clinicID)
    .then(response => {
      dispatch({ type: types.GET_DEPARTMENT_QB.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_DEPARTMENT_QB.FAIL, error })
      return Promise.reject(error)
    })

export const A_Login_HX = (clinicID, data) => dispatch =>
  clinicSummary
    .postCredentialsHX(clinicID, data)
    .then(response => {
      dispatch({ type: types.LOGIN_HX.OK, response })
      dispatch({ type: types.VIEWING_CLINIC, clinic: clinicID })
      return response
    })
    .catch(error => {
      dispatch({
        type: types.LOGIN_HX.FAIL,
        error,
        ignoreGlobalError: ignoreGlobalError(error),
      })
      return error
    })

export const A_GetClinicsList = () => dispatch =>
  clinic
    .getClinicList()
    .then(response => {
      dispatch({ type: types.FETCH_CLINICLIST.OK, data: response })
      dispatch({ type: types.SET_CLINICS_LIST, clinicsArray: response })
      dispatch({
        type: types.VIEWING_CLINIC,
        clinic: Number(sessionStorage.getItem('currentClinic')),
      })
      return response
    })
    .catch(error => {
      dispatch({ type: types.FETCH_CLINICLIST.FAIL, error })
      return Promise.reject(error)
    })

export const A_GetAllClinicsList = () => {
  return dispatch => {
    return clinic
      .getAllClinicList()
      .then(response => {
        dispatch({ type: types.FETCH_ALL_CLINICLIST.OK, data: response })
        return response
      })
      .catch(error => {
        dispatch({ type: types.FETCH_ALL_CLINICLIST.FAIL, error })
        return Promise.reject(error)
      })
  }
}

export const A_PostClinicDetails = clinicData => dispatch =>
  clinic
    .postClinicData(clinicData)
    .then(response => {
      dispatch({ type: types.CLINIC_DATA_POST.OK, clinicData: response })
      dispatch({ type: types.VIEWING_CLINIC, clinic: response.id })
      return response
    })
    .catch(error => {
      dispatch({
        type: types.CLINIC_DATA_POST.FAIL,
        error,
        ignoreGlobalError: ignoreGlobalError(error),
      })
      return error
    })

export const A_ValidateClinicDetails = clinicDetails => ({
  type: types.VALIDATE_CLINIC_DETAILS_COMPLETE,
  clinicDetails,
})

export const A_UpdatedInvoiceLogo = (clinicID, invoice_header_logo) => () => {
  return clinicSummary
    .patchUpdatedClinicInvoiceDetails(clinicID, invoice_header_logo)
    .then(response => {
      return response
    })
    .catch(error => {
      return Promise.reject(error)
    })
}
