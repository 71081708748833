import React from 'react'
import PropTypes from 'prop-types'
import * as func from '../../../utilities/ReusableFunctions'

const ReportTable = ({
  data,
  idField,
  nodeStructures,
  searchValue, //eslint-disable-line
  onSelect,
  staffResult, //eslint-disable-line
  tableWidth, //eslint-disable-line
  ...props //eslint-disable-line
}) => {
  const getHeaderRows = () => {
    let nodes = nodeStructures.map(function(nodeStructure, index) {
      return (
        <span
          key={index} //eslint-disable-line
          style={{ width: nodeStructure.width + 'px' }}
          className="searchResult__header__title searchResult__header__text form__label"
        >
          {nodeStructure.title}
        </span>
      )
    })
    return nodes
  }

  const getDataRows = () => {
    let rows = data.map(function(item, ind) {
      let nodes = nodeStructures.map(function(nodeStructure, index) {
        let nodeValue = ''
        switch (nodeStructure.type) {
          case 'data':
            nodeValue = nodeStructure.searchField
              ? func.highlightSearchResult(
                  item[nodeStructure.value],
                  searchValue,
                )
              : item[nodeStructure.value]
            break
          case 'func':
            const fieldResult = func[nodeStructure.value](item) //eslint-disable-line
            nodeValue = nodeStructure.searchField
              ? func.highlightSearchResult(fieldResult, searchValue)
              : fieldResult
            break
          default:
            nodeValue = nodeStructure.value
            break
        }
        return (
          <span
            key={index} //eslint-disable-line
            style={{ width: nodeStructure.width + 'px' }}
            className={`searchResult__header__text searchResult__field ${'searchResult__field--' +
              nodeStructure.priority}`}
          >
            {nodeValue}
          </span>
        )
      })
      return (
        <li /* eslint-disable-line */ key={ind} className="searchResult__result">
          <button
            type="button"
            data-patient={item[idField]}
            className={`buttonTransparent searchResult__row searchResults__row--table searchResult__row-selectable searchResult__row--table-selectable
                        ${
                          ind % 2
                            ? 'searchResult__row--even'
                            : 'searchResult__row--odd'
                        }`}
            onClick={onSelect}
          >
            {nodes}
          </button>
        </li>
      )
    })
    return rows
  }

  const getFooterRow = () => {
    let nodes = []
    nodeStructures.forEach(function(nodeStructure, index) {
      if (nodeStructure.footer || nodeStructure.footer === 0) {
        nodes.push(
          <span
            key={index} //eslint-disable-line
            style={{ width: nodeStructure.width + 'px' }}
            className="searchResult__header__title searchResult__header__text form__label noMargin"
          >
            {nodeStructure.footer}
          </span>,
        )
      } else if (nodeStructure.title === 'PATIENT ID') {
        nodes.push(
          <span
            key={index} //eslint-disable-line
            style={{ width: nodeStructure.width + 'px' }}
            className="searchResult__header__title searchResult__header__text form__label noMargin appointment_selector--practitioner"
          >
            {data.length + ' rows'}
          </span>,
        )
      } else {
        nodes.push(
          <span
            key={index} //eslint-disable-line
            style={{ width: nodeStructure.width + 'px' }}
            className="searchResult__header__title searchResult__header__text form__label noMargin"
          />,
        )
      }
    })
    return nodes
  }

  return (
    <React.Fragment>
      <article
        className="searchResult__table searchResult_table--patient reportFilter__table"
        style={{ width: Number(tableWidth) + 20 + 'px' }}
      >
        <div className="searchResult__header searchResult__header--table">
          <div className="searchResult__row searchResults__row--table-header">
            {getHeaderRows()}
          </div>
          <div className="searchResult__header__spacer" />
        </div>
        <div className="searchResults__scroll">
          <ul className="searchResult__results">{getDataRows()}</ul>
        </div>
        <div className="tableRow tableFooter reportTable__footer">
          <div className="searchResult__row searchResults__row--table-footer">
            {getFooterRow()}
          </div>
        </div>
      </article>
    </React.Fragment>
  )
}

ReportTable.defaultProps = {
  data: [],
  idField: 'id',
  nodeStructures: [],
  searchTerm: '',
  onSelect: () => null,
  staffResult: false,
}

ReportTable.propTypes = {
  data: PropTypes.array,
  idField: PropTypes.string,
  nodeStructures: PropTypes.array,
  searchTerm: PropTypes.string,
  onSelect: PropTypes.func,
  staffResult: PropTypes.bool,
}

export default ReportTable
