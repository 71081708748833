import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import RadioGroup from '../../forms/RadioGroup'
import TextInput from '../../forms/Input'
import MainButton from '../../Buttons'
import { getCurrencySymbol } from '../../../utilities/ReusableFunctions'

const DiscountModal = ({
  isDiscountModalOpen,
  closeModal,
  locale,
  discount,
  applyDiscount,
}) => {
  const [type, setType] = useState(0)
  const [value, setValue] = useState('')

  useEffect(() => {
    setType(discount ? discount.type : 0)
    setValue(discount ? `${discount.value}` : '')
  }, [isDiscountModalOpen])

  return (
    isDiscountModalOpen && (
      <article
        id="discountPopup"
        className="tooltipWrapper tooltipWrapper--discount"
      >
        <section className="tooltip--discount">
          <div className="tooltip__pointerStrech">
            <span className="discount__pointer tooltip--error__pointer" />
          </div>
          <RadioGroup
            type="radio"
            items={[
              { id: 0, title: getCurrencySymbol(locale) },
              { id: 1, title: '%' },
            ]}
            defaultSelected={[type]}
            name="Discount"
            inline={true}
            size="small"
            handleDiscountType={({ currentTarget: { value } }) => {
              setType(parseInt(value))
            }}
          />
          <TextInput
            type="number"
            id="Discount-input"
            noMargin={true}
            placeholder=""
            label="Discount"
            value={value}
            handleOnChange={({ currentTarget: { value } }) => {
              setValue(parseFloat(value))
            }}
          />
          <div className="modal__popup--footer">
            <MainButton
              type="button"
              label="Cancel"
              styleType="tertiary"
              margin="right"
              size="small"
              clickHandler={closeModal}
            />
            <MainButton
              type="button"
              label="Save"
              styleType="secondary"
              leftAuto={true}
              size="small"
              clickHandler={() => {
                applyDiscount({ type, value })
                closeModal()
              }}
            />
          </div>
        </section>
      </article>
    )
  )
}

DiscountModal.defaultProps = {
  isDiscountModalOpen: false,
  discount: null,
  applyDiscount: () => null,
}

DiscountModal.propTypes = {
  isDiscountModalOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  discount: PropTypes.object,
  applyDiscount: PropTypes.func,
}

export default DiscountModal
