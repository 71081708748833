import types from '../../actions/types'

var initialState = {
  clinician: null,
  timeSlots: [],
}
export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CLINICIAN_DETAILS.OK:
      return {
        ...state,
        clinician: action.clinicianDetails,
      }

    case types.SET_TIME_SLOT:
      return {
        ...state,
        timeSlots: action.timeArray,
      }

    default:
      return state
  }
}
