import axios from 'axios'
import * as auth from './OnBoarding/auth'
import * as url from '../api_urls'
import * as Sentry from '@sentry/browser'

const logError = (method, endpoint, error) => {
  let status = 0
  if (error !== undefined && error.response !== undefined) {
    status = error.response.status
  }
  if (status !== 400) {
    Sentry.captureException(
      new Error(method + ' - ' + endpoint + ' (' + status + ')'),
      {
        tags: {},
      },
    )
  }

  if (status === 401) {
    localStorage.clear()
    sessionStorage.clear()
    window.location.reload()
  }

  if (status === 0) {
    return Promise.reject({ status: status, data: undefined })
  }
  return Promise.reject(error.response)
}

const authHeaders = () => {
  const token = auth.getToken()

  let headers = {}

  if (token !== null && token !== 'TOKEN') {
    headers['Authorization'] = `token ${token}`
  }

  return headers
}
// const imageHeaders = () => {
//     return {
//         contentType: multipart / form - data
//     }
// }
//* All post2, get2, patch2 api call are for positing/getting/updating multipart form-data (Content-Type)//
export const get = (endpoint, config = null) => {
  return axios({
    url: url.SERVER + endpoint,
    method: 'get',
    headers: authHeaders(),
    ...config,
  })
    .then(response => {
      return response
    })
    .catch(error => {
      let promise = logError('GET', endpoint, error)

      if (promise !== null) {
        return promise
      }
    })
}
export const getAuth = endpoint => {
  return axios({
    url: endpoint,
    method: 'get',
    headers: authHeaders(),
  })
    .then(response => {
      return response
    })
    .catch(error => {
      let promise = logError('GET', endpoint, error)

      if (promise !== null) {
        return promise
      }
    })
}
export const getNoAuth = endpoint => {
  return axios({
    url: endpoint,
    method: 'get',
  })
    .then(response => {
      return response
    })
    .catch(error => {
      let promise = logError('GET (no auth)', endpoint, error)

      if (promise !== null) {
        return promise
      }
    })
}

export const post = (endpoint, data) => {
  return axios({
    url: url.SERVER + endpoint,
    method: 'post',
    headers: authHeaders(),
    responseType: 'json',
    data,
  })
    .then(response => {
      return response
    })
    .catch(error => {
      let promise = logError('POST', endpoint, error)

      if (promise !== null) {
        return promise
      }
    })
}

export const post2 = (endpoint, data) => {
  return axios({
    url: url.SERVER + endpoint,
    method: 'post',
    headers: {
      ...authHeaders(),
      'Content-Type': 'multipart/form-data',
    },
    responseType: 'json',
    data,
  })
    .then(response => {
      return response
    })
    .catch(error => {
      let promise = logError('POST', endpoint, error)

      if (promise !== null) {
        return promise
      }
    })
}

export const patch = (endpoint, data) => {
  return axios({
    url: url.SERVER + endpoint,
    method: 'patch',
    headers: authHeaders(),
    responseType: 'json',
    data,
  })
    .then(response => {
      return response
    })
    .catch(error => {
      let promise = logError('PATCH', endpoint, error)

      if (promise !== null) {
        return promise
      }
    })
}

export const patch2 = (endpoint, data) => {
  return axios({
    url: url.SERVER + endpoint,
    method: 'patch',
    headers: {
      ...authHeaders(),
      'Content-Type': 'multipart/form-data',
    },
    responseType: 'json',
    data,
  })
    .then(response => {
      return response
    })
    .catch(error => {
      let promise = logError('PATCH', endpoint, error)

      if (promise !== null) {
        return promise
      }
    })
}

export const remove = (endpoint, data) => {
  return axios({
    url: url.SERVER + endpoint,
    method: 'delete',
    headers: authHeaders(),
    responseType: 'json',
    data,
  })
    .then(response => {
      return response
    })
    .catch(error => {
      let promise = logError('DELETE', endpoint, error)

      if (promise !== null) {
        return promise
      }
    })
}
