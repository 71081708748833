import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import Appointment from './Appointment'
import PersonalMeeting from './PersonalMeeting'

import { A_PostNewClinicNotes } from '../../../../../actions'

import { appointmentReasonTitles } from '../../../../../utilities/ReusableObjects'

export default function View({
  appointmentDetails,
  appointmentAlerts,
  appointmentPurpose,
  timezone,
}) {
  const { APPOINTMENT } = appointmentReasonTitles
  const dispatch = useDispatch()
  const [note, setNote] = useState('')
  const { id } = appointmentDetails

  const addNote = note => {
    dispatch(A_PostNewClinicNotes({ id, text: note }))
    setNote('')
  }

  const noteHandlerProps = {
    note,
    notes: appointmentDetails.notes,
    setNote,
    addNote,
  }

  return (
    <>
      {appointmentPurpose === APPOINTMENT ? (
        <Appointment
          appointmentDetails={appointmentDetails}
          appointmentAlerts={appointmentAlerts}
          timezone={timezone}
          {...noteHandlerProps}
        />
      ) : (
        <PersonalMeeting timezone={timezone} {...noteHandlerProps} />
      )}
    </>
  )
}

View.propTypes = {
  appointmentDetails: PropTypes.object.isRequired,
  appointmentAlerts: PropTypes.array.isRequired,
  appointmentPurpose: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
}
