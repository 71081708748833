import types from '../actions/types'
import { REDIRECT_TO_V2_APP } from '../api_urls'
import {
  filteredClinicians,
  getFullName,
  getCurrencySymbol,
  cliniciansFilterList,
} from '../utilities/ReusableFunctions'
import { ClinicLocaleTypes } from '../utilities/ReusableObjects'

let initialState = {
  currentClinicID: Number(sessionStorage.getItem('currentClinic')),
  currentUserID: null,
  clinics: [],
  filterClinics: [],
  currentClinic: {
    timezone: 'Europe/London',
    locale: ClinicLocaleTypes.en_GB,
    equipment: [],
    rooms: [],
    invites: [],
  },
  visibleClinicians: [],
  filterClinicians: [],
  allFilterClinicians: [],
  clinicianIDs: '',
  account_owner: false,
  is_admin: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_CLINICIANS_LIST.OK:
      return {
        ...state,
        allFilterClinicians: cliniciansFilterList(
          action.list.map(clinician => {
            return {
              ...clinician.invite,
              clinician: clinician,
            }
          }),
        ),
      }
    case types.LOGIN.OK: {
      let user = action.user
      let current_clinic = user.current_clinic

      let clinic =
        state.currentClinicID !== null && state.currentClinicID > 0
          ? state.currentClinicID
          : current_clinic
          ? current_clinic.id
          : null

      sessionStorage.setItem('currentClinic', clinic)

      // Force the user back to v2 as their account isnt setup properly!
      if (
        !action.local &&
        (user?.account?.is_onboarding || user?.needs_setup)
      ) {
        window.location.replace(REDIRECT_TO_V2_APP)
      }

      return {
        ...state,
        currentClinicID: clinic,
        currentUserID: user.id,
        account_owner: user.invite.account_owner,
        is_admin: user.invite.is_admin,
      }
    }
    case types.SET_CLINICS_LIST:
      return {
        ...state,
        clinics: action.clinicsArray,
        filterClinics: action.clinicsArray.map(function(clinic) {
          return { id: clinic.id, title: clinic.name, isDisabled: false }
        }),
      }
    case types.GET_BASIC_DETAILS.OK:
      return {
        ...state,
        clinics: state.clinics.map(clinic => {
          if (clinic.id === action.data.id) {
            return action.data
          }
          return clinic
        }),
      }
    case types.VIEWING_CLINIC: {
      if (action.clinic !== null && action.clinic > 0) {
        sessionStorage.setItem('currentClinic', action.clinic)

        const clinics = state.clinics
        const equipmentList = []

        let currentClinicID = action.clinic
        let timezone = state.timezone

        let currentClinic = clinics.find(function(clinic) {
          return clinic.id === currentClinicID
        })

        if (!currentClinic) {
          if (clinics.length > 0) {
            currentClinic = clinics[0]
            sessionStorage.setItem('currentClinic', currentClinic.id)
          }
        }

        let currentUserID = state.currentUserID

        let visibleClinicians = []
        let filterClinicians = []

        if (currentClinic) {
          currentClinicID = currentClinic.id
          timezone = currentClinic.timezone

          let clinicians = filteredClinicians(
            currentClinic.invites,
            currentUserID,
          )

          visibleClinicians = clinicians.map(item => {
            return {
              ...item.clinician,
              default_appointment_length:
                item.default_appointment_length !== null
                  ? item.default_appointment_length
                  : item.clinician.default_appointment_length,
            }
          })

          filterClinicians = visibleClinicians.map(item => {
            return {
              id: item.id,
              title: getFullName(item),
              isDisabled: false,
            }
          })

          currentClinic.rooms.map(({ equipment }) =>
            equipment.map(equipment => equipmentList.push(equipment)),
          )
        } else {
          currentClinic = initialState.currentClinic
        }

        return {
          ...state,
          currentClinicID: currentClinicID,
          currentClinic: {
            ...currentClinic,
            currencySymbol: getCurrencySymbol(currentClinic.locale),
            equipment: equipmentList.map(equipment => ({
              ...equipment,
              title: equipment.name,
            })),
            rooms: currentClinic.rooms.map(room => ({
              ...room,
              title: room.name,
            })),
          },
          visibleClinicians: visibleClinicians,
          filterClinicians: filterClinicians,
          timezone: timezone,
          clinicianIDs: visibleClinicians
            .map(clinician => {
              return clinician.id
            })
            .join(','),
        }
      } else {
        return {
          ...state,
        }
      }
    }
    default:
      return state
  }
}
