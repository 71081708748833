import Promise from 'promise'
import * as api from '../../api'
import * as url from '../../../api_urls'

export const postClinicData = clinicData => {
  return new Promise((resolve, reject) => {
    return api
      .post(url.clinics, clinicData)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getClinicList = () => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.clinics)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getAllClinicList = () => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.clinics + '?all=true')
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getAddress = addressQuery =>
  new Promise((resolve, reject) =>
    api
      .get(`/addresses/autocomplete/?input=${addressQuery}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err.data)),
  )

export const getAddressDetails = placeId =>
  new Promise((resolve, reject) =>
    api
      .get(`/addresses/${placeId}/`)
      .then(res => resolve(res.data))
      .catch(err => reject(err.data)),
  )
