import * as func from '../../utilities/ReusableFunctions'
import types from '../../actions/types'

var initialState = {
  toggleDatePicker: false,
  weeklyCalendarDateHeader: undefined,
  currentMonthYear: undefined,
  scheduleCalendarDateHeader: undefined,
  showPicker: false,
  selectedDate: undefined,
  defaultDate: undefined,
  clinicID: '',
  showModal: false,
  clinicianID: '',
  clinicianAvatar: '',
  schedule: [],
  clinicianList: [],
  defaultOpeningTime: '',
  defaultClosingTime: '',
  selectedStartTimeSlot: '',
  selectedEndTimeSlot: '',
  selectedTime: '',
  showBookedAppointmentModal: false,
  appointmentID: null,
  showBookedModal: false,
  appointmentDate: undefined,
  zoomStatus: 'zoomedin',
  clinicList: [],
  isAppointmentChanged: false,
  addEditAppointmentScroll: '',
  clickedCellData: null,
  ignoreOpeningHours: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_DATE_PICKER:
      if (state.toggleDatePicker) {
        return { ...state, toggleDatePicker: false }
      } else {
        return { ...state, toggleDatePicker: true }
      }
    case types.SET_APPOINTMENT_OPENING_TIME: {
      let openTime = '0.00'
      let closeTime = '0.00'

      if (action.openingTime) {
        openTime = action.openingTime.opens
          .substr(0, 5)
          .split(':')
          .join('.')
        closeTime = action.openingTime.closes
          .substr(0, 5)
          .split(':')
          .join('.')
      }

      return {
        ...state,
        defaultOpeningTime: openTime,
        defaultClosingTime: closeTime,
      }
    }
    case types.GET_ZOOM_STATUS:
      return { ...state, zoomStatus: action.data }
    case types.CALENDAR_HEADER_RENDER_DATE: {
      const payload = func.getDateandWeek(action.currentday)

      const currentMonth = {
        month: action.currentday.format('MMM'),
        year: action.currentday.format('YYYY'),
      }

      return {
        ...state,
        weeklyCalendarDateHeader: payload,
        selectedDate: action.currentday,
        showPicker: false,
        currentMonthYear: currentMonth,
        scheduleCalendarDateHeader: currentMonth,
        appointmentDate: action.currentday.format('DD/MM/YYYY'),
        defaultDate: action.currentday.format('DD/MM/YYYY'),
      }
    }
    case types.CALENDAR_HEADER_RENDER_MONTH:
      return {
        ...state,
        currentMonthYear: action.payload,
        scheduleCalendarDateHeader: action.payload,
        calendarDate: action.currentdate,
        showPicker: false,
      }
    case types.SHOW_PICKER:
      return { ...state, showPicker: true }
    case types.PASS_CLINICIAN_DETAILS:
      return {
        ...state,
        showModal: true,
        clinicianID: Number(action.clinicianData.id),
        clinicianAvatar: action.clinicianData.avatar,
        clinicID: action.clinicianData.clinicID,
      }
    case types.PASS_APPOINTMENT_DETAILS:
      return {
        ...state,
        showModal: true,
        clinicianID: action.appointmentData.id,
        clinicianAvatar: action.appointmentData.avatar,
        clinicID: action.appointmentData.clinicID,
        selectedTime: action.appointmentData.selectedTime,
        selectedStartTimeSlot: action.appointmentData.selectedStartTimeSlot,
        selectedEndTimeSlot: action.appointmentData.selectedEndTimeSlot,
        ignoreOpeningHours: action.appointmentData.ignoreOpeningHours,
      }
    case types.PASS_BOOKED_APPOINTMENT_DETAILS:
      return {
        ...state,
        showBookedAppointmentModal: true,
        clinicianID: action.bookedAppointmentData.id,
        clinicianAvatar: action.bookedAppointmentData.avatar,
        clinicID: action.bookedAppointmentData.clinicID,
        appointmentID: action.bookedAppointmentData.appointmentID,
      }
    case types.CLINIC_UPDATED:
      return { ...state, viewingClinicChanged: action.bool }
    case types.FETCH_PRACTITIONER_APPOINTMENT.OK:
      return { ...state }
    case types.CLEAR_CLINICIAN_DETAILS:
      return {
        ...state,
        showModal: false,
      }
    case types.CLOSE_BOOKED_APPOINTMENT_MODAL:
      return {
        ...state,
        showBookedAppointmentModal: false,
      }
    case types.FETCH_CLINICIANS_LIST.OK:
      return { ...state, clinicianList: action.list }

    case types.FETCH_CLINICIANS_LIST.FAIL:
      return { ...state }
    case types.FETCH_SCHEDULE_LIST.OK:
      return { ...state, schedule: action.list }
    case types.FETCH_SCHEDULE_LIST.FAIL:
      return { ...state }
    case types.FETCH_SCHEDULE_CONFLICT_LIST.OK:
      return { ...state, conflicts: action.list }
    case types.FETCH_SCHEDULE_CONFLICT_LIST.FAIL:
      return { ...state }
    case types.CLOSE_DATE_PICKER:
      return { ...state, showPicker: false }

    case types.APPOINTMENT_UPDATED:
      return { ...state, isAppointmentChanged: action.value }

    case types.SET_APPOINTMENT_SCROLL_POS:
      return { ...state, addEditAppointmentScroll: action.value }
    case types.SET_CLICKED_CELL_DATA:
      return { ...state, clickedCellData: action.value }
    default:
      return state
  }
}
