import React from 'react'
import PropTypes from 'prop-types'
import MainButton from '../../../Buttons'
import TreatmentOptionOverviewItem from '../TreatmentOptionOverviewItem'

const TreatmentOverviewItem = ({
  headerText,
  allowEdit,
  consentFormTitle,
  productSheetTitle,
  treatmentOptions,
  openNewTreatOption,
  consentFormClick,
  productSheetClick,
  handleEditTreatmentClick,
  handleClickTreatOption,
  treatment,
}) => {
  const getTreatmentOptionItems = () => {
    return treatmentOptions.map(treatmentOption => {
      return (
        <TreatmentOptionOverviewItem
          key={treatmentOption.id}
          headerText={treatmentOption.title}
          allowEdit={treatmentOption.locked}
          numPrices={
            treatmentOption.levels && treatmentOption.levels.length > 0
              ? treatmentOption.levels.length
              : 0
          }
          numMetrics={
            treatmentOption.questions && treatmentOption.questions.length > 0
              ? treatmentOption.questions.length
              : 0
          }
          numProducts={treatmentOption.products.length}
          handleClickTreatOption={() => {
            handleClickTreatOption(treatmentOption, treatment)
          }}
        />
      )
    })
  }

  return (
    <React.Fragment>
      <section className="treatment primaryMarginBottom">
        <article className="treatment__section treatment__header">
          <div className="treatment__header__info">
            <div className="treatment__header__title">
              <span className="treatment__header__text multiline_wordbreak">
                {headerText}
              </span>
              {allowEdit ? (
                <span className="treatmentItem__header__editStatus treatment__header__editStatus--top">
                  <span className="icon-lock" />
                </span>
              ) : (
                ''
              )}
            </div>
            <div className="treatment__info__edit">
              <button
                className="buttonCircle buttonCircle--card icon-pencil"
                onClick={() => handleEditTreatmentClick(treatment)}
              />
            </div>
          </div>
          <div className="treatment__info__documents">
            <div className="treatment__info__product">
              {treatment && treatment.product_sheet ? (
                <button
                  className="treatment__header__button treatmentItem__alert buttonTransparent"
                  onClick={() => productSheetClick(treatment)}
                >
                  <span className="treatment__info__icon icon-document" />
                  <span className="treatment__info__text">
                    {productSheetTitle}
                  </span>
                </button>
              ) : (
                <span className="treatmentItem__alert">
                  Missing Post Treatment Instructions
                </span>
              )}
            </div>

            <div className="treatment__info__consent">
              {treatment && treatment.consent ? (
                <button
                  className="treatment__header__button treatmentItem__alert buttonTransparent"
                  onClick={() => consentFormClick(treatment)}
                >
                  <span className="treatment__info__icon icon-document" />
                  <span className="treatment__info__text">
                    {consentFormTitle}
                  </span>
                </button>
              ) : (
                <span className="treatmentItem__alert">
                  Missing Consent Form
                </span>
              )}
            </div>
          </div>
        </article>
        <article className="treatment__body">
          <p className="form__label">Treatment Options</p>
          {treatmentOptions.length > 0 ? (
            getTreatmentOptionItems()
          ) : (
            <span className="empty">No treatment options added</span>
          )}
        </article>
        <article className="treatment__section treatment__footer">
          <MainButton
            type="button"
            label="New treatment option"
            styleType="primary"
            size="small"
            clickHandler={() => openNewTreatOption(treatment)}
          />
        </article>
      </section>
    </React.Fragment>
  )
}

TreatmentOverviewItem.defaultProps = {
  headerText: '',
  allowEdit: false,
  consentFormTitle: 'Custom form',
  treatmentOptions: [],
  productSheetTitle: 'Custom Product Sheet',
  openNewTreatOption: () => {},
  consentFormClick: () => {},
  productSheetClick: () => {},
  handleEditTreatmentClick: () => {},
  handleClickTreatOption: () => {},
}

TreatmentOverviewItem.propTypes = {
  headerText: PropTypes.string,
  allowEdit: PropTypes.bool,
  consentFormTitle: PropTypes.string,
  treatmentOptions: PropTypes.array,
  productSheetTitle: PropTypes.string,
  openNewTreatOption: PropTypes.func,
  consentFormClick: PropTypes.func,
  productSheetClick: PropTypes.func,
  handleEditTreatmentClick: PropTypes.func,
  handleClickTreatOption: PropTypes.func,
  treatment: PropTypes.object.isRequired,
}

export default TreatmentOverviewItem
