import Promise from 'promise'
import * as api from '../api'
import * as url from '../../api_urls'

export const fetchClinicTreatmentList = clinicID => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.clinics + clinicID + '/treatments/')
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const fetchConsultationClinicTreatmentList = (
  clinicID,
  treatmentTypeId,
) => {
  return new Promise((resolve, reject) => {
    return api
      .get(
        '/consultations/' + clinicID + '/calendar/' + treatmentTypeId + '/',
        { params: { include_options: false } },
      )
      .then(response => {
        resolve(
          response.data.filter(({ consent, no_options_required, options }) => {
            const levels = options.find(({ levels }) => levels.length > 0)
            return (
              (levels !== undefined && consent !== null) ||
              no_options_required === true
            )
          }),
        )
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const fetchTreatmentTypeList = clinicId => {
  return new Promise((resolve, reject) => {
    return api
      .get(`/consultations/${clinicId}/calendar/treatment-types/`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getClinicAppointmentDetail = id => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.clinics + 'appointments/' + id + '/')
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getClinicPatientsList = id => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.clinics + id + '/patients/')
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getFilteredPatientsList = data => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.clinics + data.id + '/patients/?find=' + data.patient)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getClinicAppointmentNotes = id => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.clinics + 'appointments/' + id + '/notes')
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const postAppointment = appointmentData =>
  new Promise((resolve, reject) =>
    api
      .post(
        `${url.clinics}${appointmentData.clinicId}/appointments/`,
        appointmentData,
      )
      .then(res => resolve(res))
      .catch(err => reject(err)),
  )

export const getAvaliableSlotList = (data, ignoreOpeningHours) => {
  let extra = ''

  if (data.appointmentID) {
    extra += '&ignore_appointment=' + data.appointmentID
  }

  if (ignoreOpeningHours) {
    extra += `&ignore_opening_hours=${ignoreOpeningHours}`
  }

  return new Promise((resolve, reject) => {
    return api
      .get(
        url.clinics +
          data.clinic +
          '/availability/' +
          data.date +
          '/?length=' +
          data.appointmentLength +
          extra,
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
