import * as auth from '../services/OnBoarding/auth'
import { getAccount } from '../services/Bundles'
import types from './types'

export const A_ClearToken = () => ({ type: types.CLEAR_TOKEN })

export const A_GetAccount = () => dispatch => {
  return getAccount()
    .then(response => {
      dispatch({ type: types.GET_ACCOUNT.OK, data: response })
      return response
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export const A_UpgradeToken = data => dispatch =>
  auth
    .upgradeToken(data)
    .then(response => {
      dispatch({ type: types.TOKEN_UPGRADE.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.TOKEN_UPGRADE.FAIL, error })
      return Promise.reject(error)
    })
