import React from 'react'
import PropTypes from 'prop-types'
import * as func from '../../../utilities/ReusableFunctions'

const SearchResults = ({
  data,
  idField,
  nodeStructures,
  searchValue,
  onSelect,
  staffResult,
  messageResult,
}) => {
  const getHeaderRows = () => {
    let nodes = nodeStructures.map(function(nodeStructure) {
      return (
        <span
          key={`node_${nodeStructure.title}`}
          style={{ width: nodeStructure.width + '%' }}
          className="searchResult__header__title searchResult__header__text form__label"
        >
          {nodeStructure.title}
        </span>
      )
    })
    return nodes
  }

  const getDataRows = () => {
    let rows = data.map(function(item, ind) {
      let nodes = nodeStructures.map(function(nodeStructure) {
        let nodeValue = ''
        switch (nodeStructure.type) {
          case 'data':
            nodeValue = nodeStructure.searchField
              ? func.highlightSearchResult(
                  item[nodeStructure.value],
                  searchValue,
                )
              : item[nodeStructure.value]
            break
          case 'func':
            const fieldResult = func[nodeStructure.value](item) // eslint-disable-line
            nodeValue = nodeStructure.searchField
              ? func.highlightSearchResult(fieldResult, searchValue)
              : fieldResult
            break
          default:
            nodeValue = nodeStructure.value
            break
        }
        return (
          <span
            key={`${nodeStructure.title}_${item.id}`}
            style={{ width: nodeStructure.width + '%' }}
            className={`searchResult__header__text searchResult__field ${'searchResult__field--' +
              nodeStructure.priority}`}
          >
            {nodeValue}
          </span>
        )
      })
      return (
        <li key={`item_${item.id}`} className="searchResult__result">
          <button
            type="button"
            data-patient={item[idField]}
            className={`buttonTransparent searchResult__row searchResults__row--table searchResult__row-selectable searchResult__row--table-selectable
                        ${
                          ind % 2
                            ? 'searchResult__row--even'
                            : 'searchResult__row--odd'
                        }
                        ${
                          ind === data.length - 1
                            ? 'searchResults__row--table-last'
                            : ''
                        }`}
            onClick={onSelect}
          >
            {nodes}
          </button>
        </li>
      )
    })
    return rows
  }

  return (
    <React.Fragment>
      <article
        className={
          staffResult
            ? 'searchResult__table--staff searchResult__table searchResult_table--patient'
            : messageResult
            ? 'searchResult__table--staff searchResult__table searchResult_table--patient searchResult_table--left'
            : 'searchResult__table searchResult_table--patient'
        }
      >
        <div className="searchResult__header searchResult__header--table">
          <div className="searchResult__row searchResults__row--table-header">
            {getHeaderRows()}
          </div>
          <div className="searchResult__header__spacer" />
        </div>
        <div className="searchResults__scroll">
          <ul className="searchResult__results">{getDataRows()}</ul>
        </div>
      </article>
    </React.Fragment>
  )
}

SearchResults.defaultProps = {
  data: [],
  idField: 'id',
  nodeStructures: [],
  onSelect: () => null,
  staffResult: false,
  messageResult: false,
  searchValue: '',
}

SearchResults.propTypes = {
  data: PropTypes.array,
  idField: PropTypes.string,
  nodeStructures: PropTypes.array,
  onSelect: PropTypes.func,
  staffResult: PropTypes.bool,
  messageResult: PropTypes.bool,
  searchValue: PropTypes.string,
}

export default SearchResults
