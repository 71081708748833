import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TutorialContext } from '../../App'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  A_GetFilteredPatients,
  A_GetPatientsList,
  A_SetSelectedPatient,
  A_GetClinicsList,
} from '../../actions'
import * as obj from '../../utilities/ReusableObjects'
import SearchHeader from '../../components/Search/Header'
import SearchResults from '../../components/Search/Results'
import SearchResultMessage from '../../components/Search/ResultMessage'
import FullScreenModal from '../../components/Modal/FullScreen'

import FeedbackNotification from '../../components/Feedback/FeedbackNotification'
import Loader from '../../components/PatientData/Loader'
import TutorialBtn from '../Tutorials/TutorialBtn'
import { isTutorialFeatureEnabled } from '../../utilities/featureToggle'
import Placeholder from '../../components/Tutorials/Placeholder'
import { getTasks } from '../../services/OnBoarding/auth'

class PatientSearch extends Component {
  constructor(props) {
    super(props)

    this.state = {
      totalPatientCount: 0,
      showAddPatient: false,
      firstName: '',
      lastName: '',
      gender: 0,
      DateOfBirth: '',
      mobilePhone: '',
      email: '',
      permission_to_contact_given: false,
      preferred_contact: [],
      firstNameError: false,
      lastNameError: false,
      dateOfBirthError: false,
      mobilePhoneError: false,
      duplicateError: false,
      responseError: '',
      showFeedback: false,
    }
    this.onSearchChanged = this.onSearchChanged.bind(this)
    this.onAddPatient = this.onAddPatient.bind(this)
    this.onModalRemoved = this.onModalRemoved.bind(this)
    this.onHideFeedback = this.onHideFeedback.bind(this)
    this.loadPatientRecord = this.loadPatientRecord.bind(this)
    this.loadNextPatients = this.loadNextPatients.bind(this)

    this.timeout = 0
  }

  async componentDidMount() {
    const { actions, formatTasks } = this.props
    const tasks = await getTasks()
    formatTasks(tasks)

    actions.A_GetClinicsList().then(() => {
      const { global } = this.props

      let clinic = global.currentClinicID

      if (clinic) {
        this.updateSearch()
      }
    })
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout)
  }

  onAddPatient() {
    this.setState({
      showAddPatient: true,
    })
  }

  onModalRemoved() {
    this.setState({
      showAddPatient: false,
    })
  }

  onSearchChanged(event) {
    if (this.timeout) clearTimeout(this.timeout)

    let value = event.currentTarget.value

    this.timeout = setTimeout(() => {
      this.updateSearch(value)
    }, 300)
  }

  updateSearch(searchValue) {
    const { actions, global } = this.props

    if (!searchValue) {
      searchValue = ''
    }

    let clinic = global.currentClinicID

    if (clinic) {
      const data = {
        id: clinic,
        patient: searchValue,
        nextQuery: null,
      }

      actions.A_GetFilteredPatients(data)
    }
  }

  onPatientAddedSuccess() {
    this.setState(
      {
        duplicateError: false,
        showAddPatient: false,
        showFeedback: true,
      },
      () => this.updateSearch(),
    )
  }

  onHideFeedback() {
    this.setState({
      showFeedback: false,
    })
    this.onModalRemoved()
  }

  loadPatientRecord(event) {
    const {
      patients,
      history,
      actions,
      global: { currentClinicID },
    } = this.props
    const patientData = patients.patientList.filter(patient => {
      return patient.id === Number(event.currentTarget.dataset.patient)
    })[0]
    actions.A_SetSelectedPatient({
      ...patientData,
      clinicId: currentClinicID,
    })
    ///WHEN SECTIONS ARE COMPLETE, THIS WILL DEFAULT TO HISTORY
    history.push(`/clinics/patients/${patientData.id}/treatments`)
  }

  loadNextPatients() {
    const { nextQuery, actions, global, patients } = this.props

    let clinic = global.currentClinicID

    actions.A_GetFilteredPatients({
      id: clinic,
      patient: patients.searchValue,
      nextQuery: nextQuery,
    })
  }

  renderResults() {
    const { patients } = this.props
    let list = []

    if (patients.patientList.length > 0) {
      list.push(
        <SearchResults
          data={patients.patientList}
          idField="id"
          nodeStructures={obj.patientSearchNodes}
          searchValue={patients.searchValue}
          onSelect={this.loadPatientRecord}
          key="results"
        />,
      )
    }

    if (patients.isLoading) {
      list.push(<Loader key="loader" />)
    } else if (patients.patientList.length === 0) {
      if (patients.searchValue.length === 0) {
        list.push(
          <SearchResultMessage
            message="Search results will appear here"
            key="message"
          />,
        )
      } else {
        list.push(
          <SearchResultMessage
            message="No patients match your search"
            searchValue={patients.searchValue}
            key="message"
          />,
        )
      }
    }

    return list
  }

  render() {
    const { patients, nextQuery, global } = this.props

    let clinic = global.currentClinicID

    let tobeDisabled = clinic ? false : true
    const buttons = [
      {
        type: 'button',
        style: 'secondary',
        label: 'Add new patients',
        size: 'small',
        isDisabled: tobeDisabled,
        events: { onClick: this.onAddPatient },
      },
    ]
    return (
      <React.Fragment>
        <main className="main">
          {!this.props.hasCompletedAllTasks ? (
            <Placeholder activeTasks={this.props.activeTasks} />
          ) : null}
          {isTutorialFeatureEnabled ? (
            <TutorialContext.Consumer>
              {({
                toggleTutorial,
                setTutorial,
                tutorialList,
                history,
                activeTutorial,
              }) => (
                <TutorialBtn
                  tutorialList={tutorialList}
                  toggleTutorial={toggleTutorial}
                  setTutorial={setTutorial}
                  activeTutorial={activeTutorial}
                  history={history}
                />
              )}
            </TutorialContext.Consumer>
          ) : null}
          <SearchHeader
            filteredCount={patients.patientList.length}
            totalCount={patients.patientCount}
            resultsLegend="in your clinic group"
            showResults={!patients.isLoading}
            buttonSet={buttons}
            onChange={this.onSearchChanged}
          />
          <section className="main__inner main__inner--paginated">
            {this.renderResults()}
            {patients.isLoading === false && nextQuery ? (
              <article className="pagination_wrapper">
                <button
                  className="buttonTransparent textLink"
                  onClick={e => {
                    this.loadNextPatients(e)
                  }}
                >
                  See more
                </button>
              </article>
            ) : (
              ''
            )}
          </section>
        </main>
        {this.state.showAddPatient ? (
          <FullScreenModal hideModal={this.onModalRemoved} />
        ) : (
          ''
        )}
        {this.state.showFeedback ? (
          <FeedbackNotification
            id="patient_creation"
            message={`Patient record for ${this.state.firstName} ${this.state.lastName} created successfully`}
            isWarning={false}
            hideFeedback={this.onHideFeedback}
          />
        ) : (
          ''
        )}
      </React.Fragment>
    )
  }
}

PatientSearch.defaultProps = {
  nextQuery: null,
}

PatientSearch.propTypes = {
  history: PropTypes.object.isRequired,
  patients: PropTypes.object.isRequired,
  nextQuery: PropTypes.string,
  actions: PropTypes.object.isRequired,
  global: PropTypes.object.isRequired,
  formatTasks: PropTypes.func.isRequired,
  hasCompletedAllTasks: PropTypes.bool.isRequired,
  activeTasks: PropTypes.array.isRequired,
}

const mapStateToProps = state => {
  return {
    patients: state.patients,
    nextQuery: state.patients.patientNextQuery,
    global: state.global,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetPatientsList,
        A_GetFilteredPatients,
        A_SetSelectedPatient,
        A_GetClinicsList,
      },
      dispatch,
    ),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PatientSearch),
)
