import React from 'react'
import PropTypes from 'prop-types'
import OutstandingActionCard from '../OutstandingActions'
import dateFns from 'date-fns'
import {
  getAppointmentFollowUpText,
  getFullName,
  getInitials,
  getConsultationTreatmentNames,
  getFormattedDate,
  getFollowUpDate,
  clinicHasHealthExchange,
} from '../../../utilities/ReusableFunctions'
import Loader from '../../PatientData/Loader'

const PatientRecordSidebar = ({
  title,
  infoMessage,
  appointmentActions,
  referralActions,
  invoiceActions,
  onAppointmentClick,
  onReferralClick,
  onInvoiceClick,
  clinicList,
  isLoading,
  history,
  ...props //eslint-disable-line
}) => {
  // Return outstanding action components for all actions
  const getActions = () => {
    let allActions = getActionList()
    let actions = []
    actions = allActions.map(action => {
      return (
        <React.Fragment key={action.id}>
          <OutstandingActionCard
            titleAvatar={action.titleAvatar}
            titleAvatarAlt={action.titleAvatarAlt}
            titleHighlight={action.titleHighlight}
            id={action.id}
            bodyText={action.bodyText}
            actionButtons={action.actionButtons}
          />
        </React.Fragment>
      )
    })
    return actions
  }

  // Get outstanding action component properties from actions data
  // Merge actions in to one list, and sort based on most outstanding first
  const getActionList = () => {
    const linkHealthxchange = clinicId => {
      return {
        type: 'button',
        style: 'secondary',
        label: 'Link Healthxchange',
        size: 'small',
        events: {
          onClick: () => history.push(`/clinics/${clinicId}`),
        },
      }
    }

    let appointments = appointmentActions
      .filter(appointment => {
        return (
          appointment.consultation &&
          appointment.consultation.appointment &&
          appointment.duration > 0
        )
      })
      .map(action => {
        const actionStartDate =
          action.consultation.appointment &&
          action.consultation.appointment.start_date
            ? getFormattedDate(
                action.consultation.appointment.start_date,
                'date',
              )
            : ''
        return {
          actionDate: getFollowUpDate(action),
          titleAvatar:
            action.consultation.appointment &&
            action.consultation.appointment.clinician
              ? action.consultation.appointment.clinician.signed_avatar
              : '',
          titleAvatarAlt:
            action.consultation.appointment &&
            action.consultation.appointment.clinician
              ? getInitials(action.consultation.appointment.clinician)
              : '',
          titleHighlight:
            action.consultation.appointment &&
            action.consultation.appointment.clinician
              ? getFullName(action.consultation.appointment.clinician)
              : '',
          id: `appointment_${action.id}`,
          bodyText: getAppointmentFollowUpText(
            action.purpose,
            action.duration,
            action.arrange,
            action.arrange_interval,
            actionStartDate,
          ),
          actionButtons:
            action.consultation.appointment.clinic &&
            clinicHasHealthExchange(
              action.consultation.appointment.clinic.id,
              clinicList,
            )
              ? [
                  {
                    type: 'button',
                    style: 'primary',
                    label: 'Book now',
                    size: 'small',
                    events: {
                      onClick: () => onAppointmentClick(action),
                    },
                  },
                ]
              : [linkHealthxchange(action.consultation.appointment.clinic.id)],
        }
      })
    let referrals = referralActions
      .filter(appointment => {
        return appointment.consultation && appointment.consultation.appointment
      })
      .map(action => {
        return {
          actionDate: action.consultation.appointment.start_date,
          titleAvatar: action.consultation.appointment
            ? action.consultation.appointment.clinician.signed_avatar
            : '',
          titleAvatarAlt: action.consultation.appointment
            ? getInitials(action.consultation.appointment.clinician)
            : '',
          titleHighlight: action.consultation.appointment
            ? getFullName(action.consultation.appointment.clinician)
            : '',
          id: `referral_${action.id}`,
          bodyText: action.notes,
          actionButtons:
            action.consultation.appointment.clinic &&
            clinicHasHealthExchange(
              action.consultation.appointment.clinic.id,
              clinicList,
            )
              ? [
                  {
                    type: 'button',
                    style: 'primary',
                    label: 'Add referral letter',
                    size: 'small',
                    events: {
                      onClick: () => onReferralClick(action),
                    },
                  },
                ]
              : [linkHealthxchange(action.consultation.appointment.clinic.id)],
        }
      })
    let invoices = invoiceActions.map(action => {
      return {
        actionDate: action.appointment.start_date,
        titleAvatar: action.appointment
          ? action.appointment.clinician.signed_avatar
          : '',
        titleAvatarAlt: action.appointment
          ? getInitials(action.appointment.clinician)
          : '',
        titleHighlight: action.appointment
          ? getFullName(action.appointment.clinician)
          : '',
        id: `invoice_${action.id}`,
        bodyText: `${getConsultationTreatmentNames(
          action,
        )} invoice from ${getFormattedDate(
          action.appointment.start_date,
          'date',
        )} at ${getFormattedDate(action.appointment.start_date, 'time')}`,
        actionButtons:
          action.appointment.clinic &&
          clinicHasHealthExchange(action.appointment.clinic.id, clinicList)
            ? [
                {
                  type: 'button',
                  style: 'primary',
                  label: 'Create invoice',
                  size: 'small',
                  events: {
                    onClick: () => onInvoiceClick(action),
                  },
                },
              ]
            : [linkHealthxchange(action.appointment.clinic.id)],
      }
    })
    let allActions = [...appointments, ...referrals, ...invoices]
    allActions.sort((a, b) => {
      if (dateFns.isAfter(a.actionDate, b.actionDate)) {
        return 1
      } else {
        return -1
      }
    })
    return allActions
  }

  return (
    <>
      <section className="patient__sidebar patient__sidebar--overflow">
        <div className="header patient__siderbar__header">
          <h3 className="h3">{title}</h3>
        </div>

        <div className="content">
          {appointmentActions.length === 0 &&
          referralActions.length === 0 &&
          invoiceActions.length === 0 ? (
            <p className="greyText">{infoMessage}</p>
          ) : (
            getActions()
          )}
          {isLoading ? <Loader /> : ''}
        </div>
      </section>
    </>
  )
}

PatientRecordSidebar.defaultProps = {
  title: '',
  infoMessage: '',
  appointmentActions: [],
  referralActions: [],
  invoiceActions: [],
  onAppointmentClick: () => {},
  onReferralClick: () => {},
  onInvoiceClick: () => {},
  clinicList: [],
  isLoading: false,
}

PatientRecordSidebar.propTypes = {
  title: PropTypes.string,
  infoMessage: PropTypes.string,
  appointmentActions: PropTypes.array,
  referralActions: PropTypes.array,
  invoiceActions: PropTypes.array,
  onAppointmentClick: PropTypes.func,
  onReferralClick: PropTypes.func,
  onInvoiceClick: PropTypes.func,
  clinicList: PropTypes.array,
  isLoading: PropTypes.bool,
  history: PropTypes.object.isRequired,
}

export default PatientRecordSidebar
