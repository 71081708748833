import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Loader from '../../components/PatientData/Loader'
import {
  appointmentPurposeIds,
  appointmentPurposeTitles,
  appointmentReasonIds,
  ClinicLocaleTypes,
} from '../../utilities/ReusableObjects'
import {
  getFullName,
  getPatientAlertData,
} from '../../utilities/ReusableFunctions'
import MainButton from '../../components/Buttons'
import SelectInput from '../../components/forms/Select'
import { isAlertAndAllergiesEnabled } from '../../utilities/featureToggle'

class PreOrdersAppointments extends Component {
  handleClinician = clinician_id => {
    const { setVisibleClinician } = this.props

    setVisibleClinician(clinician_id)
  }

  handleProduct = (treatment_id, product_id) => {
    const {
      visibleTreatment,
      setVisibleTreatment,
      visibleProduct,
      setVisibleProduct,
    } = this.props

    if (visibleTreatment === treatment_id && visibleProduct === product_id) {
      setVisibleTreatment(0)
      setVisibleProduct(0)
    } else {
      setVisibleTreatment(treatment_id)
      setVisibleProduct(product_id)
    }
  }

  render() {
    const {
      isLoading,
      clinicians,
      appointments,
      visibleClinician,
      treatmentsForOrdering,
      visibleTreatment,
      visibleProduct,
      productsToOrder,
      setProductsToOrder,
      setIsReviewing,
      startDate,
      endDate,
      preOrderType: { locale },
      outstandingOrders,
    } = this.props

    if (visibleClinician === 0 && clinicians.length > 0) {
      this.handleClinician(clinicians[0].id)
    }

    const clinicianAppointments = appointments.filter(
      ({ clinician: { id } }) => id === visibleClinician,
    )

    const validAppointments = clinicianAppointments.filter(
      ({ treatments }) =>
        treatments.find(({ products }) => products && products.length > 0) !==
        undefined,
    )

    let summary = treatmentsForOrdering
      .map(({ products }) => {
        return products
          .map(product => {
            let total = 0
            productsToOrder.forEach(({ prescriptions }) => {
              let prescription = prescriptions.find(
                ({ product: { product_id } }) =>
                  product_id === product.product_id,
              )

              if (prescription !== undefined) {
                total += prescription.quantity
              }
            })

            if (total > 0) {
              return (
                <span
                  className="p"
                  key={product.product_id}
                  style={{ display: 'block' }}
                >
                  {product.product_description} {total}
                </span>
              )
            }
            return undefined
          })
          .filter(value => value !== undefined)
      })
      .filter(value => value.length > 0)

    const allergyAlertPatients = validAppointments.filter(
      ({ patient }) => getPatientAlertData(patient, 'allergies').length > 0,
    ).length

    return (
      <section className="main__inner">
        <section className="patientImport__wrapper">
          <article className="overviewAppointments__oneThird no-print">
            <article className="form">
              <h2 className="h2 bold">Pre-ordering</h2>
              <span className="practitioner_role">
                {startDate.format('DD.MM.YYYY')} to{' '}
                {endDate.format('DD.MM.YYYY')}
              </span>
              <br />
              <br />
              <br />
              <div className="form__filterSection--select">
                <SelectInput
                  id="reportFilterStaff"
                  options={clinicians}
                  label="For"
                  isSmallMargin={true}
                  hasOwnWrapper={false}
                  onChange={({ target: { value } }) =>
                    this.handleClinician(parseInt(value))
                  }
                  defaultSelected={visibleClinician}
                />
              </div>

              <span className="heading">Appointments Due</span>
              <h1 className="h1">{validAppointments.length}</h1>
              <br />

              <span className="heading">Appointment Types</span>
              {isLoading ? (
                <Loader />
              ) : (
                Object.keys(appointmentPurposeIds).map(key => {
                  const amount = validAppointments.filter(
                    ({ purpose }) => purpose === appointmentPurposeIds[key],
                  ).length
                  if (amount > 0) {
                    const percentage =
                      (amount / validAppointments.length) * 100.0
                    return (
                      <div key={key} className="weeklyOverviewBar--wrapper">
                        <div
                          className={`weeklyOverviewBar weeklyOverviewBar--${key}`}
                          style={{ width: `${percentage}%` }}
                        ></div>
                        <span className={`weeklyOverviewLabel--${key}`}>
                          {amount} {appointmentPurposeTitles[key]}
                        </span>
                      </div>
                    )
                  }
                  return ''
                })
              )}
              {isAlertAndAllergiesEnabled && allergyAlertPatients > 0 ? (
                <>
                  <br />
                  <span className="heading">Allergies & Alerts</span>
                  <div className="weeklyOverviewBar--wrapper">
                    <div
                      className={`weeklyOverviewBar weeklyOverviewBar--ALERT weeklyOverviewLabel--ALERT`}
                    >
                      <span className="icon-warning" />
                      <span>{allergyAlertPatients} Patients</span>
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
            </article>
          </article>
          <article className="overviewAppointment__twoThirds overviewAppointment__middleThirds">
            <article className="form">
              <h2 className="h2 bold">Product selection</h2>
              <span className="p">
                Based on your upcoming appointments in the calendar, we’ve
                predicted the following products may be required. Apply amounts
                to each patient and product.
              </span>
              {treatmentsForOrdering.map(treatment => {
                return (
                  <article key={treatment.id} className="">
                    <div className="practitioner_details overviewAppontments__header">
                      <div className="practitioner_details">
                        <h2 className="h2 bold">{treatment.title}</h2>
                      </div>
                      <div className="practitioner_detail practitioner_detail_add">
                        <div className="weeklyOverviewBar--container">
                          {Object.keys(appointmentPurposeIds)
                            .reverse()
                            .map(key => {
                              const amount = treatment[key]
                              if (amount > 0) {
                                return (
                                  <div
                                    key={key}
                                    className="weeklyOverviewBar--wrapper weeklyOverviewBar--wrapperHeader"
                                  >
                                    <span
                                      className={`p weeklyOverviewBar--${key} weeklyOverviewLabel--${key}`}
                                    >
                                      {amount}
                                    </span>
                                  </div>
                                )
                              }
                              return ''
                            })}
                        </div>
                      </div>
                    </div>
                    {treatment.products.map((product, i) => {
                      let total = 0
                      productsToOrder.forEach(({ prescriptions }) => {
                        let prescription = prescriptions.find(
                          ({ product: { product_id } }) =>
                            product_id === product.product_id,
                        )

                        if (prescription !== undefined) {
                          total += prescription.quantity
                        }
                      })

                      let options = [
                        ...Array(
                          (product.maximum_quantity
                            ? product.maximum_quantity
                            : 20) + 1,
                        ).keys(),
                      ].map(num => {
                        return {
                          id: num,
                          title: num,
                        }
                      })
                      return (
                        <div
                          key={`treatment-${treatment.id}-product-${product.product_id}`}
                          className="overviewAppontmentsProduct__container"
                        >
                          <div
                            className="overviewAppontmentsProduct__header"
                            role="button"
                            tabIndex={i}
                            onClick={() =>
                              this.handleProduct(
                                treatment.id,
                                product.product_id,
                              )
                            }
                            onKeyDown={() => {}}
                            style={{ outline: 'none', cursor: 'pointer' }}
                          >
                            <span
                              className={
                                visibleTreatment === treatment.id &&
                                visibleProduct === product.product_id
                                  ? 'icon-chevron-down'
                                  : 'icon-chevron-right'
                              }
                            />
                            <h4 className="h4 bold">
                              {product.product_description}
                            </h4>
                            <span className="overviewAppontmentsTotal bold">
                              {total}
                            </span>
                          </div>
                          {visibleTreatment === treatment.id &&
                          visibleProduct === product.product_id
                            ? treatment.patients.map(patient => {
                                let order = productsToOrder.find(
                                  ({ record: { id } }) => id === patient.id,
                                )

                                if (order !== undefined) {
                                  let prescription = order.prescriptions.find(
                                    ({ product: { product_id } }) =>
                                      product_id === product.product_id,
                                  )

                                  if (prescription !== undefined) {
                                    const app = patient.appointment

                                    const colorClass = Object.keys(
                                      appointmentReasonIds,
                                    )
                                      .filter(
                                        key =>
                                          appointmentReasonIds[key] ===
                                          app.appointment_type,
                                      )
                                      .map(key => {
                                        switch (appointmentReasonIds[key]) {
                                          case appointmentReasonIds.APPOINTMENT:
                                            return `weeklyOverviewBar--${Object.keys(
                                              appointmentPurposeIds,
                                            ).find(
                                              key =>
                                                appointmentPurposeIds[key] ===
                                                app.purpose,
                                            )}`
                                          default:
                                            return `weeklyOverviewBar--${key}`
                                        }
                                      })

                                    let title = getFullName(app.patient)

                                    return (
                                      <div
                                        key={`treatment-${treatment.id}-product-${product.product_id}-patient-${patient.id}`}
                                      >
                                        <div
                                          className={`${colorClass}`}
                                          style={{
                                            borderRadius: '8px',
                                            padding: '12px 16px',
                                            display: 'flex',
                                            alignContent: 'space-between',
                                            flexWrap: 'wrap',
                                            position: 'relative',
                                            margin: '0 16px 8px',
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: 'calc(100% * (5/12))',
                                            }}
                                          >
                                            <div className="practitioner_name singleLine_truncatedText">
                                              {title}
                                            </div>
                                            <div className="practitioner_role singleLine_truncatedText">
                                              {patient.date_of_birth
                                                ? `${moment
                                                    .tz(
                                                      patient.date_of_birth,
                                                      'UTC',
                                                    )
                                                    .format('DD.MM.YYYY')} (${
                                                    patient.age
                                                  })`
                                                : 'No date of birth registered'}
                                            </div>
                                          </div>

                                          <div
                                            style={{
                                              width: 'calc(100% * (5/12))',
                                            }}
                                          >
                                            {app.treatments.length > 0 ? (
                                              <div className="practitioner_role singleLine_truncatedText">
                                                {app.treatment_type.title}
                                              </div>
                                            ) : (
                                              ''
                                            )}
                                            {isAlertAndAllergiesEnabled &&
                                            getPatientAlertData(
                                              patient,
                                              'allergies',
                                            ).length > 0 ? (
                                              <div className="practitioner_name form__fieldIcon--invalid singleLine_truncatedText">
                                                Allergy/Alert
                                              </div>
                                            ) : (
                                              ''
                                            )}
                                          </div>
                                          <div
                                            style={{
                                              width: 'calc(100% * (2/12))',
                                            }}
                                          >
                                            <SelectInput
                                              type="text"
                                              id=""
                                              options={options}
                                              defaultSelected={
                                                prescription.quantity
                                              }
                                              onChange={({
                                                currentTarget: { value },
                                              }) => {
                                                prescription.quantity = parseInt(
                                                  value,
                                                )

                                                let products = [
                                                  ...productsToOrder.filter(
                                                    ({ record: { id } }) =>
                                                      id !== patient.id,
                                                  ),
                                                  {
                                                    ...order,
                                                  },
                                                ]

                                                setProductsToOrder(products)
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  }
                                }
                              })
                            : ''}
                        </div>
                      )
                    })}
                  </article>
                )
              })}
            </article>
          </article>
          <article className="overviewAppointments__oneThird">
            <article className="form overviewAppointments__summary">
              <h2 className="h2 bold">Order Summary</h2>
              {summary.length > 0 ? (
                summary
              ) : (
                <span className="p" style={{ display: 'block' }}>
                  Products you add will appear here
                </span>
              )}
              {summary.length > 0 ? (
                <MainButton
                  type="button"
                  label="Review order"
                  styleType="secondary"
                  size="small"
                  onClick={() => setIsReviewing(true)}
                />
              ) : (
                ''
              )}
            </article>
            {locale === ClinicLocaleTypes.en_GB &&
            outstandingOrders.count > 0 ? (
              <article className="form">
                <span className="heading">Unfinished orders</span>
                <h1 className="h1">{outstandingOrders.count}</h1>
                <span className="p" style={{ display: 'block' }}>
                  You have {outstandingOrders.count} existing orders that still
                  need to be signed for on Healthxchange e-Pharmacy
                </span>
                <a
                  className="buttonTertiary button--small"
                  style={{ width: '100%', padding: '0.5rem' }}
                  href="https://shop.healthxchange.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Go to Healthxchange
                </a>
              </article>
            ) : (
              ''
            )}
          </article>
        </section>
      </section>
    )
  }
}

PreOrdersAppointments.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  preOrderType: PropTypes.object.isRequired,
  clinicians: PropTypes.array.isRequired,
  appointments: PropTypes.array.isRequired,
  visibleClinician: PropTypes.number.isRequired,
  setVisibleClinician: PropTypes.func.isRequired,
  treatmentsForOrdering: PropTypes.array.isRequired,
  visibleProduct: PropTypes.number.isRequired,
  setVisibleProduct: PropTypes.func.isRequired,
  visibleTreatment: PropTypes.number.isRequired,
  setVisibleTreatment: PropTypes.func.isRequired,
  productsToOrder: PropTypes.array.isRequired,
  setProductsToOrder: PropTypes.func.isRequired,
  setIsReviewing: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(moment).isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired,
  outstandingOrders: PropTypes.object.isRequired,
}

export default PreOrdersAppointments
