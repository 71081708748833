import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

class ErrorToolTipCard extends Component {
  render() {
    const { data, errorMsg } = this.props
    return (
      <section className="tooltipWrapper">
        <aside className="tooltip tooltip--error">
          <section className="tooltip__tip icon-alert">
            <span className="tooltip__tipText">
              {data.inputError !== '' ? data.inputError : errorMsg}
            </span>
          </section>
          <span className="tooltip__pointerStretch">
            <span className="tooltip__pointer tooltip--error__pointer" />
          </span>
        </aside>
      </section>
    )
  }
}

export const errorSpan = () => {
  return <span className="form__label--error">ERROR:</span>
}

export const requiredSpan = () => {
  return <span className="form__label--error">REQUIRED: </span>
}
export const listError = () => {
  return <span className="form__label--error">SELECT FROM List: </span>
}

ErrorToolTipCard.propTypes = {
  data: PropTypes.object.isRequired,
  errorMsg: PropTypes.string.isRequired,
}

const mapStateToProps = state => {
  return { data: state.newAccount }
}
const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({}, dispatch),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ErrorToolTipCard),
)
