import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SelectInput from '../../forms/Select'
import TextInput from '../../forms/Input'
import PhoneNumberInput from '../../forms/PhoneNumber'
import * as utils from '../../../utilities/ReusableFunctions'
import CheckboxList from '../../forms/CheckboxList'
import DateOfBirthInput from '../../forms/DateOfBirth'
import AddressLookupInput from '../../forms/AddressLookup'
import ProcessStepper from '../../ProcessStepper'

import {
  validatePhoneNumber,
  validateEmailAddress,
} from '../../../containers/_/Validators'
import { A_AddPatient } from '../../../actions'

class FullScreenModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.resetState()
    this.modal = React.createRef()
  }

  resetState = () => {
    return {
      data: {
        first_name: '',
        last_name: '',
        gender: 0,
        date_of_birth: '',
        mobile_phone: '',
        email: '',
        permission_to_contact_given: false,
        preferred_contact: [],
        address_line_one: '',
        address_line_two: '',
        address_town: '',
        address_county: '',
        address_country: '',
      },
      duplicateError: false,
      responseError: '',
      showFeedback: false,
      errors: {
        first_name: false,
        last_name: false,
        date_of_birth: false,
        mobile_phone: false,
        email: false,
        address: false,
      },
      step: 0,
    }
  }

  componentDidMount() {
    this.modal.current.classList.remove('fadeOutFlyAnimate')
    this.modal.current.classList.add('fadeInFlyAnimate')
    this.modal.current.style.display = 'block'
    window.scrollTo(0, 0)
  }

  hideModal = () => {
    this.modal.current.classList.remove('fadeInFlyAnimate')
    this.modal.current.classList.add('fadeOutFlyAnimate')
    let that = this
    setTimeout(function() {
      that.modal.current.style.display = 'none'
      that.props.hideModal()
      that.setState(that.resetState())
    }, 500)
  }

  updateData = (key, value) => {
    let update = this.state.data
    update[key] = value

    this.setState({
      data: update,
    })
  }

  onChange = ({ currentTarget: { id, value } }) => {
    this.updateData(id, value)
  }

  dateOfBirthChange = value => {
    this.updateData('date_of_birth', value)
  }

  mobilePhoneChange = value => {
    this.updateData('mobile_phone', value)
  }

  addressItemChanged = (addressId, fieldNames, addressValues) => {
    fieldNames.forEach(fieldName => {
      this.updateData(fieldName, addressValues[fieldName])
    })
  }

  validateForm = event => {
    const { data, errors, step } = this.state
    event.preventDefault()

    let updateErrors = errors

    switch (step) {
      case 0:
        {
          const keys = ['first_name', 'last_name']

          keys.forEach(key => {
            const error = data[key].length === 0
            updateErrors[key] = error
          })

          let isValidDOBEntry = true
          const dob = data.date_of_birth
          if (dob && dob !== null) {
            const check = dob.split('-')
            for (let i = 0; i < check.length; i += 1) {
              if (Number(check[i]) === 0) {
                isValidDOBEntry = false
              }
            }
          }

          updateErrors['date_of_birth'] = !isValidDOBEntry
        }
        break
      case 1:
        {
          let isValidPhoneEntry = data.mobile_phone.length === 0

          if (!isValidPhoneEntry) {
            isValidPhoneEntry = validatePhoneNumber(data.mobile_phone).valid
          }

          let isValidEmailEntry = data.email.length === 0

          if (!isValidEmailEntry) {
            isValidEmailEntry = validateEmailAddress(data.email).valid
          }

          updateErrors = {
            ...updateErrors,
            mobile_phone: !isValidPhoneEntry,
            email: !isValidEmailEntry,
          }
        }
        break
      case 2:
        break
      default:
        break
    }

    const newStep =
      Object.values(updateErrors).find(value => value === true) === undefined
        ? step + 1
        : step

    this.setState({
      errors: updateErrors,
      responseError: '',
      duplicateError: false,
      step: newStep === 3 ? step : newStep,
    })

    if (newStep === 3) {
      this.submitModal()
    }
  }

  submitModal = () => {
    const { actions, global } = this.props
    const { data } = this.state

    let clinic = global.currentClinicID

    actions.A_AddPatient(clinic, data).then(response => {
      if (response.status && response.status === 400) {
        this.setState({
          duplicateError: true,
          responseError: [...response.data.non_field_errors],
          step: 0,
        })
      } else {
        const { history } = this.props
        history.push('/clinics/patients/' + response.id + '/treatments')
      }
    })
  }

  render() {
    const { data, errors, step } = this.state

    let stepText = ''

    switch (step) {
      case 0:
        stepText = 'Personal Details'
        break
      case 1:
        stepText = 'Contact Details'
        break
      case 2:
        stepText = 'Address'
        break
    }

    return (
      <article className="modal" ref={this.modal}>
        <div className="col__12-12 modal__closeButtonWrapper">
          <span
            role="button"
            tabIndex="0"
            className="buttonCircle--x2 icon-cross modal__closeButton"
            onClick={this.hideModal}
            onKeyDown={this.hideModal}
          />
        </div>
        <section className="modal__main">
          <article className="clinicModal__card col__12-5">
            <div className="primaryMarginBottom textCenter">
              <h3 className="h1 clinicModal__heading">
                Quick add a new patient
              </h3>
            </div>
            <ProcessStepper
              step={`${step + 1}`}
              stepText={stepText}
              processText="Progress"
            />
            <form>
              <input type="hidden" value="something" />
              {step === 0 ? (
                <>
                  <section className="form__group--halfWidth">
                    <fieldset className={`form__group--halfWidth__panel`}>
                      <TextInput
                        type="text"
                        id="first_name"
                        placeholder="First name"
                        label="First Name"
                        value={data.first_name}
                        onChange={this.onChange}
                        error={`${
                          errors.first_name
                            ? 'Required'
                            : this.state.duplicateError
                            ? 'Error'
                            : ''
                        }`}
                      />
                    </fieldset>
                    <fieldset className={`form__group--halfWidth__panel`}>
                      <TextInput
                        type="text"
                        id="last_name"
                        placeholder="Surname"
                        label="Last Name"
                        value={data.last_name}
                        onChange={this.onChange}
                        error={`${
                          errors.last_name
                            ? 'Required'
                            : this.state.duplicateError
                            ? 'Error'
                            : ''
                        }`}
                      />
                    </fieldset>
                  </section>
                  <section className="form__group--halfWidth">
                    <fieldset className={`form__group--halfWidth__panel`}>
                      <SelectInput
                        id="gender"
                        options={utils.getItems('gender')}
                        label="Gender"
                        hasOwnWrapper={true}
                        onChange={this.onChange}
                        defaultSelected={data.gender}
                      />
                    </fieldset>
                    <fieldset
                      className={`form__group--halfWidth__panel`}
                    ></fieldset>
                  </section>
                  <DateOfBirthInput
                    onChange={this.dateOfBirthChange}
                    error={`${
                      errors.date_of_birth
                        ? 'Required'
                        : this.state.duplicateError
                        ? 'Error'
                        : ''
                    }`}
                    isRequired={false}
                  />
                </>
              ) : step === 1 ? (
                <>
                  <section className="form__group--halfWidth">
                    <fieldset className={`form__group--halfWidth__panel`}>
                      <PhoneNumberInput
                        key="mobilePhone"
                        id="mobilePhone"
                        label="Mobile Phone"
                        placeholder="Mobile Phone"
                        value={data.mobile_phone}
                        hasOwnWrapper={true}
                        onChange={this.mobilePhoneChange}
                        customClasses={`${
                          errors.mobile_phone ? 'form__textField--error' : ''
                        }`}
                        className={`form__textField `}
                        error={errors.mobile_phone ? 'Invalid' : ''}
                        isRequired={false}
                      />
                    </fieldset>
                    <fieldset className={`form__group--halfWidth__panel`}>
                      <TextInput
                        type="email"
                        id="email"
                        placeholder="Email"
                        label="Email"
                        value={data.email}
                        onChange={this.onChange}
                        isRequired={false}
                        error={`${errors.email ? 'Invalid' : ''}`}
                      />
                    </fieldset>
                  </section>
                  <section className="form__group--halfWidth">
                    <fieldset className={`form__group--halfWidth__panel`}>
                      <SelectInput
                        options={utils.getItems('permission_to_contact_given')}
                        defaultSelected={utils.convertBoolean(
                          data,
                          'permission_to_contact_given',
                        )}
                        label="Permission to contact given"
                        isRequired={false}
                        showAsterisk={true}
                        onChange={({ target: { value } }) => {
                          this.updateData(
                            'permission_to_contact_given',
                            value === 'Yes' ? true : false,
                          )
                        }}
                      />
                    </fieldset>
                    <fieldset className={`form__group--halfWidth__panel`}>
                      <CheckboxList
                        formId="preferred_contact"
                        type="checkbox"
                        inline={true}
                        titleLabel="Preferred Contact Method"
                        labelAbove={true}
                        checkboxes={utils.getCheckboxItems(
                          'preferred_contact',
                          data,
                        )}
                        onChange={values => {
                          const checkedValues = values
                            .filter(value => value.checked)
                            .map(filteredValue => Number(filteredValue.id))

                          this.updateData('preferred_contact', checkedValues)
                        }}
                      />
                    </fieldset>
                  </section>
                </>
              ) : (
                <AddressLookupInput
                  fieldName="address"
                  address={utils.getAddressObject(
                    {
                      value: [
                        'address_line_one',
                        'address_line_two',
                        'address_town',
                        'address_county',
                        'address_postcode',
                        'address_country',
                      ],
                    },
                    data,
                    'address',
                  )}
                  onChange={this.addressItemChanged}
                  validations={{}}
                  layoutRow={2}
                  showError={errors.address}
                />
              )}
            </form>
            <p
              className={`form__label--error ${
                this.state.responseError.length === 0 ? 'hidden' : ''
              }`}
            >
              {this.state.responseError}
            </p>
            <div
              className={`clinicModal__buttonContainer ${
                step === 0 ? 'clinicModal__buttonContainer--right' : ''
              }`}
            >
              {step > 0 ? (
                <button
                  className="buttonTertiary button--wide modal__submit"
                  onClick={() => {
                    this.setState({ step: step - 1 })
                  }}
                >
                  Back
                </button>
              ) : (
                ''
              )}

              <button
                className="buttonSecondary button--wide modal__submit"
                onClick={this.validateForm}
              >
                {this.state.step < 2 ? 'Next' : 'Create patient'}
              </button>
            </div>
          </article>
          <article className="clinicModal__card col__12-3 textCenter">
            <div className="primaryMarginBottom">
              <h3 className="h1 clinicModal__heading">
                Create several patients
              </h3>
            </div>
            <img
              src="../../libs/assets/consult-illustration.svg"
              alt="Bulk patient upload"
              width="100"
            />
            <p className="clinic__card__instruction">
              Import data for several patients by uploading a csv file and
              mapping legacy system data to Clever Clinic fields.
            </p>
            <a
              className="buttonPrimary modal__submit"
              href="/clinics/upload-file"
            >
              Go to patient import
            </a>
          </article>
        </section>
      </article>
    )
  }
}

const mapStateToProps = state => {
  return {
    global: state.global,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_AddPatient,
      },
      dispatch,
    ),
  }
}

FullScreenModal.propTypes = {
  global: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FullScreenModal),
)
