import React from 'react'
import PropTypes from 'prop-types'
import SelectInput from '../Select'
import TextInput from '../Input'
import { getCountries } from 'country-from-iso2'
import {
  getAddress,
  getAddressDetails,
} from '../../../services/ClinicAppointments/Clinic_Setup/ClinicDashboard_01Service'

const formattedCountries = getCountries()
  .map(({ name: { common } }) => ({
    title: common,
    value: common,
  }))
  .sort((a, b) => {
    if (a.title > b.title) {
      return 1
    } else if (a.title < b.title) {
      return -1
    } else {
      return 0
    }
  })

class AddressLookupInput extends React.Component {
  constructor(props) {
    super(props)
    const { fieldName, address } = this.props

    const countryKey = `${fieldName}_country`
    const countryValue = address[countryKey]
    if (!countryValue || countryValue === null) {
      address[countryKey] = 'United Kingdom'
    }

    this.state = {
      addressList: [],
      addressValues: address,
      showResults: false,
    }

    this.handleAddressLookup = this.handleAddressLookup.bind(this)
    this.selectRelaventAddress = this.selectRelaventAddress.bind(this)
    this.error = this.error.bind(this)
    this.updateAddress = this.updateAddress.bind(this)

    this.addressLookupInterval = null
  }

  updateAddress(event) {
    const changeObj = this.state.addressValues
    const changeFields = [event.currentTarget.id]
    changeObj[event.currentTarget.id] = event.currentTarget.value
    this.setState(
      {
        addressValues: changeObj,
      },
      () => {
        const { fieldName, onChange } = this.props
        onChange(fieldName, changeFields, this.state.addressValues)
      },
    )
  }

  handleAddressLookup = async value => {
    if (value !== this.lookupAddress && value.length > 0) {
      this.lookupAddress = value

      const addresses = await getAddress(value)

      this.setState({
        addressList: addresses,
        showResults: true,
      })
    }
  }

  selectRelaventAddress = async place_id => {
    const { fieldName, onChange } = this.props
    const addressDetails = await getAddressDetails(place_id)

    let addressObj = {}

    Object.keys(addressDetails).forEach(key => {
      Object.keys(this.state.addressValues).forEach(addressKey => {
        if (addressKey.endsWith(key)) {
          let value = addressDetails[key]
          if (key === 'address_country') {
            const country = formattedCountries
              .map(({ value }) => value)
              .find(country => country === value)
            if (country === undefined) {
              value = 'United Kingdom'
            }
          }

          addressObj[addressKey] = value
        }
      })
    })

    const changeFields = Object.keys(addressObj)

    this.setState(
      {
        addressValues: addressObj,
        showResults: false,
      },
      () => {
        onChange(fieldName, changeFields, this.state.addressValues)
      },
    )
  }

  componentDidMount() {}

  componentDidUpdate() {}

  error = fieldName => {
    const { validations } = this.props

    if (validations[fieldName]) {
      const { isValid, type, showError, message } = validations[fieldName]
      return {
        error: isValid
          ? null
          : type === 'Required'
          ? 'Required'
          : !isValid && showError
          ? 'card'
          : 'Error',
        errorMessage: isValid ? '' : showError ? message : '',
      }
    }

    return {}
  }

  render() {
    const { fieldName } = this.props
    let addressObj = this.state.addressValues

    return (
      <React.Fragment key={`address_${fieldName}`}>
        <div
          className={`generalInfo__card__item--fullWidth generalInfo__card__item--fullWidth-${this
            .props.layoutRow + 2}`}
          style={{ position: 'relative' }}
        >
          <div className={`generalInfo__card__content`}>
            <div className="generalInfo__card__item">
              <TextInput
                type="text"
                id={`${fieldName}_line_one`}
                label="ADDRESS LINE 1"
                value={addressObj[`${fieldName}_line_one`]}
                onBlur={() => {
                  if (this.state.addressList.length === 0) {
                    this.setState({ showResults: false })
                  }
                }}
                onFocus={() => {
                  if (this.state.addressList.length > 0) {
                    this.setState({ showResults: true })
                  }
                }}
                onChange={event => {
                  this.updateAddress(event)

                  if (this.addressLookupInterval) {
                    clearInterval(this.addressLookupInterval)
                  }

                  const value = event.currentTarget.value

                  this.addressLookupInterval = setInterval(() => {
                    this.handleAddressLookup(value)
                  }, 1000)
                }}
              />
              <article
                className={`searchResult__selector searchResult__selector--search ${
                  this.state.showResults ? '' : 'hidden'
                }`}
              >
                <div className="searchResults__scroll">
                  <div className="searchResult__header">
                    <div className="searchResult__row">
                      <span className="form__label">Address Lookup</span>
                    </div>
                    <div className="searchResult__header__spacer" />
                  </div>
                  {this.state.addressList.length > 0 ? (
                    <ul className="searchResult__results">
                      {this.state.addressList.map(
                        ({ place_id, address_description }) => {
                          return (
                            <li key={place_id} className="searchResult__result">
                              <button
                                type="button"
                                className="buttonTransparent searchResult__row searchResult__row--selectable"
                                onClick={() => {
                                  this.selectRelaventAddress(place_id)
                                }}
                              >
                                <span className="searchResult__header__text searchResult__header">
                                  {address_description}
                                </span>
                              </button>
                            </li>
                          )
                        },
                      )}
                    </ul>
                  ) : (
                    <article className="searchResult__row noResults_message noResults_message--centered">
                      <span className="multiline_wordbreak">
                        There are no results matching this search value
                      </span>
                    </article>
                  )}
                </div>
              </article>
            </div>
            <div className="generalInfo__card__item generalInfo__card__item--two">
              <TextInput
                type="text"
                id={`${fieldName}_line_two`}
                label="ADDRESS LINE 2"
                value={addressObj[`${fieldName}_line_two`]}
                onChange={this.updateAddress}
                onFocus={() => {
                  this.setState({ showResults: false })
                }}
                {...this.error(`${fieldName}_line_two`)}
              />
            </div>
            <div className="generalInfo__card__item">
              <TextInput
                type="text"
                id={`${fieldName}_town`}
                label="CITY/TOWN"
                value={addressObj[`${fieldName}_town`]}
                onChange={this.updateAddress}
                onFocus={() => {
                  this.setState({ showResults: false })
                }}
                {...this.error(`${fieldName}_town`)}
              />
            </div>
            <div className="generalInfo__card__item">
              <TextInput
                type="text"
                id={`${fieldName}_county`}
                label="COUNTY"
                value={addressObj[`${fieldName}_county`]}
                onChange={this.updateAddress}
                onFocus={() => {
                  this.setState({ showResults: false })
                }}
                {...this.error(`${fieldName}_county`)}
              />
            </div>
            <div className="generalInfo__card__item">
              <TextInput
                type="text"
                id={`${fieldName}_postcode`}
                label="POSTCODE"
                value={addressObj[`${fieldName}_postcode`]}
                onChange={this.updateAddress}
                tooltipClasses=""
                onFocus={() => {
                  this.setState({ showResults: false })
                }}
                {...this.error(`${fieldName}_postcode`)}
              />
            </div>
            <div className="generalInfo__card__item">
              <SelectInput
                id={`${fieldName}_country`}
                options={formattedCountries}
                defaultSelected={addressObj[`${fieldName}_country`]}
                label="Country"
                onChange={this.updateAddress}
                onFocus={() => {
                  this.setState({ showResults: false })
                }}
                {...this.error(`${fieldName}_country`)}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

AddressLookupInput.defaultProps = {
  fieldName: '',
  address: {},
  layoutRow: 1,
  onChange: () => null,
  validations: {},
  showError: false,
}

AddressLookupInput.propTypes = {
  fieldName: PropTypes.string,
  address: PropTypes.object,
  layoutRow: PropTypes.number,
  onChange: PropTypes.func,
  validations: PropTypes.object,
  showError: PropTypes.bool,
}

export default AddressLookupInput
