import Promise from 'promise'
import * as api from '../api'
import * as url from '../../api_urls'

export const updateAvatar = avatar => {
  return new Promise((resolve, reject) => {
    return api
      .patch2(url.clinicSetup, avatar)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const updateAccountDetails = accountDetails => {
  return new Promise((resolve, reject) => {
    return api
      .patch(url.clinicSetup, accountDetails)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getUpdateAccountDetails = () => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.clinicSetup)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
