import React from 'react'
import PropTypes from 'prop-types'
//import * as func from '../../../utilities/ReusableFunctions'

const LegacyDataCard = ({ id, title, value, onClick, ...props }) => { //eslint-disable-line
  return (
    <React.Fragment>
      <article className="generalInfo__card legacyData__card">
        <div className="generalInfo__card__header">
          <h4 className="form__label form__label--inline form__label--inline-flex">
            {title}
          </h4>
          <span /* eslint-disable-line */
            data-itemid={id}
            className="icon-cross buttonCircle"
            onClick={onClick}
          />
        </div>
        <p className="disabledFormEntry noMargin">{value}</p>
      </article>
    </React.Fragment>
  )
}

LegacyDataCard.defaultProps = {
  id: '0',
  title: '',
  value: '',
  onClick: () => {},
}

LegacyDataCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  onClick: PropTypes.func,
}

export default LegacyDataCard
