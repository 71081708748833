import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import PaymentSidebar from '../../../components/PatientFinance/PaymentModal/Sidebar'
import PaymentLogger from '../../../components/PatientFinance/PaymentModal/PaymentLogger'
import PaymentViewer from '../../../components/PatientFinance/PaymentModal/PaymentViewer'

import ModalHeader from '../../../components/Modal/Header'
import ModalFooter from '../../../components/Modal/Footer'

import {
  createPayment,
  getPayments,
} from '../../../services/PatientFinance/InvoiceTimeLineService'

import { A_GetPatientBillList, A_GetPatientDetail } from '../../../actions'
import moment from 'moment'
import { formatCurrency } from '../../../utilities/ReusableFunctions'

const PaymentModal = ({
  type,
  patient,
  treatment,
  clinics,
  clinicians,
  rightButtons,
  paymentError,
  setPaymentRes,
  togglePaymentModal,
}) => {
  const [activeLocationId, setLocationId] = useState(treatment.locationId)
  const [payments, setPayments] = useState([])
  const [billId, setBillId] = useState(null)

  const dispatch = useDispatch()
  const clinicId = useSelector(
    ({ global: { currentClinicID } }) => currentClinicID,
  )

  useEffect(() => {
    async function fetchPayments() {
      if (type === 'view') {
        const fetchedPayments = await getPayments({
          clinicId: Number(activeLocationId),
          patientId: Number(patient.id),
          billId: Number(treatment.id),
        })
        setPayments(fetchedPayments)
        setBillId(fetchedPayments.length ? fetchedPayments[0].id : null)
      }
    }
    fetchPayments()
  }, [])

  const clinicName = treatment.location

  const activePayment = payments.find(({ id }) => id === billId) || {}

  const formattedClinics = clinics.map(({ id, name }) => ({
    id,
    title: name,
  }))

  const formattedClinicians = clinicians.map(
    ({ clinician: { id, first_name, last_name } }) => ({
      id,
      title: `${first_name} ${last_name}`,
    }),
  )

  const formattedPayments = payments.map(
    ({ id, amount, date_of_payment, clinic }) => ({
      id,
      title: `${formatCurrency(amount, clinic.locale)} on ${moment(
        date_of_payment,
      ).format('DD.MM.YYYY')}`,
    }),
  )

  const processPayment = async e => {
    e.preventDefault()
    const {
      target: {
        amount: { value: amount },
        cardType: { value: cardType },
        paymentType: { value: paymentType },
        clinician: { value: clinicianId },
        associated: { value: associatedId },
        location: { value: locationId },
        date: { value: date },
        notes: { value: notes },
      },
    } = e
    const paymentDetails = {
      amount: Number(amount),
      notes: notes || null,
      date_of_payment: date
        .split('/')
        .reverse()
        .join('-'),
      payment_type: Number(paymentType),
      card_type: Number(cardType),
      clinic: {
        id: Number(locationId),
      },
      taken_by: {
        id: Number(clinicianId),
      },
      associated_to:
        associatedId !== ''
          ? {
              id: Number(associatedId),
            }
          : null,
    }
    togglePaymentModal(false)
    const payment = await createPayment({
      clinicId: Number(locationId),
      patientId: Number(patient.id),
      billId: Number(treatment.id),
      paymentDetails,
    })
    handlePaymentTypeValidation(payment)
    refreshInvoicesAndTotal()
  }

  const handlePaymentTypeValidation = payment => {
    setPaymentRes(null)
    setPaymentRes(payment)
  }

  const refreshInvoicesAndTotal = () => {
    dispatch(
      A_GetPatientBillList({
        clinicID: clinicId,
        patientID: patient.id,
        paid: true,
        unpaid: true,
        refunded: true,
      }),
    )
    dispatch(A_GetPatientDetail(clinicId, patient.id))
  }

  const paymentViewer = type =>
    ({
      create: (
        <PaymentLogger
          clinics={formattedClinics}
          locationId={activeLocationId}
          clinicians={formattedClinicians}
          setLocationId={setLocationId}
          paymentError={paymentError}
        />
      ),
      view: (
        <PaymentViewer
          payments={formattedPayments}
          activePayment={activePayment}
          setBillId={setBillId}
        />
      ),
    }[type])

  const modalStyle = {
    maxHeight: `calc(${type === 'view' ? '100vh - 8rem' : '100vh - 13rem'})`,
  }

  return ReactDOM.createPortal(
    <div className="payment-modal--overlay">
      <div className="payment-modal fadeInFlyAnimate">
        <form onSubmit={e => processPayment(e)}>
          <ModalHeader
            title={type === 'create' ? 'Log a payment' : 'View payments'}
            closeHandler={() => togglePaymentModal(false)}
          />
          <div className="modal__body payment-content" style={modalStyle}>
            <PaymentSidebar
              patient={patient}
              treatment={treatment}
              clinicName={clinicName}
            />
            {paymentViewer(type)}
          </div>
          {type === 'create' && <ModalFooter rightButtons={rightButtons} />}
        </form>
      </div>
    </div>,
    document.getElementById('root-modal'),
  )
}

PaymentModal.defaultProps = {
  rightButtons: [
    {
      type: 'submit',
      style: 'secondary',
      label: 'Confirm payment',
      size: 'small',
    },
  ],
  paymentError: '',
}

PaymentModal.propTypes = {
  rightButtons: PropTypes.array,
  patient: PropTypes.object.isRequired,
  treatment: PropTypes.object.isRequired,
  clinics: PropTypes.array.isRequired,
  clinicians: PropTypes.array.isRequired,
  paymentError: PropTypes.string,
  togglePaymentModal: PropTypes.func.isRequired,
}

export default PaymentModal
