import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  A_GetClinicsList,
  A_GetPatientDetail,
  A_GetPatientMedicalHistoryOptionsList,
} from '../../actions'
import * as utils from '../../utilities/ReusableFunctions'
import PatientCard from '../../components/Patient/Cards'
import FeedbackNotification from '../../components/Feedback/FeedbackNotification'
import Loader from '../../components/PatientData/Loader'
import FeedbackStrap from '../../components/Feedback/FeedbackStrap'
import { TutorialContext } from '../../App'
import TutorialBtn from '../../containers/Tutorials/TutorialBtn'
import { isTutorialFeatureEnabled } from '../../utilities/featureToggle'

class PatientMedicalHistory extends Component {
  constructor(props) {
    super(props)

    this.state = {
      patient: null,
      duplicateError: false,
      responseError: '',
      isLoading: true,
      isClinicUnavailable: false,
      medicalNeedUpdate: false,
    }
    this.renderMedicalCards = this.renderMedicalCards.bind(this)
    this.getInitialPatientData = this.getInitialPatientData.bind(this)
  }

  componentDidMount = () => {
    const { actions } = this.props

    actions.A_GetClinicsList().then(() => {
      const { global } = this.props

      const { patientId } = this.props.match.params
      const clinic = global.currentClinicID

      if (clinic) {
        actions.A_GetPatientMedicalHistoryOptionsList(clinic)
        actions.A_GetPatientDetail(clinic, patientId).then(() => {
          this.getInitialPatientData()
        })
      } else {
        this.setState(() => ({
          isLoading: false,
          isClinicUnavailable: true,
        }))
      }
    })
  }

  getInitialPatientData() {
    const { patient } = this.props
    this.setState(() => ({
      patient: patient,
      isLoading: false,
    }))
  }

  renderMedicalCards() {
    const { global, medicalHistory } = this.props
    let clinic = global.currentClinicID

    let patientDetails = this.state.patient
    if (patientDetails && patientDetails.medical_history) {
      const history = utils.sortedMedicalHistory(patientDetails.medical_history)
      let cardList = medicalHistory.map(card => {
        return (
          <PatientCard
            key={`card_${card.id}`}
            patient={this.props.patient}
            title={card.name}
            placeholder={card.placeholder}
            color={card.color}
            icon={card.icon}
            list={history.filter(({ type }) => type === card.id)}
            label={card.label}
            clinic={clinic}
            error={this.renderError()}
          />
        )
      })
      return cardList
    }
  }

  renderError(state, message) {
    if (state) {
      this.setState({
        duplicateError: state,
        responseError: message,
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <main className="main">
          {this.state.medicalNeedUpdate ? (
            <FeedbackStrap
              isAlert={true}
              hasMargin={false}
              hasOffset={true}
              message="Medical history outdated, login on iPad to update"
            />
          ) : (
            <FeedbackStrap
              isAlert={false}
              hasMargin={false}
              hasOffset={true}
              message="Please confirm with your patient that the following details are
              up-to-date."
            />
          )}
          <section className="main__inner main__inner--spacedAlways">
            {this.state.isLoading ? (
              <Loader />
            ) : this.state.isClinicUnavailable ? (
              ''
            ) : (
              <React.Fragment>
                {this.renderMedicalCards()}
                {this.state.duplicateError ? (
                  <FeedbackNotification
                    id="warningMessageMedical"
                    message={this.state.responseError}
                    isWarning={true}
                    hideFeedback={() => {}}
                  />
                ) : (
                  ''
                )}
              </React.Fragment>
            )}
          </section>
          {isTutorialFeatureEnabled ? (
            <TutorialContext.Consumer>
              {({
                toggleTutorial,
                setTutorial,
                tutorialList,
                history,
                activeTutorial,
              }) => (
                <TutorialBtn
                  tutorialList={tutorialList}
                  toggleTutorial={toggleTutorial}
                  setTutorial={setTutorial}
                  activeTutorial={activeTutorial}
                  history={history}
                />
              )}
            </TutorialContext.Consumer>
          ) : null}
        </main>
      </React.Fragment>
    )
  }
}

PatientMedicalHistory.propTypes = {
  actions: PropTypes.object.isRequired,
  patient: PropTypes.object,
  global: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  medicalHistory: PropTypes.array.isRequired,
}
PatientMedicalHistory.defaultProps = {
  patient: null,
}

/** Need to change this as per need */
const mapStateToProps = state => {
  return {
    patient: state.patients.selectedPatient,
    global: state.global,
    medicalHistory: state.patients.medicalHistorySections,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetClinicsList,
        A_GetPatientDetail,
        A_GetPatientMedicalHistoryOptionsList,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PatientMedicalHistory),
)
