import React from 'react'
import PropTypes from 'prop-types'

import {
  getFullName,
  formatCurrency,
} from '../../../../utilities/ReusableFunctions'

const PaymentSidebar = ({ patient, treatment, clinicName }) => (
  <div className="payment-sidebar">
    <label htmlFor="patient" className="form__label">
      Patient
    </label>
    <p className="disabledFormEntry">{`${getFullName(patient)} (#${
      patient.id
    })`}</p>
    <label htmlFor="treatment" className="form__label">
      Treatment
    </label>
    <p className="disabledFormEntry">{treatment.treatments}</p>
    <label htmlFor="created" className="form__label">
      Invoice Created
    </label>
    <p className="disabledFormEntry">{treatment.date}</p>
    <label htmlFor="total" className="form__label">
      Total Invoiced
    </label>
    <p className="disabledFormEntry">
      {formatCurrency(treatment.paid, treatment.locale)}
    </p>
    <label htmlFor="due" className="form__label">
      Total Due
    </label>
    <p className="disabledFormEntry">
      {formatCurrency(treatment.total, treatment.locale)}
    </p>
    <label htmlFor="clinic" className="form__label">
      Clinic
    </label>
    <p className="disabledFormEntry">{clinicName}</p>
    <label htmlFor="clinician" className="form__label">
      Clinician
    </label>
    <p className="disabledFormEntry">{treatment.doctor}</p>
  </div>
)

PaymentSidebar.propTypes = {
  patient: PropTypes.object.isRequired,
  treatment: PropTypes.object.isRequired,
  clinicName: PropTypes.string.isRequired,
}

export default PaymentSidebar
