import Promise from 'promise'
import * as api from '../api'
import * as url from '../../api_urls'

export const getNotificationsUnread = () => {
  let notificationUrl = url.notifications + 'unread/'

  return new Promise((resolve, reject) => {
    return api
      .get(notificationUrl)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getNotificationList = nextQuery => {
  let notificationUrl = url.notifications
  if (nextQuery) {
    notificationUrl = notificationUrl + '?' + nextQuery
  }
  return new Promise((resolve, reject) => {
    return api
      .get(notificationUrl)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const postNotification = message => {
  return new Promise((resolve, reject) => {
    return api
      .post(url.notifications, message)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const MarkUpdateRead = notificationId => {
  return new Promise((resolve, reject) => {
    return api
      .patch(url.notifications + notificationId + '/read/')
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
