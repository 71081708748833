import React from 'react'
import PropTypes from 'prop-types'
import SelectInput from '../Select'
import dateFns from 'date-fns'
import {
  /*ErrorToolTipCard,*/ errorSpan,
  requiredSpan,
  listError,
} from '../../../containers/OnBoarding/FormErrorValidations'

const defaultYear = dateFns.getYear(dateFns.subYears(new Date(), 40))

class DateOfBirthInput extends React.Component {
  constructor(props) {
    super(props)
    const { currentDay, currentMonth, currentYear } = this.props
    this.currentDay = currentDay || 0
    this.currentMonth = currentMonth || 0
    this.currentYear = currentYear || 0

    let initialDate = new Date(defaultYear, 0, 1)
    if (currentDay && currentMonth && currentYear) {
      initialDate = new Date(currentYear, currentMonth - 1, currentDay)
    }
    this.days = this.getDaysObjects(initialDate)
    this.months = [
      { id: 0, title: 'MM', isDisabled: true },
      { id: 1, title: '01', isDisabled: false },
      { id: 2, title: '02', isDisabled: false },
      { id: 3, title: '03', isDisabled: false },
      { id: 4, title: '04', isDisabled: false },
      { id: 5, title: '05', isDisabled: false },
      { id: 6, title: '06', isDisabled: false },
      { id: 7, title: '07', isDisabled: false },
      { id: 8, title: '08', isDisabled: false },
      { id: 9, title: '09', isDisabled: false },
      { id: 10, title: '10', isDisabled: false },
      { id: 11, title: '11', isDisabled: false },
      { id: 12, title: '12', isDisabled: false },
    ]
    this.years = this.getYearRange()

    this.getDaysObjects = this.getDaysObjects.bind(this)
    this.getYearRange = this.getYearRange.bind(this)
    this.changeDay = this.changeDay.bind(this)
    this.changeMonth = this.changeMonth.bind(this)
    this.changeYear = this.changeYear.bind(this)
    this.onDOBChange = this.onDOBChange.bind(this)
    this.checkSingleDigit = this.checkSingleDigit.bind(this)

    //Initially send the DOB value to the container in case the user doesn't change
    this.onDOBChange()
  }

  getDaysObjects(date) {
    const numDays = dateFns.getDaysInMonth(date) + 1
    let days = [{ id: 0, title: 'DD', isDisabled: true }]
    for (var i = 1; i < numDays; i++) {
      let title = this.checkSingleDigit(i)
      days.push({ id: i, title: title, isDisabled: false })
    }
    return days
  }

  getYearRange() {
    const year = dateFns.getYear(new Date())
    const startYear = year - 125
    let years = [{ id: 0, title: 'YYYY', isDisabled: true }]
    for (var i = startYear; i < year; i++) {
      years.push({ id: i, title: i.toString(), isDisabled: false })
    }
    return years
  }

  changeDay(event) {
    this.currentDay = event.currentTarget.value
    this.onDOBChange()
  }

  changeMonth(event) {
    let dayVal = Number(this.currentDay)
    if (dayVal === 0) {
      dayVal = 1
    }
    let yearVal = Number(this.currentYear)
    if (yearVal === 0) {
      yearVal = 1999
    }
    let dateOfDay = dateFns.parse(
      yearVal +
        '-' +
        this.checkSingleDigit(Number(event.currentTarget.value)) +
        '-' +
        this.checkSingleDigit(dayVal),
    )
    let dateOfMonth = dateFns.parse(
      this.checkSingleDigit(
        yearVal +
          '-' +
          this.checkSingleDigit(Number(event.currentTarget.value)) +
          '-01',
      ),
    )
    while (!dateFns.isSameMonth(dateOfDay, dateOfMonth)) {
      dayVal--
      dateOfDay = dateFns.parse(
        yearVal +
          '-' +
          this.checkSingleDigit(Number(event.currentTarget.value)) +
          '-' +
          this.checkSingleDigit(dayVal),
      )
    }
    const dayRange = this.getDaysObjects(
      dateFns.parse(
        yearVal +
          '-' +
          this.checkSingleDigit(Number(event.currentTarget.value)) +
          '-' +
          this.checkSingleDigit(dayVal),
      ),
    )
    this.days = dayRange
    if (this.currentDay !== dayVal && this.currentDay !== 0) {
      this.currentDay = dayVal.toString()
    }
    this.currentMonth = event.currentTarget.value
    this.onDOBChange()
  }

  changeYear(event) {
    let dayVal = Number(this.currentDay)
    if (dayVal === 0) {
      dayVal = 1
    }
    let monthVal = Number(this.currentMonth)
    if (monthVal === 0) {
      monthVal = 1
    }
    let dateOfDay = dateFns.parse(
      Number(event.currentTarget.value) +
        '-' +
        this.checkSingleDigit(monthVal) +
        '-' +
        this.checkSingleDigit(dayVal),
    )
    let dateOfMonth = dateFns.parse(
      Number(event.currentTarget.value) +
        '-' +
        this.checkSingleDigit(monthVal) +
        '-01',
    )
    while (!dateFns.isSameMonth(dateOfDay, dateOfMonth)) {
      dayVal--
      dateOfDay = dateFns.parse(
        Number(event.currentTarget.value) +
          '-' +
          this.checkSingleDigit(monthVal) +
          '-' +
          this.checkSingleDigit(dayVal),
      )
    }
    const dayRange = this.getDaysObjects(
      dateFns.parse(
        Number(event.currentTarget.value) +
          '-' +
          this.checkSingleDigit(monthVal) +
          '-' +
          this.checkSingleDigit(dayVal),
      ),
    )
    this.days = dayRange
    if (this.currentDay !== dayVal && this.currentDay !== 0) {
      this.currentDay = dayVal.toString()
    }
    this.currentYear = event.currentTarget.value
    this.onDOBChange()
  }

  onDOBChange() {
    const { onChange } = this.props
    let DOB =
      this.currentYear +
      '-' +
      this.checkSingleDigit(Number(this.currentMonth)) +
      '-' +
      this.checkSingleDigit(Number(this.currentDay))
    if (
      Number(this.currentDay) === 0 ||
      Number(this.currentMonth) === 0 ||
      Number(this.currentYear) === 0
    ) {
      DOB = null
    }
    onChange(DOB)
  }

  checkSingleDigit(digit) {
    if (digit < 10) {
      return '0' + digit.toString()
    }
    return digit.toString()
  }

  render() {
    const { error, isRequired } = this.props
    return (
      <React.Fragment>
        <label htmlFor="dob_day" className="form__label">
          {error && error.length > 0
            ? error === 'Error'
              ? errorSpan()
              : error === 'Required'
              ? requiredSpan()
              : error === 'List'
              ? listError()
              : ''
            : ''}
          Date of birth (DD/MM/YYYY)
          {!isRequired ? (
            <span className="form__label--optional"> - optional</span>
          ) : (
            ''
          )}
        </label>
        <div className="dob_container primaryMarginBottom">
          <div className="form__select--inlineMargin">
            <SelectInput
              id="dob_day"
              options={this.days}
              defaultSelected={this.currentDay}
              isInline={true}
              customClasses="form__selectField--auto"
              onChange={this.changeDay}
              hasOwnWrapper={true}
              error={error}
            />
          </div>
          <div className="form__select--inlineMargin">
            <SelectInput
              id="dob_month"
              options={this.months}
              defaultSelected={this.currentMonth}
              isInline={true}
              customClasses="form__selectField--auto"
              onChange={this.changeMonth}
              hasOwnWrapper={true}
              error={error}
            />
          </div>
          <div className="createAppointment--right">
            <SelectInput
              id="dob_year"
              options={this.years}
              defaultSelected={this.currentYear}
              isInline={true}
              customClasses="form__selectField--auto"
              onChange={this.changeYear}
              hasOwnWrapper={true}
              error={error}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

DateOfBirthInput.defaultProps = {
  onChange: () => null,
  currentDay: undefined,
  currentMonth: undefined,
  currentYear: undefined,
  isRequired: true,
}

DateOfBirthInput.propTypes = {
  onChange: PropTypes.func,
  currentDay: PropTypes.number,
  currentMonth: PropTypes.number,
  currentYear: PropTypes.number,
  error: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
}

export default DateOfBirthInput
