import React from 'react'
import PropTypes from 'prop-types'

const TreatmentOptionOverviewItem = ({
  headerText,
  allowEdit,
  numPrices,
  numMetrics,
  handleClickTreatOption,
  numProducts,
  ...props
}) => {
  return (
    <React.Fragment>
      <button
        className="treatmentItem"
        onClick={handleClickTreatOption}
        {...props}
      >
        <span className="treatmentItem__section treatmentItem__header">
          <span className="treatmentItem__header__text singleLine_truncatedText">
            {headerText}
          </span>
          {allowEdit ? (
            <span className="treatmentItem__header__editStatus">
              <span className="icon-lock" />
            </span>
          ) : (
            ''
          )}
          {numPrices < 1 ? (
            <span className="treatmentItem__header__alerts">
              <span className="treatmentItem__alert">
                Missing pricing options
              </span>
            </span>
          ) : (
            ''
          )}
        </span>
        <span className="treatmentItem__section treatmentItem__info">
          <span className="treatmentItem__info__item treatmentItem__info__pricing">
            {numPrices} Pricing option{numPrices === 1 ? '' : 's'}
          </span>
          <span className="treatmentItem__info__item treatmentItem__info__metrics">
            {numMetrics} Treatment metric{numMetrics === 1 ? '' : 's'}
          </span>
          <span className="treatmentItem__info__item treatmentItem__info__products">
            {numProducts} Linked product{numProducts === 1 ? '' : 's'}
          </span>
        </span>
      </button>
    </React.Fragment>
  )
}

TreatmentOptionOverviewItem.defaultProps = {
  headerText: '',
  allowEdit: false,
  numPrices: 0,
  numMetrics: 0,
  numProducts: 0,
  handleClickTreatOption: () => {},
}

TreatmentOptionOverviewItem.propTypes = {
  headerText: PropTypes.string,
  allowEdit: PropTypes.bool,
  numPrices: PropTypes.number,
  numMetrics: PropTypes.number,
  numProducts: PropTypes.number,
  handleClickTreatOption: PropTypes.func,
}

export default TreatmentOptionOverviewItem
