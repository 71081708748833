import React from 'react'
import PropTypes from 'prop-types'

const MainButton = ({
  label,
  type,
  styleType,
  size,
  customClasses,
  margin,
  leftAuto,
  closeHandler,
  clickHandler,
  id,
  isDisabled,
  isDisplayed,
  loading,
  ...props
}) => {
  const buttonClasses = () => {
    let classList = customClasses
    switch (styleType) {
      case 'primary':
        classList += ' buttonPrimary'
        break
      case 'secondary':
        classList += ' buttonSecondary'
        break
      case 'tertiary':
        classList += ' buttonTertiary'
        break
      case 'destructive':
        classList += ' buttonDestructive'
        break
      case 'alert':
        classList += ' buttonAlert'
        break
      default:
        break
    }
    if (size === 'small') {
      classList += ' button--small'
    }
    if (margin === 'right') {
      classList += ' button--marginRight'
    }
    if (leftAuto === true) {
      classList += ' button--autoLeft'
    }
    return classList
  }

  let buttonFunction = clickHandler ? clickHandler : closeHandler

  const { isLoading, loadingText } = loading

  return (
    <>
      {isDisplayed && (
        <button
          type={type}
          id={id}
          className={buttonClasses()}
          onClick={buttonFunction}
          {...props}
          disabled={isDisabled}
        >
          {isLoading ? loadingText : label}
        </button>
      )}
    </>
  )
}

MainButton.defaultProps = {
  label: '',
  type: 'button',
  styleType: '',
  customClasses: '',
  size: 'medium',
  margin: '',
  id: null,
  isDisabled: false,
  leftAuto: false,
  closeHandler: () => {},
  clickHandler: () => {},
  isDisplayed: true,
  loading: {},
}

MainButton.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
  styleType: PropTypes.string,
  customClasses: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  margin: PropTypes.string,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  leftAuto: PropTypes.bool,
  closeHandler: PropTypes.func,
  clickHandler: PropTypes.func,
  isDisplayed: PropTypes.bool,
  loading: PropTypes.object,
}

export default MainButton
