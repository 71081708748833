import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '../../forms/Checkbox'
import BottomBar from '../../Bottombar'

const ImportComplete = props => {
  return (
    <React.Fragment>
      <form className="form patientImport__form">
        <h2 className="h2 bold">Unmatched fields</h2>
        <p className="p">
          Any data not matched with a Clever Clinic column will still be
          imported and stored as ‘Legacy Data’
        </p>
        <Checkbox
          classContainer="checkbox__importRow"
          label={'Select All'}
          defaultChecked={props.defaultChecked}
          handleAllChecked={props.toggleSelectAllChecked} //eslint-disable-line
          isListItem={false}
        />
        <section className="patientImport__block--unmatched">
          <section className="patientImport__column patientImport__column--small">
            <p className="form__label">IMPORT?</p>
            {Object.keys(props.unMatchedrows).map(index => {
              return (
                <Checkbox
                  key={index}
                  id={index}
                  classContainer="checkbox__importRow"
                  isListItem={true}
                  onChange={props.toggleSingleChecked}
                  checked={
                    props.defaultChecked
                      ? true
                      : props.selectedCheck['checked_' + index]
                  }
                />
              )
            })}
          </section>
          <section className="patientImport__column patientImport__column--wide">
            <p className="form__label">COLUMN IN UPLOADED FILE</p>
            {Object.keys(props.unMatchedrows).map(index => {
              return (
                <span
                  key={index}
                  className="form__textField  form__textField--green"
                >
                  {props.unMatchedrows[index]}
                </span>
              )
            })}
          </section>
        </section>
        <br />
        <BottomBar
          bar="two"
          clickHandler={props.importMatchedData} //eslint-disable-line
          cancelHandler={props.cancelImport} //eslint-disable-line
        />
      </form>
    </React.Fragment>
  )
}

ImportComplete.defaultProps = {
  unMatchedrows: [],
  selectedCheck: [],
  defaultChecked: true,
  toggleChecked: true,
  toggleSingleChecked: true,
}

ImportComplete.propTypes = {
  unMatchedrows: PropTypes.array,
  selectedCheck: PropTypes.object,
  defaultChecked: PropTypes.bool,
  toggleChecked: PropTypes.bool, //eslint-disable-line
  toggleSingleChecked: PropTypes.func,
}

export default ImportComplete
