import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Input from '../../../forms/Input'
import Select from '../../../forms/Select'
import TextArea from '../../../forms/Textarea'
import DatePicker from '../../../forms/datePickerInput'
import DatePickerRange from '../../../DatePicker/DatePickerRange'
import {
  PaymentType,
  paymentTypes,
  cardTypes,
} from '../../../../utilities/ReusableObjects'

const PaymentLogger = ({
  clinics,
  locationId,
  clinicians,
  setLocationId,
  paymentError,
}) => {
  const [paymentDate, setPaymentDate] = useState(moment())
  const [paymentType, setPaymentType] = useState(null)
  const [cardType, setCardType] = useState(null)
  const [clinician, setClinician] = useState(clinicians[0].id)
  const [associated, setAssociated] = useState('')

  const [isDateOpen, setDateOpen] = useState(false)
  const paymentDateRef = useRef(null)
  const datePickerRef = useRef(null)

  const showAllClinicians = [{ id: '', title: 'Show all' }].concat(clinicians)

  return (
    <>
      <div className="payment-viewer" ref={datePickerRef}>
        <div className="payment-viewer--col payment-viewer--col-left">
          <label htmlFor="amount" className="form__label">
            Payment amount
          </label>
          <Input id="amount" placeholder="E.g. 1200.00" />
          <label htmlFor="cardType" className="form__label">
            Card type
          </label>
          <Select
            id="cardType"
            options={cardTypes}
            value={cardType}
            onChange={({ target: { value } }) => setCardType(value)}
            disabled={parseInt(paymentType) !== PaymentType.CARD}
          />
          <label htmlFor="taken" className="form__label">
            Payment taken by
          </label>
          <Select
            id="clinician"
            options={clinicians}
            value={clinician}
            onChange={({ target: { value } }) => setClinician(value)}
          />
          <label htmlFor="associated" className="form__label">
            Associated to
            <span className="form__label--optional"> - optional</span>
          </label>
          <Select
            id="associated"
            options={showAllClinicians}
            value={associated}
            onChange={({ target: { value } }) => setAssociated(value)}
          />
        </div>
        <div className="payment-viewer--col payment-viewer--col-right">
          <label htmlFor="paymentType" className="form__label">
            <span style={{ color: 'red' }}>
              {paymentError === 'payment_type' ? 'REQUIRED: ' : ''}
            </span>
            Payment type
          </label>
          <Select
            id="paymentType"
            options={paymentTypes}
            value={paymentType}
            onChange={({ target: { value } }) => setPaymentType(value)}
            customClasses={
              paymentError === 'payment_type' ? 'form__textField--error' : ''
            }
          />
          <label htmlFor="location" className="form__label">
            Location of payment
          </label>
          <Select
            id="location"
            options={clinics}
            value={locationId}
            onChange={({ target: { value } }) => setLocationId(value)}
          />
          <section className="form__textField--has-icon">
            <label htmlFor="date" className="form__label">
              Date of payment
            </label>
            <DatePicker
              id="date"
              classList={`form__textField form__textField--right-icon datePicker__input ${
                isDateOpen ? 'form__textField--active' : null
              }`}
              onClick={() => setDateOpen(!isDateOpen)}
              refVal={paymentDateRef}
              value={paymentDate.format('DD/MM/YYYY')}
            />
          </section>
        </div>
        <hr />
        <div className="payment-viewer--note-container">
          <div className="payment-viewer--notes">
            <TextArea id="notes" label="Notes (optional)" />
          </div>
        </div>
      </div>
      {isDateOpen && (
        <DatePickerRange
          selectedDate={paymentDate.format()}
          dpInput={paymentDateRef.current}
          container={datePickerRef.current}
          calendarType="manage_schedule_range"
          position="leftTop"
          onHide={e => {
            setDateOpen(false)
            setPaymentDate(moment(e))
          }}
        />
      )}
    </>
  )
}

PaymentLogger.defaultProps = {
  paymentError: '',
}

PaymentLogger.propTypes = {
  clinics: PropTypes.array.isRequired,
  locationId: PropTypes.number.isRequired,
  clinicians: PropTypes.array.isRequired,
  setLocationId: PropTypes.func.isRequired,
  paymentError: PropTypes.string,
}

export default PaymentLogger
