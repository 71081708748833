import React from 'react'
import PropTypes from 'prop-types'

const RadioGroup = ({
  type, //eslint-disable-line
  items,
  defaultSelected,
  inline,
  name,
  size,
  handleDiscountType,
  multiselect,
  ...props
}) => {
  let listItems = []
  const listCount = items.length
  for (let i = 0; i < listCount; i++) {
    if (multiselect) {
      listItems.push(
        <li key={i} className="radioButton">
          <input
            name={name}
            id={`${name}_${items[i].id}`}
            data-id={`${items[i].id}`}
            value={items[i].id}
            checked={items[i].checked}
            type={type}
            className="form__radio"
            {...props}
          />
          <label
            className={
              size === 'small'
                ? 'roundedToggleButton roundedToggleButton--small'
                : 'roundedToggleButton'
            }
            id={items[i].id}
            htmlFor={`${name}_${items[i].id}`}
          >
            {items[i].title}
          </label>
        </li>,
      )
    } else {
      let checked = defaultSelected.indexOf(items[i].id) > -1
      listItems.push(
        <li key={i} className="radioButton">
          <input
            name={name}
            id={`${name}_${items[i].id}`}
            value={items[i].id}
            checked={checked}
            type={type}
            onChange={handleDiscountType}
            disabled={items[i].id === props.optionid ? false : props.isdisabled} //eslint-disable-line
            className="form__radio"
            {...props}
          />
          <label
            className={
              size === 'small'
                ? 'roundedToggleButton roundedToggleButton--small'
                : 'roundedToggleButton'
            }
            id={items[i].id}
            htmlFor={`${name}_${items[i].id}`}
          >
            {items[i].title}
          </label>
        </li>,
      )
    }
  }
  return (
    <ul
      className={`radio__group ${inline ? 'radio__group--inline' : ''}`}
      role="group"
    >
      {listItems}
    </ul>
  )
}

RadioGroup.defaultProps = {
  items: [],
  defaultSelected: [],
  inline: false,
  name: '',
  size: '',
  handleDiscountType: null,
  multiselect: false,
}

RadioGroup.propTypes = {
  items: PropTypes.array,
  defaultSelected: PropTypes.array,
  inline: PropTypes.bool,
  name: PropTypes.string,
  size: PropTypes.string,
  handleDiscountType: PropTypes.func,
  multiselect: PropTypes.bool,
}

export default RadioGroup
