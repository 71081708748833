import React from 'react'
import PropTypes from 'prop-types'

import Avatar from '../../../../Avatar'

import {
  getInitials,
  getFullName,
} from '../../../../../utilities/ReusableFunctions'

const Note = ({ paymentUser }) => (
  <div className="payment-viewer--note-container">
    <Avatar
      src={paymentUser.signed_avatar}
      initials={getInitials(paymentUser)}
    />
    <div className="payment-viewer--note">
      <small>
        <strong>{getFullName(paymentUser)}</strong>
        <span style={{ paddingLeft: '0.5rem' }}>{paymentUser.date}</span>
      </small>
      <br />
      {paymentUser.note}
    </div>
  </div>
)

Note.propTypes = {
  paymentUser: PropTypes.object.isRequired,
}

export default Note
