import Promise from 'promise'
import * as api from '../api'
import * as url from '../../api_urls'

export const fetchBookedAppointmentDetails = appointmentID => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.clinics + 'appointments/' + appointmentID + '/')
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const postNewClinicNotes = appointmentNotes => {
  return new Promise((resolve, reject) => {
    return api
      .post(
        url.clinics + 'appointments/' + appointmentNotes.id + '/notes/',
        appointmentNotes,
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const updateBookedAppointment = appointmentData => {
  return new Promise((resolve, reject) => {
    return api
      .patch(
        url.clinics + 'appointments/' + appointmentData.id + '/',
        appointmentData,
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
