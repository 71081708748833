import React from 'react'
import PropTypes from 'prop-types'

class Telephone extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showNumbers: false,
    }

    this.getPhoneNumbers = this.getPhoneNumbers.bind(this)
    this.showPhoneNumbers = this.showPhoneNumbers.bind(this)
  }

  getPhoneNumbers() {
    const { patient } = this.props
    let numbers = []
    if (patient.home_phone) {
      numbers.push({
        title: 'Home',
        value: patient.home_phone,
      })
    }
    if (patient.mobile_phone) {
      numbers.push({
        title: 'Mobile',
        value: patient.mobile_phone,
      })
    }
    if (patient.work_phone) {
      numbers.push({
        title: 'Work',
        value: patient.work_phone,
      })
    }
    return numbers
  }

  showPhoneNumbers() {
    let showNumbers = !this.state.showNumbers
    this.setState({
      showNumbers: showNumbers,
    })
  }

  render() {
    return (
      <div className="telephone__container">
        {this.getPhoneNumbers().length > 0 ? (
          <button
            className="buttonCircle buttonCircle--telephone icon-phone"
            onClick={this.showPhoneNumbers}
          />
        ) : (
          ''
        )}
        {this.state.showNumbers ? (
          <div className="confirm__container">
            <div
              id="phoneNumberAlert"
              className="confirmTooltip confirmTooltip--top-center"
            >
              <ul className="phoneNumber__list">
                {this.getPhoneNumbers().map(number => {
                  return (
                    <li key={number.title} className="phoneNumber__item">
                      <span className="bold phoneNumber__title">
                        {number.title}
                      </span>
                      <span className="text-blue">{number.value}</span>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
}

Telephone.defaultProps = {
  patient: null,
}

Telephone.propTypes = {
  patient: PropTypes.object,
}

export default Telephone
