import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { A_GetCliniciansList } from '../../../actions'
import StaffTable from '../../../components/Staff/StaffTable'
import * as allFunc from '../../../utilities/ReusableFunctions'

class Staff extends Component {
  constructor(props) {
    super(props)

    this.state = {
      staffModalFilter: '',
      filteredClinicians: [],
      clinicians: [],
    }
  }

  componentDidMount() {
    const { actions, global, data } = this.props

    const currentUserID = global.currentUserID

    actions.A_GetCliniciansList().then(response => { //eslint-disable-line
      let staffRows = response.map(function(staff, index) { //eslint-disable-line
        return {
          ...staff.invite,
          clinician: staff,
        }
      })

      if (data.id === 0 && data.invites.length === 0) {
        let adminUser = staffRows.find(function(staff) {
          return staff.clinician.id === currentUserID
        })

        if (adminUser) {
          data.invites = [adminUser]
        }
      }

      this.setState({
        clinicians: staffRows,
      })
    })
  }

  filterClinicianInvite = e => {
    const { data } = this.props

    let staff = this.state.clinicians
    let clinic = data.invites //eslint-disable-line
    let invitedID = []
    let searchValue = ''
    if (e) {
      clinic = clinic.map(invited => {
        return invitedID.push(invited.clinician.id)
      })
      staff = staff.filter(clinicStaff => {
        return !invitedID.includes(clinicStaff.clinician.id)
      })
      searchValue = e.target.value.toLowerCase()
      staff = staff.filter(clinician => {
        return (
          allFunc
            .getFullName(clinician.clinician)
            .toLowerCase()
            .indexOf(searchValue) !== -1 ||
          clinician.clinician.email.toLowerCase().indexOf(searchValue) !== -1
        )
      })
      this.setState({
        filteredClinicians: staff,
        staffModalFilter: searchValue,
      })
    }
  }

  inviteClinician = event => {
    event.preventDefault()
    const { data } = this.props

    let alreadyInvitedStaff = data.invites
    let staffRows = this.state.clinicians
    let invitedStaff = staffRows.find(staff => {
      return staff.id === Number(event.currentTarget.dataset.patient)
    })
    if (invitedStaff) {
      alreadyInvitedStaff.push(invitedStaff)
    }

    data.invites = alreadyInvitedStaff
    this.setState({ staffModalFilter: '' })
  }

  removeClinicianInvite = (event, info) => {
    event.preventDefault()
    const { data } = this.props

    let staff = data.invites.filter(staff => {
      return staff.clinician.id !== info.clinician.id
    })

    data.invites = staff
    this.setState({ staffModalFilter: '' })
  }

  render() {
    const { global, data, toggleInfoModal } = this.props

    return (
      <React.Fragment>
        <h2
          className="h1 gutterMarginBottom"
          id="staff"
        ref="staff" //eslint-disable-line
        >
          Staff
        </h2>
        <article className="form gutterMarginBottom">
          <StaffTable
            isLoading={false}
            VirtualClinic={true}
            isEdit={true}
            staffList={data.invites}
            toggleInfoModal={toggleInfoModal}
            staffModalFilter={this.state.staffModalFilter}
            searchClinicians={this.state.filteredClinicians}
            filterStaff={this.filterClinicianInvite}
            searchClick={e => this.inviteClinician(e)}
            removeStaffRow={(e, data) => this.removeClinicianInvite(e, data)}
            global={global}
          />
        </article>
      </React.Fragment>
    )
  }
}

Staff.defaultProps = {}

Staff.propTypes = {
  actions: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  global: PropTypes.object.isRequired,
  toggleInfoModal: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  return {
    global: state.global,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetCliniciansList,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Staff))
