import * as clinicProfile from '../services/PractitionerProfile/EditPractitionerProfileService'
import * as practitionerProfile from '../services/PractitionerProfile/PractitionerProfileService'
import types from './types'

export const A_VerifyPassword = details => dispatch =>
  clinicProfile
    .verifyPassword(details)
    .then(response => {
      dispatch({ type: types.VALID_PASSWORD.OK, user: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.VALID_PASSWORD.FAIL, error })
      return Promise.reject(error)
    })

export const A_ResetPropValues = () => ({ type: types.RESET_VALUES })

export const A_ResetProfileValues = () => ({ type: types.RESET_PROFILE_VALUES })

export const A_GetClinicianDetails = clinicianId => dispatch =>
  practitionerProfile
    .fetchClinicianDetails(clinicianId)
    .then(response => {
      dispatch({
        type: types.GET_CLINICIAN_DETAILS.OK,
        clinicianDetails: response,
      })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_CLINICIAN_DETAILS.FAIL, error })
      return Promise.reject(error)
    })

export const A_SetTimeSlots = timeArray => ({
  type: types.SET_TIME_SLOT,
  timeArray,
})
