import Promise from 'promise'
import * as api from '../api'
import * as url from '../../api_urls'
import types from '../../actions/types'
import * as setup from '../AccountSetup/AccountSetupService'

export const setToken = token => {
  localStorage.setItem('token', token)
}

export const setUser = user => {
  localStorage.setItem('user', JSON.stringify(user))
}

export const setAccountType = accountType => {
  localStorage.setItem('accountType', accountType)
}

export const getToken = () => localStorage.getItem('token')

export const getAccountType = () => localStorage.getItem('accountType')

export const getUser = dispatch => {
  const user = localStorage.getItem('user')
  if (user && user !== 'null') {
    return JSON.parse(user)
  } else {
    return setup
      .getUpdateAccountDetails()
      .then(response => {
        dispatch({
          type: types.LOGIN.OK,
          token: getToken(),
          user: response,
        })

        setToken(getToken())
        setUser({ token: getToken(), user: response })

        return response
      })
      .catch(error => {
        dispatch({ type: types.GET_USER_DETAILS.FAIL, error })
        return error
      })
  }
}

export const isLoggedIn = () => {
  return getToken()
}

export const forgotPassword = data => {
  return new Promise((resolve, reject) => {
    return api
      .post(url.ForgotPassword, data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getTutorials = () =>
  new Promise((resolve, reject) =>
    api
      .get('/onboarding/tutorial-videos/')
      .then(res => resolve(res.data))
      .catch(err => reject(err)),
  )

export const getTasks = () =>
  new Promise((resolve, reject) =>
    api
      .get('/clinics/tasks/')
      .then(res => resolve(res.data))
      .catch(err => reject(err)),
  )

export const getSupportData = () =>
  new Promise((resolve, reject) =>
    api
      .get('/support/sections/')
      .then(res => resolve(res.data))
      .catch(err => reject(err)),
  )

export const upgradeToken = () =>
  new Promise((resolve, reject) =>
    api
      .post('/onboarding/token/')
      .then(res => resolve(res.data))
      .catch(err => reject(err)),
  )
