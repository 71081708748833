import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PatientFinancial from '../../components/PatientFinance'
import PropTypes from 'prop-types'
import {
  A_GetPatientBillList,
  A_GetPatientConsultationsList,
  A_SetToDefaultValues,
  A_GetPatientDetail,
  A_GetClinicsList,
  A_GetBasicDetails,
  A_DeletePatientInvoice,
} from '../../actions'
import FeedbackNotification from '../../components/Feedback/FeedbackNotification'
import { countryForLocale } from '../../utilities/ReusableFunctions'

export let clinicID
export let patientID

const quickbooksFilters = [
  {
    id: '1',
    label: 'Paid invoices',
    name: 'paid',
    classContainer: 'radioInput',
    checked: true,
  },
  {
    id: '2',
    label: 'Unpaid invoices',
    name: 'unpaid',
    classContainer: 'radioInput',
    checked: true,
  },
  {
    id: '3',
    label: 'Refunded invoices',
    name: 'refund',
    classContainer: 'radioInput',
    checked: true,
  },
  {
    id: '4',
    label: 'No invoice created',
    name: 'not_created',
    classContainer: 'radioInput',
    checked: true,
  },
]

class InvoiceTimeLine extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoadingConsultations: true,
      isLoadingInvoices: true,
      isClinicUnavailable: false,
      clinicList: [],
      feedback: false,
      locale: props.global.currentClinic.locale,
      finances: [],
    }

    this.filterChange = this.filterChange.bind(this)
    this.refresh = this.refresh.bind(this)
    this.addNextData = this.addNextData.bind(this)
  }

  componentDidMount = () => {
    const { patient, actions, history, global } = this.props
    let clinic = global.currentClinicID

    if (parseInt(clinic)) {
      actions.A_GetBasicDetails(clinic)
      if (!patient) {
        const pathItems = history.location.pathname.split('/')
        const patientId = pathItems[pathItems.length - 2]
        actions.A_GetPatientDetail(clinic, patientId).then(() => {
          this.getInitialPatientData(clinic)
        })
      } else {
        this.getInitialPatientData(clinic)
      }
    } else {
      this.setState(() => ({
        isLoading: false,
        isClinicUnavailable: true,
      }))
    }
  }

  getInitialPatientData = clinic => {
    const { patient, actions } = this.props
    clinicID = clinic
    patientID = patient.id
    let Patients_filter = quickbooksFilters

    this.setState(
      {
        filters: Patients_filter,
      },
      () => {
        actions.A_GetClinicsList().then(() => {
          const { global } = this.props

          this.setState(
            {
              clinicList: global.clinics,
              locale: global.currentClinic.locale,
            },
            () => {
              this.refresh(this.state.locale)
            },
          )
        })
      },
    )
  }

  refresh = locale => {
    const { actions } = this.props
    this.setState({
      isLoadingInvoices: true,
      isLoadingConsultations: true,
      locale: locale,
    })

    actions
      .A_GetPatientConsultationsList({
        clinicID: clinicID,
        patientID: patientID,
        locale: locale,
      })
      .then(() => {
        this.setState({ isLoadingConsultations: false })
      })

    actions
      .A_GetPatientBillList({
        clinicID: clinicID,
        patientID: patientID,
        paid: quickbooksFilters[0].checked,
        unpaid: quickbooksFilters[1].checked,
        refunded: quickbooksFilters[2].checked,
        locale: locale,
      })
      .then(() => {
        this.setState({ isLoadingInvoices: false })
      })
  }

  addNextData = () => {
    const { actions, data } = this.props
    actions.A_GetPatientBillList({ nextUrl: data.invoicesNextUrl })
  }

  buildInvoice = e => {
    const { history, data, patient } = this.props
    history.push(
      `/clinics/patients/${patient.id}/finances/invoice/${
        data.consultationsList[e.target.id].id
      }`,
    )
  }

  linkHealthxchange = e => {
    const { history, data } = this.props
    const consultation = data.consultationsList[e.target.id]
    history.push(`/clinics/${consultation.locationId}`)
  }

  buildNewInvoice = () => {
    const { history, patient } = this.props
    history.push(`/clinics/patients/${patient.id}/finances/invoice/new`)
  }

  deleteInvoice = invoice => {
    const { actions } = this.props

    actions
      .A_DeletePatientInvoice({
        clinicID: clinicID,
        patientID: patientID,
        billID: invoice.id,
      })
      .then(() => {
        this.refresh(this.state.locale)
      })
  }

  UNSAFE_componentWillReceiveProps() {
    const { data, actions } = this.props
    if (data.createInvoiceSuccess) {
      this.setState({ feedback: false })
      actions.A_SetToDefaultValues()
    }
    this.timer = setTimeout(() => {
      this.setState({ feedback: false })
    }, 2000)
  }

  filterChange(filters) {
    this.setState({
      filters: filters,
    })

    this.refresh(this.state.locale)
  }
  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = 0
    }
  }

  render() {
    const { patient, data, global, clinicians } = this.props

    const finances =
      patient && patient.finance_information
        ? patient.finance_information.map(finance => {
            return {
              id: finance.locale,
              title: countryForLocale(finance.locale),
            }
          })
        : []

    let clinic = global.currentClinicID

    let showConsultations = this.state.filters
      ? this.state.filters[this.state.filters.length - 1].checked
      : true
    let showTreatments = this.state.filters
      ? this.state.filters[0].checked
      : true
    return (
      <React.Fragment>
        <PatientFinancial
          patient={patient}
          buildInvoice={this.buildInvoice}
          filters={this.state.filters}
          filterChange={this.filterChange}
          treatments={showTreatments ? data.invoicesList : []}
          consultations={showConsultations ? data.consultationsList : []}
          nextUrl={data.invoicesNextUrl}
          nextCall={this.addNextData}
          isLoading={
            this.state.isLoadingConsultations || this.state.isLoadingInvoices
          }
          userPrimaryClinic={clinic}
          isClinicUnavailable={this.state.isClinicUnavailable}
          clinicList={this.state.clinicList}
          addNewInvoice={this.buildNewInvoice}
          linkHealthxchange={this.linkHealthxchange}
          clinicians={clinicians.invites}
          locale={this.state.locale}
          finances={finances}
          refresh={this.refresh}
          deleteInvoice={this.deleteInvoice}
        />

        {this.state.feedback ? (
          <FeedbackNotification
            id="feedback__cancellation"
            message={'Invoice created successfully'}
          />
        ) : (
          ''
        )}
      </React.Fragment>
    )
  }
}

InvoiceTimeLine.defaultProps = {
  patient: null,
}

InvoiceTimeLine.propTypes = {
  patient: PropTypes.object,
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  global: PropTypes.object.isRequired,
  clinicians: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  patient: state.patients.selectedPatient,
  data: state.finance,
  global: state.global,
  clinicians: state.global.currentClinic || {},
})

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetPatientBillList,
        A_GetPatientConsultationsList,
        A_SetToDefaultValues,
        A_GetPatientDetail,
        A_GetClinicsList,
        A_GetBasicDetails,
        A_DeletePatientInvoice,
      },
      dispatch,
    ),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InvoiceTimeLine),
)
