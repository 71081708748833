import React from 'react'
import PropTypes from 'prop-types'
import CheckboxList from '../../forms/CheckboxList'
import MainButton from '../../Buttons'
import SelectInput from '../../forms/Select'

const SubTopBar = ({
  buttonSet,
  filterSet,
  header,
  data,
  stage,
  showInvoiceTimeLine,
  wrapperClasses,
  locale,
  finances,
  refresh,
  ...props
}) => {
  const getButtons = () => {
    const buttons = buttonSet.map((button, index) => {
      let buttonIndex = index
      return (
        <MainButton
          key={buttonIndex}
          type={button.type}
          styleType={button.style}
          label={button.label}
          size={button.size}
          customClasses={
            index < buttonSet.length - 1 ? 'modal--spacedButton--left' : ''
          }
          {...button.events}
        />
      )
    })
    return <article className="searchHeaderActions">{buttons}</article>
  }

  const displayLocales = () => {
    return (
      <article className="searchHeaderField">
        <SelectInput
          label="locale"
          options={finances}
          defaultSelected={locale}
          onChange={e => {
            refresh(e.target.value)
          }}
        />
      </article>
    )
  }

  const getFilters = () => {
    return (
      <article className="searchHeaderFilter">
        <CheckboxList
          type="checkbox"
          inline={true}
          checkboxes={filterSet}
          onChange={props.onFilterChange}
        />
      </article>
    )
  }
  const wrapperClass = wrapperClasses

  return (
    <React.Fragment>
      <section className={wrapperClass}>
        {stage === 'invoice' ? (
          <section className="invoiceHeader">
            <div className="subTopBar__icon">
              <span className="icon-arrow " />
            </div>
            <a
              role="button"
              style={{ cursor: 'pointer' }}
              href={showInvoiceTimeLine()}
            >
              Back
            </a>
            <h3 className="h3">{header}</h3>
            <p>{data ? data.date : ''}</p>
            <p>{data ? data.appointmentLength : ''}</p>
            <p>{data ? data.doctor : ''}</p>
            <p>{data ? data.location : ''}</p>
          </section>
        ) : (
          ''
        )}
        {finances.length > 0 ? displayLocales() : ''}
        {filterSet.length > 0 ? getFilters() : ''}
        {buttonSet.length > 0 ? getButtons() : ''}
      </section>
    </React.Fragment>
  )
}

SubTopBar.defaultProps = {
  buttonSet: [],
  filterSet: [],
  wrapperClasses: 'subTopBar__wrapper',
  isSearchBar: true,
  header: '',
  stage: '',
  data: null,
  showInvoiceTimeLine: () => {},
  finances: [],
  refresh: () => {},
  onFilterChange: () => {},
}

SubTopBar.propTypes = {
  buttonSet: PropTypes.array,
  filterSet: PropTypes.array,
  wrapperClasses: PropTypes.string,
  isSearchBar: PropTypes.bool,
  header: PropTypes.string,
  stage: PropTypes.string,
  showInvoiceTimeLine: PropTypes.func,
  data: PropTypes.object,
  onFilterChange: PropTypes.func,
  locale: PropTypes.string.isRequired,
  finances: PropTypes.array,
  refresh: PropTypes.func,
}

export default SubTopBar
