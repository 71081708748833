import React from 'react'
import PropTypes from 'prop-types'
import RadioGroup from '../RadioGroup'
import {
  errorSpan,
  requiredSpan,
  listError,
} from '../../../containers/OnBoarding/FormErrorValidations'

const MultiSelectInput = ({
  id,
  label,
  placeholder,
  error,
  isRequired,
  disabled,
  options,
  containerClasses,
  selectorClasses,
  showOptions,
  closeSelector,
  saveSelectorData,
  showSelector,
  onOptionChange,
  isDynamic,
}) => {
  const labelEl =
    label.length > 0 ? (
      <label className="form__label" htmlFor={id}>
        {error && error.length > 0
          ? error === 'Error'
            ? errorSpan()
            : error === 'Required'
            ? requiredSpan()
            : error === 'List'
            ? listError()
            : ''
          : ''}
        {label}{' '}
        {!isRequired ? (
          <span className="form__label--optional"> - optional</span>
        ) : (
          ''
        )}
      </label>
    ) : (
      ''
    )

  const getSelectedOptions = () => {
    const selected = options
      .filter(option => {
        return option.checked === 'checked'
      })
      .map(checkedOption => {
        return checkedOption.title
      })
    const isAllChecked = options.every(({ checked }) => checked === 'checked')
    const isAllUnchecked = options.every(({ checked }) => checked === '')
    return isAllChecked && options.length
      ? 'All options selected'
      : isAllUnchecked && options.length
      ? 'No options selected'
      : selected.join(', ')
  }

  const saveData = () => {
    saveSelectorData()
  }

  return (
    <React.Fragment>
      <article className={containerClasses}>
        {labelEl}
        <input
          className={`${error ? 'form__textField--error' : ''} form__textField`}
          type="text"
          placeholder={placeholder}
          value={getSelectedOptions()}
          id={id}
          readOnly="readonly"
          disabled={disabled}
          onClick={showOptions}
        />
      </article>
      {showSelector ? (
        <React.Fragment>
          <article className={`searchResult__selector ${selectorClasses}`}>
            <div className="radio__group--selector radio__group--footer-border">
              <RadioGroup
                type="checkbox"
                items={options}
                name={id}
                inline={true}
                multiselect={true}
                onChange={onOptionChange}
              />
            </div>
            <section className="modal__footer modal__footer--selector modal__footer--spacedButtons">
              <div className="footer__multipleButtonContainer">
                <button
                  type="button"
                  className="buttonTertiary button--small modal--spacedButton--left modal_footer_button modal__cancel--treatment"
                  onClick={closeSelector}
                >
                  {isDynamic ? 'Close' : 'Cancel'}
                </button>
                {!isDynamic && (
                  <button
                    type="button"
                    className="buttonSecondary button--small modal_footer_button modal__save--treatment"
                    onClick={saveData}
                  >
                    Save
                  </button>
                )}
              </div>
            </section>
          </article>
        </React.Fragment>
      ) : (
        ''
      )}
    </React.Fragment>
  )
}

MultiSelectInput.defaultProps = {
  id: '',
  label: '',
  placeholder: '',
  error: '',
  isRequired: true,
  disabled: false,
  containerClasses: '',
  selectorClasses: '',
  options: [],
  onOptionChange: () => {},
  showOptions: () => {},
  closeSelector: () => {},
  saveSelectorData: () => {},
  showSelector: false,
  isDynamic: false,
}

MultiSelectInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  containerClasses: PropTypes.string,
  selectorClasses: PropTypes.string,
  options: PropTypes.array,
  onOptionChange: PropTypes.func,
  showOptions: PropTypes.func,
  closeSelector: PropTypes.func,
  saveSelectorData: PropTypes.func,
  showSelector: PropTypes.bool,
  isDynamic: PropTypes.bool,
}

export default MultiSelectInput
