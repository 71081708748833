import React from 'react'
import PropTypes from 'prop-types'
import { TutorialContext } from '../../App'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  A_GetCliniciansList,
  A_GetAllClinicsList,
  A_PatchClinicInvite,
  A_PatchClinicianInvite,
} from '../../actions'
import StaffTable from './StaffTable'
import ClinicTable from './ClinicTable'
import Modal from '../Modal/'
import ModalInfo from '../Modal/Info'
import * as func from '../../utilities/ReusableFunctions'
import FeedbackNotification from '../Feedback/FeedbackNotification'
import AlertModal from '../Modal/Alert'
import TutorialBtn from '../../containers/Tutorials/TutorialBtn'
import { validateEmailAddress } from '../../containers/_/Validators'
import { isTutorialFeatureEnabled } from '../../utilities/featureToggle'

const uuidv4 = require('uuid/v4')

class StaffBlock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      isEdit: false,
      showInfoModal: false,
      showClinicModal: false,
      clinicians: [],
      backupClinicians: [],
      clinics: [],
      filteredClinicians: [],
      clinicModal: [],
      staffFilter: [],
      staffModalFilter: '',
      clinicianFilter: '',
      clinicInviteStaff: [],
      companyDetails: {},
      currentClinic: 0,
      feedbackShow: false,
      companyNameError: false,
      emailError: false,
      showAlert: false,
      errorMessage: '',
    }
  }

  componentDidMount() {
    const { actions } = this.props

    actions.A_GetAllClinicsList().then(response => {
      let staffFilter = response.map(function(staff) {
        return { id: staff.id, searchValue: '' }
      })
      this.loadClinicians({ clinics: response, staffFilter: staffFilter })
    })
  }

  formattedStaff = response => {
    let staffRows = func.sortClinicians(response).map(function(clinician) {
      return {
        ...clinician.invite,
        clinician: clinician,
        tempID: uuidv4(),
      }
    })

    let account_owner = staffRows.find(function(clinician) {
      return clinician.account_owner
    })

    let companyDetails = {}

    if (account_owner) {
      let staff = account_owner.clinician
      if (staff.account) {
        let website = staff.account.company_website

        if (website) {
          website = website.replace(/(^\w+:|^)\/\//, '')
        }

        companyDetails = {
          company_name: staff.account.company_name,
          company_website: website,
        }
      }
    }

    return {
      clinicians: staffRows,
      backupClinicians: staffRows,
      companyDetails: companyDetails,
      isLoading: false,
    }
  }

  loadClinicians = stateChanges => {
    const { actions } = this.props

    actions.A_GetCliniciansList().then(response => {
      let formatted = this.formattedStaff(response)

      this.setState({
        ...stateChanges,
        ...formatted,
      })
    })
  }

  toggleEdit = () => {
    this.loadClinicians({ isEdit: !this.state.isEdit, feedbackShow: false })
  }

  closeErrorAlert = () => {
    this.setState(() => ({
      showAlert: false,
      errorMessage: '',
    }))
  }

  filterStaff = e => {
    let staff = this.state.backupClinicians
    let searchValue = ''
    if (e) {
      searchValue = e.target.value.toLowerCase()
      staff = staff.filter(staff => {
        return (
          func
            .getFullName(staff.clinician)
            .toLowerCase()
            .indexOf(searchValue) !== -1 ||
          staff.clinician.email.toLowerCase().indexOf(searchValue) !== -1
        )
      })
      this.setState({ clinicians: staff, clinicianFilter: searchValue })
    }
  }

  filterClinicStaff = (e, data) => {
    let staff = data.invites
    let searchValue = ''
    let filter = this.state.staffFilter
    let filterValue = filter.filter(searchBar => {
      return searchBar.id === data.id
    })[0]
    filterValue.searchValue = e.target.value
    if (e) {
      searchValue = e.target.value.toLowerCase()
      staff = staff.filter(invite => {
        return (
          func
            .getFullName(invite.clinician)
            .toLowerCase()
            .indexOf(searchValue) !== -1 ||
          invite.clinician.email.toLowerCase().indexOf(searchValue) !== -1
        )
      })
      this.setState({ filteredClinicians: staff, staffFilter: filter })
    }
  }

  filterClinicianInvite = e => {
    let staff = this.state.backupClinicians
    let clinic = this.state.clinicModal //eslint-disable-line
    let invitedID = []
    let searchValue = ''
    if (e) {
      clinic = clinic.map(invited => {
        return invitedID.push(invited.clinician.id)
      })
      staff = staff.filter(clinicStaff => {
        return !invitedID.includes(clinicStaff.clinician.id)
      })
      searchValue = e.target.value.toLowerCase()
      staff = staff.filter(row => {
        return (
          func
            .getFullName(row.clinician)
            .toLowerCase()
            .indexOf(searchValue) !== -1 ||
          row.clinician.email.toLowerCase().indexOf(searchValue) !== -1
        )
      })
      this.setState({
        filteredClinicians: staff,
        staffModalFilter: searchValue,
      })
    }
  }

  toggleInfoModal = () => {
    this.setState({
      showInfoModal: !this.state.showInfoModal,
    })
  }

  toggleClinicModal = data => {
    let staffRows = []
    let clinic = data
    if (clinic) {
      staffRows = clinic.invites.map(function(staff) {
        return {
          ...staff.clinician.invite,
          ...staff,
        }
      })
    }
    this.setState({
      showClinicModal: !this.state.showClinicModal,
    })
    if (!this.state.showClinicModal) {
      this.setState({
        clinicModal: staffRows,
        currentClinic: clinic.id,
        clinicName: clinic.name,
      })
    } else if (this.state.showClinicModal) {
      this.setState({
        clinicModal: [],
        currentClinic: 0,
        staffModalFilter: '',
        clinicName: '',
      })
    }
  }

  addNewRow = e => {
    e.preventDefault()
    if (e) {
      let newRow = {
        id: 0,
        status: 0,
        account_owner: false,
        is_admin: false,
        clinician: {
          email: '',
          user_type: 2,
          job_type: 7,
        },
        tempID: uuidv4(),
      }
      this.setState({
        clinicians: [...this.state.clinicians, newRow],
      })
    }
  }

  removeStaffRow = (e, data) => {
    e.preventDefault()
    if (e) {
      this.setState({
        clinicians: this.state.clinicians.filter(function(row) {
          return row.tempID !== data.tempID
        }),
      })
    }
  }

  inviteClinician = event => {
    event.preventDefault()
    let alreadyInvitedStaff = this.state.clinicModal
    let staffRows = this.state.backupClinicians
    let invitedStaff = staffRows.find(staff => {
      return staff.id === Number(event.currentTarget.dataset.patient)
    })
    if (invitedStaff) {
      alreadyInvitedStaff.push(invitedStaff)
    }

    this.setState({ clinicModal: alreadyInvitedStaff, staffModalFilter: '' })
  }

  removeClinicianInvite = (event, data) => {
    event.preventDefault()
    let clinicStaff = this.state.clinicModal
    let tempClinicStaff = clinicStaff.filter(staff => {
      return staff.clinician.id !== data.clinician.id
    })
    this.setState({ clinicModal: tempClinicStaff })
  }

  submitInvite = e => {
    const { actions } = this.props
    let invites = []
    let temp = []
    let clinic = this.state.clinicModal
    let id = this.state.currentClinic
    let newInvites = clinic.map((invite, index) => { //eslint-disable-line
      temp.push({
        clinician: {
          id: invite.clinician.id,
          email: invite.clinician.email,
          user_type: invite.clinician.user_type,
          job_type: invite.clinician.job_type,
        },
      })
    })
    invites.push({
      invites: temp,
    })
    if (e) {
      actions.A_PatchClinicInvite(id, invites).then(response => { //eslint-disable-line
        this.setState({
          showClinicModal: false,
          clinicModal: [],
          currentClinic: 0,
        })
        actions.A_GetAllClinicsList().then(response => { //eslint-disable-line
          let staffFilter = response.map(function(staff, index) { //eslint-disable-line
            return { id: staff.id, searchValue: '' }
          })
          this.setState({ clinics: response, staffFilter: staffFilter })
        })
      })
    }
  }

  onChangeStaffRows = (e, id) => {
    let staffRows = this.state.clinicians
    let tempCompDetails = this.state.companyDetails
    if (e) {
      let newStaffRows = staffRows.map((row, index) => { //eslint-disable-line
        let emailInputValue = row.clinician.email
        let roleTypeValue = row.clinician.user_type
        let jobTypeValue = row.clinician.job_type
        if (e.target.id === 'clinicGroupName') {
          tempCompDetails.company_name = e.target.value
        } else if (e.target.id === 'clinicGroupWebsite') {
          tempCompDetails.company_website = e.target.value
        }
        if (row.tempID === id) {
          if (e.target.id === 'staffEmail_' + id) {
            emailInputValue = e.target.value
            row.clinician.email = emailInputValue
          } else if (e.target.id === 'staffRole_' + id) {
            roleTypeValue = parseInt(e.target.value)
            if (roleTypeValue === 2) {
              row.clinician.user_type = roleTypeValue
              row.clinician.job_type = 7
            } else if (roleTypeValue === 1) {
              row.clinician.user_type = roleTypeValue
              row.clinician.job_type = 0
            }
          } else if (e.target.id === 'jobRole_' + id) {
            jobTypeValue = e.target.value
            row.clinician.job_type = jobTypeValue
          } else if (e.target.id === 'adminRole_' + id) {
            row.is_admin = !row.is_admin
          }
        }

        return row
      })
      this.setState({
        clinicians: newStaffRows,
        companyDetails: tempCompDetails,
      })
    }
  }

  validateStaffDetails = () => {
    const { actions } = this.props
    let { clinicians, companyDetails } = this.state

    clinicians = clinicians.filter(function(invite) {
      return invite.clinician.email.length > 0
    })

    const company_website = func.isUrlValid(companyDetails.company_website)
    const company_name = companyDetails.company_name

    if (company_name === null || company_name.length === 0) {
      this.setState({ companyNameError: true })
      return
    }

    let error = clinicians.find(function(invite) {
      return !validateEmailAddress(invite.clinician.email).valid
    })

    if (error) {
      this.setState({ emailError: true })
      return
    }

    this.setState({ isLoading: true }, () => {
      actions
        .A_PatchClinicianInvite({
          company_name,
          company_website,
          invites: clinicians,
        })
        .then(response => {
          if (response.data && response.data.non_field_errors) {
            this.setState({
              showAlert: true,
              errorMessage: response.data.non_field_errors,
            })
          } else {
            this.loadClinicians({
              isEdit: false,
              feedbackShow: true,
              companyNameError: false,
              emailError: false,
            })
          }
        })
    })
  }

  hideFeedback = () => this.setState({ feedbackShow: false })

  render() {
    const { global } = this.props

    return (
      <React.Fragment>
        <main className="main">
          {isTutorialFeatureEnabled ? (
            <TutorialContext.Consumer>
              {({
                toggleTutorial,
                setTutorial,
                tutorialList,
                history,
                activeTutorial,
              }) => (
                <TutorialBtn
                  tutorialList={tutorialList}
                  toggleTutorial={toggleTutorial}
                  setTutorial={setTutorial}
                  activeTutorial={activeTutorial}
                  history={history}
                />
              )}
            </TutorialContext.Consumer>
          ) : null}
          <section className="main__inner">
            <article className="col__12-8 fadeInFlyAnimate clinicForm--feedbackStrap">
              <StaffTable
                isLoading={this.state.isLoading}
                clinicHeadingData={this.state.companyDetails}
                staffList={this.state.clinicians}
                toggleEdit={e => this.toggleEdit(e)}
                isEdit={this.state.isEdit}
                toggleInfoModal={() => this.toggleInfoModal()}
                isClinicView={false}
                staffFilter={this.state.clinicianFilter}
                filterStaff={this.filterStaff}
                addNewRow={e => this.addNewRow(e)}
                removeStaffRow={(e, data) => this.removeStaffRow(e, data)}
                onChangeStaffRows={(e, id) => this.onChangeStaffRows(e, id)}
                submitEdit={() => this.validateStaffDetails()}
                companyNameError={this.state.companyNameError}
                emailError={this.state.emailError}
                global={global}
              />
              <div className="clinicTables__container">
                {this.state.clinics.map((clinic, index) => {
                  return (
                    <ClinicTable
                      key={index} //eslint-disable-line
                      clinicDetails={clinic}
                      toggleClinicModal={this.toggleClinicModal}
                      filterStaff={this.filterClinicStaff}
                      staffFilter={
                        this.state.staffFilter.filter(staff => {
                          return staff.id === clinic.id
                        })[0].searchValue
                      }
                      filteredClinicians={this.state.filteredClinicians}
                      clinicHeader={clinic}
                      global={global}
                    />
                  )
                })}
              </div>
            </article>
            {this.state.feedbackShow ? (
              <FeedbackNotification
                id="feedbackMessage"
                message="changes saved successfully"
                isWarning={false}
                hideFeedback={this.hideFeedback}
              />
            ) : (
              ''
            )}
          </section>
        </main>
        {this.state.showClinicModal && this.state.clinicModal ? (
          <Modal
            id="staffTableModal"
            title="Manage clinic staff"
            closeModal={() => this.toggleClinicModal()}
            rightButtons={[
              {
                type: 'button',
                style: 'tertiary',
                label: 'Close',
                size: 'small',
                events: {
                  onClick: () => this.toggleClinicModal(),
                },
              },
              {
                type: 'button',
                style: 'secondary',
                label: 'Save',
                size: 'small',
                events: {
                  onClick: e => this.submitInvite(e),
                },
              },
            ]}
          >
            <StaffTable
              isLoading={false}
              isEdit={false}
              isClinicView={true}
              staffList={this.state.clinicModal}
              staffModalFilter={this.state.staffModalFilter}
              clinicHeadingData={{ company_name: this.state.clinicName }}
              searchClinicians={this.state.filteredClinicians}
              filterStaff={this.filterClinicianInvite}
              searchClick={e => this.inviteClinician(e)}
              removeStaffRow={(e, data) => this.removeClinicianInvite(e, data)}
              global={global}
            />
          </Modal>
        ) : this.state.showInfoModal ? (
          <Modal
            id="infoRoleModal"
            title="Clever Clinic user roles"
            closeModal={() => this.toggleInfoModal()}
            rightButtons={[
              {
                type: 'button',
                style: 'tertiary',
                label: 'Close',
                size: 'small',
                events: {
                  onClick: () => this.toggleInfoModal(),
                },
              },
            ]}
          >
            <ModalInfo type="Staff" />
          </Modal>
        ) : (
          ''
        )}
        {this.state.showAlert ? (
          <AlertModal
            title="Error"
            message={this.state.errorMessage}
            closeAlert={this.closeErrorAlert}
            rightButtons={[
              {
                type: 'button',
                style: 'tertiary',
                label: 'Cancel',
                size: 'small',
              },
            ]}
          />
        ) : (
          ''
        )}
      </React.Fragment>
    )
  }
}

StaffBlock.propTypes = {
  actions: PropTypes.object.isRequired,
  global: PropTypes.object.isRequired,
}

/** Need to change this as per need */
const mapStateToProps = state => {
  return {
    data: state.auth,
    auth: state.auth,
    global: state.global,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetCliniciansList,
        A_GetAllClinicsList,
        A_PatchClinicianInvite,
        A_PatchClinicInvite,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StaffBlock),
)
