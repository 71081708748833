import React from 'react'
import PropTypes from 'prop-types'
import MainButton from '../../../Buttons'

const TreatmentOptionSearchResult = ({ product, clickHandler }) => {
  return (
    <React.Fragment>
      <section className="treatmentOptionSearchResult primaryMarginBottom">
        <article className="treatment__section treatment__header">
          <div className="treatment__header__info">
            <div className="treatment__header__title">
              <span className="treatmentItem__header__text singleLine_truncatedText">
                {product.description}
              </span>
            </div>
          </div>
        </article>
        <article className="treatment__body">
          <p className="treatmentItem__info__item">{product.sageStock_Code}</p>
        </article>
        <article className="treatment__section treatment__footer">
          {product.isAlreadyLinked ? (
            <MainButton
              type="button"
              label="Already Linked to Treatment Option"
              styleType="primary"
              size="small"
              onClick={() => clickHandler(product)}
            />
          ) : (
            <MainButton
              type="button"
              label="Link to treatment"
              styleType="primary"
              size="small"
              onClick={() => clickHandler(product)}
            />
          )}
        </article>
      </section>
    </React.Fragment>
  )
}

TreatmentOptionSearchResult.defaultProps = {
  clickHandler: () => {},
}

TreatmentOptionSearchResult.propTypes = {
  product: PropTypes.object.isRequired,
  clickHandler: PropTypes.func,
}

export default TreatmentOptionSearchResult
