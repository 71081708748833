export const TEST_ENVIRONMENT = 'UAT'

export const REACT_APP_SERVERS = {
  VAGRANT: 'http://localhost:8888/api/v1',
  // VAGRANT: 'https://cleverclinic.local/api/v1',
  UAT: 'https://api.uat.cleverclinic.co.uk/api/v1',
  PROD: 'https://api.cleverclinic.co.uk/api/v1',
  ALEX: 'http://86.167.91.47:8888/api/v1',
}

export const REACT_APP_V2_SERVERS = {
  VAGRANT: 'http://localhost:8888/api/v2',
  // VAGRANT: 'https://cleverclinic.local/api/v2',
  UAT: 'https://api.uat.cleverclinic.co.uk/api/v2',
  PROD: 'https://api.cleverclinic.co.uk/api/v2',
  ALEX: 'http://86.167.91.47:8888/api/v2',
}

export const REACT_APP_URL = {
  V1_VAGRANT: 'http://localhost:3000',
  V1_UAT: 'http://app.uat.cleverclinic.co.uk',
  V1_PROD: 'http://app.cleverclinic.co.uk',
  V2_VAGRANT: 'http://localhost:3000',
  V2_UAT: 'http://appv2.uat.cleverclinic.co.uk',
  V2_PROD: 'http://appv2.cleverclinic.co.uk',
}

export const SERVER =
  process.env.REACT_APP_SERVER || REACT_APP_SERVERS[TEST_ENVIRONMENT]
export const V2_SERVER =
  process.env.REACT_APP_V2_SERVER || REACT_APP_V2_SERVERS[TEST_ENVIRONMENT]
export const REDIRECT_TO_V2_APP =
  process.env.REACT_APP_V2_APP || REACT_APP_URL[`V2_${TEST_ENVIRONMENT}`]

export const Login = '/onboarding/login/'
export const ForgotPassword = '/onboarding/forgot-password/'
export const clinicSetup = '/onboarding/me/'
export const AssignclinicsDeligate = '/clinics/delegate/'
export const clinics = '/clinics/'
export const postQuery = '/support/query/'
export const treatmentList = '/clinics/treatments/'
export const scheduleList = '/clinics/schedules/'
export const conflictList = '/clinics/conflicts/'
export const clinicians = '/clinics/clinicians/'
export const notifications = '/notifications/'
export const clinicAccount = '/clinics/account/'
export const treatmentSummary = '/reports/treatment_summary/?'
export const appointmentTreatmentSummary =
  '/reports/appointment_treatment_summary/?'
export const inventory = '/inventory/'
export const report = '/reports/'

export const SENTRY_ENABLED =
  process.env.REACT_APP_SENTRY_ENABLED === 'true' ?? false
