import React from 'react'
import PropTypes from 'prop-types'
import {
  /*ErrorToolTipCard,*/ errorSpan,
  requiredSpan,
  listError,
} from '../../../containers/OnBoarding/FormErrorValidations'

const SelectInput = ({
  id,
  label,
  defaultSelected,
  options,
  isColumnCell,
  error,
  isInline,
  isLabelInline,
  hasTopMargin,
  isSmallMargin,
  hasImage,
  //inputSelect,
  hasOwnWrapper,
  isRequired,
  customClasses,
  // showAsterisk,
  errorMessage,
  tooltipClasses,
  isCalendarHeader,
  ...props
}) => {
  const labelEl =
    label.length > 0 ? (
      <label
        className={`form__label ${isLabelInline ? 'form__label--inline' : ''}`}
        htmlFor={id}
      >
        {error && error.length > 0
          ? error === 'Error'
            ? errorSpan()
            : error === 'Required'
            ? requiredSpan()
            : error === 'List'
            ? listError()
            : ''
          : ''}
        {label}{' '}
        {!isRequired ? (
          <span className="form__label--optional"> - optional</span>
        ) : (
          ''
        )}
      </label>
    ) : (
      ''
    )

  const renderInner = () => {
    return (
      <React.Fragment>
        {labelEl}
        <select
          className={`form__textField form__selectField ${
            isInline ? 'form__selectField--inline' : ''
          }
                ${isSmallMargin ? 'form__selectField--smallMargin' : ''} ${
            hasImage ? 'form__textField--hasImage' : ''
          }
                ${error && error.length > 0 ? 'form__textField--error' : ''} ${
            isColumnCell ? 'patientImport__row' : ''
          }
                ${
                  hasTopMargin ? 'form__select--labeless' : ''
                } ${customClasses}`}
          id={id}
          name={props.name}
          value={defaultSelected}
          //ref={props.Ref}
          {...props}
        >
          {Object.keys(options).map(function(index) {
            return (
              <option
                disabled={options[index].isDisabled}
                value={options[index].id}
                key={index}
              >
                {options[index].title}
              </option>
            )
          })}
        </select>
        <span
          className={`icon-chevron-down form__select ${
            label.length > 0 ? '' : 'form__select--labeless'
          } ${error && error.length > 0 ? 'form__select--error' : ''} ${
            isLabelInline
              ? isCalendarHeader
                ? 'form__select--inline'
                : 'form__select--labeless'
              : ''
          }`}
        />
      </React.Fragment>
    )
  }

  const renderErrorCard = () => {
    return (
      <React.Fragment>
        <section className="tooltipWrapper">
          <aside className={`tooltip tooltip--error ${tooltipClasses}`}>
            <section className="tooltip__tip icon-alert">
              <span className="tooltip__tipText">{errorMessage}</span>
            </section>
            <span className="tooltip__pointerStretch">
              <span className="tooltip__pointer tooltip--error__pointer" />
            </span>
          </aside>
        </section>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {hasOwnWrapper ? (
        renderInner()
      ) : (
        <section className="createAppointment--right">{renderInner()}</section>
      )}
      {error === 'card' ? renderErrorCard() : ''}
    </React.Fragment>
  )
}

SelectInput.defaultProps = {
  id: '',
  label: '',
  error: '',
  defaultSelected: null,
  customClasses: '',
  options: [],
  isInline: false,
  isLabelInline: false,
  hasTopMargin: false,
  //inputSelect: null,
  hasImage: false,
  isSmallMargin: false,
  isColumnCell: false,
  isRequired: true,
  hasOwnWrapper: false,
  // showAsterisk: false,
  errorMessage: 'Required',
  tooltipClasses: 'tooltip--patientImport',
  isCalendarHeader: false,
  name: '',
}

SelectInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  defaultSelected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  customClasses: PropTypes.string,
  //inputSelect: PropTypes.string,
  options: PropTypes.array,
  isInline: PropTypes.bool,
  isLabelInline: PropTypes.bool,
  hasTopMargin: PropTypes.bool,
  hasImage: PropTypes.bool,
  isSmallMargin: PropTypes.bool,
  isColumnCell: PropTypes.bool,
  isRequired: PropTypes.bool,
  hasOwnWrapper: PropTypes.bool,
  // showAsterisk: PropTypes.bool,
  errorMessage: PropTypes.string,
  tooltipClasses: PropTypes.string,
  isCalendarHeader: PropTypes.bool,
  name: PropTypes.string,
}

export default SelectInput
