import React from 'react'
import PropTypes from 'prop-types'

import Note from './Note'

export default function Notes({ notes, timezone }) {
  return (
    <>
      {notes && notes.length
        ? notes.map(note => (
            <Note key={note.id} {...note} timezone={timezone} />
          ))
        : ''}
    </>
  )
}

Notes.propTypes = {
  notes: PropTypes.array.isRequired,
  timezone: PropTypes.string.isRequired,
}
