import * as fetchData from '../services/DataImport/DataImport_Service'
import * as importing from '../services/DataImport/ImportFile_Service'
import types from './types'

export const A_GetImportedFileData = data => dispatch =>
  fetchData
    .getimportedFileData(data)
    .then(response => {
      dispatch({ type: types.GET_IMPORTED_DATA.OK, data: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_IMPORTED_DATA.FAIL, error })
      return Promise.reject(error)
    })

export const A_UpdateFieldHeadersList = updatedData => ({
  type: types.UPDATE_FILE_HEADERS,
  updatedData,
})

export const A_ImportCSVFile = importData => dispatch =>
  importing
    .importCSVFile(importData)
    .then(response => {
      dispatch({ type: types.FILE_IMPORT.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.FILE_IMPORT.FAIL, error })
      return Promise.reject(error)
    })

export const A_ImportData = importData => dispatch =>
  importing
    .importData(importData)
    .then(response => {
      dispatch({ type: types.DATA_IMPORT.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.DATA_IMPORT.FAIL, error })
      return error
    })
