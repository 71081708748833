import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ErrorToolTipCard, {
  errorSpan,
  requiredSpan,
} from '../../OnBoarding/FormErrorValidations'
import PhoneNumberInput from '../../../components/forms/PhoneNumber'

import {
  getAddress,
  getAddressDetails,
} from '../../../services/ClinicAppointments/Clinic_Setup/ClinicDashboard_01Service'

class Form extends Component {
  prefixForInputLabel = key => {
    return this.state.requiredFailed.includes(key)
      ? requiredSpan()
      : this.state.showValidationErrors && this.state.validationFailed[key]
      ? errorSpan()
      : ''
  }

  classForInput = key => {
    return this.state.requiredFailed.includes(key) ||
      (this.state.showValidationErrors && this.state.validationFailed[key])
      ? 'form__textField--error'
      : ''
  }

  componentForType = (
    type = Form.InputType.TEXT,
    key,
    placeholder,
    value,
    disabled,
  ) => {
    switch (type) {
      case Form.InputType.TEXT:
        return (
          <input
            className={`form__textField ${this.classForInput(key)}`}
            type="text"
            id={key}
            placeholder={placeholder}
            value={value}
            onChange={({ target: { value } }) => {
              this.handleFieldChange(value, key)
            }}
            disabled={disabled}
          />
        )
      case Form.InputType.PHONE:
        return (
          <PhoneNumberInput
            key={key}
            id={key}
            value={value}
            hasOwnWrapper={true}
            placeholder={placeholder}
            onChange={value => {
              this.handleFieldChange(value, key)
            }}
            customClasses={`${this.classForInput(key)}`}
            className={`form__textField`}
          />
        )
      case Form.InputType.SELECT:
        return (
          <>
            <select
              className={`form__textField ${this.classForInput(key)}`}
              id={key}
              value={value}
              disabled={disabled}
              onChange={({ target: { value } }) => {
                this.handleFieldChange(value, key)
              }}
              onBlur={({ target: { value } }) => {
                this.handleFieldChange(value, key)
              }}
            >
              {placeholder.map(option => (
                <option
                  disabled={option.isDisabled}
                  value={option.id}
                  key={option.id}
                >
                  {option.title}
                </option>
              ))}
            </select>
            <span className="icon-chevron-down form__select" />
          </>
        )
      case Form.InputType.ADDRESS_SEARCH: {
        let list = this.searchLists[key]

        if (!list) {
          list = []
        }

        return (
          <>
            <span className="form__fieldIcon icon-magnifying-glass" />
            <input
              className={`form__textField form__textField--left-icon ${this.classForInput(
                key,
              )}`}
              type="text"
              id={key}
              placeholder={placeholder}
              value={value}
              onBlur={() => {
                this.setState({ showResults: null })
              }}
              onChange={({ target: { value } }) => {
                this.handleFieldChange(value, key)

                if (this.addressLookupInterval) {
                  clearInterval(this.addressLookupInterval)
                }

                this.addressLookupInterval = setInterval(() => {
                  this.handleAddressLookup(value, key)
                }, 1000)
              }}
            />
            <article
              className={`searchResult__selector searchResult__selector--search ${
                this.state.showResults === key ? '' : 'hidden'
              }`}
            >
              <div className="searchResults__scroll">
                <div className="searchResult__header">
                  <div className="searchResult__row">
                    <span className="searchResult__header__title searchResult__header__text form__label">
                      Address
                    </span>
                  </div>
                  <div className="searchResult__header__spacer" />
                </div>
                {list.length > 0 ? (
                  <ul className="searchResult__results">
                    {list.map(({ place_id, address_description }) => {
                      return (
                        <li key={place_id} className="searchResult__result">
                          <button
                            type="button"
                            className="buttonTransparent searchResult__row searchResult__row--selectable"
                            onClick={() => {
                              this.selectRelaventAddress(place_id, key)
                            }}
                          >
                            <span className="searchResult__header__text searchResult__header">
                              {address_description}
                            </span>
                          </button>
                        </li>
                      )
                    })}
                  </ul>
                ) : (
                  <article className="searchResult__row noResults_message noResults_message--centered">
                    <span className="multiline_wordbreak">
                      There are no results matching this search value
                    </span>
                  </article>
                )}
              </div>
            </article>
          </>
        )
      }
      default:
        return null
    }
  }
  newField = (
    key,
    title,
    placeholder,
    validators = [],
    required = true,
    type = Form.InputType.TEXT,
    className = '',
    disabled = false,
  ) => {
    let { data, showValidationErrors, validationFailed } = this.state

    if (data === undefined) {
      return
    }

    if (type === null) {
      type = Form.InputType.TEXT
    }

    const value = data[key]

    this.validators[key] = validators

    let failedValidator = validationFailed[key]

    if (required && !this.required.includes(key)) {
      this.required.push(key)
    }

    return (
      <fieldset className={`form__group--halfWidth__panel ${className}`}>
        <label className="form__label">
          {this.prefixForInputLabel(key)}
          {title}
        </label>

        {this.componentForType(type, key, placeholder, value, disabled)}

        {showValidationErrors && failedValidator ? (
          <ErrorToolTipCard errorMsg={failedValidator.error} />
        ) : (
          ''
        )}
      </fieldset>
    )
  }

  handleFieldChange = (value, key) => {
    let { data, requiredFailed, validationFailed } = this.state

    data[key] = value

    // If the field is NOT require and there is NO value in it, skip validation
    // ... however, if the field is NOT empty, still validate it!
    let skipValidation = !this.required.includes(key) && value === ''
    let fieldValidators = this.validators[key]
    let validationError = undefined

    if (fieldValidators && !skipValidation) {
      for (let i = 0; i < fieldValidators.length; i++) {
        let validator = fieldValidators[i](value)

        if (validator.valid === false) {
          validationError = validator
          break
        }
      }
    }

    if (validationError !== undefined) {
      validationFailed[key] = validationError
    } else if (validationFailed[key]) {
      delete validationFailed[key]
    }

    this.setState({
      data: data,
      requiredFailed: requiredFailed.filter(function(key2) {
        return key2 !== key
      }),
      validationFailed: validationFailed,
    })
  }

  handleValidation = () => {
    let { data, validationFailed } = this.state
    const required = this.required

    let requiredFailed = []

    for (let i = 0; i < required.length; i++) {
      const key = required[i]
      const value = data[key] ? data[key].trim() : ''

      if (!value || value.length === 0) {
        requiredFailed.push(key)
      }
    }

    this.children.forEach(function(child) {
      if (child.handleValidation() === false) {
        requiredFailed = [...requiredFailed, child.requiredFailed]
        validationFailed = { ...validationFailed, validationFailed }
      }
    })

    const errors =
      requiredFailed.length > 0 || Object.keys(validationFailed).length > 0

    this.setState({
      requiredFailed: requiredFailed,
      showValidationErrors: errors,
    })

    if (errors) {
      window.scroll(0, 0)
    }

    return requiredFailed.length === 0 && errors === false
  }

  handleAddressLookup = async (value, key) => {
    if (value !== this.lookupAddress && value.length > 0) {
      this.lookupAddress = value

      const addresses = await getAddress(value)

      this.searchLists[key] = addresses

      this.setState({ showResults: key })
    }
  }

  selectRelaventAddress = async place_id => {
    const addressDetails = await getAddressDetails(place_id)

    let { data } = this.state

    for (const key in addressDetails) {
      const value = addressDetails[key]

      if (data[key] !== undefined) {
        data[key] = value
      }
    }

    this.setState(
      {
        data: data,
        showResults: null,
      },
      () => {
        this.handleValidation()
      },
    )
  }

  constructor(props) {
    super(props)

    this.state = {
      requiredFailed: [],
      validationFailed: {},
      showValidationErrors: false,
      data: {},
      showResults: null,
    }

    this.validators = {}
    this.required = []

    this.children = []
    this.searchLists = {}
  }

  componentDidMount() {
    const { parent } = this.props

    if (parent) {
      parent.children.push(this)
    }
  }

  componentWillUnmount() {
    const { parent } = this.props

    if (parent) {
      parent.children = parent.children.filter(function(child) {
        return child !== this
      })
    }
  }

  isValid = () => {}
}

Form.propTypes = {
  parent: PropTypes.object.isRequired,
}

Form.InputType = {
  TEXT: 'text',
  PHONE: 'phone',
  SELECT: 'select',
  ADDRESS_SEARCH: 'address_search',
}

export default Form
