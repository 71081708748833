import React, { Component } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import * as qs from 'querystringify'
import { setToken } from './../../services/OnBoarding/auth'
import { A_GetAccount, A_GetUserDetails } from './../../actions'

class RemoteAuthRedirect extends Component {
  state = {
    url: null,
  }

  componentDidMount = () => {
    const {
      location: { search },
      actions,
    } = this.props

    const qsParams = qs.parse(search)

    setToken(qsParams.token)

    actions.A_GetUserDetails().then(response => {
      if (response.invite.is_admin) {
        actions.A_GetAccount().then(() => {
          this.setState({ url: qsParams.url })
        })
      } else {
        this.setState({ url: qsParams.url })
      }
    })
  }

  render() {
    const { url } = this.state
    if (url !== null) {
      return (
        <Redirect from="/" to={{ pathname: url, state: { status: 202 } }} />
      )
    } else {
      return <div>Redirecting...</div>
    }
  }
}

RemoteAuthRedirect.propTypes = {
  location: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetAccount,
        A_GetUserDetails,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RemoteAuthRedirect),
)
