import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { availableCountries } from '../../../utilities/ReusableFunctions'
import Form from '../Form'
import { validateNotEmpty } from '../Validators'

class Address extends Form {
  constructor(props) {
    super(props)

    const { prefix } = props

    console.log(props.data)

    this.validationKeys = [
      `${prefix}address_line_one`,
      `${prefix}address_town`,
      `${prefix}address_county`,
      `${prefix}address_postcode`,
      `${prefix}address_country`,
    ]

    this.state = {
      ...this.state,
      data: props.data,
      isLoadingAddresses: false,
      addressList: null,
      newAddresses: [],
    }
  }

  render() {
    let { prefix } = this.props

    return (
      <React.Fragment>
        <article className="form__group">
          <section className="form__group--halfWidth">
            {this.newField(
              `${prefix}address_line_one`,
              'Address Line 1',
              'Street Address',
              [validateNotEmpty],
              false,
              Form.InputType.ADDRESS_SEARCH,
            )}
            {this.newField(
              `${prefix}address_line_two`,
              'Address Line 2',
              '(optional)',
              [validateNotEmpty],
              false,
            )}
          </section>
          <section className="form__group--halfWidth">
            {this.newField(
              `${prefix}address_town`,
              'City/Town',
              'City/Town',
              [validateNotEmpty],
              true,
            )}
            {this.newField(
              `${prefix}address_county`,
              'County',
              'County',
              [validateNotEmpty],
              true,
            )}
          </section>
          <section className="form__group--halfWidth">
            {this.newField(
              `${prefix}address_postcode`,
              'Postcode',
              'Postcode',
              [validateNotEmpty],
              true,
            )}
            {this.newField(
              `${prefix}address_country`,
              'Country',
              availableCountries,
              [validateNotEmpty],
              true,
              Form.InputType.SELECT,
              '',
              this.state.data.id !== 0,
            )}
          </section>
        </article>
      </React.Fragment>
    )
  }
}

Address.defaultProps = {
  title: 'Clinic',
  prefix: '',
}

Address.propTypes = {
  actions: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({}, dispatch),
  }
}

export default withRouter(connect(null, mapDispatchToProps)(Address))
