import React from 'react'
import PropTypes from 'prop-types'
import ModalHeader from '../Header'
import ModalFooter from '../Footer'

const AlertModal = ({
  title,
  message,
  leftButtons,
  rightButtons,
  preMessage,
  allowEdit,
  modalClassWidth,
  children,
  ...props
}) => {
  const getPremessage = () => {
    return (
      <React.Fragment>
        {preMessage} <br />
        <br />
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      <article
        id="alertModal"
        className={`modal modal--overlay ${modalClassWidth}`}
        style={{ display: 'block' }}
      >
        <form method="post" noValidate className="modal_flex_content">
          <ModalHeader
            title={title}
            allowEdit={allowEdit}
            closeHandler={props.closeAlert}
          />
          <section className="modal__body">
            {children ? (
              children
            ) : (
              <p className="modal__body__text">
                {preMessage.length > 0 ? getPremessage() : ''}
                {message}
              </p>
            )}
          </section>
          <ModalFooter
            leftButtons={leftButtons}
            rightButtons={rightButtons}
            closeHandler={props.closeAlert}
            clickHandler={props.confirmCancelImport}
          />
        </form>
      </article>
    </React.Fragment>
  )
}

AlertModal.defaultProps = {
  title: '',
  message: '',
  preMessage: '',
  leftButtons: [],
  rightButtons: [],
  allowEdit: false,
  confirmCancelImport: () => {},
  modalClassWidth: 'col__12-4',
  children: null,
}

AlertModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  preMessage: PropTypes.string,
  leftButtons: PropTypes.array,
  rightButtons: PropTypes.array,
  allowEdit: PropTypes.bool,
  closeAlert: PropTypes.func.isRequired,
  confirmCancelImport: PropTypes.func,
  modalClassWidth: PropTypes.string,
  children: PropTypes.any,
}

export default AlertModal
