import * as api from '../api'
import * as auth from '../OnBoarding/auth'

const baseNotifUrl = '/notifications/'

export const getBundles = () =>
  new Promise((resolve, reject) =>
    api
      .get(`${baseNotifUrl}sms-bundles/`)
      .then(res => resolve(res.data))
      .catch(err => reject(err)),
  )

export const addBundle = details =>
  api
    .post(`${baseNotifUrl}purchase-bundle/`, details)
    .then(res => res.data)
    .catch(err => err.data)

export const addVoucherBundle = details =>
  api
    .post(`${baseNotifUrl}add-voucher-bundle/`, details)
    .then(res => res.data)
    .catch(err => err.data)

export const fetchBundle = bundleId =>
  new Promise((resolve, reject) =>
    api
      .get(`${baseNotifUrl}purchase-bundle/${bundleId}/`)
      .then(res => resolve(res.data))
      .catch(err => reject(err)),
  )

export const getAccount = () =>
  new Promise((resolve, reject) =>
    api
      .get('/clinics/account/')
      .then(res => {
        auth.setAccountType(res.data.account_type)
        resolve(res.data)
      })
      .catch(err => reject(err)),
  )

export const patchAccount = data =>
  new Promise(resolve =>
    api
      .patch('/clinics/account/', data)
      .then(res => resolve(res))
      .catch(err => resolve(err)),
  )
