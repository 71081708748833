import Promise from 'promise'
import * as api from '../api'

export const getPractitionerAppointmentList = (date, clinicianID, clinicID) => {
  return new Promise((resolve, reject) => {
    // For time being we are using the primary clinic id
    return api
      .get(
        `/clinics/${clinicID}/appointments/?date=${date}&clinicians=${clinicianID}`,
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getAppointmentsOverview = (clinicID, params) => {
  return new Promise((resolve, reject) => {
    return api
      .get(`/clinics/${clinicID}/appointments/overview/`, { params })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getPreOrderType = clinicID => {
  return new Promise((resolve, reject) => {
    return api
      .get(`/clinics/${clinicID}/appointments/overview/pre-order/`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const preOrderProducts = (clinicID, data) => {
  return new Promise((resolve, reject) => {
    return api
      .post(`/clinics/${clinicID}/appointments/overview/pre-orders/`, data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getOutstandingOrders = clinicID => {
  return new Promise((resolve, reject) => {
    return api
      .get(`/clinics/${clinicID}/epharm/uncompleted-orders/`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
