import React from 'react'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalHeader from '../../Modal/Header'
import ModalFooter from '../../Modal/Footer'
import TextInput from '../../forms/Input'
import TextAreaInput from '../../forms/Textarea'
import UploadDocumentButton from '../../Library/DocumentUpload/UploadDocumentButton'
import Loader from '../../../components/PatientData/Loader'
import { A_AddPatientDocument, A_AddCorrespondence } from '../../../actions'
import * as func from '../../../utilities/ReusableFunctions'

class DocumentUploadModal extends React.Component {
  constructor(props) {
    super(props)
    this.uploadDocument = React.createRef()
    this.state = {
      files: [],
      tempFiles: [],
      uploadErrors: [],
      title: '',
      description: '',
      titleValid: true,
      filesValid: true,
      isLoading: false,
      isOpen: true,
    }

    this.onFileChange = this.onFileChange.bind(this)
    this.onFileDelete = this.onFileDelete.bind(this)
    this.addDocument = this.addDocument.bind(this)
    this.removeDocument = this.removeDocument.bind(this)
    this.removeErrorDocument = this.removeErrorDocument.bind(this)
    this.uploadDocuments = this.uploadDocuments.bind(this)
    this.getFileErrors = this.getFileErrors.bind(this)
    this.setTitleValue = this.setTitleValue.bind(this)
    this.setDescriptionValue = this.setDescriptionValue.bind(this)
    this.getFileIds = this.getFileIds.bind(this)
    this.validateUpload = this.validateUpload.bind(this)
    this.submitUpload = this.submitUpload.bind(this)
    this.onLabelClick = this.onLabelClick.bind(this)
    this.getFileButtons = this.getFileButtons.bind(this)
    this.getTempFileButtons = this.getTempFileButtons.bind(this)
    this.checkTitleError = this.checkTitleError.bind(this)
    this.onCloseSelected = this.onCloseSelected.bind(this)
  }

  onFileChange(event) {
    const fileLength = event.target.files && event.target.files.length
    if (fileLength > 0) {
      for (let i = 0; i < fileLength; i++) {
        this.addDocument(event.target.files[i])
      }
      event.target.value = null
    }
  }

  onFileDelete(event) {
    let fileId = Number(event.target.dataset.fileid)
    let files = this.state.files.filter(file => {
      return file.id !== fileId
    })
    this.setState(() => ({
      files: files,
    }))
  }

  addDocument(file) {
    let tempFiles = this.state.tempFiles
    if (
      tempFiles.filter(tempFile => {
        return (
          tempFile.name === file.name &&
          tempFile.lastModified === file.lastModified &&
          tempFile.size === file.size
        )
      }).length === 0
    ) {
      tempFiles.push(file)
      this.setState(() => ({
        tempFiles: tempFiles,
        filesValid: true,
      }))
    }
  }

  removeDocument(event) {
    let fileId = Number(event.target.dataset.fileid)
    let tempFiles = this.state.tempFiles.filter(file => {
      return file.lastModified !== fileId
    })
    this.setState(() => ({
      tempFiles: tempFiles,
    }))
  }

  removeErrorDocument(event) {
    let fileId = Number(event.target.dataset.fileid)
    let tempFiles = this.state.tempFiles.filter(file => {
      return file.lastModified !== fileId
    })
    let uploadErrors = this.state.uploadErrors.filter(error => {
      return error.file.lastModified !== fileId
    })
    this.setState(() => ({
      tempFiles: tempFiles,
      uploadErrors: uploadErrors,
    }))
  }

  uploadDocuments() {
    const {
      isCorrespondence,
      documentType,
      clinicId,
      patientId,
      isReferral,
      actions,
    } = this.props
    let filesToUpload = this.state.tempFiles
    let filesLength = filesToUpload.length
    let uploadErrors = []
    let updatedTempFiles = []
    for (let i = 0; i < filesLength; i++) {
      let fileData = new FormData()
      fileData.append('file', filesToUpload[i])
      fileData.append('document_type', documentType)
      if (!isCorrespondence && !isReferral) {
        fileData.append('document_description', this.state.description)
      }
      actions
        .A_AddPatientDocument(clinicId, patientId, fileData, documentType)
        .then(response => {
          if (response.status && response.status >= 300) {
            uploadErrors.push({ file: filesToUpload[i], error: response })
            updatedTempFiles.push(filesToUpload[i])
          } else {
            let files = this.state.files
            files.push(response)
            this.setState(() => ({
              files: files,
              tempFiles: [],
            }))
          }
          const fileTotal = this.state.files.length + updatedTempFiles.length
          if (filesLength === fileTotal) {
            this.setState(
              () => ({
                isLoading: false,
                uploadErrors: uploadErrors,
                tempFiles: updatedTempFiles,
              }),
              () => {
                if (uploadErrors.length === 0) {
                  if (isCorrespondence) {
                    this.submitUpload('correspondence')
                  } else if (isReferral) {
                    this.submitUpload('referral')
                  } else {
                    this.onCloseSelected(true)
                  }
                }
              },
            )
          }
        })
    }
  }

  setTitleValue(event) {
    const title = event.currentTarget.value
    this.setState(() => ({
      title: title,
    }))
  }

  setDescriptionValue(event) {
    const description = event.currentTarget.value
    this.setState(() => ({
      description: description,
    }))
  }

  getFileIds() {
    return this.state.files.map(file => {
      return { id: file.id }
    })
  }

  validateUpload(event) {
    const { isCorrespondence } = this.props
    event.preventDefault()
    if (isCorrespondence && this.state.title.trim().length === 0) {
      this.setState(() => ({
        titleValid: false,
      }))
    } else {
      if (this.state.tempFiles.length === 0) {
        if (isCorrespondence) {
          this.setState(
            () => ({
              titleValid: true,
              filesValid: true,
            }),
            () => {
              this.submitUpload('correspondence')
            },
          )
        } else {
          this.setState(() => ({
            titleValid: true,
            filesValid: false,
          }))
        }
      } else {
        this.setState(
          () => ({
            titleValid: true,
            isLoading: true,
          }),
          () => {
            this.uploadDocuments()
          },
        )
      }
    }
  }

  submitUpload(type) {
    const { title, clinicId, patientId, actions, followUpId } = this.props
    const docTitle = type === 'referral' ? title : this.state.title
    const data = {
      title: docTitle,
      information: this.state.description,
      documents: this.getFileIds(),
    }
    if (type === 'referral' && followUpId) {
      data.referral_follow_up = { id: followUpId }
    }
    actions.A_AddCorrespondence(clinicId, patientId, data).then(() => {
      this.onCloseSelected(true)
    })
  }

  getFileButtons() {
    const { canDelete } = this.props
    let files = []
    this.state.files.length > 0
      ? (files = this.state.files.map(file => {
          return (
            <UploadDocumentButton
              key={file.id}
              id={file.id}
              title={`${func.file(file)} ${func.file_size(file)}`}
              type={func.getCorrespondenceType(file)}
              onOpen={() => {
                window.open(file.signed_file, '_blank')
              }}
              canDelete={canDelete}
              onDelete={this.removeDocument}
              size="full"
            />
          )
        }))
      : (files = [])
    return files
  }

  getTempFileButtons() {
    const { canDelete } = this.props
    let files = []
    this.state.tempFiles.length > 0
      ? (files = this.state.tempFiles.map(file => {
          return (
            <UploadDocumentButton
              key={file.lastModified}
              id={file.lastModified}
              title={`${func.getFileName(file.name)} ${func.file_size({
                file_size: file.size,
              })}`}
              type={func.getCorrespondenceType({ file: file.name })}
              isPreload={true}
              canDelete={canDelete}
              onDelete={this.removeDocument}
              size="full"
            />
          )
        }))
      : (files = [])
    return files
  }

  getFileErrors() {
    const { canDelete } = this.props
    let errorMessages = []
    this.state.uploadErrors.length > 0
      ? (errorMessages = this.state.uploadErrors.map(error => {
          return (
            <React.Fragment key={error.file.lastModified}>
              <span className="form__label--error upload__info__container">{`ERROR: ${
                error.error.status && error.error.status === 400
                  ? error.error.data.file
                  : 'The file could not be uploaded. Please try again'
              }`}</span>
              <UploadDocumentButton
                id={error.file.lastModified}
                title={`${func.getFileName(error.file.name)} ${func.file_size({
                  file_size: error.file.size,
                })}`}
                type={func.getCorrespondenceType({ file: error.file.name })}
                isPreload={true}
                canDelete={canDelete}
                onDelete={this.removeErrorDocument}
                size="full"
              />
            </React.Fragment>
          )
        }))
      : (errorMessages = [])
    return errorMessages
  }

  onLabelClick(event) {
    event.preventDefault()
    this.uploadDocument.current.click()
  }

  checkTitleError() {
    if (this.state.titleValid) {
      return ''
    }
    return 'Required'
  }

  onCloseSelected(save) {
    const { onSaveSelected, onCloseSelected } = this.props
    this.setState(
      () => ({
        isOpen: false,
      }),
      () => {
        this.closeTimeout = setTimeout(() => {
          save ? onSaveSelected() : onCloseSelected()
        }, 500)
      },
    )
  }

  componentWillUnmount() {
    clearTimeout(this.closeTimeout)
  }

  render() {
    const { title, isCorrespondence, allowMultipleUpload } = this.props
    return (
      <React.Fragment>
        <article
          id="DocumentUploadModal"
          className={`modal modal--overlay col__12-6 ${
            this.state.isOpen ? 'fadeInFlyAnimate' : 'fadeOutFlyAnimate'
          }`}
          style={{ display: 'block' }}
        >
          <form method="post" noValidate className="modal_flex_content">
            <ModalHeader
              title={title}
              allowEdit={false}
              closeHandler={this.onCloseSelected}
            />
            <section className="modal__body modal__body--form">
              {this.state.isLoading ? (
                <Loader text="UPLOADING FILES" />
              ) : (
                <fieldset className="form__group">
                  {isCorrespondence ? (
                    <TextInput
                      id="documentTitle"
                      name="documentTitle"
                      error={this.checkTitleError()}
                      label="Title"
                      type="text"
                      onChange={this.setTitleValue}
                    />
                  ) : (
                    ''
                  )}
                  <TextAreaInput
                    id="documentDescription"
                    name="documentDescription"
                    label="Description"
                    isRequired={false}
                    onChange={this.setDescriptionValue}
                  />
                  <div className="secondaryMarginBottom">
                    <label id="uploadDocumentButton" htmlFor="uploadDocument">
                      <p
                        role="presentation"
                        className="noMargin textLink button--wide"
                        onClick={this.onLabelClick}
                        onKeyPress={this.onLabelClick}
                        aria-controls="uploadDocument"
                      >
                        <span className="treatment__info__icon icon-upload" />
                        <span>Upload documents (DOC, PDF, JPG, PNG)</span>
                      </p>
                    </label>
                    <input
                      id="uploadDocument"
                      ref={this.uploadDocument}
                      type="file"
                      accept=".pdf, .jpg, .jpeg, .png, .doc"
                      className="hidden"
                      onChange={this.onFileChange}
                      multiple={allowMultipleUpload ? 'multiple' : ''}
                    />
                  </div>
                  {this.getFileButtons()}
                  {this.state.filesValid ? (
                    ''
                  ) : (
                    <span className="form__label--error upload__info__container">
                      ERROR: Please attach a referral letter
                    </span>
                  )}
                  {this.state.uploadErrors.length > 0
                    ? this.getFileErrors()
                    : this.getTempFileButtons()}
                </fieldset>
              )}
            </section>
            <ModalFooter
              rightButtons={[
                {
                  type: 'button',
                  style: 'tertiary',
                  label: 'Cancel',
                  size: 'small',
                  events: { onClick: this.onCloseSelected },
                },
                {
                  type: 'submit',
                  style: 'secondary',
                  label: 'Save',
                  size: 'small',
                  events: { onClick: this.validateUpload },
                },
              ]}
            />
          </form>
        </article>
        <section
          className={`overlay ${
            this.state.isOpen ? 'fadeInAnimate' : 'fadeOutAnimate'
          }`}
          style={{ display: 'block' }}
        />
      </React.Fragment>
    )
  }
}

DocumentUploadModal.defaultProps = {
  title: '',
  onCloseSelected: () => {},
  clinicId: null,
  patientId: null,
  canDelete: true,
  allowMultipleUpload: true,
  isCorrespondence: false,
  isReferral: false,
  followUpId: null,
  documentType: 'Other',
}

DocumentUploadModal.propTypes = {
  title: PropTypes.string,
  onCloseSelected: PropTypes.func,
  clinicId: PropTypes.number,
  patientId: PropTypes.number,
  canDelete: PropTypes.bool,
  allowMultipleUpload: PropTypes.bool,
  isCorrespondence: PropTypes.bool,
  isReferral: PropTypes.bool,
  followUpId: PropTypes.number,
  documentType: PropTypes.oneOf([
    'Invoice',
    'Consent',
    'Referral Letter',
    'Other',
  ]),
  actions: PropTypes.object.isRequired,
  onSaveSelected: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_AddPatientDocument,
        A_AddCorrespondence,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(null, mapDispatchToProps)(DocumentUploadModal),
)
