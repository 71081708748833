import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  A_GetClinicsList,
  A_GetPatientDetail,
  A_UpdatePatientDetail,
} from '../../actions'
import * as obj from '../../utilities/ReusableObjects'
import { validatePhoneNumber } from '../../containers/_/Validators'
import MainButton from '../../components/Buttons'
import GeneralInfoNavigation from '../../components/PatientRecord/GeneralInfoNavigation'
import GeneralInfoCard from '../../components/PatientRecord/GeneralInfoCard'
import FeedbackStrap from '../../components/Feedback/FeedbackStrap'
import Loader from '../../components/PatientData/Loader'
import { filteredClinicians } from '../../utilities/ReusableFunctions'
import { TutorialContext } from '../../App'
import TutorialBtn from '../../containers/Tutorials/TutorialBtn'
import { isTutorialFeatureEnabled } from '../../utilities/featureToggle'

class PatientGeneralInfo extends Component {
  constructor(props) {
    super(props)
    obj.generalInfoSections.forEach(section => {
      let ref = section.id
      this[ref] = React.createRef()
    })

    this.state = {
      selectedNav: '',
      isEdited: false,
      isEditMode: false,
      patientEditData: null,
      validations: {},
      isLoading: true,
      isClinicUnavailable: false,
    }

    this.getNavList = this.getNavList.bind(this)
    this.onNavListChange = this.onNavListChange.bind(this)
    this.getGeneralInfoSections = this.getGeneralInfoSections.bind(this)
    this.getPatientLegacyData = this.getPatientLegacyData.bind(this)
    this.updateCreatedDate = this.updateCreatedDate.bind(this)
    this.setEditMode = this.setEditMode.bind(this)
    this.cancelEditMode = this.cancelEditMode.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this.hideValidations = this.hideValidations.bind(this)
    this.getFooterButtons = this.getFooterButtons.bind(this)
    this.getSubmitPatientData = this.getSubmitPatientData.bind(this)
    this.updateFormItem = this.updateFormItem.bind(this)
    this.deleteLegacyData = this.deleteLegacyData.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.getInitialData = this.getInitialData.bind(this)
    this.getInitialPatientData = this.getInitialPatientData.bind(this)
  }

  getInitialData(dataObj) {
    /// deep clone data object
    return JSON.parse(JSON.stringify(dataObj))
  }

  /*  Parse the data to create the PATCH object  */
  getSubmitPatientData() {
    const patient = this.state.patientEditData
    let patientValues = {}

    //Data items that are objects should only have the id returned in the patch object
    //E.g. Clinician or clinic changes
    let objectKeys = Object.keys(patient)
    let objectKeysLength = objectKeys.length
    for (let i = 0; i < objectKeysLength; i++) {
      const patientKey = objectKeys[i]
      if (
        patient[patientKey] &&
        typeof (patient[patientKey] === 'object') &&
        Object.prototype.hasOwnProperty.call(patient[patientKey], 'id')
      ) {
        let keyObj = { id: patient[patientKey].id }
        patientValues[patientKey] = keyObj
      } else {
        patientValues[patientKey] = patient[patientKey]
      }
    }
    if (
      patientValues.permission_to_contact_given === false ||
      patientValues.preferred_contact === 0
    ) {
      patientValues.preferred_contact = []
    }
    if (
      patientValues.can_contact_for_marketing === false ||
      patientValues.preferred_contact_marketing === 0
    ) {
      patientValues.preferred_contact_marketing = []
    }
    return patientValues
  }

  /*  Check if legacy data is available and add side navigation component */
  getNavList() {
    const { patient } = this.props
    const sections =
      patient.legacy_data.length > 0
        ? obj.generalInfoSections
        : obj.generalInfoSections.slice(0, -1)
    return (
      <GeneralInfoNavigation
        selected={this.state.selectedNav}
        onClick={this.onNavListChange}
        sections={sections}
      />
    )
  }

  /*  Navigate to data section  */
  onNavListChange(event) {
    this.setState(
      {
        selectedNav: event.target.id,
      },
      () => {
        const selectedEl = document.getElementById(
          `${this.state.selectedNav}_card`,
        )
        const rect = selectedEl.getBoundingClientRect()
        const top = rect.top + window.pageYOffset - 90

        //If the browser supports scroll behaviour, add animation
        let supportsScrollBehaviour = false
        window.scrollTo({
          top: top,
          get behavior() {
            supportsScrollBehaviour = true
            return 'smooth'
          },
        })
        if (!supportsScrollBehaviour) {
          window.scrollTo(0, top)
        }
        let isScrolling
        let that = this

        //If the user scrolls the info sections, clear the selected navigation
        window.onscroll = function() {
          window.clearTimeout(isScrolling)
          isScrolling = setTimeout(function() {
            window.onscroll = function() {
              that.setState(
                {
                  selectedNav: '',
                },
                () => {
                  window.onscroll = null
                },
              )
            }
          }, 66)
        }
      },
    )
  }

  /*  Parse legacy data into section component data structure  */
  getPatientLegacyData() {
    const legacyData = this.state.patientEditData.legacy_data.map(dataItem => {
      let value = 'legacy_data.' + dataItem.key
      return {
        id: dataItem.id.toString(),
        title: dataItem.key,
        func: null,
        value: value,
        width: 1,
        canEdit: true,
        type: 'legacy',
      }
    })
    return legacyData
  }

  /*  Add a GeneralInfoCard component for each section  */
  getGeneralInfoSections() {
    const { global } = this.props
    let infoSections = []
    //Check if there is legacy data and if required parse the legacy data
    const allSections = obj.generalInfoSections.map(item => {
      if (item.id === 'patient_legacy') {
        item.fields = this.getPatientLegacyData()
      }
      return item
    })
    let allSectionsLength = allSections.length
    for (let i = 0; i < allSectionsLength; i++) {
      const section = allSections[i]
      if (
        section.id !== 'patient_legacy' ||
        this.state.patientEditData.legacy_data.length > 0
      ) {
        infoSections.push(
          <GeneralInfoCard
            key={section.id}
            idRef={`${section.id}_card`}
            sectionData={section}
            patientData={this.state.patientEditData}
            clinicData={global.clinics}
            clinicianData={filteredClinicians(
              this.state.patientEditData.clinic.invites,
              global.currentUserID,
            ).map(item => {
              return item.clinician
            })}
            isDynamic={section.id === 'patient_legacy'}
            isEditMode={this.state.isEditMode}
            onFormItemChanged={this.updateFormItem}
            onDOBItemChanged={this.updateFormItem}
            onLegacyItemDelete={this.deleteLegacyData}
            validations={this.state.validations}
          />,
        )
      }
    }
    return infoSections
  }

  /*  Update the data with the current data to remove top bar message  */
  updateCreatedDate() {
    const { actions, patient, global } = this.props

    let clinic = global.currentClinicID

    actions.A_UpdatePatientDetail(clinic, patient.id, patient).then(() => {
      this.setState({
        isEdited: true,
      })
    })
  }

  /*  Update the current data properties on change  */
  updateFormItem(property, data) {
    const { global } = this.props

    let updateData = this.state.patientEditData
    if (property === 'clinician') {
      updateData.clinician = updateData.clinic.invites
        .map(function(invite) {
          return invite.clinician
        })
        .find(clinician => clinician.id === data)
    } else if (property === 'provider') {
      updateData.clinic = global.clinics.find(function(clinic) {
        return clinic.id === data
      })
    } else {
      updateData[property] = data
    }

    this.setState({
      patientEditData: updateData,
    })
  }

  /*  Update the current data properties if legacy item removed  */
  deleteLegacyData(deleteAll, legacyItemId) {
    let updateData = this.state.patientEditData
    if (deleteAll) {
      updateData.legacy_data = []
    } else {
      let newLegacy = updateData.legacy_data.filter(legacyItem => {
        return legacyItem.id !== legacyItemId
      })
      updateData.legacy_data = newLegacy
    }
    this.setState({
      patientEditData: updateData,
    })
  }

  hideValidations() {
    let vals = this.state.validations
    let v = Object.keys(vals)
    let count = v.length
    for (let i = 0; i < count; i++) {
      if (vals[v[i]].showError) {
        vals[v[i]].showError = false
      }
    }
    this.setState({
      validations: vals,
    })
  }

  /*  Perform any field validations before submit  */
  validateForm(event) {
    event.preventDefault()
    let submitData = this.getSubmitPatientData()
    let validations = this.state.validations
    let allValid = true
    let objectKeys = Object.keys(validations)
    let objectKeysLength = objectKeys.length
    for (let i = 0; i < objectKeysLength; i++) {
      const valName = objectKeys[i]
      let currentVal = validations[valName]
      let currentProp = submitData[valName]
      currentVal.isValid = true
      if (
        currentVal.type === 'Required' &&
        (!currentProp || currentProp.length === 0)
      ) {
        currentVal.isValid = false
        allValid = false
      } else if (
        currentVal.type === 'postcodeRegex' &&
        currentProp &&
        currentProp.length > 0 &&
        !obj.postcodeRegex.test(currentProp.replace(/\s/g, ''))
      ) {
        currentVal.isValid = false
        currentVal.showError = true
        allValid = false
      } else if (
        currentVal.type === 'emailRegex' &&
        currentProp &&
        currentProp.length > 0 &&
        !obj.emailRegex.test(currentProp)
      ) {
        currentVal.isValid = false
        currentVal.showError = true
        allValid = false
      } else if (
        currentVal.type === 'isValidNumber' &&
        currentProp &&
        currentProp.length > 0 &&
        !validatePhoneNumber(currentProp).valid
      ) {
        currentVal.isValid = false
        currentVal.showError = true
        allValid = false
      }
      validations[valName] = currentVal
    }
    this.setState(
      {
        validations: validations,
      },
      () => {
        if (allValid) {
          this.submitForm()
        } else {
          window.scroll(0, 0)
        }
      },
    )
  }

  /*  Send the patch request and handle the response  */
  submitForm() {
    const { actions, patient, global } = this.props

    let clinic = global.currentClinicID

    actions
      .A_UpdatePatientDetail(clinic, patient.id, this.getSubmitPatientData())
      .then(() => {
        const { patient } = this.props

        this.setState({
          patientEditData: this.getInitialData(patient),
          isEditMode: false,
          validations: this.getInitialData(obj.patientGeneralInfoValidations),
        })
      })
  }

  /*  Reset the data  */
  cancelEditMode() {
    const { patient } = this.props
    this.setState({
      patientEditData: this.getInitialData(patient),
      isEditMode: false,
      validations: this.getInitialData(obj.patientGeneralInfoValidations),
    })
  }

  /*  Switch to editable mode  */
  setEditMode() {
    const editMode = !this.state.isEditMode
    this.setState({
      isEditMode: editMode,
    })
  }

  /*  Show correct footer for view and edit modes  */
  getFooterButtons() {
    let buttonList = []
    if (this.state.isEditMode) {
      buttonList.push(
        <MainButton
          key="EditButton"
          type="button"
          label="Save changes"
          styleType="primary"
          size="medium"
          margin="right"
          onClick={this.validateForm}
        />,
      )
      buttonList.push(
        <MainButton
          key="CancelButton"
          type="submit"
          label="Cancel"
          styleType="tertiary"
          size="medium"
          onClick={this.cancelEditMode}
        />,
      )
    } else {
      buttonList.push(
        <MainButton
          key="EditButton"
          type="button"
          label="Edit General info"
          styleType="primary"
          size="medium"
          onClick={this.setEditMode}
        />,
      )
    }
    return buttonList
  }

  componentDidMount = () => {
    const { actions } = this.props

    actions.A_GetClinicsList().then(() => {
      const { global } = this.props

      const { patientId } = this.props.match.params
      const clinic = global.currentClinicID

      if (clinic) {
        actions.A_GetPatientDetail(clinic, patientId).then(() => {
          this.getInitialPatientData()
        })
      } else {
        this.setState(() => ({
          isLoading: false,
          isClinicUnavailable: true,
        }))
      }
    })

    document.addEventListener('mousedown', this.hideValidations, false)
  }

  getInitialPatientData() {
    const { patient, global } = this.props

    const isEdited = patient.created_at !== patient.updated_at
    let patientEditData = this.getInitialData(patient)

    if (patientEditData.clinic) {
      const patientClinic = global.clinics.find(function(clinic) {
        return clinic.id === patientEditData.clinic.id
      })

      if (patientClinic) {
        patientEditData.clinic = patientClinic
      } else {
        patientEditData.clinic = global.clinics[0]
      }
    }

    const validations = this.getInitialData(obj.patientGeneralInfoValidations)
    this.setState({
      isLoading: false,
      isEdited: isEdited,
      patientEditData: patientEditData,
      validations: validations,
    })
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.hideValidations, false)
  }

  render() {
    return (
      <React.Fragment>
        <main className="main">
          {this.state.isLoading ? (
            <Loader />
          ) : this.state.isClinicUnavailable ? (
            ''
          ) : (
            <React.Fragment>
              {' '}
              {!this.state.isEdited ? (
                <FeedbackStrap
                  hasMargin={false}
                  message="This patient’s information has not been updated since it was created"
                  buttonText="Don't show this again"
                  onClick={this.updateCreatedDate}
                />
              ) : (
                ''
              )}
              <section className="main__inner main__inner--spaced">
                {this.state.patientEditData ? (
                  <React.Fragment>
                    <div className="generalInfo__navContainer col__12-3">
                      <nav className="generalInfo__nav">
                        {this.getNavList()}
                      </nav>
                    </div>
                    <div className="generalInfo col__12-9">
                      <h2 className="generalInfo__header">
                        Patient{' '}
                        <span className="regular text-blue">
                          {this.state.patientEditData.id}
                        </span>
                      </h2>
                      <p className="p secondaryMarginBottom">
                        <span className="patientImport__red">*</span>Required
                        fields are marked with an asterix
                      </p>
                      {this.getGeneralInfoSections()}
                    </div>
                  </React.Fragment>
                ) : (
                  ''
                )}
              </section>
              <aside className="clinicControls">
                {this.getFooterButtons()}
              </aside>
            </React.Fragment>
          )}
          {isTutorialFeatureEnabled ? (
            <TutorialContext.Consumer>
              {({
                toggleTutorial,
                setTutorial,
                tutorialList,
                history,
                activeTutorial,
              }) => (
                <TutorialBtn
                  tutorialList={tutorialList}
                  toggleTutorial={toggleTutorial}
                  setTutorial={setTutorial}
                  activeTutorial={activeTutorial}
                  history={history}
                />
              )}
            </TutorialContext.Consumer>
          ) : null}
        </main>
      </React.Fragment>
    )
  }
}

PatientGeneralInfo.defaultProps = {
  patient: null,
}

PatientGeneralInfo.propTypes = {
  actions: PropTypes.object.isRequired,
  patient: PropTypes.object,
  global: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

/** Need to change this as per need */
const mapStateToProps = state => {
  return {
    patient: state.patients.selectedPatient,
    global: state.global,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetClinicsList,
        A_GetPatientDetail,
        A_UpdatePatientDetail,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PatientGeneralInfo),
)
