////////  STORYBOOK EXAMPLE PAGE ///////////

import React from 'react'
import PropTypes from 'prop-types'

const ProcessStepper = ({ step, stepText, processText }) => {
  return (
    <>
      <section className="processStepper__wrapper">
        <article className="processStepper">
          <p className="processStepper__text">{processText}</p>
          <aside
            className={`processStepper__steps ${
              step > 1 ? `processStepper__steps--step${step}` : ''
            }`}
          >
            <article className="processStepper__stepper">
              <span className="processStepper__stepper__stepNumber">
                Step 0{step}
              </span>
              <span className="processStepper__stepper__stepName">
                {stepText}
              </span>
              <span className="processStepper__stepper__pointer">
                <span className="processStepper__stepper__pointerInner" />
              </span>
            </article>
            <section className="processStepper__process">
              <div className="processStepper__process__outerSection">
                <span
                  className={`processStepper__process__icon1 ${
                    step === '1'
                      ? 'processStepper__process__iconActive'
                      : parseInt(step) >= 1
                      ? 'processStepper__process__iconDone icon-tick-circle'
                      : 'processStepper__process__iconInactive'
                  }`}
                />
              </div>
              <span
                className={`processStepper__process__icon2 ${
                  step === '2'
                    ? 'processStepper__process__iconActive'
                    : parseInt(step) >= 2
                    ? 'processStepper__process__iconDone icon-tick-circle'
                    : 'processStepper__process__iconInactive'
                }`}
              />
              <div className="processStepper__process__outerSection">
                <span
                  className={`processStepper__process__icon3 ${
                    step === '3'
                      ? 'processStepper__process__iconActive'
                      : parseInt(step) >= 3
                      ? 'processStepper__process__iconDone icon-tick-circle'
                      : 'processStepper__process__iconInactive'
                  }`}
                />
              </div>
            </section>
          </aside>
          <p className="processStepper__text" />
        </article>
      </section>
    </>
  )
}

ProcessStepper.defaultProps = {
  step: 'one',
  stepText: '',
  processText: '',
}

ProcessStepper.propTypes = {
  step: PropTypes.string,
  stepText: PropTypes.string,
  processText: PropTypes.string,
}

export default ProcessStepper
