import React from 'react'
import PropTypes from 'prop-types'
import ProductInvoiceRow from './row'
import SearchResultMessage from '../../Search/ResultMessage'
import MainButton from '../../Buttons'
import SelectInput from '../../forms/Select'

const ProductInvoice = ({
  applyDiscount,
  removeDiscount,
  products,
  showNewProductRow,
  addProductRow,
  onProductSearch,
  productSearchValue,
  searchResults,
  clinicsList,
  selectedClinic,
  removeProduct,
  selectProduct,
  toggleVat,
  onClinicChange,
  hideNewProduct,
  locale,
}) => {
  return (
    <React.Fragment>
      <section className="treatment__invoiceCard">
        {clinicsList.length > 0 ? (
          <div className="form__group--fixed__panel">
            <SelectInput
              id="invoiceClinic"
              options={clinicsList}
              defaultSelected={selectedClinic}
              label="Clinic"
              hasOwnWrapper={true}
              onChange={onClinicChange}
            />
          </div>
        ) : (
          ''
        )}
        <table>
          <thead>
            <tr>
              <th>Product</th>
              <th>Sub Total</th>
              <th>Discount</th>
              <th>VAT</th>
              <th colSpan="2">Total price</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, i) => (
              <ProductInvoiceRow
                key={product.treatment_level_id}
                index={i}
                product={product}
                applyDiscount={applyDiscount}
                removeDiscount={removeDiscount}
                discount={product.item.discount}
                toggleVat={toggleVat}
                locale={locale}
                isNew={false}
                productList={searchResults}
                removeProduct={removeProduct}
                isOdd={i % 2 === 0}
              />
            ))}

            {showNewProductRow ? (
              <ProductInvoiceRow
                product={null}
                isNew={true}
                isEdit={false}
                searchProducts={onProductSearch}
                productSearchValue={productSearchValue}
                productList={searchResults}
                removeProduct={hideNewProduct}
                selectProduct={selectProduct}
                isOdd={products.length % 2 === 0}
                locale={locale}
              />
            ) : null}
          </tbody>
        </table>
        {products.length === 0 && !showNewProductRow ? (
          <SearchResultMessage message="No row items" />
        ) : (
          ''
        )}
        <div
          className={`${
            products.length === 0 && !showNewProductRow
              ? 'invoiceItem__container--center'
              : 'secondaryMarginTop'
          }`}
        >
          <MainButton
            type="button"
            styleType="primary"
            label="Add item row"
            size="small"
            clickHandler={addProductRow}
          />
        </div>
      </section>
    </React.Fragment>
  )
}

ProductInvoice.defaultProps = {
  products: [],
  onProductSearch: null,
  productSearchValue: '',
  searchResults: [],
  clinicsList: [],
  selectedClinic: null,
  selectProduct: null,
  onClinicChange: () => {},
}

ProductInvoice.propTypes = {
  products: PropTypes.array,
  applyDiscount: PropTypes.func.isRequired,
  onProductSearch: PropTypes.func,
  productSearchValue: PropTypes.string,
  searchResults: PropTypes.array,
  clinicsList: PropTypes.array,
  selectedClinic: PropTypes.number,
  removeProduct: PropTypes.func.isRequired,
  selectProduct: PropTypes.func,
  onClinicChange: PropTypes.func,
  removeDiscount: PropTypes.func.isRequired,
  addProductRow: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  showNewProductRow: PropTypes.bool.isRequired,
  toggleVat: PropTypes.func.isRequired,
  hideNewProduct: PropTypes.func.isRequired,
}

export default ProductInvoice
