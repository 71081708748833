import types from '../../actions/types'

var initialState = {
  reportRecallTable: [],
  storedReportRecallData: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_REPORT_RECALL_TABLE.FAIL:
      return { ...state }
    case types.GET_REPORT_RECALL_TABLE.OK:
      return {
        ...state,
        reportRecallTable: action.data,
      }
    case types.STORE_REPORT_RECALL_DATA:
      return {
        ...state,
        storedReportRecallData: action.data,
      }
    default:
      return state
  }
}
