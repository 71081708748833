import React from 'react'
import PropTypes from 'prop-types'
import MainButton from '../../Buttons'
import { formatCurrency } from '../../../utilities/ReusableFunctions'

const PatientSidebar = ({
  timeline,
  creator,
  subTotal,
  discount,
  VAT,
  createInvoice,
  patient,
  createInvoiceEnabled,
  locale,
}) => {
  let totalDue = parseFloat(subTotal) - parseFloat(discount) + parseFloat(VAT)

  let subTotalValue = formatCurrency(subTotal, locale)
  let discountValue = formatCurrency(discount, locale)
  let vatValue = formatCurrency(VAT, locale)

  totalDue = formatCurrency(totalDue, locale)

  const finance =
    patient && patient.finance_information
      ? patient.finance_information.find(info => {
          return info.locale === locale
        })
      : null

  return (
    <React.Fragment>
      {timeline ? (
        <section className="patient__sidebar">
          <section className="header">
            <h3 className="h3">Account summary</h3>
          </section>
          <section className="content">
            <React.Fragment>
              <div className="patient__totalBox">
                <div className="patient__totalRow">
                  <p>Total invoiced</p>
                  <span className="patient__totalSum">
                    {finance
                      ? formatCurrency(finance.total_billed, locale)
                      : ''}
                  </span>
                </div>
                <div className="patient__totalRow">
                  <p>Total paid</p>
                  <span className="patient__totalSum">
                    {finance ? formatCurrency(finance.total_paid, locale) : ''}
                  </span>
                </div>
              </div>
            </React.Fragment>
            <div className="patient__totalRow">
              <p>Total due</p>
              <span className="patient__totalSum patient__totalSum--large">
                {finance
                  ? formatCurrency(
                      finance.total_billed - finance.total_paid,
                      locale,
                    )
                  : ''}
              </span>
            </div>
          </section>
        </section>
      ) : creator ? (
        <section className="patient__breakdown">
          <h3 className="h3">Breakdown</h3>
          <div>
            <p>Sub total</p>
            <span className="patient__totalSum">{subTotalValue}</span>
          </div>
          {discount > 0 ? (
            <div>
              <p>Discount</p>
              <span className="patient__totalSum">({discountValue})</span>
            </div>
          ) : (
            ''
          )}
          {VAT > 0 ? (
            <div>
              <p>VAT</p>
              <span className="patient__totalSum">{vatValue}</span>
            </div>
          ) : (
            ''
          )}
          <section className="patient__totalRow">
            <p className="h3">Total due</p>
            <span className="patient__totalSum patient__totalSum--large">
              {totalDue}
            </span>
          </section>
          <MainButton
            label="Create invoice"
            styleType="secondary"
            size="medium"
            clickHandler={createInvoice}
            isDisabled={createInvoiceEnabled ? false : true}
          />
        </section>
      ) : (
        ''
      )}
    </React.Fragment>
  )
}

PatientSidebar.defaultProps = {
  timeline: false,
  creator: false,
  subTotal: 0,
  discount: 0,
  VAT: 0,
  createInvoice: null,
  patient: null,
  createInvoiceEnabled: true,
}

PatientSidebar.propTypes = {
  timeline: PropTypes.bool,
  creator: PropTypes.bool,
  subTotal: PropTypes.number,
  discount: PropTypes.number,
  VAT: PropTypes.number,
  createInvoice: PropTypes.func,
  patient: PropTypes.object,
  createInvoiceEnabled: PropTypes.bool,
  locale: PropTypes.string.isRequired,
}

export default PatientSidebar
