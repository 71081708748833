import React from 'react'
import PropTypes from 'prop-types'
import Nouislider from 'nouislider-react'
import 'nouislider/distribute/nouislider.css'

const MultiRangeSlider = ({
  rangeMin,
  rangeMax,
  startMin,
  startMax,
  step,
  label,
  id,
  onSlide,
  disabled,
}) => {
  return (
    <React.Fragment>
      <label className="form__label " htmlFor={id}>
        {label}
      </label>
      <div
        className={
          'multiRangeSlider__background ' +
          (disabled === true ? 'multiRangeSlider__disabled' : '')
        }
      >
        <div className="multiRangeSlider__values">
          <div className="multiRangeSlider__value multiRangeSlider__value--start">
            {startMin}
          </div>
          <div className="multiRangeSlider__value multiRangeSlider__value--end">
            {startMax}
          </div>
        </div>
        <article className="multiRangeSlider__wrapper">
          <Nouislider
            id={id}
            range={{ min: rangeMin, max: rangeMax }}
            start={[startMin, startMax]}
            step={step}
            onSlide={onSlide}
            disabled={disabled}
          />
        </article>
      </div>
    </React.Fragment>
  )
}

MultiRangeSlider.defaultProps = {
  rangeMin: 0,
  rangeMax: 100,
  startMin: 0,
  startMax: 100,
  step: 1,
  disabled: false,
}

MultiRangeSlider.propTypes = {
  rangeMin: PropTypes.number,
  rangeMax: PropTypes.number,
  startMin: PropTypes.number,
  startMax: PropTypes.number,
  step: PropTypes.number,
  label: PropTypes.string.isRequired,
  onSlide: PropTypes.isRequired,
  id: PropTypes.isRequired,
  disabled: PropTypes.bool,
}

export default MultiRangeSlider
