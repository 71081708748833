import React from 'react'
import HealthXChange from '../../../assets/Healthxchange-Logo.svg'

const Tutorial = () => {
  return (
    <article className="form patientImport__oneThird">
      <div className="patientImport__oneThird--content">
        <h2 className="h2 bold">How to export patients from E-Pharmacy™</h2>
        <span className="p">(For UK Clinics only)</span>
        <ol className="p import__list--numbered">
          <li>
            Sign into your profile at: <br />
            <a
              href="https://shop.healthxchange.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              shop.healthxchange.com
            </a>
          </li>
          <li>Click on the ‘Patients’ tab in the main navigation</li>
          <li>Click ‘Export patient data’ at the top right of the page </li>
        </ol>
      </div>
      <img
        className="healthXchange__logo"
        src={HealthXChange}
        alt="HealthXchange"
      />
    </article>
  )
}

export default Tutorial
