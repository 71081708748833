import React from 'react'
import PropTypes from 'prop-types'
import StaffRow from './StaffRow'
import MainButton from '../../../components/Buttons'
import TextInput from '../../../components/forms/Input'
import SearchResults from '../../Search/Results'
import * as obj from '../../../utilities/ReusableObjects'
import Loader from '../../../components/PatientData/Loader'

const StaffTable = ({
  staffList, //eslint-disable-line
  clinicHeadingData, //eslint-disable-line
  toggleInfoModal, //eslint-disable-line
  isEdit, //eslint-disable-line
  toggleEdit, //eslint-disable-line
  isClinicView, //eslint-disable-line
  staffFilter, //eslint-disable-line
  filterStaff, //eslint-disable-line
  searchClinicians, //eslint-disable-line
  staffModalFilter, //eslint-disable-line
  searchClick, //eslint-disable-line
  addNewRow, //eslint-disable-line
  removeStaffRow, //eslint-disable-line
  onChangeStaffRows, //eslint-disable-line
  submitEdit, //eslint-disable-line
  companyNameError, //eslint-disable-line
  emailError, //eslint-disable-line
  VirtualClinic, //eslint-disable-line
  global, //eslint-disable-line
  isLoading,
}) => {
  const getHeaderRows = () => {
    let nodes = []
    let nodeStructures = isClinicView
      ? obj.staffClinicViewTableHeader
      : isEdit
      ? obj.staffEditTableHeader
      : obj.staffTableHeader
    nodeStructures.forEach(function(nodeStructure, index) {
      nodes.push(
        <section
          key={index} //eslint-disable-line
          style={{ width: nodeStructure.width + '%' }}
          className="tableCell tableHeadCell"
        >
          {nodeStructure.title}
          {nodeStructure.title === 'USER ROLE' && !isClinicView ? (
            <span /* eslint-disable-line */
              className="icon-info tableHeadCell__icon"
              id="mobilityModalButton"
              onClick={toggleInfoModal}
            />
          ) : (
            ''
          )}
        </section>,
      )
    })
    return nodes
  }

  const SortRows = () => {
    return staffList.map(function(staff, index) {
      if (isClinicView || (VirtualClinic && isEdit)) {
        return (
          <StaffRow
            isModalRow={true}
            key={index} //eslint-disable-line
            staffDetails={staff}
            isEdit={true}
            nodeStructures={obj.staffClinicViewTableHeader}
            isClinicView={isClinicView ? isClinicView : VirtualClinic}
            onChangeStaffRows={onChangeStaffRows}
            removeStaffRow={removeStaffRow}
          />
        )
      } else {
        return (
          <StaffRow
            key={index} //eslint-disable-line
            staffDetails={staff}
            isEdit={isEdit}
            nodeStructures={obj.staffEditTableHeader}
            isClinicView={false}
            onChangeStaffRows={onChangeStaffRows}
            removeStaffRow={removeStaffRow}
            emailError={emailError}
          />
        )
      }
    })
  }

  const addStaffRow = e => {
    const bottomTable = document.getElementById('tableBottom')
    addNewRow(e)
    setTimeout(function() {
      bottomTable.scrollTop = bottomTable.scrollHeight
    }, 100)
  }

  return (
    <React.Fragment>
      {isClinicView ? (
        <article className="modal__body">
          <section className="search__staffContainer">
            <TextInput
              type="text"
              placeholder="Search clinic staff"
              label="Invite staff"
              leftIcon="icon-magnifying-glass"
              value={staffModalFilter}
              onChange={e => filterStaff(e)}
            />
            {staffModalFilter.length > 0 && searchClinicians.length > 0 ? (
              <SearchResults
                data={searchClinicians}
                idField="id"
                nodeStructures={obj.staffSearchNodes}
                searchValue={staffModalFilter}
                staffResult={true}
                onSelect={e => searchClick(e)}
              />
            ) : (
              ''
            )}
          </section>
          <p className="form__label clinicName__label">
            Staff in {clinicHeadingData.company_name} clinic
          </p>
          <article className="table">
            <div className="tableRow tableHeader">{getHeaderRows()}</div>
            <div className="staff__tableBody">
              {isLoading ? (
                <Loader />
              ) : staffList.length > 0 ? (
                SortRows()
              ) : (
                <div className="noResults_message--page noResults_message--centered">
                  <p>No staff for this clinic</p>
                </div>
              )}
            </div>
          </article>
        </article>
      ) : VirtualClinic && isEdit ? (
        <React.Fragment>
          <div className="staffHeader gutterMarginBottom">
            <section className="search__staffContainer">
              <TextInput
                type="text"
                placeholder="Search clinic staff"
                label="Invite staff"
                leftIcon="icon-magnifying-glass"
                value={staffModalFilter}
                onChange={e => filterStaff(e)}
              />
              {staffModalFilter.length > 0 && searchClinicians.length > 0 ? (
                <SearchResults
                  data={searchClinicians}
                  idField="id"
                  nodeStructures={obj.staffSearchNodes}
                  searchValue={staffModalFilter}
                  staffResult={true}
                  onSelect={e => searchClick(e)}
                />
              ) : (
                ''
              )}
            </section>
          </div>
          <article className="table">
            <div className="tableRow tableHeader">{getHeaderRows()}</div>
            <div className="staff__tableBody staff__tableBody--fixed">
              {staffList.length > 0 ? (
                SortRows()
              ) : (
                <div className="noResults_message--page noResults_message--centered">
                  <p>No staff for this clinic</p>
                </div>
              )}
            </div>
          </article>
        </React.Fragment>
      ) : VirtualClinic && !isEdit ? (
        <React.Fragment>
          <div className="staffHeader">
            <section className="search__staffContainer">
              <TextInput
                type="text"
                id="VirtualClinicSearch"
                placeholder="Filter"
                leftIcon="icon-magnifying-glass"
                value={staffFilter}
                handleOnChange={e => filterStaff(e)}
              />
            </section>
          </div>
          <span className="divider" />
          <article className="table">
            <div className="tableRow tableHeader">{getHeaderRows()}</div>
            <div className="staff__tableBody staff__tableBody--fixed">
              {staffList.length > 0 ? (
                SortRows()
              ) : (
                <div className="noResults_message--page noResults_message--centered">
                  <p>No staff for this clinic</p>
                </div>
              )}
            </div>
          </article>
        </React.Fragment>
      ) : !isClinicView ? (
        <article className="staffForm">
          {!VirtualClinic && !isClinicView ? (
            <div className="staffHeader">
              {isEdit ? (
                <div>
                  <div className="clinicGroup__name">
                    <TextInput
                      type="text"
                      id="clinicGroupName"
                      label="Clinic group name"
                      placeholder="Enter your clinic name"
                      onChange={(e, id) => onChangeStaffRows(e, id)}
                      value={clinicHeadingData.company_name}
                      error={companyNameError ? 'Required' : ''}
                    />
                  </div>
                  <div className="clinicGroup__name">
                    <TextInput
                      type="text"
                      id="clinicGroupWebsite"
                      label="Website"
                      placeholder="https://www.example.com"
                      onChange={(e, id) => onChangeStaffRows(e, id)}
                      value={
                        clinicHeadingData.company_website
                          ? clinicHeadingData.company_website
                          : ''
                      }
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div>
                    <h2 className="h1" id="clinicGroupName">
                      {clinicHeadingData.company_name}
                    </h2>
                    {global.is_admin ? (
                  <span /* eslint-disable-line */
                        onClick={toggleEdit}
                        className="buttonCircle icon-pencil"
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  <a
                    href={clinicHeadingData.company_website}
                    id="clinicGroupWebsite"
                    className="textLink"
                    target="blank"
                  >
                    {clinicHeadingData.company_website}
                  </a>
                </>
              )}
            </div>
          ) : (
            ''
          )}
          <div className="staffHeader gutterMarginBottom">
            <section className="search__staffContainer">
              <TextInput
                type="text"
                id="StaffScreenSearch"
                placeholder="Filter"
                leftIcon="icon-magnifying-glass"
                value={staffFilter}
                handleOnChange={e => filterStaff(e)}
              />
            </section>
          </div>

          <hr />
          <div className="staffBody">
            <article className="table">
              <div className="tableRow tableHeader">{getHeaderRows()}</div>
              <div
                className="staff__tableBody staff__tableBody--fixed"
                id="tableBottom"
              >
                {isLoading ? (
                  <Loader />
                ) : staffList.length > 0 ? (
                  SortRows()
                ) : (
                  <div className="noResults_message--page noResults_message--centered">
                    <p>
                      {(VirtualClinic || isClinicView) && staffFilter === ''
                        ? 'No staff for this clinic'
                        : `No staff matching '${staffFilter}'`}
                    </p>
                  </div>
                )}
              </div>
            </article>
            {!VirtualClinic && !isClinicView && !isLoading && isEdit ? (
              <>
                <div className="staffTable__addStaff">
                  <MainButton
                    type="button"
                    label="Add more staff"
                    styleType="primary"
                    size="small"
                    margin="right"
                    clickHandler={e => addStaffRow(e)}
                  />
                </div>
                <div className="staffTable__footer">
                  <MainButton
                    type="button"
                    label="Cancel"
                    styleType="tertiary"
                    size="small"
                    leftAuto={true}
                    margin="right"
                    clickHandler={toggleEdit}
                  />
                  <MainButton
                    type="submit"
                    label="Save changes"
                    styleType="secondary"
                    size="small"
                    clickHandler={submitEdit}
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </article>
      ) : (
        ''
      )}
    </React.Fragment>
  )
}

StaffTable.defaultProps = {
  staffList: [],
  staffModalFilter: '',
  searchClinicians: [],
  clinicHeadingData: {},
  isLoading: true,
}

StaffTable.propTypes = {
  staffList: PropTypes.array,
  staffModalFilter: PropTypes.string,
  searchClinicians: PropTypes.array,
  clinicHeadingData: PropTypes.object,
  global: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
}

export default StaffTable
