import Promise from 'promise'
import * as api from './api'
import * as url from '../api_urls'

export const fetchCliniciansList = () => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.clinicians)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const fetchClinicScheduleList = (year, month, day, clinicians) => {
  return new Promise((resolve, reject) => {
    return api
      .get(
        url.scheduleList +
          '?year=' +
          year +
          '&month=' +
          month +
          '&day=' +
          day +
          '&clinicians=' +
          clinicians,
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const fetchClinicScheduleMonth = (year, month, clinicians) => {
  return new Promise((resolve, reject) => {
    return api
      .get(
        url.scheduleList +
          'month/' +
          '?year=' +
          year +
          '&month=' +
          month +
          '&clinicians=' +
          clinicians,
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const deleteBookedSchedule = id => {
  return new Promise((resolve, reject) => {
    return api
      .remove(url.clinics + 'schedules/' + id + '/delete/')
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const deleteBookedScheduleChange = change => {
  return new Promise((resolve, reject) => {
    return api
      .remove(
        url.clinics +
          'schedules/' +
          change.schedule.id +
          '/changes/' +
          change.id +
          '/delete/',
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const fetchClinicScheduleConflictList = (
  clinician,
  startdate,
  enddate,
  isRecurring,
  interval,
  every,
  repeatOn,
) => {
  return new Promise((resolve, reject) => {
    return api
      .get(
        url.conflictList +
          clinician +
          '/?start_date=' +
          startdate +
          '&end_date=' +
          enddate +
          '&repeat_recurrence=' +
          isRecurring +
          '&repeat_interval=' +
          interval +
          '&repeat_every=' +
          every +
          '&repeat_on=' +
          repeatOn,
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const checkCanDeleteScheduleChange = change => {
  return new Promise((resolve, reject) => {
    return api
      .get(
        url.scheduleList +
          change.schedule.id +
          '/changes/' +
          change.id +
          '/check/',
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const postClinicScheduleChange = scheduleChange => {
  return new Promise((resolve, reject) => {
    return api
      .post(url.scheduleList, scheduleChange)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
