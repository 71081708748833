import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '../../forms/Checkbox'

const ChangeColumnModal = ({ data, checkboxHandler, ...props }) => { //eslint-disable-line
  return (
    <React.Fragment>
      <div className="patientImport__form modalReport__scrollBody">
        {data.map((row, index) => {
          return (
            <React.Fragment /* eslint-disable-line */ key={index}>
              <p className="form__label">{row.title}</p>
              <section className="changeColumn__section">
                {row.rows.map((elm, index) => {
                  return (
                    <div className="changeColumn__row" /* eslint-disable-line */ key={index}>
                      {elm.locked ? (
                        <div className="changeColumn__checkboxes">
                          <span className="icon-lock" />
                        </div>
                      ) : (
                        <div className="changeColumn__checkboxes">
                          <Checkbox
                            id={elm.heading}
                            classContainer="checkbox__importRow"
                            isListItem={true}
                            onChange={checkboxHandler}
                            checked={elm.checked}
                          />
                        </div>
                      )}
                      <div className="changeColumn__labels">
                        <span
                          className={
                            elm.checked
                              ? 'form__textField form__textField--green'
                              : 'form__textField form__textField--gray changeColumn__unchecked'
                          }
                        >
                          {elm.title}
                        </span>
                      </div>
                    </div>
                  )
                })}
              </section>
            </React.Fragment>
          )
        })}
      </div>
    </React.Fragment>
  )
}

ChangeColumnModal.defaultProps = {
  data: [],
}

ChangeColumnModal.propTypes = {
  data: PropTypes.array,
}

export default ChangeColumnModal
