import * as notification from '../services/Notifications/Notifications'
import types from './types'

export const A_GetNotificationsUnread = () => () =>
  notification
    .getNotificationsUnread()
    .then(response => {
      return response.unread
    })
    .catch(error => {
      return Promise.reject(error)
    })

export const A_GetNotificationList = (nextQuery, checkUnread) => dispatch =>
  notification
    .getNotificationList(nextQuery)
    .then(response => {
      dispatch({
        type: types.GET_NOTIFICATION_LIST.OK,
        data: response,
        nextQuery: nextQuery,
        checkUnread: checkUnread,
      })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_NOTIFICATION_LIST.FAIL, error })
      return Promise.reject(error)
    })

export const A_MarkUpdateRead = notificationId => dispatch =>
  notification
    .MarkUpdateRead(notificationId)
    .then(response => {
      dispatch({ type: types.MARK_NOTIFICATION_READ.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.MARK_NOTIFICATION_READ.FAIL, error })
      return Promise.reject(error)
    })

export const A_PostNotification = message => dispatch =>
  notification
    .postNotification(message)
    .then(response => {
      dispatch({ type: types.POST_NOTIFICATION.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.POST_NOTIFICATION.FAIL, error })
      return Promise.reject(error)
    })
