import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import moment from 'moment'

import ModalHeader from '../../../components/Modal/Header'
import ModalBody from '../../../components/Modal/Body'
import ModalFooter from '../../../components/Modal/Footer'
import AlertModal from '../../../components/Modal/Alert'
import DatePickerHHL from '../../../components/DatePicker/DatePickerHHL'
import ConfirmTooltip from '../../Tooltip/ConfirmTooltip'

import AppointmentSidebar from './Sidebar'
import AppointmentContent from './Content'
import AppointmentValidation from './ValidationWrapper'
import AppointmentStatus from './Content/View/Appointment/Status'

import {
  appointmentReasonIds,
  appointmentReasonTitles,
  appointmentPurposeIds,
  appointmentStatusIds,
  appointmentModalTypes,
} from '../../../utilities/ReusableObjects'
import {
  filterMultiSelect,
  getItems,
} from '../../../utilities/ReusableFunctions'

import { A_UpdateBookedAppointment } from '../../../actions'
import { postAppointment } from '../../../services/ClinicAppointments/ClinicAppointmentService'
import { updateBookedAppointment } from '../../../services/ClinicAppointments/ViewAppointmentDetailService'

export default function AppointmentModal({
  currentClinic,
  clinicianID,
  clinicians,
  closeModal,
  treatmentTypes,
  filteredTreatments,
  type,
  selectedDate,
  refreshAppointments,
  appointmentId,
  patient,
  appointmentDetails,
  appointmentAlerts,
  isPurposesDisabled,
  ignoreOpeningHours,
}) {
  const dispatch = useDispatch()

  const { CREATE, EDIT, VIEW } = appointmentModalTypes
  const { REPEAT, REVIEW } = appointmentPurposeIds
  const { APPOINTMENT } = appointmentReasonTitles
  const { CANCELLED, NO_SHOW, PATIENT_ARRIVED } = appointmentStatusIds
  const [modalType, setModalType] = useState(type)
  const [clinician, setClinician] = useState(
    clinicians.find(({ id }) => id === clinicianID) ||
      (clinicians.length > 0 && clinicians[0]),
  )

  const defaultAppointmentPurpose = () => {
    if (appointmentDetails && appointmentDetails.appointment_type) {
      return getItems('appointmentReasons')[appointmentDetails.appointment_type]
        .title
    }
    return APPOINTMENT
  }

  const [appointmentPurpose, setAppointmentPurpose] = useState(
    defaultAppointmentPurpose,
  )

  const [isVirtual, setIsVirtual] = useState(
    appointmentDetails && appointmentDetails.is_virtual,
  )

  const defaultSelectedDate = () => {
    if (appointmentDetails && appointmentDetails.start_date) {
      return moment.tz(appointmentDetails.start_date, 'UTC')
    }

    return selectedDate
  }

  const [appointmentDate, setAppointmentDate] = useState(defaultSelectedDate)
  const [availableSlots, setAvailableSlots] = useState([])
  const [activeSlot, setActiveSlot] = useState({})
  const [selectedPatient, setPatient] = useState(
    appointmentDetails &&
      appointmentDetails.patient &&
      appointmentDetails.patient.id
      ? appointmentDetails.patient
      : patient,
  )
  const [treatments, setTreatments] = useState(filteredTreatments)
  const [equipment, setEquipment] = useState(
    currentClinic.equipment.map(equip => ({ ...equip, checked: '' })),
  )

  const [isDatePickerOpen, toggleDatePicker] = useState(false)
  const [isPatientArrivedModalOpen, togglePatientArrivedModal] = useState(false)
  const [isCancelTooltipOpen, toggleCancelTooltip] = useState(false)
  const [isNoShowTooltipOpen, toggleNoShowTooltip] = useState(false)
  const [title, setTitle] = useState(appointmentDetails.title)

  const isReBookingAppointment =
    (type === CREATE && appointmentDetails.purpose === REPEAT) ||
    appointmentDetails.purpose === REVIEW
  const isOutstandingAction = Boolean(appointmentDetails.appointmentLength)
  const isAvailableSlotFound = Boolean(
    availableSlots.find(({ id }) => id === activeSlot.id),
  )

  const [errors, setError] = useState({})
  const hasErrors =
    Boolean(
      Object.values(errors).filter(value => value !== null && value !== '')
        .length,
    ) || !isAvailableSlotFound
  const [isBtnDisabled, setBtnDisabled] = useState(hasErrors)
  const [isCreatingAppointment, setAppointmentCreating] = useState(false)

  const datePickerRef = useRef(null)

  useEffect(() => {
    if (appointmentDetails.appointment_type && type === VIEW)
      setAppointmentPurpose(
        getItems('appointmentReasons')[appointmentDetails.appointment_type]
          .title,
      )
  }, [appointmentDetails])

  useEffect(() => {
    setBtnDisabled(hasErrors)
  }, [hasErrors])

  const dateHandlerProps = {
    isDatePickerOpen,
    toggleDatePicker,
    appointmentDate,
    setAppointmentDate,
    datePickerRef,
  }

  const slotHandlerProps = {
    activeSlot,
    setActiveSlot,
    availableSlots,
    setAvailableSlots,
  }

  const treatmentHandlerProps = {
    treatments,
    filteredTreatments,
    setTreatments,
    treatmentTypes,
  }

  const equipmentHandlerProps = {
    equipment,
    setEquipment,
  }

  const errorHandlerProps = {
    errors,
    setError,
  }

  const patientHandlerProps = {
    selectedPatient,
    setPatient,
  }

  const titleHandlerProps = {
    title,
    setTitle,
  }

  const viewTitle =
    appointmentDetails &&
    appointmentDetails.patient &&
    `${appointmentDetails.patient.first_name} ${appointmentDetails.patient.last_name}`

  const meetingPersonalTitle = appointmentDetails && appointmentDetails.title

  const appointmentTitle = {
    create: {
      [appointmentPurpose]: `New ${appointmentPurpose}`,
    }[appointmentPurpose],
    edit: viewTitle,
    view: {
      Appointment: viewTitle,
      Meeting: meetingPersonalTitle,
      Personal: meetingPersonalTitle,
    }[appointmentPurpose],
  }[modalType]

  const start_date = moment.tz(appointmentDetails.start_date, 'UTC')
  const end_date = moment.tz(appointmentDetails.end_date, 'UTC')

  const subtitles = {
    [appointmentPurpose]: appointmentDetails
      ? [
          start_date.format('DD.MM.YY'),
          `${start_date.format('HH:mm')} - ${end_date.format('HH:mm')}`,
        ]
      : [],
  }[appointmentPurpose]

  const rightButtons = {
    create: [
      {
        style: 'tertiary',
        label: 'Cancel',
        size: 'small',
        events: { onClick: () => closeModal() },
      },
      {
        type: 'submit',
        style: 'secondary',
        label: 'Save',
        loadingText: 'Creating appointment...',
        isLoading: isCreatingAppointment,
        size: 'small',
        isDisabled: isBtnDisabled,
      },
    ],
    view: [
      {
        style: 'destructive',
        label: 'No show',
        size: 'small',
        isDisplayed:
          appointmentDetails.status !== appointmentStatusIds.NO_SHOW &&
          appointmentDetails.status < appointmentStatusIds.ACTIVE,
        events: {
          onClick: () => toggleNoShowTooltip(true),
        },
      },
      {
        style: 'tertiary',
        label: 'Cancel',
        size: 'small',
        events: { onClick: () => closeModal() },
      },
      {
        style: 'primary',
        label: 'Confirm patient arrival',
        size: 'small',
        isDisplayed:
          appointmentDetails &&
          appointmentDetails.status === appointmentStatusIds.PENDING &&
          start_date.isSame(
            moment.tz(new Date(), currentClinic.timezone).utc(),
            'day',
          ),
        events: { onClick: () => togglePatientArrivedModal(true) },
      },
    ],
    edit: [
      {
        style: 'tertiary',
        label: 'Cancel',
        size: 'small',
        events: { onClick: () => setModalType('view') },
      },
      {
        type: 'submit',
        style: 'secondary',
        label: 'Save changes',
        size: 'small',
      },
    ],
  }[modalType]

  const leftButtons = {
    view: [
      {
        style: 'tertiary',
        label: 'Edit appointment',
        size: 'small',
        isDisplayed:
          appointmentDetails.status > appointmentStatusIds.NO_SHOW &&
          appointmentDetails.status < appointmentStatusIds.ACTIVE,
        events: {
          onClick: () => setModalType(EDIT),
        },
      },
    ],
    edit: [
      {
        style: 'alert',
        label: `Cancel ${appointmentPurpose}`,
        size: 'small',
        events: {
          onClick: () => toggleCancelTooltip(true),
        },
      },
    ],
  }[modalType]

  const createAppointment = async ({ target }) => {
    let appointment_type =
      appointmentReasonIds[
        Object.keys(appointmentReasonIds).find(
          key => appointmentReasonTitles[key] === appointmentPurpose,
        )
      ]

    let appointmentData = {
      id: appointmentId,
      clinicId: currentClinic.id,
      clinician: { id: clinician.id },
      start_date: activeSlot.start_date,
      end_date: activeSlot.end_date,
      appointment_type,
    }

    switch (appointment_type) {
      case appointmentReasonIds.APPOINTMENT:
        appointmentData = {
          ...appointmentData,
          purpose: target.appointmentType
            ? Number(target.appointmentType.value)
            : null,
          treatments: filterMultiSelect(treatments),
          room: { id: Number(target.room.value) },
          additional_equipment: filterMultiSelect(equipment),
          is_virtual: isVirtual,
        }

        if (modalType === CREATE) {
          appointmentData['patient'] = selectedPatient

          if (target.notes.value !== '') {
            appointmentData['notes'] = [{ text: target.notes.value }]
          }

          if (appointmentDetails && appointmentDetails.appointment_follow_up) {
            appointmentData['appointment_follow_up'] =
              appointmentDetails.appointment_follow_up
          }
        }

        if (validateOnSubmit(target)) {
          return
        }
        break
      default:
        appointmentData = {
          ...appointmentData,
          title: target.title ? target.title.value : '',
        }
        break
    }

    try {
      setBtnDisabled(true)
      setAppointmentCreating(true)
      modalType === CREATE
        ? await postAppointment(appointmentData)
        : await updateBookedAppointment(appointmentData)
      setAppointmentCreating(false)
      refreshAppointments()
      closeModal()
    } catch {
      setAppointmentCreating(false)
    }
  }

  const validateOnSubmit = target => {
    let errors = false
    const hasCheckedTreatments = treatments.some(
      ({ checked }) => checked === 'checked',
    )

    const values = {
      appointmentType: target.appointmentType
        ? target.appointmentType.value
        : '',
      treatmentType: target.treatmentType ? target.treatmentType.value : '',
      room: target.room ? target.room.value : '',
    }
    Object.entries(values).map(([key, value]) => {
      if (value === undefined || value === '-1') {
        setError(err => ({ ...err, [`${key}HasError`]: 'Required' }))
        errors = true
      }
    })
    if (!hasCheckedTreatments) {
      setError(err => ({ ...err, treatmentsHasError: 'Required' }))
      errors = true
    }

    if (
      modalType === CREATE &&
      (selectedPatient === null ||
        selectedPatient === undefined ||
        !Object.values(selectedPatient).length)
    ) {
      setError(err => ({ ...err, patientHasError: 'Required' }))
      errors = true
    }

    return errors
  }

  return createPortal(
    <div className="fadeInFlyAnimate modal__background">
      <article
        style={{ display: 'block' }}
        className="modal modal--overlay col__12-9 fadeInFlyAnimate"
      >
        <form
          onSubmit={e => {
            e.preventDefault()
            createAppointment(e)
          }}
        >
          <ModalHeader
            title={appointmentTitle}
            closeHandler={closeModal}
            subtitles={
              modalType === VIEW || modalType === EDIT ? subtitles : []
            }
          />
          {appointmentDetails.status && modalType === VIEW ? (
            <AppointmentStatus status={appointmentDetails.status} />
          ) : (
            ''
          )}
          <ModalBody>
            <AppointmentValidation
              {...errorHandlerProps}
              {...patientHandlerProps}
              setTreatments={setTreatments}
              appointmentPurpose={appointmentPurpose}
              isReBookingAppointment={isReBookingAppointment}
              title={title}
            >
              <article
                className={`createAppointment__section createAppointment__section--left ${modalType}Appointment__section`}
              >
                <AppointmentSidebar
                  type={modalType}
                  clinician={clinician}
                  setClinician={setClinician}
                  clinicians={clinicians}
                  clinicId={currentClinic.id}
                  appointmentDetails={appointmentDetails}
                  timezone={currentClinic.timezone}
                  dateHandlerProps={dateHandlerProps}
                  slotHandlerProps={slotHandlerProps}
                  errorHandlerProps={errorHandlerProps}
                  closeModal={closeModal}
                  ignoreOpeningHours={ignoreOpeningHours}
                />
              </article>
              <article className="createAppointment__section createAppointment__section--right">
                <AppointmentContent
                  type={modalType}
                  appointmentId={appointmentId}
                  clinicId={currentClinic.id}
                  appointmentPurpose={appointmentPurpose}
                  appointmentDetails={appointmentDetails}
                  appointmentAlerts={appointmentAlerts}
                  setAppointmentPurpose={setAppointmentPurpose}
                  isPurposesDisabled={isPurposesDisabled}
                  timezone={currentClinic.timezone}
                  rooms={activeSlot.rooms || []}
                  isReBookingAppointment={isReBookingAppointment}
                  isOutstandingAction={isOutstandingAction}
                  isVirtual={isVirtual}
                  setIsVirtual={setIsVirtual}
                  {...patientHandlerProps}
                  {...errorHandlerProps}
                  {...treatmentHandlerProps}
                  {...equipmentHandlerProps}
                  {...titleHandlerProps}
                />
              </article>
            </AppointmentValidation>
          </ModalBody>
          <ModalFooter
            rightButtons={
              modalType === CREATE || modalType === EDIT
                ? rightButtons
                : modalType === VIEW &&
                  appointmentPurpose === APPOINTMENT &&
                  rightButtons
            }
            leftButtons={leftButtons}
            rightChildren={
              <>
                {isNoShowTooltipOpen && (
                  <ConfirmTooltip
                    title="Confirm no show?"
                    bodyText="This action cannot be undone"
                    removeLabel="Yes"
                    keepLabel="No"
                    onKeepClick={() => toggleNoShowTooltip(false)}
                    onRemoveClick={async () => {
                      await dispatch(
                        A_UpdateBookedAppointment({
                          id: appointmentId,
                          status: NO_SHOW,
                        }),
                      )
                      refreshAppointments()
                      closeModal()
                    }}
                  />
                )}
              </>
            }
          >
            {isCancelTooltipOpen && (
              <ConfirmTooltip
                title="Confirm Cancel?"
                bodyText="This action cannot be undone"
                removeLabel="Remove"
                keepLabel="Keep"
                onKeepClick={() => toggleCancelTooltip(false)}
                onRemoveClick={async () => {
                  await dispatch(
                    A_UpdateBookedAppointment({
                      id: appointmentId,
                      status: CANCELLED,
                    }),
                  )
                  refreshAppointments()
                  closeModal()
                }}
              />
            )}
          </ModalFooter>
        </form>
      </article>
      {isDatePickerOpen && (
        <DatePickerHHL
          calendarType="appointment_calendar"
          dpInput={datePickerRef.current}
          changeDate={e => {
            let date = moment.tz(e, 'DD/MM/YYYY', 'UTC')
            date.hours(appointmentDate.hours())
            date.minutes(appointmentDate.minutes())

            setAppointmentDate(date)
            toggleDatePicker(false)
          }}
          datePickerInputDate={appointmentDate.format('DD/MM/YYYY')}
        />
      )}
      {isPatientArrivedModalOpen && (
        <AlertModal
          title="Has your patient arrived?"
          message="Click confirm if your patient has arrived, otherwise click cancel."
          leftButtons={[
            {
              style: 'tertiary',
              label: 'Cancel',
              size: 'small',
              events: { onClick: () => togglePatientArrivedModal(false) },
            },
          ]}
          rightButtons={[
            {
              style: 'tertiary',
              label: 'Confirm',
              size: 'small',
              events: {
                onClick: async () => {
                  await dispatch(
                    A_UpdateBookedAppointment({
                      id: appointmentId,
                      status: PATIENT_ARRIVED,
                    }),
                  )
                  refreshAppointments()
                  togglePatientArrivedModal(false)
                },
              },
            },
          ]}
          closeAlert={() => togglePatientArrivedModal(false)}
          modalClassWidth="col__12-7"
        />
      )}
    </div>,
    document.getElementById('root-modal'),
  )
}

AppointmentModal.defaultProps = {
  selectedDate: moment.tz(new Date(), 'UTC'),
  appointmentId: null,
  appointmentDetails: null,
  appointmentAlerts: [],
  patient: {},
  isPurposesDisabled: false,
  filteredTreatments: [],
  treatmentTypes: [],
  ignoreOpeningHours: false,
}

AppointmentModal.propTypes = {
  type: PropTypes.string.isRequired,
  clinicianID: PropTypes.number.isRequired,
  clinicians: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  currentClinic: PropTypes.object.isRequired,
  refreshAppointments: PropTypes.func.isRequired,
  patient: PropTypes.object,
  selectedDate: PropTypes.instanceOf(moment),
  appointmentId: PropTypes.number,
  appointmentDetails: PropTypes.object,
  appointmentAlerts: PropTypes.array,
  isPurposesDisabld: PropTypes.bool,
  filteredTreatments: PropTypes.array,
  treatmentTypes: PropTypes.array,
  ignoreOpeningHours: PropTypes.bool,
}
