import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  appointmentReasonIds,
  appointmentReasonTitles,
} from '../../../../utilities/ReusableObjects'

export default function ValidationWrapper({
  errors,
  setError,
  selectedPatient,
  setTreatments,
  appointmentPurpose,
  children,
  isReBookingAppointment,
  title,
}) {
  useEffect(() => {
    Object.entries(errors).forEach(([key, value]) => {
      if (value === '' || (value === null && isReBookingAppointment)) {
        const { [key]: omit, ...rest } = errors //eslint-disable-line
        setError({ ...rest })
      }
    })
  }, [errors])

  useEffect(() => {
    const changeErrors = () => {
      const hasPatient =
        selectedPatient !== null &&
        selectedPatient !== undefined &&
        Boolean(Object.values(selectedPatient).length)
      const defaultAppointmentErrors = {
        appointmentTypeHasError: null,
        treatmentTypeHasError: null,
        treatmentsHasError: null,
        roomHasError: null,
      }
      setError(err => {
        let appointment_type =
          appointmentReasonIds[
            Object.keys(appointmentReasonIds).find(
              key => appointmentReasonTitles[key] === appointmentPurpose,
            )
          ]

        let foundErrors

        switch (appointment_type) {
          case appointmentReasonIds.APPOINTMENT:
            foundErrors = defaultAppointmentErrors

            if (hasPatient === false) {
              foundErrors['patientHasError'] = null
            }
            break
          default:
            foundErrors = {
              titleHasError: title ? null : 'Required',
            }
            break
        }

        return {
          ...foundErrors,
          slotsHasError: err.slotsHasError ? err.slotsHasError : '',
        }
      })
      setTreatments([])
    }
    changeErrors()
  }, [appointmentPurpose])

  return <>{children}</>
}

ValidationWrapper.defaultProps = {
  selectedPatient: {},
  isReBookingAppointment: false,
  title: '',
}

ValidationWrapper.propTypes = {
  errors: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
  selectedPatient: PropTypes.object,
  setTreatments: PropTypes.func.isRequired,
  isReBookingAppointment: PropTypes.bool,
  appointmentPurpose: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}
