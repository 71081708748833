import types from '../../actions/types'

let initialState = {
  isLoggedIn: false,
  token: null,
  user: {},
  account: {},
  errorMessage: null,
  verifyPassword: true,
  hasValues: false,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN.OK: {
      let errorMessage = ''

      if (action.user.account && action.user.account.is_onboarding === true) {
        errorMessage = 'Please continue your onboarding on the iOS app'
      }

      return {
        ...state,
        isLoggedIn: errorMessage === '',
        token: action.token,
        user: action.user,
        account: {
          ...action.user.account,
          sms: {},
        },
        errorMessage: errorMessage,
        verifyPassword: true,
      }
    }
    case types.LOGIN.FAIL: {
      var error = action.error.data.errors

      return {
        ...state,
        verifyPassword: false,
        isLoggedIn: false,
        errorMessage: error['username'] || error['non_field_errors'][0],
      }
    }
    case types.CLEAR_TOKEN:
      return { ...state, isLoggedIn: false }
    case types.GET_ACCOUNT.REQ:
      return {
        ...state,
        account: {
          ...state.account,
          isFetching: true,
        },
      }
    case types.GET_ACCOUNT.OK:
      return {
        ...state,
        account: {
          isFetching: false,
          id: action.data.id,
          company_name: action.data.company_name,
          company_website: action.data.company_website,
          sms: {
            ...action.data.text_messaging_configuration,
          },
        },
      }
    case types.CLINICIAN_LIST_PATCH.REQ:
      return {
        ...state,
        account: {
          ...state.account,
          isFetching: true,
        },
      }
    case types.CLINICIAN_LIST_PATCH.OK:
      return {
        ...state,
        account: {
          ...state.account,
          isFetching: false,
          company_name: action.data.company_name,
          company_website: action.data.company_website,
        },
      }
    default:
      return state
  }
}
