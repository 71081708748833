import types from '../../actions/types'

var initialState = {
  clinicians: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CLINICIANS_LIST.FAIL:
      return { ...state }
    case types.GET_CLINICIANS_LIST.OK:
      return {
        ...state,
        clinicians: action.data,
      }
    case types.CLINICIAN_LIST_PATCH.OK:
      return {
        ...state,
        clinicians: action.data,
      }
    case types.CLINICIAN_LIST_PATCH.FAIL:
      return { ...state }
    default:
      return state
  }
}
