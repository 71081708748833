import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { A_CalendarHeaderActions } from '../../actions'

import CalendarHeader from './CalendarHeader'
import WeeklyCalendar from './WeeklyCalendar'
import Placeholder from '../../components/Tutorials/Placeholder'
import { getTasks } from '../../services/OnBoarding/auth'
import moment from 'moment'

const WeeklyCalendarContainer = props => {
  useEffect(() => {
    setTimeout(() => {
      const timeIndicator = document.getElementById('currentTime')
      return timeIndicator ? toCurrentTime() : null
    }, 1250)
  }, [])

  useEffect(() => {
    async function fetchTasks() {
      const tasks = await getTasks()
      props.formatTasks(tasks)
    }
    fetchTasks()
  }, [])

  const toCurrentTime = () => {
    const { actions, selectedDate, timezone } = props
    if (isSameDate(selectedDate)) {
      const timeIndicator = document.getElementById('currentTime')
      if (timeIndicator) {
        timeIndicator.scrollIntoView()
      }
    } else {
      actions.A_CalendarHeaderActions(moment.tz(new Date(), timezone))
    }
  }

  const isSameDate = date => {
    const { timezone } = props

    return moment.tz(new Date(), timezone).isSame(date, 'day')
  }

  return (
    <main className="main main--calendar">
      {!props.hasCompletedAllTasks ? (
        <Placeholder activeTasks={props.activeTasks} />
      ) : null}
      <CalendarHeader toCurrentTime={toCurrentTime} isSameDay={isSameDate} />
      <WeeklyCalendar />
    </main>
  )
}

WeeklyCalendarContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  selectedDate: PropTypes.instanceOf(moment).isRequired,
  timezone: PropTypes.string.isRequired,
  formatTasks: PropTypes.func.isRequired,
  hasCompletedAllTasks: PropTypes.bool.isRequired,
  activeTasks: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  selectedDate: state.calendar.selectedDate,
})

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_CalendarHeaderActions,
      },
      dispatch,
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WeeklyCalendarContainer)
