import types from '../../actions/types'
import { getFileName } from '../../utilities/ReusableFunctions'

var initialState = {
  patientRecordFields: [],
  fileHeaders: [],
  importedFileSize: '',
  errountCount: 0,
  errorCsv: '',
  successCount: 0,
  handlebtnClicked: null,
}

export default (state = initialState, action) => {
  let count = 0
  switch (action.type) {
    case types.GET_IMPORTED_DATA.OK: {
      let fileHeaders = []
      let data = action.data.field_headers.split(',')
      data.forEach(ele => {
        fileHeaders.push({
          id: count,
          title: ele,
          isDisabled: false,
        })
        count++
      })
      fileHeaders.unshift({
        id: -1,
        title: 'Please select...',
        isDisabled: false,
      })

      return {
        ...state,
        fileHeaders: fileHeaders,
        importedFileName: getFileName(action.data.imported_file),
        importedFileSize: action.data.imported_file_size,
        errountCount: action.data.failed,
        errorCsv: action.data.signed_errors_file,
        successCount: action.data.successful,
      }
    }

    case types.GET_IMPORTED_DATA.FAIL:
      return { ...state }

    case types.UPDATE_FILE_HEADERS: {
      let updatedList = []
      action.updatedData.forEach(data => {
        if (!data.isSelected) {
          updatedList.push(data)
          count++
        }
      })
      return {
        ...state,
        fileHeaders: updatedList,
      }
    }
    default:
      return state
  }
}
