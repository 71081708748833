import React from 'react'
import { storiesOf } from '@storybook/react'
import ReportTable from '../ReportsTable'

import {
  cardTypes,
  paymentTypes,
  invoiceStatuses,
} from '../../../utilities/ReusableObjects'

export const financeReportData = [
  {
    id: 1,
    patient: 'Jonothan Ridgeway',
    clinician: 'John Curran',
    clinic: 'Newcastle',
    status: 'Paid',
    treatment: 'Acne Treatment',
    inventory: 'Derma Cream (50ml)',
    total: '£400',
    paid: '£100',
    payment: 'Card',
    card: 'Visa Debit',
    remaining: '£100',
    note: '1st payment on a 4 week payment plan',
  },
  {
    id: 2,
    patient: 'Ahmed Nazeri',
    clinician: 'Thoma Fulloway',
    purpose: 'Purely cosmetic',
    clinic: 'Heaton',
    status: 'Unpaid',
    treatment: '-',
    inventory: '£800',
    total: '£400',
    paid: '£100',
    payment: 'Cash',
    card: 'Matercard',
    remaining: '£300',
    note: 'Payment made to catch up on monthly scheme',
  },
]

const response = [
  {
    id: 1,
    amount: 800,
    created_at: '2019-11-25T12:23:41Z',
    date_of_payment: '2019-11-25',
    payment_type: 1,
    card_type: 0,
    bill: {
      id: 1,
      items: [
        {
          id: 1,
          text: 'asdasd',
          consultation_level: null,
          treatment_level: null,
        },
      ],
      total: 1000,
      created_at: '2019-11-25T12:23:07Z',
      payment_status: 1,
      notes: '',
      consultation: {
        id: 1,
        appointment: {
          id: 1,
          clinician: {
            id: 2,
            full_name: 'mark forster',
          },
          treatments: [
            {
              id: 6,
              title:
                'Discussed how management of secondary clinical findings will help manage primary concerns',
              treatment_type: {
                id: 3,
                title: 'Add Primary Concerns',
                hide: false,
              },
              locked: true,
            },
          ],
        },
      },
      patient: {
        id: 1,
        full_name: 'James Forster',
      },
    },
    created_by: {
      id: 2,
      full_name: 'mark forster',
    },
    taken_by: {
      id: 2,
      full_name: 'mark forster',
    },
    clinic: {
      id: 1,
      name: 'Newcastle',
      vat: 20,
    },
    notes: '',
  },
  {
    id: 2,
    amount: 100,
    created_at: '2019-11-27T10:10:15Z',
    date_of_payment: '2019-11-27',
    payment_type: 0,
    card_type: null,
    bill: {
      id: 1,
      items: [
        {
          id: 1,
          text: 'asdasd',
          consultation_level: null,
          treatment_level: null,
        },
      ],
      total: 1000,
      created_at: '2019-11-25T12:23:07Z',
      payment_status: 1,
      notes: '',
      consultation: {
        id: 1,
        appointment: {
          id: 1,
          clinician: {
            id: 2,
            full_name: 'mark forster',
          },
          treatments: [
            {
              id: 6,
              title:
                'Discussed how management of secondary clinical findings will help manage primary concerns',
              treatment_type: {
                id: 3,
                title: 'Add Primary Concerns',
                hide: false,
              },
              locked: true,
            },
          ],
        },
      },
      patient: {
        id: 1,
        full_name: 'James Forster',
      },
    },
    created_by: {
      id: 2,
      full_name: 'mark forster',
    },
    taken_by: {
      id: 2,
      full_name: 'mark forster',
    },
    clinic: {
      id: 1,
      name: 'Newcastle',
      vat: 20,
    },
    notes: '',
  },
]

const newFinanceReportData = response.map(
  ({
    id,
    bill,
    amount,
    taken_by,
    clinic: { name },
    card_type,
    payment_type,
    notes,
  }) => ({
    id,
    patient: bill ? bill.patient.full_name : '',
    clinician: taken_by.full_name,
    clinic: name,
    status: bill ? invoiceStatuses[bill.payment_status] : '',
    treatment: bill
      ? bill.consultation.appointment.treatments.map(({ title }) => title)[0]
      : '',
    inventory: '',
    total: bill ? String(bill.total) : '',
    paid: String(amount),
    payment: paymentTypes.find(({ id }) => id === payment_type).title,
    card:
      card_type !== null
        ? cardTypes.find(({ id }) => id === card_type).title
        : null,
    remaining: bill ? String(bill.total - amount) : '',
    note: notes,
  }),
)

export const financeReportHeaders = [
  {
    title: 'Patient Name',
    priority: 'primary',
    type: 'data',
    value: 'patient',
    width: 180,
  },
  {
    title: 'Appointment Purpose',
    priority: 'secondary',
    width: 180,
    type: 'data',
    value: 'purpose',
  },
  {
    title: 'Treatment',
    type: 'data',
    priority: 'secondary',
    value: 'category',
    width: 200,
  },
  {
    title: 'Treatment Options',
    type: 'data',
    priority: 'secondary',
    value: 'treatment',
    width: 200,
  },
  {
    title: 'Inventory Items',
    type: 'data',
    priority: 'secondary',
    value: 'inventory',
    width: 200,
  },
  {
    title: 'Clinic',
    type: 'data',
    priority: 'primary',
    value: 'clinic',
    width: 140,
  },
  {
    title: 'Clinician',
    type: 'data',
    priority: 'primary',
    value: 'clinician',
    width: 140,
  },
  {
    title: 'Taken By',
    type: 'data',
    priority: 'primary',
    value: 'taken_by',
    width: 140,
  },
  {
    title: 'Associated To',
    type: 'data',
    priority: 'primary',
    value: 'associated_to',
    width: 140,
  },
  {
    title: 'Payment taken',
    type: 'data',
    priority: 'secondary',
    value: 'taken',
    width: 140,
  },
  {
    title: 'Invoice Status',
    type: 'data',
    priority: 'secondary',
    value: 'status',
    width: 140,
  },
  {
    title: 'Bill ID',
    priority: 'secondary',
    type: 'data',
    value: 'id',
    width: 80,
  },
  {
    title: 'Total Treatments',
    type: 'data',
    priority: 'secondary',
    value: 'totalTreatments',
    width: 180,
  },
  {
    title: 'Total Inventory',
    type: 'data',
    priority: 'secondary',
    value: 'totalInventory',
    width: 180,
  },
  {
    title: 'Total VAT',
    type: 'data',
    priority: 'secondary',
    value: 'totalVAT',
    width: 140,
  },
  {
    title: 'Invoice Total',
    type: 'data',
    priority: 'secondary',
    value: 'total',
    width: 120,
  },
  {
    title: 'Amount Paid',
    type: 'data',
    priority: 'primary',
    value: 'paid',
    width: 120,
  },
  {
    title: 'Payment Type',
    type: 'data',
    priority: 'secondary',
    value: 'payment',
    width: 120,
  },
  {
    title: 'Card Type',
    type: 'data',
    priority: 'secondary',
    value: 'card',
    width: 120,
  },
  {
    title: 'Total due',
    type: 'data',
    priority: 'primary',
    value: 'remaining',
    width: 120,
  },
  {
    title: 'Notes?',
    type: 'data',
    priority: 'secondary',
    value: 'note',
    width: 340,
  },
]

storiesOf('PresentationComponents/Report/', module).add('Finance', () => (
  <ReportTable
    data={newFinanceReportData}
    nodeStructures={financeReportHeaders}
  />
))
