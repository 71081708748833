import React from 'react'
import ProfileCard from './ProfileCard'
import ProfileCalendar from './ProfileCalendar'
import { TutorialContext } from '../../App'
import TutorialBtn from '../Tutorials/TutorialBtn'
import { isTutorialFeatureEnabled } from '../../utilities/featureToggle'

const PractitionerProfile = () => (
  <main className="main main--calendar">
    <div className="main__calendar_userProfile">
      {isTutorialFeatureEnabled ? (
        <TutorialContext.Consumer>
          {({
            toggleTutorial,
            setTutorial,
            tutorialList,
            history,
            activeTutorial,
          }) => (
            <TutorialBtn
              toggleTutorial={toggleTutorial}
              tutorialList={tutorialList}
              setTutorial={setTutorial}
              activeTutorial={activeTutorial}
              history={history}
            />
          )}
        </TutorialContext.Consumer>
      ) : null}
      <ProfileCard />
      <ProfileCalendar />
    </div>
  </main>
)

export default PractitionerProfile
