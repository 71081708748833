import React from 'react'
import PropTypes from 'prop-types'

class ExportCSV extends React.Component {
  //   label,
  //   type,
  //   styleType,
  //   size,
  //   customClasses,
  //   margin,
  //   leftAuto,
  //   closeHandler,
  //   clickHandler,
  //   total,
  //   id,
  //   isDisabled,
  //   ...props
  // }) => {

  buttonClasses = () => {
    const { customClasses, styleType, size } = this.props
    let classList = customClasses //eslint-disable-line
    switch (styleType) { //eslint-disable-line
      case 'primary':
        classList += ' buttonPrimary'
        break
      case 'secondary':
        classList += ' buttonSecondary'
        break
      case 'tertiary':
        classList += ' buttonTertiary'
        break
      case 'destructive':
        classList += ' buttonDestructive'
        break
      case 'alert':
        classList += ' buttonAlert'
        break
    }
    if (size === 'small') {
      classList += ' button--small'
    }
    return classList
  }

  createRow = row => {
    let line = [],
      cols = row.getElementsByClassName('searchResult__header__text')
    for (const col of cols) { //eslint-disable-line
      line.push(col.innerText.replace(/,/g, ''))
    }
    return line.join(',')
  }

  exportTable = () => {
    const { downloadCSV } = this
    const { table } = this.props //eslint-disable-line
    const csv = []
    const headerRows = table().getElementsByClassName(
      'searchResults__row--table-header',
    )
    const rows = table().getElementsByClassName('searchResults__row--table')
    const footerRows = table().getElementsByClassName(
      'searchResults__row--table-footer',
    )

    for (const headerRow of headerRows) { //eslint-disable-line
      csv.push(this.createRow(headerRow))
    }

    for (const row of rows) { //eslint-disable-line
      csv.push(this.createRow(row))
    }

    for (const footerRow of footerRows) { //eslint-disable-line
      csv.push(this.createRow(footerRow))
    }

    downloadCSV(csv.join('\r\n'))
  }
  downloadCSV = csv => {
    const csvFile = new Blob([csv], { type: 'text/csv' })
    const downloadLink = document.createElement('a')
    downloadLink.download = 'export.csv'
    downloadLink.href = window.URL.createObjectURL(csvFile)
    downloadLink.style.display = 'none'
    document.body.appendChild(downloadLink)
    downloadLink.click()
  }

  render() {
    const { isDisabled, id, label } = this.props
    return (
      <button
        id={id}
        className={this.buttonClasses()}
        onClick={this.exportTable}
        disabled={isDisabled}
      >
        {label}
      </button>
    )
  }
}

ExportCSV.defaultProps = {
  customClasses: '',
  isDisabled: false,
  id: 0,
}

ExportCSV.propTypes = {
  customClasses: PropTypes.string,
  styleType: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  id: PropTypes.number,
  label: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
}

export default ExportCSV
