import React from 'react'
import PropTypes /*, { number } */ from 'prop-types'
import TreatmentInvoiceRow from './row'

const TreatmentInvoice = ({
  applyDiscount,
  removeDiscount,
  treatments,
  toggleVatModal,
  isCosmeticConsultation,
  isVatModalOpen,
  locale,
  toggleVat,
  setVatModalId,
}) => {
  return (
    <React.Fragment>
      <section className="treatment__invoiceCard">
        <table>
          <thead>
            <tr>
              <th>Treatment</th>
              <th>Sub Total</th>
              <th>Discount</th>
              <th>VAT</th>
              <th colSpan="2">Total price</th>
            </tr>
          </thead>
          <tbody>
            {treatments.map(treatment => (
              <TreatmentInvoiceRow
                key={treatment.consultation_level_id}
                treatment={treatment}
                applyDiscount={applyDiscount}
                removeDiscount={removeDiscount}
                discount={treatment.item.discount}
                toggleVat={toggleVat}
                toggleVatModal={toggleVatModal}
                isCosmeticConsultation={isCosmeticConsultation}
                setVatModalId={setVatModalId}
                isVatModalOpen={isVatModalOpen}
                locale={locale}
              />
            ))}
          </tbody>
        </table>
      </section>
    </React.Fragment>
  )
}

TreatmentInvoice.defaultProps = {
  treatments: [],
  isVatModalOpen: false,
}

TreatmentInvoice.propTypes = {
  treatments: PropTypes.array,
  applyDiscount: PropTypes.func.isRequired,
  removeDiscount: PropTypes.func.isRequired,
  toggleVatModal: PropTypes.func.isRequired,
  isCosmeticConsultation: PropTypes.bool.isRequired,
  setVatModalId: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  isVatModalOpen: PropTypes.bool,
  toggleVat: PropTypes.func.isRequired,
}

export default TreatmentInvoice
