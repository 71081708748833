import * as charts from '../services/Analytics/AnalyticsChartServices'
import * as treatments from '../services/Analytics/TreatmentSummaryService'
import * as reports from '../services/Report/Report'
import types from './types'

export const A_GetChartDetails = (chart, params) => dispatch =>
  charts
    .getChartDetails(chart, params)
    .then(response => {
      dispatch({ type: types.GET_GRAPH_DETAILS.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_GRAPH_DETAILS.FAIL, error })
      return Promise.reject(error)
    })

export const A_ValidateFilterData = data => ({
  type: types.VALIDATE_FILTER_DATA,
  data,
})

export const A_ToggleAnalyticsApplyButton = bool => ({
  type: types.DISABLE_ANALYTICS_APPLY_BUTTON,
  bool,
})

export const A_GetTreatmentSummaryData = params => dispatch =>
  treatments
    .getTreatmentSummaryData(params)
    .then(response => {
      dispatch({ type: types.GET_APPOINTMENT_SUMMARY_DATA.OK, response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_APPOINTMENT_SUMMARY_DATA.FAIL, error })
      return Promise.reject(error)
    })

export const A_GetAppointmentTreatmentSummaryData = params => dispatch =>
  treatments
    .getAppointmentTreatmentSummaryData(params)
    .then(response => {
      dispatch({
        type: types.GET_APPOINTMENT_TREATMENT_SUMMARY_DATA.OK,
        response,
      })
      return response
    })
    .catch(error => {
      dispatch({
        type: types.GET_APPOINTMENT_TREATMENT_SUMMARY_DATA.FAIL,
        error,
      })
      return Promise.reject(error)
    })

export const A_GetReportTable = params => dispatch =>
  reports
    .getReportTable(params)
    .then(response => {
      dispatch({ type: types.GET_REPORT_TABLE.OK, data: response })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_REPORT_TABLE.FAIL, error })
      return Promise.reject(error)
    })

export const A_StoreReportData = data => ({
  type: types.STORE_REPORT_DATA,
  data,
})

export const A_GetReportRecallTable = params => dispatch =>
  reports
    .getReportRecallTable(params)
    .then(response => {
      dispatch({ type: types.GET_REPORT_RECALL_TABLE.OK, data: response })
      dispatch({ type: types.STORE_REPORT_RECALL_DATA, data: params })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_REPORT_RECALL_TABLE.FAIL, error })
      return Promise.reject(error)
    })

export const A_GetReportAppointmentsTable = params => dispatch =>
  reports
    .getReportAppointmentsTable(params)
    .then(response => {
      dispatch({ type: types.GET_REPORT_APPOINTMENTS_TABLE.OK, data: response })
      dispatch({ type: types.STORE_REPORT_APPOINTMENTS_DATA, data: params })
      return response
    })
    .catch(error => {
      dispatch({ type: types.GET_REPORT_APPOINTMENTS_TABLE.FAIL, error })
      return Promise.reject(error)
    })
