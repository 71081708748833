import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = ({
  id,
  keyVal,
  label,
  type,
  checked,
  classContainer,
  isListItem,
  handleAllChecked,
  // handleSingleChecked,
  defaultChecked,
  // selectedCheck,
  ...props
}) => {
  return (
    <React.Fragment>
      {isListItem ? (
        <li className={classContainer}>
          <input
            className="form__checkbox"
            key={keyVal}
            id={id}
            type={type}
            checked={checked}
            {...props}
          />
          <label className="form__checkboxLabel" htmlFor={id}>
            {label}
          </label>
        </li>
      ) : (
        <div className={classContainer}>
          <input
            key={keyVal}
            id={id}
            type={type}
            {...props}
            checked={defaultChecked}
            onClick={handleAllChecked}
          />
          <label className="form__checkbox" htmlFor={id}>
            {label}
          </label>
        </div>
      )}
    </React.Fragment>
  )
}

Checkbox.defaultProps = {
  id: null,
  keyVal: 0,
  label: '',
  type: 'checkbox',
  handleAllChecked: null,
  //handleSingleChecked: null,
  classContainer: '',
  // selectedCheck: true,
  isListItem: false,
  defaultChecked: false,
  checked: false,
  onChange: () => {},
}

Checkbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  keyVal: PropTypes.number,
  label: PropTypes.string,
  type: PropTypes.oneOf(['checkbox', 'radio']),
  classContainer: PropTypes.string,
  isListItem: PropTypes.bool,
  handleAllChecked: PropTypes.func,
  //handleSingleChecked: PropTypes.func,
  // selectedCheck: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
}

export default Checkbox
