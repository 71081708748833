import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import GraphHeaders from './GraphHeaders'
import TextInput from '../../components/forms/Input'
import { formatAmount, formatCurrency } from '../../utilities/ReusableFunctions'
import { A_GetTreatmentSummaryData } from '../../actions'
import PropTypes from 'prop-types'
import Loader from '../../components/PatientData/Loader'

let spanTitle = [
  'productSpan',
  'treatmentTypeSpan',
  'priceSpan',
  'shortSpan',
  'shortSpan',
]

class TreatmentSummary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchText: '',
      treatmentList: [],
    }
  }

  componentDidMount = () => {
    this.getTreatmentsData()
  }

  getTreatmentsData = () => {
    const {
      startDate,
      endDate,
      clinicID,
      clinicianID,
      actions,
      locale,
    } = this.props

    this.setState({ isLoading: true })

    let sDate = new Date(startDate)
    let eDate = new Date(endDate)
    let treatmentList = []
    let classTitle = []
    if (Date.parse(sDate) <= Date.parse(eDate)) {
      actions
        .A_GetTreatmentSummaryData({
          clinic: clinicID,
          clinician: clinicianID,
          search: this.state.searchText,
          start_date: startDate,
          end_date: endDate,
          locale: locale,
        })
        .then(res => {
          let totalQuantity = 0
          let totalRevenue = 0
          let uniqueTreatmentNameCount = []
          let uniqueTreatmentOptionCount = []

          res.results.forEach(result => {
            if (
              uniqueTreatmentNameCount.indexOf(
                result.treatment_option.treatment.title,
              ) === -1
            ) {
              uniqueTreatmentNameCount.push(
                result.treatment_option.treatment.title,
              )
            }
            if (
              uniqueTreatmentOptionCount.indexOf(
                result.treatment_option.title,
              ) === -1
            ) {
              uniqueTreatmentOptionCount.push(
                result.treatment_option.treatment.title,
              )
            }
            treatmentList.push({
              treatmentName: result.treatment_option.treatment.title,
              treatmentOption: result.treatment_option.title,
              pricingOption: formatCurrency(
                result.treatment_level.price,
                locale,
              ),
              quantity: result.count,
              Revenue: formatCurrency(result.total_income, locale),
            })
            totalQuantity += result.count
            totalRevenue += result.total_income
          })

          totalRevenue = formatAmount(totalRevenue)
          if (treatmentList.length > 0) {
            treatmentList.push({
              treatmentName: uniqueTreatmentNameCount.length,
              treatmentOption: uniqueTreatmentOptionCount.length,
              pricingOption: treatmentList.length,
              quantity: totalQuantity,
              Revenue: formatCurrency(totalRevenue, locale),
            })
          }
          classTitle = [
            'productSpan',
            'treatmentTypeSpan',
            'priceSpan',
            'shortSpan',
            'shortSpan',
          ]
          let treatmentSubHeaderList = [
            'treatment name',
            'treatment option',
            'pricing option',
            'quantity',
            'revenue',
          ]
          this.setState({
            isLoading: false,
            treatmentList: treatmentList,
            headers: { header: treatmentSubHeaderList, classTitle: classTitle },
          })
        })
    }
  }

  filterTreatments = e => {
    this.setState({ searchText: e.target.value }, () => {
      this.getTreatmentsData()
    })
  }

  UNSAFE_componentWillReceiveProps = props => {
    const { filterCount } = this.props
    if (filterCount !== props.filterCount) { // eslint-disable-line
      this.getTreatmentsData()
    }
  }

  render() {
    const { headers, isLoading, treatmentList } = this.state
    return (
      <>
        {headers ? (
          <article className="Appoint_Analytics_table">
            <h1 className="sub__heading">Treatments</h1>
            <section className="treatment_Summary_TableCell">
              <TextInput
                leftIcon="icon-magnifying-glass"
                placeholder="Filter"
                type="text"
                id="treatmentName"
                name="EpharmProdName"
                onChange={this.filterTreatments}
              />
            </section>
            <div className="Appoint_Analytics_tableBody">
              <GraphHeaders headers={headers} />
              <hr />
              {isLoading ? (
                <Loader isCreate={true} minHeight={100} />
              ) : (
                treatmentList.map((treatment, index) => {
                  let count = -1
                    let footerClass = parseInt(index) === treatmentList.length - 1 //eslint-disable-line
                      ? 'tableFooter reportTable__footer'
                      : ''
                  return (
                    <div
                      className={`tableRow__plain ${footerClass}`}
                      key={treatment}
                    >
                      {Object.keys(treatment).map(id => {
                        count++
                        let spanClass =
                          count === 0 &&
                          parseInt(index) !== treatmentList.length - 1
                            ? 'span_textLink'
                            : count === 1
                            ? 'summaryTable bold'
                            : count === 4
                            ? 'summaryTable bold'
                            : parseInt(index) === treatmentList.length - 1
                            ? 'summaryTable bold'
                            : 'summaryTable'
                        return (
                          <section
                            className={`tableCell ${spanTitle[count]}`}
                            key={id}
                          >
                            <div className="staffTable__email--container">
                              <span className={`noMargin  ${spanClass}`}>
                                {this.state.treatmentList[index][id]}
                              </span>
                            </div>
                          </section>
                        )
                      })}
                    </div>
                  )
                })
              )}
              {!isLoading && treatmentList.length === 0 ? (
                <p className="emptyState">No Treatment data yet</p>
              ) : (
                ''
              )}
            </div>
          </article>
        ) : (
          ''
        )}
      </>
    )
  }
}

TreatmentSummary.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  clinicID: PropTypes.number.isRequired,
  clinicianID: PropTypes.string.isRequired,
  filterCount: PropTypes.number.isRequired,
  actions: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
}

//export default ClinicsPage;
const mapStateToProps = state => {
  return { data: state.charts }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetTreatmentSummaryData,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TreatmentSummary),
)
