import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import TutorialList from '../TutorialList'
import ModalHeader from '../../Modal/Header'

const TutorialModal = ({
  activeTutorial,
  activeVideo,
  setVideo,
  toggleTutorial,
}) => {
  const [videosPlaying, setVideosStatus] = useState([])
  useEffect(() => {
    const { videos } = activeTutorial
    const videosPlayStatus = videos.map(video => ({
      ...video,
      isPlaying: false,
    }))
    setVideo(videos[0].signed_video)
    setVideosStatus(videosPlayStatus)
  }, [])

  useEffect(() => {
    const playingVideos = videosPlaying.filter(
      video => video.isPlaying === true,
    )
    if (playingVideos.length > 1) {
      const updatedVideos = [...videosPlaying]
      let prevVideo = updatedVideos.find(
        video => video.signed_video !== activeVideo && video.isPlaying === true,
      )
      prevVideo.isPlaying = false
      setVideosStatus(updatedVideos)
    }
  })

  const selectedVideo = videosPlaying.find(
    video => video.signed_video === activeVideo,
  )

  const isVideoPlaying =
    selectedVideo === undefined ? false : selectedVideo.isPlaying

  return ReactDOM.createPortal(
    <div className="tutorialOverlay fadeInFlyAnimate">
      <div className="tutorialModal">
        <ModalHeader
          title={`${activeTutorial.title} tutorials`}
          closeHandler={() => toggleTutorial(false)}
        />
        <div className="tutorialModal--video-content">
          <div className="tutorialModal--video-content-colLeft">
            <section className="modal__body" style={{ borderBottom: 'none' }}>
              <TutorialList
                videos={videosPlaying}
                setVideo={setVideo}
                setVideosStatus={setVideosStatus}
              />
            </section>
          </div>
          <div className="tutorialModal--video-content-colRight">
            <ReactPlayer
              url={activeVideo}
              width="100%"
              height="100%"
              controls={true}
              playing={isVideoPlaying}
            />
          </div>
        </div>
      </div>
    </div>,
    document.getElementById('root-modal'),
  )
}

TutorialModal.propTypes = {
  activeTutorial: PropTypes.shape({
    title: PropTypes.string,
    videos: PropTypes.array,
  }).isRequired,
  activeVideo: PropTypes.string.isRequired,
  setVideo: PropTypes.func.isRequired,
  toggleTutorial: PropTypes.func.isRequired,
}

export default TutorialModal
