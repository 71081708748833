import Promise from 'promise'
import * as api from '../api'
import * as url from '../../api_urls'

/// CHANGE 12/7/2019 HANDLE UNDEFINED ERROR
export const getimportedFileData = data => {
  return new Promise((resolve, reject) => {
    return api
      .get(
        url.clinics + data.clinicID + '/patients/import/' + data.importID + '/',
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        error = error || {
          data: {
            detail:
              'The data import could not be completed at this time. Please try again.',
          },
        }
        reject(error)
      })
  })
}
