import React from 'react'
import PropTypes from 'prop-types'

export default function CurrencyIcon({ currency }) {
  return <span className="currency__symbol">{currency || '?'}</span>
}

CurrencyIcon.defaultProps = {
  currency: '',
}

CurrencyIcon.propTypes = {
  currency: PropTypes.string,
}
