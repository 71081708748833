/*
    Feature flags used to show/hide features
*/

export const isBundlesEnabled = true

export const isTutorialFeatureEnabled = true

export const financeFeatureToggle = {
  isLoggingEnabled: true,
  isViewingEnabled: true,
  isReportingEnabled: true,
  isMultiFiltersEnabled: true,
}

export const isSupportFeatureEnabled = true

export const isVoucherBundlesEnabled = true

export const isAppointmentOverviewEnabled = true
export const isAppointmentPreOrderEnabled = true
export const isAlertAndAllergiesEnabled = false

export const maxClinics = 24

export const restrictedToInternal = [
  24,
  25,
  26, // ASC Clinics
  463,
  1051,
  564,
  37,
  35,
  1121, // The Cosmetic Clinic (Demo account)
]

export const restrictedToExternal = [
  ...restrictedToInternal,
  250,
  1371, // Medizen
]
