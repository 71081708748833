import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import MainButton from '../../Buttons'
import SelectInput from '../../forms/Select'
import TextInput from '../../forms/Input'
import QuickbooksLogo from '../../../assets/quickbooks-logo.svg'
import Avatar from '../../Avatar'
import UploadFile from '../../forms/fileUpload'

const ClinicSetupFinanceCard = ({
  vatList,
  DeptList,
  vatError,
  vatErrorMessage,
  departmentError,
  isEdit,
  handleConnectQB,
  handleRemoveQB,
  handleChangeVat,
  handleChangeDepartment,
  clinicLogo, //eslint-disable-line
  selectLogoPreview, //eslint-disable-line
  clearLogoPreview, //eslint-disable-line
  data,
}) => {
  const {
    vat,
    has_quickbooks,
    quickbooks_token,
    tax_code_id,
    signed_invoice_header_logo,
  } = data

  const hasTokenExpired =
    quickbooks_token &&
    moment(new Date()).diff(moment(quickbooks_token.expires), 'seconds') > 0

  let company = undefined
  let department = undefined
  let selectedDept = undefined

  if (has_quickbooks) {
    company = quickbooks_token.company_id
    department = quickbooks_token.department_name
    selectedDept = quickbooks_token.department_id
  }

  if (!clinicLogo) {
    clinicLogo = signed_invoice_header_logo
  }

  const quickbooksTokenCheck = `
    ${
      hasTokenExpired
        ? `Your quickbooks session has expired. ${
            isEdit ? 'Please select remove quickbooks, then reconnect.' : ''
          }`
        : `Quickbooks tracks patient invoice payments in the ‘Finances’
         section of patient records.`
    }
  `
  return (
    <React.Fragment>
      <h2 className="h1 gutterMarginBottom">Clinic Finances</h2>
      <div className="form">
        <article className="form__group">
          <div className="form__group--halfWidth  secondaryMarginBottom">
            {isEdit ? (
              <React.Fragment>
                <div className="form__group--twothird__panel clinicLogo__container">
                  {clinicLogo === null || clinicLogo === '' ? (
                    <React.Fragment>
                      <UploadFile
                        label="Upload"
                        styleType="tertiary"
                        size="small"
                        labelID="uploadClinicLogo"
                        inputID="inputClinicLogo"
                        accept="image/png, image/jpeg"
                        fileHandler={e => {
                          selectLogoPreview(e.target)
                        }}
                      />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Avatar src={clinicLogo} type="editProfile" />
                      <div className="button--marginRight">
                        <UploadFile
                          label="Replace"
                          styleType="tertiary"
                          size="small"
                          labelID="uploadClinicLogo"
                          inputID="inputClinicLogo"
                          accept="image/png, image/jpeg"
                          fileHandler={e => {
                            selectLogoPreview(e.target)
                          }}
                        />
                      </div>
                      <MainButton
                        type="button"
                        label="Remove"
                        styleType="destructive"
                        size="small"
                        onClick={e => {
                          clearLogoPreview(e)
                        }}
                      />
                    </React.Fragment>
                  )}
                </div>
                {has_quickbooks ? (
                  <React.Fragment>
                    <div className="form__group--halfWidth__panel">
                      <SelectInput
                        id="vat"
                        options={vatList}
                        defaultSelected={tax_code_id}
                        label="DEFAULT VAT"
                        customClasses="noMargin"
                        isRequired={true}
                        error={vatError}
                        onChange={handleChangeVat}
                      />
                    </div>
                    <div className="form__group--halfWidth__panel">
                      <SelectInput
                        id="department"
                        options={DeptList}
                        defaultSelected={selectedDept}
                        label="DEFAULT DEPARTMENT"
                        customClasses="noMargin"
                        isRequired={true}
                        error={departmentError}
                        onChange={handleChangeDepartment}
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="form__group--halfWidth__panel">
                      <TextInput
                        type="text"
                        id="vat"
                        noMargin={true}
                        placeholder="VAT"
                        defaultValue={vat}
                        label="DEFAULT VAT"
                        maxLength="3"
                        isAuto={true}
                        size="5"
                        isRequired={true}
                        error={vatError}
                        errorMessage={vatErrorMessage}
                        onChange={handleChangeVat}
                      />{' '}
                      %
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="form__label">Clinic logo</div>
                {clinicLogo ? (
                  <div className="secondaryMarginBottom">
                    <Avatar src={clinicLogo} type="editProfile" />
                  </div>
                ) : (
                  <p className="disabledFormEntry disabledFormEntry--editable secondaryMarginBottom">
                    Please upload your clinic&apos;s logo for use in invoices
                    and other official documents
                  </p>
                )}
                <div className="form__label">Default VAT</div>
                <p className="disabledFormEntry disabledFormEntry--editable">{`${vat}%`}</p>
              </React.Fragment>
            )}
          </div>
        </article>
        <hr />
        <article className="form__group">
          {has_quickbooks ? (
            <React.Fragment>
              <div className="form__group--halfWidth">
                <div className="noMargin form__group--halfWidth__panel">
                  <img src={QuickbooksLogo} alt="Intuit Quickbooks Logo" />
                  <p
                    className="text-light"
                    style={{
                      color: `${hasTokenExpired ? 'red' : null}`,
                    }}
                  >
                    {quickbooksTokenCheck}
                  </p>
                </div>
                <div className="form__group--halfWidth__panel form__group--information">
                  <div className="form__label">CONNECTED COMPANY</div>
                  <p className="disabledFormEntry secondaryMarginBottom">
                    {company}
                  </p>
                  {isEdit ? (
                    <React.Fragment>
                      <div className="form__label">CONNECTED DEPARTMENT</div>
                      <p className="disabledFormEntry secondaryMarginBottom">
                        {department}
                      </p>
                      <MainButton
                        type="button"
                        label="Remove Quickbooks"
                        styleType="primary"
                        size="small"
                        clickHandler={handleRemoveQB}
                      />{' '}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="form__label">CONNECTED DEPARTMENT</div>
                      <p className="disabledFormEntry noMargin">{department}</p>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="noMargin form__group--halfWidth__panel">
                <img src={QuickbooksLogo} alt="Intuit Quickbooks Logo" />
              </div>
              <p className="text-light">
                Connect Quickbooks to your clinic to track patient invoice
                payments.
              </p>
              {isEdit ? (
                <MainButton
                  type="button"
                  label="Connect Quickbooks"
                  styleType="primary"
                  size="small"
                  clickHandler={handleConnectQB}
                />
              ) : (
                ''
              )}
            </React.Fragment>
          )}
        </article>
      </div>
    </React.Fragment>
  )
}

ClinicSetupFinanceCard.defaultProps = {
  vatList: [],
  vatError: '',
  vatErrorMessage: '',
  departmentError: '',
  isEdit: false,
  handleConnectQB: () => {},
  handleRemoveQB: () => {},
  handleChangeVat: () => {},
  handleChangeDepartment: () => {},
  DeptList: [],
}

ClinicSetupFinanceCard.propTypes = {
  vatList: PropTypes.array,
  vatError: PropTypes.string,
  vatErrorMessage: PropTypes.string,
  departmentError: PropTypes.string,
  isEdit: PropTypes.bool,
  handleConnectQB: PropTypes.func,
  handleRemoveQB: PropTypes.func,
  handleChangeVat: PropTypes.func,
  handleChangeDepartment: PropTypes.func,
  DeptList: PropTypes.array,
  data: PropTypes.object.isRequired,
}

export default ClinicSetupFinanceCard
