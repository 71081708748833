import types from '../../../actions/types'

var initialState = {
  validPassword: false,
  noError: true,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case types.VALID_PASSWORD.OK:
      return {
        ...state,
        validPassword: true,
        noError: true,
      }
    case types.VALID_PASSWORD.FAIL:
      return {
        ...state,
        validPassword: false,
        noError: false,
      }
    case types.RESET_PROFILE_VALUES:
      return {
        ...state,
        validPassword: false,
        noError: true,
      }
    default:
      return state
  }
}
