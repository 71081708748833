import React from 'react'
import PropTypes from 'prop-types'
//import MainButton from "../../../Buttons";

const NotificationCard = ({ notification, handleClick, ...props }) => { //eslint-disable-line
  return (
    <React.Fragment>
      {notification.unread ? (
        <button
          className="notification__content"
          onClick={() => handleClick(notification)}
        >
          <div className="notification__card">
            <h6 className="h4">{notification.title}</h6>
            <p className="h4">{notification.detail}</p>
            <span className="read__indicator" />
          </div>
        </button>
      ) : (
        <button
          className="notification__content--read"
          onClick={() => handleClick(notification)}
        >
          <div className="notification__card">
            <h6 className="h4">{notification.title}</h6>
            <p className="h4">{notification.detail}</p>
          </div>
        </button>
      )}

      {/* Notification styles from designs, may be used at later (added 03/2019) */}

      {/* <div className="notification__card">
        <p className="p">15 minutes <span className="modal__body__text--bold">Dermal filler review </span>needed <span className="textLink">next week</span></p>
        <div className="notification__buttons">
        <MainButton
        type="button"
        styleType="primary"
        label="Book appointment"
        size="small"
        />
        </div>
      </div> */}
      {/* <div className="notification__card">
        <p className="h4">Referral required</p>
        <p className="notification__note">Miss Darwin should be referred to Queens Road Medical Practice as her eczema condition requires attention</p>
        <div className="notification__buttons">
        <MainButton
        type="button"
        styleType="primary"
        label="Log correspondence"
        size="small"
        />
        </div>
      </div> */}
    </React.Fragment>
  )
}

NotificationCard.defaultProps = {
  notification: { title: '', detail: '' },
  title: '',
  details: '',
  read: false,
}

NotificationCard.propTypes = {
  notification: PropTypes.object,
  title: PropTypes.string,
  details: PropTypes.string,
  read: PropTypes.bool,
}

export default NotificationCard
