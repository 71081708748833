import React from 'react'
import PropTypes from 'prop-types'

import {
  appointmentModalTypes,
  appointmentReasonTitles,
  appointmentReasonIds,
} from '../../../../../../utilities/ReusableObjects'

export default function AppointmentPurpose({
  type,
  appointmentPurpose,
  setAppointmentPurpose,
  isPurposesDisabled,
  isVirtual,
  setIsVirtual,
}) {
  const { EDIT } = appointmentModalTypes

  const virtualVisible = appointmentPurpose === 'Appointment'

  return (
    <fieldset
      className="form__group--topStackable createAppointment--appointmentPurpose createAppointment--right"
      key="purpose"
    >
      <div className="form__label">Appointment purpose</div>
      <ul className="radio__group radio__group--inline" role="group">
        {Object.keys(appointmentReasonIds).map(key => {
          const title = appointmentReasonTitles[key]
          const inputID = 'purpose' + title

          return (
            <li key={key} className="radioButton">
              <input
                name="purpose"
                id={inputID}
                type="radio"
                className="form__radio"
                defaultChecked={appointmentPurpose === title}
                onClick={() => setAppointmentPurpose(title)}
                disabled={
                  (isPurposesDisabled || type === EDIT) &&
                  appointmentPurpose !== title
                }
              />
              <label
                className="roundedToggleButton"
                htmlFor={inputID}
                style={{
                  pointerEvents: `${
                    isPurposesDisabled || type === EDIT ? 'none' : ''
                  }`,
                }}
              >
                {title}
              </label>
            </li>
          )
        })}
        {virtualVisible && (
          <li>
            <input
              name="is_virtual"
              id="is_virtual"
              type="checkbox"
              checked={isVirtual}
              onClick={({ target: { checked } }) => setIsVirtual(checked)}
              disabled={isPurposesDisabled || type === EDIT}
            />
            <label
              className="clinicOpeningHours__openStatus"
              htmlFor="is_virtual"
            >
              Virtual (Phone/video call)
            </label>
          </li>
        )}
      </ul>
    </fieldset>
  )
}

AppointmentPurpose.defaultProps = {
  isPurposesDisabled: false,
  isVirtual: false,
}

AppointmentPurpose.propTypes = {
  type: PropTypes.string.isRequired,
  appointmentPurpose: PropTypes.string.isRequired,
  setAppointmentPurpose: PropTypes.func.isRequired,
  isPurposesDisabled: PropTypes.bool,
  isVirtual: PropTypes.bool,
  setIsVirtual: PropTypes.func.isRequired,
}
