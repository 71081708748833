import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  A_GetClinicsList,
  A_GetFilteredDocuments,
  A_GetDocumentsList,
  A_SetSelectedDocument,
  A_SetFilteredDocumentList,
  A_GetPatientDetail,
} from '../../actions'
import * as obj from '../../utilities/ReusableObjects'

import SearchHeader from '../../components/Search/Header'
import SearchResults from '../../components/Search/Results'
import SearchResultMessage from '../../components/Search/ResultMessage'
import DocumentUploadModal from '../../components/PatientRecord/DocumentUpload'
import Loader from '../../components/PatientData/Loader'
import { TutorialContext } from '../../App'
import TutorialBtn from '../../containers/Tutorials/TutorialBtn'
import { isTutorialFeatureEnabled } from '../../utilities/featureToggle'

class PatientDocumentSearch extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filters: [
        {
          id: 1,
          label: 'Patient Record',
          classContainer: 'radioInput',
          name: 'filterPatientRecord',
          checked: true,
        },
        {
          id: 2,
          label: 'Invoice',
          classContainer: 'radioInput',
          name: 'filterInvoice',
          checked: true,
        },
        {
          id: 3,
          label: 'Consent',
          classContainer: 'radioInput',
          name: 'filterConsent',
          checked: true,
        },
        {
          id: 4,
          label: 'Referral Letter',
          classContainer: 'radioInput',
          name: 'filterReferral',
          checked: true,
        },
        {
          id: 5,
          label: 'Other',
          classContainer: 'radioInput',
          name: 'filterOther',
          checked: true,
        },
      ],
      isLoading: true,
      isClinicUnavailable: false,
      searchValue: '',
      showDocumentUploadModal: false,
    }

    this.handleSearchTreatment = this.handleSearchTreatment.bind(this)
    this.applyFilterDocuments = this.applyFilterDocuments.bind(this)
    this.loadPatientDocumentRecord = this.loadPatientDocumentRecord.bind(this)
    this.onDocumentsSave = this.onDocumentsSave.bind(this)
    this.showDocumentUploadModal = this.showDocumentUploadModal.bind(this)
    this.hideDocumentUploadModal = this.hideDocumentUploadModal.bind(this)
    this.getInitialPatientData = this.getInitialPatientData.bind(this)
    this.getResults = this.getResults.bind(this)
  }

  componentDidMount = () => {
    const { actions } = this.props

    actions.A_GetClinicsList().then(() => {
      const { global } = this.props

      const { patientId } = this.props.match.params
      const clinic = global.currentClinicID

      if (clinic) {
        actions.A_GetPatientDetail(clinic, patientId).then(() => {
          this.getInitialPatientData()
        })
      } else {
        this.setState(() => ({
          isLoading: false,
          isClinicUnavailable: true,
        }))
      }
    })
  }

  getInitialPatientData() {
    const { actions, patient } = this.props
    actions.A_GetDocumentsList(patient).then(() => {
      this.setState(() => ({
        isLoading: false,
      }))
    })
  }

  /*----  function for both checkboxes and search   -----*/

  handleSearchTreatment(event) {
    if (event.target) {
      let enteredValue = event.target.value
      enteredValue = enteredValue.toLowerCase()
      this.applyFilterDocuments(enteredValue)
      this.setState({
        searchValue: enteredValue,
      })
    } else {
      let searchBoxString = this.state.searchValue
      this.applyFilterDocuments(searchBoxString)
    }
  }

  getResults() {
    const { filters } = this.state
    const { patients, actions } = this.props
    let results = patients.patientFilteredDocumentList.filter(document => {
      let otherChecked = true
      let docType = document.document_type.toLowerCase()
      let types = filters.filter(type => {
        let filterType = type.label.toLowerCase()
        return docType === filterType && type.checked
      })
      let otherFilter = this.state.filters.filter(other => {
        let filterType = other.label.toLowerCase()
        if (filterType === 'other' && !other.checked) {
          otherChecked = false
        }
        return (
          docType !== 'patient record' &&
          docType !== 'invoice' &&
          docType !== 'consent' &&
          docType !== 'referral letter'
        )
      })
      return types.length > 0 || (otherFilter.length > 0 && otherChecked)
    })
    actions.A_SetFilteredDocumentList(results)
    return results
  }

  /*----  checks the documents returned from the API against the checked filter boxes   -----*/

  applyFilterDocuments(enteredValue, nextQuery) {
    const { patient, actions, global } = this.props

    let clinic = global.currentClinicID

    /**
     * Entered value result validation
     */
    if (enteredValue || enteredValue.length === 0) {
      let searchfilter = {
        id: clinic,
        patient: patient.id,
        document: enteredValue,
      }
      if (nextQuery) {
        searchfilter.nextQuery = nextQuery
      }
      actions.A_GetFilteredDocuments(searchfilter).then(() => {
        return this.getResults()
      })
    } else {
      /**
       * Check box checked and unchecked validation with search input
       */
      return this.getResults()
    }
  }

  loadNextDocuments() {
    const { nextQuery } = this.props
    this.applyFilterDocuments(this.state.searchValue, nextQuery)
  }

  /*----  opens the selected file in a new tab  -----*/

  loadPatientDocumentRecord(event) {
    const { patients, actions } = this.props
    const patientDocumentData = patients.patientDocumentList.filter(
      document => {
        return document.id === Number(event.currentTarget.dataset.patient)
      },
    )[0]
    actions.A_SetSelectedDocument(patientDocumentData)
    const link = patientDocumentData.signed_file
    window.open(link, '_blank')
  }

  /*---- opens the document upload modal ------*/

  onDocumentsSave() {
    this.hideDocumentUploadModal()
  }

  showDocumentUploadModal() {
    this.setState(() => ({
      showDocumentUploadModal: true,
    }))
  }

  hideDocumentUploadModal() {
    this.applyFilterDocuments(this.state.searchValue)
    this.setState(() => ({
      showDocumentUploadModal: false,
    }))
  }

  /*----  different states depending on results returned  -----*/

  renderResults() {
    const { patients } = this.props
    if (
      this.state.searchValue.length > 0 &&
      patients.patientDocumentList.length === 0
    ) {
      return (
        <SearchResultMessage
          message="No documents match your search"
          searchValue={"'" + this.state.searchValue + "'"}
        />
      )
    }
    if (patients.patientDocumentList.length === 0) {
      return <SearchResultMessage message="No documents available" />
    }
    return (
      <SearchResults
        data={patients.patientDocumentList}
        idField="id"
        nodeStructures={obj.patientDocumentSearchNodes}
        searchValue={this.state.searchValue}
        onSelect={this.loadPatientDocumentRecord}
      />
    )
  }

  render() {
    const {
      nextQuery,
      patient,
      global: { currentClinicID },
    } = this.props

    const clinic = currentClinicID

    return (
      <React.Fragment>
        <main className="main">
          {this.state.isLoading ? (
            <Loader />
          ) : this.state.isClinicUnavailable ? (
            ''
          ) : (
            <React.Fragment>
              <SearchHeader
                onChange={this.handleSearchTreatment}
                buttonSet={[
                  {
                    type: 'button',
                    style: 'secondary',
                    label: 'Upload documents',
                    size: 'small',
                    events: { onClick: this.showDocumentUploadModal },
                  },
                ]}
                filterSet={this.state.filters}
                onFilterChange={this.handleSearchTreatment}
              />
              <section className="main__inner main__inner--paginated">
                {this.renderResults()}
                {nextQuery && !this.state.isLoading ? (
                  <article className="pagination_wrapper">
                    <button
                      className="buttonTransparent textLink"
                      onClick={() => {
                        this.loadNextDocuments()
                      }}
                    >
                      See more
                    </button>
                  </article>
                ) : (
                  ''
                )}
              </section>
              {this.state.showDocumentUploadModal ? (
                <DocumentUploadModal
                  title="Upload documents"
                  onSaveSelected={this.onDocumentsSave}
                  onCloseSelected={this.hideDocumentUploadModal}
                  clinicId={clinic}
                  patientId={patient.id}
                  canDelete={true}
                />
              ) : null}
            </React.Fragment>
          )}
          {isTutorialFeatureEnabled ? (
            <TutorialContext.Consumer>
              {({
                toggleTutorial,
                setTutorial,
                tutorialList,
                history,
                activeTutorial,
              }) => (
                <TutorialBtn
                  tutorialList={tutorialList}
                  toggleTutorial={toggleTutorial}
                  setTutorial={setTutorial}
                  activeTutorial={activeTutorial}
                  history={history}
                />
              )}
            </TutorialContext.Consumer>
          ) : null}
        </main>
      </React.Fragment>
    )
  }
}

PatientDocumentSearch.defaultProps = {
  patient: null,
  nextQuery: null,
}

PatientDocumentSearch.propTypes = {
  actions: PropTypes.object.isRequired,
  patient: PropTypes.object,
  patients: PropTypes.object.isRequired,
  nextQuery: PropTypes.string,
  global: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

/** Need to change this as per need */
const mapStateToProps = state => {
  return {
    patient: state.patients.selectedPatient,
    patients: state.patients,
    nextQuery: state.patients.patientDocumentsNextQuery,
    global: state.global,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetClinicsList,
        A_GetDocumentsList,
        A_GetFilteredDocuments,
        A_SetSelectedDocument,
        A_SetFilteredDocumentList,
        A_GetPatientDetail,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PatientDocumentSearch),
)
