import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SelectInput from '../../forms/Select'
import MainButton from '../../Buttons'
import DatePickerInput from '../../forms/datePickerInput'
import {
  A_GetClinicsList,
  A_GetReportAppointmentsTable,
  A_FetchCliniciansList,
} from '../../../actions'
import DatePickerRangeHHL from '../../DatePicker/DatePickerRange'
import moment from 'moment'
import * as func from '../../../utilities/ReusableFunctions'
import FeedbackNotification from '../../Feedback/FeedbackNotification'

class AppointmentsFilterHeading extends React.Component {
  constructor(props) {
    super(props)
    this.startDateRef = React.createRef()
    this.endDateRef = React.createRef()
    this.dpContainer = React.createRef()

    this.state = {
      showStartPicker: false,
      showEndPicker: false,
      dateError: false,
      startDate: func.getPreviousWeekStartEndDays().startDate,
      endDate: func.getPreviousWeekStartEndDays().endDate,
      clinicSelected: '',
      staffSelected: '',
      statusSelected: '',
      clinicOptions: [{ id: '', title: 'Show all' }],
      staffOptions: [{ id: '', title: 'Show all' }],
      appointStatusOptions: [
        { id: -3, title: 'Show all' },
        { id: -2, title: 'Cancelled' },
        { id: -1, title: 'No show' },
        { id: 0, title: 'Pending' },
        { id: 1, title: 'Patient arrived' },
        { id: 2, title: 'Ready for patient' },
        { id: 3, title: 'Active' },
        { id: 4, title: 'Complete' },
      ],
    }
  }

  componentDidMount() {
    const { actions } = this.props

    actions.A_GetClinicsList().then(() => {
      //eslint-disable-line

      const { global } = this.props

      const data = func.clinicsForLocale(
        global.clinics,
        global.currentClinicID,
        global.currentClinic.locale,
      )

      this.setState({
        clinicOptions: data.clinics,
        localeOptions: data.locales,
        clinicSelected: '',
        localeSelected: data.locale,
      })

      this.getStaffOptions()

      this.applyFilter()
    })
  }

  getStaffOptions = () => {
    const { actions } = this.props

    actions.A_FetchCliniciansList().then(() => {
      const {
        global: { allFilterClinicians },
      } = this.props

      this.setState({
        staffOptions: allFilterClinicians,
      })
    })
  }

  showStartDatePicker = () => {
    this.setState(() => ({
      showStartPicker: true,
    }))
  }

  showEndDatePicker = () => {
    this.setState(() => ({
      showEndPicker: true,
    }))
  }

  hideStartDatePicker = day => {
    this.resetDateValue(true)
    let date = day && day <= this.state.endDate ? day : this.state.startDate
    let datePickerState = !day || day <= this.state.endDate ? false : true
    let showError = !day || day <= this.state.endDate ? false : true
    this.setState(() => ({
      showStartPicker: datePickerState,
      startDate: date,
      dateError: showError,
    }))
  }
  hideEndDatePicker = day => {
    this.resetDateValue(true)
    let date = day && day >= this.state.startDate ? day : this.state.endDate
    let datePickerState = !day || day >= this.state.startDate ? false : true
    let showError = !day || day >= this.state.startDate ? false : true
    this.setState(() => ({
      showEndPicker: datePickerState,
      endDate: date,
      dateError: showError,
    }))
  }

  resetDateValue = e => {
    if (e) {
      this.setState(() => ({
        dateError: false,
      }))
    }
  }

  // -------- Handle change for all selects and inputs inside the filter

  filterBasicChange = e => {
    if (e.target) {
      let selectedValue = e.target.value

      if (e.target.id === 'reportFilterClinic') {
        this.setState({
          clinicSelected: selectedValue,
        })
      } else if (e.target.id === 'reportFilterStaff') {
        this.setState({
          staffSelected: selectedValue,
        })
      } else if (e.target.id === 'reportFilterStatus') {
        this.setState({
          statusSelected: selectedValue,
        })
      }
    }
  }

  applyFilter = () => {
    const { actions } = this.props
    const params = {
      start_date: moment(this.state.startDate).format('YYYY-MM-DD'),
      end_date: moment(this.state.endDate).format('YYYY-MM-DD'),
      clinic: this.state.clinicSelected,
      clinician: this.state.staffSelected,
      status: this.state.statusSelected,
    }

    actions.A_GetReportAppointmentsTable(params)
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="reportFilters__wrapper"
          /* eslint-disable-line */ ref={this.dpContainer}
        >
          <article className="reportFilters__wrapper--inner">
            <div className="form__filterSection--dateinput">
              <label className="form__label">
                START DATE {/* eslint-disable-line */}
              </label>
              <DatePickerInput
                id="startDate"
                value={moment(this.state.startDate).format('DD.MM.YYYY')}
                refVal={this.startDateRef}
                classList={`form__textField form__textField--right-icon datePicker__input noMargin ${
                  this.state.showStartPicker ? 'form__textField--active' : ''
                }`}
                onClick={this.showStartDatePicker}
              />
            </div>
            <div className="form__filterSection--dateinput">
              <label className="form__label">
                END DATE{/* eslint-disable-line */}
              </label>
              <DatePickerInput
                id="endDate"
                value={
                  this.state.endDate
                    ? moment(this.state.endDate).format('DD.MM.YYYY')
                    : ''
                }
                refVal={this.endDateRef}
                classList={`form__textField form__textField--right-icon datePicker__input noMargin ${
                  this.state.showEndPicker ? 'form__textField--active' : ''
                }`}
                onClick={this.showEndDatePicker}
              />
            </div>
            <div className="form__filterSection--select">
              <SelectInput
                id="reportFilterClinic"
                options={this.state.clinicOptions}
                label="Clinic"
                isSmallMargin={true}
                hasOwnWrapper={false}
                onChange={e => this.filterBasicChange(e)}
                defaultSelected={this.state.clinicSelected}
              />
            </div>
            <div className="form__filterSection--select">
              <SelectInput
                id="reportFilterStaff"
                options={this.state.staffOptions}
                label="STAFF MEMBER"
                isSmallMargin={true}
                hasOwnWrapper={false}
                onChange={e => this.filterBasicChange(e)}
                defaultSelected={this.state.staffSelected}
              />
            </div>
            <div className="form__filterSection--select">
              <SelectInput
                id="reportFilterStatus"
                options={this.state.appointStatusOptions}
                label="STATUS"
                isSmallMargin={true}
                hasOwnWrapper={false}
                onChange={e => this.filterBasicChange(e)}
                defaultSelected={this.state.statusSelected}
              />
            </div>
          </article>
          <MainButton
            type="button"
            styleType="secondary"
            label="Apply"
            size="small"
            margin="right"
            onClick={e => this.applyFilter(e)}
          />
        </section>
        {this.state.showStartPicker ? (
          <DatePickerRangeHHL
            selectedDate={this.state.startDate}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            dpInput={this.startDateRef.current}
            position="leftTop"
            container={this.dpContainer.current} //eslint-disable-line
            calendarType="manage_schedule_range"
            onHide={this.hideStartDatePicker}
          />
        ) : this.state.showEndPicker ? (
          <DatePickerRangeHHL
            selectedDate={this.state.endDate}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            dpInput={this.endDateRef.current}
            position="leftTop"
            container={this.dpContainer.current} //eslint-disable-line
            calendarType="manage_schedule_range"
            onHide={this.hideEndDatePicker}
          />
        ) : (
          ''
        )}
        {this.state.dateError ? (
          <FeedbackNotification
            id="feedbackMessage"
            dataError={true}
            message={'End date cannot be before start date'}
            iserrorAlert={true}
            closeHandler={() => {
              this.resetDateValue(true)
            }}
          />
        ) : (
          ''
        )}
      </React.Fragment>
    )
  }
}

AppointmentsFilterHeading.propTypes = {
  actions: PropTypes.object.isRequired,
  global: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
  return {
    clinics: state.clinics,
    global: state.global,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        A_GetClinicsList,
        A_GetReportAppointmentsTable,
        A_FetchCliniciansList,
      },
      dispatch,
    ),
  }
}

// Wrap the component to inject dispatch and state into it
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppointmentsFilterHeading),
)
