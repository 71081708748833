import Promise from 'promise'
import * as api from '../api'
import * as url from '../../api_urls'

export const importCSVFile = importData => {
  return new Promise((resolve, reject) => {
    return api
      .post2(
        url.clinics + importData.clinicID + '/patients/import/',
        importData.imported_file,
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const importData = importData => {
  let rows_to_exclude = importData.rows_to_exclude
    ? importData.rows_to_exclude
    : null
  let import_keys = {
    import_keys: importData.import_keys,
    rows_to_exclude: rows_to_exclude,
  }
  return new Promise((resolve, reject) => {
    return api
      .patch(
        url.clinics +
          importData.clinicID +
          '/patients/import/' +
          importData.importID +
          '/',
        import_keys,
        rows_to_exclude,
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
