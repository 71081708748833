import React, { useState, useEffect } from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory as createHistory } from 'history'
import Routes from './routes'
import { getTutorials, isLoggedIn } from './services/OnBoarding/auth'
import TutorialModal from './components/Tutorials/TutorialModal'
import SmartBanner from 'react-smartbanner'
const history = createHistory()
require('dotenv').config()

export const TutorialContext = React.createContext(false)
export const TaskContext = React.createContext(false)

const App = () => {
  const [tutorialList, setTutorials] = useState([])
  const [isTutorialOpen, toggleTutorial] = useState(false)
  const [activeTutorial, setTutorial] = useState(null)
  const [activeVideo, setVideo] = useState('')

  const [activeTasks, setActiveTasks] = useState([])

  useEffect(() => {
    async function fetchTutorials() {
      const tutorials = await getTutorials()
      setTutorials(tutorials)
    }

    if (isLoggedIn()) {
      fetchTutorials()
    }
  }, [])

  const formatTasks = tasks => {
    const area = history.location.pathname.split('/')[2]
    const filteredTasks = tasks.filter(
      ({ required }) => area === undefined || required.includes(area),
    )
    setActiveTasks(filteredTasks)
  }

  const hasCompletedAllTasks =
    activeTasks.find(({ complete, optional }) => {
      return !complete && !optional
    }) === undefined

  return (
    <>
      <SmartBanner title="Clever Clinic" />
      <TaskContext.Provider
        value={{ activeTasks, hasCompletedAllTasks, formatTasks }}
      >
        <TutorialContext.Provider
          value={{
            activeVideo,
            toggleTutorial,
            setTutorial,
            tutorialList,
            setVideo,
            history,
            activeTutorial,
          }}
        >
          {isTutorialOpen && (
            <TutorialModal
              activeTutorial={activeTutorial}
              activeVideo={activeVideo}
              toggleTutorial={toggleTutorial}
              setVideo={setVideo}
            />
          )}
          <Router history={history}>
            <Routes />
          </Router>
        </TutorialContext.Provider>
      </TaskContext.Provider>
    </>
  )
}

export default App
