import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

import PlaceholderImg from '../../../assets/placeholder_crane.png'
import { ReactComponent as Tick } from '../../../assets/tick.svg'
import { ReactComponent as Cross } from '../../../assets/cross.svg'
import { isSupportFeatureEnabled } from '../../../utilities/featureToggle'

const Placeholder = ({ activeTasks }) => {
  let url = 'https://cleverclinic.co.uk/resources'

  if (isSupportFeatureEnabled) {
    url = '/clinics/support'
  }

  return ReactDOM.createPortal(
    <div className="fadeInFlyAnimate placeholder--overlay">
      <div className="placeholder">
        <img
          className="placeholder--img"
          src={PlaceholderImg}
          alt="placeholder"
        />
        <div className="placeholder--rightCol">
          <h2>{"You're almost there!"}</h2>
          <p className="placeholder--text">
            There are a few things to complete before this feature is enabled.
            Need help? Support documents for each step are{' '}
            <a href={url}>available here</a>.
          </p>
          <div className="placeholder--tasks">
            {activeTasks.map(({ title, complete }) => (
              <div key={title} className="placeholder--task">
                {complete ? <Tick /> : <Cross />}
                <p key={title} className="placeholder--task-title">
                  {title}
                </p>
              </div>
            ))}
          </div>
          <p className="placeholder--text">
            Start using Clever Clinic by booking an appointment, or begin a
            Quick Consultation! Complete a consultation to create medical
            records, take photos, and send consents and aftercare documents by
            email. Patient history is easily accessible on both the iPad and web
            applications.
          </p>
          <p className="placeholder--text">
            Still not sure where to begin? Send us an email to{' '}
            <a href="mailto:support@cleverclinic.co.uk">
              support@cleverclinic.co.uk
            </a>
          </p>
        </div>
      </div>
    </div>,
    document.getElementById('root'),
  )
}

Placeholder.propTypes = {
  activeTasks: PropTypes.array.isRequired,
}

export default Placeholder
