export const showModal = modalId => {
  let overlay = document.getElementsByClassName('overlay')[0]
  if (document.getElementById(modalId)) {
    document.getElementById(modalId).classList.remove('fadeOutFlyAnimate')
    document.getElementById(modalId).classList.add('fadeInFlyAnimate')
    document.getElementById(modalId).style.display = 'block'
  }
  if (overlay) {
    overlay.classList.remove('fadeOutAnimate')
    overlay.classList.add('fadeInAnimate')
    overlay.style.display = 'block'
  }
}

export const closeOverlayModal = () => {
  const modals = document.getElementsByClassName('modal')
  //const submitModalButtons = document.getElementsByClassName('modal__submit')
  //const deleteRoomAlertModal = document.getElementsByClassName('modal')[1]
  //const clinicModal = document.getElementsByClassName('scrollModal')[0]
  //let modal = document.getElementsByClassName('modal')[0]
  let overlay = document.getElementsByClassName('overlay')
  if (modals.length > 0) {
    for (let i = 0; i < modals.length; i++) {
      modals[i].classList.remove('fadeInFlyAnimate')
      modals[i].classList.add('fadeOutFlyAnimate')

      setTimeout(function() {
        modals[i].style.display = 'none'
      }, 500 /* Length of CSS Animation */)
    }
  }

  if (typeof overlay !== 'undefined' && overlay !== null) {
    overlay[0].classList.add('fadeOutAnimate')
    overlay[0].classList.remove('fadeInAnimate')
    overlay[0].style.display = 'none'
  }
}
