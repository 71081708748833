import React from 'react'
import FileIcon from '../../../assets/file-icon.svg'
import PropTypes from 'prop-types'
import * as utils from '../../../utilities/ReusableFunctions'

// CHANGE 9/7/2019 height:max-content DISPLAYS BUTTON HALF HEIGHT IN CHROME
// ADDED AUTO HEIGHT AND TRUNCATED TEXT

const FileName = props => {
  return (
    <span className="patientImport__fileTag patientImport__fileTag--auto textLink singleLine_truncatedText">
      <img src={FileIcon} alt="File icon" />
      <a
        className="textLink"
        href={props.file}
        download="signed_imported_file"
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.name} ({utils.bytesToSize(props.size)})
      </a>
    </span>
  )
}

FileName.defaultProps = {
  file: '',
  name: '',
  size: '',
}

FileName.propTypes = {
  file: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.number,
}

export default FileName
