import React from 'react'
import PropTypes from 'prop-types'
import NotificationCard from './Notification'
import TextInput from '../../../forms/Input'
import TextAreaInput from '../../../forms/Textarea'
import MainButton from '../../../Buttons'
import SearchResults from '../../../Search/Results'
import * as obj from '../../../../utilities/ReusableObjects'

const ModalRightSideBody = ({
  notifications,
  handleClick,
  nextQuery,
  loadNextNotifications,
  messageActive,
  messageStaffValue,
  messageStaffAction,
  staffList,
  messageAreaValue,
  messageAreaAction,
  selectClinician,
  selectedClinician,
  sendMessage,
}) => {
  return (
    <React.Fragment>
      {messageActive ? (
        <React.Fragment>
          <div className="staffBody searchResult__header--table">
            <div className="searchClinic__staffContainer gutterMarginBottom">
              <TextInput
                id="messageStaff"
                leftIcon="icon-magnifying-glass"
                placeholder="Search for staff member"
                label="Send message to"
                value={messageStaffValue}
                onChange={messageStaffAction}
              />
              {messageStaffValue.length > 0 &&
              staffList.length > 0 &&
              !selectedClinician.id ? (
                <SearchResults
                  data={staffList}
                  idField="id"
                  nodeStructures={obj.staffSearchNodes}
                  searchValue={messageStaffValue}
                  messageResult={true}
                  onSelect={e => selectClinician(e)}
                />
              ) : (
                ''
              )}
            </div>
            <TextAreaInput
              id="messageText"
              placeholder="Enter your message..."
              value={messageAreaValue}
              label="Message"
              onChange={messageAreaAction}
            />

            <MainButton
              type="button"
              label="Send message"
              styleType="tertiary"
              size="small"
              leftAuto={true}
              onClick={sendMessage}
              isDisabled={
                selectedClinician &&
                selectedClinician.id &&
                messageAreaValue.length > 0
                  ? false
                  : true
              }
            />
          </div>
          <div className="confirm__container">
            <div className="modal__messages--container">
              {notifications.map(function(notification) {
                return notification.appointment === null ? (
                  <NotificationCard
                    key={notification.id}
                    notification={notification}
                    handleClick={handleClick}
                  />
                ) : (
                  ''
                )
              })}
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className="patient__sidebar--overflow">
          {notifications.map(function(notification) {
            return notification.appointment &&
              notification.appointment !== null ? (
              <NotificationCard
                key={notification.id}
                notification={notification}
                handleClick={handleClick}
              />
            ) : (
              ''
            )
          })}
        </div>
      )}
      {nextQuery && !messageActive ? (
        <article className="pagination_wrapper">
          <button
            className="buttonTransparent textLink"
            onClick={() => {
              loadNextNotifications()
            }}
          >
            See more
          </button>
        </article>
      ) : (
        ''
      )}
    </React.Fragment>
  )
}

ModalRightSideBody.defaultProps = {
  notifications: [],
  handleClick: () => {},
  nextQuery: '',
  loadNextNotifications: () => {},
  messageActive: false,
  messageStaffValue: '',
  messageStaffAction: () => {},
  staffList: [],
  messageAreaValue: '',
  messageAreaAction: () => {},
  selectClinician: () => {},
  selectedClinician: {},
  sendMessage: () => {},
}

ModalRightSideBody.propTypes = {
  notifications: PropTypes.array,
  handleClick: PropTypes.func,
  nextQuery: PropTypes.string,
  loadNextNotifications: PropTypes.func,
  messageActive: PropTypes.bool,
  messageStaffValue: PropTypes.string,
  messageStaffAction: PropTypes.func,
  staffList: PropTypes.array,
  messageAreaValue: PropTypes.string,
  messageAreaAction: PropTypes.func,
  selectClinician: PropTypes.func,
  selectedClinician: PropTypes.object,
  sendMessage: PropTypes.func,
}

export default ModalRightSideBody
