import React from 'react'
import PropTypes from 'prop-types'
import PatientSidebar from './PatientSideBar'
import InvoiceItem from './InvoiceItem'
import SubTopBar from './SubTopBar'
import MainButton from '../Buttons'
import SearchResultMessage from '../Search/ResultMessage'
import Loader from '../PatientData/Loader'
import AlertModal from '../Modal/Alert'
import { clinicHasHealthExchange } from '../../utilities/ReusableFunctions'
import { TutorialContext } from '../../App'
import TutorialBtn from '../../containers/Tutorials/TutorialBtn'
import { isTutorialFeatureEnabled } from '../../utilities/featureToggle'

/** This is the component for invoice Timeline of Patient Finance Epic cc-2265 */

class PatientFinancial extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showAlert: false,
      pendingTreatment: null,
    }
  }

  treatmentForIndex = id => {
    const { treatments } = this.props
    return treatments.find(treatment => treatment.id === parseInt(id))
  }

  displayInvoice = treatmentId => {
    const treatment = this.treatmentForIndex(treatmentId)

    let a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = treatment.signed_file
    a.click()
    document.body.removeChild(a)
  }

  openQuickbooks = treatmentId => {
    const { userPrimaryClinic } = this.props
    let treatmentIndex = this.treatmentForIndex(treatmentId)
    if (!userPrimaryClinic || userPrimaryClinic !== treatmentIndex.locationId) {
      this.setState(() => ({
        showAlert: true,
        pendingTreatment: treatmentIndex,
      }))
    } else {
      let a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      a.href = treatmentIndex.quickbooksURL
      a.click()
      document.body.removeChild(a)
    }
  }

  proceedToQuickbooks = () => {
    let treatment = this.state.pendingTreatment

    let a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = treatment.quickbooksURL
    a.click()
    document.body.removeChild(a)

    this.setState(() => ({
      showAlert: false,
      pendingTreatment: null,
    }))
  }

  closeAlert = () => {
    this.setState(() => ({
      showAlert: false,
      pendingTreatment: null,
    }))
  }

  render() {
    const {
      isClinicUnavailable,
      filters,
      isLoading,
      consultations,
      nextCall,
      nextUrl,
      filterChange,
      addNewInvoice,
      patient,
      buildInvoice,
      linkHealthxchange,
      treatments,
      clinicList,
      clinicians,
      locale,
      finances,
      refresh,
      deleteInvoice,
    } = this.props

    return (
      <main className="main main__patient">
        <SubTopBar
          patient={patient}
          wrapperClasses="subTopBar__wrapper subTopBar__wrapper--hasSidebar"
          filterSet={filters}
          onFilterChange={filterChange}
          locale={locale}
          finances={finances}
          refresh={refresh}
          buttonSet={[
            {
              type: 'button',
              style: 'secondary',
              label: 'New invoice',
              size: 'small',
              events: { onClick: addNewInvoice },
            },
          ]}
        />
        <PatientSidebar timeline={true} patient={patient} locale={locale} />
        <section className="main__patient--inner">
          <React.Fragment>
            {isClinicUnavailable ? (
              ''
            ) : isLoading ? (
              <Loader />
            ) : consultations.length === 0 && treatments.length === 0 ? (
              <main className="main__patient">
                <SearchResultMessage message="No invoices available for the patient selected" />
              </main>
            ) : (
              <React.Fragment>
                {consultations.map((consultation, index) => (
                  /* Consultations are treatments without an invoice */
                  <React.Fragment key={consultation.id}>
                    <section className="invoiceItem">
                      <div className="invoiceItem__row invoiceItem__row--top">
                        <h4 className="h3">{consultation.treatmentName}</h4>
                        <p>{consultation.date}</p>
                        <p>{consultation.consultationTime}</p>
                        <p>{consultation.doctor}</p>
                        <p>{consultation.location}</p>
                      </div>
                      {consultation.createInvoiceAllowed &&
                      clinicHasHealthExchange(
                        consultation.locationId,
                        clinicList,
                      ) ? (
                        <div className="invoiceItem__row">
                          <MainButton
                            label="Create invoice"
                            size="small"
                            styleType="secondary"
                            clickHandler={buildInvoice}
                            id={`${index}`}
                          />
                        </div>
                      ) : (
                        <div className="invoiceItem__row">
                          <MainButton
                            label="Link Healthxchange"
                            size="small"
                            styleType="secondary"
                            clickHandler={linkHealthxchange}
                            id={`${index}`}
                          />
                        </div>
                      )}
                    </section>
                  </React.Fragment>
                ))}
                {treatments.map(treatment => (
                  /* Treatments are finished consultations */
                  <React.Fragment key={treatment.id}>
                    <InvoiceItem
                      patient={patient}
                      treatment={treatment}
                      displayInvoice={this.displayInvoice}
                      openQuickbooks={this.openQuickbooks}
                      deleteInvoice={() => {
                        const invoice = this.treatmentForIndex(treatment.id)
                        deleteInvoice(invoice)
                      }}
                      clinics={clinicList}
                      clinicians={clinicians}
                    />
                  </React.Fragment>
                ))}
                {treatments.length > 0 &&
                nextUrl &&
                filters[filters.length - 1].checked ? (
                  <article className="pagination_wrapper">
                    <button
                      className="buttonTransparent textLink"
                      onClick={e => {
                        nextCall(e)
                      }}
                    >
                      See more
                    </button>
                  </article>
                ) : (
                  ''
                )}
              </React.Fragment>
            )}
            {this.state.showAlert ? (
              <AlertModal
                message={
                  'This appointment took place in a clinic which is not your primary clinic. Please ensure you are logged into the correct Quickbooks account before you proceed. Do you wish to continue?'
                }
                closeAlert={this.closeAlert}
                title="Check Quickbooks account"
                rightButtons={[
                  {
                    type: 'button',
                    style: 'tertiary',
                    label: 'Cancel',
                    size: 'small',
                    events: {
                      onClick: this.closeAlert,
                    },
                  },
                ]}
                leftButtons={[
                  {
                    type: 'button',
                    style: 'tertiary',
                    label: 'Proceed',
                    size: 'small',
                    events: {
                      onClick: this.proceedToQuickbooks,
                    },
                  },
                ]}
              />
            ) : null}
          </React.Fragment>
        </section>
        {isTutorialFeatureEnabled ? (
          <TutorialContext.Consumer>
            {({
              toggleTutorial,
              setTutorial,
              tutorialList,
              history,
              activeTutorial,
            }) => (
              <TutorialBtn
                tutorialList={tutorialList}
                toggleTutorial={toggleTutorial}
                setTutorial={setTutorial}
                activeTutorial={activeTutorial}
                history={history}
              />
            )}
          </TutorialContext.Consumer>
        ) : null}
      </main>
    )
  }
}

PatientFinancial.defaultProps = {
  filters: [],
  treatments: [],
  patient: null,
  clinicList: [],
  nextUrl: null,
}

PatientFinancial.propTypes = {
  filters: PropTypes.array,
  patient: PropTypes.object,
  clinicians: PropTypes.array.isRequired,
  clinicList: PropTypes.array,
  treatments: PropTypes.array,
  userPrimaryClinic: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  consultations: PropTypes.array.isRequired,
  nextCall: PropTypes.func.isRequired,
  nextUrl: PropTypes.string,
  filterChange: PropTypes.func.isRequired,
  addNewInvoice: PropTypes.func.isRequired,
  buildInvoice: PropTypes.func.isRequired,
  isClinicUnavailable: PropTypes.bool.isRequired,
  linkHealthxchange: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  finances: PropTypes.array.isRequired,
  refresh: PropTypes.func.isRequired,
  deleteInvoice: PropTypes.func.isRequired,
}

export default PatientFinancial
