import types from '../../../actions/types'
import {
  mapOpeningTimesDays,
  getCurrencySymbol,
} from '../../../utilities/ReusableFunctions'

var initialState = {
  clinicInfo: {
    id: 0,
    name: '',
    email: '',
    main_phone: '',
    alternative_phone: '',
    address_line_one: '',
    address_line_two: '',
    address_town: '',
    address_county: '',
    address_postcode: '',
    address_country: 'United Kingdom',
    opening_hours: mapOpeningTimesDays(),
    rooms: [],
    invites: [],
  },
  updated: false,
  selectedIndex: -1,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_CLINIC_DETAILS.OK: {
      let clinicInfo = action.data
      clinicInfo.opening_hours = mapOpeningTimesDays(clinicInfo.opening_hours)

      return {
        ...state,
        updated: false,
        clinicInfo: {
          ...clinicInfo,
          currencySymbol: getCurrencySymbol(clinicInfo.locale),
        },
      }
    }
    case types.FETCH_CLINIC_DETAILS.FAIL:
      return {
        ...state,
      }
    case types.PATCH_UPDATED_CLINICINFO.FAIL:
      return {
        ...state,
        equipList: action.equipList,
      }
    case types.PATCH_UPDATED_CLINICINFO.OK: {
      let clinicInfo = action.data
      clinicInfo.opening_hours = mapOpeningTimesDays(clinicInfo.opening_hours)

      return {
        ...state,
        updated: true,
        clinicInfo: clinicInfo,
      }
    }
    case types.POST_CALLBACK_URL_QB.OK: {
      const clinic_url =
        window.location.origin +
        `/clinics/${sessionStorage.getItem('currentClinic')}/edit`
      window.location.href = clinic_url

      return {
        ...state,
        clinicInfo: {
          ...state.clinicInfo,
          quickbooks_token: action.data,
        },
      }
    }
    case types.POST_CALLBACK_URL_QB.FAIL: {
      const clinic_url =
        window.location.origin +
        `/clinics/${sessionStorage.getItem('currentClinic')}/edit`
      window.location.href = clinic_url

      return {
        ...state,
      }
    }
    case types.DELETE_QB.OK:
      return {
        ...state,
        clinicInfo: {
          ...state.clinicInfo,
          quickbooks_token: null,
          has_quickbooks: false,
        },
      }
    default:
      return state
  }
}
