import types from '../../actions/types'

var initialState = {
  inventoryList: [],
  inventoryOptionList: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_INVENTORY_LIST.FAIL:
      return { ...state }
    case types.GET_INVENTORY_LIST.OK:
      return {
        ...state,
        inventoryList: action.data,
      }
    case types.GET_INVENTORY_OPTION_LIST.FAIL:
      return { ...state }
    case types.GET_INVENTORY_OPTION_LIST.OK:
      return {
        ...state,
        inventoryOptionList: action.data,
      }
    default:
      return state
  }
}
