import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import CreateEdit from './CreateEdit'
import View from './View'

import { appointmentModalTypes } from '../../../../utilities/ReusableObjects'

export default function AppointmentSidebar({
  type,
  clinician,
  setClinician,
  clinicians,
  clinicId,
  appointmentDetails,
  timezone,
  dateHandlerProps,
  slotHandlerProps,
  errorHandlerProps,
  closeModal,
  ignoreOpeningHours,
}) {
  const { CREATE, EDIT } = appointmentModalTypes
  const [minutes, setMinutes] = useState(0)
  const [hours, setHours] = useState(0)

  useEffect(() => {
    setInitialAppointmentLength()
  }, [type])

  const setInitialAppointmentLength = () => {
    let length = 0

    if (type === CREATE) {
      const clinicianDefaultAppointmentLength =
        clinician.default_appointment_length
      const repeatReviewAppointmentLength = appointmentDetails.appointmentLength

      length =
        repeatReviewAppointmentLength === null ||
        repeatReviewAppointmentLength === undefined
          ? clinicianDefaultAppointmentLength
          : repeatReviewAppointmentLength
    } else if (type === EDIT) {
      const startDate = moment(appointmentDetails.start_date)
      const endDate = moment(appointmentDetails.end_date)
      length = moment.duration(endDate.diff(startDate)).asMinutes()
    }

    const hours = Math.floor(length / 60)
    const mins = Math.floor(length % 60)

    setHours(hours * 60)
    setMinutes(mins)
  }

  return (
    <>
      {type === CREATE || type === EDIT ? (
        <CreateEdit
          clinician={clinician}
          setClinician={setClinician}
          appointmentId={appointmentDetails.id}
          appointmentLengthProps={{ minutes, setMinutes, hours, setHours }}
          clinicians={clinicians}
          clinicId={clinicId}
          timezone={timezone}
          {...dateHandlerProps}
          {...slotHandlerProps}
          {...errorHandlerProps}
          ignoreOpeningHours={ignoreOpeningHours}
        />
      ) : (
        <View
          patient={appointmentDetails.patient}
          closeModal={closeModal}
          createdBy={appointmentDetails.created_by}
          bookingSent={appointmentDetails.booking_sent}
          reminderSent={appointmentDetails.reminder_sent}
        />
      )}
    </>
  )
}

AppointmentSidebar.defaultProps = {
  appointmentDetails: {},
  clinician: {},
}

AppointmentSidebar.propTypes = {
  clinician: PropTypes.object,
  type: PropTypes.string.isRequired,
  setClinician: PropTypes.func.isRequired,
  clinicId: PropTypes.number.isRequired,
  clinicians: PropTypes.array.isRequired,
  appointmentDetails: PropTypes.object,
  timezone: PropTypes.string.isRequired,
  dateHandlerProps: PropTypes.object.isRequired,
  slotHandlerProps: PropTypes.object.isRequired,
  errorHandlerProps: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  ignoreOpeningHours: PropTypes.bool.isRequired,
}
